import React from 'react'

const InsightsChartIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={InsightsChartIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M0.642853 0.642883V17.3572H17.3571" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.5 8.35718L7.71429 11.5715L12.8571 3.85718L17.3571 7.07146" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default InsightsChartIcon
