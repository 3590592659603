import React from 'react'

const HideIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={HideIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M4.66602 5.16032C5.9424 4.3309 7.42138 3.71466 8.99994 3.71466C12.5871 3.71466 15.66 6.89684 17.01 8.53133C17.2232 8.79711 17.3412 9.14242 17.3412 9.50045C17.3412 9.85848 17.2232 10.2038 17.01 10.4696C16.2658 11.3705 14.9981 12.7418 13.4095 13.7911M10.9285 14.99C10.3083 15.1776 9.66291 15.2862 8.99994 15.2862C5.4128 15.2862 2.33994 12.1041 0.989942 10.4696C0.776719 10.2038 0.658691 9.85848 0.658691 9.50045C0.658691 9.14242 0.776719 8.79711 0.989942 8.53133C1.41766 8.01347 2.01832 7.34026 2.75349 6.66212" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.8182 11.3187C11.8224 10.3145 11.8224 8.68637 10.8182 7.68216C9.81397 6.67797 8.18585 6.67797 7.18164 7.68216" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.3571 17.8575L0.642822 1.14325" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default HideIcon
