export const PARAMETER_TYPES = {
  INPUT: 'input',
  OUTPUT: 'output',
}

export const ARTIFACT_TYPES = {
  PREDICTION: 'PREDICTION',
}

export enum USE_CASE_ARTIFACTS_TYPES {
  TRADEOFFS_V1 = 'TRADEOFFS_V1',
  PARAMETER_DATASET_V1 = 'PARAMETER_DATASET_V1',
}

export const USE_CASE_ARTIFACTS_TYPE_TO_ENDPOINT_MAP = {
  [USE_CASE_ARTIFACTS_TYPES.TRADEOFFS_V1]: 'v7/artifact/tradeoffs/link',
  [USE_CASE_ARTIFACTS_TYPES.PARAMETER_DATASET_V1]: 'v7/artifact/parameter-dataset/link',
}

export const DEFAULT_USE_CASE_ARTIFACTS_TYPE = USE_CASE_ARTIFACTS_TYPES.TRADEOFFS_V1
export const USE_CASE_ARTIFACTS_TYPES_OPTIONS_LIST = Object.keys(USE_CASE_ARTIFACTS_TYPES).map((item) => ({
  value: item,
  label: item,
}))

export enum USE_CASE_FAMILY_TYPES {
  DEMAND = 'DEMAND',
  ARTICLE_ALLOCATION = 'ARTICLE_ALLOCATION',
  REPLENISHMENT = 'REPLENISHMENT',
  WORKFORCE_PLANNING = 'WORKFORCE_PLANNING',
  SALES_PLANNING = 'SALES_PLANNING',
  CUSTOM = 'CUSTOM',
}

export enum VISIBILITY_STATES {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export const USE_CASE_FAMILY_OPTIONS_LIST = [{
  value: USE_CASE_FAMILY_TYPES.ARTICLE_ALLOCATION,
  labelKey: 'use_cases.family.types.article_allocation',
}, {
  value: USE_CASE_FAMILY_TYPES.DEMAND,
  labelKey: 'use_cases.family.types.demand',
}, {
  value: USE_CASE_FAMILY_TYPES.REPLENISHMENT,
  labelKey: 'use_cases.family.types.replenishment',
}, {
  value: USE_CASE_FAMILY_TYPES.SALES_PLANNING,
  labelKey: 'use_cases.family.types.sales_planning',
}, {
  value: USE_CASE_FAMILY_TYPES.WORKFORCE_PLANNING,
  labelKey: 'use_cases.family.types.workforce_planning',
}, {
  value: USE_CASE_FAMILY_TYPES.CUSTOM,
  labelKey: 'use_cases.family.types.custom',
}]

export const DEFAULT_USE_CASE_FAMILY_TYPE = USE_CASE_FAMILY_TYPES.CUSTOM
