import React from 'react'

const ViewIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={ViewIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M17.01 8.13858C17.2232 8.37483 17.3413 8.68176 17.3413 9C17.3413 9.31825 17.2232 9.62518 17.01 9.86143C15.66 11.3143 12.5871 14.1429 9 14.1429C5.41286 14.1429 2.34 11.3143 0.990002 9.86143C0.77678 9.62518 0.658752 9.31825 0.658752 9C0.658752 8.68176 0.77678 8.37483 0.990002 8.13858C2.34 6.68572 5.41286 3.85715 9 3.85715C12.5871 3.85715 15.66 6.68572 17.01 8.13858Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.00002 11.5714C10.4202 11.5714 11.5714 10.4202 11.5714 9C11.5714 7.57984 10.4202 6.42857 9.00002 6.42857C7.57986 6.42857 6.42859 7.57984 6.42859 9C6.42859 10.4202 7.57986 11.5714 9.00002 11.5714Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ViewIcon
