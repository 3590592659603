export const DATE_FORMATS = {
  DATE_HOUR: 'dateHour',
  DATE_HOUR_UTC: 'dateHourUTC',
  DATE_HOUR_12: 'dateHour12',
  DATE_HOUR_12_SHORT: 'dateHour12Short',
  DATE_HOUR_24: 'dateHour24',
  DATE_HOUR_24_SHORT_UTC: 'dateHour24ShortUTC',
  FULL_DATE: 'fullDate',
  FULL_DATE_SHORT: 'fullDateShort',
  FULL_DATE_MEDIUM: 'fullDateMedium',
  FULL_DATE_UTC: 'fullDateUTC',
  MONTH_LONG_YEAR_UTC: 'monthLongYearUTC',
  DAY_LONG_MONTH_SHORT_HOUR_UTC: 'dayLongMonthShortHourUTC',
  DAY_MONTH_SHORT_HOUR_24: 'dayMonthShortHour24',
  DAY_MONTH_SHORT_HOUR_12_UTC: 'dayMonthShortHour12UTC',
  DAY_MONTH_SHORT_UTC: 'dayMonthShortUTC',
  DAY_MONTH_LONG_UTC: 'dayMonthLongUTC',
  DAY_MONTH_UTC: 'dayMonthUTC',
  MONTH_LONG_UTC: 'monthLongUTC',
  MONTH_SHORT_UTC: 'monthShortUTC',
  DAY_UTC: 'dayUTC',
}

export const TIME_FORMATS = {
  TIME_24_DURATION: 'time24Duration',
  TIME_12_UTC: 'time12UTC',
  TIME_24_UTC: 'time24UTC',
  TIME_12: 'time12',
  TIME_24: 'time24',
}

export const getDateFormats = (timezone?: any, showHour12?: boolean) => {
  return {
    date: {
      // DD/MM/YYYY HH:MM Z
      [DATE_FORMATS.DATE_HOUR]: {
        timeZone: timezone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      },
      // DD/MM/YYYY, HH:MM (UTC)
      [DATE_FORMATS.DATE_HOUR_UTC]: {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
      },
      // DD/MM/YYYY, HH:MM A
      [DATE_FORMATS.DATE_HOUR_12]: {
        timeZone: timezone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
      },
      // D MMM YYYY, HH:MM A
      [DATE_FORMATS.DATE_HOUR_12_SHORT]: {
        timeZone: timezone,
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
      },
      // DD/MM/YYYY, HH:MM
      [DATE_FORMATS.DATE_HOUR_24]: {
        timeZone: timezone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
      },
      // D MMM YYYY, HH:MM
      [DATE_FORMATS.DATE_HOUR_24_SHORT_UTC]: {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour12: showHour12,
        hour: '2-digit',
        minute: '2-digit',
      },
      // DD/MM/YYYY
      [DATE_FORMATS.FULL_DATE]: {
        timeZone: timezone,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      // D MMM YYYY
      [DATE_FORMATS.FULL_DATE_SHORT]: {
        timeZone: timezone,
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      // D MMMM YYYY
      [DATE_FORMATS.FULL_DATE_MEDIUM]: {
        timeZone: timezone,
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
      // DD/MM/YYYY  (UTC)
      [DATE_FORMATS.FULL_DATE_UTC]: {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
      // MMMM YYYY
      [DATE_FORMATS.MONTH_LONG_YEAR_UTC]: {
        timeZone: 'UTC',
        month: 'long',
        year: 'numeric',
      },
      // DD MMM, HH:MM
      [DATE_FORMATS.DAY_LONG_MONTH_SHORT_HOUR_UTC]: {
        timeZone: 'UTC',
        day: '2-digit',
        month: 'short',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      },
      // D MMM, h:mm
      [DATE_FORMATS.DAY_MONTH_SHORT_HOUR_24]: {
        timeZone: timezone,
        day: 'numeric',
        month: 'short',
        hour12: showHour12,
        hour: 'numeric',
        minute: '2-digit',
      },
      // D MMM, h:mm A
      [DATE_FORMATS.DAY_MONTH_SHORT_HOUR_12_UTC]: {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'short',
        hour12: showHour12,
        hour: 'numeric',
        minute: '2-digit',
      },
      // D MMM
      [DATE_FORMATS.DAY_MONTH_SHORT_UTC]: {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'short',
      },
      // MM/DD
      [DATE_FORMATS.DAY_MONTH_UTC]: {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
      },
      // D
      [DATE_FORMATS.DAY_UTC]: {
        timeZone: 'UTC',
        day: 'numeric',
      },
      // MMMM
      [DATE_FORMATS.MONTH_LONG_UTC]: {
        timeZone: 'UTC',
        month: 'long',
      },
      // MM
      [DATE_FORMATS.MONTH_SHORT_UTC]: {
        timeZone: 'UTC',
        month: 'short',
      },
      // DD/MM/YYYY  (UTC)
      [DATE_FORMATS.DAY_MONTH_LONG_UTC]: {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'long',
      },
    },
    time: {
      // HH:MM:SS
      [TIME_FORMATS.TIME_24_DURATION]: {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
      },
      // HH:MM (UTC)
      [TIME_FORMATS.TIME_24_UTC]: {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit',
        hour12: showHour12,
      },
      // HH:MM A (UTC)
      [TIME_FORMATS.TIME_12_UTC]: {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit',
        hour12: showHour12,
      },
      // HH:MM
      [TIME_FORMATS.TIME_24]: {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      },
      // HH:MM A
      [TIME_FORMATS.TIME_12]: {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: showHour12,
      },
    },
  }
}
