import React from 'react'

import {
  Box, Chip,
  ChipProps, Typography,
} from '@mui/material'

import palette from '@configuration/theme/theme.palette'

export interface DataGridStatusComponentProps extends ChipProps {
  getBgColorByValue?: (value: any) => string
}

export const DataGridStatusComponent: React.FC<DataGridStatusComponentProps> = ({
  getBgColorByValue,
  label,
  ...props
}) => {
  const bgColor = getBgColorByValue ? getBgColorByValue(label as string) : undefined

  if (!label) {
    return null
  }

  return (
    <Chip
      {...props}
      size='small'
      variant='outlined'
      sx={{
        borderColor: bgColor,
        borderRadius: '4px',
        justifyContent: 'flex-start',
      }}
      label={(
        <Box
          gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '8px',
              width: '8px',
              borderRadius: '100%',
              backgroundColor: bgColor,
            }}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '12px',
              color: palette.new.black,
            }}
          >
            {label}
          </Typography>
        </Box>
      )}
    />
  )
}

export default DataGridStatusComponent
