import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import { FULL_LOCALES, SHORT_LOCALES, DEFAULT_LOCALE } from '@constants/locales.constants'

import { getDateFormats } from '@constants/date-formats.constants'
import { getIntlMessages, getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

import EN_LOCALE from '@locales/en-US'
import DE_LOCALE from '@locales/de-DE'

const dateFormats = getDateFormats()

/**
 * @function getReactIntlLocaleByShortCode Returns React Intl Locale by short code
 *
 * @return {String} Selected Locale
 */
export const getReactIntlLocaleByShortCode = (locale: SHORT_LOCALES): FULL_LOCALES => {
  switch (locale) {
    case SHORT_LOCALES.de:
      return FULL_LOCALES.de
    case SHORT_LOCALES.en:
      return FULL_LOCALES.en
    default:
      return FULL_LOCALES.en
  }
}

/**
 * @function getUserMessages Returns user messages
 *
 * @param {String} locale Selected Locale
 *
 * @return {Object} Return user locale messages
 */
export const getUserMessages = (locale: SHORT_LOCALES) => {
  switch (locale) {
    case SHORT_LOCALES.de:
      return DE_LOCALE
    case SHORT_LOCALES.en:
    default:
      return EN_LOCALE
  }
}

/**
 * Provides instance of React-Intl to be used in tests
 *
 * @returns {Object} React-Intl instance
 */
export const getIntlProviderObject = (): IntlProvider => {
  const messages = EN_LOCALE

  return new IntlProvider({ locale: DEFAULT_LOCALE, messages })
}

/**
 * @function mapStateToProps Decorates IntlProvider with props
 *
 * @param {Object} state Application State
 *
 * @return {Object} props for IntlProvider
 */
const mapStateToProps = (state: any) => {
  const selectedLanguage = getSelectedLanguage(state)
  const selectedLocale = getReactIntlLocaleByShortCode(selectedLanguage)
  const messages = getIntlMessages(state)

  return {
    locale: selectedLocale,
    defaultLocale: DEFAULT_LOCALE,
    formats: dateFormats,
    messages,
  }
}

// @ts-ignore
export default connect(mapStateToProps)(IntlProvider)
