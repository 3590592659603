import React from 'react'

const CopyIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#000001',
}) => {
  return (
    <svg data-testid={CopyIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M16.0731 12.8571C16.0731 13.1981 15.9376 13.5251 15.6965 13.7662C15.4554 14.0074 15.1284 14.1428 14.7874 14.1428H6.43025C6.08925 14.1428 5.76223 14.0074 5.52111 13.7662C5.27999 13.5251 5.14453 13.1981 5.14453 12.8571V1.92854C5.14453 1.58754 5.27999 1.26052 5.52111 1.0194C5.76223 0.778281 6.08925 0.642822 6.43025 0.642822H12.216L16.0731 4.49996V12.8571Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2154 17.3571H3.2154C2.87441 17.3571 2.54738 17.2217 2.30626 16.9806C2.06515 16.7394 1.92969 16.4124 1.92969 16.0714V4.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default CopyIcon
