import React, {
  useCallback,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import { RequestFileDownloadActionPayload } from '@redux/modules/file-service/file-service.types'

import ConnectIcon from '@icons/flow/connect.icon'
import ActionButtonComponent from '@base/pagebar/ActionButton'
import ConfirmationDialogComponent from '@base/dialogs/ConfirmationDialog'
import TextFieldComponent from '@base/forms/TextField'
import { parsePfpPath } from '@utils/files.utils'

export interface FileBrowserDownloadByUrlButtonComponentProps {
  /**
   * Handle download action
   * @param payload download action payload
   */
  handleDownload: (payload: RequestFileDownloadActionPayload) => void
}

const FileBrowserDownloadByUrlButtonComponent: React.FC<FileBrowserDownloadByUrlButtonComponentProps> = ({
  handleDownload,
}) => {
  const intl = useIntl()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [pfpPath, setPfpPath] = useState<string>('')

  const parsedPfp = parsePfpPath(pfpPath)
  const isValid = pfpPath.includes('pfp://') && parsedPfp.bucket && parsedPfp.path
  const errors = !isValid ? intl.formatMessage({ id: 'fileManager.header.download.error' }) : ''

  const handleOpenDialog = useCallback(() => {
    setPfpPath('')
    setDialogOpen(true)
  }, [])

  const onSubmit = useCallback(() => {
    if (!isValid) {
      return
    }

    handleDownload({
      bucketName: parsedPfp.bucket,
      fileName: parsedPfp.name,
      filePath: parsedPfp.path,
    })
  }, [handleDownload, isValid, parsedPfp])

  return (
    <>
      <ActionButtonComponent
        name='fileManagerPfpDownload'
        label={intl.formatMessage({ id: 'fileManager.header.download.title' })}
        onClick={handleOpenDialog}
        IconComponent={ConnectIcon}
      />

      <ConfirmationDialogComponent
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={onSubmit}
        disableSubmitButton={!pfpPath || !isValid}
        title={intl.formatMessage({ id: 'fileManager.header.download.title' })}
        description={(
          <Box>
            <Typography variant='body1'>
              {intl.formatMessage({ id: 'fileManager.header.download.description' })}
            </Typography>
            <Box my={1}>
              <TextFieldComponent
                name='pfpPath'
                label={intl.formatMessage({ id: 'fileManager.header.download.input' })}
                placeholder={intl.formatMessage({ id: 'fileManager.header.download.placeholder' })}
                value={pfpPath}
                onChange={(e) => setPfpPath(e.target.value)}
                errors={errors}
                touched={Boolean(pfpPath)}
              />
            </Box>
          </Box>
        )}
        closeButtonLabel={intl.formatMessage({ id: 'fileManager.header.download.cancel' })}
        submitButtonLabel={intl.formatMessage({ id: 'fileManager.header.download.button' })}
      />
    </>
  )
}

export default FileBrowserDownloadByUrlButtonComponent
