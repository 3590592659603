import React from 'react'

const DashboardIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DashboardIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <rect x="0.5" y="0.5" width="17" height="4" rx="1.5" stroke={detailsFill} />
      <rect x="0.5" y="6.5" width="17" height="4" rx="1.5" stroke={detailsFill} />
      <rect x="0.5" y="12.5" width="17" height="4" rx="1.5" stroke={detailsFill} />

    </svg>
  )
}

export default DashboardIcon
