import React from 'react'

const AmazonWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={AmazonWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.4947 14.5323C8.20826 17.9999 3.68624 15.0987 0.791554 13.3365C0.612431 13.2255 0.307986 13.3625 0.572134 13.6659C1.5365 14.8352 4.69693 17.6535 8.82221 17.6535C12.9503 17.6535 15.4062 15.401 15.7134 15.0081C16.0185 14.6185 15.803 14.4036 15.4946 14.5323H15.4947ZM17.541 13.4022C17.3454 13.1474 16.3512 13.0999 15.7256 13.1768C15.099 13.2514 14.1584 13.6344 14.2402 13.8643C14.2821 13.9505 14.3678 13.9118 14.7983 13.8731C15.2299 13.8301 16.4391 13.6775 16.6911 14.0068C16.9442 14.3385 16.3054 15.9183 16.1887 16.1731C16.076 16.4279 16.2318 16.4936 16.4435 16.3239C16.6523 16.1543 17.0304 15.715 17.2841 15.0933C17.536 14.4682 17.6897 13.5962 17.541 13.4022H17.541Z" fill="black" fillOpacity="0.4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5964 7.45648C10.5964 8.36634 10.6194 9.12513 10.1595 9.93315C9.78836 10.5902 9.20038 10.9942 8.54345 10.9942C7.64668 10.9942 7.12441 10.3109 7.12441 9.30252C7.12441 7.31191 8.90799 6.95062 10.5964 6.95062V7.45648ZM12.9516 13.149C12.7972 13.2869 12.5738 13.2968 12.3997 13.2048C11.6246 12.561 11.4866 12.2621 11.0596 11.6479C9.77852 12.9552 8.87192 13.3461 7.20985 13.3461C5.24556 13.3461 3.71484 12.134 3.71484 9.70653C3.71484 7.81126 4.74303 6.5203 6.20465 5.88967C7.47261 5.33119 9.2431 5.23266 10.5964 5.07833V4.7761C10.5964 4.22094 10.6391 3.564 10.3139 3.08446C10.0281 2.65413 9.48288 2.47674 9.00326 2.47674C8.11314 2.47674 7.31821 2.93331 7.12441 3.87932C7.08495 4.0896 6.93062 4.29655 6.72041 4.30638L4.45389 4.06335C4.26342 4.02057 4.05321 3.86623 4.10576 3.57384C4.62803 0.82782 7.10793 0 9.32841 0C10.465 0 11.9496 0.302228 12.8464 1.16287C13.9829 2.22382 13.8745 3.63954 13.8745 5.18011V8.81965C13.8745 9.91349 14.3278 10.393 14.7548 10.9843C14.9059 11.1945 14.9388 11.4475 14.7482 11.6051C14.2719 12.0026 13.4245 12.7417 12.9581 13.1556L12.9515 13.149" fill="black"/>

    </svg>
  )
}

export default AmazonWhiteIcon
