import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import {
  Grid, Card,
  Box, useTheme,
} from '@mui/material'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu'

import TradeOffsChartComponent from '@components/(features)/trade-offs/trade-offs-chart'
import TradeOffsConfigurationComponent from '@components/(features)/trade-offs/configuration'
import { MAX_SELECTED_POINTS } from '@constants/analysis.constants'
import TradeOffsLegendComponent from '@components/(features)/trade-offs/trade-offs-legend'
import TradeOffsPanelComponent from '@components/(features)/trade-offs/trade-offs-panel'
import TradeOffsOptimizePanelComponent from '@components/(features)/trade-offs/trade-offs-optimize-panel'

export interface TradeOffsLayoutComponentProps {
  exportId?: string,
  dataSet?: Socrates.TradeOffsDatasetItem[],
  configuration: Socrates.TradeOffsConfiguration,
  kpisOptions: Socrates.KpiOption[],
  isDefaultConfig?: boolean,
  optimizeEnabled?: boolean,
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  showSuboptimalSolutions?: boolean,
  tradeOffsComparisonMeta?: Socrates.TradeOffsComparisonMetaItem[],
  selectTradeOffsPoint(payload: Socrates.SelectedTradeOffsPoint): any,
  handleConfigurationChange: Socrates.HandleConfigurationChange,
  handleResetClick(): any,
  handleDownloadSelection: {
    (index: number): any,
  },
}

const TradeOffsLayoutComponent: React.FC<TradeOffsLayoutComponentProps> = ({
  exportId = '',
  dataSet = [],
  configuration = {} as Socrates.TradeOffsConfiguration,
  isDefaultConfig,
  selectTradeOffsPoint,
  kpisOptions = [],
  selectedPoints = [],
  handleConfigurationChange,
  handleResetClick,
  showSuboptimalSolutions,
  tradeOffsComparisonMeta = [],
  handleDownloadSelection,
  optimizeEnabled,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const [solutionToExecute, setSolutionToExecute] = useState<number | null>(null)

  const handleSelect = (data: {
    payload: Socrates.TradeOffsDatasetItem,
  }) => {
    if (optimizeEnabled) {
      selectTradeOffsPoint({
        payload: {
          ...data.payload,
        },
        select: true,
        decision: true,
        deselect: false,
        maxItems: 1,
      })

      setSolutionToExecute(0)
    } else {
      selectTradeOffsPoint({
        payload: {
          ...data.payload,
        },
        select: true,
        decision: true,
        deselect: true,
        maxItems: MAX_SELECTED_POINTS,
      })

      setSolutionToExecute(null)
    }
  }

  const handleReset = () => {
    handleResetClick()
    setSolutionToExecute(null)
  }

  return (
    <Card
      id={exportId}
      elevation={0}
      data-testid={TradeOffsLayoutComponent.name}
      sx={{
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.new.grey_a}`,
      }}
    >
      <Grid container={true} spacing={0}>
        <Grid
          item={true}
          xs={8}
          sx={{
            borderRight: `1px solid ${theme.palette.new.grey_a}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
              height: theme.spacing(8),
              borderBottom: `1px solid ${theme.palette.new.grey_a}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                borderRight: `1px solid ${theme.palette.new.grey_a}`,
                padding: theme.spacing(0, 2),
              }}
            >
              <DropdownMenuComponent
                name='xAxisSelector'
                overline={intl.formatMessage({ id: 'trade_offs.chart.pareto.x' })}
                options={kpisOptions}
                value={configuration.AXIS.x}
                onChange={(value) => handleConfigurationChange('AXIS', 'x', value as any)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                padding: theme.spacing(0, 2),
              }}
            >
              <DropdownMenuComponent
                name='xAxisSelector'
                overline={intl.formatMessage({ id: 'trade_offs.chart.pareto.y' })}
                options={kpisOptions}
                value={configuration.AXIS.y}
                onChange={(value) => handleConfigurationChange('AXIS', 'y', value as any)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: theme.spacing(60),
              [theme.breakpoints.down('xl')]: {
                height: theme.spacing(40),
              },
            }}
          >
            <TradeOffsChartComponent
              dataset={dataSet}
              isDefaultConfig={isDefaultConfig}
              handleSelect={handleSelect}
              xAxisLabel={configuration.AXIS.x}
              yAxisLabel={configuration.AXIS.y}
              optimizeEnabled={optimizeEnabled}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
              height: theme.spacing(5),
              borderTop: `1px solid ${theme.palette.new.grey_a}`,
            }}
          >
            <TradeOffsLegendComponent
              optimizeEnabled={optimizeEnabled}
              showSuboptimalSolutions={showSuboptimalSolutions}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                borderLeft: `1px solid ${theme.palette.new.grey_a}`,
                padding: theme.spacing(0, 4),
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                },
                [theme.breakpoints.down('xl')]: {
                  padding: theme.spacing(0, 2),
                },
              }}
            >
              <ToggleButtonComponent
                label={intl.formatMessage({ id: 'trade_offs.chart.pareto.toggle' })}
                onChange={() => handleConfigurationChange('SUBOPTIMAL', 'enabled', (!showSuboptimalSolutions) as any)}
                name='showSuboptimalSolutions'
                value={showSuboptimalSolutions}
                checked={showSuboptimalSolutions}
                transparent={true}
              />
            </Box>
          </Box>
          <TradeOffsConfigurationComponent
            kpisOptions={kpisOptions}
            configuration={configuration}
            handleChange={handleConfigurationChange}
            optimizeEnabled={optimizeEnabled}
            handleReset={handleReset}
          />
        </Grid>
        {
          optimizeEnabled ? (
            <TradeOffsOptimizePanelComponent
              selectedPoints={selectedPoints}
              solutionToExecute={solutionToExecute}
            />
          ) : (
            <TradeOffsPanelComponent
              tradeOffsComparisonMeta={tradeOffsComparisonMeta}
              selectedPoints={selectedPoints}
              handleDownloadSelection={handleDownloadSelection}
              solutionToExecute={solutionToExecute}
              setSolutionToExecute={setSolutionToExecute}
              selectTradeOffsPoint={selectTradeOffsPoint}
            />
          )
        }
      </Grid>
    </Card>
  )
}

export default TradeOffsLayoutComponent
