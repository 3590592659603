import { SNAPSHOTS } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/api.utils'
import apiProvider from '@redux/api-provider'

export const getSnapshotsList = (payload: Snapshots.APIGetListRequest) => {
  return apiProvider.getApi(SNAPSHOTS).post('v1/snapshots/list', payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.snapshots as Snapshots.APIGetListResponse
  })
}

export const getSnapshotItem = (payload: Snapshots.APIGetItemRequest) => {
  return apiProvider.getApi(SNAPSHOTS).post('v1/snapshots/get-by-id', payload).then(({ data }) => {
    checkApiForError(data)

    return data.data as Snapshots.APIGetItemResponse
  })
}

export const deleteSnapshot = (payload: Snapshots.APIDeleteRequest) => {
  return apiProvider.getApi(SNAPSHOTS).post('v1/snapshots/delete', payload).then(({ data }) => {
    checkApiForError(data)
  })
}
