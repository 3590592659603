import { createAction } from 'redux-actions'

import {
  REQUEST_SNAPSHOTS,
  RECEIVE_SNAPSHOTS,

  REQUEST_SNAPSHOT,
  RECEIVE_SNAPSHOT,

  DELETE_SNAPSHOT,

  START_FETCHING_SNAPSHOTS,
  STOP_FETCHING_SNAPSHOTS,
} from './snapshots.action-types'

export const requestSnapshotsAction = createAction<Snapshots.APIGetListRequest>(REQUEST_SNAPSHOTS)
export const receiveSnapshotsAction = createAction(RECEIVE_SNAPSHOTS)

export const requestSnapshotAction = createAction<Snapshots.APIGetItemRequest>(REQUEST_SNAPSHOT)
export const receiveSnapshotAction = createAction(RECEIVE_SNAPSHOT)

export const deleteSnapshotAction = createAction<Snapshots.APIDeleteRequest>(DELETE_SNAPSHOT)

export const startFetchingSnapshotsAction = createAction<string>(START_FETCHING_SNAPSHOTS)
export const stopFetchingSnapshotsAction = createAction<string>(STOP_FETCHING_SNAPSHOTS)
