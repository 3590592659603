import React from 'react'

const SyncIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={SyncIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M13.9219 11.3406L16.3833 10.7252L16.9986 13.1866" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.384 10.7252C15.8374 12.2742 14.8444 13.6267 13.5304 14.6123C12.2163 15.5978 10.6398 16.1723 8.99977 16.2634C7.48368 16.2637 6.00425 15.7973 4.76243 14.9275C3.52061 14.0578 2.57659 12.827 2.05859 11.4021" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.07676 6.42284L1.61535 7.03819L1 4.57678" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.61328 7.03817C2.64707 4.20755 5.82229 1.5 8.99751 1.5C10.521 1.50428 12.0058 1.97968 13.2485 2.86101C14.4912 3.74234 15.4309 4.98646 15.9387 6.42282" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default SyncIcon
