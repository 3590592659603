import { HERMES } from '@constants/api-provider.constants'
import { HERMES_DIRECTIONS } from '@constants/hermes.constants'
import { checkApiForError } from '@utils/api.utils'
import apiProvider from '@redux/api-provider'

import {
  AddConnectionPayload,
  AddPublisherPayload,
  AddTransformationPayload,
  GetPublisherPayload,
  GetPublishersListPayload,
  GetSourceSchemaPayload,
  RemovePublisherPayload,
  UpdateConnectionPayload,
  UpdatePublisherPayload,
  UpdateTransformationPayload,
} from './hermes.types'

export const fetchSources = async () => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/sources/list')

  checkApiForError(data)

  return data.data.sources
}

export const fetchDestinations = async () => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/destinations/list')

  checkApiForError(data)

  return data.data.destinations
}

export const fetchConnections = async (companyId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/list', { companyId })

  checkApiForError(data)

  return data.data.connections
}

export const removeConnection = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/remove', {
      companyId,
      connectionId,
    })

  checkApiForError(data)

  return data.data.removedConnections
}

export const addConnection = async (payload: AddConnectionPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/add', payload)

  checkApiForError(data)

  return data.data.connectionId
}

export const updateConnection = async (payload: UpdateConnectionPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/update', payload)

  checkApiForError(data)

  return data.data.connectionId
}

export const getConnection = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/get', {
      companyId,
      connectionId,
    })

  checkApiForError(data)

  return data.data as Hermes.ConnectionItem
}

export const getSourceSchema = async (payload: GetSourceSchemaPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/sources/get-schema', payload)

  checkApiForError(data)

  return data.data.streams
}

export const getSyncStatus = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/sync/status', {
      companyId,
      connectionId,
    })

  checkApiForError(data)

  return data.data
}

export const startSync = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/sync/start', {
      companyId,
      connectionId,
    })

  checkApiForError(data)

  return data.data
}

export const cancelSync = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/sync/cancel', {
      companyId,
      connectionId,
    })

  checkApiForError(data)

  return data.data
}

export const addTransformation = async (payload: AddTransformationPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/transformations/add', payload)

  checkApiForError(data)
}

export const removeTransformation = async (useCaseId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/transformations/remove', { usecaseId: useCaseId })

  checkApiForError(data)
}

export const updateTransformation = async (payload: UpdateTransformationPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/transformations/update', payload)
  checkApiForError(data)
}

export const getTransformation = async (companyId: string, usecaseId: string, silent = false) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/transformations/get', { companyId, usecaseId })

  if (!silent) {
    checkApiForError(data)
  }

  return data.data
}

export const resetSyncData = async (companyId: string, connectionId: string) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/connections/sync/reset', {
      companyId,
      connectionId,
    })
  checkApiForError(data)
  return data.data
}

export const getOAuthLink = async (customerId: string, id: string, direction = HERMES_DIRECTIONS.SOURCE): Promise<string> => {
  const payload = direction === HERMES_DIRECTIONS.SOURCE ? {
    sourceId: id,
  } : {
    destinationId: id,
  }

  const { data } = await apiProvider.getApi(HERMES).post('v1/oauth/get', {
    customerId,
    ...payload,
  })

  checkApiForError(data)

  return data.data.url
}

export const postOAuthCallback = async (customerId: string, id: string, oAuthParams: any, direction = HERMES_DIRECTIONS.SOURCE) => {
  const payload = direction === HERMES_DIRECTIONS.SOURCE ? {
    sourceId: id,
  } : {
    destinationId: id,
  }

  const { data } = await apiProvider.getApi(HERMES).post('v1/oauth/callback', {
    customerId,
    params: oAuthParams,
    ...payload,
  })

  checkApiForError(data)

  return data.data
}

export const fetchPublishers = async (payload: GetPublishersListPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/publishers/list', payload)

  checkApiForError(data)

  return data.data.publishers as Hermes.PublisherListItem[]
}

export const addPublisher = async (payload: AddPublisherPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/publishers/add', payload)

  checkApiForError(data)

  return data.data.publisherId
}

export const updatePublisher = async (payload: UpdatePublisherPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/publishers/update', payload)

  checkApiForError(data)

  return data.data.publisherId
}

export const removePublisher = async (payload: RemovePublisherPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/publishers/remove', payload)

  checkApiForError(data)
}

export const getPublisher = async (payload: GetPublisherPayload) => {
  const { data } = await apiProvider.getApi(HERMES)
    .post('v1/publishers/get', payload)

  checkApiForError(data)

  return data.data as Hermes.PublisherItem
}
