import React from 'react'
import {
  Box, SxProps,
  Typography, useTheme,
  Theme,
} from '@mui/material'

export interface FormattedUnitValueComponentProps {
  value: number | string,
  unit?: string,
  className?: string,
  sx?: SxProps<Theme>,
}

const FormattedUnitValueComponent: React.FC<FormattedUnitValueComponentProps> = ({
  value,
  unit,
  className,
  sx,
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={FormattedUnitValueComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        minWidth: theme.spacing(8),
        height: theme.spacing(4),
        ...sx,
      }}
      className={className}
    >
      <Typography
        noWrap={true}
        title={String(value)}
        sx={{
          fontSize: '16px',
          color: theme.palette.new.white,
        }}
      >
        {value}
      </Typography>

      {
        unit ? (
          <Typography
            noWrap={true}
            title={unit}
            sx={{
              fontSize: '12px',
              color: theme.palette.new.black_d,
              marginLeft: theme.spacing(1),
              textTransform: 'uppercase',
            }}
          >
            {unit}
          </Typography>
        ) : (
          null
        )
      }
    </Box>
  )
}

export default FormattedUnitValueComponent
