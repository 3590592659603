import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'

import TabComponent from '@base/pagebar/Tab'

export interface TabBarComponentProps {
  /**
   * Tab options
   */
  options?: Common.TabOption[]
  /**
   * Callback to be called when tab is changed
   * @param tab - Selected tab option
   */
  onTabChange?: (tab: Common.TabOption) => void,
}

const TabBarComponent: React.FC<TabBarComponentProps> = ({
  options = [], onTabChange,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const match = useRouteMatch<Common.RouterMatch>()
  const handleClick = (option: Common.TabOption) => {
    history.push(generatePath(option.path, match.params))

    if (onTabChange) {
      onTabChange(option)
    }
  }

  if (!options.length) {
    return null
  }

  return (
    <Box
      data-testid={TabBarComponent.name}
      role='tablist'
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '36px',
        outline: 'none',
        p: '0px 3px',
        backgroundColor: theme.palette.new.smokey_silver,
      }}
    >
      {
        options.map((option, index) => {
          const isActive = match.path === option.path

          return (
            <TabComponent
              key={index}
              label={option.label}
              active={isActive}
              onClick={() => handleClick(option)}
            />
          )
        })
      }
    </Box>
  )
}

export default TabBarComponent
