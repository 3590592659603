import React from 'react'
import { Box, useTheme } from '@mui/material'

export interface DataGridLinkComponentProps {
  onClick?: (e: React.SyntheticEvent) => void
  label: string
}

export const DataGridLinkComponent: React.FC<DataGridLinkComponentProps> = ({
  onClick,
  label,
}) => {
  const theme = useTheme()

  if (!label) {
    return null
  }

  return (
    <Box
      component='span'
      data-testid={DataGridLinkComponent.name}
      sx={{
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.new.violet,
          textDecoration: 'none',
        },
      }}
      onClick={onClick}
    >
      {label}
    </Box>
  )
}

export default DataGridLinkComponent
