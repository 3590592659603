import React from 'react'

import {
  Divider,
  Grid, GridProps,
  Typography, useTheme,
} from '@mui/material'

export const DEFAULT_SPACING = 1

export interface FormLayoutContainerProps extends GridProps {}
export const FormLayoutContainer: React.FC<FormLayoutContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Grid
      container={true}
      rowGap={2}
      data-testid={FormLayoutContainer.name}
      {...props}
    >
      {children}
    </Grid>
  )
}

export interface FormLayoutItemsContainerProps extends GridProps {
  /**
   * the title of the container
   */
  title?: string
  /**
   * if true, the container will be hidden
   */
  hidden?: boolean
  /**
   * if true, the container will have a divider at the bottom
   */
  divider?: boolean
  /**
   * Spacing between the items
   * @default 2
   */
  spacing?: number
}
export const FormLayoutItemsContainer: React.FC<FormLayoutItemsContainerProps> = ({
  children,
  title,
  hidden,
  divider = true,
  spacing = DEFAULT_SPACING,
  ...props
}) => {
  const theme = useTheme()
  const containerSx = {
    position: 'relative',
    mb: divider ? 4 : 0,
  }
  const dividerSx = {
    backgroundColor: theme.palette.new.grey_a,
    width: 'calc(100% + 70px)',
    bottom: '-40px',
    left: '-30px',
  }

  if (hidden) {
    return null
  }

  return (
    <Grid
      container={true}
      spacing={spacing}
      data-testid={FormLayoutItemsContainer.name}
      sx={containerSx}
    >
      <FormLayoutTitle title={title} />

      {children}

      {
        divider ? (
          <Divider
            absolute={true}
            orientation='horizontal'
            sx={dividerSx}
          />
        ) : (
          null
        )
      }
    </Grid>
  )
}

export interface FormLayoutItemProps extends Pick<GridProps, 'xs' | 'children' | 'container' | 'alignItems' | 'alignContent'> {
  /**
   * if true, the item will be hidden
   */
  hidden?: boolean
}
export const FormLayoutItem: React.FC<FormLayoutItemProps> = ({
  children,
  container,
  hidden,
  ...props
}) => {
  if (hidden) {
    return null
  }

  return (
    <Grid
      item={true}
      data-testid={FormLayoutItem.name}
      container={container}
      spacing={container ? DEFAULT_SPACING : undefined}
      {...props}
    >
      {children}
    </Grid>
  )
}

export interface FormLayoutTitleProps {
  /**
   * the title of the container
   */
  title?: string
}

export const FormLayoutTitle: React.FC<FormLayoutTitleProps> = ({
  title,
}) => {
  if (!title) {
    return null
  }

  return (
    <Grid
      item={true}
      data-testid={FormLayoutTitle.name}
      xs={12}
    >
      <Typography
        variant='subtitle1'
        mb={1}
      >
        {title}
      </Typography>
    </Grid>
  )
}
