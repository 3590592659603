import React, {
  useEffect, useState,
  useCallback,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import TextFieldComponent from '@base/forms/TextField'
import FormDialogComponent from '@base/dialogs/FormDialog'

import {
  INPUT_TYPES,
  GOAL_DEFAULT,
  SOURCE_TYPE_DEFAULT,
  UNIT_LABEL_DEFAULT,
} from '@constants/flow.constants'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { createParameterAction, createGroupingAction } from '@redux/modules/parameters/parameters.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { PARAMETER_TYPES } from '@constants/use-cases.constants'
import { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'

export interface ConnectParameterCreationModalDetails extends Common.ModalDetails {
  parameterType: PARAMETER_BLOCK_TYPES | INPUT_TYPES
}

const ConnectParameterCreationModalContainer: React.FC = () => {
  const intl = useIntl()
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const { parameterType } = useSelector((state) => getModalDetails<ConnectParameterCreationModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const open = modalPageName === CONNECT_PARAMETER_CREATION_MODAL_NAME

  useEffect(() => {
    setName('')
  }, [open])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ((e.key === 'Enter') && name) {
      handleSubmit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleClose = () => {
    dispatch(
      setPrimaryModalPageName(null),
    )
  }

  const getDefaultFieldConfig = () => {
    if (parameterType === INPUT_TYPES.GROUPING) {
      return {
        name,
        dataColumnName: null,
        description: '',
        sourceType: SOURCE_TYPE_DEFAULT,
        unitLabel: UNIT_LABEL_DEFAULT,
        groupingAttributeId: '',
      } as UseCase.GroupingParameter
    }

    if (parameterType === PARAMETER_BLOCK_TYPES.TARGET) {
      return {
        name,
        dataColumnName: null,
        description: '',
        sourceType: SOURCE_TYPE_DEFAULT,
        unitLabel: UNIT_LABEL_DEFAULT,
        goal: GOAL_DEFAULT,
        modelOutputParameterId: '',
      } as UseCase.OutputParameter
    }

    return {
      name,
      dataColumnName: null,
      description: '',
      inputType: parameterType,
      sourceType: SOURCE_TYPE_DEFAULT,
      unitLabel: UNIT_LABEL_DEFAULT,
      modelInputParameterId: '',
    } as UseCase.InputParameter
  }

  const handleSubmit = () => {
    const isGrouping = (parameterType === INPUT_TYPES.GROUPING)
    const isTarget = (parameterType === PARAMETER_BLOCK_TYPES.TARGET)
    const config = getDefaultFieldConfig()

    if (isGrouping) {
      dispatch(
        createGroupingAction({
          name,
        }),
      )
    } else {
      dispatch(
        createParameterAction({
          type: isTarget ? PARAMETER_TYPES.OUTPUT : PARAMETER_TYPES.INPUT,
          config: config as any,
        }),
      )
    }
  }

  const getPlaceholderText = () => {
    switch (parameterType) {
      case INPUT_TYPES.PASSIVE:
        return intl.formatMessage({ id: 'connect.block.input.add_modal_placeholader' })
      case INPUT_TYPES.ACTIVE:
        return intl.formatMessage({ id: 'connect.block.active_inputs.add_modal_placeholader' })
      case INPUT_TYPES.GENERIC:
        return intl.formatMessage({ id: 'connect.block.generic_inputs.add_modal_placeholader' })
      case INPUT_TYPES.GROUPING:
        return intl.formatMessage({ id: 'connect.block.grouping.add_modal_placeholader' })
      default:
        return intl.formatMessage({ id: 'connect.block.output.add_modal_placeholader' })
    }
  }

  const getTitleText = () => {
    switch (parameterType) {
      case INPUT_TYPES.PASSIVE:
        return intl.formatMessage({ id: 'connect.block.input.add_modal_title' })
      case INPUT_TYPES.ACTIVE:
        return intl.formatMessage({ id: 'connect.block.active_inputs.add_modal_title' })
      case INPUT_TYPES.GENERIC:
        return intl.formatMessage({ id: 'connect.block.generic_inputs.add_modal_title' })
      case INPUT_TYPES.GROUPING:
        return intl.formatMessage({ id: 'connect.block.grouping.add_modal_title' })
      default:
        return intl.formatMessage({ id: 'connect.block.output.add_modal_title' })
    }
  }

  const getDescriptionText = () => {
    switch (parameterType) {
      case INPUT_TYPES.PASSIVE:
        return intl.formatMessage({ id: 'connect.block.input.add_modal_description' })
      case INPUT_TYPES.ACTIVE:
        return intl.formatMessage({ id: 'connect.block.active_inputs.add_modal_description' })
      case INPUT_TYPES.GENERIC:
        return intl.formatMessage({ id: 'connect.block.generic_inputs.add_modal_description' })
      case INPUT_TYPES.GROUPING:
        return intl.formatMessage({ id: 'connect.block.grouping.add_modal_description' })
      default:
        return intl.formatMessage({ id: 'connect.block.output.add_modal_description' })
    }
  }
  return (
    <FormDialogComponent
      open={Boolean(open)}
      onClose={handleClose}
      onSubmit={handleSubmit}
      disableSubmitButton={!name}
      title={getTitleText()}
      description={getDescriptionText()}
    >
      <TextFieldComponent
        name='name'
        label='Parameter name'
        hideLabel={true}
        placeholder={getPlaceholderText()}
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus={true}
      />
    </FormDialogComponent>
  )
}

export default ConnectParameterCreationModalContainer
