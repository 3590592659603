import React from 'react'

import {
  SxProps,
  Theme,
  useTheme,
  Box,
} from '@mui/material'

export interface SidePanelButtonContainerProps {
  /**
   * content of the card
   */
  children: React.ReactNode,
  /**
   * if true, the container is hidden
   */
  hidden?: boolean,
  /**
   * custom styles
   */
  sx?: SxProps<Theme>
}

export const SidePanelButtonContainerComponent: React.FC<SidePanelButtonContainerProps> = ({
  children, hidden, sx,
}) => {
  const theme = useTheme()

  if (hidden) {
    return null
  }

  const styles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    ...sx,
  } as SxProps<Theme>

  return (
    <Box
      sx={styles}
    >
      {children}
    </Box>
  )
}

export default SidePanelButtonContainerComponent
