import React, { useMemo } from 'react'
import {
  Button,
  ButtonProps,
  Typography,
  useTheme,
} from '@mui/material'

import { useRouteMatch } from 'react-router-dom'
import { getButtonsStyles } from '@utils/ui.utils'
import { BadgeComponent, BadgeComponentProps } from '@base/buttons/Badge/Badge.component'

import {
  trackEvent,
  TRACKING_ACTIONS,
  TrackingEventNameProps,
} from '@utils/tracking.utils'

import {
  DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_FONT_SIZE,
  DEFAULT_BUTTON_HEIGHT, DEFAULT_BUTTON_TRANSITION,
  DEFAULT_PADDING,
} from '@constants/ui.constants'

import LoadingCloudComponent from '@base/loading/LoadingCloud'

export interface ButtonComponentProps extends Pick<ButtonProps, 'onClick' | 'disabled' | 'sx'> {
  /**
   * Button name, used for tracking and testing
   */
  name: string
  /**
   * Button label
   */
  label: string
  /**
   * Aria label for the button. By default, it is the same as the label.
   * Please provide a different value if the badge is used.
   */
  ariaLabel?: string
  /**
   * Button color
   *
   * @default 'primary'
   */
  color?: 'primary' | 'secondary' | 'tertiary' | 'highlighted' | 'highlighted-secondary'
  /**
   * If 'true', the button will be in loading state
   */
  loading?: boolean
  /**
   * If 'true', the button will be rounded
   *
   * @default false
   */
  rounded?: boolean
  /**
   * Ref to be forwarded to the button
   */
  forwardedRef?: React.ForwardedRef<any>
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: Partial<TrackingEventNameProps>
  /**
   * Icon component to be displayed at the start of the button
   */
  StartIconComponent?: React.FC<Common.IconProps>
  /**
   * Props to be passed to the Start Icon component
   */
  startIconComponentProps?: Common.IconProps
  /**
   * Icon component to be displayed at the end of the button
   */
  EndIconComponent?: React.FC<Common.IconProps>
  /**
   * Props to be passed to the End Icon component
   */
  endIconComponentProps?: Common.IconProps
  /**
   * Badge component props
   */
  badgeProps?: BadgeComponentProps
  /**
   * Max width before ellipsis is applied
   */
  maxWidth?: string
  /**
   * If 'true', the button will be bold
   */
  bold?: boolean
}

export const ButtonComponent: React.FC<ButtonComponentProps> = ({
  name,
  loading,
  forwardedRef,
  StartIconComponent,
  EndIconComponent,
  startIconComponentProps,
  endIconComponentProps,
  badgeProps,
  label,
  trackingProps,
  maxWidth,
  rounded = false,
  bold = false,
  onClick,
  color = 'primary',
  disabled,
  ariaLabel,
  sx,
  ...rest
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CLICK,
      ...trackingProps,
    }, {
      router: params,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const buttonStyles = getButtonsStyles(theme, color, 'regular', loading)

  const typographyProps = useMemo(() => {
    if (maxWidth) {
      return {
        sx: {
          maxWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }
    }

    return {}
  }, [maxWidth])

  const iconProps = useMemo(() => ({
    ...(color === 'highlighted' || color === 'primary' ? {
      detailsFill: 'white',
    } : {}),
    ...(disabled ? {
      detailsFill: 'rgba(0, 0, 0, 0.20)',
    } : {}),
  }), [
    color,
    disabled,
  ])

  const startIcon = useMemo(() => {
    if (!StartIconComponent) {
      return undefined
    }

    const icon = (
      <StartIconComponent
        {...iconProps}
        {...startIconComponentProps}
      />
    )

    if (badgeProps) {
      return (
        <BadgeComponent
          {...badgeProps}
        >
          {icon}
        </BadgeComponent>
      )
    }

    return icon
  }, [
    badgeProps,
    StartIconComponent,
    startIconComponentProps,
    iconProps,
  ])

  const endIcon = useMemo(() => {
    if (!EndIconComponent) {
      return undefined
    }

    return (
      <EndIconComponent
        {...iconProps}
        {...endIconComponentProps}
      />
    )
  }, [
    EndIconComponent,
    endIconComponentProps,
    iconProps,
  ])

  return (
    <Button
      {...rest}
      id={name}
      data-testid={name}
      ref={forwardedRef}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={true}
      disableRipple={true}
      onClick={onClickHandler}
      variant='contained'
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      data-title={label}
      title={maxWidth ? label : undefined}
      sx={{
        textTransform: 'initial',
        p: 0,
        px: DEFAULT_PADDING.MEDIUM,
        py: 0,
        lineHeight: '20px',
        minWidth: 'auto',
        whiteSpace: 'nowrap',

        transition: DEFAULT_BUTTON_TRANSITION,
        height: DEFAULT_BUTTON_HEIGHT,
        minHeight: DEFAULT_BUTTON_HEIGHT,
        fontSize: DEFAULT_BUTTON_FONT_SIZE,
        borderRadius: rounded ? DEFAULT_BORDER_RADIUS.SMALL : DEFAULT_BUTTON_HEIGHT,

        ...buttonStyles,

        '&.Mui-disabled': {
          cursor: 'not-allowed',
          backgroundColor: theme.palette.new.business_black_5,
          color: 'rgba(0, 0, 0, 0.20)',
        },

        '& .MuiButton-startIcon': {
          marginLeft: 0,
          marginRight: DEFAULT_PADDING.SMALL,
          ...(loading ? {
            visibility: 'hidden',
          } : {}),
        },

        '& .MuiButton-endIcon': {
          marginRight: 0,
          marginLeft: DEFAULT_PADDING.SMALL,
          ...(loading ? {
            visibility: 'hidden',
          } : {}),
        },

        ...(loading ? {
          pointerEvents: 'none',
          cursor: 'progress',
          '& .MuiButton-label': {
            visibility: 'hidden',
          },
        } : {}),

        ...sx,
      }}
    >
      <Typography
        variant='button'
        className='MuiButton-label'
        fontWeight={bold ? 500 : 400}
        noWrap={true}
        {...typographyProps}
      >
        {label}
      </Typography>

      {
        loading ? (
          <LoadingCloudComponent size='small' position='absolute' />
        ) : (
          null
        )
      }
    </Button>
  )
}

export default React.forwardRef<React.FC, ButtonComponentProps>((props, ref) => <ButtonComponent {...props} forwardedRef={ref} />)
