import React from 'react'

const S3Icon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={S3Icon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M15.1789 3.11243L12.0664 9.03741L15.1789 14.9624L16.4539 14.2499V3.82492L15.1789 3.11243Z" fill="#E25444"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.1789 3.11243L8.9539 3.82492L5.78516 9.03741L8.9539 14.2499L15.1789 14.9624V3.11243Z" fill="#7B1D13"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.61406 3.11243L1.60156 3.56243V14.5124L2.61406 14.9624L8.95154 9.03741L2.61406 3.11243Z" fill="#58150D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.60156 3.09998L8.96105 4.86663V13.3666L2.60156 14.9666V3.09998Z" fill="#E25444"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96592 5.80006L6.26562 5.36671L8.96592 2.30005L11.6595 5.36671L8.96592 5.80006Z" fill="#58150D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6595 5.36671L8.96258 5.8067L6.26562 5.36671V2.30005" fill="#58150D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96592 12.2334L6.26562 12.7334L8.96592 15.3667L11.6595 12.7334L8.96592 12.2334Z" fill="#58150D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.9539 0L6.25391 1.425V5.36248L8.9623 4.6L8.9539 0Z" fill="#7B1D13"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96258 6.76672L6.26562 7.06009V10.9915L8.96258 11.3V6.76672Z" fill="#7B1D13"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96258 13.4L6.26562 12.7238V16.5886L8.96258 18V13.4Z" fill="#7B1D13"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6579 12.7238L8.96094 13.4002V18L11.6579 16.5886V12.7238Z" fill="#E25444"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96094 6.76672L11.6579 7.06009V10.9915L8.96094 11.3V6.76672Z" fill="#E25444"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.95312 0L11.6531 1.425V5.36248L8.95312 4.61248V0Z" fill="#E25444"/>

    </svg>
  )
}

export default S3Icon
