import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { styled, InputBase } from '@mui/material'

export default styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '19px',
  },
  '&.MuiInputBase-multiline': {
    padding: '0',
  },
  '&.MuiInputBase-root': {
    borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
    position: 'relative',
    backgroundColor: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.business_black_20,
    fontSize: '16px',
    padding: '8px 10px 8px 10px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),

    '&:hover': {
      borderColor: theme.palette.new.business_black,
    },

    '&.Mui-focused:not(.Mui-error)': {
      borderColor: theme.palette.new.business_black_20,
      backgroundColor: theme.palette.new.smokey_silver,

      '& .MuiInputBase-input': {
        backgroundColor: theme.palette.new.smokey_silver,
        '&::placeholder': {
          opacity: 0,
        },
      },
    },
    '&.Mui-focused:hover': {
      borderColor: theme.palette.new.business_black,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.new.smokey_silver,
      color: theme.palette.new.business_black_20,
      textFillColor: theme.palette.new.business_black_20,
      borderColor: theme.palette.new.smokey_silver,
      '&:hover': {
        borderColor: theme.palette.new.smokey_silver,
      },
    },

    '&.Mui-error:not(.Mui-disabled)': {
      color: theme.palette.new.rebellious_red,
      borderColor: theme.palette.new.rebellious_red_40,
      '&:hover': {
        borderColor: theme.palette.new.rebellious_red,
      },
      '&.Mui-focused': {
        borderColor: theme.palette.new.rebellious_red,
      },
      '&.Mui-focused:hover': {
        borderColor: theme.palette.new.rebellious_red,
      },
      '& .MuiInputBase-input': {
        color: theme.palette.new.rebellious_red,
      },
    },

    '& .MuiInputBase-input': {
      height: '18px',
      padding: 0,
      caretColor: theme.palette.new.carret,

      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),

      '&::placeholder': {
        opacity: 0.2,
      },
    },

    '& .MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
      paddingRight: '24px',
    },
  },
}))
