import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'

import DialogTitleComponent from '@base/dialogs/DialogTitle'
import DialogActionsComponent from '@base/dialogs/DialogActions'
import DialogContentComponent from '@base/dialogs/DialogContent'
import DialogComponent from '@base/dialogs/Dialog'

import ModalButtonComponent from '@base/buttons/ModalButton'

export interface ConfirmationDialogComponentProps {
  /**
   * Dialog open state
   */
  open?: boolean,
  /**
   * Dialog title
   */
  title?: string,
  /**
   * Close button label
   */
  closeButtonLabel?: string,
  /**
   * Submit button label
   */
  submitButtonLabel?: string,
  /**
   * If submit button should be disabled
   */
  disableSubmitButton?: boolean,
  /**
   * Dialog description
   */
  description?: string | React.ReactNode,
  /**
   * Custom width for the dialog
   */
  width?: string,
  /**
   * Callback to be executed on close
   */
  onClose?: {
    (): void
  },
  /**
   * Callback to be executed on submit
   */
  onSubmit?: {
    (): void
  },
}

/**
 * This dialog is used to display a confirmation dialog.
 * The buttons aviability depends on the props passed.
 */
const ConfirmationDialogComponent: React.FC<ConfirmationDialogComponentProps> = ({
  onSubmit,
  onClose,
  open = false,
  title,
  width,
  description,
  submitButtonLabel,
  closeButtonLabel,
  disableSubmitButton,
}) => {
  const intl = useIntl()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }

    handleClose()
  }

  return (
    <DialogComponent
      open={open}
      onClose={handleClose}
      data-testid={ConfirmationDialogComponent.name}
      width={width}
    >
      <DialogTitleComponent
        title={title || intl.formatMessage({ id: 'common.modal.confirmation.title' })}
      />

      <DialogContentComponent>
        {
          typeof description === 'string' ? (
            <Typography
              component='div'
              variant='body1'
            >
              {description}
            </Typography>
          ) : (
            description
          )
        }
      </DialogContentComponent>

      <DialogActionsComponent>
        {
          onClose ? (
            <ModalButtonComponent
              name='confirmationDialogCloseButton'
              type='cancel'
              width='100%'
              onClick={handleClose}
              label={closeButtonLabel || intl.formatMessage({ id: 'common.modal.button.cancel' })}
            />
          ) : (
            null
          )
        }
        {
          onSubmit ? (
            <ModalButtonComponent
              name='confirmationDialogSubmitButton'
              onClick={handleSubmit}
              type='submit'
              width='100%'
              disabled={disableSubmitButton}
              label={submitButtonLabel || intl.formatMessage({ id: 'common.modal.button.delete' })}
            />
          ) : (
            null
          )
        }
      </DialogActionsComponent>
    </DialogComponent>
  )
}

export default ConfirmationDialogComponent
