import React from 'react'

import { Box, useTheme } from '@mui/material'

import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

export interface DataGridSkeletonComponentProps {
  minHeight?: string
}

export const DataGridSkeletonComponent: React.FC<DataGridSkeletonComponentProps> = ({
  minHeight = '400px',
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={DataGridSkeletonComponent.name}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: minHeight,
        minHeight,
        borderRadius: DEFAULT_BORDER_RADIUS.MEDIUM,
        border: `1px solid ${theme.palette.new.business_black_20}`,
        overflow: 'hidden',
      }}
    >
      <LoadingFallbackComponent fluid={true} size='medium' />
    </Box>
  )
}

export default DataGridSkeletonComponent
