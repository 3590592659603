import React from 'react'
import {
  Badge,
  BadgeProps,
  useTheme,
} from '@mui/material'

export interface BadgeComponentProps extends BadgeProps {
  /**
   * Ref to be forwarded
   */
  forwardedRef?: React.ForwardedRef<any>
}

export const BadgeComponent: React.FC<BadgeComponentProps> = ({
  forwardedRef,
  children,
  sx,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Badge
      {...props}
      data-testid={BadgeComponent.name}
      ref={forwardedRef}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: theme.palette.new.versatile_violet,
          color: theme.palette.new.white,
          fontWeight: 400,
          fontSize: '10px',
          minWidth: '14px',
          width: '14px',
          height: '14px',
          borderRadius: '30px',
          lineHeight: 1,
          padding: '0px 4px',
        },
        ...sx,
      }}
    >
      {children}
    </Badge>
  )
}

export default React.forwardRef<React.FC, BadgeComponentProps>((props, ref) => <BadgeComponent {...props} forwardedRef={ref} />)
