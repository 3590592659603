import React, { useCallback, useMemo, useState } from 'react'

import {
  Grid, SxProps, Theme,
  useTheme, GridProps,
} from '@mui/material'

import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'
import { handleExport } from '@utils/export.utils'
import { EXPORT_AS_PNG } from '@constants/export.constants'

import SectionTileHeaderComponent from './SectionTileHeader.component'
import SectionTileContentComponent from './SectionTileContent.component'

export interface SectionTileComponentProps extends Pick<GridProps, 'container' | 'item' | 'xs' | 'sm' | 'md' | 'lg'> {
  /**
   * Section name, used for tracking and exporting
   */
  name: string
  /**
   * Title of the section
   */
  title?: string
  /**
   * if true, the component will have export functionality
   */
  exportable?: boolean
  /**
   * if true, the component will have collapse/exapand functionality
   */
  collapsable?: boolean
  /**
   * if true, the component will be a grid container
   */
  container?: boolean
  /**
   * If true, the loading spinner will be shown
   */
  loading?: boolean
  /**
   * If true, the component will show no data message
   */
  noData?: boolean
  /**
   * Custom no data message
   */
  noDataMessage?: string
  /**
   * The minimum height of the content
   *
   * @default '150px'
   */
  contentMinHeight?: string
  /**
   * The padding of the content
   *
   * @default DEFAULT_PADDING.LARGE
   */
  contentPadding?: string
  /**
   * If contentType is 'data-grid', the content block will have no borders and the padding will be set to 0 and the header will have no border bottom.
   * If contentType is 'data-grid-headless', the content block will have no borders, padding will be set to 0 and the header will have border bottom.
   *
   * @default 'default'
   */
  contentType?: 'default' | 'data-grid' | 'data-grid-headless'
  /**
   * Tile color
   *
   * @default theme.palette.new.white
   */
  color?: string,
  /**
   * Children
   */
  children?: React.ReactNode,
  /**
   * Right side actions
   */
  rightSideActions?: React.ReactNode,
  /**
   * Left side actions before the title
   */
  leftSideActionsBefore?: React.ReactNode,
  /**
   * Left side actions after the title
   */
  leftSideActionsAfter?: React.ReactNode,
  /**
   * Spacing between the tiles
   * Reduced to 0 when loading or no data
   *
   * @default 2
   */
  spacing?: number,
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

const SectionTileComponent: React.FC<SectionTileComponentProps> = ({
  name,
  color,
  title,
  loading,
  noData,
  noDataMessage,
  children,
  container,
  collapsable,
  exportable,
  contentType = 'default',
  contentMinHeight = '150px',
  contentPadding = DEFAULT_PADDING.LARGE,
  rightSideActions,
  leftSideActionsBefore,
  leftSideActionsAfter,
  spacing = 2,
  ...rest
}) => {
  const theme = useTheme()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const colorToUse = color || theme.palette.new.white
  const noHeader = !title && !collapsable && !exportable && !rightSideActions && !leftSideActionsBefore && !leftSideActionsAfter

  const containerStyles = useMemo(() => {
    const baseStyles = {
      backgroundColor: colorToUse,
      borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
      border: '1px solid',
      borderColor: theme.palette.new.business_black_20,
      overflow: 'hidden',
    }

    if ((contentType === 'data-grid' || contentType === 'data-grid-headless') && !loading && !noData) {
      Object.assign(baseStyles, {
        border: 'none',
      })
    }

    return baseStyles
  }, [
    colorToUse, contentType, theme,
    loading, noData,
  ])

  const handleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const handleExportClick = useCallback(() => {
    handleExport({
      type: EXPORT_AS_PNG,
      fileName: name,
      exportId: name,
    })
  }, [name])

  return (
    <Grid
      data-testid={SectionTileComponent.name}
      id={name}
      container={true}
      {...rest}
    >
      <Grid
        item={true}
        container={true}
        xs={12}
        sx={containerStyles}
        data-testid={name}
      >
        <SectionTileHeaderComponent
          name={name}
          title={title}
          loading={loading}
          collapsable={collapsable}
          exportable={exportable}
          contentType={contentType}
          leftSideActionsBefore={leftSideActionsBefore}
          leftSideActionsAfter={leftSideActionsAfter}
          rightSideActions={rightSideActions}
          isCollapsed={isCollapsed}
          noData={noData}
          hidden={noHeader}
          handleCollapse={handleCollapse}
          handleExport={handleExportClick}
        />

        <SectionTileContentComponent
          isCollapsed={isCollapsed}
          loading={loading}
          noData={noData}
          noDataMessage={noDataMessage}
          contentMinHeight={contentMinHeight}
          contentPadding={contentPadding}
          contentType={contentType}
          container={container}
          spacing={spacing}
        >
          {children}
        </SectionTileContentComponent>
      </Grid>
    </Grid>
  )
}

export default SectionTileComponent
