import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import ButtonComponent from '@base/buttons/Button'
import ChevronLeftIcon from '@icons/chevronLeft.icon'

export interface BackToButtonComponentProps {
  /**
   * Path to navigate to
   */
  path?: string,
}

const BackToButtonComponent: React.FC<BackToButtonComponentProps> = ({
  path,
}) => {
  const intl = useIntl()
  const history = useHistory()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    if (!path) {
      return
    }

    history.push(path)
  }

  if (!path) {
    return null
  }

  return (
    <ButtonComponent
      name='backToButton'
      color='secondary'
      label={intl.formatMessage({ id: 'common.button.back' })}
      StartIconComponent={ChevronLeftIcon}
      onClick={onClickHandler}
    />
  )
}

export default BackToButtonComponent
