import { State } from '@redux/modules/types'

import {
  REQUEST_SOCRATES_PROJECTS,
  REQUEST_SOCRATES_PROJECT,
  REQUEST_TRADE_OFFS_DATA,
  CHANGE_TRADE_OFFS_CONFIGURATION,
  CALCULATE_TRADE_OFFS_DATASET,
} from './socrates.action-types'

export const showSuboptimalSolutions = (state: State) => {
  return state.socrates.tradeOffsData.configuration.SUBOPTIMAL.enabled
}

export const getProjectsList = (state: State) => {
  return state.socrates.list
}

export const getSocratesProjectItem = (state: State) => {
  return state.socrates.item
}

export const isFetchingProjects = (state: State) => {
  return state.socrates.fetchingKeys.includes(REQUEST_SOCRATES_PROJECTS) || state.socrates.fetchingKeys.includes(REQUEST_SOCRATES_PROJECT)
}

export const getProjectMeta = (state: State) => {
  return state.socrates.projectMeta
}

export const getProjectParetoSolutions = (state: State) => {
  return state.socrates.paretoSolutions
}

export const getTradeOffsData = (state: State) => {
  return state.socrates.tradeOffsData
}

export const getTradeOffsComparisonMeta = (state: State) => {
  return state.socrates.tradeOffsData.comparisonMeta
}

export const getTradeOffsDataSet = (state: State) => {
  return state.socrates.tradeOffsDataSet
}

export const getTradeOffsDataKpisOptions = (state: State) => {
  return state.socrates.tradeOffsData.kpisOptions
}

export const getTradeOffsSelectedPoints = (state: State) => {
  return state.socrates.selectedTradeOffsPoints
}

export const getTradeOffsConfiguration = (state: State) => {
  return state.socrates.tradeOffsData.configuration
}

export const isDefaultTradeOffsConfig = (state: State) => {
  return state.socrates.tradeOffsData.configuration.DEFAULT
}

export const isFetching = (state: State) => {
  return state.socrates.fetchingKeys.length > 0 &&
        (!state.socrates.fetchingKeys.includes(CHANGE_TRADE_OFFS_CONFIGURATION) && !state.socrates.fetchingKeys.includes(CALCULATE_TRADE_OFFS_DATASET))
}

export const isFetchingIntialData = (state: State) => {
  return state.socrates.fetchingKeys.includes(REQUEST_TRADE_OFFS_DATA)
}
