import React from 'react'

const DeleteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DeleteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M1 4.5H17" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 4.5V3.83333C5.5 2.94928 5.86875 2.10143 6.52513 1.47631C7.1815 0.851189 8.07174 0.5 9 0.5C9.92826 0.5 10.8185 0.851189 11.4749 1.47631C12.1313 2.10143 12.5 2.94928 12.5 3.83333V4.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 4.49997L3.39346 16.115C3.45358 16.8965 4.10524 17.5 4.88905 17.5H13.111C13.8948 17.5 14.5464 16.8965 14.6065 16.115L15.5 4.49997" stroke={detailsFill} strokeLinejoin="round"/>
      <path d="M7 7.99997L7.30769 14M11 7.99997L10.6923 14" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default DeleteIcon
