import React from 'react'

export interface SearchIconProps extends Common.IconProps {
  opacity?: string,
}

const SearchIcon: React.FC<SearchIconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  opacity = '1',
}) => {
  return (
    <svg opacity={opacity} data-testid={SearchIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M13.7296 14.4529C12.4048 15.4255 10.7695 16 9 16C4.58172 16 1 12.4183 1 8C1 3.58172 4.58172 0 9 0C13.4183 0 17 3.58172 17 8C17 10.2892 16.0385 12.3538 14.4974 13.812L17.3796 17.1746C17.5593 17.3843 17.5351 17.6999 17.3254 17.8796C17.1157 18.0593 16.8001 18.0351 16.6204 17.8254L13.7296 14.4529ZM16 8C16 11.866 12.866 15 9 15C5.13401 15 2 11.866 2 8C2 4.13401 5.13401 1 9 1C12.866 1 16 4.13401 16 8Z" fill={detailsFill}/>

    </svg>
  )
}

export default SearchIcon
