import React from 'react'
import IconButtonComponent, { IconButtonComponentProps } from '@base/buttons/IconButton/IconButton.component'

export interface TopBarButtonComponentProps extends Pick<IconButtonComponentProps,
  'name' |
  'disabled' |
  'onClick' |
  'label' |
  'forwardedRef' |
  'trackingProps' |
  'IconComponent' |
  'sx'
> {}

export const TopBarButtonComponent: React.FC<TopBarButtonComponentProps> = ({
  name,
  label,
  onClick,
  IconComponent,
  ...props
}) => {
  return (
    <IconButtonComponent
      color='tertiary'
      name={name}
      tooltip={true}
      rounded={true}
      tooltipPlacement='bottom'
      onClick={onClick}
      IconComponent={IconComponent}
      label={label}
      {...props}
    />
  )
}

export default React.forwardRef<React.FC, TopBarButtonComponentProps>((props, ref) => <TopBarButtonComponent {...props} forwardedRef={ref} />)
