import { handleActions } from 'redux-actions'
import { UseCaseState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  REDIRECTED_TO_USE_CASE,
  RECEIVE_USE_CASES,
  RECEIVE_USE_CASE,
  START_FETCHING_USE_CASES,
  STOP_FETCHING_USE_CASES,
  PREPARE_CONNECT_OVERVIEW_DONE,
  RESET_CONNECT_OVERVIEW,
  RECEIVE_ARTIFACT_MAPPINGS,
  RECEIVE_DEMAND_PROBLEM_DEFINITION,
  RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
} from './use-case.action-types'

import {
  receive,
  receiveOne,
  initialState,
  prepareConnectOverviewData,
  resetConnectOverviewData,
  receiveArtifactsMappings,
  receiveDemandProblemDefintion,
  receiveUseCaseAvailableMetaDataColumns,
  receiveUseCaseSpecifiedMetaDataColumns,
} from './use-case.reducers'

export default handleActions<UseCaseState, any>({
  [RECEIVE_USE_CASES]: receive,
  [RECEIVE_USE_CASE]: receiveOne,
  [REDIRECTED_TO_USE_CASE]: receiveOne,
  [RECEIVE_DEMAND_PROBLEM_DEFINITION]: receiveDemandProblemDefintion,
  [RECEIVE_ARTIFACT_MAPPINGS]: receiveArtifactsMappings,
  [PREPARE_CONNECT_OVERVIEW_DONE]: prepareConnectOverviewData,
  [RESET_CONNECT_OVERVIEW]: resetConnectOverviewData,
  [RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS]: receiveUseCaseAvailableMetaDataColumns,
  [RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS]: receiveUseCaseSpecifiedMetaDataColumns,
  [START_FETCHING_USE_CASES]: startFetchingAction<UseCaseState>,
  [STOP_FETCHING_USE_CASES]: stopFetchingAction<UseCaseState>,
}, initialState)
