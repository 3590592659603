import React from 'react'

const GoogleSheetsIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={GoogleSheetsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M10.875 0.666748L15 4.79175L12.9375 5.16675L10.875 4.79175L10.5 2.72925L10.875 0.666748Z" fill="#188038"/>
      <path d="M10.875 4.79163V0.666626H4.125C3.50344 0.666626 3 1.17006 3 1.79163V16.0416C3 16.6632 3.50344 17.1666 4.125 17.1666H13.875C14.4966 17.1666 15 16.6632 15 16.0416V4.79163H10.875Z" fill="#34A853"/>
      <path d="M5.25 7.04175V12.4792H12.75V7.04175H5.25ZM8.53125 11.5417H6.1875V10.2292H8.53125V11.5417ZM8.53125 9.29175H6.1875V7.97925H8.53125V9.29175ZM11.8125 11.5417H9.46875V10.2292H11.8125V11.5417ZM11.8125 9.29175H9.46875V7.97925H11.8125V9.29175Z" fill="white"/>

    </svg>
  )
}

export default GoogleSheetsIcon
