import get from 'lodash.get'
import set from 'lodash.set'
import { IntlShape } from 'react-intl'
import { checkifOAuthRequired, isOAuthDone } from '@utils/connectors.utils'

const validateAuthDataModal = (values: Hermes.ConnectionFormItem, props: any, intl: IntlShape, source: Hermes.SourceDetails) => {
  const fieldSchemas = {} as Hermes.ConfigurationFormFields

  source?.configurationForm?.forEach((block) => Object.assign(fieldSchemas, block.fields))

  const isOAuthRequired = checkifOAuthRequired(source)
  const isAuthDone = isOAuthDone(values)
  const errors = {} as any

  if (!values.name) {
    errors.name = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  if (!isAuthDone && isOAuthRequired) {
    errors['configuration.credentials.refreshToken'] = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  Object.entries(fieldSchemas).forEach(([path, data]) => {
    // TODO: dynamic validations provided by backend
    const value = get(values, `configuration.${path}`)
    if (!value && (data.required ?? true)) {
      const required = intl.formatMessage({ id: 'connectors.modal.form.required' })
      set(errors, `configuration.${path}`, required)
    }
  })

  return errors
}

export default validateAuthDataModal
