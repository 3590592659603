import React from 'react'

const ConnectIcon: React.FC<Common.IconProps> = ({
  width = 20, height = 20,
  viewBox = '0 0 20 20', fill = 'none',
  className = '', detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ConnectIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
        <path d="M5.84984 9.29443L1.4092 13.6936C1.27954 13.8222 1.17662 13.9752 1.10639 14.1438C1.03616 14.3123 1 14.4932 1 14.6758C1 14.8584 1.03616 15.0392 1.10639 15.2078C1.17662 15.3764 1.27954 15.5294 1.4092 15.658L4.34196 18.5907C4.47057 18.7204 4.62357 18.8233 4.79215 18.8935C4.96072 18.9638 5.14154 18.9999 5.32416 18.9999C5.50678 18.9999 5.6876 18.9638 5.85618 18.8935C6.02475 18.8233 6.17776 18.7204 6.30636 18.5907L10.7055 14.1501" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.1486 10.7055L18.5478 6.30636C18.6774 6.17776 18.7803 6.02475 18.8506 5.85618C18.9208 5.6876 18.957 5.50678 18.957 5.32416C18.957 5.14154 18.9208 4.96072 18.8506 4.79215C18.7803 4.62357 18.6774 4.47057 18.5478 4.34196L15.6565 1.4092C15.5279 1.27954 15.3749 1.17662 15.2063 1.10639C15.0377 1.03616 14.8569 1 14.6743 1C14.4917 1 14.3109 1.03616 14.1423 1.10639C13.9737 1.17662 13.8207 1.27954 13.6921 1.4092L9.29297 5.84984" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.7679 7.23315L7.23438 12.7667" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ConnectIcon
