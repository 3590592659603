import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, useTheme } from '@mui/material'

import ButtonComponent from '@base/buttons/Button'
import SearchFieldComponent from '@base/forms/SearchField'

export interface OptimizeOverviewHeaderComponentProps {
  setSearchTerm: (searchTerm: string) => void,
  searchTerm: string,
  handleExport: () => void,
}

const OptimizeOverviewHeaderComponent: React.FC<OptimizeOverviewHeaderComponentProps> = ({
  setSearchTerm,
  searchTerm,
  handleExport,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0, 3),
        height: theme.spacing(8),
      }}
    >
      <Typography
        variant='h4'
      >
        {intl.formatMessage({ id: 'optimize.table.restockRecommendations' })}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <SearchFieldComponent
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label={null}
          fullWidth={false}
        />
        <ButtonComponent
          name='exportButton'
          onClick={handleExport}
          disabled={false}
          color='highlighted'
          rounded={true}
          label={intl.formatMessage({ id: 'optimize.table.export' })}
        />
      </Box>
    </Box>
  )
}

export default OptimizeOverviewHeaderComponent
