import React from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'

import {
  trackEvent,
  TRACKING_ACTIONS,
  TRACKING_MODULES,
} from '@utils/tracking.utils'

import LogoIcon from '@icons/logo.icon'
import { useIntl } from 'react-intl'

export interface LogoComponentProps {
  /**
   * Function to be called when the logo is clicked
   */
  onClick?: () => void
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

const LogoComponent: React.FC<LogoComponentProps> = ({
  onClick, sx,
}) => {
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const handleLogoClick = () => {
    trackEvent({
      componentName: 'logo',
      actionName: TRACKING_ACTIONS.CLICK,
      moduleName: TRACKING_MODULES.TOP_BAR,
    }, {
      router: params,
    })

    if (onClick) {
      onClick()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      onClick={handleLogoClick}
      data-testid={LogoComponent.name}
      aria-label={intl.formatMessage({ id: 'common.logo' })}
      role='button'
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          height: '60px',
          width: '60px',
          paddingLeft: '2px',
          overflow: 'hidden',
          '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(45.03deg, #F500E1 0.04%, #FBB03B 100%)',
            transition: 'opacity 0.3s ease-out',
            zIndex: -1,
          },
          '&::after': {
            background: 'linear-gradient(45.03deg, #FBB03B 0.04%, #F500E1 100%)',
            opacity: 0,
          },
          '&:hover::after': {
            opacity: 1,
          },
          '& a': {
            height: '20px',
          },
          '& span svg': {
            width: '28px',
          },
          '& span': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Box component='span'>
          <LogoIcon
            detailsFill='white'
          />
        </Box>
      </Box>
    </Box>
  )
}
export default LogoComponent
