import React from 'react'
import SwitchBaseComponent, { SwitchBaseComponentProps } from '@base/forms/SwitchBase/SwitchBase.component'

export interface DataGridSwitchBaseComponentProps extends Omit<SwitchBaseComponentProps, 'name'> {
  forwardedRef?: any
  name?: string
}

export const DataGridSwitchBaseComponent: React.FC<DataGridSwitchBaseComponentProps> = ({
  forwardedRef,
  sx,
  name,
  id,
  ...props
}) => {
  const switchName = name || id || 'switch'

  return (
    <SwitchBaseComponent
      data-testid={DataGridSwitchBaseComponent.name}
      ref={forwardedRef}
      id={id}
      name={switchName}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridSwitchBaseComponent {...props} forwardedRef={ref} />)
