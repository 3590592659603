import get from 'lodash.get'

export const ASC_SORTING_ORDER = 'asc' as ListUtils.Direction
export const DESC_SORTING_ORDER = 'desc' as ListUtils.Direction
export const DEFAULT_SORTING_ORDER = DESC_SORTING_ORDER
export const DEFAULT_TABLE_LIMIT = 10
export const DEFAULT_SORTING_KEY = 'updatedAt'
export const CREATED_AT_SORTING_KEY = 'createdAt'
export const DEFAULT_ROWS_PER_PAGE = [10, 25, 50]

export const DEFAULT_SORTER = (data: any, key = DEFAULT_SORTING_KEY) => {
  const value = String(get(data, key, '')) || ''
  return value.toLowerCase()
}
