import React from 'react'

import palette from '@configuration/theme/theme.palette'

const InsightsIcon: React.FC<Common.IconProps> = ({
  width = 19,
  height = 18,
  viewBox = '0 0 19 18',
  fill = 'none',
  className = '',
  detailsFill = palette.new.black,
}) => {
  return (
    <svg data-testid={InsightsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path strokeOpacity="0" d="M1 8.16143H0.5V9.16143H1V8.16143ZM4.71429 8.66143V9.16143C4.89023 9.16143 5.05323 9.06895 5.14348 8.91792L4.71429 8.66143ZM7.5 4L7.98238 3.86844C7.92964 3.67505 7.76649 3.53185 7.56789 3.50463C7.36929 3.47741 7.17363 3.57144 7.0708 3.74351L7.5 4ZM10.2857 14.2143L9.80333 14.3458C9.85486 14.5348 10.012 14.6762 10.2052 14.7078C10.3985 14.7393 10.5924 14.6551 10.7013 14.4923L10.2857 14.2143ZM14 8.66143V8.16143C13.8331 8.16143 13.6772 8.24471 13.5844 8.38344L14 8.66143ZM17.7143 9.16143H18.2143V8.16143H17.7143V9.16143ZM1 9.16143H4.71429V8.16143H1V9.16143ZM5.14348 8.91792L7.9292 4.25649L7.0708 3.74351L4.28509 8.40494L5.14348 8.91792ZM7.01762 4.13156L9.80333 14.3458L10.7681 14.0827L7.98238 3.86844L7.01762 4.13156ZM10.7013 14.4923L14.4156 8.93942L13.5844 8.38344L9.87012 13.9363L10.7013 14.4923ZM14 9.16143H17.7143V8.16143H14V9.16143Z" fill={detailsFill}/>

    </svg>
  )
}

export default InsightsIcon
