import React from 'react'

const AlertSuccessIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#000000',
}) => {
  return (
    <svg data-testid={AlertSuccessIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M5.14285 10.2857L7.77857 12.3943C7.84935 12.4526 7.93224 12.4944 8.0212 12.5166C8.11016 12.5388 8.20296 12.541 8.29285 12.5229C8.3836 12.5058 8.46962 12.4695 8.54505 12.4162C8.62049 12.363 8.68357 12.2941 8.73 12.2143L12.8571 5.14285M17.3571 9C17.3571 13.6155 13.6155 17.3571 9 17.3571C4.38447 17.3571 0.642853 13.6155 0.642853 9C0.642853 4.38447 4.38447 0.642853 9 0.642853C13.6155 0.642853 17.3571 4.38447 17.3571 9Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertSuccessIcon
