import {
  takeEvery, call,
  put, select, take,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { getModalDetails } from '@redux/modules/modal-manager/modal-manager.selectors'

import { ActionPayload, State } from '@redux/modules/types'

import {
  REQUEST_PARAMETERS_TEMPLATES,
  REQUEST_PARAMETER_TEMPLATE,

  CREATE_PARAMETERS_TEMPLATES,
  UPDATE_PARAMETERS_TEMPLATES,
  DELETE_PARAMETERS_TEMPLATES,
  RECEIVE_PARAMETERS_TEMPLATES,
} from './parameters-templates.action-types'

import {
  FetchPayload,
  CreatePayload,
  UpdatePayload,
  RemovePayload,
} from './parameters-templates.types'

import {
  receiveParameterTemplateActionDone,
  receiveParametersTemplatesActionDone,
  startFetchingParametersTemplatesAction,
  stopFetchingParametersTemplatesAction,
  requestParametersTemplatesAction,
} from './parameters-templates.actions'

import * as API from './parameters-templates.api'

const CREATE_PARAMETER_SUCCESS = 'connect.modal.predifined_parameters.success.create'
const UPDATE_PARAMETER_SUCCESS = 'connect.modal.predifined_parameters.success.update'
const DELETE_PARAMETER_SUCCESS = 'connect.modal.predifined_parameters.success.delete'

function* fetchParameterTemplateGenerator({ payload } : ActionPayload<FetchPayload>) {
  try {
    yield put(startFetchingParametersTemplatesAction(REQUEST_PARAMETER_TEMPLATE))

    const item: UseCase.ParameterTemplateItem = yield call(API.fetch, payload)

    yield put(receiveParameterTemplateActionDone(item))

    yield put(stopFetchingParametersTemplatesAction(REQUEST_PARAMETER_TEMPLATE))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParametersTemplatesAction(REQUEST_PARAMETER_TEMPLATE))
  }
}

function* fetchParametersTemplatesGenerator() {
  try {
    yield put(startFetchingParametersTemplatesAction(REQUEST_PARAMETERS_TEMPLATES))

    const list: UseCase.ParameterTemplateItem[] = yield call(API.list)

    yield put(receiveParametersTemplatesActionDone(list))

    yield put(stopFetchingParametersTemplatesAction(REQUEST_PARAMETERS_TEMPLATES))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, {})
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParametersTemplatesAction(REQUEST_PARAMETERS_TEMPLATES))
  }
}

function* createParametersTemplatesGenerator({ payload } : ActionPayload<CreatePayload>) {
  try {
    yield put(startFetchingParametersTemplatesAction(CREATE_PARAMETERS_TEMPLATES))

    const state: State = yield select()
    const modalDetails: Common.ModalDetails = yield call(getModalDetails, state)
    yield call(API.create, payload)

    yield put(requestParametersTemplatesAction())

    yield take(RECEIVE_PARAMETERS_TEMPLATES)

    yield put(setPrimaryModalPageName({
      primaryModalPage: modalDetails.returnTo,
      modalDetails: {
        returnTo: '',
      },
    }))

    yield put(changeToastAction({ useIntl: true, message: CREATE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParametersTemplatesAction(CREATE_PARAMETERS_TEMPLATES))
  }
}

function* updateParametersTemplatesGenerator({ payload } : ActionPayload<UpdatePayload>) {
  try {
    yield put(startFetchingParametersTemplatesAction(UPDATE_PARAMETERS_TEMPLATES))

    const state: State = yield select()
    const modalDetails: Common.ModalDetails = yield call(getModalDetails, state)
    yield call(API.update, payload)

    yield put(requestParametersTemplatesAction())

    yield take(RECEIVE_PARAMETERS_TEMPLATES)

    yield put(setPrimaryModalPageName({
      primaryModalPage: modalDetails.returnTo,
      modalDetails: {
        returnTo: '',
      },
    }))

    yield put(changeToastAction({ useIntl: true, message: UPDATE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParametersTemplatesAction(UPDATE_PARAMETERS_TEMPLATES))
  }
}

function* deleteParametersTemplatesGenerator({ payload } : ActionPayload<RemovePayload>) {
  try {
    yield put(startFetchingParametersTemplatesAction(DELETE_PARAMETERS_TEMPLATES))

    yield call(API.remove, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParametersTemplatesAction(DELETE_PARAMETERS_TEMPLATES))
  }
}

export function* watchFetchParameterTemplate() {
  yield takeEvery(REQUEST_PARAMETER_TEMPLATE, fetchParameterTemplateGenerator)
}

export function* watchFetchParametersTemplates() {
  yield takeEvery(REQUEST_PARAMETERS_TEMPLATES, fetchParametersTemplatesGenerator)
}

export function* watchCreateParametersTemplates() {
  yield takeEvery(CREATE_PARAMETERS_TEMPLATES, createParametersTemplatesGenerator)
}

export function* watchUpdateParametersTemplates() {
  yield takeEvery(UPDATE_PARAMETERS_TEMPLATES, updateParametersTemplatesGenerator)
}

export function* watchDeleteParametersTemplates() {
  yield takeEvery(DELETE_PARAMETERS_TEMPLATES, deleteParametersTemplatesGenerator)
}
