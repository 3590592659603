import React, { useMemo } from 'react'
import { Box, useTheme, Typography } from '@mui/material'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export interface ChartTooltipLegendItemComponentProps {
  /**
   * Type of the legend item
   */
  type?: 'line' | 'square',
  /**
   * Color of the legend item
   */
  color?: string,
  /**
   * Label of the legend item
   */
  label?: string,
}

export const ChartTooltipLegendItemComponent: React.FC<ChartTooltipLegendItemComponentProps> = ({
  type,
  label,
  color,
}) => {
  const gradientLegend = color?.includes('linear-gradient')

  const theme = useTheme()

  const legendItem = useMemo(() => {
    if (!type) {
      return null
    }

    if (type === 'square') {
      return (
        <Box
          component='span'
          sx={{
            display: 'inline-block',
            marginRight: theme.spacing(1),
            width: '16px',
            height: '16px',
            background: color,
            borderRadius: '2px',
          }}
          data-testid={type}
        />
      )
    }

    return (
      <Box
        component='span'
        sx={{
          display: 'inline-block',
          marginRight: theme.spacing(1),
          width: '16px',
          borderRadius: theme.spacing(0.5),
          ...(gradientLegend ? {
            height: '4px',
            border: 'none',
            background: color,
          } : {
            height: '1px',
            border: '2px solid',
            borderColor: color,
          }),
        }}
        data-testid={type}
      />
    )
  }, [type, color, gradientLegend, theme])

  const containerStyles = color ? {
    ...(gradientLegend ? {
      ...getTextBackgroundFillStyles(color),
      backgroundImage: color,
    } : {
      color,
    }),
  } : {}

  if (!color && !label) {
    return null
  }

  return (
    <Typography
      display='flex'
      alignItems='center'
      fontWeight={500}
      variant='body1'
      pr={4}
      sx={containerStyles}
    >
      {legendItem}

      {label}
    </Typography>
  )
}

export default ChartTooltipLegendItemComponent
