export const REQUEST_TRAINING_DATA_FLAT_LIST = 'REQUEST_TRAINING_DATA_FLAT_LIST'
export const RECEIVE_TRAINING_DATA_FLAT_LIST = 'RECEIVE_TRAINING_DATA_FLAT_LIST'

export const REQUEST_TRAINING_DATA_LIST = 'REQUEST_TRAINING_DATA_LIST'
export const RECEIVE_TRAINING_DATA_LIST = 'RECEIVE_TRAINING_DATA_LIST'

export const REQUEST_FILE_IDENTIFIERS_LIST = 'REQUEST_FILE_IDENTIFIERS_LIST'
export const RECEIVE_FILE_IDENTIFIERS_LIST = 'RECEIVE_FILE_IDENTIFIERS_LIST'

export const CREATE_FILE_IDENTIFIER = 'CREATE_FILE_IDENTIFIER'
export const REMOVE_FILE_IDENTIFIER = 'REMOVE_FILE_IDENTIFIER'

export const REMOVE_TRAINING_DATA = 'REMOVE_TRAINING_DATA'
export const UPLOAD_TRAINING_DATA = 'UPLOAD_TRAINING_DATA'
export const DOWNLOAD_TRAINING_DATA = 'DOWNLOAD_TRAINING_DATA'

export const CREATE_FILE_IDENTIFIER_VERSION = 'CREATE_FILE_IDENTIFIER_VERSION'
export const REMOVE_FILE_IDENTIFIER_VERSION = 'REMOVE_FILE_IDENTIFIER_VERSION'

export const START_FETCHING_TRAINING_FILES = 'START_FETCHING_TRAINING_FILES'
export const STOP_FETCHING_TRAINING_FILES = 'STOP_FETCHING_TRAINING_FILES'

export const TRAINING_DATA_AUTO_REFRESH_START = 'TRAINING_DATA_AUTO_REFRESH_START'
export const TRAINING_DATA_AUTO_REFRESH_STOP = 'TRAINING_DATA_AUTO_REFRESH_STOP'
