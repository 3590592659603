import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'

import {
  Box, SxProps,
  Theme, Typography, useTheme,
} from '@mui/material'

export interface TableEmptyOverlayComponentProps {
  labels?: string[]
  sx?: SxProps<Theme>
}

export const DataGridTableEmptyOverlayComponent: React.FC<TableEmptyOverlayComponentProps> = ({
  labels = [],
  sx,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const labelsToUse = useMemo(() => {
    if (labels.length > 0) {
      return labels
    }

    return [intl.formatMessage({ id: 'common.no_data' })]
  }, [labels, intl])

  return (
    <Box
      data-testid={DataGridTableEmptyOverlayComponent.name}
      height='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      width='100%'
      sx={sx}
    >
      <Box
        width='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        gap={1}
      >
        {
          labelsToUse.map((label, index) => {
            return (
              <Typography
                key={index}
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  maxWidth: '400px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  opacity: 0.4,
                  color: theme.palette.new.black,
                }}
              >
                {label}
              </Typography>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default DataGridTableEmptyOverlayComponent
