import React from 'react'
import { Chip } from '@mui/material'
import TooltipComponent from '@base/tooltips/Tooltip'

export interface DataGridChipComponentProps {
  getBgColorByValue?: (value: string) => string | undefined
  getColorByValue?: (value: string) => string | undefined
  tooltip?: React.ReactNode
  uppercase?: boolean
  value: string
}

export const DataGridChipComponent: React.FC<DataGridChipComponentProps> = ({
  getColorByValue,
  getBgColorByValue,
  value,
  tooltip,
  uppercase,
}) => {
  const bgColor = getBgColorByValue ? getBgColorByValue(value) : undefined
  const color = getColorByValue ? getColorByValue(value) : undefined

  if (!value) {
    return null
  }

  const content = (
    <Chip
      data-testid={DataGridChipComponent.name}
      size='small'
      label={value}
      sx={{
        cursor: tooltip ? 'pointer' : 'default',
        backgroundColor: bgColor,
        color,
        fontWeight: 500,
        borderRadius: '4px',
        textTransform: uppercase ? 'uppercase' : 'none',
      }}
    />
  )

  if (tooltip) {
    return (
      <TooltipComponent
        title={tooltip}
        placement='top'
        describeChild={true}
      >
        {content}
      </TooltipComponent>
    )
  }

  return content
}

export default DataGridChipComponent
