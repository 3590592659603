import React from 'react'

const ParetosSftpWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={ParetosSftpWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M16.1923 6.5H1.80769C1.08547 6.5 0.5 7.03726 0.5 7.7V11.3C0.5 11.9627 1.08547 12.5 1.80769 12.5H16.1923C16.9145 12.5 17.5 11.9627 17.5 11.3V7.7C17.5 7.03726 16.9145 6.5 16.1923 6.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.0346 8.53564C3.84388 8.53564 3.65745 8.5922 3.49887 8.69816C3.34029 8.80411 3.2167 8.95471 3.14371 9.13091C3.07073 9.30711 3.05163 9.501 3.08884 9.68805C3.12605 9.87511 3.21789 10.0469 3.35275 10.1818C3.4876 10.3166 3.65942 10.4085 3.84648 10.4457C4.03353 10.4829 4.22741 10.4638 4.40361 10.3908C4.57981 10.3178 4.73042 10.1942 4.83637 10.0357C4.94233 9.87708 4.99888 9.69065 4.99888 9.49993C4.99555 9.24522 4.89289 9.00188 4.71277 8.82176C4.53265 8.64164 4.28931 8.53897 4.0346 8.53564Z" fill="white"/>
      <path d="M7.71484 9.5H10.2863" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.85938 12.5L8.85938 17.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 17.5H15" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0773 0C12.046 1.2169 11.0403 2.33429 9.74076 2.31534C8.44123 2.33429 7.43365 1.21596 7.40427 0H4.5V3.7597H5.99384V0.320338C6.15687 2.2471 7.77109 3.7597 9.73981 3.7597C11.8166 3.7597 13.5 2.07651 13.5 0H12.0763H12.0773Z" fill="white"/>

    </svg>
  )
}

export default ParetosSftpWhiteIcon
