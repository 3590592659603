import React from 'react'
import {
  styled, Box,
  IconButton, useTheme,
} from '@mui/material'

import ChevronIcon from '@icons/flow/chevron.icon'

export const TableIconButton = styled(IconButton)(({ theme }) => ({
  padding: '9px',
  borderRadius: '100%',

  '&.Mui-disabled': {
    background: theme.palette.new.grey,

    '& svg': {
      opacity: 0.2,
    },
  },
}))

export interface TablePaginationActionsProps {
  count: number,
  onPageChange: (event: any, page: number) => void,
  page: number,
  rowsPerPage: number,
}

const TablePaginationActionsComponent: React.FC<TablePaginationActionsProps> = ({
  count,
  onPageChange,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme()

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box
      data-testid={TablePaginationActionsComponent.name}
      sx={{
        flexShrink: 0,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),

        '& .buttonRoot:first-of-type': {
          marginRight: theme.spacing(2),
        },
      }}
    >
      <TableIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        className='buttonRoot'
        size='large'
        sx={{
          '& svg': {
            transform: 'rotate(90deg)',
          },
        }}
      >
        <ChevronIcon />
      </TableIconButton>
      <TableIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        className='buttonRoot'
        size='large'
        sx={{
          '& svg': {
            transform: 'rotate(-90deg)',
          },
        }}
      >
        <ChevronIcon />
      </TableIconButton>
    </Box>
  )
}

export default TablePaginationActionsComponent
