import React from 'react'
import { useIntl } from 'react-intl'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import useUploadEmitter from '@hooks/useUploadEmitter.hook'

import { Box, useTheme } from '@mui/material'

export interface DataGridLoadingOverlayComponentProps {
  actionsMenuWidth?: number
  uploading?: boolean
}

export const DataGridLoadingOverlayComponent: React.FC<DataGridLoadingOverlayComponentProps> = ({
  actionsMenuWidth = 0,
  uploading,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const { fileName, progress } = useUploadEmitter({ uploading })

  const linearProgressSx = {
    width: '100%',
    height: '3px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.new.business_black_20,
    },
    [`& .${linearProgressClasses.barColorPrimary}`]: {
      animationDuration: '2s',
      background: 'linear-gradient(90deg, #F500E1 0%, #F500E1 0.01%, #FBB03B 100%)',
    },
  }

  if (uploading) {
    return (
      <Box
        data-testid={DataGridLoadingOverlayComponent.name}
      >
        <LinearProgress
          color='primary'
          sx={linearProgressSx}
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '8px',
            fontSize: '16px',
            color: theme.palette.new.black,
            mt: 0,
          }}
        >
          {intl.formatMessage({ id: 'common.upload.message' }, { name: fileName, progress })}
        </Box>
      </Box>
    )
  }

  return (
    <LinearProgress
      data-testid={DataGridLoadingOverlayComponent.name}
      color='primary'
      sx={linearProgressSx}
    />
  )
}

export default DataGridLoadingOverlayComponent
