import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box, Typography, useTheme,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import DeleteIcon from '@icons/delete.icon'
import ButtonComponent from '@base/buttons/Button'
import TextFieldComponent from '@base/forms/TextField'
import ConfirmationDialogComponent from '@base/dialogs/ConfirmationDialog'
import WarningTileComponent from '@base/tiles/WarningTile'

import { UpdatePipelineDeliveryScheduleActionPayload } from '@redux/modules/pipelines/pipelines.types'
import { updatePipelineDeliveryScheduleAction, deletePipelineDeliveryScheduleAction, updatePipelineExecutionScheduleAction } from '@redux/modules/pipelines/pipelines.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME, PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME } from '@constants/modals.constants'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import {
  cronToReadableValue, getCronIntervals,
  isCronWithinDST, isValidCron,
} from '@utils/pipelines.utils'

export interface PipelineScheduleConfigurationModalDetails extends Common.ModalDetails {
  pipeline: Pipelines.ReduxPipelineItem
}

const PipelineScheduleConfigurationModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [removeDeliveryScheduleDialogState, setRemoveDeliveryScheduleDialogState] = useState(false)

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isSubmitting = useSelector((state) => isSubmittingPipeline(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const { pipeline, returnTo } = useSelector((state) => getModalDetails<PipelineScheduleConfigurationModalDetails>(state))

  const open = [PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME, PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME].includes(modalPageName!)
  const isDeliveryScheduleEdit = !!pipeline?.deliverySchedule && modalPageName === PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME
  const isExecutionScheduleModal = modalPageName === PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME

  const pipelineId = pipeline?.pipelineId || ''
  const pipelineName = pipeline?.name || ''
  const pipelineSchedule = (isExecutionScheduleModal ? pipeline?.executionSchedule : pipeline?.deliverySchedule) || '* * * * *'

  const modalTitle = isExecutionScheduleModal ? (
    intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.executionTitle' })
  ) : (
    intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.deliveryTitle' })
  )

  const initialValues: UpdatePipelineDeliveryScheduleActionPayload = {
    pipelineId,
    schedule: pipelineSchedule,
  } as any

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName({
        primaryModalPage: returnTo || '',
        modalDetails: {
          returnTo: '',
          pipeline,
        } as PipelineScheduleConfigurationModalDetails,
      }))
    }
  }

  const handleSubmitAction = (values: UpdatePipelineDeliveryScheduleActionPayload) => {
    if (isExecutionScheduleModal) {
      dispatch(
        updatePipelineExecutionScheduleAction({
          pipelineId,
          executionSchedule: values.schedule,
        }),
      )
    } else {
      dispatch(
        updatePipelineDeliveryScheduleAction({
          pipelineId,
          schedule: values.schedule,
        }),
      )
    }
  }

  const handleRemoveDeliveryScheduleAction = () => {
    if (isExecutionScheduleModal) {
      return
    }

    dispatch(
      deletePipelineDeliveryScheduleAction({ pipelineId }),
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    validate: (fromValues) => {
      const errorsList: any = {}

      if (!isValidCron(fromValues.schedule)) {
        errorsList.schedule = intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.schedule.invalid' })
      }

      return errorsList
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const nextRuns = useMemo(() => {
    return getCronIntervals(values.schedule)
  }, [values.schedule])

  const parsedCron = useMemo(() => {
    return cronToReadableValue(intl, values.schedule)
  }, [intl, values.schedule])

  const isWithInDST = useMemo(() => {
    return isCronWithinDST(values.schedule)
  }, [values.schedule])

  return (
    <>
      <SidePanelComponent
        open={open && isAdmin}
        title={modalTitle}
        handleClose={handleCloseAction}
        hasUnsavedChanges={dirty || isSubmitting}
      >
        <SidePanelLoadingComponent loading={false}>
          <Box component='form' onSubmit={handleSubmit}>
            <SidePanelCardComponent>
              <FormLayoutContainer>
                <FormLayoutItemsContainer>
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='pipelineName'
                      value={pipelineName}
                      label={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.pipelineName' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='schedule'
                      value={values.schedule}
                      errors={errors.schedule}
                      touched={touched.schedule}
                      label={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.schedule' })}
                      floatingHelp={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.cron' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={12}
                    hidden={!parsedCron}
                  >
                    <Typography
                      variant='body2'
                      color={theme.palette.new.versatile_violet}
                    >
                      {parsedCron}
                    </Typography>
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={12}
                    hidden={!isWithInDST}
                  >
                    <WarningTileComponent
                      text={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.dstWarning' })}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.nextRuns' })}
                  hidden={nextRuns.length === 0}
                >
                  <FormLayoutItem xs={12}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap={1}
                      width='100%'
                    >
                      {
                        nextRuns.map((run, index) => (
                          <Typography
                            key={index}
                            variant='body1'
                            sx={{
                              whiteSpace: 'pre-wrap',
                              padding: 1,
                              backgroundColor: theme.palette.new.grey_d,
                              borderRadius: '4px',
                            }}
                          >
                            {run}
                          </Typography>
                        ))
                      }
                    </Box>
                  </FormLayoutItem>
                </FormLayoutItemsContainer>
              </FormLayoutContainer>

              {
                isDeliveryScheduleEdit ? (
                  <ButtonComponent
                    color='tertiary'
                    name='removeDeliveryScheduleButton'
                    StartIconComponent={DeleteIcon}
                    label={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.remove.button' })}
                    onClick={() => setRemoveDeliveryScheduleDialogState(true)}
                    disabled={isSubmitting}
                    sx={{
                      mt: 2,
                    }}
                  />
                ) : (
                  null
                )
              }

            </SidePanelCardComponent>

            <SidePanelCardActionsComponent>
              <ModalButtonComponent
                name='scheduleConfigurationModalBackButton'
                onClick={() => handleCloseAction()}
                type='cancel'
              />

              <ModalButtonComponent
                name='scheduleConfigurationModalSubmitButton'
                onClick={(e) => handleSubmitAction(values)}
                loading={isSubmitting}
                disabled={isSubmitting || !dirty || !isValid}
                type='submit'
              />
            </SidePanelCardActionsComponent>
          </Box>
        </SidePanelLoadingComponent>
      </SidePanelComponent>

      <ConfirmationDialogComponent
        open={removeDeliveryScheduleDialogState}
        onClose={() => setRemoveDeliveryScheduleDialogState(false)}
        onSubmit={handleRemoveDeliveryScheduleAction}
        title={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.remove.title' })}
        description={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.remove.description' })}
        closeButtonLabel={intl.formatMessage({ id: 'common.modal.button.cancel' })}
        submitButtonLabel={intl.formatMessage({ id: 'common.modal.button.delete' })}
      />
    </>
  )
}

export default PipelineScheduleConfigurationModalContainer
