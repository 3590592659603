import { Moment } from 'moment'

export enum PROMOTION_DAYS {
  /** Static holidays */
  VALENTINES_DAY = 'VALENTINES_DAY',
  WOMENS_DAY = 'WOMENS_DAY',
  EARTH_DAY = 'EARTH_DAY',
  HALLOWEEN = 'HALLOWEEN',
  SINGLES_DAY = 'SINGLES_DAY',
  NIKOLAUS = 'NIKOLAUS',
  CHRISTMAS = 'CHRISTMAS',

  /** Moveable holidays */
  EASTER = 'EASTER',
  MOTHERS_DAY = 'MOTHERS_DAY',
  FATHERS_DAY = 'FATHERS_DAY',
  PRIME_DEAL_DAYS = 'PRIME_DEAL_DAYS',
  BLACK_WEEK = 'BLACK_WEEK',
  CYBER_MONDAY = 'CYBER_MONDAY',
  SUPER_BOWL = 'SUPER_BOWL',
}

export interface PromotionItem {
  labelKey: string
  from: Moment
  to: Moment
}

export const PROMOTION_DAYS_LIST = Object.keys(PROMOTION_DAYS) as PROMOTION_DAYS[]

export const PROMOTION_DATES_TO_LABELS_MAP: { [key in PROMOTION_DAYS]: string } = {
  [PROMOTION_DAYS.VALENTINES_DAY]: 'common.promotions.valentines_day',
  [PROMOTION_DAYS.WOMENS_DAY]: 'common.promotions.womens_day',
  [PROMOTION_DAYS.EARTH_DAY]: 'common.promotions.earth_day',
  [PROMOTION_DAYS.HALLOWEEN]: 'common.promotions.halloween',
  [PROMOTION_DAYS.SINGLES_DAY]: 'common.promotions.singles_day',
  [PROMOTION_DAYS.NIKOLAUS]: 'common.promotions.nikolaus',
  [PROMOTION_DAYS.CHRISTMAS]: 'common.promotions.christmas',
  [PROMOTION_DAYS.EASTER]: 'common.promotions.easter',
  [PROMOTION_DAYS.MOTHERS_DAY]: 'common.promotions.mothers_day',
  [PROMOTION_DAYS.FATHERS_DAY]: 'common.promotions.fathers_day',
  [PROMOTION_DAYS.PRIME_DEAL_DAYS]: 'common.promotions.prime_day',
  [PROMOTION_DAYS.BLACK_WEEK]: 'common.promotions.black_week',
  [PROMOTION_DAYS.CYBER_MONDAY]: 'common.promotions.cyber_monday',
  [PROMOTION_DAYS.SUPER_BOWL]: 'common.promotions.super_bowl',
}
