import produce from 'immer'
import { TrainingFilesState, ReducerPayload } from '@redux/modules/types'
import { LEGACY_FILES_IDENTIFIER } from '@constants/files.constants'

export const initialState: TrainingFilesState = {
  fetchingKeys: [],
  trainingDataFlatList: [],
  trainingDataList: [],
  fileIdentifiers: [],
}

export const receiveTrainingDataFlatList = (state: TrainingFilesState, action: ReducerPayload<TrainingFiles.TrainingFileItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.trainingDataFlatList = action.payload
  })

  return nextState
}

export const receiveTrainingDataList = (state: TrainingFilesState, action: ReducerPayload<TrainingFiles.TrainingFileItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.trainingDataList = action.payload
  })

  return nextState
}

export const receiveFileIdentifiersList = (state: TrainingFilesState, action: ReducerPayload<TrainingFiles.FileIdentifierItem[]>) => {
  const nextState = produce(state, (draftState) => {
    const sortedList = action.payload.map((identifier) => {
      const latestAvailableVersion = Math.max(...identifier.versions.map((version) => version.versionNumber), 1)
      const sortedVersion = identifier.versions.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime()
        const dateB = new Date(b.createdAt).getTime()

        return dateA < dateB ? 1 : -1
      })

      return {
        value: identifier.fileIdentifier,
        label: identifier.fileIdentifier,
        versions: sortedVersion,
        latestVersion: String(latestAvailableVersion),
        createdAt: identifier.createdAt,
        updatedAt: identifier.updatedAt,
      }
    }).sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime()
      const dateB = new Date(b.updatedAt).getTime()

      return dateA < dateB ? 1 : -1
    }) as TrainingFiles.FileIdentifierOption[]

    draftState.fileIdentifiers = [
      ...sortedList,
      {
        value: LEGACY_FILES_IDENTIFIER,
        label: LEGACY_FILES_IDENTIFIER,
        versions: [],
        latestVersion: 'upload',
        createdAt: '',
        updatedAt: '',
      },
    ]
  })

  return nextState
}
