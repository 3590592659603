import React, {
  useCallback, useEffect,
  useMemo, useRef,
} from 'react'

import {
  Box, FormControlLabel,
  Popover, PopoverProps,
  useTheme,
} from '@mui/material'

import {
  useGridApiContext,
  useGridSelector,
  useGridRootProps,
  gridColumnDefinitionsSelector,
  GridColumnsManagementProps,
  GridColDef,
  GridPanelWrapper,
  GridPanelHeader,
  GridPanelContent,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  DataGridPremiumProps,
  GridRowGroupingModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCallbackDetails,
  GRID_CHECKBOX_SELECTION_FIELD,
} from '@mui/x-data-grid-premium'

import DataGridSwitchBaseComponent from '@base/datagrid/data-grid-switch-base'
import { DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'

const collator = new Intl.Collator()

const defaultSearchPredicate: NonNullable<GridColumnsManagementProps['searchPredicate']> = (
  column,
  searchValue,
) => {
  return (column.headerName || column.field).toLowerCase().indexOf(searchValue) > -1
}

export interface DataGridGroupingPanelComponentProps extends PopoverProps {
  disabled?: boolean
  sort?: 'asc' | 'desc'
  autoFocusSearchField?: boolean
  onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
  groupingMode?: 'server' | 'client'
  groupingModel: GridRowGroupingModel
}

export const DataGridGroupingPanelComponent: React.FC<DataGridGroupingPanelComponentProps> = ({
  sort, disabled = false, autoFocusSearchField = true, groupingMode = 'client',
  onRowGroupingModelChange, groupingModel,
  id, open, anchorEl, onClose, ...other
}) => {
  const theme = useTheme()
  const apiRef = useGridApiContext()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector)
  const rootProps = useGridRootProps()
  const [searchValue, setSearchValue] = React.useState('')

  const sortedColumns = useMemo(() => {
    switch (sort) {
      case 'asc':
        return [...columns].sort((a, b) => collator.compare(a.headerName || a.field, b.headerName || b.field))

      case 'desc':
        return [...columns].sort((a, b) => -collator.compare(a.headerName || a.field, b.headerName || b.field))

      default:
        return columns
    }
  }, [columns, sort])

  const handleSearchValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [],
  )

  const getGroupableColumns = useCallback((cols: GridColDef[]) => {
    return cols.filter((column) => {
      return (
        ![
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD, GRID_CHECKBOX_SELECTION_FIELD,
          GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        ].includes(column.field)
      ) && (
        column.type !== GRID_ACTIONS_COLUMN_TYPE
      )
    }).map((column) => column.field)
  }, [])

  const currentColumns = useMemo(() => {
    const togglableColumns = getGroupableColumns ? getGroupableColumns(sortedColumns) : null

    const togglableSortedColumns = togglableColumns ? (
      sortedColumns.filter(({ field }) => togglableColumns.includes(field))
    ) : sortedColumns

    if (!searchValue) {
      return togglableSortedColumns
    }

    return togglableSortedColumns.filter((column) => defaultSearchPredicate(column, searchValue.toLowerCase()))
  }, [sortedColumns, searchValue, getGroupableColumns])

  const groupColumn = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name: field } = event.target as HTMLInputElement

    if (groupingMode === 'server' && onRowGroupingModelChange && groupingModel) {
      const newGroupModel = (
        groupingModel.includes(field) ?
          groupingModel.filter((group) => group !== field) :
          [...groupingModel, field]
      ).filter((group) => group !== null && group !== undefined)

      onRowGroupingModelChange(newGroupModel, {} as GridCallbackDetails)

      return
    }

    if (groupingModel.includes(field)) {
      apiRef.current.removeRowGroupingCriteria(field)
    } else {
      apiRef.current.addRowGroupingCriteria(field)
    }
  }

  const firstSwitchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (autoFocusSearchField) {
          searchInputRef?.current?.focus()
        } else if (firstSwitchRef.current && typeof firstSwitchRef.current.focus === 'function') {
          firstSwitchRef?.current?.focus()
        }
      }, 100)
    }
  }, [open, searchInputRef, autoFocusSearchField])

  let firstGroupableColumnFound = false
  const isFirstGroupableColumn = (column: GridColDef) => {
    if (firstGroupableColumnFound === false && column.groupable !== false) {
      firstGroupableColumnFound = true
      return true
    }
    return false
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          border: `1px solid ${theme.palette.new.business_black_20}`,
          borderTop: 'none',
          boxShadow: DEFAULT_SELECT_BOX_SHADOW,
          borderRadius: '0px 0px 3px 3px',
          marginLeft: '-1px',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...other}
    >
      <GridPanelWrapper>
        <GridPanelHeader>
          <rootProps.slots.baseTextField
            inputRef={searchInputRef}
            value={searchValue}
            type='search'
            onChange={handleSearchValueChange}
            variant='standard'
            fullWidth={true}
            placeholder={apiRef.current.getLocaleText('columnsManagementSearchTitle')}
            InputProps={{
              startAdornment: (
                <rootProps.slots.baseInputAdornment position='start'>
                  <rootProps.slots.quickFilterIcon />
                </rootProps.slots.baseInputAdornment>
              ),
              sx: { pl: 1.5 },
            }}
            {...rootProps.slotProps?.baseTextField}
          />
        </GridPanelHeader>
        <GridPanelContent>
          <Box
            sx={{
              padding: '0px 15px',
              minWidth: '300px',
            }}
          >
            {
              currentColumns.map((column) => {
                return (
                  <Box
                    key={column.field}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0px 10px',
                      '& .MuiSwitch-root': {
                        marginRight: '10px',
                      },
                    }}
                  >
                    <FormControlLabel
                      control={(
                        <DataGridSwitchBaseComponent
                          disabled={column.groupable === false}
                          checked={groupingModel.includes(column.field)}
                          onClick={groupColumn}
                          name={column.field}
                          size='small'
                          inputRef={isFirstGroupableColumn(column) ? firstSwitchRef : undefined}
                          {...rootProps.slotProps?.baseSwitch}
                        />
                      )}
                      label={column.headerName || column.field}
                    />
                  </Box>
                )
              })
            }
          </Box>
        </GridPanelContent>
      </GridPanelWrapper>
    </Popover>
  )
}

export default DataGridGroupingPanelComponent
