import React from 'react'

import {
  TextField,
  TextFieldProps,
} from '@mui/material'

import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

export interface DataGridBaseTextFieldComponentProps extends TextFieldProps<'outlined'> {
  forwardedRef?: any
}

export const DataGridBaseTextFieldComponent: React.FC<DataGridBaseTextFieldComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  return (
    <TextField
      data-testid={DataGridBaseTextFieldComponent.name}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridBaseTextFieldComponent variant='outlined' {...props} forwardedRef={ref} />)
