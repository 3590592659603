import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import { GridActionsCellItem, GridActionsCellItemProps, GridRowId } from '@mui/x-data-grid-premium'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

export interface DataGridActionButtonComponentProps {
  icon: GridActionsCellItemProps['icon']
  label: GridActionsCellItemProps['label']
  onClick: GridActionsCellItemProps['onClick']
  name: string
  showInMenu?: GridActionsCellItemProps['showInMenu']
  tooltip?: boolean
  disabled?: boolean
  id: GridRowId
}

export const DataGridActionButtonComponent: React.FC<DataGridActionButtonComponentProps> = ({
  id,
  icon,
  label,
  tooltip = true,
  name,
  disabled,
  onClick,
  showInMenu,
}) => {
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      id,
      router: params,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const content = (
    <GridActionsCellItem
      icon={icon}
      label={label}
      onClick={onClickHandler}
      showInMenu={showInMenu as any}
      data-testid={name}
      disabled={disabled}
      sx={{
        opacity: disabled ? '0.2' : '1',
        '&.Mui-disabled': {
          pointerEvents: 'auto',
        },
      }}
    />
  )

  return !showInMenu && tooltip ? (
    <TooltipComponent
      title={label}
      placement='top'
      describeChild={true}
    >
      <Box component='span'>
        {content}
      </Box>
    </TooltipComponent>
  ) : (
    content
  )
}

export default DataGridActionButtonComponent
