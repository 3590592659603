import React, {
  useMemo,
} from 'react'

import { Box } from '@mui/material'

import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

import FileBrowserItemComponent from '@components/file-browser/FileBrowserItem'
import FileBrowserEmptyListComponent from '@components/file-browser/FileBrowserEmptyList'

const renderRow = (props: ListChildComponentProps) => {
  const { index, style, data } = props
  const item = data[index]

  return (
    <FileBrowserItemComponent
      name={item.name}
      url={item.url}
      fileExtension={item.fileExtension}
      filesCount={item.filesCount || 0}
      sizeInBytes={item.sizeInBytes}
      createdAt={item.createdAt}
      directoriesCount={item.directoriesCount || 0}
      isDirectory={item.isDirectory || false}
      style={style}
      key={index}
    />
  )
}

interface FileBrowserViewComponentProps {
  /**
   * Browser tree
   */
  browserTree: FileService.BrowserTreeNode | null
  /**
   * Files list
   */
  filesList: FileService.ReduxFileItem[]
  /**
   * Handles search bar visibility
   */
  closeSearch: () => void
  /**
   * if true, search bar will be shown
   */
  showSearchBar?: boolean
  /**
   * Search field value
   */
  searchValue?: string
  /**
   * Max height of the container
   */
  containerMaxHeight?: number
}

const FileBrowserViewComponent: React.FC<FileBrowserViewComponentProps> = (props) => {
  const {
    browserTree,
    filesList,
    showSearchBar,
    searchValue,
    closeSearch,
    containerMaxHeight = 0,
  } = props

  const isEmpty = useMemo(() => {
    if (showSearchBar) {
      return !filesList || filesList.length === 0
    }

    return !browserTree || !browserTree.children || browserTree.children.length === 0
  }, [showSearchBar, filesList, browserTree])

  const filteredList = useMemo(() => {
    if (isEmpty || !showSearchBar) {
      return []
    }

    if (!searchValue) {
      return filesList
    }

    return filesList.filter((item) => {
      return item.path.toLowerCase().includes(searchValue.toLowerCase())
    })
  }, [isEmpty, filesList, showSearchBar, searchValue])

  const isEmptyDueToSearch = useMemo(() => {
    if (!showSearchBar) {
      return false
    }

    return isEmpty || filteredList.length === 0
  }, [isEmpty, filteredList, showSearchBar])

  const dataToUse = useMemo(() => {
    if (showSearchBar) {
      return filteredList
    }

    return browserTree?.children || []
  }, [showSearchBar, filteredList, browserTree])

  if (isEmpty || isEmptyDueToSearch) {
    return (
      <FileBrowserEmptyListComponent
        search={searchValue}
        closeSearch={closeSearch}
      />
    )
  }

  return (
    <Box
      data-testid={FileBrowserViewComponent.name}
      sx={{
        width: '100%',
        minHeight: `${containerMaxHeight}px`,
      }}
    >
      <AutoSizer>
        {({ height, width }: any) => (
          <FixedSizeList
            height={height}
            width={width}
            itemSize={70}
            itemCount={dataToUse.length}
            overscanCount={5}
            itemData={dataToUse}
          >
            {renderRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  )
}

export default FileBrowserViewComponent
