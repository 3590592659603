import React from 'react'

const InfoButtonIcon: React.FC<Common.IconProps> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  fill = 'none',
  opacity = '1',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg opacity={opacity} data-testid={InfoButtonIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M8 8V11.7692M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.5 5.26904C8.5 5.54519 8.27614 5.76904 8 5.76904C7.72386 5.76904 7.5 5.54519 7.5 5.26904C7.5 4.9929 7.72386 4.76904 8 4.76904C8.27614 4.76904 8.5 4.9929 8.5 5.26904Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default InfoButtonIcon
