import React from 'react'

const BigQueryIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={BigQueryIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M0.156938 9.53847L4.0725 16.3205H4.07166C4.28062 16.6817 4.66509 16.9047 5.08219 16.9061H12.915C13.2148 16.9057 13.4982 16.7907 13.7115 16.5919C13.4986 16.7892 13.2162 16.9034 12.9175 16.9038H12.3595L6.86328 11.3924C6.86328 11.3924 9.56525 12.3396 11.0491 10.8597C12.533 9.37976 11.4446 6.76892 11.4446 6.76892L16.5208 11.8288L17.8431 9.53847C18.0523 9.17622 18.0523 8.72959 17.8431 8.36734L13.9281 1.58556C13.7199 1.225 13.336 1.00197 12.9198 1H5.085C4.66734 1.00056 4.28147 1.22359 4.0725 1.58556L0.156938 8.36734C-0.0523125 8.72959 -0.0523125 9.17622 0.156938 9.53847Z" fill="black" fillOpacity="0.4"/>
      <path d="M11.4446 6.76892C11.4446 6.76892 12.533 9.37976 11.0491 10.8597C9.56525 12.3396 6.86328 11.3924 6.86328 11.3924L12.3595 16.9038H12.9175C13.3354 16.9032 13.7213 16.6799 13.93 16.3182L16.522 11.83L11.4446 6.76892Z" fill="white" fillOpacity="0.2"/>
      <path d="M12.732 12.1112L11.5319 10.9083C11.5188 10.8949 11.5039 10.8836 11.4875 10.8746C11.9979 10.2171 12.2385 9.38964 12.1602 8.56092C12.0818 7.73219 11.6905 6.96451 11.0659 6.41428C10.4412 5.86404 9.63032 5.57265 8.79834 5.59948C7.96635 5.62631 7.17589 5.96933 6.58801 6.55866C6.00012 7.14799 5.65905 7.93929 5.63426 8.77134C5.60948 9.60339 5.90286 10.4136 6.45463 11.0369C7.0064 11.6601 7.77504 12.0496 8.60396 12.1259C9.43287 12.2022 10.2597 11.9596 10.916 11.4475C10.9245 11.463 10.9352 11.4772 10.9477 11.4897L12.1506 12.6926C12.186 12.7279 12.2339 12.7477 12.2838 12.7477C12.3338 12.7477 12.3817 12.7279 12.417 12.6926L12.732 12.3776C12.7673 12.3422 12.7871 12.2943 12.7871 12.2444C12.7871 12.1945 12.7673 12.1466 12.732 12.1112ZM8.8994 11.3333C8.41264 11.3334 7.93681 11.1891 7.53207 10.9187C7.12732 10.6483 6.81186 10.2639 6.62556 9.81425C6.43926 9.36456 6.3905 8.86973 6.48545 8.39233C6.5804 7.91492 6.81478 7.4764 7.15897 7.13222C7.50315 6.78803 7.94168 6.55364 8.41908 6.4587C8.89648 6.36375 9.39132 6.41251 9.841 6.59881C10.2907 6.7851 10.675 7.10057 10.9454 7.50531C11.2158 7.91006 11.3601 8.38589 11.3601 8.87264C11.36 9.52523 11.1007 10.1511 10.6393 10.6125C10.1778 11.074 9.55198 11.3332 8.8994 11.3333ZM7.32637 8.7413V9.7583C7.48302 10.0348 7.70971 10.2654 7.98393 10.4263V8.73511L7.32637 8.7413ZM8.55683 7.89811V10.6465C8.77419 10.6865 8.99704 10.6865 9.2144 10.6465V7.89811H8.55683ZM10.4592 9.75605V9.15361H9.80221V10.4192C10.076 10.2588 10.3028 10.0291 10.4598 9.75324L10.4592 9.75605Z" fill="black"/>

    </svg>
  )
}

export default BigQueryIcon
