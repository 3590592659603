import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  return Yup.object().shape({
    overestimateCostPerUnit: Yup.number().required(intl.formatMessage({ id: 'use_cases.forecast.overestimateCostPerUnit.required' })),
    underestimateCostPerUnit: Yup.number().required(intl.formatMessage({ id: 'use_cases.forecast.underestimateCostPerUnit.required' })),
  })
}

export default validation
