import React, {
  useCallback, useEffect,
  useMemo, useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelButtonContainerComponent,
  SidePanelLoadingComponent,
  SidePanelActionButtonComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'

import {
  fetchUseCaseAction,
  manageUseCaseAction,
  removeUseCaseAction,
} from '@redux/modules/use-case/use-case.actions'

import {
  getDemandProblemDefinition,
  getDemandUseCasesList,
  getUseCaseAvailableMetaDataColumns,
  getUseCaseItem,
  getUseCaseSpecifiedMetaDataColumns,
  isFetchingDemandProblemDefinition,
  isFetchingUseCase,
  isFetchingUseCaseMetaDataColumns,
  isSubmittingUseCase,
} from '@redux/modules/use-case/use-case.selectors'

import { isDemandFamilyCheck } from '@utils/use-cases.utils'

import DeleteIcon from '@icons/delete.icon'
import GearIcon from '@icons/gear.icon'
import SumIcon from '@icons/flow/sum.icon'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'

import {
  USE_CASE_ADMIN_MODAL_NAME,
  USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME,
  USE_CASE_FORECAST_PARAMETERS_MODAL_NAME,
} from '@constants/modals.constants'

import {
  DEFAULT_PREDICTION_EVALUATION_HORIZON,
  DEFAULT_PREDICTION_HORIZON,
  DEFAULT_TIME_RESOLUTION_LEVEL,
  TIME_RESOLUTION_OPTIONS,
} from '@constants/date.constants'

import { USE_CASE_FAMILY_OPTIONS_LIST, USE_CASE_FAMILY_TYPES } from '@constants/use-cases.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { requestCompanyUsersListAction } from '@redux/modules/customer/customer.actions'
import { ARTIFACTS_FORMAT_DEFAULT, ARTIFACTS_FORMAT_OPTIONS } from '@constants/locales.constants'
import { CONNECT_PATH, ARTICLE_ALLOCATION_SETUP_PATH } from '@constants/routes.constants'
import { UseCaseArtifactMappingsModalDetails } from '@containers/modals/use-case-artifact-mappings-modal/UseCaseArtifactMappingsModal.container'
import { UseCaseForecastParametersModalDetails } from '@containers/modals/use-case-forecast-parameters-modal/UseCaseForecastParametersModal.container'

import {
  getSelectedCompanyId,
  getCompanyUsersList,
  isFetching as isFetchingCompanyUsers,
  getIsAdmin,
} from '@redux/modules/customer/customer.selectors'

import usecaseAdminValidations from './UseCaseAdminModal.validations'

export interface UseCaseAdminModalModalDetails extends Common.ModalDetails {
  useCaseId: string
}

export interface UseCaseConfigurationModalValues extends Omit<UseCase.DetailsExtended, 'contacts'> {
  contacts: Customer.UserItem[],
  metaDataColumns: UseCase.MetaDataColumns,
  demandProblemDefinition: UseCase.DemandProblemDefinition,
}

const UseCaseAdminModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const isSubmitting = useSelector((state) => isSubmittingUseCase(state))
  const isFetchingUseCaseFlag = useSelector((state) => isFetchingUseCase(state))
  const isFetchingCompanyUsersFlag = useSelector((state) => isFetchingCompanyUsers(state))
  const isFetchingDemandProblemDefinitionFlag = useSelector((state) => isFetchingDemandProblemDefinition(state))
  const isFetchingColumns = useSelector((state) => isFetchingUseCaseMetaDataColumns(state))

  const demandUseCases = useSelector((state) => getDemandUseCasesList(state))
  const useCase = useSelector((state) => getUseCaseItem(state))
  const demandProblemDefinition = useSelector((state) => getDemandProblemDefinition(state))
  const useCaseSpecifiedColumns = useSelector((state) => getUseCaseSpecifiedMetaDataColumns(state))
  const useCaseAvailableColumns = useSelector((state) => getUseCaseAvailableMetaDataColumns(state))
  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const companyUsersList = useSelector((state) => getCompanyUsersList(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))

  const modalDetails = useSelector((state) => getModalDetails<UseCaseAdminModalModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const [removeDialogState, setRemoveDialogState] = useState(false)

  const isFetching = isFetchingCompanyUsersFlag || isFetchingUseCaseFlag || isFetchingDemandProblemDefinitionFlag || isFetchingColumns
  const open = modalPageName === USE_CASE_ADMIN_MODAL_NAME
  const selectedUseCaseId = (modalDetails && modalDetails.useCaseId) || ''
  const edit = Boolean(selectedUseCaseId)

  const enrichContactsList = useCallback((contacts: string[]) => {
    return contacts.map((customerId) => {
      const userDetails = companyUsersList.find((userItem) => userItem.customerId === customerId)

      return userDetails
    }).filter((userDetails) => Boolean(userDetails))
  }, [
    companyUsersList,
  ])

  const initialValues: UseCaseConfigurationModalValues = useMemo(() => {
    return edit ? {
      ...useCase as any,
      contacts: enrichContactsList(useCase.contacts || []),
      metaDataColumns: useCaseSpecifiedColumns,
      demandProblemDefinition,
    } : {
      name: '',
      demandUseCaseId: '',
      artifactLanguage: ARTIFACTS_FORMAT_DEFAULT,
      family: USE_CASE_FAMILY_TYPES.CUSTOM,
      demandProblemDefinition: {
        targetName: '',
        targetUnit: '',
        predictionHorizon: DEFAULT_PREDICTION_HORIZON,
        evaluationHorizon: DEFAULT_PREDICTION_EVALUATION_HORIZON,
        timeResolution: DEFAULT_TIME_RESOLUTION_LEVEL,
      },
    } as UseCaseConfigurationModalValues
  }, [
    edit,
    demandProblemDefinition,
    useCaseSpecifiedColumns,
    useCase,
    enrichContactsList,
  ])

  useEffect(() => {
    if (edit && open && selectedUseCaseId) {
      dispatch(
        fetchUseCaseAction({
          useCaseId: selectedUseCaseId,
          includeForecast: true,
          includeDemandProblemDefinition: true,
          includeMetaDataColumns: true,
        }),
      )

      dispatch(
        requestCompanyUsersListAction({
          companyId,
        }),
      )
    }
  }, [open, edit, selectedUseCaseId, companyId, dispatch])

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const handleUseCaseDelete = (useCaseId: string) => {
    dispatch(
      removeUseCaseAction({
        useCaseId,
      }),
    )
  }

  const handleArtifactsClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME,
        modalDetails: {
          returnTo: USE_CASE_ADMIN_MODAL_NAME,
          useCaseName: useCase.name,
          useCaseId: useCase.useCaseId,
        } as UseCaseArtifactMappingsModalDetails,
      }),
    )
  }

  const handleForecastClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: USE_CASE_FORECAST_PARAMETERS_MODAL_NAME,
        modalDetails: {
          returnTo: USE_CASE_ADMIN_MODAL_NAME,
          useCaseName: useCase.name,
          useCaseId: useCase.useCaseId,
          forecastParameters: useCase.forecastParameters,
          forecastValue: useCase.forecastValue,
        } as UseCaseForecastParametersModalDetails,
      }),
    )
  }

  const handleDelete = () => {
    setRemoveDialogState(true)
  }

  const handleModalSubmit = (values: UseCaseConfigurationModalValues) => {
    const isDemandFamily = isDemandFamilyCheck(values.family)
    const contacts = values.contacts?.map((userDetails) => userDetails.customerId)

    dispatch(
      manageUseCaseAction({
        useCase: {
          useCaseId: selectedUseCaseId,
          name: values.name,
          family: values.family,
          artifactLanguage: values.artifactLanguage,
          demandUseCaseId: values.demandUseCaseId!,
        },
        contacts,
        metaDataColumns: values.metaDataColumns,
        demandProblemDefinition: (isDemandFamily && isAdmin) ? {
          ...initialValues.demandProblemDefinition,
          ...values.demandProblemDefinition,
          timeResolution: values.demandProblemDefinition.timeResolution,
          predictionHorizon: Number(values.demandProblemDefinition.predictionHorizon),
          metaDataColumns: values.demandProblemDefinition.metaDataColumns,
        } : undefined,
      }),
    )
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleModalSubmit,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: usecaseAdminValidations(intl, isAdmin, useCaseAvailableColumns),
  })

  const isDemandFamily = isDemandFamilyCheck(values.family)
  const demandProblemDefinitionCompositeKeyColumns = (demandProblemDefinition?.metaDataColumns || [])
    .filter((column) => column.isCompositeKeyPart)
    .map((column) => column.columnName)

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const modalTitle = edit ? intl.formatMessage({ id: 'use_cases.modal.edit' }, { name: initialValues.name }) : intl.formatMessage({ id: 'use_cases.modal.create' })
  const modalAllowedRedirects = edit ? [] : [CONNECT_PATH, ARTICLE_ALLOCATION_SETUP_PATH]
  const modalHasUnsavedChanges = dirty || isSubmitting

  return (
    <>
      <SidePanelComponent
        open={open}
        loading={isFetching}
        title={modalTitle}
        handleClose={handleModalClose}
        allowedRedirects={modalAllowedRedirects}
        hasUnsavedChanges={modalHasUnsavedChanges}
      >
        <SidePanelLoadingComponent loading={isFetching}>
          <Box component='form' onSubmit={handleSubmit}>
            <SidePanelCardComponent>
              <FormLayoutContainer>
                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.family.general' })}
                  hidden={edit}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='name'
                      touched={touched.name}
                      errors={errors.name}
                      value={values.name}
                      label={intl.formatMessage({ id: 'use_cases.form.name.title' })}
                      floatingHelp={intl.formatMessage({ id: 'use_cases.form.name.help' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='artifactLanguage'
                      value={values.artifactLanguage}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'use_cases.form.artifactsFormat.title' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={ARTIFACTS_FORMAT_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='family'
                      value={values.family}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'use_cases.family.types' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={USE_CASE_FAMILY_OPTIONS_LIST}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.family.general' })}
                  hidden={!edit}
                >
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='useCaseId'
                      value={values.useCaseId}
                      label={intl.formatMessage({ id: 'use_cases.form.id.title' })}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='name'
                      touched={touched.name}
                      errors={errors.name}
                      value={values.name}
                      label={intl.formatMessage({ id: 'use_cases.form.name.title' })}
                      floatingHelp={intl.formatMessage({ id: 'use_cases.form.name.help' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='companyId'
                      value={companyId}
                      label={intl.formatMessage({ id: 'use_cases.form.company_id.title' })}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='fileStorageBucket'
                      value={values.fileStorageBucket}
                      label={intl.formatMessage({ id: 'use_cases.form.fileStorageBucket.title' })}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='family'
                      value={values.family}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'use_cases.family.types' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={USE_CASE_FAMILY_OPTIONS_LIST}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='artifactLanguage'
                      value={values.artifactLanguage}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'use_cases.form.artifactsFormat.title' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={ARTIFACTS_FORMAT_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12} hidden={!edit || isDemandFamily}>
                    <SelectFieldComponent
                      name='demandUseCaseId'
                      value={values.demandUseCaseId || ''}
                      useIntlForLabel={false}
                      disabled={false}
                      label={intl.formatMessage({ id: 'use_cases.form.demandUseCase.title' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      valueKey='useCaseId'
                      labelKey='name'
                      options={demandUseCases}
                      allowEmpty={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.family.demand' })}
                  hidden={!isDemandFamily || !isAdmin}
                >
                  <FormLayoutItem xs={6}>
                    <SelectFieldComponent
                      name='demandProblemDefinition.timeResolution'
                      value={values.demandProblemDefinition.timeResolution}
                      useIntlForLabel={true}
                      label={intl.formatMessage({ id: 'use_cases.family.demand.time_resolution' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                      labelKey='labelKey'
                      options={TIME_RESOLUTION_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='demandProblemDefinition.predictionHorizon'
                      value={values.demandProblemDefinition.predictionHorizon}
                      label={intl.formatMessage({ id: 'use_cases.family.demand.prediction_horizon' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      disabled={false}
                      touched={touched.demandProblemDefinition?.predictionHorizon}
                      errors={errors.demandProblemDefinition?.predictionHorizon}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='demandProblemDefinition.evaluationHorizon'
                      value={values.demandProblemDefinition.evaluationHorizon}
                      label={intl.formatMessage({ id: 'use_cases.family.demand.evaluation_horizon' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      disabled={false}
                      touched={touched.demandProblemDefinition?.evaluationHorizon}
                      errors={errors.demandProblemDefinition?.evaluationHorizon}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='demandProblemDefinition.targetName'
                      value={values.demandProblemDefinition.targetName}
                      label={intl.formatMessage({ id: 'use_cases.family.demand.target_name' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <TextFieldComponent
                      name='demandProblemDefinition.targetUnit'
                      value={values.demandProblemDefinition.targetUnit}
                      label={intl.formatMessage({ id: 'use_cases.family.demand.target_unit' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={false}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>
                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.form.compositeKeyColumns' })}
                  hidden={!isDemandFamily || !isAdmin || !edit}
                >
                  <FormLayoutItem xs={12}>
                    <AutocompleteChipsFieldComponent<string>
                      name='compositeKeyColumns'
                      label={intl.formatMessage({ id: 'use_cases.form.compositeKeyColumns' })}
                      floatingHelp={intl.formatMessage({ id: 'use_cases.form.compositeKeyColumns.help' })}
                      placeholder={intl.formatMessage({ id: 'use_cases.form.compositeKeyColumns.placeholder' })}
                      value={demandProblemDefinitionCompositeKeyColumns}
                      freeSolo={true}
                      disabled={true}
                      options={demandProblemDefinitionCompositeKeyColumns}
                      getOptionLabel={(item: string) => item}
                      handleChangeCallback={() => {}}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.notification' })}
                  hidden={!edit}
                >
                  <FormLayoutItem xs={12}>
                    <AutocompleteChipsFieldComponent<Customer.UserItem>
                      name='contacts'
                      label={intl.formatMessage({ id: 'use_cases.notification.contacts' })}
                      floatingHelp={intl.formatMessage({ id: 'use_cases.notification.contacts.help' })}
                      placeholder={intl.formatMessage({ id: 'use_cases.notification.contacts.placeholder' })}
                      value={values.contacts}
                      options={companyUsersList}
                      isOptionEqualToValue={(option: Customer.UserItem, value: Customer.UserItem) => option.customerId === value.customerId}
                      getOptionLabel={(item: Customer.UserItem) => item.email}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                        handleChange({
                          target: {
                            name: 'contacts',
                            value: newValue,
                          },
                        })
                      }}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'use_cases.meta_data' })}
                  hidden={!isDemandFamily || !isAdmin || !edit}
                >
                  <FormLayoutItem xs={12}>
                    <AutocompleteChipsFieldComponent<string>
                      name='metaDataColumns'
                      label={intl.formatMessage({ id: 'use_cases.meta_data.columns' })}
                      floatingHelp={intl.formatMessage({ id: 'use_cases.meta_data.columns.help' })}
                      placeholder={intl.formatMessage({ id: 'use_cases.meta_data.columns.placeholder' })}
                      value={values.metaDataColumns}
                      errors={errors.metaDataColumns as string[]}
                      options={useCaseAvailableColumns}
                      getOptionLabel={(item: string) => item}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                        handleChange({
                          target: {
                            name: 'metaDataColumns',
                            value: newValue,
                          },
                        })
                      }}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>
              </FormLayoutContainer>

              <SidePanelButtonContainerComponent hidden={!(edit && isAdmin)}>
                <SidePanelActionButtonComponent
                  name='useCaseAdminModalForecastButton'
                  StartIconComponent={SumIcon}
                  label={intl.formatMessage({ id: 'use_cases.admin.menu.forecast' })}
                  onClick={handleForecastClick}
                  hidden={initialValues.family !== USE_CASE_FAMILY_TYPES.DEMAND}
                />

                <SidePanelActionButtonComponent
                  name='useCaseAdminModalArtifactsButton'
                  StartIconComponent={GearIcon}
                  label={intl.formatMessage({ id: 'use_cases.admin.menu.mappings' })}
                  onClick={handleArtifactsClick}
                />

                <SidePanelActionButtonComponent
                  name='useCaseAdminModalDeleteButton'
                  StartIconComponent={DeleteIcon}
                  label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
                  onClick={handleDelete}
                />
              </SidePanelButtonContainerComponent>
            </SidePanelCardComponent>

            <SidePanelCardActionsComponent>
              <ModalButtonComponent
                name='useCaseAdminModalCloseButton'
                onClick={() => handleModalClose()}
                type='cancel'
                disabled={isSubmitting}
              />

              <ModalButtonComponent
                name='useCaseAdminModalSubmitButton'
                type='submit'
                onClick={(e) => handleModalSubmit(values)}
                loading={isSubmitting}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </SidePanelCardActionsComponent>
          </Box>
        </SidePanelLoadingComponent>
      </SidePanelComponent>

      <TextConfirmationDialogComponent
        open={removeDialogState}
        onClose={() => setRemoveDialogState(false)}
        onSubmit={() => handleUseCaseDelete(initialValues.useCaseId)}
        confirmationText={initialValues.name}
        confirmationInputLabel={intl.formatMessage({ id: 'use_cases.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'use_cases.table.dialog.content' }, {
            name: <Box component='strong'>{initialValues.name}</Box>,
          })
        }
      />
    </>
  )
}

export default UseCaseAdminModalContainer
