import React, { useMemo } from 'react'
import moment from 'moment'
import get from 'lodash.get'

import { useIntl } from 'react-intl'
import { Box, useTheme } from '@mui/material'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Text,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine,
  ReferenceArea,
} from 'recharts'

import {
  getArrowHead,
  getXAxisProps,
  getYAxisProps,
  getYTextProps,
  getCartesianGridProps,
  getTooltipProps,
  getLineProps,
  getReferenceLabelProps,
  getTooltipFormattedAxis,
  getXLabelProps,
} from '@utils/svg.utils'

import ChartLegendComponent, { ChartLegendItem } from '@components/charts/ChartLegend/ChartLegend.component'
import InsightsChartTooltipComponent from '@components/insights/InsightsChartTooltip'
import InsightsChartMenuComponent from '@components/insights/InsightsChartMenu'

import { generatePredictionPayloadKey, getColorByIndex, getLineLabel } from '@utils/insights.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DEFAULT_CHART_TICKS_FORMAT, formatTimestamp, toUnixTimestamp } from '@utils/moment.utils'
import { createId } from '@utils/common.utils'
import { PROMOTION_DAYS } from '@constants/promotions.constants'

export interface InsightsLineChartComponentProps {
  /**
   * Dataset to be displayed in the chart
   */
  dataset?: Hera.BaseChartDatasetItem[]
  /**
   * Name of the target
   */
  targetName?: string
  /**
   * Unit of the target
   */
  targetUnit?: string
  /**
   * Prefix for the prediction key
   */
  predictionKeyPrefix?: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationKeyPrefix?: string
  /**
   * Lines to be displayed in the chart
   */
  lines?: Hera.BaseChartLineItem[]
  /**
   * Annotations to be displayed in the chart
   */
  annotations?: Hera.BaseChartAnnotationItem[]
  /**
   * Flag to enable today line
   */
  enableTodayLine?: boolean
  /**
   *lag to indicate if the grid has grouping enabled
   */
  hasGroupingEnabled?: boolean
  /**
   * Flag to indicate if the chart is fetching data
   */
  isFetching?: boolean
  /**
   * Flag to show promotion days
   */
  promotionsVisibility?: boolean
  /**
   * Flag to show promotion days
   */
  promotionsToExclude?: PROMOTION_DAYS[]
  /**
   * Promotion days to be displayed in the chart
   */
  promotionDays?: Hera.BaseChartPromotionItem[]
  /**
   * Selected rows in the grid
   */
  selectedRows?: GridRowSelectionModel
}

const InsightsLineChartComponent: React.FC<InsightsLineChartComponentProps> = ({
  dataset = [],
  lines = [],
  annotations = [],
  promotionDays = [],
  targetName = '',
  targetUnit = '',
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
  hasGroupingEnabled = false,
  enableTodayLine = true,
  promotionsVisibility = true,
  promotionsToExclude = [],
  selectedRows = [],
  isFetching,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const xKey = 'date'

  const todayTimestamp = useMemo(() => Number(toUnixTimestamp(moment().utc().startOf('day'))), [])
  const firstTimestamp = useMemo(() => Number(get(dataset, '[0].date', todayTimestamp)), [todayTimestamp, dataset])
  const lastTimestamp = useMemo(() => Number(get(dataset, `[${dataset.length - 1}].date`, todayTimestamp)), [todayTimestamp, dataset])
  const promotionDaysFiltered = useMemo(() => {
    return promotionDays.filter((promotionDay) => {
      return !promotionsToExclude.includes(promotionDay.key as PROMOTION_DAYS)
    })
  }, [promotionDays, promotionsToExclude])

  const chartLines = useMemo(() => {
    const linesList: {
      id: string,
      color: string,
    }[] = []

    lines.forEach((line, index) => {
      if (hasGroupingEnabled) {
        /** Actual line */
        linesList.push({
          id: line.id,
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            useOpacity: true,
            selectedRows,
          }),
        })

        /** Prediction line */
        linesList.push({
          id: generatePredictionPayloadKey(line.id, predictionKeyPrefix),
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            useOpacity: false,
            selectedRows,
          }),
        })
      } else {
        linesList.push({
          id: line.id,
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: false,
            useOpacity: false,
            selectedRows,
          }),
        })
      }
    })

    return linesList
  }, [
    lines,
    selectedRows,
    isFetching,
    hasGroupingEnabled,
    predictionKeyPrefix,
  ])

  const legendRows = useMemo(() => {
    const rows: ChartLegendItem[] = []

    lines.forEach((line, index) => {
      rows.push({
        label: getLineLabel(intl, line, targetName),
        color: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: false,
          selectedRows,
        }),
      })
    })

    if (promotionsVisibility) {
      rows.push({
        label: intl.formatMessage({ id: 'common.promotions' }),
        color: theme.palette.new.gainful_grey_20,
        type: 'square',
      })
    }

    return rows
  }, [
    theme, intl, lines, isFetching, selectedRows,
    targetName, hasGroupingEnabled, promotionsVisibility,
  ])

  return (
    <Box
      data-testid={InsightsLineChartComponent.name}
      sx={{
        width: '100%',
        height: '100%',
        ...(isFetching ? {
          filter: 'blur(5px)',
        } : {}),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '392px',
          position: 'relative',
          paddingTop: '20px',
        }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={dataset}
            margin={{
              top: 0,
              right: 5,
              left: 50,
              bottom: 20,
            }}
          >
            <CartesianGrid
              {...getCartesianGridProps(true, true)}
            />

            <Tooltip
              {...getTooltipProps(false, { x: false, y: false })}
              cursor={!isFetching}
              content={(
                <InsightsChartTooltipComponent
                  lines={lines}
                  isFetching={isFetching}
                  hasGroupingEnabled={hasGroupingEnabled}
                  targetName={targetName}
                  selectedRows={selectedRows}
                  predictionKeyPrefix={predictionKeyPrefix}
                  absDeviationKeyPrefix={absDeviationKeyPrefix}
                  promotionsVisibility={promotionsVisibility}
                  promotionsToExclude={promotionsToExclude}
                />
              )}
            />

            {
              chartLines.map((line, index) => {
                return (
                  <Line
                    key={index}
                    {...getLineProps(line.id, line.color)}
                  />
                )
              })
            }

            {
              enableTodayLine ? (
                <ReferenceLine
                  x={todayTimestamp}
                  stroke={theme.palette.new.black}
                  strokeWidth={1}
                >
                  <Label
                    value={intl.formatMessage({ id: 'insights.chart.today' })}
                    {...getReferenceLabelProps()}
                  />
                </ReferenceLine>
              ) : (
                null
              )
            }

            {
              annotations.map((annotation, index) => {
                /**
                 * Calculate edge proximity to determine annotation position.
                 * This calculates the edge position as 10% of the total chart width from the lastTimestamp.
                 */
                const annotationX = Number(annotation.x)
                const isNearRightEdge = annotationX > (lastTimestamp - (0.1 * (lastTimestamp - firstTimestamp)))
                const position = isNearRightEdge ? 'insideTopLeft' : 'insideTopRight'

                return (
                  <ReferenceLine
                    key={index}
                    x={Number(annotation.x)}
                    stroke={theme.palette.new.black}
                    strokeWidth={1}
                    className={position}
                  >
                    {
                      annotation.overline ? (
                        <Label
                          {...getReferenceLabelProps({ position })}
                          value={annotation.overline}
                        />
                      ) : (
                        null
                      )
                    }

                    <Label
                      {...getReferenceLabelProps({ position })}
                      value={annotation.label}
                      fontWeight={500}
                      style={{
                        transform: annotation.overline ? `translate(${isNearRightEdge ? -10 : 10}px, 20px)` : '',
                      }}
                    />
                  </ReferenceLine>
                )
              })
            }

            {
              promotionsVisibility && promotionDaysFiltered.map((promotionDay, index) => {
                return (
                  <ReferenceArea
                    key={createId(promotionDay, index)}
                    x1={promotionDay.from}
                    x2={promotionDay.to}
                    fill={theme.palette.new.gainful_grey}
                    fillOpacity={0.2}
                  />
                )
              })
            }

            <XAxis
              {...getXAxisProps()}
              dataKey={xKey}
              minTickGap={100}
              scale='time'
              type='number'
              domain={['auto', 'auto']}
              tickFormatter={(unixTime: number) => {
                return formatTimestamp(unixTime, intl.locale, DEFAULT_CHART_TICKS_FORMAT)
              }}
            >
              <Label
                {...getXLabelProps()}
                value={intl.formatMessage({ id: 'insights.chart.x.title' })}
              />
            </XAxis>

            <YAxis
              {...getYAxisProps()}
              tickFormatter={(value) => String(defaultNumberFormatter(value))}
            >
              <Label
                content={(
                  <Text
                    {...getYTextProps()}
                    style={{ transform: 'translate(100px, 0px)' }}
                  >
                    {
                      getTooltipFormattedAxis(intl, targetName, 'insights.chart.yAxis', targetUnit)
                    }
                  </Text>
                )}
              />
            </YAxis>

            {getArrowHead()}
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        gap={1}
        mt={1}
      >
        <ChartLegendComponent
          items={legendRows}
          sx={{
            padding: '0px',
            paddingLeft: '110px',
          }}
        />

        <InsightsChartMenuComponent />
      </Box>
    </Box>
  )
}

export default InsightsLineChartComponent
