import {
  OPTIMIZE_DATA,
  generateDrillDownData,
  generateOptimizeData,
} from '@redux/modules/optimize/optimize.mock'

import {
  RequestOptimizeDrillDownPayload,
  RequestOptimizeDataPayload,
} from './optimize.types'

export const fetchOptimizeDrillDown = (payload: RequestOptimizeDrillDownPayload = {} as RequestOptimizeDrillDownPayload) => {
  return generateDrillDownData(payload.tableData && payload.tableData.length ? payload.tableData : OPTIMIZE_DATA)
    .find((item) => (item.meta.sku === payload.sku) && (item.meta.region === payload.region))
}

export const fetchOptimizeTableData = (payload: RequestOptimizeDataPayload = {} as RequestOptimizeDataPayload) => {
  return payload.reflectTargets ? generateOptimizeData(payload.rangeSelectorData!) : OPTIMIZE_DATA
}
