import { USE_CASE_FAMILY_TYPES } from '@constants/use-cases.constants'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape, isAdmin: boolean, useCaseAvailableColumns: UseCase.MetaDataColumns) => {
  const shape = Yup.object().shape({
    name: Yup.string().max(255).required(intl.formatMessage({ id: 'use_cases.form.name.required' })),
    demandProblemDefinition: Yup.object().when('family', {
      is: (value: USE_CASE_FAMILY_TYPES) => {
        return value === USE_CASE_FAMILY_TYPES.DEMAND && isAdmin
      },
      then: Yup.object().shape({
        predictionHorizon: Yup.number().min(1).required(),
        evaluationHorizon: Yup.number().min(1).max(Yup.ref('predictionHorizon'), intl.formatMessage({ id: 'use_cases.evaluation_horizon.validation.invalid' })).required(),
      }),
    }),
    metaDataColumns: Yup.array().when('family', {
      is: (value: USE_CASE_FAMILY_TYPES) => {
        return value === USE_CASE_FAMILY_TYPES.DEMAND && isAdmin
      },
      then: Yup.array().of(
        Yup.string().matches(/^[A-Za-z_][A-Za-z0-9_]{0,63}$/, intl.formatMessage({ id: 'use_cases.meta_data.validation.invalid' })).oneOf(useCaseAvailableColumns, (params) => {
          if (!params.values) {
            return intl.formatMessage({
              id: 'use_cases.meta_data.validation.no_avaliable_options',
            }, {
              name: params.value,
            })
          }

          return intl.formatMessage({
            id: 'use_cases.meta_data.validation.not_avaliable',
          }, {
            name: params.value,
            available: params.values,
          })
        }),
      ).min(0),
    }),
  })

  return shape
}

export default validation
