import moment from 'moment'
import get from 'lodash.get'

import {
  put, call,
  select, take,
} from 'redux-saga/effects'

import { IntlShape } from 'react-intl'
import { GridRowSelectionModel, GridAggregationModel } from '@mui/x-data-grid-premium'
import { convertAPIFieldTypeToMUIFieldType, parseStoredTimeWindow } from '@redux/modules/hera/hera.utils'

import palette from '@configuration/theme/theme.palette'

import {
  INSIGHTS_COLOR_WAY,
  DEFAULT_AGGREGATED_BACKTEST_PREDICTION_FIELD,
  DEFAULT_AGGREGATED_BACKTEST_TARGET_FIELD,
  DEFAULT_ANALYZE_AGGREGATED_PREDICTION_FIELD,
  DEFAULT_ANALYZE_AGGREGATED_TARGET_FIELD,
  DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX,
  DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX,
} from '@constants/insights.constants'

import {
  getDataGridId, getTableStateFromStorage,
  removeTableStateFromStorage, storeTableStateInStorage,
} from '@utils/data-grid.utils'

import { DATA_GRIDS, DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { dateStringToUnixTimestamp } from '@utils/moment.utils'
import { getPromotionDates } from '@utils/promotions.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { TIME_RESOLUTION } from '@constants/date.constants'

/**
 * Creates the initial data grid state
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export function createInsightsDataGridState<T extends Hera.HeraBaseGridState>(tableId: string, initialState?: Hera.HeraBaseGridState, customProps?: Partial<T>) {
  const tableStateFromStorage = getTableStateFromStorage<T>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Analyze.AnalyzeGridState
  const parsedSsState = (tableStateFromStorage?.sessionStorageState || {}) as Analyze.AnalyzeGridState
  const hasGroupingsInSs = (parsedSsState?.groupingModel && parsedSsState?.groupingModel.length > 0)

  const columnVisibilityModel = {
    ...(initialState?.columnVisibilityModel || {}),
  }

  if (hasGroupingsInSs) {
    Object.assign(columnVisibilityModel, {
      ...(parsedLsState?.columnVisibilityModel || {}),
    })
  } else {
    Object.assign(columnVisibilityModel, {
      /**
        * Since we use custom server side row grouping, where we change the visibility of the columns depending on the row grouping model,
        * We need to store the additional column visibility model which reflects user's choice of columns visibility.
      */
      ...(parsedLsState?.internalColumnVisibilityModel || {}),
    })
  }

  return {
    /**
     * Non-persistent state
     */
    rowSelectionModel: initialState?.rowSelectionModel,
    rowSelectionModelMode: hasGroupingsInSs ? 'include' : initialState?.rowSelectionModelMode,

    /**
     * Local Storage persistence
     */
    aggregationModel: {
      ...initialState?.aggregationModel,
      ...parsedLsState?.aggregationModel,
    },
    columnVisibilityModel,
    pinnedColumns: {
      ...initialState?.pinnedColumns,
      ...parsedLsState?.pinnedColumns,
    },
    internalColumnVisibilityModel: {
      ...initialState?.internalColumnVisibilityModel,
      ...parsedLsState?.internalColumnVisibilityModel,
    },
    /**
     * Session Storage persistence
     */
    groupingModel: parsedSsState?.groupingModel || initialState?.groupingModel,
    sortModel: parsedSsState?.sortModel || initialState?.sortModel,
    filterModel: {
      ...initialState?.filterModel,
      ...parsedSsState?.filterModel,
    },
    paginationModel: {
      page: parsedSsState?.paginationModel?.page || 0,
      pageSize: parsedLsState?.paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    },
    timeWindow: parseStoredTimeWindow(parsedSsState?.timeWindow as [string | null, string | null]) || initialState?.timeWindow,
    abcFilter: parsedSsState?.abcFilter || initialState?.abcFilter,

    /**
     * Custom state and overrides
     */
    ...customProps,
  } as T
}

/**
 * Returns the color for the line by index
 *
 * @param index Line index
 * @param options Options
 * @returns Color
 */
export const getColorByIndex = (lineIndex: number, rowId: string, options: {
  hasGroupingEnabled?: boolean,
  useColorWay?: boolean,
  useOpacity?: boolean,
  isFetching?: boolean,
  selectedRows?: GridRowSelectionModel,
} = {
  hasGroupingEnabled: false,
  useColorWay: false,
  useOpacity: false,
  isFetching: false,
  selectedRows: [],
}) => {
  if (options.isFetching) {
    return palette.new.black
  }

  if (!options.hasGroupingEnabled) {
    return lineIndex === 0 ? palette.new.black : palette.new.pink
  }

  const colorIndex = (options.selectedRows || []).findIndex((row) => String(row) === rowId)
  const indexToUse = options.hasGroupingEnabled ? colorIndex : lineIndex

  if (options.useColorWay) {
    if (options.useOpacity) {
      return `${INSIGHTS_COLOR_WAY[indexToUse].base}80`
    }

    return INSIGHTS_COLOR_WAY[indexToUse].base
  }

  return palette.new.black
}

/**
 * Returns the line label
 * @param intl React Intl
 * @param line Insight chart line item
 * @param prediction Is prediction line
 * @returns Line label
 */
export const getLineLabel = (intl: IntlShape, line: Hera.BaseChartLineItem, targetName = '', prediction = false) => {
  if (line.id === DEFAULT_ANALYZE_AGGREGATED_TARGET_FIELD || line.id === DEFAULT_AGGREGATED_BACKTEST_TARGET_FIELD) {
    return intl.formatMessage({
      id: 'insights.chart.actualResults',
    }, {
      name: targetName,
    })
  }

  if (line.id === DEFAULT_ANALYZE_AGGREGATED_PREDICTION_FIELD || line.id === DEFAULT_AGGREGATED_BACKTEST_PREDICTION_FIELD) {
    return intl.formatMessage({
      id: 'insights.chart.predictionResults',
    }, {
      name: targetName,
    })
  }

  if (prediction) {
    return intl.formatMessage({
      id: 'insights.chart.predictionLabel',
    }, {
      name: line.label,
    })
  }

  return line.label
}

/**
 * Returns the key for the prediction payload
 * @param id Key
 * @returns Returns the key for the prediction payload
 */
export const generatePredictionPayloadKey = (id: string, prefix = DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the key for the prediction payload
 * @param id Key
 * @returns Returns the key for the prediction payload
 */
export const generateAbsDeviationPayloadKey = (id: string, prefix = DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX) => `${prefix}_${id}`

/**
 * Returns the class name for the grouping row
 * @param rowIndex Row index
 * @returns Class name
 */
export const generateGroupingRowClassName = (rowIndex: number) => {
  return `insights-row-${rowIndex}`
}

/**
 * Aggregation model transformer for insights table
 * @param currentAggregationModel Current aggregation model
 * @param newAggregationModel New aggregation model
 * @param fields Fields
 * @returns Synced aggregation model
 */
export const aggregationModelTransformer = (
  currentAggregationModel: GridAggregationModel,
  newAggregationModel: GridAggregationModel,
  fields: {
    target: string,
    prediction: string,
  },
) => {
  /**
   * We change the aggregation model for both target and prediction columns at the same time
   */
  const prevTargetAggregation = currentAggregationModel[fields.target]
  const newTargetAggregation = newAggregationModel?.[fields.target]
  const newPredictionAggregation = newAggregationModel?.[fields.prediction]
  const aggregationToUse = prevTargetAggregation === newTargetAggregation ? newPredictionAggregation : newTargetAggregation
  const syncAggregationModel = {
    [fields.target]: aggregationToUse,
    [fields.prediction]: aggregationToUse,
  }

  return syncAggregationModel
}

/**
 * Returns data grid columns from Hera columns
 *
 * @param columns Columns definition
 * @param specialColumns Special columns to be treated differently (e.g. target)
 * @param disableAggregation Disable aggregation, otherwise special columns will be aggregable
 *
 * @returns Data grid columns
 */
export const getDataGridColumnsFromHeraColumns = ({
  columns,
  specialColumnNames,
  disableAggregation,
}: {
  columns: Hera.APITableColumnDefinition[],
  specialColumnNames: string[]
  disableAggregation?: boolean
}) => {
  return columns.map((column) => {
    const field = column.name
    const type = convertAPIFieldTypeToMUIFieldType(column.type)
    const isSpecialColumn = specialColumnNames.includes(field)

    return {
      field,
      type,
      headerName: column.label,
      pinnable: !isSpecialColumn,
      hideable: !isSpecialColumn,
      groupable: !isSpecialColumn,
      aggregable: disableAggregation ? false : isSpecialColumn,
      minWidth: isSpecialColumn ? 200 : undefined,
    }
  }) as Hera.SimplifiedGridColumnDefinition[]
}

/**
 * Returns the target name from the column definitions
 *
 * @param columns Column definitions
 * @param defaultValue Default value for the target name
 *
 * @returns Target name
 */
export const getTargetNameFromColumnDefinitions = (columns: Hera.APITableColumnDefinition[], defaultValue = '') => {
  const targetColumns = columns.filter((column) => column.isTarget)
  const targetColumnsNames = targetColumns.map((column) => column.name)

  return targetColumns[0]?.label || targetColumnsNames[0] || defaultValue
}

/**
 * Determines the prediction resolution by dates.
 * The first date is the first date in the dataset are used to determine the resolution.
 * Also the last and second to last dates are used to double check the result because there might be gaps in the dataset.
 *
 * @param firstDate First date
 * @param secondDate Second date
 * @param lastDate Last date
 * @param secondToLastDate Second to last date
 *
 * @returns Prediction resolution
 */
export const getPredictionResolutionByDates = ({
  firstDate,
  secondDate,
  lastDate,
  secondToLastDate,
} : {
  firstDate: string,
  secondDate: string,
  lastDate: string,
  secondToLastDate: string,
}): TIME_RESOLUTION => {
  if (!firstDate || !secondDate) {
    return TIME_RESOLUTION.DAILY
  }

  const firstDateMoment = moment(firstDate)
  const nextDateMoment = moment(secondDate)

  const dailyDiffFromFirstDate = nextDateMoment.diff(firstDateMoment, 'days')
  const weeklyDiffFromFirstDate = nextDateMoment.diff(firstDateMoment, 'week')
  const monthlyDiffFromFirstDate = nextDateMoment.diff(firstDateMoment, 'month')

  /**
   * Dobule check if there are gaps in the dataset
   */
  if (lastDate && secondToLastDate) {
    const lastDateMoment = moment(lastDate)
    const secondToLastDateMoment = moment(secondToLastDate)
    const dailyDiffFromLastDate = lastDateMoment.diff(secondToLastDateMoment, 'days')

    if (dailyDiffFromLastDate !== dailyDiffFromFirstDate) {
      return TIME_RESOLUTION.DAILY
    }
  }

  if (dailyDiffFromFirstDate === 1) {
    return TIME_RESOLUTION.DAILY
  }

  if (weeklyDiffFromFirstDate === 1) {
    return TIME_RESOLUTION.WEEKLY
  }

  if (monthlyDiffFromFirstDate === 1) {
    return TIME_RESOLUTION.MONTHLY
  }

  return TIME_RESOLUTION.DAILY
}

/**
 * Returns the chart definition
 *
 * @param dataPoints list of data points
 * @param gridState grid state
 * @param baseLegend base legend
 * @param groupingLegend grouping legend
 *
 * @returns lines definition and dataset
 */
export function processChartData<
  TState extends Hera.HeraBaseGridState,
>({
  dataPoints,
  gridState,
  baseLegend,
  groupingLegend,
}: {
  dataPoints: Hera.APIChartDatapoint[]
  gridState: TState
  baseLegend: string[]
  groupingLegend: Hera.APIChartMetaData['legend']
}) {
  const selectedRows = gridState.rowSelectionModel || []
  const groupingModel = gridState.groupingModel || []
  const hasGrouping = groupingModel.length > 0

  const keys = hasGrouping ? Object.keys(groupingLegend) : baseLegend

  /**
   * For groupping: Sort the legend alphabetically.
   * Filter out the keys that are not selected if grouping is enabled.
   */
  const finalKeys = hasGrouping ? keys.filter((key) => {
    return selectedRows.includes(String(key))
  }).sort((a, b) => {
    return groupingLegend[a] > groupingLegend[b] ? 1 : -1
  }) : (
    keys
  )

  /**
   * Create the base lines definition for the chart.
   */
  const lines: Hera.BaseChartLineItem[] = finalKeys.map((key, index) => {
    return {
      id: key,
      label: groupingLegend[key] || key,
    }
  })

  const firstDate = dateStringToUnixTimestamp(get(dataPoints, '[0].date', ''))
  const lastDate = dateStringToUnixTimestamp(get(dataPoints, `[${dataPoints.length - 1}].date`, ''))
  const firstYear = moment.unix(firstDate).year()
  const lastYear = moment.unix(lastDate).year()
  const promotionDays = getPromotionDates(firstYear, lastYear)
  const predictionResolution = getPredictionResolutionByDates({
    firstDate: get(dataPoints, '[0].date', ''),
    secondDate: get(dataPoints, '[1].date', ''),
    lastDate: get(dataPoints, `[${dataPoints.length - 1}].date`, ''),
    secondToLastDate: get(dataPoints, `[${dataPoints.length - 2}].date`, ''),
  })

  const dataset = dataPoints.map((item) => {
    const unixDate = dateStringToUnixTimestamp(item.date)
    const promotionsAtDate = promotionDays.filter((promotionDay) => {
      if (predictionResolution === TIME_RESOLUTION.DAILY) {
        return unixDate >= promotionDay.from && unixDate <= promotionDay.to
      }

      if (predictionResolution === TIME_RESOLUTION.WEEKLY) {
        return moment.unix(unixDate).isBetween(moment.unix(promotionDay.from), moment.unix(promotionDay.to), 'week', '[]')
      }

      return moment.unix(unixDate).isBetween(moment.unix(promotionDay.from), moment.unix(promotionDay.to), 'month', '[]')
    })

    return {
      ...item,
      date: unixDate,
      eventKeys: promotionsAtDate.map((promotionDay) => promotionDay.key),
    }
  })

  return { lines, dataset, promotionDays }
}

/**
 * Creates the grid state change handler
 *
 * @param getGridState Function to get the grid state
 * @param getTableDetails Function to get the table details
 * @param receiveGridStateChangeAction Action to receive the grid state change
 * @param requestTableAction Action to request the table data
 * @param requestChartAction Action to request the chart data
 * @param receiveTableAction Action to receive the table action done
 *
 * @returns Generator function for the grid state change handler
 */
export function createGridStateChangeHandler<
  TPayload extends Hera.BaseGridStateChangePayload,
  TState extends Hera.HeraBaseGridState,
  TTable extends { useCaseId: string, tableId: string }
>({
  getGridState,
  getTableDetails,
  receiveGridStateChangeAction,
  requestTableAction,
  requestChartAction,
  receiveTableAction,
}: {
  getGridState: (state: State) => TState | undefined
  getTableDetails: (state: State) => TTable | undefined
  receiveGridStateChangeAction: (payload: TState) => any
  requestTableAction: (payload: any) => any
  requestChartAction: (payload: any) => any
  receiveTableAction: (payload: any) => any
}) {
  return function* gridStateChangeHandler({ payload }: ActionPayload<TPayload>) {
    try {
      const {
        requestTableData = true,
        requestChartData = true,
        resetPagination = true,
        resetRowSelection = false,
        preselectRows = false,
        ...newGridState
      } = payload

      const state: State = yield select()
      const gridState: TState = yield call(getGridState, state)
      const tableDetails: TTable = yield call(getTableDetails, state)

      if (resetPagination) {
        Object.assign(newGridState, {
          paginationModel: {
            pageSize: newGridState?.paginationModel?.pageSize || gridState?.paginationModel?.pageSize,
            page: 0,
          },
        })
      }

      if (resetRowSelection || preselectRows) {
        Object.assign(newGridState, {
          rowSelectionModel: [],
          rowSelectionModelMode: preselectRows ? 'include' : 'exclude',
        })
      }

      const finalState = {
        ...gridState,
        ...newGridState,
        ...(!requestTableData ? { internalColumnVisibilityModel: newGridState.columnVisibilityModel } : {}),
      }

      yield put(receiveGridStateChangeAction(finalState))

      const tableRequest = {
        useCaseId: tableDetails.useCaseId,
        ...finalState,
      }

      if (requestTableData) {
        yield put(requestTableAction(tableRequest))
      }

      const chartRequest = {
        useCaseId: tableDetails.useCaseId,
        preselectRows,
        ...finalState,
      }

      if (requestChartData) {
        yield put(requestChartAction(chartRequest))
      }

      storeTableStateInStorage(tableDetails.tableId, finalState)
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)

      yield put(receiveTableAction({}))

      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  }
}

/**
 * Creates the chart request handler
 * @param startFetchingAction Start fetching action
 * @param stopFetchingAction Stop fetching action
 * @param getTableFetchingState Function to get the table fetching state
 * @param requestChartAPI Function to request the chart API
 * @param receiveChartAction Action to receive the chart action
 * @param requestGridStateChangeAction Action to request the grid state change
 * @param convertAPIPayload Function to convert the API payload
 * @param receiveGridStateChangeType Type to receive the grid state change
 * @param receiveTableActionType Type to receive the table action
 *
 * @returns Generator function for the chart request handler
 */
export function createChartRequestHandler<
  TPayload extends Hera.BaseChartRequestPayload,
  TRequest extends Hera.BaseChartAPIRequest,
  TResponse extends Hera.BaseChartAPIResponse,
  TStateChangePayload extends Hera.BaseGridStateChangePayload
>({
  startFetchingAction,
  stopFetchingAction,
  getTableFetchingState,
  requestChartAPI,
  receiveChartAction,
  requestGridStateChangeAction,
  convertAPIPayload,
  receiveGridStateChangeType,
  receiveTableActionType,
}: {
  startFetchingAction: () => any
  stopFetchingAction: () => any
  getTableFetchingState: (state: State) => boolean
  requestChartAPI: (payload: TRequest) => Promise<TResponse>
  receiveChartAction: (apiResponse: TResponse | {}) => any
  requestGridStateChangeAction: (payload: TStateChangePayload) => any
  convertAPIPayload: (payload: TPayload, shouldPreselectRows: boolean) => TRequest
  receiveGridStateChangeType: string
  receiveTableActionType: string
}) {
  return function* chartRequestHandler({ payload }: ActionPayload<TPayload>) {
    try {
      yield put(startFetchingAction())

      const shouldPreselectRows = Boolean(payload.preselectRows || (payload.initialization && ((payload.groupingModel || []).length > 0)))
      const reqPayload: TRequest = yield call(convertAPIPayload, payload, shouldPreselectRows)
      const response: TResponse = yield call(requestChartAPI, reqPayload)

      const state: State = yield select()
      const isFetchingTable: boolean = yield call(getTableFetchingState, state)

      if (isFetchingTable) {
        yield take(receiveTableActionType)
      }

      if (shouldPreselectRows) {
        const preselectedRows = Object.keys(response.metaData.legend || {}).map((rowId) => String(rowId))

        yield put(requestGridStateChangeAction({
          resetPagination: false,
          requestTableData: false,
          requestChartData: false,
          rowSelectionModel: preselectedRows,
          rowSelectionModelMode: 'include',
        } as TStateChangePayload))

        yield take(receiveGridStateChangeType)
      }

      yield put(receiveChartAction(response))
    } catch (e) {
      const message = parseAndReportErrorResponse(e, payload)

      yield put(receiveChartAction({}))

      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    } finally {
      yield put(stopFetchingAction())
    }
  }
}

/**
 * Creates the table request handler
 *
 * @param tableName Table name
 * @param tableVersion Table version
 * @param startFetchingAction Start fetching action
 * @param stopFetchingAction Stop fetching action
 * @param getInitialGridState Function to get the initial grid state
 * @param createGridState Function to create the grid state
 * @param requestTableAPI Function to request the table API
 * @param receiveTableAction Action to receive the table action done
 * @param retryAction Retry action
 * @param convertAPIPayload Function to convert the API payload
 *
 * @returns Generator function for the table request handler
 */
export function createTableRequestHandler<
  TPayload extends Hera.BaseTableRequestPayload,
  TRequest extends Hera.BaseTablePaginatedAPIRequest,
  TResponse extends Hera.BaseTablePaginatedAPIResponse,
  TState extends Hera.HeraBaseGridState,
>({
  tableName,
  tableVersion,

  startFetchingAction,
  stopFetchingAction,
  getInitialGridState,
  createGridState,

  requestTableAPI,
  receiveTableAction,
  retryAction,
  convertAPIPayload,
}: {
  tableName: DATA_GRIDS
  tableVersion: number
  startFetchingAction: () => any
  stopFetchingAction: () => any
  getInitialGridState: (payload: TState) => any
  createGridState: (tableId: string, initialState: TState) => any

  requestTableAPI: (payload: TRequest) => Promise<TResponse>
  receiveTableAction: (apiResponse: TResponse | {}) => any
  retryAction: (payload: { useCaseId: string }) => any
  convertAPIPayload: (payload: TPayload) => TRequest
}) {
  return function* tableRequestHandler({ payload }: ActionPayload<any>) {
    const tableId: string = yield call(getDataGridId, tableName, tableVersion, payload.useCaseId)
    const tableFromStorage: TState = yield call(getTableStateFromStorage, tableId)
    const hasTableStateInStorage = Boolean(tableFromStorage)

    try {
      yield put(startFetchingAction())

      const initialGridState: TState = yield call(getInitialGridState, payload)

      const gridStateFromStorage: TState = yield call(createGridState, tableId, initialGridState)

      const reqPayload: TRequest = yield call(convertAPIPayload, payload)

      const response: TResponse = yield call(requestTableAPI, reqPayload)

      const reducerPayload = {
        useCaseId: payload.useCaseId,
        tableId,
        response,
        gridInitialState: gridStateFromStorage,
        initialization: payload.initialization,
      }

      yield put(receiveTableAction(reducerPayload))
    } catch (e) {
      if (hasTableStateInStorage) {
        removeTableStateFromStorage(tableId)

        yield put(retryAction({ useCaseId: payload.useCaseId }))
      } else {
        const message = parseAndReportErrorResponse(e, payload)

        yield put(receiveTableAction({}))

        yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
      }
    } finally {
      yield put(stopFetchingAction())
    }
  }
}

/**
 * Creates reset view generator
 *
 * @param receiveTableAction Action to receive the table action done
 * @param receiveChartAction Action to receive the chart action
 *
 * @returns Generator function for the reset view generator
 */
export function createResetViewGenerator({
  receiveTableAction,
  receiveChartAction,
}: {
  receiveTableAction: (payload: {}) => any
  receiveChartAction: (payload: {}) => any
}) {
  return function* resetViewGenerator() {
    yield put(receiveTableAction({}))

    yield put(receiveChartAction({}))
  }
}

/**
 * Default formatter for the insights tooltip value
 *
 * @param intl IntlShape
 * @param value datapoint value
 * @param options formatter options
 *
 * @returns formatted value
 */
export const defaultInsightsTooltipValueFormatter = (
  intl: IntlShape,
  value: number | null,
  options: {
    showIntervals: boolean
  },
) => {
  const MIN_INTERVAL = 0
  const MAX_INTERVAL = 3

  if (value === null) {
    return intl.formatMessage({ id: 'common.na' })
  }

  /**
   * In case value is float, we want to show the interval. Only for limited range of values.
   */
  if (options && options.showIntervals && !Number.isInteger(value) && value >= MIN_INTERVAL && value <= MAX_INTERVAL) {
    const formattedFrom = Math.floor(value)
    const formattedTo = formattedFrom + 1

    return intl.formatMessage({ id: 'insights.chart.tooltip.interval' }, { from: formattedFrom, to: formattedTo })
  }

  return defaultNumberFormatter(value, { intl })
}
