import React from 'react'

const CodeIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={CodeIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M5.14285 13.8751L0.642853 9.37512L5.14285 4.87512" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.8571 13.8751L17.3571 9.37512L12.8571 4.87512" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default CodeIcon
