import ReactGA from 'react-ga4'
import posthog from 'posthog-js'

import { Action, createBrowserHistory, Location } from 'history'
import { cancelPendingRequests } from '@utils/request-cancelation.utils'
import { CONTENT_LAYOUT_ID } from '@constants/ui.constants'

/**
 * Reacts on history changes
 *
 * @param {Object} location History Location
 *
 * @returns {Void}
 */
export const historyListener = (location: Location, action: Action) => {
  const element = document?.getElementById(CONTENT_LAYOUT_ID)

  if (action !== 'REPLACE') {
    element?.scrollTo(0, 0)

    cancelPendingRequests(location)

    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document?.title || 'n/a' })

    posthog.capture('$pageview')
  }
}

/**
 * Browser History
 */
export const history = createBrowserHistory()
