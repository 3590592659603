import React from 'react'

const Y42Icon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={Y42Icon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M3.98941 12.0894V8.61438C3.72144 8.62298 3.4621 8.69218 3.22006 8.83476L0.812576 10.2439C0.3112 10.5378 0 11.0823 0 11.6745V14.4969C0 15.0847 0.3112 15.6294 0.812576 15.9233L3.22006 17.3323C3.72144 17.6262 4.33951 17.6262 4.84521 17.3323L7.25271 15.9233C7.50772 15.7719 7.71081 15.5602 7.85347 15.3095L4.80199 13.5201C4.30061 13.2218 3.98941 12.6773 3.98941 12.0894Z" fill="black" fillOpacity="0.4"/>
      <path d="M17.1893 10.2439L14.7819 8.83476C14.5441 8.69652 14.2804 8.62298 14.0125 8.61438V12.0894C14.0125 12.6773 13.7013 13.2218 13.1999 13.5157L10.1484 15.3052C10.2868 15.5558 10.4942 15.7719 10.7492 15.9189L13.1567 17.328C13.6581 17.6219 14.2762 17.6219 14.7819 17.328L17.1893 15.9189C17.6907 15.625 18.0019 15.0804 18.0019 14.4926V11.6702C18.0062 11.0823 17.6907 10.5378 17.1893 10.2439Z" fill="black" fillOpacity="0.4"/>
      <path d="M9.81399 4.71123L12.9087 6.52224C12.9908 6.32341 13.0383 6.1073 13.0383 5.88254V3.06013C13.0383 2.47231 12.7271 1.92771 12.2258 1.6338L9.81399 0.220434C9.31258 -0.0734778 8.69454 -0.0734778 8.18879 0.220434L5.78133 1.62947C5.27995 1.92339 4.96875 2.46799 4.96875 3.05581V5.87823C4.96875 6.10298 5.0163 6.31909 5.09842 6.51791L8.19312 4.7069C8.6902 4.41731 9.31258 4.41731 9.81399 4.71123Z" fill="black" fillOpacity="0.4"/>
      <path d="M8.07233 11.6743C8.07233 11.0864 7.76114 10.5419 7.2598 10.248L4.85231 8.83893C4.58433 8.68333 4.29042 8.61414 3.99219 8.61848V12.0935C3.99219 12.6814 4.30338 13.226 4.80476 13.5199L7.85622 15.3093C7.99027 15.0673 8.06806 14.7863 8.06806 14.4967V11.6743H8.07233Z" fill="black"/>
      <path d="M14.0095 12.089V8.61397C13.7156 8.60529 13.4173 8.67875 13.1494 8.83435L10.7462 10.2434C10.2449 10.5374 9.93359 11.0819 9.93359 11.6697V14.4921C9.93359 14.7818 10.0071 15.0627 10.1454 15.3047L13.1969 13.5153C13.7026 13.2214 14.0095 12.6768 14.0095 12.089Z" fill="black"/>
      <path d="M5.78098 7.31327L8.18845 8.7223C8.68987 9.01621 9.30791 9.01621 9.81359 8.7223L12.2211 7.31327C12.5323 7.13172 12.77 6.85078 12.904 6.52661L9.80932 4.71128C9.30791 4.41737 8.68987 4.41737 8.18411 4.71128L5.09375 6.52661C5.23206 6.84645 5.46978 7.12738 5.78098 7.31327Z" fill="black"/>

    </svg>
  )
}

export default Y42Icon
