import React, { useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'

import {
  prepareConnectOverviewAction,
  resetConnectOverviewAction,
} from '@redux/modules/use-case/use-case.actions'

import PageLayoutContainer from '@containers/application/PageLayout'
import DataSourcesModalContainer from '@containers/modals/data-sources-modal'
import ConnectParameterModalContainer from '@containers/modals/connect-parameter-modal'
import ConnectGroupingModalContainer from '@containers/modals/connect-grouping-modal'
import ConnectDataSourceModalContainer from '@containers/modals/connect-data-source-modal'
import AuthDataSourceModalContainer from '@containers/modals/auth-data-source-modal'
import ParetosDataSourceModalContainer from '@containers/modals/paretos-data-source-modal'
import UseCaseTrainingDataModalContainer from '@containers/modals/use-case-training-data-modal'
import ConnectParameterCreationModalContainer from '@containers/modals/connect-parameter-creation-modal'
import DataSourceConnectedModalContainer from '@containers/modals/data-source-connected-modal'
import UseCaseDataTypesModalContainer from '@containers/modals/use-case-data-types'
import UseCaseDataTypeVersionsModalContainer from '@containers/modals/use-case-data-type-versions'
import ListPredefinedParametersModalContainer from '@containers/modals/list-predefined-parameters-modal'
import ManagePredefinedParametersModalContainer from '@containers/modals/manage-predefined-parameters-modal'

import {
  getConnectOverviewNodes,
  getConnectOverviewEdges,
  getUseCaseItem,
  isFetchingConnectOverviewElements,
} from '@redux/modules/use-case/use-case.selectors'

import ConnectViewComponent from '@components/connect-view/view/ConnectView'
import ConnectBreadcrumbsActionComponent from '@components/connect-view/view/ConnectBreadcrumbsAction'

const ConnectContainer = () => {
  const EXPORT_ID = 'ConnectContainer'
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const connectOverviewNodes = useSelector((state) => getConnectOverviewNodes(state))
  const connectOverviewEdges = useSelector((state) => getConnectOverviewEdges(state))
  const isFetching = useSelector((state) => isFetchingConnectOverviewElements(state))

  useLayoutEffect(() => {
    dispatch(prepareConnectOverviewAction({ useCaseId: usecase, recalculation: false }))

    return () => {
      dispatch(resetConnectOverviewAction())
    }
  }, [usecase, dispatch])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'connect.browser_tab.title' }, { name: useCase.name })}
      isFetching={isFetching}
      overlayFetching={true}
      pageBarRightSideBlocks={<ConnectBreadcrumbsActionComponent exportId={EXPORT_ID} />}
    >
      <ConnectViewComponent
        exportId={EXPORT_ID}
        nodes={connectOverviewNodes}
        edges={connectOverviewEdges}
        isFetching={isFetching}
      />

      <ConnectParameterModalContainer />

      <ConnectGroupingModalContainer />

      <UseCaseTrainingDataModalContainer />

      <UseCaseDataTypesModalContainer />

      <UseCaseDataTypeVersionsModalContainer />

      <DataSourcesModalContainer />

      <ConnectDataSourceModalContainer />

      <AuthDataSourceModalContainer />

      <ParetosDataSourceModalContainer />

      <ConnectParameterCreationModalContainer />

      <ListPredefinedParametersModalContainer />

      <ManagePredefinedParametersModalContainer />

      <DataSourceConnectedModalContainer />
    </PageLayoutContainer>
  )
}

export default ConnectContainer
