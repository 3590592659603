import React from 'react'

import {
  Typography,
  Box,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material'

import { DEFAULT_PADDING } from '@constants/ui.constants'

export interface UseCaseListItemBlockComponentProps {
  /**
   * Name of the block
   */
  name?: string
  /**
   * Title of the block
   */
  title: string
  /**
   * Content of the block
   */
  children: React.ReactNode
  /**
   * if true, the block will be hidden
   */
  hidden?: boolean
  /**
   * if true, the block will have a border left
   */
  borderLeft?: boolean
  /**
   * Custom color of the title
   */
  titleColor?: string
  /*
   * Custom bg color
   */
  bgColor?: string
  /**
   * Custom min width
   */
  minWidth?: string
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

export const UseCaseListItemBlockComponent: React.FC<UseCaseListItemBlockComponentProps> = ({
  name,
  title,
  children,
  hidden,
  borderLeft = true,
  titleColor,
  bgColor,
  minWidth = '230px',
  sx,
}) => {
  const theme = useTheme()

  if (hidden) {
    return null
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      data-testid={name || UseCaseListItemBlockComponent.name}
      padding={DEFAULT_PADDING.LARGE}
      justifyContent='space-between'
      className='useCaseListItemBlock'
      height='95px'
      borderLeft={borderLeft ? `1px solid ${theme.palette.new.business_black_20}` : 'none'}
      sx={{
        background: bgColor,
        minWidth,
        ...sx,
      }}
    >
      <Typography
        variant='overline'
        noWrap={true}
        color={titleColor}
      >
        {title}
      </Typography>

      <Box>
        {children}
      </Box>
    </Box>
  )
}

export default UseCaseListItemBlockComponent
