import React from 'react'
import { Box } from '@mui/material'
import LoadingTooLongTextComponent from '@base/loading/LoadingTooLongText'
import LoadingCloudComponent, { LoadingCloudComponentProps } from '@base/loading/LoadingCloud/LoadingCloud.component'
import { PARETOS_GRADIENT_20, TOPBAR_HEIGHT } from '@constants/ui.constants'

export interface LoadingFallbackComponentProps {
  /**
   * Custom min height
   *
   * @default `calc(100vh - ${TOPBAR_HEIGHT}px)`
   */
  minHeight?: string
  /**
   * If true, the long request note will be shown
   *
   * @default false
   */
  showLongRequestNote?: boolean
  /**
   * If true, the fallback will be fluid, and will fill the container
   *
   * @default false
   */
  fluid?: boolean
  /**
   * Size of the cloud
   *
   * @default 'large'
   */
  size?: LoadingCloudComponentProps['size']
}

const LoadingFallbackComponent: React.FC<LoadingFallbackComponentProps> = ({
  minHeight = `calc(100vh - ${TOPBAR_HEIGHT}px)`,
  showLongRequestNote = false,
  fluid = false,
  size = 'large',
}) => {
  const styles = fluid ? {
    display: 'flex',
    minHeight: '100%',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: PARETOS_GRADIENT_20,
  } : {
    display: 'flex',
    minHeight,
    justifyContent: 'center',
    alignItems: 'center',
    background: PARETOS_GRADIENT_20,
  }

  return (
    <Box
      data-testid={LoadingFallbackComponent.name}
      sx={styles}
    >
      <LoadingCloudComponent size={size} />

      <LoadingTooLongTextComponent enabled={showLongRequestNote} />
    </Box>
  )
}

export default LoadingFallbackComponent
