import { handleActions } from 'redux-actions'
import { CustomerState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  CHANGE_USER_INFO_DONE,
  CHANGE_COMPANY_ID_DONE,
  REQUEST_COMPANIES_LIST_DONE,
  REQUEST_COMPANY_USERS_LIST_DONE,
  START_FETCHING_CUSTOMER,
  STOP_FETCHING_CUSTOMER,
  REQUEST_COMPANY_ITEM_DONE,
} from './customer.action-types'

import {
  initialState,
  changeUserInfoReducer,
  changeCompanyIdReducer,
  receiveCompaniesListReducer,
  receiveCompanyUsersListReducer,
  receiveCompanyItemReducer,
} from './customer.reducers'

export default handleActions<CustomerState, any>({
  [REQUEST_COMPANY_ITEM_DONE]: receiveCompanyItemReducer,
  [CHANGE_COMPANY_ID_DONE]: changeCompanyIdReducer,
  [REQUEST_COMPANIES_LIST_DONE]: receiveCompaniesListReducer,
  [REQUEST_COMPANY_USERS_LIST_DONE]: receiveCompanyUsersListReducer,
  [CHANGE_USER_INFO_DONE]: changeUserInfoReducer,
  [START_FETCHING_CUSTOMER]: startFetchingAction<CustomerState>,
  [STOP_FETCHING_CUSTOMER]: stopFetchingAction<CustomerState>,
}, initialState)
