export enum STOCK_STATUS_OPTIONS {
  GOOD = 'Good',
  UNDERSTOCKED = 'Understocked',
  OVERSTOCKED = 'Overstocked',
}

export enum OPTIMIZE_RESTOCK_SELECTOR_TYPES {
  REPLENISHMENT_COST = 'REPLENISHMENT_COST',
  REVENUE = 'REVENUE',
  TRADE_OFFS = 'TRADE_OFFS',
}

export const INVENTORY_DEMO_USE_CASES_IDS = [
  '29fe90cc-8f9d-4419-b780-446a8ba68e5c', '99042932-7cbf-4a93-bbcd-e81f2a3eea44',
  'de0c76f3-eb5c-4025-bb0d-373499b73b76', 'aa7a26a1-2423-447a-9904-266e7ac4abc9',
]

export const REVENUE_MEDIUM = 35000000
export const REPLENISHMENT_COST_MEDIUM = 30000000
export const REVENUE_MIN_MAX = [30000000, 40000000]
export const REPLENISHMENT_COST_MIN_MAX = [20000000, 40000000]

export const RANGE_SELECTOR_REPLENISHMENT_COST_TO_REVENUE = {
  20000000: 30000000,
  22000000: 32500000,
  24000000: 35100000,
  26000000: 36400000,
  28000000: 37700000,
  30000000: 38800000,
  32000000: 39500000,
  34000000: 39600000,
  36000000: 39700000,
  38000000: 39740000,
  40000000: 39800000,
}

export const REPLENISHMENT_COST_RANGES = Object.keys(RANGE_SELECTOR_REPLENISHMENT_COST_TO_REVENUE)

export const RANGE_SELECTOR_REVENUE_TO_REPLENISHMENT_COST = {
  30000000: 20000000,
  31000000: 20800000,
  32000000: 21500000,
  33000000: 22200000,
  34000000: 23200000,
  35000000: 23700000,
  36000000: 25600000,
  37000000: 26900000,
  38000000: 28500000,
  39000000: 30800000,
  39800000: 39600000,
}

export const REVENUERANGES = Object.keys(RANGE_SELECTOR_REVENUE_TO_REPLENISHMENT_COST)

export const TRADE_OFFS_LABEL_REPLENISHMENT_COST = 'Replenishment Cost'
export const TRADE_OFFS_LABEL_REVENUE = 'Revenue'
