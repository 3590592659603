import React, { useLayoutEffect } from 'react'
import { Box } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import { getUseCaseItem, isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'

import PageLayoutContainer from '@containers/application/PageLayout'

import {
  isFetchingKPIItemsArtifact,
  isFetchingKPIsPerItemArtifact,
  isFetchingVisualisationsArtifact,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import ComparisonToggleContainer from '@containers/shared/artifacts/ComparisonToggle'
import KPIItemsArtifactContainer from '@containers/shared/artifacts/KPIItemsArtifact'
import VisualisationsArtifactContainer from '@containers/shared/artifacts/VisualisationsArtifact'

const AaResultsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const isFetchingUseCaseFlag = useSelector((state) => isFetchingUseCase(state))
  const isFetchingKPIItemsArtifactFlag = useSelector((state) => isFetchingKPIItemsArtifact(state))
  const isFetchingVisualisationsArtifactFlag = useSelector((state) => isFetchingVisualisationsArtifact(state))
  const isFetchingKPIsPerItemArtifactFlag = useSelector((state) => isFetchingKPIsPerItemArtifact(state))
  const isFetching = isFetchingUseCaseFlag || isFetchingKPIItemsArtifactFlag || isFetchingVisualisationsArtifactFlag || isFetchingKPIsPerItemArtifactFlag
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'recommendation.results.browser_tab.title' }, { name: useCase.name })}
      isFetching={isFetching}
      overlayFetching={true}
      pageBarRightSideBlocks={(
        <ComparisonToggleContainer />
      )}
    >
      <KPIItemsArtifactContainer />

      <Box mt={2} />

      <VisualisationsArtifactContainer />
    </PageLayoutContainer>
  )
}

export default AaResultsContainer
