import React from 'react'

const AddFlowIcon: React.FC<Common.IconProps> = ({
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={AddFlowIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M7 1V13" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 6.96423H13" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AddFlowIcon
