import { handleActions } from 'redux-actions'
import { SnapshotsState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_SNAPSHOTS,
  RECEIVE_SNAPSHOT,
  START_FETCHING_SNAPSHOTS,
  STOP_FETCHING_SNAPSHOTS,
} from './snapshots.action-types'

import {
  initialState,
  receiveSnapshotReducer,
  receiveSnapshotsReducer,
} from './snapshots.reducers'

export default handleActions<SnapshotsState, any>({
  [RECEIVE_SNAPSHOTS]: receiveSnapshotsReducer,
  [RECEIVE_SNAPSHOT]: receiveSnapshotReducer,
  [START_FETCHING_SNAPSHOTS]: startFetchingAction<SnapshotsState>,
  [STOP_FETCHING_SNAPSHOTS]: stopFetchingAction<SnapshotsState>,
}, initialState)
