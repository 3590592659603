import React from 'react'
import { useIntl } from 'react-intl'

import {
  Box,
  Typography,
  useTheme,
} from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import InfoButtonComponent from '@base/buttons/InfoButton'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold'

import { getUseCaseForecastAddedValue } from '@utils/use-cases.utils'
import { DEFAULT_PADDING, PARETOS_GRADIENT } from '@constants/ui.constants'
import UseCaseListItemBlockComponent from '@components/dashboard/UseCaseListItemBlock'

export interface ForecastAddedValueComponentProps {
  /**
   * Use case details
   */
  useCase: UseCase.DetailsExtended,
}

const ForecastAddedValueComponent: React.FC<ForecastAddedValueComponentProps> = ({
  useCase,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  if (!useCase.forecastValue || !useCase.forecastParameters || !useCase.forecastParameters.displayForecastValue) {
    return null
  }

  const {
    overestimateCostPerUnit = 0,
    underestimateCostPerUnit = 0,
  } = useCase.forecastValue

  return (
    <UseCaseListItemBlockComponent
      title={intl.formatMessage({ id: 'use_cases.form.forecastAddedValue.title' })}
      bgColor={PARETOS_GRADIENT}
      titleColor={theme.palette.new.white_60}
    >
      <Box
        display='flex'
        alignItems='center'
        width='100%'
        gap={DEFAULT_PADDING.SMALL}
      >
        <Typography
          variant='body1'
          color={theme.palette.new.white}
        >
          {
            intl.formatMessage({ id: 'use_cases.form.forecastAddedValue.value' }, {
              value: getUseCaseForecastAddedValue(useCase.forecastValue),
            })
          }
        </Typography>

        <InfoButtonComponent
          name='forecastAddedValueHelp'
          IconComponentProps={{
            detailsFill: '#FFFFFF',
          }}
          tooltip={intl.formatMessage({ id: 'use_cases.form.forecastAddedValue.tooltip' }, {
            underestimate: <IntlFormatBoldComponent>{defaultNumberFormatter(underestimateCostPerUnit)}</IntlFormatBoldComponent>,
            overestimate: <IntlFormatBoldComponent>{defaultNumberFormatter(overestimateCostPerUnit)}</IntlFormatBoldComponent>,
          })}
        />
      </Box>
    </UseCaseListItemBlockComponent>
  )
}

export default ForecastAddedValueComponent
