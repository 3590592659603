import React from 'react'

const AlertWarningIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#000000',
}) => {
  return (
    <svg data-testid={AlertWarningIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M9 6.42857V10.2857M9.64285 14.1429C9.64285 14.4979 9.35504 14.7857 9 14.7857C8.64496 14.7857 8.35714 14.4979 8.35714 14.1429C8.35714 13.7878 8.64496 13.5 9 13.5C9.35504 13.5 9.64285 13.7878 9.64285 14.1429ZM10.1443 1.35C10.0364 1.1394 9.87246 0.962657 9.67057 0.839241C9.46867 0.715824 9.23663 0.65052 9 0.65052C8.76336 0.65052 8.53132 0.715824 8.32943 0.839241C8.12753 0.962657 7.96361 1.1394 7.85571 1.35L0.784282 15.4929C0.685655 15.6886 0.638698 15.9063 0.64787 16.1252C0.657043 16.3442 0.722041 16.5572 0.836691 16.744C0.951342 16.9308 1.11184 17.0852 1.30294 17.1925C1.49404 17.2998 1.7094 17.3565 1.92857 17.3571H16.0714C16.2906 17.3565 16.506 17.2998 16.6971 17.1925C16.8882 17.0852 17.0486 16.9308 17.1633 16.744C17.2779 16.5572 17.3429 16.3442 17.3521 16.1252C17.3613 15.9063 17.3143 15.6886 17.2157 15.4929L10.1443 1.35Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertWarningIcon
