import {
  takeEvery, call, put,
  take, select,
} from 'redux-saga/effects'

import { AxiosResponse } from 'axios'
import { push } from 'redux-first-history'

import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { ERR_CANCELED_CODE, REQUEST_CANCELED_BY_USER } from '@utils/request-cancelation.utils'
import { FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME } from '@constants/modals.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { downloadTrainingDataAction } from '@redux/modules/training-files/training-files.actions'
import { downloadArtifactAction } from '@redux/modules/artifacts/artifacts.actions'
import { getFileExtension } from '@utils/files.utils'
import { FILE_PREVIEW_TYPE } from '@constants/files.constants'

import * as ARTIFACTS_API from '@redux/modules/artifacts/artifacts.api'
import * as TRAINING_FILES_API from '@redux/modules/training-files/training-files.api'
import * as API from './file-service.api'

import {
  RECEIVE_FILES_LIST,
  REQUEST_FILES_LIST,
  REQUEST_FILE_DELETE,
  REQUEST_FILE_UPLOAD,
  REQUEST_FILE_DOWNLOAD,
  REQUEST_FILE_PREVIEW,
  CLOSE_FILE_PREVIEW,
  REQUEST_FILES_LIST_REFRESH,
  REQUEST_SORTING_CHANGE,
} from './file-service.action-types'

import {
  startFetchingFilesAction,
  stopFetchingFilesAction,
  receiveFilesListAction,
  receiveSortingChangeAction,
  requestFilesListAction,
  receiveFilePreviewAction,
} from './file-service.actions'

import {
  RequestFilesListActionPayload,
  RequestFileDeleteActionPayload,
  RequestFileDownloadActionPayload,
  RequestFileUploadActionPayload,
  RequestFilePreviewActionPayload,
  RequestSortingChangeActionPayload,
} from './file-service.types'

const DOWNLOAD_FILE_SUCCESS = 'fileManager.list.confirmation.download'
const DELETE_FILE_SUCCESS = 'fileManager.list.confirmation.delete'
const UPLOAD_FILE_SUCCESS = 'fileManager.list.confirmation.upload'

function* fetchFilesListGenerator({ payload } : ActionPayload<RequestFilesListActionPayload>) {
  try {
    if (!payload.silent) {
      yield put(startFetchingFilesAction(REQUEST_FILES_LIST))
    } else if (payload.refresh) {
      yield put(startFetchingFilesAction(REQUEST_FILES_LIST_REFRESH))
    }

    yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId }))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const isAdmin: boolean = yield call(getIsAdmin, state)
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { fileStorageBucket } = useCase

    if (!isAdmin) {
      yield put(push('/'))
    } else {
      const filesList: FileService.FileItem[] = yield call(API.getFilesList, fileStorageBucket, payload.searchPattern)

      yield put(receiveFilesListAction(filesList))

      if (payload.refresh) {
        yield put(changeToastAction({ useIntl: true, message: 'fileManager.list.confirmation.refresh', severity: TOAST_TYPE_SUCCESS }))
      }
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    if (!payload.silent) {
      yield put(stopFetchingFilesAction(REQUEST_FILES_LIST))
    } else if (payload.refresh) {
      yield put(stopFetchingFilesAction(REQUEST_FILES_LIST_REFRESH))
    }
  }
}

function* downloadFileGenerator({ payload }: ActionPayload<RequestFileDownloadActionPayload>) {
  try {
    yield put(startFetchingFilesAction(REQUEST_FILE_DOWNLOAD))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    const isTrainingDataFile = payload.fileType === FILE_PREVIEW_TYPE.TRAINING_FILES
    const isOutputFile = payload.fileType === FILE_PREVIEW_TYPE.OUTPUT_FILES

    if (isTrainingDataFile) {
      yield put(downloadTrainingDataAction({
        useCaseId: useCase.useCaseId,
        trainingDataFileId: payload.filePath,
        fileName: payload.fileName,
      }))
    } else if (isOutputFile) {
      yield put(downloadArtifactAction({
        useCaseId: useCase.useCaseId,
        fileName: payload.fileName,
        artifactId: payload.filePath,
      }))
    } else {
      const downloadToken: string = yield call(API.generateDownloadToken, payload.bucketName || useCase.fileStorageBucket, payload.filePath)

      yield call(API.downloadByToken, {
        downloadToken,
        fileName: payload.fileName,
      })
    }

    yield put(changeToastAction({ useIntl: true, message: DOWNLOAD_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingFilesAction(REQUEST_FILE_DOWNLOAD))
  }
}

function* deleteFileGenerator({ payload }: ActionPayload<RequestFileDeleteActionPayload>) {
  try {
    yield put(startFetchingFilesAction(REQUEST_FILE_DELETE))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield call(API.deleteFile, useCase.fileStorageBucket, payload.filePath)

    yield put(changeToastAction({ useIntl: true, message: DELETE_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestFilesListAction({ useCaseId: useCase.useCaseId, silent: true }))

    yield take(RECEIVE_FILES_LIST)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingFilesAction(REQUEST_FILE_DELETE))
  }
}

function* uploadFileGenerator({ payload }: ActionPayload<RequestFileUploadActionPayload>) {
  try {
    yield put(startFetchingFilesAction(REQUEST_FILE_UPLOAD))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const uploadToken: string = yield call(API.generateUploadToken)

    const { fileKey, overwrite, file } = payload
    const formData = new FormData()

    formData.append('file', file)
    formData.append('overwrite', overwrite ? 'True' : 'False')
    formData.append('fileKey', fileKey)
    formData.append('bucket', useCase.fileStorageBucket)
    formData.append('uploadToken', uploadToken)

    yield call(API.uploadFile, formData, (file?.name || ''), fileKey)

    yield put(changeToastAction({ useIntl: true, message: UPLOAD_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestFilesListAction({ useCaseId: useCase.useCaseId, silent: true }))

    yield take(RECEIVE_FILES_LIST)
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    if (message !== REQUEST_CANCELED_BY_USER && e?.code !== ERR_CANCELED_CODE) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  } finally {
    yield put(stopFetchingFilesAction(REQUEST_FILE_UPLOAD))
  }
}

function* requestFilePreviewGenerator({ payload }: ActionPayload<RequestFilePreviewActionPayload>) {
  try {
    yield put(startFetchingFilesAction(REQUEST_FILE_PREVIEW))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield put(
      setPrimaryModalPageName({
        primaryModalPage: FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME,
        modalDetails: {
          isEditable: payload.isEditable,
          fileType: payload.fileType,
          returnTo: '',
        },
      }),
    )

    const fileExt = getFileExtension(payload.fileName, false)
    const fileExtension = payload.fileExtension || fileExt || ''

    yield put(receiveFilePreviewAction({
      fileName: payload.fileName,
      filePath: payload.filePath,
      fileExtension,
    }))

    let response: AxiosResponse | null = null

    const isTrainingDataFile = payload.fileType === FILE_PREVIEW_TYPE.TRAINING_FILES
    const isOutputFile = payload.fileType === FILE_PREVIEW_TYPE.OUTPUT_FILES

    if (isTrainingDataFile) {
      const downloadToken: string = yield call(TRAINING_FILES_API.getTrainingDataFilesDownloadToken, {
        useCaseId: useCase.useCaseId,
        trainingDataFileId: payload.filePath,
      })

      response = yield call(API.downloadFileForPreviewByToken, downloadToken, payload.fileName)
    } else if (isOutputFile) {
      const downloadToken: string = yield call(ARTIFACTS_API.getArtifactDownloadToken, {
        useCaseId: useCase.useCaseId,
        artifactId: payload.filePath,
      })

      response = yield call(API.downloadFileForPreviewByToken, downloadToken, payload.fileName)
    } else {
      response = yield call(API.downloadFileForPreview, useCase.fileStorageBucket, payload.filePath)
    }

    if (response && response.data) {
      yield put(receiveFilePreviewAction({
        file: response.data,
        contentType: response.headers['content-type'],
        fileName: payload.fileName,
        filePath: payload.filePath,
        fileExtension,
      }))
    } else {
      throw new Error('Internal error')
    }
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    if (message !== REQUEST_CANCELED_BY_USER && e?.code !== ERR_CANCELED_CODE) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
    yield put(setPrimaryModalPageName(null))
  } finally {
    yield put(stopFetchingFilesAction(REQUEST_FILE_PREVIEW))
  }
}

function* requestSortingChangeGenerator({ payload }: ActionPayload<RequestSortingChangeActionPayload>) {
  try {
    yield put(startFetchingFilesAction(REQUEST_SORTING_CHANGE))

    yield put(receiveSortingChangeAction(payload))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingFilesAction(REQUEST_SORTING_CHANGE))
  }
}

function* requestCloseFilePreviewGenerator() {
  yield put(receiveFilePreviewAction(null))

  yield put(setPrimaryModalPageName(null))
}

export function* watchCloseFilePreview() {
  yield takeEvery(CLOSE_FILE_PREVIEW, requestCloseFilePreviewGenerator)
}

export function* watchFetchTrainingData() {
  yield takeEvery(REQUEST_FILES_LIST, fetchFilesListGenerator)
}

export function* watchDownloadFile() {
  yield takeEvery(REQUEST_FILE_DOWNLOAD, downloadFileGenerator)
}

export function* watchDeleteFile() {
  yield takeEvery(REQUEST_FILE_DELETE, deleteFileGenerator)
}

export function* watchUploadFile() {
  yield takeEvery(REQUEST_FILE_UPLOAD, uploadFileGenerator)
}

export function* watchRequestFilePreview() {
  yield takeEvery(REQUEST_FILE_PREVIEW, requestFilePreviewGenerator)
}

export function* watchRequestSortingChange() {
  yield takeEvery(REQUEST_SORTING_CHANGE, requestSortingChangeGenerator)
}
