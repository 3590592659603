export enum HERMES_DIRECTIONS {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION'
}

export enum DESTINATION_TYPES {
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
}

export const HERMES_DESTINATION_OPTIONS = [{
  value: DESTINATION_TYPES.GOOGLE_SHEETS,
  destinationId: 'a4cbd2d1-8dbe-4818-b8bc-b90ad782d12a',
}]

export const EXPORT_VIEW_DESTINATION = HERMES_DESTINATION_OPTIONS[0].destinationId
export const EXPORT_VIEW_DESTINATION_NAME = 'Google Sheet'
