import { useEffect, useState } from 'react'
import eventEmitter from '@utils/emitter.utils'
import { EMITTER_TYPES } from '@constants/emitter.constants'

const useUploadEmitter = ({ uploading } : { uploading?: boolean }) => {
  const [progress, setProgress] = useState(0)
  const [name, setName] = useState('')

  const getEvent = () => {
    if (uploading) {
      return EMITTER_TYPES.FILE_UPLOAD_PROGRESS
    }

    return ''
  }

  const emitterEvent = getEvent()

  useEffect(() => {
    eventEmitter.on(
      emitterEvent,
      (progressEvent) => {
        if (progressEvent.name) {
          setProgress(Math.floor(100 * (progressEvent.loaded / progressEvent.total)))
          setName(progressEvent.name)
        }
      },
    )

    return function cleanup() {
      eventEmitter.off(emitterEvent)
      setProgress(0)
      setName('')
    }
  }, [emitterEvent])

  return {
    progress,
    fileName: name,
  }
}

export default useUploadEmitter
