import { createAction } from 'redux-actions'

import {
  TOGGLE_COMPARISON_MODE,
  START_FETCHING_RECOMMENDATION_ARTIFACTS,
  STOP_FETCHING_RECOMMENDATION_ARTIFACTS,
  REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT,
  RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT,
  REQUEST_KPI_ITEMS_ARTIFACT,
  RECEIVE_KPI_ITEMS_ARTIFACT,
  REQUEST_TOP_ARTICLES_ARTIFACT,
  RECEIVE_TOP_ARTICLES_ARTIFACT,
  REQUEST_VISUALISATIONS_ARTIFACT,
  RECEIVE_VISUALISATIONS_ARTIFACT,
  REQUEST_KPIS_PER_ITEM_ARTIFACT,
  RECEIVE_KPIS_PER_ITEM_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT,
  RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT,
  RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT,
  SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM,
} from './recommendation-artifacts.action-types'

import {
  ReqestKPIItemsArtifactActionPayload,
  ReqestConstraintsAnalyzeArtifactActionPayload,
  RequestTopArticlesArtifactActionPayload,
  RequestVisualisationsArtifactActionPayload,
  ReqestKPIsPerItemArtifactActionPayload,
  ReqestArticlesOverviewTableArtifactActionPayload,
  ReqestArticlesOverviewDetailsArtifactActionPayload,
} from './recommendation-artifacts.types'

export const requestConstraintsAnalyzeArtifactAction = createAction<ReqestConstraintsAnalyzeArtifactActionPayload>(REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT)
export const receiveConstraintsAnalyzeArtifactActionDone = createAction(RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT)

export const requestKPIItemsArtifactAction = createAction<ReqestKPIItemsArtifactActionPayload>(REQUEST_KPI_ITEMS_ARTIFACT)
export const receiveKPIItemsActionArtifactDone = createAction(RECEIVE_KPI_ITEMS_ARTIFACT)

export const requestKPIsPerItemArtifactAction = createAction<ReqestKPIsPerItemArtifactActionPayload>(REQUEST_KPIS_PER_ITEM_ARTIFACT)
export const receiveKPIsPerItemArtifactActionDone = createAction(RECEIVE_KPIS_PER_ITEM_ARTIFACT)

export const startFetchingRecommendationArtifacts = createAction<string>(START_FETCHING_RECOMMENDATION_ARTIFACTS)
export const stopFetchingRecommendationArtifacts = createAction(STOP_FETCHING_RECOMMENDATION_ARTIFACTS)

export const requestTopArticlesArtifactAction = createAction<RequestTopArticlesArtifactActionPayload>(REQUEST_TOP_ARTICLES_ARTIFACT)
export const receiveTopArticlesArtifactActionDone = createAction(RECEIVE_TOP_ARTICLES_ARTIFACT)

export const requestVisualisationsArtifactAction = createAction<RequestVisualisationsArtifactActionPayload>(REQUEST_VISUALISATIONS_ARTIFACT)
export const receiveVisualisationsArtifactActionDone = createAction(RECEIVE_VISUALISATIONS_ARTIFACT)

export const requestArticlesOverviewArtifactAction = createAction<ReqestArticlesOverviewTableArtifactActionPayload>(REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT)
export const receiveArticlesOverviewArtifactActionDone = createAction(RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT)

export const requestArticlesOverviewDetailsArtifactAction = createAction<ReqestArticlesOverviewDetailsArtifactActionPayload>(REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT)
export const receiveArticlesOverviewDetailsArtifactActionDone = createAction(RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT)
export const selectArticlesOverviewDetailsItemAction = createAction<ReqestArticlesOverviewDetailsArtifactActionPayload['itemId']>(SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM)

export const toggleComparisonModeAction = createAction(TOGGLE_COMPARISON_MODE)
