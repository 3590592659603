import React from 'react'

const TikTokblackIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={TikTokblackIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M13.2487 3.1629C12.6043 2.42559 12.2493 1.47934 12.2497 0.5H11.4675C11.6721 1.59376 12.3158 2.55616 13.2487 3.1629ZM6.02467 8.9133C4.66978 8.91487 3.57189 10.0128 3.57031 11.3677C3.5711 12.2798 4.07761 13.1164 4.88562 13.5402C4.58372 13.1239 4.42125 12.6231 4.42125 12.1089C4.42263 10.754 5.52052 9.65573 6.87561 9.65415C7.12847 9.65415 7.37089 9.6959 7.59972 9.76778V6.78703C7.35986 6.75296 7.11783 6.73543 6.87561 6.73425C6.83307 6.73425 6.79112 6.73662 6.74898 6.7374V9.02693C6.51463 8.95249 6.27044 8.91408 6.02467 8.9133Z" fill="black"/>
      <path d="M15.4609 4.47129V6.74053C13.9467 6.74053 12.5443 6.25628 11.3988 5.43429V11.3678C11.3988 14.3311 8.98835 16.7419 6.02512 16.7419C4.87997 16.7419 3.81811 16.3805 2.94531 15.7677C3.95971 16.8616 5.38411 17.4831 6.87586 17.4827C9.83909 17.4827 12.2499 15.0719 12.2499 12.1091V6.17554C13.4333 7.02648 14.8545 7.48355 16.312 7.48178V4.56129C16.0198 4.56129 15.7356 4.52959 15.4607 4.4707" fill="black"/>
      <path d="M11.3966 11.3677V5.43411C12.5799 6.28525 14.0012 6.74213 15.4587 6.74036V4.47132C14.5993 4.286 13.8232 3.82696 13.2468 3.1629C12.3139 2.55616 11.6701 1.59376 11.4655 0.5H9.32802L9.32349 12.2011C9.27445 13.5114 8.19586 14.5633 6.87366 14.5633C6.08456 14.5625 5.3439 14.1816 4.88426 13.5404C4.07625 13.1168 3.56955 12.2802 3.56857 11.3678C3.57014 10.013 4.66803 8.91507 6.02292 8.91349C6.27539 8.91349 6.51781 8.95485 6.74704 9.02712V6.7376C3.8423 6.80574 1.5 9.18782 1.5 12.1089C1.5 13.5215 2.04885 14.8074 2.94311 15.7679C3.84466 16.4026 4.9205 16.7427 6.02292 16.7417C8.98634 16.7417 11.3966 14.3309 11.3966 11.3677Z" fill="black"/>

    </svg>
  )
}

export default TikTokblackIcon
