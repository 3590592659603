import React from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { GridPagination, GridSlotProps } from '@mui/x-data-grid-premium'
import { defaultNumberFormatter } from '@utils/analysis.utils'

import {
  DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_FONT_SIZE,
  DEFAULT_BUTTON_TRANSITION, DEFAULT_PADDING,
} from '@constants/ui.constants'

import ChevronRightIcon from '@icons/chevronRight.icon'
import ArrowLastIcon from '@icons/arrowLast.icon'
import ArrowFirstIcon from '@icons/arrowFirst.icon'
import ChevronLeftIcon from '@icons/chevronLeft.icon'
import IconButtonComponent from '@base/buttons/IconButton'
import ChevronIcon from '@icons/flow/chevron.icon'

export interface PaginationActionsComponentProps {
  count: number,
  onPageChange: (event: any, page: number) => void,
  page: number,
  rowsPerPage: number,
  nextIconButtonProps?: {
    disabled?: boolean,
  },
  backIconButtonProps?: {
    disabled?: boolean,
  },
}

const PaginationActionsComponent: React.FC<PaginationActionsComponentProps> = ({
  count,
  onPageChange,
  page,
  rowsPerPage,
  nextIconButtonProps,
  backIconButtonProps,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const lastPage = Math.ceil(count / rowsPerPage) - 1

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  const handleGoToFirstPage = (event: any) => {
    onPageChange(event, 0)
  }

  const handleGoToLastPage = (event: any) => {
    onPageChange(event, lastPage)
  }

  const isLastPage = page >= lastPage

  return (
    <Box
      sx={{
        flexShrink: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'flex',
        gap: DEFAULT_PADDING.MEDIUM,
      }}
      data-testid={PaginationActionsComponent.name}
    >
      <IconButtonComponent
        name='firstPage'
        color='tertiary'
        onClick={handleGoToFirstPage}
        disabled={backIconButtonProps?.disabled || (page === 0)}
        aria-label={intl.formatMessage({ id: 'common.button.firstPage' })}
        label={intl.formatMessage({ id: 'common.button.firstPage' })}
        IconComponent={ArrowFirstIcon}
      />

      <IconButtonComponent
        name='previousPage'
        color='tertiary'
        onClick={handleBackButtonClick}
        disabled={backIconButtonProps?.disabled || (page === 0)}
        aria-label={intl.formatMessage({ id: 'common.button.previousPage' })}
        label={intl.formatMessage({ id: 'common.button.previousPage' })}
        IconComponent={ChevronLeftIcon}
      />

      <IconButtonComponent
        name='nextPage'
        color='tertiary'
        onClick={handleNextButtonClick}
        disabled={nextIconButtonProps?.disabled || isLastPage}
        aria-label={intl.formatMessage({ id: 'common.button.nextPage' })}
        label={intl.formatMessage({ id: 'common.button.nextPage' })}
        IconComponent={ChevronRightIcon}
      />

      <IconButtonComponent
        name='lastPage'
        color='tertiary'
        onClick={handleGoToLastPage}
        disabled={nextIconButtonProps?.disabled || isLastPage}
        aria-label={intl.formatMessage({ id: 'common.button.lastPage' })}
        label={intl.formatMessage({ id: 'common.button.lastPage' })}
        IconComponent={ArrowLastIcon}
      />
    </Box>
  )
}

const DataGridPaginationActionsComponent = (props: GridSlotProps['pagination']) => {
  const intl = useIntl()
  const theme = useTheme()

  const toolbarStyles = {
    '& .MuiTablePagination-selectLabel': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
    '& .MuiTablePagination-input': {
      ml: '10px',
      mr: '20px',
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
  }

  const slotProps = {
    select: {
      MenuProps: {
        sx: {
          '& .MuiPaper-root': {
            transform: 'translate(0px, 2px) !important',
          },
        },
      },
      IconComponent: ChevronIcon,
      sx: {
        '& .MuiSelect-select.MuiTablePagination-select.MuiInputBase-input[aria-expanded="true"]': {
          outline: `1px solid ${theme.palette.new.business_black}`,
          backgroundColor: theme.palette.new.smokey_silver,
        },
        '& .MuiSelect-select.MuiTablePagination-select.MuiInputBase-input': {
          fontSize: DEFAULT_BUTTON_FONT_SIZE,
          transition: DEFAULT_BUTTON_TRANSITION,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          pt: '0px',
          pb: '0px',
          pl: '10px',
          pr: '40px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          outline: `1px solid ${theme.palette.new.transparent}`,
          '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.new.business_black_10,
          },
          '&:focus-visible': {
            opacity: 0.8,
            backgroundColor: theme.palette.new.business_black_10,
          },
          '&:active': {
            opacity: 1,
          },
        },
        '& .MuiTablePagination-selectIcon': {
          top: 'unset',
          mr: '10px',

          '&.MuiSelect-iconOpen': {
            top: 'unset',
          },
        },
        '&.Mui-disabled': {
          pointerEvents: 'none',
          backgroundColor: theme.palette.new.business_black_5,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          '& .MuiTablePagination-selectIcon > path': {
            stroke: theme.palette.new.business_black_20,
          },
        },
      },
    },
  }

  const labelDisplayedRows = ({
    from,
    to,
    count,
  }: {
    from: number,
    to: number,
    count: number,
  }) => {
    const formattedFrom = defaultNumberFormatter(from)
    const formattedTo = defaultNumberFormatter(to)
    const formattedCount = defaultNumberFormatter(count)

    return intl.formatMessage({ id: 'common.pagination' }, { from: formattedFrom, to: formattedTo, count: formattedCount })
  }

  return (
    <GridPagination
      ActionsComponent={PaginationActionsComponent}
      labelDisplayedRows={labelDisplayedRows}
      slotProps={slotProps}
      sx={toolbarStyles}
      {...props}
    />
  )
}

export default DataGridPaginationActionsComponent
