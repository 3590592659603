import { handleActions } from 'redux-actions'
import { ArtifactsState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_ARTIFACTS_LIST,
  START_FETCHING_ARTIFACTS,
  STOP_FETCHING_ARTIFACTS,
} from './artifacts.action-types'

import {
  initialState,
  receiveArtifactsList,
} from './artifacts.reducers'

export default handleActions<ArtifactsState, any>({
  [RECEIVE_ARTIFACTS_LIST]: receiveArtifactsList,
  [START_FETCHING_ARTIFACTS]: startFetchingAction<ArtifactsState>,
  [STOP_FETCHING_ARTIFACTS]: stopFetchingAction<ArtifactsState>,
}, initialState)
