import React from 'react'
import { Box, useTheme } from '@mui/material'

import { SOURCE_TYPE_SELECTOR_ID } from '@components/connect-view/parameters/ParameterTemplateSelector'

import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'

const ParameterSelectorListBoxComponent = React.forwardRef((props: any, ref) => {
  const { children, id, ...rest } = props
  const theme = useTheme()
  const isSourceTypeSelector = id?.includes(SOURCE_TYPE_SELECTOR_ID)

  return (
    <Box component='ul' {...rest} id={id} ref={ref} data-testid={ParameterSelectorListBoxComponent.name}>
      {children}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: theme.spacing(20),
          padding: theme.spacing(0, 3),
          width: '100%',
          height: '100%',
          borderTop: isSourceTypeSelector ? 'none' : `1px solid ${theme.palette.new.grey_a}`,
        }}
      >
        <ParameterSelectorNoOptionSectionComponent />
      </Box>
    </Box>
  )
})

export default ParameterSelectorListBoxComponent
