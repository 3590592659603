import React from 'react'

const ExpandLessIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ExpandLessIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M2 12L8.62308 5.37692C8.67142 5.32541 8.72981 5.28436 8.79464 5.25629C8.85946 5.22823 8.92936 5.21375 9 5.21375C9.07064 5.21375 9.14054 5.22823 9.20536 5.25629C9.27019 5.28436 9.32858 5.32541 9.37692 5.37692L16 12" stroke={detailsFill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ExpandLessIcon
