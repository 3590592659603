import { ExportToCsv } from 'export-to-csv'
// @ts-ignore
import { saveAsPng } from 'save-html-as-image'
import { EXPORT_AS_CSV, EXPORT_AS_PNG } from '@constants/export.constants'

export interface ExportData {
  selector?: HTMLElement,
  js?: object,
}

/**
 * @function getExportToCsvConfig Returns CSV config
 *
 * @param {String} title File Name
 *
 * @return {Object} Config Object
 */
export const getExportToCsvConfig = (title: string) => ({
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  filename: title,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
})

/**
 * @function exportTo Exports file to SVG or PNG
 *
 * @param {String} type Export Type
 * @param {String} title File Name
 * @param {Object} data Data to export / Selector
 */
export const exportTo = (type: string, title: string, data: ExportData = {}) => {
  switch (type) {
    case EXPORT_AS_PNG:
      saveAsPng(data.selector, { filename: title, printDate: false })
      break

    case EXPORT_AS_CSV:
    default: {
      const config = getExportToCsvConfig(title)

      const csvExporter = new ExportToCsv(config)

      csvExporter.generateCsv(data.js)
      break
    }
  }
}

/**
 * @function handleExport Handles Export
 *
 * @param type Export Type
 * @param fileName File Name
 * @param data Data to export
 * @param exportId Export ID
 *
 */
export const handleExport = ({
  type,
  fileName,
  data,
  exportId,
  timeout = 1000,
} : {
  type: string,
  exportId: string,
  data?: any[],
  fileName: string,
  timeout?: number,
}) => {
  const jsonData = (data && data.length > 0) ? data : [{}]

  setTimeout(() => {
    exportTo(
      type,
      fileName,
      {
        js: jsonData,
        selector: document.getElementById(exportId)!,
      },
    )
  }, timeout)
}
