import React from 'react'

const FolderIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={FolderIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M17.3572 7.71427C17.3572 7.20278 17.154 6.71224 16.7923 6.35057C16.4306 5.98889 15.9401 5.7857 15.4286 5.7857H9.00003L7.1486 1.92856H2.57145C2.05997 1.92856 1.56943 2.13175 1.20775 2.49342C0.846071 2.8551 0.642883 3.34564 0.642883 3.85713V14.1428C0.642883 14.6543 0.846071 15.1449 1.20775 15.5065C1.56943 15.8682 2.05997 16.0714 2.57145 16.0714H15.4286C15.9401 16.0714 16.4306 15.8682 16.7923 15.5065C17.154 15.1449 17.3572 14.6543 17.3572 14.1428V7.71427Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FolderIcon
