import { Edge, Node } from '@xyflow/react'

import CronParser from 'cron-parser'
import cronstrue from 'cronstrue'

import {
  DEFAULT_ARROW_CONFIG,
  NODE_TYPES,
} from '@constants/flow.constants'

import { IntlShape } from 'react-intl'
import { PIPELINE_TYPES, PIPELINE_TYPES_TO_SHORT_TYPE_MAP } from '@constants/pipelines.constants'

/**
 * Generate pipeline flow by graph
 *
 * @param piplineGraph Pipeline graph
 *
 * @returns Pipeline flow
 */
export const generatePipelineFlowByGraph = (piplineGraph: Pipelines.APIPipelineGraphItem | null) => {
  if (!piplineGraph) {
    return null
  }

  const nodes: Node<Pipelines.PipelineGraphNodeData>[] = []
  const edges: Edge[] = []
  const input = piplineGraph.composite.pipelineId

  const pipelines: {
    [key: string]: Pipelines.APIPipelineGraphItem['children'][0]
  } = {}

  piplineGraph.children.forEach((child) => {
    pipelines[child.pipelineId] = child
  })

  piplineGraph.chain.forEach((layer, layerIndex) => {
    layer.forEach((pipelineId, index) => {
      const pipeline = pipelines[pipelineId]

      if (pipeline) {
        nodes.push({
          id: pipeline.pipelineId,
          type: NODE_TYPES.PIPELINE_ITEM,
          position: { x: 0, y: 0 },
          data: {
            pipelineDetails: pipeline,
            composite: false,
            last: ((index === layer.length - 1) && (layerIndex === piplineGraph.chain.length - 1)),
            step: layerIndex + 1,
          },
        })

        pipeline.dependsOn.forEach((dependency) => {
          edges.push({
            id: `${dependency}-${pipeline.pipelineId}`,
            source: dependency,
            target: pipeline.pipelineId,
            ...DEFAULT_ARROW_CONFIG,
          })
        })
      }
    })
  })

  nodes.push({
    id: piplineGraph.composite.pipelineId,
    type: NODE_TYPES.PIPELINE_ITEM,
    position: { x: 0, y: 0 },
    data: {
      pipelineDetails: piplineGraph.composite,
      composite: true,
      last: nodes.length === 0,
      step: null,
    },
  })

  edges.push({
    id: `${input}-${nodes[0].id}`,
    source: input,
    target: nodes[0].id,
    ...DEFAULT_ARROW_CONFIG,
  })

  return {
    nodes,
    edges,
  }
}

/**
 * Check if the CRON expression is valid
 * @param cronValue CRON expression
 * @returns if true, the CRON expression is valid
 */
export const isValidCron = (cronValue?: string) => {
  try {
    if (!cronValue) {
      return false
    }

    CronParser.parseExpression(cronValue)

    return true
  } catch (err) {
    return false
  }
}

/**
 * Check if the CRON expression is within the DST period
 * @param cronValue CRON expression
 * @returns if true, the CRON expression is within the DST period
 */
export const isCronWithinDST = (cronValue?: string) => {
  if (!cronValue) {
    return false
  }

  if (!isValidCron(cronValue)) {
    return false
  }

  try {
    const interval = CronParser.parseExpression(cronValue, {
      currentDate: new Date(),
      iterator: true,
    })

    return interval.fields.hour.includes(2) || interval.fields.hour.includes(3)
  } catch (err) {
    return false
  }
}

/**
 * Convert CRON expression to a human-readable value
 * @param intl React Intl
 * @param cronValue CRON expression
 * @returns human-readable value of the CRON expression
 */
export const cronToReadableValue = (intl: IntlShape, cronValue?: string) => {
  if (!cronValue) {
    return ''
  }

  if (!isValidCron(cronValue)) {
    return ''
  }

  try {
    return `${cronstrue.toString(cronValue)} (${intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.timezone' })})`
  } catch (err) {
    return ''
  }
}

/**
 * Get the N next CRON intervals
 * @param cronValue CRON expression
 * @param numOfRuns Number of runs
 * @returns N next CRON intervals
 */
export const getCronIntervals = (cronValue?: string, numOfRuns = 3) => {
  if (!cronValue) {
    return []
  }

  if (!isValidCron(cronValue)) {
    return []
  }

  try {
    const interval = CronParser.parseExpression(cronValue, {
      currentDate: new Date(),
      iterator: true,
    })

    const runs = []

    for (let i = 0; i < numOfRuns; i += 1) {
      runs.push(interval.next().value.toString())
    }

    return runs
  } catch (err) {
    return []
  }
}

/**
 * Generate pipeline name
 *
 * @param useCaseSlug Use case slug
 * @param pipelineType Pipeline type
 *
 * @returns pipeline name
 */
export const generatePipelineName = (useCaseSlug?: string, pipelineType?: PIPELINE_TYPES) => {
  if (!useCaseSlug || !pipelineType) {
    return ''
  }

  if (!PIPELINE_TYPES_TO_SHORT_TYPE_MAP[pipelineType]) {
    return ''
  }

  const processedSlug = useCaseSlug.replace(/-/g, '_')

  return `${PIPELINE_TYPES_TO_SHORT_TYPE_MAP[pipelineType]}_${processedSlug}`
}
