import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import SwitchFieldComponent from '@base/forms/SwitchField'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'
import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import WarningTileComponent from '@base/tiles/WarningTile'

import { CreatePipelineActionPayload } from '@redux/modules/pipelines/pipelines.types'
import { createPipelineAction } from '@redux/modules/pipelines/pipelines.actions'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getPipelinesList, isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { getCompaniesList, getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { CREATE_PIPELINE_MODAL_NAME } from '@constants/modals.constants'
import { fetchAll as fetchAllUseCasesIds, fetchMany as fetchAllUseCases } from '@redux/modules/use-case/use-case.api'
import { cronToReadableValue, generatePipelineName, isCronWithinDST } from '@utils/pipelines.utils'

import {
  PIPELINES_WITH_GPU, PIPELINES_WITH_RESOURCE_MANAGEMENT,
  PIPELINE_DEMAND_CONFIG_DEFAULT_TIME_RESOLUTION_UNIT,
  TIME_RESOLUTION_UNIT_OPTIONS,
  PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS,
  PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON,
  PIPELINE_DEMAND_CONFIG_MIN_STRIDE,
  PIPELINE_METRICS_ABSOLUTE_DEFAULT,
  PIPELINE_METRICS_ABSOLUTE_OPTIONS,
  PIPELINE_METRICS_DEFAULT,
  PIPELINE_METRICS_OPTIONS,
  PIPELINE_METRICS_RELATIVE_DEFAULT,
  PIPELINE_METRICS_RELATIVE_OPTIONS,
  PIPELINE_MIN_RAM_MB, PIPELINE_TYPES,
  PIPELINE_TYPE_OPTIONS,
  PIPELINE_DEFAULT_RAM_MB,
  PIPELINE_DEFAULT_EXECUTION_OFFSET,
} from '@constants/pipelines.constants'

import validation from './CreatePipelineModal.validations'

export interface PipelineConfigurationModalDetails extends Common.ModalDetails {
  pipeline: Pipelines.ReduxPipelineItem
}

export interface ConfigurePipelineModalValues extends Omit<CreatePipelineActionPayload, 'dependsOn' | 'memberOf'> {
  dependsOn: Pipelines.ReduxPipelineItem[]
  memberOf: Pipelines.ReduxPipelineItem | null
  useCase: UseCase.DetailsExtended | null
  company: Customer.CompanyItem | null
}

const CreatePipelineModalContainer: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isSubmitting = useSelector((state) => isSubmittingPipeline(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const pipelinesList = useSelector((state) => getPipelinesList(state))
  const companyList = useSelector((state) => getCompaniesList(state))

  const open = modalPageName === CREATE_PIPELINE_MODAL_NAME
  const [useCasesList, setUseCasesList] = useState<UseCase.Details[]>([])
  const [useCasesLoading, setUseCasesLoading] = useState(false)

  const getUseCasesForCompany = async (companyId: string) => {
    setUseCasesLoading(true)

    const useCasesIds: string[] = await fetchAllUseCasesIds({ companyId })
    const useCases = await fetchAllUseCases({ companyId, useCaseIds: useCasesIds })

    setUseCasesList(useCases)

    setUseCasesLoading(false)
  }

  const initialValues: ConfigurePipelineModalValues = useMemo(() => {
    return {
      useCase: null,
      company: null,
      useCaseId: usecase,
      name: '',
      enabled: false,
      deliversToCustomer: false,
      gpu: false,
      memoryMb: PIPELINE_DEFAULT_RAM_MB,
      pipelineType: null as any,
      memberOf: null,
      executionSchedule: '* * * * *',
      executionOffset: PIPELINE_DEFAULT_EXECUTION_OFFSET,
      latestExpectedDelivery: null,
      latestDelivery: null,
      dependsOn: [],
      timeResolution: {
        stride: PIPELINE_DEMAND_CONFIG_MIN_STRIDE,
        unit: PIPELINE_DEMAND_CONFIG_DEFAULT_TIME_RESOLUTION_UNIT as any,
      },
      demandConfig: {
        nFolds: PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS,
        stride: PIPELINE_DEMAND_CONFIG_MIN_STRIDE,
        predictionHorizon: PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON,
        usePublicHolidays: false,
        mainMetric: PIPELINE_METRICS_DEFAULT,
        relativeMetric: PIPELINE_METRICS_RELATIVE_DEFAULT,
        absoluteMetric: PIPELINE_METRICS_ABSOLUTE_DEFAULT,
        preset: '',
      } as Pipelines.DemandConfig,
    } as ConfigurePipelineModalValues
  }, [
    usecase,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: ConfigurePipelineModalValues) => {
    const payload: CreatePipelineActionPayload = {
      useCaseId: values.useCase ? values.useCase?.useCaseId : usecase,
      name: values.name,
      pipelineType: values.pipelineType,
      enabled: values.enabled || false,
      deliversToCustomer: values.deliversToCustomer || false,
      gpu: values.gpu || false,
      memoryMb: Number(values.memoryMb) || PIPELINE_DEFAULT_RAM_MB,
      memberOf: values.memberOf ? values.memberOf.pipelineId : null,
      dependsOn: values.dependsOn.map((item) => item.pipelineId),
      demandConfig: {
        nFolds: Number(values.demandConfig?.nFolds) || PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS,
        stride: Number(values.demandConfig?.stride) || PIPELINE_DEMAND_CONFIG_MIN_STRIDE,
        predictionHorizon: Number(values.demandConfig?.predictionHorizon) || PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON,
        usePublicHolidays: values.demandConfig?.usePublicHolidays || false,
        mainMetric: values.demandConfig?.mainMetric || PIPELINE_METRICS_DEFAULT,
        relativeMetric: values.demandConfig?.relativeMetric || PIPELINE_METRICS_RELATIVE_DEFAULT,
        absoluteMetric: values.demandConfig?.absoluteMetric || PIPELINE_METRICS_ABSOLUTE_DEFAULT,
        preset: values.demandConfig?.preset || '',
      },
      timeResolution: {
        stride: Number(values.timeResolution?.stride) || PIPELINE_DEMAND_CONFIG_MIN_STRIDE,
        unit: values.timeResolution?.unit || PIPELINE_DEMAND_CONFIG_DEFAULT_TIME_RESOLUTION_UNIT as any,
      },
      executionOffset: Number(values.executionOffset),
      executionSchedule: values.executionSchedule,
      latestExpectedDelivery: null,
      latestDelivery: null,
    }

    dispatch(
      createPipelineAction(payload),
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema: validation(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  useEffect(() => {
    if (values.company) {
      getUseCasesForCompany(values.company.companyId)

      setFieldValue('useCase', null)
    }
  }, [setFieldValue, values.company])

  const parsedCron = useMemo(() => {
    return cronToReadableValue(intl, values.executionSchedule)
  }, [intl, values.executionSchedule])

  const isWithInDST = useMemo(() => {
    return isCronWithinDST(values.executionSchedule)
  }, [values.executionSchedule])

  const dependsOnList = useMemo(() => {
    return pipelinesList.filter((item) => {
      return (item.type !== PIPELINE_TYPES.COMPOSITE_PIPELINE) && (values.company?.companyId === item.companyId)
    })
  }, [pipelinesList, values])

  const memberOfList = useMemo(() => {
    return pipelinesList.filter((item) => {
      return item.type === PIPELINE_TYPES.COMPOSITE_PIPELINE && (values.company?.companyId === item.companyId)
    })
  }, [pipelinesList, values])

  return (
    <SidePanelComponent
      open={open && isAdmin}
      title={intl.formatMessage({ id: 'pipelines.create' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer>
                <FormLayoutItem xs={12} container={true}>
                  <FormLayoutItem xs={12}>
                    <AutocompleteSelectFieldComponent<Customer.CompanyItem>
                      name='company'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.company' })}
                      value={values.company}
                      options={companyList}
                      getOptionLabel={(item: Customer.CompanyItem) => item.name}
                      isOptionEqualToValue={(option: Customer.CompanyItem, value: Customer.CompanyItem) => {
                        return option.companyId === value.companyId
                      }}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue) => {
                        setFieldValue('company', selectedValue)
                      }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12}>
                    <AutocompleteSelectFieldComponent<UseCase.Details>
                      name='useCase'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.useCase' })}
                      value={values.useCase}
                      options={useCasesList}
                      loading={useCasesLoading}
                      disabled={!values.company}
                      isOptionEqualToValue={(option: UseCase.Details, value: UseCase.Details) => {
                        return option.useCaseId === value.useCaseId
                      }}
                      getOptionLabel={(item: UseCase.Details) => item.name}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue) => {
                        setFieldValue('useCase', selectedValue)

                        setFieldValue('name', generatePipelineName(selectedValue?.fileStorageBucket, values.pipelineType))
                      }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12}>
                    <SelectFieldComponent
                      name='pipelineType'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.type' })}
                      value={values.pipelineType}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('pipelineType', e.target.value)

                        setFieldValue('name', generatePipelineName(values.useCase?.fileStorageBucket, e.target.value as PIPELINE_TYPES))
                      }}
                      options={PIPELINE_TYPE_OPTIONS}
                      disabled={!values.useCase}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'pipelines.config.dialog.generalConfig' })}
                hidden={!values.useCase || !values.pipelineType}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='name'
                      value={values.name}
                      errors={errors.name}
                      touched={touched.name}
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.name' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='enabled'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.enabled' })}
                      checked={values.enabled}
                      value={values.enabled}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={6}>
                    <SwitchFieldComponent
                      name='deliversToCustomer'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.deliversToCustomer' })}
                      checked={values.deliversToCustomer}
                      value={values.deliversToCustomer}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                hidden={!values.useCase || !values.pipelineType}
                title={intl.formatMessage({ id: 'pipelines.config.dialog.executionSchedule' })}
              >
                <FormLayoutItem xs={12}>
                  <TextFieldComponent
                    name='executionSchedule'
                    value={values.executionSchedule}
                    errors={errors.executionSchedule}
                    touched={touched.executionSchedule}
                    label={intl.formatMessage({ id: 'pipelines.config.dialog.executionSchedule' })}
                    floatingHelp={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.cron' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  hidden={!parsedCron}
                >
                  <Typography
                    variant='body2'
                    color={theme.palette.new.versatile_violet}
                  >
                    {parsedCron}
                  </Typography>
                </FormLayoutItem>
                <FormLayoutItem
                  xs={12}
                  hidden={!isWithInDST}
                >
                  <WarningTileComponent
                    text={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.dstWarning' })}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                hidden={values.pipelineType === PIPELINE_TYPES.COMPOSITE_PIPELINE || !values.useCase || !values.pipelineType}
                title={intl.formatMessage({ id: 'pipelines.config.dialog.dependencyManagement' })}
              >
                <FormLayoutItem xs={12}>
                  <AutocompleteSelectFieldComponent<Pipelines.ReduxPipelineItem>
                    name='memberOf'
                    label={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf' })}
                    floatingHelp={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf.help' })}
                    placeholder={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf.placeholder' })}
                    value={values.memberOf}
                    options={memberOfList}
                    getOptionLabel={(item: Pipelines.ReduxPipelineItem) => item.name}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue) => {
                      setFieldValue('memberOf', selectedValue)
                    }}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <AutocompleteChipsFieldComponent<Pipelines.ReduxPipelineItem>
                    name='dependsOn'
                    label={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn' })}
                    floatingHelp={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn.help' })}
                    placeholder={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn.placeholder' })}
                    value={values.dependsOn}
                    options={dependsOnList}
                    getOptionLabel={(item: Pipelines.ReduxPipelineItem) => item.name}
                    handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                      setFieldValue('dependsOn', newValue)
                    }}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'pipelines.config.dialog.resourceManagement' })}
                hidden={!PIPELINES_WITH_RESOURCE_MANAGEMENT.includes(values.pipelineType) || !values.useCase || !values.pipelineType}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={!PIPELINES_WITH_GPU.includes(values.pipelineType) ? 12 : 6}
                  >
                    <TextFieldComponent
                      name='memoryMb'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.memoryMb' })}
                      touched={touched.memoryMb}
                      errors={errors.memoryMb}
                      value={values.memoryMb}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      inputProps={{ min: PIPELINE_MIN_RAM_MB }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                    hidden={!PIPELINES_WITH_GPU.includes(values.pipelineType)}
                  >
                    <SwitchFieldComponent
                      name='gpu'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.gpu' })}
                      checked={values.gpu}
                      value={values.gpu}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'pipelines.config.dialog.demandConfig' })}
                hidden={values.pipelineType !== PIPELINE_TYPES.DEMAND_PIPELINE || !values.useCase || !values.pipelineType}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={6}
                  >
                    <TextFieldComponent
                      name='demandConfig.predictionHorizon'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.predictionHorizon' })}
                      touched={touched.demandConfig?.predictionHorizon}
                      errors={errors.demandConfig?.predictionHorizon}
                      value={values.demandConfig?.predictionHorizon}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      inputProps={{ min: PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                  >
                    <TextFieldComponent
                      name='demandConfig.preset'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.preset' })}
                      touched={touched.demandConfig?.preset}
                      errors={errors.demandConfig?.preset}
                      value={values.demandConfig?.preset}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={6}
                  >
                    <TextFieldComponent
                      name='demandConfig.nFolds'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.nFolds' })}
                      touched={touched.demandConfig?.nFolds}
                      errors={errors.demandConfig?.nFolds}
                      value={values.demandConfig?.nFolds}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      inputProps={{ min: PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                  >
                    <TextFieldComponent
                      name='demandConfig.stride'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.stride' })}
                      touched={touched.demandConfig?.stride}
                      errors={errors.demandConfig?.stride}
                      value={values.demandConfig?.stride}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      inputProps={{ min: PIPELINE_DEMAND_CONFIG_MIN_STRIDE }}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={6}
                  >
                    <SelectFieldComponent
                      name='demandConfig.mainMetric'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.mainMetric' })}
                      value={values.demandConfig?.mainMetric}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={PIPELINE_METRICS_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                  >
                    <SelectFieldComponent
                      name='demandConfig.relativeMetric'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.relativeMetric' })}
                      value={values.demandConfig?.relativeMetric}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={PIPELINE_METRICS_RELATIVE_OPTIONS}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={6}
                  >
                    <SelectFieldComponent
                      name='demandConfig.absoluteMetric'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.absoluteMetric' })}
                      value={values.demandConfig?.absoluteMetric}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={PIPELINE_METRICS_ABSOLUTE_OPTIONS}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                  >
                    <SwitchFieldComponent
                      name='demandConfig.usePublicHolidays'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.usePublicHolidays' })}
                      checked={values.demandConfig?.usePublicHolidays}
                      value={values.demandConfig?.usePublicHolidays}
                      onChange={handleChange}
                      useLabelPlaceholder={true}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={12}
                  >
                    <WarningTileComponent
                      text={intl.formatMessage({ id: 'pipelines.config.dialog.demandConfig.warning' })}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'pipelines.config.dialog.executionConfig' })}
                hidden={values.pipelineType !== PIPELINE_TYPES.DEMAND_PIPELINE || !values.useCase || !values.pipelineType}
              >
                <FormLayoutItem
                  xs={12}
                  container={true}
                >
                  <FormLayoutItem
                    xs={6}
                  >
                    <TextFieldComponent
                      name='timeResolution.stride'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.stride' })}
                      touched={touched.timeResolution?.stride}
                      errors={errors.timeResolution?.stride}
                      value={values.timeResolution?.stride}
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      inputProps={{ min: PIPELINE_DEMAND_CONFIG_MIN_STRIDE }}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem
                    xs={6}
                  >
                    <SelectFieldComponent
                      name='timeResolution.unit'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.unit' })}
                      value={values.timeResolution?.unit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={TIME_RESOLUTION_UNIT_OPTIONS}
                    />
                  </FormLayoutItem>
                </FormLayoutItem>

                <FormLayoutItem
                  xs={12}
                >
                  <TextFieldComponent
                    name='executionOffset'
                    label={intl.formatMessage({ id: 'pipelines.config.dialog.executionOffset' })}
                    touched={touched.executionOffset}
                    errors={errors.executionOffset}
                    value={values.executionOffset}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='number'
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='createPipelineModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='createPipelineModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default CreatePipelineModalContainer
