import React from 'react'

const SumIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={SumIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M16.5 16.5H2.07904C1.96516 16.4994 1.85399 16.4652 1.75955 16.4015C1.66511 16.3379 1.59162 16.2477 1.54835 16.1423C1.50123 16.0383 1.48813 15.9221 1.51091 15.8102C1.5337 15.6983 1.59121 15.5965 1.67525 15.5192L7.7782 9.40385C7.88389 9.296 7.9431 9.15101 7.9431 9C7.9431 8.84899 7.88389 8.704 7.7782 8.59615L1.67525 2.48077C1.59121 2.4035 1.5337 2.30166 1.51091 2.18978C1.48813 2.07791 1.50123 1.96169 1.54835 1.85769C1.59162 1.75234 1.66511 1.66215 1.75955 1.59849C1.85399 1.53484 1.96516 1.50057 2.07904 1.5H16.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default SumIcon
