import { handleActions } from 'redux-actions'
import { AnalyzeState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  START_FETCHING_ANALYZE,
  STOP_FETCHING_ANALYZE,
  RECEIVE_ANALYZE_TABLE,
  RECEIVE_ANALYZE_CHART,
  RECEIVE_ANALYZE_TIME_WINDOW_DETAILS,
  RECEIVE_ANALYZE_GRID_STATE_CHANGE,
} from './analyze.action-types'

import {
  receiveAnalyzeTable,
  receiveAnalyzeChart,
  initialState,
  receiveGridStateChange,
  receiveAnalyzeTimeWindowDetails,
} from './analyze.reducers'

export default handleActions<AnalyzeState, any>({
  [START_FETCHING_ANALYZE]: startFetchingAction<AnalyzeState>,
  [STOP_FETCHING_ANALYZE]: stopFetchingAction<AnalyzeState>,
  [RECEIVE_ANALYZE_TABLE]: receiveAnalyzeTable,
  [RECEIVE_ANALYZE_CHART]: receiveAnalyzeChart,
  [RECEIVE_ANALYZE_GRID_STATE_CHANGE]: receiveGridStateChange,
  [RECEIVE_ANALYZE_TIME_WINDOW_DETAILS]: receiveAnalyzeTimeWindowDetails,
}, initialState)
