import React from 'react'

const PinLeftIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={PinLeftIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M12.5099 10.3361L16.7142 7.62323C16.878 7.52391 17.0175 7.38926 17.1225 7.22912C17.2276 7.06898 17.2955 6.88739 17.3214 6.69762C17.3473 6.50786 17.3304 6.3147 17.2721 6.13228C17.2138 5.94986 17.1154 5.78277 16.9842 5.64323L12.8571 1.51609C12.7175 1.3849 12.5504 1.28655 12.368 1.22821C12.1856 1.16987 11.9924 1.15303 11.8027 1.1789C11.6129 1.20478 11.4313 1.27273 11.2712 1.37778C11.111 1.48283 10.9764 1.62233 10.8771 1.78609L8.09993 5.93895L2.32707 7.22466C2.19506 7.2567 2.07384 7.32302 1.97569 7.41693C1.87753 7.51084 1.80591 7.62901 1.76807 7.75948C1.73024 7.88994 1.72753 8.02809 1.76021 8.15995C1.7929 8.2918 1.85982 8.41269 1.95421 8.51038L9.86136 16.4304C9.96144 16.521 10.0832 16.5842 10.2149 16.6139C10.3466 16.6436 10.4837 16.6389 10.613 16.6001C10.7423 16.5613 10.8594 16.4898 10.9529 16.3925C11.0465 16.2952 11.1134 16.1754 11.1471 16.0447L12.5099 10.3361Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.90139 12.5604L0.642822 17.8575" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default PinLeftIcon
