import React from 'react'
import get from 'lodash.get'

import { useSelector, useDispatch } from '@redux/hooks'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getNavigation } from '@utils/navigation.utils'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { changeSidebarStateAction } from '@redux/modules/common/common.actions'
import { getSidebarState } from '@redux/modules/common/common.selectors'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import SideBarLayoutComponent from '@base/sidebar/SideBarLayout'

const SideBarContainer = () => {
  const match = useRouteMatch<Common.RouterMatch>()
  const usecaseId = get(match, 'params.usecase', '')

  const intl = useIntl()
  const dispatch = useDispatch()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const sidebarState = useSelector((state) => getSidebarState(state))
  const navItems = getNavigation(intl, usecaseId, useCase, isAdmin, companyId)
  const adminItems = navItems.filter((item) => item.onlyForAdmin)
  const nonAdminItems = navItems.filter((item) => !item.onlyForAdmin)

  const changeSidebarState = (newSidebarState: boolean) => {
    dispatch(changeSidebarStateAction(newSidebarState))
  }

  return (
    <SideBarLayoutComponent
      isAdmin={isAdmin}
      adminItems={adminItems}
      nonAdminItems={nonAdminItems}
      expanded={sidebarState}
      changeSidebarState={changeSidebarState}
    />
  )
}

export default SideBarContainer
