import React from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_FILL_COLOR, SUB_OPTIMAL_FILL_COLOR, DEFAULT_SELECTED_COLOR } from '@constants/analysis.constants'

import {
  Box, Typography,
  useTheme, styled,
} from '@mui/material'

const LegendItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  marginRight: theme.spacing(3),
}))

const TypographyLegendItemIndicator = styled(Typography)(({ theme }) => ({
  color: theme.palette.new.black,
  fontSize: '12px',
}))

export interface TradeOffsLegendComponentProps {
  optimizeEnabled?: boolean,
  showSuboptimalSolutions?: boolean,
}

const TradeOffsLegendComponent: React.FC<TradeOffsLegendComponentProps> = (props) => {
  const {
    showSuboptimalSolutions,
    optimizeEnabled,
  } = props

  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      data-testid={TradeOffsLegendComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('xl')]: {
          padding: theme.spacing(0, 2),
        },
      }}
    >
      <LegendItem
        sx={!showSuboptimalSolutions ? { opacity: 0.4 } : {}}
      >
        <Box
          sx={{
            width: theme.spacing(0.8),
            height: theme.spacing(0.8),
            marginRight: theme.spacing(1),
            borderRadius: '100%',
            backgroundColor: SUB_OPTIMAL_FILL_COLOR,
          }}
        />
        <TypographyLegendItemIndicator>
          {intl.formatMessage({ id: 'trade_offs.chart.pareto.sub_optimum' })}
        </TypographyLegendItemIndicator>
      </LegendItem>
      <LegendItem>
        <Box
          sx={{
            width: theme.spacing(1.4),
            height: theme.spacing(1.4),
            marginRight: theme.spacing(1),
            borderRadius: '100%',
            backgroundColor: DEFAULT_FILL_COLOR,
          }}
        />
        <TypographyLegendItemIndicator>
          {intl.formatMessage({ id: 'trade_offs.chart.pareto.optimum' })}
        </TypographyLegendItemIndicator>
      </LegendItem>
      {
        optimizeEnabled ? (
          null
        ) : (
          <LegendItem>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                fontSize: '12px',
                fontFeatureSettings: "'tnum' on, 'lnum' on",
                color: theme.palette.new.white,
                minWidth: theme.spacing(2),
                width: theme.spacing(2),
                height: theme.spacing(2),
                marginRight: theme.spacing(1),
                borderRadius: '100%',
                backgroundColor: DEFAULT_SELECTED_COLOR,
              }}
            >
              1
            </Typography>
            <TypographyLegendItemIndicator>
              {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
            </TypographyLegendItemIndicator>
          </LegendItem>
        )
      }
      <LegendItem>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: '12px',
            fontFeatureSettings: "'tnum' on, 'lnum' on",
            color: theme.palette.new.white,
            minWidth: theme.spacing(2),
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
            borderRadius: '100%',
            background: theme.palette.new.pink,
          }}
        >
          {optimizeEnabled ? null : 1}
        </Typography>
        <TypographyLegendItemIndicator>
          {intl.formatMessage({ id: optimizeEnabled ? 'trade_offs.details.choosen_strategy' : 'trade_offs.chart.pareto.optimumToExecute' })}
        </TypographyLegendItemIndicator>
      </LegendItem>
    </Box>
  )
}

export default TradeOffsLegendComponent
