import React from 'react'

import palette from '@configuration/theme/theme.palette'

const TradeOffsIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = palette.new.black,
}) => {
  return (
    <svg data-testid={TradeOffsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <circle fillOpacity="0" cx="9" cy="9" r="8.5" stroke={detailsFill} />
      <circle fillOpacity="0" cx="9.00056" cy="9.00007" r="0.785714" fill={detailsFill} stroke={detailsFill} />

    </svg>
  )
}

export default TradeOffsIcon
