import React from 'react'

const OpenLinkIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={OpenLinkIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M13.5 10.2857V16.0714C13.5 16.4124 13.3646 16.7394 13.1234 16.9806C12.8823 17.2217 12.5553 17.3571 12.2143 17.3571H1.9286C1.5876 17.3571 1.26058 17.2217 1.01946 16.9806C0.778342 16.7394 0.642883 16.4124 0.642883 16.0714V5.78571C0.642883 5.44472 0.778342 5.1177 1.01946 4.87658C1.26058 4.63546 1.5876 4.5 1.9286 4.5H7.71431" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.8572 0.642853H17.3572V5.14285" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.3571 0.642853L9 9" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default OpenLinkIcon
