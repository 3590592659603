import React from 'react'
import { useIntl } from 'react-intl'

import {
  Typography, TableBody,
  TableRow, TableCell, Box,
  useTheme,
} from '@mui/material'

export interface NoTableDataComponentProps {
  colSpan?: number,
  search?: string,
  customNoDataMessage?: React.ReactElement | React.ReactNode | React.ReactNode[],
}

const NoTableDataComponent: React.FC<NoTableDataComponentProps> = ({
  colSpan, search,
  customNoDataMessage,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const dataToRender = customNoDataMessage || (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: theme.spacing(18),
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '24px',
          color: theme.palette.new.black,
          marginBottom: theme.spacing(2),
        }}
      >
        {intl.formatMessage({ id: 'common.search_empty_header' })}
      </Typography>

      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '18px',
          maxWidth: theme.spacing(70),
          color: theme.palette.new.grey_h,
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {
          search ? (
            intl.formatMessage({ id: 'common.search_empty_text' }, { search })
          ) : (
            intl.formatMessage({ id: 'common.table_empty_text' })
          )
        }
      </Typography>
    </Box>
  )

  return (
    <TableBody data-testid={NoTableDataComponent.name}>
      <TableRow
        hover={false}
        sx={{
          '&:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
        }}
      >
        <TableCell colSpan={colSpan}>
          {dataToRender}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default NoTableDataComponent
