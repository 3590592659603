import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useRouteMatch, generatePath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box } from '@mui/material'

import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import PageLayoutContainer from '@containers/application/PageLayout'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'

import { isFetchingSnapshots, getSnapshotsList } from '@redux/modules/snapshots/snapshots.selectors'
import { deleteSnapshotAction, requestSnapshotsAction } from '@redux/modules/snapshots/snapshots.actions'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { SNAPSHOT_DETAILS_PATH } from '@constants/routes.constants'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'

import DeleteIcon from '@icons/delete.icon'
import ViewIcon from '@icons/view.icon'

import {
  GRID_ACTIONS_COLUMN_TYPE,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'

import {
  generateActionsColumnDefinition, generateDateTimeColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateChipColumnDefinition,
  generateNumeralColumnDefinition,
} from '@utils/data-grid-cells.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import useGridInitialState from '@hooks/useGridInitialState.hook'

const SnapshotsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const useCase = useSelector((state) => getUseCaseItem(state))
  const snapshots = useSelector((state) => getSnapshotsList(state))
  const isFetching = useSelector((state) => isFetchingSnapshots(state))
  const { params: { usecase } } = useRouteMatch<Common.SnapshotsRouterMatch>()

  const tableId = getDataGridId(DATA_GRIDS.SNAPSHOTS_TABLE, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
    },
  })

  const [refresh, setRefresh] = useState<boolean | undefined>(undefined)
  const [dialogState, setDialogState] = useState({
    open: false,
    snapshotId: '',
  })

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId: usecase,
      }),
    )
  }, [dispatch, usecase])

  useLayoutEffect(() => {
    dispatch(
      requestSnapshotsAction({
        useCaseId: usecase,
      }),
    )
  }, [dispatch, usecase, refresh])

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const handleDelete = useCallback((record: Snapshots.SnapshotItem) => {
    setDialogState({
      open: true,
      snapshotId: record.snapshotId,
    })
  }, [])

  const handleDeleteConfirmed = (snapshotId: string) => {
    dispatch(
      deleteSnapshotAction({
        snapshotId,
      }),
    )

    setDialogState({
      open: false,
      snapshotId: '',
    })
  }

  const pageBarRightSideBlocks = (
    <CallToActionButtonComponent
      name='refreshButton'
      onClick={handleRefresh}
      loading={isFetching}
      label={intl.formatMessage({ id: 'common.refresh' })}
    />
  )

  const getActionItems = useCallback((params: GridRowParams<Snapshots.SnapshotItem>) => {
    return [
      <DataGridActionButtonComponent
        name='snapshotDetails'
        icon={<ViewIcon />}
        label={intl.formatMessage({ id: 'snapshots.table.snapshotDetails' })}
        id={params.id}
        onClick={() => {
          history.push({
            pathname: generatePath(SNAPSHOT_DETAILS_PATH, {
              usecase: params.row.useCaseId,
              snapshot: params.row.snapshotId,
            }),
          })
        }}
      />,
      <DataGridActionButtonComponent
        name='deleteSnapshot'
        icon={<DeleteIcon />}
        onClick={() => handleDelete(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
        id={params.id}
      />,
    ]
  }, [history, intl, handleDelete])

  const columns = useMemo<GridColDef[]>(() => {
    return [
      generateCopyButtonColumnDefinition({
        field: 'snapshotId',
        headerName: intl.formatMessage({ id: 'snapshots.table.snapshotId' }),
      }),
      generateCopyButtonColumnDefinition({
        field: 'pipelineRunId',
        headerName: intl.formatMessage({ id: 'snapshots.table.pipelineRunId' }),
      }),
      generateChipColumnDefinition({
        intl,
        field: 'snapshotType',
        headerName: intl.formatMessage({ id: 'snapshots.table.snapshotType' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        uppercase: true,
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'snapshots.table.createdAt' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'committedAt',
        headerName: intl.formatMessage({ id: 'snapshots.table.committedAt' }),
      }),
      generateNumeralColumnDefinition({
        intl,
        field: 'elapsedTime',
        headerName: intl.formatMessage({ id: 'snapshots.table.elapsedTime' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        gridColDefOverrides: {
          width: 150,
          valueFormatter: (value) => {
            if (value === null || value === undefined) {
              return intl.formatMessage({ id: 'common.na' })
            }

            const formattedValue = defaultNumberFormatter(value, { numberFormatOptions: { minimumFractionDigits: 0, maximumFractionDigits: 2 } })

            return `${formattedValue} sec`
          },
          valueGetter: (value, row: Snapshots.SnapshotItem) => {
            const createdAt = row.createdAt
            const committedAt = row.committedAt

            if (!createdAt || !committedAt) {
              return null
            }

            const elapsedTime = new Date(committedAt).getTime() - new Date(createdAt).getTime()

            return elapsedTime / 1000
          },
        },
      }),
      generateNumeralColumnDefinition({
        intl,
        field: 'numArtifacts',
        headerName: intl.formatMessage({ id: 'snapshots.table.metadata.numArtifacts' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        gridColDefOverrides: {
          width: 200,
          valueGetter: (value, row: Snapshots.SnapshotItem) => {
            return row.artifacts ? row.artifacts.length : null
          },
        },
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 1,
      }),
    ]
  }, [
    intl,
    getActionItems,
  ])

  if (!isAdmin && !isFetching) {
    return null
  }

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'snapshots.browser_tab.title' }, { name: useCase.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.SNAPSHOTS_TABLE}
        columns={columns}
        loading={isFetching}
        rows={snapshots}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.snapshotId}
        initialState={initialState}
      />

      <TextConfirmationDialogComponent
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, snapshotId: '' })}
        onSubmit={() => handleDeleteConfirmed(dialogState.snapshotId)}
        confirmationText={dialogState.snapshotId}
        confirmationInputLabel={intl.formatMessage({ id: 'snapshots.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'snapshots.table.dialog.content' }, {
            name: <Box component='strong'>{dialogState.snapshotId}</Box>,
          })
        }
      />
    </PageLayoutContainer>
  )
}

export default SnapshotsContainer
