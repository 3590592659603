import React from 'react'
import { DateRange, PickersShortcutsProps } from '@mui/x-date-pickers-pro'
import {
  Box, Chip, List, ListItem,
} from '@mui/material'

import { Moment } from 'moment'
import classNames from 'classnames'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { dateRangeToShortcutOption, getShortcutsAvailability } from './DateRangePicker.utils'

export interface DateRangePickerRangeShortcutsPayload {
  /**
   * Name of the component for tracking purposes
   */
  name: string
  /**
   * Router params for tracking
   */
  routerParams?: Common.RouterMatch
  /**
   * Selected shortcut option
   */
  selectedShortcutOption?: DATE_PICKER_SHORTCUT
  /**
   * Minimum date
   */
  minDate?: Moment
  /**
   * Maximum date
   */
  maxDate?: Moment
  /**
   * List of shortcuts
   */
  items: Common.PickersShortcutsItem<DateRange<Moment>>[]
  /**
   * If 'true' MUI validation is used
   * @default false
   */
  useMuiValidation?: boolean
}

export interface DateRangePickerRangeShortcutsComponentProps extends DateRangePickerRangeShortcutsPayload, Omit<PickersShortcutsProps<DateRange<Moment>>, 'items'> {}

const DateRangePickerRangeShortcutsComponent = (props: DateRangePickerRangeShortcutsComponentProps) => {
  const {
    name,
    routerParams,
    items,
    onChange,
    isValid,
    selectedShortcutOption,
    changeImportance = 'accept',
    useMuiValidation = false,
    minDate,
    maxDate,
  } = props

  if (items == null || items.length === 0) {
    return null
  }

  const shortcutsAvailability = getShortcutsAvailability(minDate, maxDate)
  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid })

    return {
      label: item.label,
      onClick: () => {
        trackEvent({
          componentName: `${name}Shortcuts`,
          actionName: TRACKING_ACTIONS.SELECT,
        }, {
          dateRange: newValue,
          label: item.label,
          router: routerParams,
        })

        onChange(newValue, changeImportance, item)
      },
      disabled: useMuiValidation ? !isValid(newValue) : !shortcutsAvailability[item.id],
      selected: dateRangeToShortcutOption(newValue) === selectedShortcutOption,
    }
  })

  return (
    <Box
      data-testid={DateRangePickerRangeShortcutsComponent.name}
      className='MuiPickersLayout-root'
      sx={{
        gridRow: '2 / 3',
        gridColumn: 1,
      }}
    >
      <List
        dense={true}
        className='MuiPickersLayout-shortcuts'
      >
        {resolvedItems.map(({ selected, ...item }) => {
          return (
            <ListItem key={item.label}>
              <Chip
                {...item}
                className={classNames({
                  selected,
                })}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default DateRangePickerRangeShortcutsComponent
