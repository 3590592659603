import React, { useMemo } from 'react'

import { getAxisFormatterByType, getChartDataPointKeys } from '@utils/recommendation-artifacts.utils'
import type { TooltipRow } from '@components/charts/ChartTooltip/ChartTooltip.component'
import type { ChartLegendItem } from '@components/charts/ChartLegend/ChartLegend.component'
import { ChartConfig, getChartItemColor } from '@utils/svg.utils'

import VisualizationComposedChartComponent from '@components/recommendation-artifacts/VisualizationComposedChart'
import SectionTileComponent from '@base/tiles/SectionTile'

export interface VisualizationItemComponentProps {
  /**
   * Unique identifier of the visualisation
   */
  id: string
  /**
   * Visualisation definition
   */
  visualization: RecommendationArtifacts.Visualisations
  /**
   * Comparison mode flag. If true, the visualisation will be displayed in comparison mode
   * The fields to show/hide are defined in the visualisation configuration
   * @default false
   */
  comparisonMode?: boolean
  /**
   * Loading flag. If true, the visualisation will be displayed in loading state
   * @default false
   */
  loading?: boolean
  /**
   * Wrapped flag. If true, the visualisation will be wrapped in a SectionTile
   * @default true
   */
  wappred?: boolean
  /**
   * Minimum height of the visualisation
   * @default '360px'
   */
  minHeight?: string
}

const VisualizationItemComponent: React.FC<VisualizationItemComponentProps> = ({
  id,
  visualization,
  comparisonMode = false,
  loading = false,
  wappred = true,
  minHeight = '360px',
}) => {
  const {
    title,
    xKey,
    xLabel,
    xType,
    yType = 'number',
    barChartStackOffset,
    barChartBarSizes,
    items,
    type,
    data,
    yLabel,
    unitLabel,
    actualValueKeys,
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    xFormattingOptions,
    yFormattingOptions,
  } = visualization

  const chartConfig = useMemo(() => ({
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    barChartStackOffset,
    barChartBarSizes,
  } as ChartConfig), [
    showAllXTicks,
    rotateXLabels,
    maxXLabelsHeight,
    barChartStackOffset,
    barChartBarSizes,
  ])

  const yTickFormatter = useMemo(() => {
    return getAxisFormatterByType(yType, chartConfig, yFormattingOptions)
  }, [yType, chartConfig, yFormattingOptions])

  const xTickFormatter = useMemo(() => {
    return getAxisFormatterByType(xType, chartConfig, xFormattingOptions)
  }, [xType, chartConfig, xFormattingOptions])

  const chartItemsList = useMemo(() => getChartDataPointKeys({
    items,
    type,
    actualValueKeys,
    comparisonMode,
  }), [items, comparisonMode, type, actualValueKeys])

  const tooltipRows = useMemo(() => {
    const rows: TooltipRow<RecommendationArtifacts.VisualisationDataItem>[] = [{
      key: xKey,
      numeric: false,
      label: xLabel,
      valueFormatter: xTickFormatter,
    }]

    chartItemsList.forEach((item, index) => {
      rows.push({
        key: item.dataKey,
        label: item.label,
        numeric: true,
        legendType: item.type === 'line' ? 'line' : 'square',
        legendColor: getChartItemColor(item.color, item.type!, true),
        valueFormatter: yTickFormatter,
      })
    })

    return rows
  }, [
    xKey,
    xLabel,
    chartItemsList,
    xTickFormatter,
    yTickFormatter,
  ])

  const legendRows = useMemo(() => {
    const rows = [] as ChartLegendItem[]

    chartItemsList.forEach((item, index) => {
      const color = getChartItemColor(item.color, item.type!, true)

      rows.push({
        label: item.label,
        color,
        type: item.type === 'line' ? 'line' : 'square',
      })
    })

    return rows
  }, [
    chartItemsList,
  ])

  const content = useMemo(() => {
    return (
      <VisualizationComposedChartComponent
        data={data}
        xKey={xKey}
        xLabel={xLabel}
        yLabel={yLabel}
        unitLabel={unitLabel}
        tooltipRows={tooltipRows}
        xTickFormatter={xTickFormatter}
        yTickFormatter={yTickFormatter}
        legendRows={legendRows}
        items={chartItemsList}
        chartConfig={chartConfig}
        height={minHeight}
      />
    )
  }, [
    minHeight,
    tooltipRows,
    chartItemsList,
    xTickFormatter,
    yTickFormatter,
    legendRows,
    xKey,
    xLabel,
    yLabel,
    unitLabel,
    data,
    chartConfig,
  ])

  if (!wappred) {
    return content
  }

  return (
    <SectionTileComponent
      name={id}
      title={title}
      contentMinHeight='360px'
      loading={loading}
    >
      {content}
    </SectionTileComponent>
  )
}

export default VisualizationItemComponent
