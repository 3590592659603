export const REQUEST_SOURCES = 'REQUEST_SOURCES'
export const RECEIVE_SOURCES = 'RECEIVE_SOURCES'

export const REQUEST_DESTINATIONS = 'REQUEST_DESTINATIONS'
export const RECEIVE_DESTINATIONS = 'RECEIVE_DESTINATIONS'

export const REQUEST_CONNECTIONS = 'REQUEST_CONNECTIONS'
export const RECEIVE_CONNECTIONS = 'RECEIVE_CONNECTIONS'

export const REQUEST_DETAILED_CONNECTIONS = 'REQUEST_DETAILED_CONNECTIONS'
export const RECEIVE_DETAILED_CONNECTIONS = 'RECEIVE_DETAILED_CONNECTIONS'

export const REQUEST_TRANSFORMATIONS = 'REQUEST_TRANSFORMATIONS'
export const RECEIVE_TRANSFORMATIONS = 'RECEIVE_TRANSFORMATIONS'

export const CREATE_TRANSFORMATION = 'CREATE_TRANSFORMATION'
export const UPDATE_TRANSFORMATION = 'UPDATE_TRANSFORMATION'
export const REMOVE_TRANSFORMATION = 'REMOVE_TRANSFORMATION'
export const MODIFY_TRANSFORMATION = 'MODIFY_TRANSFORMATION'

export const REQUEST_ADD_CONNECTION = 'REQUEST_ADD_CONNECTION'
export const REQUEST_UPDATE_CONNECTION = 'REQUEST_UPDATE_CONNECTION'
export const REQUEST_DELETE_CONNECTION = 'REQUEST_DELETE_CONNECTION'
export const REQUEST_REMOVE_CONNECTION = 'REQUEST_REMOVE_CONNECTION'
export const RECEIVE_REMOVED_CONNECTIONS = 'RECEIVE_REMOVED_CONNECTIONS'

export const START_FETCHING_HERMES = 'START_FETCHING_HERMES'
export const STOP_FETCHING_HERMES = 'STOP_FETCHING_HERMES'

export const REQUEST_PUBLISHERS_LIST = 'REQUEST_PUBLISHERS_LIST'
export const RECEIVE_PUBLISHERS_LIST = 'RECEIVE_PUBLISHERS_LIST'

export const REQUEST_PUBLISHER = 'REQUEST_PUBLISHER'
export const RECEIVE_PUBLISHER = 'RECEIVE_PUBLISHER'

export const CREATE_PUBLISHER = 'CREATE_PUBLISHER'
export const UPDATE_PUBLISHER = 'UPDATE_PUBLISHER'
export const REMOVE_PUBLISHER = 'REMOVE_PUBLISHER'

export const REQUEST_ARTIFACTS_EXPORT_DETAILS = 'REQUEST_ARTIFACTS_EXPORT_DETAILS'
export const RECEIVE_ARTIFACTS_EXPORT_DETAILS = 'RECEIVE_ARTIFACTS_EXPORT_DETAILS'
