import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from 'react-redux'

import { State } from '@redux/modules/types'

export const useSelector: TypedUseSelectorHook<State> = useReduxSelector
export const useDispatch = useReduxDispatch
