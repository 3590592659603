export const START_FETCHING_RECOMMENDATION_ARTIFACTS = 'START_FETCHING_RECOMMENDATION_ARTIFACTS'
export const STOP_FETCHING_RECOMMENDATION_ARTIFACTS = 'STOP_FETCHING_RECOMMENDATION_ARTIFACTS'

export const REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT = 'REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT'
export const RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT = 'RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT'

export const REQUEST_TOP_ARTICLES_ARTIFACT = 'REQUEST_TOP_ARTICLES_ARTIFACT'
export const RECEIVE_TOP_ARTICLES_ARTIFACT = 'RECEIVE_TOP_ARTICLES_ARTIFACT'

export const REQUEST_VISUALISATIONS_ARTIFACT = 'REQUEST_VISUALISATIONS_ARTIFACT'
export const RECEIVE_VISUALISATIONS_ARTIFACT = 'RECEIVE_VISUALISATIONS_ARTIFACT'

export const REQUEST_KPI_ITEMS_ARTIFACT = 'REQUEST_KPI_ITEMS_ARTIFACT'
export const RECEIVE_KPI_ITEMS_ARTIFACT = 'RECEIVE_KPI_ITEMS_ARTIFACT'

export const REQUEST_KPIS_PER_ITEM_ARTIFACT = 'REQUEST_KPIS_PER_ITEM_ARTIFACT'
export const RECEIVE_KPIS_PER_ITEM_ARTIFACT = 'RECEIVE_KPIS_PER_ITEM_ARTIFACT'

export const REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT = 'REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT'
export const RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT = 'RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT'

export const REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT = 'REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT'
export const RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT = 'RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT'
export const SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM = 'SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM'

export const TOGGLE_COMPARISON_MODE = 'TOGGLE_COMPARISON_MODE'
