import {
  takeEvery, call,
  put, select, take,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { CONNECT_GROUPING_MODAL_NAME, CONNECT_PARAMETER_MODAL_NAME } from '@constants/modals.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { fetchUseCaseAction, prepareConnectOverviewAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE, PREPARE_CONNECT_OVERVIEW_DONE } from '@redux/modules/use-case/use-case.action-types'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'

import { ActionPayload, State } from '@redux/modules/types'

import {
  REQUEST_PARAMETER_OVERVIEW,
  REQUEST_PARAMETER,
  CREATE_PARAMETER,
  UPDATE_PARAMETER,
  DELETE_PARAMETER,

  REQUEST_GROUPING_OVERVIEW,
  REQUEST_GROUPING,
  CREATE_GROUPING,
  UPDATE_GROUPING,
  DELETE_GROUPING,
} from './parameters.action-types'

import {
  CreateGroupingPayload, CreatePayload,
  FetchGroupingPayload, UpdatePayload,
  RemoveGroupingPayload, RemovePayload,
} from './parameters.types'

import {
  receiveGroupingActionDone,
  receiveParameterActionDone,
  startFetchingParameterAction,
  stopFetchingParameterAction,
} from './parameters.actions'

import * as API from './parameters.api'

const CREATE_PARAMETER_SUCCESS = 'connect.modal.parameter.success.create'
const UPDATE_PARAMETER_SUCCESS = 'connect.modal.parameter.success.update'
const DELETE_PARAMETER_SUCCESS = 'connect.modal.parameter.success.delete'

const CREATE_GROUPING_SUCCESS = 'connect.modal.grouping.success.create'
const UPDATE_GROUPING_SUCCESS = 'connect.modal.grouping.success.update'
const DELETE_GROUPING_SUCCESS = 'connect.modal.grouping.success.delete'

function* fetchParameterGenerator({ payload } : ActionPayload<UseCase.ParameterItem>) {
  try {
    yield put(startFetchingParameterAction(REQUEST_PARAMETER))

    yield put(receiveParameterActionDone(payload))

    yield put(stopFetchingParameterAction(REQUEST_PARAMETER))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(REQUEST_PARAMETER))
  }
}

function* parameterOverviewGenerator({ payload } : ActionPayload<UseCase.ParameterItem>) {
  try {
    yield put(startFetchingParameterAction(REQUEST_PARAMETER))

    yield put(receiveParameterActionDone(payload))

    yield put(setPrimaryModalPageName(CONNECT_PARAMETER_MODAL_NAME))

    yield put(stopFetchingParameterAction(REQUEST_PARAMETER))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(REQUEST_PARAMETER))
  }
}

function* createParameterGenerator({ payload } : ActionPayload<CreatePayload>) {
  try {
    yield put(startFetchingParameterAction(CREATE_PARAMETER))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { useCaseId = '' } = useCaseItem || {}

    yield call(API.create, {
      useCaseId,
      ...payload,
    })

    yield put(setPrimaryModalPageName(null))

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(changeToastAction({ useIntl: true, message: CREATE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(CREATE_PARAMETER))
  }
}

function* updateParameterGenerator({ payload } : ActionPayload<UpdatePayload>) {
  try {
    yield put(startFetchingParameterAction(UPDATE_PARAMETER))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { useCaseId = '' } = useCaseItem || {}

    yield call(API.update, {
      useCaseId,
      ...payload,
    })

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: UPDATE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(UPDATE_PARAMETER))
  }
}

function* deleteParameterGenerator({ payload } : ActionPayload<RemovePayload>) {
  try {
    yield put(startFetchingParameterAction(DELETE_PARAMETER))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { useCaseId = '' } = useCaseItem || {}

    yield call(API.remove, {
      useCaseId,
      ...payload,
    })

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_PARAMETER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(DELETE_PARAMETER))
  }
}

function* fetchGroupingGenerator({ payload } : ActionPayload<FetchGroupingPayload>) {
  try {
    yield put(startFetchingParameterAction(REQUEST_GROUPING))

    yield put(receiveGroupingActionDone(payload))

    yield put(stopFetchingParameterAction(REQUEST_GROUPING))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(REQUEST_GROUPING))
  }
}

function* groupingOverviewGenerator({ payload } : ActionPayload<FetchGroupingPayload>) {
  try {
    yield put(startFetchingParameterAction(REQUEST_GROUPING))

    yield put(receiveGroupingActionDone(payload))

    yield put(setPrimaryModalPageName(CONNECT_GROUPING_MODAL_NAME))

    yield put(stopFetchingParameterAction(REQUEST_GROUPING))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(REQUEST_GROUPING))
  }
}

function* createGroupingGenerator({ payload } : ActionPayload<CreateGroupingPayload>) {
  try {
    yield put(startFetchingParameterAction(CREATE_GROUPING))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { useCaseId = '' } = useCaseItem || {}

    yield call(API.createGrouping, {
      useCaseId,
      ...payload,
    })

    yield put(setPrimaryModalPageName(null))

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(changeToastAction({ useIntl: true, message: CREATE_GROUPING_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(CREATE_GROUPING))
  }
}

function* updateGroupingGenerator({ payload } : ActionPayload<UseCase.GroupingParameter>) {
  try {
    yield put(startFetchingParameterAction(UPDATE_GROUPING))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const companyId: string = yield call(getSelectedCompanyId, state)
    const { useCaseId = '' } = useCaseItem || {}

    yield call(API.updateGrouping, {
      companyId,
      useCaseId,
      ...payload,
    } as UseCase.GroupingParameter)

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: UPDATE_GROUPING_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(UPDATE_GROUPING))
  }
}

function* deleteGroupingGenerator({ payload } : ActionPayload<RemoveGroupingPayload>) {
  try {
    yield put(startFetchingParameterAction(DELETE_GROUPING))

    const state: State = yield select()
    const useCaseItem: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const { useCaseId } = useCaseItem || {}

    yield call(API.removeGrouping, {
      ...payload,
    })

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

    yield take(PREPARE_CONNECT_OVERVIEW_DONE)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_GROUPING_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingParameterAction(DELETE_GROUPING))
  }
}

export function* watchFetchParameter() {
  yield takeEvery(REQUEST_PARAMETER, fetchParameterGenerator)
}

export function* watchRequestParameterOverview() {
  yield takeEvery(REQUEST_PARAMETER_OVERVIEW, parameterOverviewGenerator)
}

export function* watchCreateParameter() {
  yield takeEvery(CREATE_PARAMETER, createParameterGenerator)
}

export function* watchUpdateParameter() {
  yield takeEvery(UPDATE_PARAMETER, updateParameterGenerator)
}

export function* watchDeleteParameter() {
  yield takeEvery(DELETE_PARAMETER, deleteParameterGenerator)
}

export function* watchFetchGrouping() {
  yield takeEvery(REQUEST_GROUPING, fetchGroupingGenerator)
}

export function* watchRequestGroupingOverview() {
  yield takeEvery(REQUEST_GROUPING_OVERVIEW, groupingOverviewGenerator)
}

export function* watchCreateGrouping() {
  yield takeEvery(CREATE_GROUPING, createGroupingGenerator)
}

export function* watchUpdateGrouping() {
  yield takeEvery(UPDATE_GROUPING, updateGroupingGenerator)
}

export function* watchDeleteGrouping() {
  yield takeEvery(DELETE_GROUPING, deleteGroupingGenerator)
}
