import React from 'react'

const RefreshIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  opacity = '0.6',
}) => {
  return (
    <svg data-testid={RefreshIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g opacity={opacity}>
        <path d="M14.5278 11.3406L16.9892 10.7252L17.6046 13.1866" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.99 10.7252C16.4433 12.2742 15.4504 13.6267 14.1363 14.6123C12.8222 15.5978 11.2458 16.1723 9.60572 16.2634C8.08964 16.2637 6.61021 15.7973 5.36839 14.9275C4.12657 14.0578 3.18255 12.827 2.66455 11.4021" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.68272 6.42284L2.22131 7.0382L1.60596 4.57679" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.21924 7.03817C3.25303 4.20755 6.42825 1.5 9.60346 1.5C11.1269 1.50428 12.6118 1.97968 13.8545 2.86101C15.0972 3.74234 16.0368 4.98646 16.5446 6.42282" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      </g>

    </svg>
  )
}

export default RefreshIcon
