import React from 'react'

const HelpIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={HelpIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9.00167 17.3571C13.6172 17.3571 17.3588 13.6155 17.3588 9C17.3588 4.38448 13.6172 0.642857 9.00167 0.642857C4.38615 0.642857 0.644531 4.38448 0.644531 9C0.644531 13.6155 4.38615 17.3571 9.00167 17.3571Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.07422 7.07143C7.07422 6.68999 7.18733 6.31712 7.39924 5.99997C7.61116 5.68282 7.91236 5.43563 8.26476 5.28966C8.61716 5.14369 9.00493 5.1055 9.37904 5.17991C9.75314 5.25433 10.0968 5.43801 10.3665 5.70772C10.6362 5.97744 10.8199 6.32108 10.8943 6.69518C10.9687 7.06929 10.9305 7.45706 10.7846 7.80946C10.6386 8.16186 10.3914 8.46306 10.0742 8.67498C9.7571 8.88689 9.38423 9 9.00279 9V10.2857" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00335 12.2143C8.81263 12.2143 8.6262 12.2708 8.46762 12.3768C8.30904 12.4828 8.18545 12.6334 8.11246 12.8096C8.03948 12.9858 8.02038 13.1796 8.05759 13.3667C8.0948 13.5537 8.18664 13.7256 8.3215 13.8604C8.45635 13.9953 8.62817 14.0871 8.81523 14.1243C9.00228 14.1615 9.19616 14.1424 9.37236 14.0695C9.54857 13.9965 9.69917 13.8729 9.80512 13.7143C9.91108 13.5557 9.96763 13.3693 9.96763 13.1786C9.9643 12.9239 9.86164 12.6805 9.68152 12.5004C9.5014 12.3203 9.25806 12.2176 9.00335 12.2143Z" fill={detailsFill} />

    </svg>
  )
}

export default HelpIcon
