import { STOCK_STATUS_OPTIONS } from '@constants/optimize.constants'
import palette from '@configuration/theme/theme.palette'

/**
 * @function getChipColor - returns the color for the stock status chip
 *
 * @param {Optimize.STOCK_STATUS_OPTIONS} status - the stock status
 *
 * @returns {string} - the color for the stock status chip
 */
export const getChipColor = (status: Optimize.STOCK_STATUS_OPTIONS) => {
  switch (status) {
    case STOCK_STATUS_OPTIONS.UNDERSTOCKED:
      return palette.new.rebellious_red
    case STOCK_STATUS_OPTIONS.OVERSTOCKED:
      return palette.new.youthful_yellow
    case STOCK_STATUS_OPTIONS.GOOD:
    default:
      return palette.new.generous_green
  }
}
