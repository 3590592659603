import React from 'react'

const ColumnsIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={ColumnsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <rect x="0.499817" y="17.835" width="17" height="4" rx="1.5" transform="rotate(-90 0.499817 17.835)" stroke={detailsFill} />
      <rect x="6.49982" y="17.835" width="17" height="4" rx="1.5" transform="rotate(-90 6.49982 17.835)" stroke={detailsFill}/>
      <rect x="12.4998" y="17.835" width="17" height="4" rx="1.5" transform="rotate(-90 12.4998 17.835)" stroke={detailsFill} strokeLinecap="square"/>

    </svg>
  )
}

export default ColumnsIcon
