import React from 'react'

import {
  Box, SxProps, Theme,
  Typography, useTheme,
} from '@mui/material'

import WarningIcon from '@icons/flow/warning.icon'
import { DEFAULT_PADDING } from '@constants/ui.constants'

export interface WarningTileComponentProps {
  /**
   * The text to display in the warning block
   */
  text: string
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

const WarningTileComponent: React.FC<WarningTileComponentProps> = ({
  text,
  sx,
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={WarningTileComponent.name}
      sx={{
        p: DEFAULT_PADDING.MEDIUM,
        border: '1px solid',
        borderColor: theme.palette.new.rebellious_red,
        borderRadius: '10px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        ...sx,
      }}
    >
      <Typography
        component='span'
        variant='body1'
        color={theme.palette.new.rebellious_red}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: DEFAULT_PADDING.MEDIUM,
        }}
      >
        <WarningIcon
          detailsFill={theme.palette.new.rebellious_red}
          height={24}
          width={24}
        />

        {text}
      </Typography>
    </Box>
  )
}

export default WarningTileComponent
