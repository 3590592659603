import {
  takeEvery, put, call,
} from 'redux-saga/effects'

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload } from '@redux/modules/types'

import {
  REQUEST_SNAPSHOT,
  REQUEST_SNAPSHOTS,
  DELETE_SNAPSHOT,
} from './snapshots.action-types'

import {
  startFetchingSnapshotsAction,
  stopFetchingSnapshotsAction,
  receiveSnapshotsAction,
  receiveSnapshotAction,
} from './snapshots.actions'

import * as API from './snapshots.api'

const DELETE_SNAPSHOT_SUCCESS = 'snapshots.confirmation.delete'

function* requestSnapshotsListGenerator({ payload } : ActionPayload<Snapshots.APIGetListRequest>) {
  try {
    yield put(startFetchingSnapshotsAction(REQUEST_SNAPSHOTS))

    const snapshots: Snapshots.SnapshotItem[] = yield call(API.getSnapshotsList, payload)

    yield put(receiveSnapshotsAction(snapshots))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingSnapshotsAction(REQUEST_SNAPSHOTS))
  }
}

function* requestSnapshotItemGenerator({ payload } : ActionPayload<Snapshots.APIGetItemRequest>) {
  try {
    yield put(startFetchingSnapshotsAction(REQUEST_SNAPSHOT))

    const snapshot: Snapshots.SnapshotItem = yield call(API.getSnapshotItem, payload)

    yield put(receiveSnapshotAction(snapshot))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingSnapshotsAction(REQUEST_SNAPSHOT))
  }
}

function* deleteSnapshotGenerator({ payload } : ActionPayload<Snapshots.APIDeleteRequest>) {
  try {
    yield put(startFetchingSnapshotsAction(DELETE_SNAPSHOT))

    yield call(API.deleteSnapshot, payload)

    yield put(changeToastAction({ useIntl: true, message: DELETE_SNAPSHOT_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingSnapshotsAction(DELETE_SNAPSHOT))
  }
}

export function* watchDeleteSnapshotRequests() {
  yield takeEvery(DELETE_SNAPSHOT, deleteSnapshotGenerator)
}

export function* watchSnapshotsRequests() {
  yield takeEvery(REQUEST_SNAPSHOTS, requestSnapshotsListGenerator)
}

export function* watchSnapshotRequests() {
  yield takeEvery(REQUEST_SNAPSHOT, requestSnapshotItemGenerator)
}
