import { createAction } from 'redux-actions'

import {
  CREATE_PIPELINE,
  DELETE_PIPELINE,
  TRIGGER_PIPELINE,
  REQUEST_PIPELINE_OVERVIEW,
  RECEIVE_PIPELINE_OVERVIEW,
  REQUEST_PIPELINES_LIST,
  RECEIVE_PIPELINES_LIST,
  UPDATE_PIPELINE_DELIVERY_SCHEDULE,
  DELETE_PIPELINE_DELIVERY_SCHEDULE,
  SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG,
  START_FETCHING_PIPELINES,
  STOP_FETCHING_PIPELINES,
  START_COMPOSITE_PIPELINE,
  STOP_COMPOSITE_PIPELINE,
  REQUEST_COMPOSITE_PIPELINE_STATUS,
  RECEIVE_COMPOSITE_PIPELINE_STATUS,
  COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_START,
  COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_STOP,
  UPDATE_PIPELINE_EXECUTION_SCHEDULE,
  UPDATE_PIPELINE_CONFIGURATION,
  REPORT_PIPELINE_DELIVERY,
} from './pipelines.action-types'

import {
  RequestPipelineOverviewActionPayload,
  RequestPipelinesListActionPayload,
  UpdatePipelineDeliveryScheduleActionPayload,
  DeletePipelineDeliveryScheduleActionPayload,
  SetPipelineDeliversToCustomerActionPayload,
  RequestCompositePipelineStatusActionPayload,
  UpdatePipelineExecutionScheduleActionPayload,
  ConfigurePipelineActionPayload,
  PipelineReportDeliveryActionPayload,
  CreatePipelineActionPayload,
} from './pipelines.types'

export const requestPipelinesListAction = createAction<RequestPipelinesListActionPayload>(REQUEST_PIPELINES_LIST)
export const receivePipelinesListAction = createAction(RECEIVE_PIPELINES_LIST)

export const requestPipelineOverviewAction = createAction<RequestPipelineOverviewActionPayload>(REQUEST_PIPELINE_OVERVIEW)
export const receivePipelineOverviewAction = createAction(RECEIVE_PIPELINE_OVERVIEW)

export const createPipelineAction = createAction<CreatePipelineActionPayload>(CREATE_PIPELINE)
export const deletePipelineAction = createAction<Pipelines.APIPipelineActionRequest>(DELETE_PIPELINE)
export const triggerPipelineAction = createAction<Pipelines.APIPipelineActionRequest>(TRIGGER_PIPELINE)

export const startCompositePipelineAction = createAction<Pipelines.APIStartCompositePipelineRequest>(START_COMPOSITE_PIPELINE)
export const stopCompositePipelineAction = createAction<Pipelines.APIStopCompositePipelineRequest>(STOP_COMPOSITE_PIPELINE)

export const requestCompositePipelineStatusAction = createAction<RequestCompositePipelineStatusActionPayload>(REQUEST_COMPOSITE_PIPELINE_STATUS)
export const receiveCompositePipelineStatusActionDone = createAction(RECEIVE_COMPOSITE_PIPELINE_STATUS)

export const compositePipelineStatusAutoRefreshStartAction = createAction<RequestCompositePipelineStatusActionPayload>(COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_START)
export const compositePipelineStatusAutoRefreshStopAction = createAction<RequestCompositePipelineStatusActionPayload>(COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_STOP)

export const updatePipelineExecutionScheduleAction = createAction<UpdatePipelineExecutionScheduleActionPayload>(UPDATE_PIPELINE_EXECUTION_SCHEDULE)
export const updatePipelineDeliveryScheduleAction = createAction<UpdatePipelineDeliveryScheduleActionPayload>(UPDATE_PIPELINE_DELIVERY_SCHEDULE)
export const deletePipelineDeliveryScheduleAction = createAction<DeletePipelineDeliveryScheduleActionPayload>(DELETE_PIPELINE_DELIVERY_SCHEDULE)
export const setPipelineDeliversToCustomerAction = createAction<SetPipelineDeliversToCustomerActionPayload>(SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG)
export const updatePipelineConfigurationAction = createAction<ConfigurePipelineActionPayload>(UPDATE_PIPELINE_CONFIGURATION)
export const reportPipelineDeliveryAction = createAction<PipelineReportDeliveryActionPayload>(REPORT_PIPELINE_DELIVERY)

export const startFetchingPipelinesAction = createAction<string>(START_FETCHING_PIPELINES)
export const stopFetchingPipelinesAction = createAction<string>(STOP_FETCHING_PIPELINES)
