import React from 'react'
import { Box } from '@mui/material'

import LoadingFallbackComponent from '@base/loading/LoadingFallback'

interface SidePanelLoadingComponentProps {
  /**
   * Children to render
   */
  children: any
  /**
   * Loading state
   */
  loading?: boolean
}

const SidePanelLoadingComponent: React.FC<SidePanelLoadingComponentProps> = ({
  loading,
  children,
}) => {
  if (loading) {
    return (
      <Box data-testid={SidePanelLoadingComponent.name}>
        <LoadingFallbackComponent size='large' showLongRequestNote={true} />
      </Box>
    )
  }

  return (
    children
  )
}

export default SidePanelLoadingComponent
