import { checkApiForError } from '@utils/api.utils'
import { USE_CASE } from '@constants/api-provider.constants'
import apiProvider from '@redux/api-provider'
import { USE_CASE_ARTIFACTS_TYPES, USE_CASE_ARTIFACTS_TYPE_TO_ENDPOINT_MAP } from '@constants/use-cases.constants'

import {
  CreatePayload,
  EditDemandProblemDefinitionPayload,
  FetchAllPayload,
  FetchManyPayload,
  FetchPayload,
  GetDemandProblemDefinitionPayload,
  GetArtifactsMappingsPayload,
  CreateArtifactsMappingsPayload,
  RemovePayload,
  UpdatePayload,
  DeleteArtifactsMappingsPayload,
  ChangeFamilyPayload,
  SetUseCaseContactsPayload,
  CreateForecastParametersPayload,
} from './use-case.types'

export const fetchAll = ({ companyId } : FetchAllPayload = {} as FetchAllPayload) => {
  const URL = 'v5/useCase/list'
  const payload = { companyId }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.useCaseIds
    })
}

export const fetchMany = ({ useCaseIds } : FetchManyPayload) => {
  const URL = 'v8/useCase/get'
  const payload = { useCaseId: useCaseIds }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}

export const fetch = ({ companyId, useCaseId } : FetchPayload) => {
  const URL = 'v8/useCase/get'
  const payload = { companyId, useCaseId }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)
      const useCase = data.data[0]
      return useCase
    })
}

export const create = (payload: CreatePayload = {} as CreatePayload) => {
  const URL = 'v6/useCase/open'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data as UseCase.Details
  })
}

export const update = (payload: UpdatePayload = {} as UpdatePayload) => {
  const URL = 'v7/useCase/edit'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const changeFamily = (payload: ChangeFamilyPayload = {} as ChangeFamilyPayload) => {
  const URL = 'v6/useCase/family/change'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const remove = ({ useCaseId } : RemovePayload) => {
  return apiProvider.getApi(USE_CASE).post('v6/useCase/delete', { useCaseId }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const updateDemandProblemDefinition = (payload: EditDemandProblemDefinitionPayload = {} as EditDemandProblemDefinitionPayload) => {
  const URL = 'v7/demand-problem-definition/put'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const setUseCaseContacts = (payload: SetUseCaseContactsPayload) => {
  const URL = 'v8/useCase/contacts/set'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const defaultDemandProblemDefinition = {
  useCaseId: '',
  // Default value has to be string, formik limitation
  // @ts-expect-error
  predictionHorizon: '' as number,
  // @ts-expect-error
  evaluationHorizon: '' as number,
  timeResolution: '',
  targetName: '',
  targetUnit: '',
}

export const getDemandProblemDefinition = async ({ useCaseId } : GetDemandProblemDefinitionPayload) => {
  try {
    const response = await apiProvider.getApi(USE_CASE).post('v7/demand-problem-definition/get', { useCaseId })
    const data = response.data?.data?.problemDefinition || {}

    return {
      useCaseId,
      ...data,
    }
  } catch (e: any) {
    return defaultDemandProblemDefinition
  }
}

export const createArtifactsMappings = (payload: CreateArtifactsMappingsPayload = {} as CreateArtifactsMappingsPayload) => {
  const URL = USE_CASE_ARTIFACTS_TYPE_TO_ENDPOINT_MAP[payload.artifactType as USE_CASE_ARTIFACTS_TYPES]

  return apiProvider.getApi(USE_CASE).post(URL, {
    useCaseId: payload.useCaseId,
    artifactId: payload.artifactId,
  }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const deleteArtifactsMappings = (payload: DeleteArtifactsMappingsPayload = {} as DeleteArtifactsMappingsPayload) => {
  const URL = 'v7/artifact/unlink'

  return apiProvider.getApi(USE_CASE).post(URL, {
    useCaseId: payload.useCaseId,
    artifactId: payload.artifactId,
  }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const getArtifactsMappings = async ({ useCaseId } : GetArtifactsMappingsPayload) => {
  return apiProvider.getApi(USE_CASE).post('v7/artifact/list', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.artifacts
    })
}

export const getForecastParameters = async ({ useCaseIds } : { useCaseIds: string[] }) => {
  return apiProvider.getApi(USE_CASE).post('v7/use-case/forecast-value-parameters/get', { useCaseIds })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}

export const createForecastParameters = async (payload: CreateForecastParametersPayload) => {
  return apiProvider.getApi(USE_CASE).post('v7/use-case/forecast-value-parameters/put', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const getAvailableMetaDataColumns = async ({ useCaseId } : { useCaseId: string }) => {
  return apiProvider.getApi(USE_CASE).post('v6/output-settings/meta-data-columns/get-available', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.availableColumns
    })
}

export const getSpecifiedMetaDataColumns = async ({ useCaseId } : { useCaseId: string }) => {
  return apiProvider.getApi(USE_CASE).post('v6/output-settings/meta-data-columns/get-specified', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.outputMetaDataColumns
    })
}

export const setSpecifiedMetaDataColumns = async ({ useCaseId, outputMetaDataColumns } : { useCaseId: string, outputMetaDataColumns: UseCase.MetaDataColumns }) => {
  return apiProvider.getApi(USE_CASE).post('v6/output-settings/meta-data-columns/specify', { useCaseId, outputMetaDataColumns })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}

export const linkDemandUseCase = async ({ useCaseId, demandUseCaseId } : { useCaseId: string, demandUseCaseId: string }) => {
  return apiProvider.getApi(USE_CASE).post('v6/useCase/demandUseCase/set', { useCaseId, demandUseCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}
