import React, { useMemo } from 'react'

import {
  BaseSelectProps,
  MenuProps,
  Select,
} from '@mui/material'

import ChevronIcon from '@icons/flow/chevron.icon'

export interface DataGridSelectBaseComponentProps extends BaseSelectProps {
  forwardedRef?: any
}

export const DataGridSelectBaseComponent: React.FC<DataGridSelectBaseComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  const menuProps = useMemo(() => {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    } as Omit<MenuProps, 'open'>
  }, [])

  return (
    <Select
      data-testid={DataGridSelectBaseComponent.name}
      ref={forwardedRef}
      {...props}
      autoWidth={false}
      MenuProps={menuProps}
      IconComponent={ChevronIcon}
      sx={{
        '&.MuiOutlinedInput-root': {
          borderRadius: '3px',
        },
      }}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridSelectBaseComponent {...props} forwardedRef={ref} />)
