import React from 'react'

const TikTokIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={TikTokIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M7.26614 7.11693V6.4257C7.02628 6.39163 6.78425 6.37411 6.54203 6.37292C3.5788 6.37292 1.16797 8.78415 1.16797 11.7474C1.16797 13.5651 2.07641 15.1742 3.46222 16.1472C2.53428 15.1549 2.01832 13.8469 2.01911 12.4882C2.01911 9.56715 4.36141 7.18507 7.26614 7.11693Z" fill="#00F2EA"/>
      <path d="M7.39194 14.9424C8.71414 14.9424 9.79253 13.8906 9.84176 12.5799L9.84629 0.878795H11.984C11.9383 0.634403 11.9153 0.386467 11.9151 0.137939H8.99535L8.99043 11.8394C8.94179 13.1498 7.8628 14.2012 6.541 14.2012C6.14418 14.2014 5.75328 14.1047 5.40234 13.9194C5.86218 14.561 6.60264 14.9417 7.39194 14.9424ZM15.977 4.8507V4.20043C15.1914 4.20122 14.4226 3.97258 13.765 3.54288C14.3416 4.20674 15.1178 4.66559 15.9774 4.8507" fill="#00F2EA"/>
      <path d="M13.7643 3.5423C13.12 2.80499 12.7649 1.85873 12.7653 0.879395H11.9831C12.1877 1.97315 12.8315 2.93555 13.7643 3.5423ZM6.54029 9.29269C5.1854 9.29427 4.08751 10.3922 4.08594 11.747C4.08673 12.6592 4.59323 13.4958 5.40124 13.9196C5.09935 13.5033 4.93688 13.0025 4.93688 12.4883C4.93826 11.1334 6.03615 10.0351 7.39123 10.0335C7.64409 10.0335 7.88651 10.0753 8.11535 10.1472V7.16642C7.87549 7.13236 7.63346 7.11483 7.39123 7.11365C7.34869 7.11365 7.30675 7.11601 7.2646 7.1168V9.40632C7.03026 9.33188 6.78606 9.29348 6.54029 9.29269Z" fill="#FF004F"/>
      <path d="M15.9765 4.85069V7.11993C14.4623 7.11993 13.06 6.63567 11.9144 5.81368V11.7472C11.9144 14.7104 9.50397 17.1213 6.54075 17.1213C5.39559 17.1213 4.33374 16.7599 3.46094 16.1471C4.47533 17.241 5.89973 17.8625 7.39149 17.8621C10.3547 17.8621 12.7655 15.4513 12.7655 12.4885V6.55493C13.9489 7.40587 15.3702 7.86295 16.8276 7.86118V4.94069C16.5354 4.94069 16.2512 4.90898 15.9763 4.8501" fill="#FF004F"/>
      <path d="M11.9161 11.747V5.81351C13.0995 6.66464 14.5207 7.12152 15.9782 7.11975V4.85071C15.1188 4.6654 14.3427 4.20635 13.7663 3.5423C12.8334 2.93555 12.1897 1.97315 11.985 0.879395H9.84755L9.84302 12.5805C9.79398 13.8908 8.71539 14.9426 7.39319 14.9426C6.60409 14.9419 5.86343 14.561 5.40379 13.9198C4.59579 13.4962 4.08908 12.6596 4.0881 11.7472C4.08967 10.3924 5.18756 9.29446 6.54245 9.29289C6.79492 9.29289 7.03734 9.33424 7.26657 9.40652V7.11699C4.36183 7.18513 2.01953 9.56721 2.01953 12.4883C2.01953 13.9009 2.56838 15.1868 3.46264 16.1473C4.36419 16.782 5.44003 17.1221 6.54245 17.1211C9.50587 17.1211 11.9161 14.7103 11.9161 11.747Z" fill="black"/>

    </svg>
  )
}

export default TikTokIcon
