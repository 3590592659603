import React from 'react'

import {
  useTheme,
  Box,
} from '@mui/material'

import { SIDE_PANEL_FOOTER_HEIGTH } from '@constants/ui.constants'

export interface SidePanelCardActionsProps {
  /**
   * content of the card
   */
  children: React.ReactNode,
}

export const SidePanelCardActionsComponent: React.FC<SidePanelCardActionsProps> = ({
  children,
}) => {
  const theme = useTheme()

  const styles = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    minHeight: `${SIDE_PANEL_FOOTER_HEIGTH}px`,
    height: `${SIDE_PANEL_FOOTER_HEIGTH}px`,
    backgroundColor: theme.palette.new.white,
    padding: theme.spacing(0, 4),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
  }

  return (
    <Box
      sx={styles}
      data-testid={SidePanelCardActionsComponent.name}
    >
      {children}
    </Box>
  )
}

export default SidePanelCardActionsComponent
