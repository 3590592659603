import React, { useMemo } from 'react'

import {
  Box, useTheme,
  Slider, SliderProps,
  SliderThumb, SliderValueLabelProps,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import { SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS } from '@constants/tooltips.constants'
import SliderBarChartComponent, { SliderBarCount, SliderBarValue } from '@components/(features)/trade-offs/slider-bar-chart/SliderBarChart.component'
import { defaultNumberFormatter } from '@utils/analysis.utils'

const ThumbComponent = React.forwardRef((props, ref: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other} ref={ref}>
      {children}
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
    </SliderThumb>
  )
}) as React.ElementType<React.HTMLAttributes<HTMLSpanElement>>

export interface ValueLabelComponentProps extends SliderValueLabelProps {
  children: React.ReactElement<any, any>,
  open: boolean,
  value: number,
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    value,
  } = props

  return (
    <TooltipComponent
      open={open}
      describeChild={true}
      enterTouchDelay={0}
      placement='top'
      title={defaultNumberFormatter(value)}
      ref={ref}
      TransitionProps={SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS}
    >
      {children}
    </TooltipComponent>
  )
})

export interface SliderFieldComponentProps extends SliderProps {
  className?: string,
  counts?: SliderBarCount[],
  value?: SliderBarValue,
  withBarChart?: boolean,
}

const SliderFieldComponent: React.FC<SliderFieldComponentProps> = ({
  className,
  max = 100,
  min = 0,
  value,
  counts = [],
  withBarChart = true,
  valueLabelDisplay,
  ...props
}) => {
  const theme = useTheme()
  const sliderStyles = useMemo(() => {
    return {
      color: theme.palette.new.black,
      height: '1px',
      padding: '13px 0',

      '& .MuiSlider-thumb': {
        height: '40px',
        zIndex: 1,
        width: '19px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: `1px solid ${theme.palette.new.black}`,
        boxShadow: 'none',
        transition: 'none',
        '&.Mui-disabled': {
          height: '40px',
          width: '19px',
          borderRadius: '5px',
          backgroundColor: theme.palette.new.grey_f,
          cursor: 'not-allowed',
          pointerEvents: 'all',
        },
        '&:focus, &:hover, &$active': {
          boxShadow: 'none !important',
        },
        '&.Mui-active': {
          boxShadow: 'none !important',
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none !important',
        },
        '&:before': {
          boxShadow: 'none !important',
        },
        '& .bar': {
          height: '13px',
          width: '1px',
          backgroundColor: theme.palette.new.black,
          marginLeft: '1.5px',
          marginRight: '1.5px',
        },
        '&:hover': {
          boxShadow: 'none !important',
        },
      },

      '& .MuiSlider-mark': {
        height: '1px',
        width: '1px',
        backgroundColor: 'transparent',
      },

      '& .MuiSlider-markActive': {
        opacity: 1,
        height: '1px',
        backgroundColor: theme.palette.new.grey_a,
      },

      '& .MuiSlider-track': {
        height: '60px',
        backgroundColor: `${theme.palette.new.versatile_violet}1A`,
        borderBottom: `1px solid ${theme.palette.new.versatile_violet}`,
        zIndex: 1,
        position: 'absolute',
        border: 'none',
        transition: 'none',
        top: '-13px',
        borderRadius: '0px',
        borderLeft: `1px solid ${theme.palette.new.business_black_20}`,
        borderRight: `1px solid ${theme.palette.new.business_black_20}`,
      },

      '& .MuiSlider-rail': {
        color: theme.palette.new.business_black_20,
        opacity: 1,
        height: '1px',
        top: '16px',
      },
    }
  }, [theme])

  return (
    <>
      {
        withBarChart ? (
          <SliderBarChartComponent
            counts={counts}
            value={value}
          />
        ) : (
          null
        )
      }

      <Slider
        data-testid={SliderFieldComponent.name}
        value={value}
        min={min}
        max={max}
        sx={sliderStyles}
        components={{
          Thumb: ThumbComponent,
          ValueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay={valueLabelDisplay}
        {...props}
      />
    </>
  )
}

export default SliderFieldComponent
