import React, { Suspense } from 'react'
import type Keycloak from 'keycloak-js'
import { getDefaultMonitorTab } from '@utils/local-storage.utils'

import {
  Redirect,
  Route,
  Switch,
  RouteComponentProps,
  generatePath,
} from 'react-router-dom'

import {
  ROUTES,
  DASHBOARD_PAGE_NAME,
  CONNECTORS_PAGE_NAME,
  NOT_FOUND_PAGE_NAME,
  CONNECT_PAGE_NAME,
  DATA_UPLOAD_PAGE_NAME,
  DATA_FILE_LINK_PAGE_NAME,
  TRADE_OFFS_PAGE_NAME,
  MONITOR_PAGE_NAME,
  MONITOR_BACKTESTING_PAGE_NAME,
  MONITOR_LIVE_MONITORING_PAGE_NAME,
  COMPANIES_PAGE_NAME,
  USERS_PAGE_NAME,
  EXPORT_PAGE_NAME,
  OAUTH_CALLBACK_PAGE_NAME,
  OPTIMIZE_PAGE_NAME,
  OPTIMIZE_DRILL_DOWN_PAGE_NAME,
  FILE_MANAGER_PAGE_NAME,
  FILE_MANAGER_FOLDER_PAGE_NAME,
  PIPELINES_PAGE_NAME,
  USE_CASE_PIPELINES_PAGE_NAME,
  PIPELINE_OVERVIEW_PAGE_NAME,
  ARTICLE_ALLOCATION_ANALYZE_PAGE_NAME,
  ARTICLE_ALLOCATION_SETUP_PAGE_NAME,
  ARTICLE_ALLOCATION_EXPORT_PAGE_NAME,
  ARTICLE_ALLOCATION_RESULTS_PAGE_NAME,
  REPLENISHMENT_SETUP_PAGE_NAME,
  REPLENISHMENT_EXPORT_PAGE_NAME,
  REPLENISHMENT_RESULTS_PAGE_NAME,
  REPLENISHMENT_ANALYZE_PAGE_NAME,
  REPLENISHMENT_DISCOVER_PAGE_NAME,
  ANALYZE_PAGE_NAME,
  TRAINING_DATA_OVERVIEW_PAGE_NAME,
  SNAPSHOTS_PAGE_NAME,
  SNAPSHOT_DETAILS_PAGE_NAME,
} from '@constants/routes.constants'

import NotFoundErrorComponent from '@base/utils/NotFoundError'
import OAuthContainer from '@containers/pages/(company-global)/oauth/oauth.container'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

import DashboardContainer from '@containers/pages/(company-global)/dashboard/dashboard.container'
import CompaniesContainer from '@containers/pages/(company-global)/companies/companies.container'
import ConnectorsContainer from '@containers/pages/(company-global)/connectors/connectors.container'
import CompanyUsersContainer from '@containers/pages/(company-global)/company-users/company-users.container'
import PipelinesContainer from '@containers/pages/(company-global)/pipelines/pipelines.container'

import AnalyzeContainer from '@containers/pages/(mixed-usecase)/analyze/analyze.container'
import ConnectContainer from '@containers/pages/(usecase)/connect/connect.container'
import TrainingDataContainer from '@containers/pages/(usecase)/training-data/training-data.container'

import TradeOffsContainer from '@containers/pages/(demand-usecase)/trade-offs/trade-offs.container'
import OptimizeContainer from '@containers/pages/(demand-usecase)/optimize/optimize.container'
import OptimizeDrillDownContainer from '@containers/pages/(demand-usecase)/(sku)/(region)/optimize-drill-down/optimize-drill-down.container'
import FileBrowserContainer from '@containers/pages/(usecase)/file-browser/file-browser.container'
import SnapshotsContainer from '@containers/pages/(usecase)/snapshots/snapshots.container'
import SnapshotDetailsContainer from '@containers/pages/(usecase)/(snapshot)/snapshot-details/snapshot-details.container'
import ExportContainer from '@containers/pages/(mixed-usecase)/export/export.container'
import AaAnalyzeContainer from '@containers/pages/(aa-usecase)/aa-analyze/aa-analyze.container'
import AaResultsContainer from '@containers/pages/(aa-usecase)/aa-results/aa-results.container'
import RpResultsContainer from '@containers/pages/(rp-usecase)/rp-results/rp-results.container'
import PipelineOverviewContainer from '@containers/pages/(usecase)/(pipeline)/pipeline-overview/pipeline-overview.container'
import DiscoverContainer from '@containers/pages/(rp-usecase)/discover/discover.container'
import MonitorContainer from '@containers/pages/(demand-usecase)/monitor/monitor.container'

import MainContainer from '@containers/application/Main'

const DemandExportContainer = React.lazy(() => import('@containers/pages/(demand-usecase)/export/export.container'))

interface RouterContainerProps {
  /**
   * Keycloak instance
   */
  keycloak: Keycloak,
}

const RouterContainer: React.FC<RouterContainerProps> = ({ keycloak }) => {
  const preloadedLayoutRenderer = (Component: any): ((props: RouteComponentProps) => React.ReactNode) => (routerProps: RouteComponentProps) => {
    return (
      <MainContainer
        Component={Component}
        routerProps={routerProps}
        keycloak={keycloak}
      />
    )
  }

  const suspenceRenderer = (Component: any) => (routerProps: RouteComponentProps) => {
    const SuspenceWrappedComponent = () => {
      const { location } = routerProps
      const { pathname } = location

      return (
        <Suspense fallback={<LoadingFallbackComponent />} key={pathname}>
          <Component keycloak={keycloak} {...routerProps} />
        </Suspense>
      )
    }

    return (
      <MainContainer
        Component={SuspenceWrappedComponent}
        routerProps={routerProps}
        keycloak={keycloak}
      />
    )
  }

  return (
    <>
      <Switch>
        <Switch>
          {/* OAuth callback route */}
          <Route path={ROUTES[OAUTH_CALLBACK_PAGE_NAME]} exact={true} component={OAuthContainer} />

          {/* Global Routes */}
          <Route path={ROUTES[DASHBOARD_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(DashboardContainer)} />
          <Route path={ROUTES[COMPANIES_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(CompaniesContainer)} />
          <Route path={ROUTES[USERS_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(CompanyUsersContainer)} />
          <Route path={ROUTES[CONNECTORS_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(ConnectorsContainer)} />

          <Route path={[ROUTES[PIPELINES_PAGE_NAME], ROUTES[USE_CASE_PIPELINES_PAGE_NAME]]} exact={true} render={preloadedLayoutRenderer(PipelinesContainer)} />

          {/*
            Comman Usecase Routes
          */}
          <Route
            path={[
              ROUTES[ARTICLE_ALLOCATION_SETUP_PAGE_NAME],
              ROUTES[REPLENISHMENT_SETUP_PAGE_NAME],
              ROUTES[CONNECT_PAGE_NAME],
              ROUTES[DATA_UPLOAD_PAGE_NAME],
              ROUTES[DATA_FILE_LINK_PAGE_NAME],
            ]}
            exact={true}
            render={preloadedLayoutRenderer(ConnectContainer)}
          />

          <Route
            path={[ROUTES[FILE_MANAGER_PAGE_NAME], ROUTES[FILE_MANAGER_FOLDER_PAGE_NAME]]}
            exact={true}
            render={preloadedLayoutRenderer(FileBrowserContainer)}
          />

          <Route
            path={ROUTES[SNAPSHOTS_PAGE_NAME]}
            exact={true}
            render={preloadedLayoutRenderer(SnapshotsContainer)}
          />

          <Route
            path={ROUTES[SNAPSHOT_DETAILS_PAGE_NAME]}
            exact={true}
            render={preloadedLayoutRenderer(SnapshotDetailsContainer)}
          />

          <Route
            path={ROUTES[TRAINING_DATA_OVERVIEW_PAGE_NAME]}
            exact={true}
            render={preloadedLayoutRenderer(TrainingDataContainer)}
          />

          <Route
            path={ROUTES[PIPELINE_OVERVIEW_PAGE_NAME]}
            exact={true}
            render={preloadedLayoutRenderer(PipelineOverviewContainer)}
          />

          <Route
            path={[
              ROUTES[ARTICLE_ALLOCATION_EXPORT_PAGE_NAME],
              ROUTES[REPLENISHMENT_EXPORT_PAGE_NAME],
            ]}
            exact={true}
            render={preloadedLayoutRenderer(ExportContainer)}
          />

          <Route
            path={[
              ROUTES[ANALYZE_PAGE_NAME],
              ROUTES[REPLENISHMENT_ANALYZE_PAGE_NAME],
            ]}
            exact={true}
            render={preloadedLayoutRenderer(AnalyzeContainer)}
          />

          {/*
            Article Allocation Usecase Routes
          */}

          <Route path={ROUTES[ARTICLE_ALLOCATION_ANALYZE_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(AaAnalyzeContainer)} />
          <Route path={ROUTES[ARTICLE_ALLOCATION_RESULTS_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(AaResultsContainer)} />

          {/*
            Replenishment Usecase Routes
          */}
          <Route path={ROUTES[REPLENISHMENT_DISCOVER_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(DiscoverContainer)} />
          <Route path={ROUTES[REPLENISHMENT_RESULTS_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(RpResultsContainer)} />

          {/*
            Demand Usecase Routes
          */}
          <Route
            exact={true}
            path={ROUTES[MONITOR_PAGE_NAME]}
            render={(props) => {
              const defaultMonitorTab = getDefaultMonitorTab()

              props.history.push(generatePath(defaultMonitorTab, props.match.params))

              return null
            }}
          />

          <Route
            path={[
              ROUTES[MONITOR_BACKTESTING_PAGE_NAME],
              ROUTES[MONITOR_LIVE_MONITORING_PAGE_NAME],
            ]}
            exact={true}
            render={preloadedLayoutRenderer(MonitorContainer)}
          />

          <Route path={ROUTES[TRADE_OFFS_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(TradeOffsContainer)} />
          <Route path={ROUTES[EXPORT_PAGE_NAME]} exact={true} render={suspenceRenderer(DemandExportContainer)} />
          <Route path={ROUTES[OPTIMIZE_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(OptimizeContainer)} />
          <Route path={ROUTES[OPTIMIZE_DRILL_DOWN_PAGE_NAME]} exact={true} render={preloadedLayoutRenderer(OptimizeDrillDownContainer)} />

          {/* Error Routes */}
          <Route path={ROUTES[NOT_FOUND_PAGE_NAME]} render={preloadedLayoutRenderer(NotFoundErrorComponent)} />

          <Route path='*' render={() => <Redirect to={ROUTES[DASHBOARD_PAGE_NAME]} />} />
        </Switch>

        <Route path={ROUTES[NOT_FOUND_PAGE_NAME]} component={NotFoundErrorComponent} />

        <Route path='*' render={() => <Redirect to={ROUTES[NOT_FOUND_PAGE_NAME]} />} />
      </Switch>
    </>
  )
}

export default RouterContainer
