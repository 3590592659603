export const START_FETCHING_INSIGHTS = 'START_FETCHING_INSIGHTS'
export const STOP_FETCHING_INSIGHTS = 'STOP_FETCHING_INSIGHTS'

export const REQUEST_INSIGHTS_ABC_TOTALS = 'REQUEST_INSIGHTS_ABC_TOTALS'
export const RECEIVE_INSIGHTS_ABC_TOTALS = 'RECEIVE_INSIGHTS_ABC_TOTALS'

export const REQUEST_INSIGHTS_PIPELINE_RUN_INFO = 'REQUEST_INSIGHTS_PIPELINE_RUN_INFO'
export const RECEIVE_INSIGHTS_PIPELINE_RUN_INFO = 'RECEIVE_INSIGHTS_PIPELINE_RUN_INFO'

export const REQUEST_PROMOTIONS_VISIBILITY_CHANGE = 'REQUEST_PROMOTIONS_VISIBILITY_CHANGE'
export const RECEIVE_PROMOTIONS_VISIBILITY_CHANGE = 'RECEIVE_PROMOTIONS_VISIBILITY_CHANGE'

export const REQUEST_PROMOTIONS_SETTINGS_CHANGE = 'REQUEST_PROMOTIONS_SETTINGS_CHANGE'
export const RECEIVE_PROMOTIONS_SETTINGS_CHANGE = 'RECEIVE_PROMOTIONS_SETTINGS_CHANGE'
