import produce from 'immer'
import sortBy from 'lodash.sortby'

import { ParametersTemplatesState, ReducerPayload } from '@redux/modules/types'

export const initialState: ParametersTemplatesState = {
  fetchingKeys: [],
  list: [] as UseCase.ParameterTemplateItem[],
  item: {} as UseCase.ParameterTemplateItem,
}

export const receiveOne = (state: ParametersTemplatesState, action: ReducerPayload<UseCase.ParameterTemplateItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
  })

  return nextState
}

export const receiveList = (state: ParametersTemplatesState, action: ReducerPayload<UseCase.ParameterTemplateItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.list = sortBy((action.payload || []), 'sourceType', 'name')
  })

  return nextState
}
