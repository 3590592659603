import React, { useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'
import { formatDate } from '@utils/moment.utils'

import {
  getCompositePipelineStatus,
  isFetchingCompositePipelineStatus,
  isCompositePipelineRunning,
} from '@redux/modules/pipelines/pipelines.selectors'

import { isFetchingUseCase, isRecommendationUseCase } from '@redux/modules/use-case/use-case.selectors'
import { requestCompositePipelineStatusAction } from '@redux/modules/pipelines/pipelines.actions'

import StatusBarComponent from '@base/topbar/StatusBar'

export interface StatusBarContainerProps {}

const StatusBarContainer: React.FC<StatusBarContainerProps> = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const isRecommendation = useSelector((state) => isRecommendationUseCase(state))
  const isOptimizing = useSelector((state) => isCompositePipelineRunning(state))
  const isFetching = useSelector((state) => isFetchingCompositePipelineStatus(state))
  const isFetchingUseCaseFlag = useSelector((state) => isFetchingUseCase(state))
  const pipelineStatus = useSelector((state) => getCompositePipelineStatus(state))

  const lastSuccessfulRun = pipelineStatus?.lastSuccessfulRun
  const hasAtLeastOneRun = Boolean(lastSuccessfulRun?.startTime)

  useEffect(() => {
    if (usecase && isRecommendation) {
      dispatch(
        requestCompositePipelineStatusAction({
          useCaseId: usecase,
          initialFetch: true,
        }),
      )
    }
  }, [
    dispatch,
    usecase,
    isRecommendation,
  ])

  const label = useMemo(() => {
    if (isFetching) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    if (isOptimizing) {
      return intl.formatMessage({ id: 'connect.setup.status_bar.step.optimization' })
    }

    if (hasAtLeastOneRun && lastSuccessfulRun) {
      return intl.formatMessage({
        id: 'connect.setup.status_bar.idle',
      }, {
        date: formatDate(intl, lastSuccessfulRun.startTime),
      })
    }

    return (
      intl.formatMessage({ id: 'connect.setup.status_bar.no_runs' })
    )
  }, [
    intl,
    lastSuccessfulRun,
    hasAtLeastOneRun,
    isFetching,
    isOptimizing,
  ])

  const type = useMemo(() => {
    if (isOptimizing) {
      return 'processing'
    }

    if (isFetching) {
      return 'info'
    }

    if (!hasAtLeastOneRun && !isFetching) {
      return 'alert'
    }

    return 'info'
  }, [
    isOptimizing,
    isFetching,
    hasAtLeastOneRun,
  ])

  if (!usecase || !isRecommendation || isFetchingUseCaseFlag) {
    return null
  }

  return (
    <StatusBarComponent
      label={label}
      type={type}
    />
  )
}

export default StatusBarContainer
