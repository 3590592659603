export const REQUEST_SOCRATES_PROJECTS = 'REQUEST_SOCRATES_PROJECTS'
export const RECEIVE_SOCRATES_PROJECTS = 'RECEIVE_SOCRATES_PROJECTS'

export const REQUEST_SOCRATES_PROJECT = 'REQUEST_SOCRATES_PROJECT'
export const RECEIVE_SOCRATES_PROJECT = 'RECEIVE_SOCRATES_PROJECT'

export const REQUEST_TRADE_OFFS_BY_USE_CASE = 'REQUEST_TRADE_OFFS_BY_USE_CASE'

export const REQUEST_TRADE_OFFS_DATA = 'REQUEST_TRADE_OFFS_DATA'
export const RECEIVE_TRADE_OFFS_DATA = 'RECEIVE_TRADE_OFFS_DATA'

export const START_FETCHING_ANALYSIS = 'START_FETCHING_ANALYSIS'
export const STOP_FETCHING_ANALYSIS = 'STOP_FETCHING_ANALYSIS'

export const RESET_TRADE_OFFS_CONFIGURATION = 'RESET_TRADE_OFFS_CONFIGURATION'
export const RESET_TRADE_OFFS_CONFIGURATION_DONE = 'RESET_TRADE_OFFS_CONFIGURATION_DONE'
export const CHANGE_TRADE_OFFS_CONFIGURATION = 'CHANGE_TRADE_OFFS_CONFIGURATION'
export const CHANGE_TRADE_OFFS_CONFIGURATION_DONE = 'CHANGE_TRADE_OFFS_CONFIGURATION_DONE'

export const CALCULATE_TRADE_OFFS_DATASET = 'CALCULATE_TRADE_OFFS_DATASET'
export const CALCULATE_TRADE_OFFS_DATASET_DONE = 'CALCULATE_TRADE_OFFS_DATASET_DONE'

export const SELECT_TRADE_OFFS_POINT = 'SELECT_TRADE_OFFS_POINT'
export const SELECT_TRADE_OFFS_POINT_RESET = 'SELECT_TRADE_OFFS_POINT_RESET'
export const SELECT_TRADE_OFFS_POINT_DONE = 'SELECT_TRADE_OFFS_POINT_DONE'
