import { createAction } from 'redux-actions'

import {
  REQUEST_TRAINING_DATA_LIST,
  TRAINING_DATA_AUTO_REFRESH_START,
  TRAINING_DATA_AUTO_REFRESH_STOP,
  RECEIVE_TRAINING_DATA_LIST,
  REQUEST_FILE_IDENTIFIERS_LIST,
  RECEIVE_FILE_IDENTIFIERS_LIST,
  DOWNLOAD_TRAINING_DATA,
  UPLOAD_TRAINING_DATA,
  REMOVE_TRAINING_DATA,
  START_FETCHING_TRAINING_FILES,
  STOP_FETCHING_TRAINING_FILES,
  CREATE_FILE_IDENTIFIER,
  REMOVE_FILE_IDENTIFIER,
  CREATE_FILE_IDENTIFIER_VERSION,
  REMOVE_FILE_IDENTIFIER_VERSION,
  REQUEST_TRAINING_DATA_FLAT_LIST,
  RECEIVE_TRAINING_DATA_FLAT_LIST,
} from './training-files.action-types'

import {
  RequestFileIdentifiersListActionPayload,
  RequestTrainingDataListActionPayload,
  DownloadTrainingDataActionPayload,
  UploadTrainingDataActionPayload,
  RemoveTrainingDataActionPayload,
  CreateFileIdentifiersActionPayload,
  RemoveFileIdentifiersActionPayload,
  CreateFileIdentifierVersionActionPayload,
  RemoveFileIdentifierVersionActionPayload,
  ListTrainingDataFlatListPayload,
} from './training-files.types'

export const requestTrainingDataFlatListAction = createAction<ListTrainingDataFlatListPayload>(REQUEST_TRAINING_DATA_FLAT_LIST)
export const receiveTrainingDataFlatList = createAction(RECEIVE_TRAINING_DATA_FLAT_LIST)

export const requestTrainingDataListAction = createAction<RequestTrainingDataListActionPayload>(REQUEST_TRAINING_DATA_LIST)
export const receiveTrainingDataList = createAction(RECEIVE_TRAINING_DATA_LIST)

export const trainingDataAutoRefreshStartAction = createAction<RequestTrainingDataListActionPayload>(TRAINING_DATA_AUTO_REFRESH_START)
export const trainingDataAutoRefreshStopAction = createAction<RequestTrainingDataListActionPayload>(TRAINING_DATA_AUTO_REFRESH_STOP)

export const requestFileIdentifiersListAction = createAction<RequestFileIdentifiersListActionPayload>(REQUEST_FILE_IDENTIFIERS_LIST)
export const receiveFileIdentifiersList = createAction(RECEIVE_FILE_IDENTIFIERS_LIST)
export const createFileIdentifierAction = createAction<CreateFileIdentifiersActionPayload>(CREATE_FILE_IDENTIFIER)
export const removeFileIdentifierAction = createAction<RemoveFileIdentifiersActionPayload>(REMOVE_FILE_IDENTIFIER)

export const createFileIdentifierVersionAction = createAction<CreateFileIdentifierVersionActionPayload>(CREATE_FILE_IDENTIFIER_VERSION)
export const removeFileIdentifierVersionAction = createAction<RemoveFileIdentifierVersionActionPayload>(REMOVE_FILE_IDENTIFIER_VERSION)

export const downloadTrainingDataAction = createAction<DownloadTrainingDataActionPayload>(DOWNLOAD_TRAINING_DATA)
export const uploadTrainingDataAction = createAction<UploadTrainingDataActionPayload>(UPLOAD_TRAINING_DATA)
export const removeTrainingDataAction = createAction<RemoveTrainingDataActionPayload>(REMOVE_TRAINING_DATA)

export const startFetchingTrainingFilesAction = createAction<string>(START_FETCHING_TRAINING_FILES)
export const stopFetchingTrainingFilesAction = createAction<string>(STOP_FETCHING_TRAINING_FILES)
