import React from 'react'

const CalendarMarkIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={CalendarMarkIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>
      {/* eslint-disable */}
      <path d="M1.92857 3.21429C1.58758 3.21429 1.26055 3.34975 1.01943 3.59087C0.778315 3.83199 0.642857 4.15902 0.642857 4.50001V16.0714C0.642857 16.4124 0.778315 16.7395 1.01943 16.9806C1.26055 17.2217 1.58758 17.3572 1.92857 17.3572H16.0714C16.4124 17.3572 16.7394 17.2217 16.9806 16.9806C17.2217 16.7395 17.3571 16.4124 17.3571 16.0714V4.50001C17.3571 4.15902 17.2217 3.83199 16.9806 3.59087C16.7394 3.34975 16.4124 3.21429 16.0714 3.21429H13.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 0.642883V5.78574" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 0.642883V5.78574" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 3.21429H10.9286" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 10.2857C4.85504 10.2857 5.14286 9.9979 5.14286 9.64286C5.14286 9.28782 4.85504 9 4.5 9C4.14496 9 3.85714 9.28782 3.85714 9.64286C3.85714 9.9979 4.14496 10.2857 4.5 10.2857Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 10.2857C9.35504 10.2857 9.64286 9.9979 9.64286 9.64286C9.64286 9.28782 9.35504 9 9 9C8.64496 9 8.35714 9.28782 8.35714 9.64286C8.35714 9.9979 8.64496 10.2857 9 10.2857Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 10.2857C13.855 10.2857 14.1429 9.9979 14.1429 9.64286C14.1429 9.28782 13.855 9 13.5 9C13.145 9 12.8571 9.28782 12.8571 9.64286C12.8571 9.9979 13.145 10.2857 13.5 10.2857Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 14.1429C4.85504 14.1429 5.14286 13.8551 5.14286 13.5C5.14286 13.145 4.85504 12.8572 4.5 12.8572C4.14496 12.8572 3.85714 13.145 3.85714 13.5C3.85714 13.8551 4.14496 14.1429 4.5 14.1429Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      {/* eslint-enable */}
    </svg>
  )
}

export default CalendarMarkIcon
