import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'
import { copyToClipboard } from '@utils/common.utils'

import CopyIcon from '@icons/copy.icon'

export interface DataGridCopyCellComponentProps {
  text: string
}

export const DataGridCopyCellComponent: React.FC<DataGridCopyCellComponentProps> = ({
  text,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  if (!text) {
    return null
  }

  const onClick = (e: React.SyntheticEvent) => {
    copyToClipboard({
      e, text, intl,
    })
  }

  return (
    <Typography
      component='div'
      data-testid={DataGridCopyCellComponent.name}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        fontSize: '14px',
        width: 'max-content',
        borderRadius: '6px',
        padding: '2px 5px 2px 25px',
        border: `1px solid ${theme.palette.new.grey_a}`,
        '&:hover': {
          backgroundColor: theme.palette.new.white,
          transition: 'background-color 0.3s',
        },
      }}
      noWrap={true}
      onClick={onClick}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '5px',
          top: '4px',
          width: '14px',
          height: '14px',
        }}
      >
        <CopyIcon
          width={14}
          height={14}
        />
      </Box>

      {text}
    </Typography>
  )
}

export default DataGridCopyCellComponent
