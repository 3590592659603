import { State } from '@redux/modules/types'

import {
  REQUEST_VISUALISATIONS_ARTIFACT,
  REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT,
  REQUEST_KPI_ITEMS_ARTIFACT,
  REQUEST_TOP_ARTICLES_ARTIFACT,
  REQUEST_KPIS_PER_ITEM_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT,
} from './recommendation-artifacts.action-types'

export const getVisualisationsArtifact = (state: State) => {
  return state.recommendationArtifacts.visualisationsArtifact
}

export const getConstraintsAnalyzeArtifact = (state: State) => {
  return state.recommendationArtifacts.constraintsAnalyzeArtifact
}

export const getArticlesOverviewTableArtifact = (state: State) => {
  return state.recommendationArtifacts.articlesOverviewTableArtifact
}

export const getArticlesOverviewDetailsArtifact = (state: State) => {
  return state.recommendationArtifacts.articlesOverviewDetailsArtifact
}

export const getTopArticlesArtifact = (state: State) => {
  return state.recommendationArtifacts.topArticlesArtifact
}

export const getKPIItemsArtifact = (state: State) => {
  return state.recommendationArtifacts.KPIItemsArtifact
}

export const getKPIsPerItemArtifact = (state: State) => {
  return state.recommendationArtifacts.KPIsPerItemArtifact
}

export const isFetchingKPIsPerItemArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_KPIS_PER_ITEM_ARTIFACT)
}

export const isFetchingKPIItemsArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_KPI_ITEMS_ARTIFACT)
}

export const isFetchingTopArticleArtifactss = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_TOP_ARTICLES_ARTIFACT)
}

export const isFetchingConstraintsAnalyzeArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT)
}

export const isFetchingVisualisationsArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_VISUALISATIONS_ARTIFACT)
}

export const isFetchingArticlesOverviewTableArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT)
}

export const isFetchingArticlesOverviewDetailsArtifact = (state: State) => {
  return state.recommendationArtifacts.fetchingKeys.includes(REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT)
}

export const getComparisonModeState = (state: State) => {
  return state.recommendationArtifacts.comparisonMode
}
