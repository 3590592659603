import React from 'react'
import { useIntl } from 'react-intl'

import {
  Box, Container,
  SxProps,
  Theme,
  Typography, useTheme,
} from '@mui/material'

export interface InternalErrorComponentProps {
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
  /**
   * Whether the error is a chunk load error
   */
  isChunkLoadError?: boolean
}

const InternalErrorComponent: React.FC<InternalErrorComponentProps> = ({
  sx,
  isChunkLoadError,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'
      data-testid={InternalErrorComponent.name}
      sx={{
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        ...sx,
      }}
    >
      <Container maxWidth='md'>
        <Typography
          align='center'
          color='textPrimary'
          variant='h2'
          gutterBottom={true}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {intl.formatMessage({ id: 'common.errorFound.title' })}
        </Typography>
        <Typography
          align='center'
          color='textPrimary'
          variant='subtitle2'
        >
          {intl.formatMessage({ id: isChunkLoadError ? 'common.errorFound.chunk' : 'common.errorFound.content' })}
        </Typography>
      </Container>
    </Box>
  )
}

export default InternalErrorComponent
