import React from 'react'
import {
  Box,
  BoxProps,
} from '@mui/material'

export interface IntlFormatBoldComponentProps extends BoxProps {
  /**
   * Children to be rendered
   */
  children?: React.ReactNode
}

export const IntlFormatBoldComponent: React.FC<IntlFormatBoldComponentProps> = ({
  children,
}) => {
  return (
    <Box
      component='strong'
      display='contents'
      data-testid={IntlFormatBoldComponent.name}
    >
      {children}
    </Box>
  )
}

export default IntlFormatBoldComponent
