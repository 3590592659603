import React, { SyntheticEvent, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { copyToClipboard } from '@utils/common.utils'

import CopyIcon from '@icons/copy.icon'
import InlineButtonComponent from '@base/buttons/InlineButton'

export interface CopyButtonComponentProps {
  /**
   * Value to copy
   */
  value: string,
  /**
   * If true, only icon will be displayed
   */
  iconOnly?: boolean,
  /**
   * If true, icon placement will be reversed
   */
  reversed?: boolean,
  /**
   * Max width value before text is ellipsized
   */
  maxValueWidth?: string,
}

export const CopyButtonComponent: React.FC<CopyButtonComponentProps> = ({
  value,
  reversed,
  maxValueWidth = '150px',
  iconOnly,
}) => {
  const intl = useIntl()

  const onClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard({ e, text: value, intl })
  }, [value, intl])

  return (
    <InlineButtonComponent
      name='copyButton'
      label={value}
      iconOnly={iconOnly}
      onClick={onClick}
      StartIconComponent={reversed ? undefined : CopyIcon}
      EndIconComponent={reversed ? CopyIcon : undefined}
      typographyProps={{
        maxWidth: maxValueWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    />
  )
}

export default CopyButtonComponent
