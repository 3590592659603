import {
  GridFooterContainer,
  GridFooterContainerProps,
  gridFilteredTopLevelRowCountSelector,
  GridSelectedRowCount,
  gridTopLevelRowCountSelector,
  selectedGridRowsCountSelector,
  useGridSelector,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-premium'

import React, { forwardRef } from 'react'

import { DEFAULT_BUTTON_FONT_SIZE } from '@constants/ui.constants'

export interface DataGridCustomFooterComponentProps extends GridFooterContainerProps {
  forwardedRef?: React.ForwardedRef<any>,
  selectedRowCountAlwaysVisible?: boolean,
}

export const DataGridCustomFooterComponent: React.FC<DataGridCustomFooterComponentProps> = ({ forwardedRef, selectedRowCountAlwaysVisible, ...rest }) => {
  const apiRef = useGridApiContext()
  const rootProps = useGridRootProps()
  const totalTopLevelRowCount = useGridSelector(apiRef, gridTopLevelRowCountSelector)
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector)
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector)

  const selectedRowCountElement =
    !rootProps.hideFooterSelectedRowCount && (selectedRowCount > 0 || selectedRowCountAlwaysVisible) ? (
      <GridSelectedRowCount selectedRowCount={selectedRowCount} />
    ) : (
      <div />
    )

  const rowCountElement =
    !rootProps.hideFooterRowCount && !rootProps.pagination ? (
      <rootProps.slots.footerRowCount
        {...rootProps.slotProps?.footerRowCount}
        rowCount={totalTopLevelRowCount}
        visibleRowCount={visibleTopLevelRowCount}
      />
    ) : null

  const paginationElement = rootProps.pagination &&
    !rootProps.hideFooterPagination &&
    rootProps.slots.pagination && (
      <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
  )

  return (
    <GridFooterContainer
      ref={forwardedRef}
      data-testid={DataGridCustomFooterComponent.name}
      sx={{ fontSize: DEFAULT_BUTTON_FONT_SIZE }}
      {...rest}
    >
      {selectedRowCountElement}
      {rowCountElement}
      {paginationElement}
    </GridFooterContainer>
  )
}

export default forwardRef<HTMLDivElement, GridFooterContainerProps>((props, ref) => <DataGridCustomFooterComponent {...props} forwardedRef={ref} />)
