import React from 'react'

const FileIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={FileIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M5.78573 5.78571H8.35716M5.78573 9.64285H12.2143M5.78573 13.5H12.2143M16.0714 16.0714C16.0714 16.4124 15.936 16.7394 15.6949 16.9806C15.4538 17.2217 15.1267 17.3571 14.7857 17.3571H3.2143C2.87331 17.3571 2.54628 17.2217 2.30517 16.9806C2.06405 16.7394 1.92859 16.4124 1.92859 16.0714V1.92857C1.92859 1.58757 2.06405 1.26055 2.30517 1.01943C2.54628 0.778312 2.87331 0.642853 3.2143 0.642853H9.64287L16.0714 7.07142V16.0714Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FileIcon
