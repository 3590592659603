import React from 'react'
import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import TooltipComponent from '@base/tooltips/Tooltip'

export interface ViewInfoComponentProps {
  /**
   * Label to be displayed
   */
  label: string
  /**
   * Value to be displayed
   */
  value?: string | number
  /**
   * Tooltip to be displayed
   */
  tooltip?: React.ReactNode
  /**
   * If true, loading state will be displayed
   */
  loading?: boolean
}

const ViewInfoComponent: React.FC<ViewInfoComponentProps> = ({
  label,
  value,
  tooltip,
  loading,
}) => {
  const intl = useIntl()

  if (!value && !loading) {
    return null
  }

  const content = (
    <Box
      data-testid={ViewInfoComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        width: 'fit-content',
        cursor: tooltip ? 'help' : 'default',
      }}
    >
      <Typography
        variant='overline'
        noWrap={true}
      >
        {label}
      </Typography>
      <Typography
        variant='body1'
        noWrap={true}
      >
        {
          loading ? intl.formatMessage({ id: 'common.loading' }) : value
        }
      </Typography>
    </Box>
  )

  if (tooltip && !loading) {
    return (
      <TooltipComponent
        title={tooltip}
        placement='left'
        noMaxWidth={true}
        disableInteractive={false}
      >
        {content}
      </TooltipComponent>
    )
  }

  return content
}

export default ViewInfoComponent
