import { Box } from '@mui/material'
import React from 'react'

export interface CheckRoundIconProps extends Common.IconProps {
}

const CheckRoundIcon: React.FC<CheckRoundIconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <Box
      component='svg'
      data-testid={CheckRoundIcon.name}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >

      <circle cx="9" cy="9" r="9" transform="matrix(0 -1 -1 0 18 18)" fill='white' />
      <circle cx="9" cy="9" r="8.5" transform="matrix(0 -1 -1 0 18 18)" stroke={detailsFill} strokeOpacity="0.2"/>
      <circle className='checkRoundIconDot' opacity='0' cx="1" cy="1" r="1" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 10 10)" fill="#5F26E0"/>

    </Box>
  )
}

export default CheckRoundIcon
