import {
  useEffect,
  useState,
} from 'react'

const useIsRequestTakingLongTime = (longTimeAfter = 3000) => {
  const [isTakingLong, setIsTakingLong] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTakingLong(true)
    }, longTimeAfter)

    return () => clearTimeout(timer)
  }, [longTimeAfter])

  return isTakingLong
}

export default useIsRequestTakingLongTime
