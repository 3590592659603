import React, {
  useEffect,
  useState,
} from 'react'

import { Box } from '@mui/material'

export interface FileBrowserPreviewTxtComponentProps {
  /**
   * File preview data
   */
  filePreview: FileService.FilePreview
}

const FileBrowserPreviewTxtComponent: React.FC<FileBrowserPreviewTxtComponentProps> = ({
  filePreview,
}) => {
  const [text, setText] = useState<string | null>(null)

  useEffect(() => {
    if (!filePreview) {
      return
    }

    const jsonBlob = new Blob([filePreview.file], { type: filePreview.contentType })

    const reader = new FileReader()

    reader.onload = () => {
      setText(reader.result as string)
    }

    reader.readAsText(jsonBlob)
  }, [filePreview])

  if (!filePreview || !text) {
    return null
  }

  return (
    <Box
      data-testid={FileBrowserPreviewTxtComponent.name}
      display='flex'
      alignItems='flex-start'
      justifyContent='flex-start'
      width='100%'
      p={2}
    >
      <Box
        component='pre'
        style={{
          width: '100%',
          whiteSpace: 'pre-wrap',
          backgroundColor: 'rgba(0, 0, 0, 0.025)',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        {text}
      </Box>
    </Box>
  )
}

export default FileBrowserPreviewTxtComponent
