import React from 'react'

const IntegrationsIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={IntegrationsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillOpacity="0" d="M17.4956 10.963C17.4952 10.5313 17.3812 10.1072 17.165 9.7335C16.9488 9.35975 16.6381 9.04945 16.2641 8.83377C15.8901 8.61808 15.4659 8.50459 15.0341 8.5047C14.6024 8.50481 14.1783 8.61852 13.8043 8.8344V5.4261C13.8043 5.09976 13.6747 4.7868 13.444 4.55605C13.2132 4.3253 12.9002 4.19566 12.5739 4.19566H9.1656C9.38257 3.82164 9.49715 3.39705 9.49782 2.96465C9.49848 2.53225 9.3852 2.10731 9.16939 1.73262C8.95357 1.35793 8.64284 1.04672 8.26848 0.830324C7.89412 0.613932 7.46935 0.5 7.03695 0.5C6.60455 0.5 6.17979 0.613932 5.80543 0.830324C5.43107 1.04672 5.12034 1.35793 4.90452 1.73262C4.6887 2.10731 4.57542 2.53225 4.57609 2.96465C4.57675 3.39705 4.69134 3.82164 4.9083 4.19566H2.73043C2.4041 4.19566 2.09114 4.3253 1.86039 4.55605C1.62963 4.7868 1.5 5.09976 1.5 5.4261V7.96079C1.7013 7.91123 1.9079 7.88644 2.11522 7.88696C2.76788 7.88696 3.39381 8.14623 3.85531 8.60773C4.31682 9.06924 4.57608 9.69517 4.57608 10.3478C4.57608 11.0005 4.31682 11.6264 3.85531 12.0879C3.39381 12.5494 2.76788 12.8087 2.11522 12.8087C1.9079 12.8092 1.7013 12.7844 1.5 12.7349V15.2696C1.5 15.5959 1.62963 15.9089 1.86039 16.1396C2.09114 16.3704 2.4041 16.5 2.73043 16.5H5.88034C5.83079 16.2987 5.806 16.0921 5.80652 15.8848C5.80652 15.2321 6.06579 14.6062 6.52729 14.1447C6.98879 13.6832 7.61472 13.4239 8.26739 13.4239C8.92005 13.4239 9.54598 13.6832 10.0075 14.1447C10.469 14.6062 10.7283 15.2321 10.7283 15.8848C10.7288 16.0921 10.704 16.2987 10.6544 16.5H12.5739C12.9002 16.5 13.2132 16.3704 13.444 16.1396C13.6747 15.9089 13.8043 15.5959 13.8043 15.2696V13.0917C14.1783 13.3076 14.6024 13.4213 15.0341 13.4214C15.4659 13.4215 15.8901 13.308 16.2641 13.0923C16.6381 12.8766 16.9488 12.5663 17.165 12.1926C17.3812 11.8189 17.4952 11.3948 17.4956 10.963Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default IntegrationsIcon
