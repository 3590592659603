import React from 'react'
import { useSelector } from '@redux/hooks'
import { isFetchingLiveMonitoring, getLiveMonitoringChart, isFetchingLiveMonitoringChart } from '@redux/modules/monitor/monitor.selectors'
import InsightsKpisComponent from '@components/insights/InsightsKpis'

const LiveMonitoringKpisContainer = () => {
  const liveMonitoringChart = useSelector((state) => getLiveMonitoringChart(state))
  const isFetchingLiveMonitoringChartFlag = useSelector((state) => isFetchingLiveMonitoringChart(state))
  const isFetchingLiveMonitoringFlag = useSelector((state) => isFetchingLiveMonitoring(state))

  const {
    targetName,
    overallNumbers = {
      absDeviation: 0,
      relDeviation: 0,
      target: 0,
      prediction: 0,
    },
  } = liveMonitoringChart

  return (
    <InsightsKpisComponent
      loading={isFetchingLiveMonitoringFlag}
      loadingValues={isFetchingLiveMonitoringChartFlag}
      targetName={targetName}
      absDeviation={overallNumbers.absDeviation}
      relDeviation={overallNumbers.relDeviation}
      target={overallNumbers.target}
      prediction={overallNumbers.prediction}
    />
  )
}

export default LiveMonitoringKpisContainer
