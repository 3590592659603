import React from 'react'

const CalendarSettingsIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={CalendarSettingsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <g>
        <path d="M1.9286 3.21429C1.5876 3.21429 1.26058 3.34975 1.01946 3.59087C0.778342 3.83199 0.642883 4.15902 0.642883 4.50001V16.0714C0.642883 16.4124 0.778342 16.7395 1.01946 16.9806C1.26058 17.2217 1.5876 17.3572 1.9286 17.3572H16.0715C16.4124 17.3572 16.7395 17.2217 16.9806 16.9806C17.2217 16.7395 17.3572 16.4124 17.3572 16.0714V4.50001C17.3572 4.15902 17.2217 3.83199 16.9806 3.59087C16.7395 3.34975 16.4124 3.21429 16.0715 3.21429H13.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 0.642883V5.78574" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.5 0.642883V5.78574" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 3.21429H10.9286" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.96143 5.78577V7.71434" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.06573 8.03577L6.73716 9.00005" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.06573 12.5358L6.73716 11.5715" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.96143 14.7857V12.8572" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8572 12.5358L11.1857 11.5715" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8572 8.03577L11.1857 9.00005" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.96144 12.8572C10.3816 12.8572 11.5329 11.7059 11.5329 10.2857C11.5329 8.86556 10.3816 7.71429 8.96144 7.71429C7.54128 7.71429 6.39001 8.86556 6.39001 10.2857C6.39001 11.7059 7.54128 12.8572 8.96144 12.8572Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      </g>

    </svg>
  )
}

export default CalendarSettingsIcon
