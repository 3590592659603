import { handleActions } from 'redux-actions'
import { RecommendationArtifactsState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  TOGGLE_COMPARISON_MODE,
  START_FETCHING_RECOMMENDATION_ARTIFACTS,
  STOP_FETCHING_RECOMMENDATION_ARTIFACTS,
  RECEIVE_KPI_ITEMS_ARTIFACT,
  RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT,
  RECEIVE_VISUALISATIONS_ARTIFACT,
  RECEIVE_TOP_ARTICLES_ARTIFACT,
  RECEIVE_KPIS_PER_ITEM_ARTIFACT,
  RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT,
  RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT,
  SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM,
} from './recommendation-artifacts.action-types'

import {
  receiveConstraintsAnalyzeArtifact,
  receiveVisualisationsArtifact,
  receiveKPIItemsArtifact,
  toggleComparisonMode,
  receiveTopArticlesArtifact,
  receiveArticlesOverviewTableArtifact,
  receiveArticlesOverviewDetailsArtifact,
  selectArticlesOverviewDetailsItem,
  receiveKPIsPerItemArtifact,
  initialState,
} from './recommendation-artifacts.reducers'

export default handleActions<RecommendationArtifactsState, any>({
  [RECEIVE_KPI_ITEMS_ARTIFACT]: receiveKPIItemsArtifact,
  [RECEIVE_VISUALISATIONS_ARTIFACT]: receiveVisualisationsArtifact,
  [RECEIVE_CONSTRAINTS_ANALYZE_ARTIFACT]: receiveConstraintsAnalyzeArtifact,
  [RECEIVE_TOP_ARTICLES_ARTIFACT]: receiveTopArticlesArtifact,
  [RECEIVE_ARTICLES_OVERVIEW_TABLE_ARTIFACT]: receiveArticlesOverviewTableArtifact,
  [RECEIVE_ARTICLES_OVERVIEW_DETAILS_ARTIFACT]: receiveArticlesOverviewDetailsArtifact,
  [SELECT_ARTICLES_OVERVIEW_DETAILS_ITEM]: selectArticlesOverviewDetailsItem,
  [RECEIVE_KPIS_PER_ITEM_ARTIFACT]: receiveKPIsPerItemArtifact,
  [TOGGLE_COMPARISON_MODE]: toggleComparisonMode,
  [START_FETCHING_RECOMMENDATION_ARTIFACTS]: startFetchingAction<RecommendationArtifactsState>,
  [STOP_FETCHING_RECOMMENDATION_ARTIFACTS]: stopFetchingAction<RecommendationArtifactsState>,
}, initialState)
