import React from 'react'

const AlertInfoIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#000000',
}) => {
  return (
    <svg data-testid={AlertInfoIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M9 9V13.5M17.3571 9C17.3571 13.6155 13.6155 17.3571 9 17.3571C4.38447 17.3571 0.642853 13.6155 0.642853 9C0.642853 4.38447 4.38447 0.642853 9 0.642853C13.6155 0.642853 17.3571 4.38447 17.3571 9ZM9.64285 5.78571C9.64285 6.14075 9.35504 6.42857 9 6.42857C8.64496 6.42857 8.35714 6.14075 8.35714 5.78571C8.35714 5.43067 8.64496 5.14285 9 5.14285C9.35504 5.14285 9.64285 5.43067 9.64285 5.78571Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertInfoIcon
