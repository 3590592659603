import React from 'react'

const ProfileIcon: React.FC<Common.IconProps> = ({
  width = 19,
  height = 18,
  viewBox = '0 0 19 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ProfileIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9.43694 10.286C11.2121 10.286 12.6512 8.84691 12.6512 7.07171C12.6512 5.29651 11.2121 3.85742 9.43694 3.85742C7.66174 3.85742 6.22266 5.29651 6.22266 7.07171C6.22266 8.84691 7.66174 10.286 9.43694 10.286Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.94531 15.3003C4.51905 14.3585 5.32541 13.5802 6.28686 13.0401C7.24832 12.5 8.33255 12.2163 9.43531 12.2163C10.5381 12.2163 11.6223 12.5 12.5838 13.0401C13.5452 13.5802 14.3516 14.3585 14.9253 15.3003" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.43527 17.3574C14.0508 17.3574 17.7924 13.6157 17.7924 9.00021C17.7924 4.38469 14.0508 0.643066 9.43527 0.643066C4.81975 0.643066 1.07812 4.38469 1.07812 9.00021C1.07812 13.6157 4.81975 17.3574 9.43527 17.3574Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ProfileIcon
