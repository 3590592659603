import React from 'react'

const ChevronDownIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  opacity = '1',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ChevronDownIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M16 9.50037L9.37692 16.1234C9.32858 16.175 9.27019 16.216 9.20536 16.2441C9.14053 16.2721 9.07064 16.2866 9 16.2866C8.92936 16.2866 8.85946 16.2721 8.79464 16.2441C8.72981 16.216 8.67142 16.175 8.62308 16.1234L2 9.50037" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="9" y1="3.00037" x2="9" y2="15.7866" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ChevronDownIcon
