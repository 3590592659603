import React from 'react'
import { Box, Grid } from '@mui/material'
import { DEFAULT_PADDING } from '@constants/ui.constants'

import BackToButtonComponent from '@base/pagebar/BackToButton'
import ViewTitleComponent from '@base/pagebar/ViewTitle'
import TabBarComponent from '@base/pagebar/TabBar'

export interface PageBarComponentProps {
  /**
   * Title to be displayed
   */
  title?: React.ReactNode
  /**
   * Tabs to be displayed
   */
  tabs?: Common.TabOption[]
  /**
   * Callback to be called when tab is changed
   * @param tab - Selected tab option
   */
  onTabChange?: (tab: Common.TabOption) => void,
  /**
   * Blocks to be displayed on the right side of the page bar
   */
  rightSideBlocks?: React.ReactNode,
  /**
   * Path for the back button
   */
  backToPath?: string,
}

export const PageBarComponent: React.FC<PageBarComponentProps> = ({
  tabs,
  title,
  backToPath,
  onTabChange,
  rightSideBlocks = null,
}) => {
  return (
    <Grid
      container={true}
      alignItems='center'
      justifyContent='space-between'
      direction='row'
      wrap='nowrap'
      data-testid={PageBarComponent.name}
      mb={2}
      gap={1}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minWidth: 0,
          gap: DEFAULT_PADDING.SMALL,
        }}
      >
        <BackToButtonComponent
          path={backToPath}
        />

        <ViewTitleComponent
          title={title}
        />

        <TabBarComponent
          options={tabs}
          onTabChange={onTabChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: DEFAULT_PADDING.MEDIUM,
        }}
      >
        {rightSideBlocks}
      </Box>
    </Grid>
  )
}

export default PageBarComponent
