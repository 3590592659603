import React from 'react'
import { IntlShape } from 'react-intl'
import get from 'lodash.get'

import {
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'

import palette from '@configuration/theme/theme.palette'
import { createId } from '@utils/common.utils'

import NoTableDataComponent from '@components/_tables/no-table-data'
import { TableComponentProps } from '@components/_tables/table/Table.component'

type ITableRow = any
type ITableBodyPropsFields = 'isFetching' | 'withTitles' | 'withAligment' | 'singleColor' |
                             'withPagination' | 'tableRows' | 'tableColumns' |
                             'searchTerm' | 'customNoDataMessage' | 'overlayFetching' |
                             'onRowClick'

interface ITableBodyProps extends Pick<TableComponentProps<IntlShape>, ITableBodyPropsFields> {
  colSpan: number
  indexKey: string,
  paginatedTableRows: ITableRow[]
}

export const TableBodyComponent: React.FC<ITableBodyProps> = (props) => {
  const {
    colSpan,
    withAligment,
    withPagination,
    withTitles,
    tableRows,
    indexKey,
    searchTerm,
    tableColumns,
    customNoDataMessage,
    paginatedTableRows,
    onRowClick,
  } = props

  if (!(tableRows && tableRows.length)) {
    return (
      <NoTableDataComponent
        colSpan={colSpan}
        search={searchTerm}
        customNoDataMessage={customNoDataMessage}
      />
    )
  }

  return (
    <TableBody data-testid={TableBodyComponent.name}>
      {
        paginatedTableRows.map((record, recordIndex) => {
          return (
            <React.Fragment key={createId(record[indexKey], recordIndex, 'fragment')}>
              <TableRow
                hover={true}
                key={createId(record[indexKey], recordIndex)}
                onClick={onRowClick ? (e) => onRowClick(e, recordIndex, record) : undefined}
                sx={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  '&[data-hidden-row="true"]:last-child': {
                    '& .MuiTableCell-root': {
                      borderBottom: 'none',
                    },
                  },
                  '&.MuiTableRow-root.MuiTableRow-hover': {
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                    },
                  },
                  ...(!withPagination ? {
                    '&:last-child': {
                      '& .MuiTableCell-root': {
                        borderBottom: 'none',
                      },
                    },
                  } : {}),

                  ...(recordIndex === (paginatedTableRows.length - 1) ? {
                    '& .MuiTableCell-root': {
                      borderBottom: 'none',
                    },
                  } : {}),
                }}
                style={{
                  backgroundColor: (recordIndex % 2 === 0) ? `${palette.new.gainful_grey}0D` : palette.new.white,
                }}
              >
                {
                  tableColumns?.map((column, recordColumnIndex) => {
                    const value = get(record, column.key, '')
                    const plainValue = value || column.noDataText || '-'
                    const columnValue = column.valueFormatter ? (
                      column.valueFormatter(recordIndex, record, column, props)
                    ) : (
                      plainValue
                    )

                    const title = (withTitles && typeof columnValue === 'string') ? { title: (column.plainTitle ? plainValue : columnValue) } : {}
                    const width = column.width ? { width: column.width } : {}
                    const tableCellStyles = {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '220px',

                      fontSize: '18px',
                      fontWeight: 400,
                      color: palette.new.black,
                    }

                    return (
                      <TableCell
                        key={createId(record[indexKey], recordColumnIndex)}
                        sx={tableCellStyles}
                        align={column.alignment || (withAligment && (recordColumnIndex === 0) ? 'left' : 'right')}
                        {...title}
                        {...width}
                      >
                        {columnValue}
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            </React.Fragment>
          )
        })
      }
    </TableBody>
  )
}

export default TableBodyComponent
