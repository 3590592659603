import React from 'react'
import { Typography, useTheme } from '@mui/material'

export interface OptimizeRestockDetailsItemComponentProps {
  value?: number | string,
}

const OptimizeValueComponent: React.FC<OptimizeRestockDetailsItemComponentProps> = ({
  value,
}) => {
  const theme = useTheme()

  return (
    <Typography
      data-testid={OptimizeValueComponent.name}
      sx={{
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '40px',
        color: theme.palette.new.white,
        marginRight: theme.spacing(2),
        minWidth: '175px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
          minWidth: '145px',
          fontSize: '36px',
          lineHeight: 1,
        },
      }}
    >
      {value}
    </Typography>
  )
}

export default OptimizeValueComponent
