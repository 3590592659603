import React from 'react'

const PinterestWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={PinterestWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M4.70546 1.5138C3.6192 2.00999 2.69386 2.60006 1.96969 3.25718C0.266541 4.75917 -0.229652 6.58302 0.092203 8.91647C0.387237 10.794 1.19187 12.4569 2.38542 13.8382C2.38542 13.8382 2.41224 13.8516 2.39883 13.8516L4.3836 3.36447C4.5043 2.80122 4.70546 1.5138 4.70546 1.5138ZM12.6445 3.24377C12.6445 2.22457 12.2288 1.13831 10.9816 1.13831C9.56011 1.13831 8.24586 2.74758 7.77649 5.26878L7.30712 8.03137C7.72285 8.43368 8.31292 8.66167 9.03709 8.66167C11.0755 8.66167 12.6445 5.76497 12.6445 3.24377ZM16.6007 2.13069C16.547 2.07705 16.5739 2.10387 16.5739 2.10387C16.6141 2.38549 16.6275 2.66712 16.6275 2.96215C16.6275 7.4413 13.6638 11.5315 9.18461 11.5315C8.12517 11.5315 7.24007 11.2767 6.79752 11.0085H6.74387L5.69784 16.413L5.73808 16.4398C6.89139 17.0433 8.17881 17.4993 9.5601 17.7407C11.974 18.0893 13.6638 17.5395 15.0316 15.9302C16.3861 14.4014 17.3785 11.8668 17.8613 8.82259C18.2234 5.85884 17.9015 3.64609 16.6007 2.13069Z" fill="white" fillOpacity="0.4"/>
      <path d="M12.6464 3.24378C12.6464 5.76498 11.0639 8.66167 9.03891 8.66167C8.30133 8.66167 7.72467 8.42028 7.30894 8.03137L7.77831 5.26878C8.24768 2.74759 9.56193 1.15172 10.9835 1.15172C12.2306 1.13831 12.6464 2.22457 12.6464 3.24378ZM16.6159 2.96216C16.6159 2.66712 16.6025 2.3855 16.5623 2.10388C16.522 2.06365 16.4952 2.02341 16.455 1.98318C15.1005 0.414138 12.7805 -0.283214 9.58875 0.105694C7.77831 0.387317 6.1154 0.870099 4.69387 1.51381C4.59999 2.17093 4.49271 2.80123 4.38542 3.35107L2.41406 13.8382C3.31257 14.8708 4.42566 15.7425 5.68626 16.413L6.73228 11.0085H6.78593C7.24189 11.2633 8.12699 11.5315 9.17302 11.5315C13.6522 11.5315 16.6159 7.4279 16.6159 2.96216Z" fill="white"/>

    </svg>
  )
}

export default PinterestWhiteIcon
