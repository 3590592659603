import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useRouteMatch } from 'react-router-dom'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import HelpButtonComponent from '@base/buttons/HelpButton'
import SearchFieldComponent from '@base/forms/SearchField'
import DataSourcesWidgetComponent from '@containers/modals/data-sources-modal/data-sources-widget'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { DATA_SOURCES_MODAL_NAME } from '@constants/modals.constants'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { isFetching as isFetchingCustomer } from '@redux/modules/customer/customer.selectors'

const DataSourcesModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const isFetching = useSelector((state) => isFetchingCustomer(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const open = modalPageName === DATA_SOURCES_MODAL_NAME

  const handleCloseFn = () => {
    dispatch(setPrimaryModalPageName(''))

    setSearchValue('')
  }

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    trackEvent({
      componentName: 'searchDataSourceInput',
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      useCaseId: usecase,
      search: e.target.value,
    })

    setSearchValue(e.target.value)
  }

  const headerRightSideBlocks = (
    <>
      <SearchFieldComponent
        value={searchValue}
        onChange={handleSearch}
        label={null}
        fullWidth={false}
        placeholder={intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.search' })}
      />
      <HelpButtonComponent
        tooltip={intl.formatMessage({ id: 'connect.modal.data_sources.help' })}
        name='modalHelpButton'
      />
    </>
  )

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'connect.modal.data_sources' })}
      handleClose={handleCloseFn}
      headerRightSideBlocks={headerRightSideBlocks}
    >
      <SidePanelLoadingComponent
        loading={isFetching}
      >
        <SidePanelCardComponent>
          <DataSourcesWidgetComponent
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='dataSourcesModalCloseButton'
            onClick={handleCloseFn}
            type='cancel'
            label={intl.formatMessage({ id: 'common.modal.button.close' })}
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default DataSourcesModalContainer
