import React, {
  useMemo,
  useRef,
} from 'react'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'

import ChevronLeftIcon from '@icons/chevronLeft.icon'
import ChevronRightIcon from '@icons/chevronRight.icon'
import IconButtonComponent from '@base/buttons/IconButton'

import DropdownMenuComponent, { DropdownMenuComponentProps } from '@base/dropdowns/DropdownMenu/DropdownMenu.component'

export interface DateRangeSliderComponentProps {
  /**
   * Name of the component for tracking purposes
   */
  name: string
  /**
   * Overline text
   */
  overline?: string
  /**
   * If 'true' the component is disabled
   */
  disabled?: boolean
  /**
   * If 'true' the component is loading
   */
  loading?: boolean
  /**
   * On change callback
   * @param value New value
   */
  onChange: (value: string | number) => void
  /**
   * Formatted options
   */
  options?: DropdownMenuComponentProps['options']
  /**
   * Current value
   */
  value?: string | number
  /**
   * If 'true' the options are reversed
   * The arrow buttons logic is reversed as well
   * @default false
   */
  reversed?: boolean
}

export const DateRangeSliderComponent: React.FC<DateRangeSliderComponentProps> = ({
  name,
  disabled,
  overline,
  loading,
  value,
  options = [],
  reversed,
  onChange,
}) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const optionIndex = useMemo(() => options.findIndex((option) => option.value === value), [options, value])

  const isPrevDisabled = useMemo(() => {
    return reversed ? (optionIndex === options.length - 1) : (optionIndex === 0)
  }, [
    options,
    optionIndex,
    reversed,
  ])

  const isNextDisabled = useMemo(() => {
    return reversed ? (optionIndex === 0) : (optionIndex === options.length - 1)
  }, [
    options,
    optionIndex,
    reversed,
  ])

  const handlePrev = () => {
    const prevOptionIndex = reversed ? optionIndex + 1 : optionIndex - 1

    if (prevOptionIndex < 0) {
      return
    }

    if (!options[prevOptionIndex]) {
      return
    }

    onChange(options[prevOptionIndex].value)
  }

  const handleNext = () => {
    const nextOptionIndex = reversed ? optionIndex - 1 : optionIndex + 1

    if (nextOptionIndex >= options.length) {
      return
    }

    if (!options[nextOptionIndex]) {
      return
    }

    onChange(options[nextOptionIndex].value)
  }

  const handleChange = (newValue: DropdownMenuComponentProps['value']) => {
    onChange(newValue as number)
  }

  return (
    <Box
      data-testid={DateRangeSliderComponent.name}
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <DropdownMenuComponent
        name={name}
        overline={overline}
        disabled={disabled}
        onChange={handleChange}
        options={options}
        loading={loading}
        value={value}
        forbidUnselected={true}
        maxHeight='334px'
        buttonProps={{
          ariaLabel: overline,
        }}
      />

      <Box
        display='flex'
        alignItems='center'
        flexDirection='row'
        gap={1}
        mt='16px'
      >
        <IconButtonComponent
          name='prevFold'
          color='tertiary'
          label={intl.formatMessage({ id: 'monitor.backtesting.folds.previous' })}
          IconComponent={ChevronLeftIcon}
          disabled={disabled || isPrevDisabled}
          onClick={handlePrev}
          rounded={true}
        />

        <IconButtonComponent
          name='nextFold'
          color='tertiary'
          label={intl.formatMessage({ id: 'monitor.backtesting.folds.next' })}
          IconComponent={ChevronRightIcon}
          disabled={disabled || isNextDisabled}
          onClick={handleNext}
          rounded={true}
        />
      </Box>
    </Box>
  )
}

export default DateRangeSliderComponent
