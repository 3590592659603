import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, useTheme } from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { getChipColor } from '@utils/optimize.utils'

import OptimizeDrillDownGridItemComponent from '@components/(features)/optimize/optimize-drill-down-grid-item'
import OptimizeDrillDownChartComponent from '@components/(features)/optimize/optimize-drill-down-chart'
import ChipComponent from '@base/chips/Chip'

export interface OptimizeDrillDownComponentProps {
  drillDownData: Optimize.OptimizeDrillDownItem,
  exportId?: string,
}

const OptimizeDrillDownComponent: React.FC<OptimizeDrillDownComponentProps> = (props) => {
  const {
    drillDownData,
    exportId,
  } = props

  const theme = useTheme()
  const intl = useIntl()

  const {
    meta,
    data,
  } = drillDownData

  const {
    currentStock,
    currentStockInEur,
    currentReachInDays,
    restockRecommendation,
    gradientPercentage,
    stockStatus,
  } = meta

  const chipColor = getChipColor(stockStatus)
  const currentStockBlock = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
      }}
    >
      <ChipComponent
        color={chipColor}
        label={stockStatus}
        textTransform='lowercase'
      />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '48px',
          lineHeight: 1,
          marginTop: theme.spacing(1),
          color: chipColor || theme.palette.new.black,
        }}
      >
        {defaultNumberFormatter(currentStock)}
      </Typography>
    </Box>
  )
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.new.white,
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }}
      data-testid={OptimizeDrillDownComponent.name}
      id={exportId}
    >
      <OptimizeDrillDownChartComponent
        dataset={data}
        gradientPercentage={gradientPercentage}
      />
      <Box
        sx={{
          display: 'grid',
          background: theme.palette.new.white,
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.new.business_black_20}`,
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)',
          gridRowGap: theme.spacing(0),
          gridColumnGap: theme.spacing(0),
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}
      >
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.currentStock' })}
          subHeader={intl.formatMessage({ id: 'optimize.drillDown.currentStockValue' }, { value: defaultNumberFormatter(currentStockInEur) })}
          value={currentStockBlock}
          borderBottom={true}
          borderRight={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.rangeOfStorage' })}
          value={intl.formatMessage({ id: 'optimize.drillDown.rangeOfStorageDays' }, { days: currentReachInDays })}
          borderBottom={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.leadTime' })}
          value={intl.formatMessage({ id: 'optimize.drillDown.leadTimeDays' }, { days: currentReachInDays })}
          borderRight={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.reorder' })}
          subHeader={intl.formatMessage({ id: 'optimize.drillDown.reorderRecommendation' })}
          value={String(defaultNumberFormatter(restockRecommendation))}
          colorfull={true}
        />
      </Box>
    </Box>
  )
}

export default OptimizeDrillDownComponent
