import React from 'react'

const LeafIcon: React.FC<Common.IconProps> = ({
  width = 19, height = 19,
  viewBox = '0 0 19 19', fill = 'none', opacity = '1',
  className = '', detailsFill = '#00C04D',
  ...rest
}) => {
  return (
    <svg data-testid={LeafIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <g clipPath="url(#clip0_2065_1703)">
        <path d="M14.8908 7.58544C17.4623 11.2066 15.4951 15.3543 14.5437 16.9722C14.4042 17.1985 14.2167 17.3916 13.9947 17.538C13.7725 17.6844 13.5212 17.7805 13.258 17.8196C11.3937 18.1407 6.75223 18.5003 4.25795 14.8791C1.80223 11.4634 1.90509 5.45386 2.12366 2.59033C2.13227 2.37699 2.19149 2.16874 2.29646 1.98273C2.40143 1.79672 2.54912 1.63826 2.72738 1.5204C2.90564 1.40254 3.10938 1.32866 3.32183 1.30482C3.53428 1.28098 3.74937 1.30787 3.94937 1.38328C6.71366 2.17943 12.4737 4.16976 14.8908 7.58544Z" stroke={detailsFill} strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.22461 6.39124C9.16958 9.85734 11.7543 13.6133 13.9389 17.6013" stroke={detailsFill} strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2065_1703">
          <rect width="18" height="18" fill="white" transform="translate(0.0917969 0.653564)"/>
        </clipPath>
      </defs>

    </svg>
  )
}

export default LeafIcon
