import React from 'react'

const ShrinkIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ShrinkIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M14.1428 6.16089L10.9285 9.37517L14.1428 12.5895" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.85718 6.16089L7.07146 9.37517L3.85718 12.5895" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.642822 1.01807V17.7324" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.3572 1.01807V17.7324" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ShrinkIcon
