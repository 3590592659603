import React, { useMemo } from 'react'
import {
  IconButtonProps,
  IconButton,
  useTheme,
  Box,
} from '@mui/material'

import { useRouteMatch } from 'react-router-dom'
import { getButtonsStyles } from '@utils/ui.utils'

import {
  trackEvent,
  TRACKING_ACTIONS,
  TrackingEventNameProps,
} from '@utils/tracking.utils'

import { DEFAULT_BORDER_RADIUS, DEFAULT_BUTTON_HEIGHT, DEFAULT_BUTTON_TRANSITION } from '@constants/ui.constants'
import { BadgeComponent, BadgeComponentProps } from '@base/buttons/Badge/Badge.component'

import TooltipComponent from '@base/tooltips/Tooltip'
import LoadingCloudComponent from '@base/loading/LoadingCloud'

export interface IconButtonComponentProps extends Pick<IconButtonProps, 'onClick' | 'disabled' | 'sx'> {
  /**
   * Button name, used for tracking and testing
   */
  name: string
  /**
   * Button label
   */
  label: string
  /**
   * Aria label for the button. By default, it is the same as the label.
   * Please provide a different value if the badge is used.
   */
  ariaLabel?: string
  /**
   * Tooltip to be displayed when the button is disabled
   */
  disabledTooltip?: string
  /**
   * Whether to display a tooltip on hover
   */
  tooltip?: boolean
  /**
   * Tooltip placement
   */
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  /**
   * Button color
   *
   * @default 'primary'
   */
  color?: 'primary' | 'secondary' | 'tertiary'
  /**
   * If 'true', the button will be in loading state
   */
  loading?: boolean
  /**
   * If 'true', the button will be rounded
   *
   * @default false
   */
  rounded?: boolean
  /**
   * Ref to be forwarded to the button
   */
  forwardedRef?: React.ForwardedRef<any>
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: Partial<TrackingEventNameProps>
  /**
   * Icon component to be displayed
   */
  IconComponent: React.FC<Common.IconProps>
  /**
   * Props to be passed to the Icon component
   */
  iconComponentProps?: Common.IconProps
  /**
   * Badge component props
   */
  badgeProps?: BadgeComponentProps
}

export const IconButtonComponent: React.FC<IconButtonComponentProps> = ({
  name,
  loading,
  forwardedRef,
  IconComponent,
  iconComponentProps,
  label,
  ariaLabel,
  onClick,
  color = 'primary',
  disabled,
  trackingProps,
  disabledTooltip,
  badgeProps,
  tooltip = false,
  rounded = false,
  tooltipPlacement = 'left',
  sx,
  ...rest
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CLICK,
      ...trackingProps,
    }, {
      router: params,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const buttonStyles = getButtonsStyles(theme, color, 'icon', loading)
  const tooltipStyles = {
    ...(!(disabled && disabledTooltip) ? {
      fontWeight: '500 !important',
    } : {}),
  }

  const content = useMemo(() => {
    if (!IconComponent) {
      return undefined
    }

    const icon = (
      <IconComponent
        {...(color === 'primary' ? {
          detailsFill: 'white',
        } : {})}
        {...(disabled ? {
          detailsFill: 'rgba(0, 0, 0, 0.20)',
        } : {})}
        {...iconComponentProps}
      />
    )

    if (badgeProps) {
      return (
        <BadgeComponent
          {...badgeProps}
        >
          {icon}
        </BadgeComponent>
      )
    }

    return icon
  }, [
    color,
    badgeProps,
    IconComponent,
    iconComponentProps,
    disabled,
  ])

  const component = (
    <IconButton
      {...rest}
      data-testid={name}
      ref={forwardedRef}
      disableRipple={true}
      onClick={onClickHandler}
      disabled={disabled}
      aria-label={ariaLabel || label}
      sx={{
        fontWeight: 400,
        transition: DEFAULT_BUTTON_TRANSITION,
        height: DEFAULT_BUTTON_HEIGHT,
        width: DEFAULT_BUTTON_HEIGHT,
        borderRadius: rounded ? DEFAULT_BORDER_RADIUS.SMALL : DEFAULT_BUTTON_HEIGHT,

        ...buttonStyles,

        '&.Mui-disabled': {
          cursor: 'not-allowed',
          backgroundColor: theme.palette.new.business_black_5,
        },

        ...(loading ? {
          pointerEvents: 'none',
          cursor: 'progress',
        } : {}),

        ...sx,
      }}
    >
      {
        loading ? (
          <LoadingCloudComponent size='small' />
        ) : (
          content
        )
      }
    </IconButton>
  )

  if (((disabled && disabledTooltip) || tooltip) && !loading) {
    return (
      <TooltipComponent
        describeChild={true}
        title={disabled ? disabledTooltip || label : label}
        placement={tooltipPlacement}
        tooltipSx={tooltipStyles}
      >
        <Box component='span' display='inline-block'>
          {component}
        </Box>
      </TooltipComponent>
    )
  }

  return component
}

export default React.forwardRef<React.FC, IconButtonComponentProps>((props, ref) => <IconButtonComponent {...props} forwardedRef={ref} />)
