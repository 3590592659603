import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import {
  Box, CircularProgress,
  Typography, useTheme,
  MenuItem, MenuList,
} from '@mui/material'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextFieldComponent from '@base/forms/TextField'

import { DEFAULT_BORDER_RADIUS, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'
import { getAutocompleteEndAdornmentButtonStyles } from '@utils/ui.utils'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ChevronIcon from '@icons/flow/chevron.icon'
import CloseIcon from '@icons/close.icon'

export interface AutocompleteSelectFieldProps<T> extends Omit<AutocompleteProps<any, false, false, any>, 'renderInput' | 'options'> {
  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name: string,
  /**
   * The options to be shown
   */
  options: T[],
  /**
   * onChange callback
   * @param e Event
   * @param selectedValue Selected value
   * @param reason Reason
   */
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: T, reason: any): any,
  /**
   * Gets the label of the option
   * @param option Option
   * @returns Label
   */
  getOptionLabel: (option: T) => string,
  /**
   * The label content.
   */
  label: string
  /**
   * If true, the label will be hidden.
   */
  hideLabel?: boolean
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Input validation errors.
   */
  errors?: string | NonNullable<React.ReactNode>
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * If true, loading spinner will be shown.
   */
  loading?: boolean
  /**
   * Help text to be displayed in a tooltip.
   */
  floatingHelp?: string | NonNullable<React.ReactNode>
}

const AutocompleteSelectFieldComponent = <T extends {}> ({
  name,
  value,
  handleChangeCallback,
  getOptionLabel,
  label,
  hideLabel,
  errors,
  touched,
  options,
  disabled,
  floatingHelp,
  placeholder,
  noOptionsText,
  loading,
  ...rest
}: AutocompleteSelectFieldProps<T>) => {
  const theme = useTheme()
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const errorMessages = touched && errors

  const handleChange = (e: React.ChangeEvent<any>, selectedValue: T, reason: any) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      selectedValue,
      router: params,
    })

    handleChangeCallback && handleChangeCallback(e, selectedValue, reason)
  }

  const autocompleteStyles = useMemo(() => {
    return {
      '&.MuiAutocomplete-root': {
        '&.MuiAutocomplete-root.Mui-focused.Mui-expanded': {
          '& .MuiInputBase-root.Mui-focused:not(.Mui-error)': {
            borderColor: theme.palette.new.business_black,
          },
          '& .MuiInputBase-root.Mui-focused.Mui-error': {
            borderColor: theme.palette.new.rebellious_red,
          },
        },

        '& .MuiInputBase-root': {
          gap: '5px',
          paddingRight: `${floatingHelp ? '80px' : '40px'} !important`,
          minHeight: '36px',
        },

        '& .MuiAutocomplete-endAdornment': {
          ...getAutocompleteEndAdornmentButtonStyles(theme, Boolean(floatingHelp)),
        },
      },
    }
  }, [theme, floatingHelp])

  const slotProps = useMemo(() => {
    return {
      paper: {
        sx: {
          outline: `1px solid ${theme.palette.new.business_black_20}`,
          boxShadow: DEFAULT_SELECT_BOX_SHADOW,
          borderRadius: DEFAULT_BORDER_RADIUS.SMALL,

          '& .MuiAutocomplete-noOptions': {
            backgroundColor: theme.palette.new.smokey_silver,
            padding: theme.spacing(0),
            height: '36px',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      popper: {
        sx: {
          marginTop: '1px !important',
          marginBottom: '1px !important',
        },
      },
    }
  }, [theme])

  return (
    <Box
      data-testid={AutocompleteSelectFieldComponent.name}
    >
      <Autocomplete
        id={name}
        autoHighlight={false}
        autoSelect={false}
        fullWidth={true}
        disablePortal={false}
        disableCloseOnSelect={false}
        freeSolo={false}
        clearIcon={<CloseIcon />}
        popupIcon={<ChevronIcon />}
        noOptionsText={(
          <Typography
            variant='body1'
            px='12px'
            color={theme.palette.new.business_black_40}
          >
            {noOptionsText || intl.formatMessage({ id: 'common.dropdown.noOptions' })}
          </Typography>
        )}
        options={options}
        value={value}
        disabled={disabled}
        onChange={(event, newValue: T, reason) => {
          handleChange(event, newValue, reason)
        }}
        getOptionLabel={getOptionLabel}
        ListboxComponent={MenuList}
        renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: T, { selected } : { selected: boolean }) => {
          return (
            <MenuItem
              {...props}
              selected={selected}
            >
              {getOptionLabel(option)}
            </MenuItem>
          )
        })}
        renderInput={(inputParams) => (
          <TextFieldComponent
            {...inputParams.InputProps}
            inputProps={inputParams.inputProps}
            name={name}
            label={label}
            disabled={disabled}
            hideLabel={hideLabel}
            touched={touched}
            placeholder={disabled ? '' : placeholder}
            errors={errorMessages}
            floatingHelp={floatingHelp}
            endAdornment={(
              <>
                {loading ? <CircularProgress color='inherit' size={16} sx={{ opacity: '0.6' }} /> : null}
                {inputParams.InputProps.endAdornment}
              </>
            )}
          />
        )}
        loading={loading}
        slotProps={slotProps}
        sx={autocompleteStyles}
        {...rest}
      />
    </Box>
  )
}

export default AutocompleteSelectFieldComponent
