import produce from 'immer'

import { ActionPayload, ModalManagerState } from '@redux/modules/types'

export const handleSetPrimaryModalPageName = (state: ModalManagerState, action: ActionPayload<Common.ModalPageName>) => {
  const nextState = produce(state, (draftState) => {
    const withDetails = ((typeof action.payload !== 'string') && (action.payload !== null))

    if (withDetails) {
      const payload = action.payload as Common.ModalPageNameWithDetails

      draftState.primaryModalPage = payload.primaryModalPage
      draftState.modalDetails = payload.modalDetails
    } else {
      draftState.primaryModalPage = action.payload as Common.ModalPageNameContent
      draftState.modalDetails = action.payload ? draftState.modalDetails : { returnTo: '' }
    }
  })

  return nextState
}

export const handleSetModalDetails = (state: ModalManagerState, action: ActionPayload<Common.ModalDetails>) => {
  const nextState = produce(state, (draftState) => {
    draftState.modalDetails = action.payload
  })

  return nextState
}
