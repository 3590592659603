import React, { useMemo } from 'react'

import {
  Box,
  Slider, SliderProps,
  SliderThumb, SliderValueLabelProps,
  useTheme,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import { SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS } from '@constants/tooltips.constants'
import FormattedUnitValueComponent from '@components/(features)/trade-offs/formatted-unit-value'

const ThumbComponent = React.forwardRef((props, ref: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other} ref={ref}>
      {children}
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
    </SliderThumb>
  )
}) as React.ElementType<React.HTMLAttributes<HTMLSpanElement>>

export interface ValueLabelComponentProps extends SliderValueLabelProps {
  children: React.ReactElement<any, any>,
  open: boolean,
  value: number,
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    value,
  } = props

  return (
    <TooltipComponent
      open={open}
      describeChild={true}
      enterTouchDelay={0}
      placement='top'
      title={value}
      ref={ref}
      TransitionProps={SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS}
    >
      {children}
    </TooltipComponent>
  )
})

export interface SimpleSliderFieldComponentProps extends SliderProps {
  className?: string,
  unit?: string,
  trackColor?: string,
  counts?: any[],
  formatter?: (value: number) => string,
  tooltipFormatter?: (value: number) => string,
  valueLabelFormat?: (value: number) => string,
}

const OptimizeSliderFieldComponent: React.FC<SimpleSliderFieldComponentProps> = ({
  className,
  max = 100,
  min = 0,
  value,
  unit,
  trackColor,
  counts = [],
  valueLabelDisplay = 'auto',
  valueLabelFormat,
  formatter,
  ...props
}) => {
  const theme = useTheme()
  const formattedMin = formatter ? formatter(min!) : String(min)
  const formattedMax = formatter ? formatter(max!) : String(max)

  const sliderStyles = useMemo(() => {
    return {
      color: theme.palette.new.black,
      height: '1px',
      padding: '13px 0',

      '& .MuiSlider-thumb': {
        zIndex: 5,
        height: '40px',
        width: '19px',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.new.black}`,
        boxShadow: 'none',
        transition: 'none',
        borderRadius: '5px',
        '&.Mui-disabled': {
          height: '40px',
          width: '19px',
          backgroundColor: theme.palette.new.grey_d,
          cursor: 'not-allowed',
          pointerEvents: 'all',
        },
        '&:focus, &:hover, &$active': {
          boxShadow: 'none !important',
        },
        '&.Mui-active': {
          boxShadow: 'none !important',
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none !important',
        },
        '&:before': {
          boxShadow: 'none !important',
        },
        '& .bar': {
          height: '13px',
          width: '1px',
          backgroundColor: theme.palette.new.black,
          marginLeft: '1.5px',
          marginRight: '1.5px',
        },
        '&:hover': {
          boxShadow: 'none !important',
        },
      },
      '& .MuiSlider-mark': {
        height: '7px',
        width: '1px',
        backgroundColor: theme.palette.new.black_d,
      },

      '& .MuiSlider-markActive': {
        opacity: 1,
        height: '7px',
        backgroundColor: theme.palette.new.white,
      },

      '& .MuiSlider-track': {
        height: '1px',
        border: 'none',
        transition: 'none',
        color: trackColor || theme.palette.new.white,
      },

      '& .MuiSlider-rail': {
        opacity: 1,
        height: '1px',
        color: theme.palette.new.black_d,
      },
    }
  }, [trackColor, theme])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <FormattedUnitValueComponent
        value={formattedMin}
        unit={unit}
        sx={{ marginRight: theme.spacing(3) }}
      />

      <Slider
        data-testid={OptimizeSliderFieldComponent.name}
        value={value}
        min={min}
        max={max}
        components={{
          Thumb: ThumbComponent,
          ValueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        sx={sliderStyles}
        {...props}
      />

      <FormattedUnitValueComponent
        value={formattedMax}
        unit={unit}
        sx={{ marginLeft: theme.spacing(3) }}
      />
    </Box>
  )
}

export default OptimizeSliderFieldComponent
