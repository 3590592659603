import { handleActions } from 'redux-actions'
import { ParametersState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_PARAMETER,
  RECEIVE_GROUPING,
  START_FETCHING_PARAMETER,
  STOP_FETCHING_PARAMETER,
} from './parameters.action-types'

import {
  receive,
  receiveGrouping,
  initialState,
} from './parameters.reducers'

export default handleActions<ParametersState, any>({
  [RECEIVE_PARAMETER]: receive,
  [RECEIVE_GROUPING]: receiveGrouping,
  [START_FETCHING_PARAMETER]: startFetchingAction<ParametersState>,
  [STOP_FETCHING_PARAMETER]: stopFetchingAction<ParametersState>,
}, initialState)
