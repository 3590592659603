import React, { useCallback } from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import SettingsIcon from '@icons/settings.icon'
import ContextMenuComponent, { ContextMenuAction } from '@base/menus/ContextMenu/ContextMenu.component'
import ManagePromotionsModalContainer from '@containers/modals/manage-promotions-modal'

import { requestPromotionsVisibilityChangeAction } from '@redux/modules/insights/insights.actions'
import { getPromotionsVisibility } from '@redux/modules/insights/insights.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { MANAGE_PROMOTIONS_MODAL_NAME } from '@constants/modals.constants'

export interface InsightsChartMenuComponentProps {}

const InsightsChartMenuComponent: React.FC<InsightsChartMenuComponentProps> = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const intl = useIntl()
  const promotionsVisibility = useSelector((state) => getPromotionsVisibility(state))

  const handleShowEventsToggle = useCallback(() => {
    dispatch(
      requestPromotionsVisibilityChangeAction(!promotionsVisibility),
    )
  }, [dispatch, promotionsVisibility])

  const handleManageEvents = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(MANAGE_PROMOTIONS_MODAL_NAME),
    )
  }, [dispatch])

  const chartControlMenuActions: ContextMenuAction[] = [{
    name: 'showEvents',
    type: 'toggle',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.showEvents' }),
    disabled: false,
    checked: promotionsVisibility,
    handler: handleShowEventsToggle,
  }, {
    name: 'manageEvents',
    label: intl.formatMessage({ id: 'insights.chart.menu.settings.manageEvents' }),
    disabled: false,
    handler: handleManageEvents,
  }]

  return (
    <Box
      data-testid={InsightsChartMenuComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(0.5),
      }}
    >
      <ContextMenuComponent
        name='chartSettingsMenu'
        actions={chartControlMenuActions}
        label={intl.formatMessage({ id: 'insights.chart.menu.settings' })}
        IconComponent={SettingsIcon}
        buttonColor='tertiary'
      />

      <ManagePromotionsModalContainer />
    </Box>
  )
}

export default InsightsChartMenuComponent
