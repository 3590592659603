import React from 'react'

import { IconButton, useTheme } from '@mui/material'
import { FLOW_ELEMENTS_TRANSITIONS } from '@constants/flow.constants'
import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'

import AddFlowIcon from '@icons/flow/addFlow.icon'

export interface AddButtonProps {
  /**
   * Data test id
   */
  dataTestId?: string,
  /**
   * If true, the button will be disabled
   */
  disabled?: boolean,
  /**
   * Click handler
   */
  onClick?: React.MouseEventHandler,
}

export const AddNodeButtonComponent: React.FC<AddButtonProps> = ({
  disabled,
  onClick,
  dataTestId,
}) => {
  const theme = useTheme()
  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'addNodeButton',
      actionName: TRACKING_ACTIONS.CLICK,
    })

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <IconButton
      onClick={handleOnClick}
      disabled={disabled}
      data-testid={dataTestId || AddNodeButtonComponent.name}
      size='large'
      sx={{
        background: 'rgba(0, 0, 0, 0.4)',
        width: theme.spacing(2.8),
        height: theme.spacing(2.8),
        padding: 0,
        ...FLOW_ELEMENTS_TRANSITIONS,
        '& svg > path': {
          stroke: theme.palette.new.white,
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
        },
      }}
    >
      <AddFlowIcon />
    </IconButton>
  )
}

export default AddNodeButtonComponent
