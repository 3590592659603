export const REQUEST_PARAMETERS_TEMPLATES = 'REQUEST_PARAMETERS_TEMPLATES'
export const RECEIVE_PARAMETERS_TEMPLATES = 'RECEIVE_PARAMETERS_TEMPLATES'

export const REQUEST_PARAMETER_TEMPLATE = 'REQUEST_PARAMETER_TEMPLATE'
export const RECEIVE_PARAMETER_TEMPLATE = 'RECEIVE_PARAMETER_TEMPLATE'

export const CREATE_PARAMETERS_TEMPLATES = 'CREATE_PARAMETERS_TEMPLATES'
export const UPDATE_PARAMETERS_TEMPLATES = 'UPDATE_PARAMETERS_TEMPLATES'
export const DELETE_PARAMETERS_TEMPLATES = 'DELETE_PARAMETERS_TEMPLATES'

export const START_FETCHING_PARAMETERS_TEMPLATES = 'START_FETCHING_PARAMETERS_TEMPLATES'
export const STOP_FETCHING_PARAMETERS_TEMPLATES = 'STOP_FETCHING_PARAMETERS_TEMPLATES'
