import { State } from '@redux/modules/types'

import {
  REQUEST_OPTIMIZE_PAGE_DATA,
  REQUEST_OPTIMIZE_DRILL_DOWN,
  SET_TARGET_REVENUE,
  SET_TARGET_REPLENISHMENT_COST,
} from './optimize.action-types'

export const isFetchingData = (state: State) => {
  return state.optimize.fetchingKeys.includes(REQUEST_OPTIMIZE_PAGE_DATA) || state.optimize.fetchingKeys.includes(REQUEST_OPTIMIZE_DRILL_DOWN)
}

export const isReflectingTargets = (state: State) => {
  return state.optimize.fetchingKeys.includes(SET_TARGET_REVENUE) || state.optimize.fetchingKeys.includes(SET_TARGET_REPLENISHMENT_COST)
}

export const getDrillDownData = (state: State) => {
  return state.optimize.drillDownData
}

export const getTableData = (state: State) => {
  return state.optimize.tableData
}

export const getRangeSelectorData = (state: State) => {
  return state.optimize.rangeSelector
}

export const getPredictionOverview = (state: State) => {
  return state.optimize.predictionOverview
}

export const getRangeSelectorType = (state: State) => {
  return state.optimize.rangeSelector.targetSelectorType
}
