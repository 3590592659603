import React from 'react'

const SAPIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={SAPIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M9.09716 13.4496H0V4.54517H18L9.09716 13.4496ZM8.90507 6.32603H10.6828L10.6828 6.32583C12.3758 6.32583 13.1712 6.89888 13.1717 8.21456C13.1717 9.39588 12.3535 10.1003 10.9693 10.1003H10.3967L10.3901 11.6711L7.69902 11.6685L7.51143 10.9878C7.23381 11.0788 6.92077 11.1295 6.58795 11.1295C6.24641 11.1295 5.92467 11.076 5.6417 10.9795L5.37259 11.6685L3.87269 11.6732L3.93997 11.3266C3.93615 11.3299 3.93235 11.3333 3.92856 11.3366C3.91116 11.352 3.89391 11.3672 3.87507 11.3824C3.47417 11.7049 2.96958 11.8539 2.34786 11.8652L2.18718 11.8664C1.47285 11.8664 0.844196 11.6974 0.251953 11.3606L0.799476 10.2743C1.39231 10.6247 1.76432 10.7036 2.26633 10.693C2.52654 10.6878 2.71452 10.6403 2.8372 10.5137C2.91121 10.4361 2.94999 10.3399 2.95395 10.2371C2.9589 9.95488 2.55721 9.8223 2.06549 9.66994C1.66677 9.54647 1.21422 9.37827 0.860422 9.11905C0.441716 8.81096 0.242455 8.4255 0.252349 7.88173C0.258285 7.48657 0.404714 7.12921 0.6762 6.84823C1.06562 6.44555 1.69388 6.20117 2.44838 6.20117H2.45234C3.09939 6.20177 3.88852 6.3852 4.4592 6.67568L3.8438 7.74817C3.21792 7.44581 2.9033 7.41712 2.54712 7.39793C2.01622 7.36963 1.74908 7.55939 1.74513 7.764C1.73998 8.01332 2.24674 8.2389 2.72422 8.39225C3.44726 8.62199 4.36619 8.9287 4.50669 9.81756L5.8281 6.32445H7.36303L8.91101 10.5072L8.90507 6.32603ZM6.02043 9.80074C6.18724 9.86406 6.38116 9.90007 6.58794 9.90007H6.58774C6.78898 9.90007 6.97835 9.86505 7.14457 9.80568L6.59249 8.02876H6.58161L6.02043 9.80074ZM10.3963 8.882H10.7869H10.7871C11.3075 8.882 11.7226 8.70886 11.723 8.15876C11.723 7.62726 11.3075 7.45393 10.7871 7.45393H10.3963V8.882Z" fill={detailsFill || "url(#paint0_linear_2860_8496)"}/>
      <defs>
        <linearGradient id="paint0_linear_2860_8496" x1="9" y1="4.54517" x2="9" y2="13.4498" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00AEEF"/>
          <stop offset="0.212" stopColor="#0097DC"/>
          <stop offset="0.519" stopColor="#007CC5"/>
          <stop offset="0.792" stopColor="#006CB8"/>
          <stop offset="1" stopColor="#0066B3"/>
        </linearGradient>
      </defs>

    </svg>
  )
}

export default SAPIcon
