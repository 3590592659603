import React from 'react'
import useForkRef from '@mui/utils/useForkRef'
import { Moment } from 'moment'
import { FieldType, SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro'

import DropdownButtonComponent from '@base/dropdowns/DropdownButton'

interface DateRangePickerButtonComponentProps extends SingleInputDateRangeFieldProps<Moment> {
  /**
   * Function to set the open state
   */
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * Label text
   */
  overline?: string
  /**
   * If true, date range picker is opened
   */
  open?: boolean
}

type DateRangeButtonFieldComponent = ((
  props: DateRangePickerButtonComponentProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { fieldType?: FieldType };

const DateRangePickerButtonComponent = React.forwardRef(
  (props: DateRangePickerButtonComponentProps, ref: React.Ref<HTMLElement>) => {
    const {
      setOpen,
      open,
      label,
      overline,
      disabled,
      InputProps: { ref: containerRef } = {},
      inputProps: { 'aria-label': ariaLabel } = {},
    } = props

    const handleRef = useForkRef(ref, containerRef)

    return (
      <DropdownButtonComponent
        dataTestId={DateRangePickerButtonComponent.name}
        name='datePickerDropdownButton'
        disabled={disabled}
        outlined={true}
        handleRef={handleRef}
        ariaLabel={ariaLabel}
        open={open}
        onClick={() => {
          if (setOpen) {
            setOpen((prev) => !prev)
          }
        }}
        overline={overline}
        label={label}
        bold={true}
      />
    )
  },
) as DateRangeButtonFieldComponent

export default DateRangePickerButtonComponent
