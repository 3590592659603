import React from 'react'

const FacebookWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={FacebookWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M9 0.5C13.6944 0.5 17.5 4.30558 17.5 9C17.5 13.2426 14.3917 16.7591 10.3281 17.3967V11.457H12.3087L12.6855 9H10.3281V7.40554C10.3281 6.73333 10.6574 6.07812 11.7133 6.07812H12.7852V3.98633C12.7852 3.98633 11.8124 3.82031 10.8824 3.82031C8.94087 3.82031 7.67188 4.99703 7.67188 7.12734V9H5.51367V11.457H7.67188V17.3967C3.60833 16.7591 0.5 13.2426 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5Z" fill="black"/>

    </svg>
  )
}

export default FacebookWhiteIcon
