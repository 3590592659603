import { generatePath } from 'react-router-dom'
import { IntlShape } from 'react-intl'

import {
  DASHBOARD_PATH,
  CONNECT_PATH,
  MONITOR_PATH,
  EXPORT_PATH,
  TRADE_OFFS_PATH,
  COMPANIES_PATH,
  USERS_PATH,
  CONNECTORS_PATH,
  OPTIMIZE_PATH,
  FILE_MANAGER_PATH,
  PIPELINES_PATH,
  USE_CASE_PIPELINES_PATH,
  ARTICLE_ALLOCATION_RESULTS_PATH,
  ARTICLE_ALLOCATION_ANALYZE_PATH,
  ARTICLE_ALLOCATION_EXPORT_PATH,
  ARTICLE_ALLOCATION_SETUP_PATH,
  ANALYZE_PATH,
  REPLENISHMENT_RESULTS_PATH,
  REPLENISHMENT_ANALYZE_PATH,
  REPLENISHMENT_DISCOVER_PATH,
  REPLENISHMENT_EXPORT_PATH,
  REPLENISHMENT_SETUP_PATH,
  TRAINING_DATA_OVERVIEW_PATH,
  SNAPSHOTS_PATH,
} from '@constants/routes.constants'

import { MODAL_NAMES_TO_HASH_MAP, MODAL_HASH_TO_NAME_MAP } from '@constants/modals.constants'
import { USE_CASE_FAMILY_TYPES } from '@constants/use-cases.constants'
import { isOptimizeEnabled, isUseCaseMenuItemDisabled } from '@utils/use-cases.utils'

import DashboardIcon from '@icons/navigation/dashboard.icon'
import IntegrationsIcon from '@icons/navigation/integrations.icon'
import CompaniesIcon from '@icons/navigation/companies.icon'
import UsersIcon from '@icons/navigation/users.icon'
import CalendarIcon from '@icons/navigation/calendar.icon'

import ConnectIcon from '@icons/navigation/connect.icon'
import InsightsIcon from '@icons/navigation/insights.icon'
import TradeOffsIcon from '@icons/navigation/tradeOffs.icon'
import WidgetIcon from '@icons/navigation/widget.icon'
import InsightsChartIcon from '@icons/navigation/insightsChart.icon'
import DiscoverIcon from '@icons/navigation/discover.icon'
import DatabaseIcon from '@icons/navigation/database.icon'
import OutboundEmailIcon from '@icons/navigation/outboundEmail.icon'

import ParetosSftpIcon from '@icons/flow/paretosSftp.icon'
import SnapshotIcon from '@icons/navigation/snapshot.icon'

/**
 * @function getNavigation Returns project overview navigation
 *
 * @param {Object} intl React Intl instance
 * @param {String} usecase Selected Use Case ID
 * @param {Object} useCaseDetails Use Case Details
 * @param {Boolean} isAdmin if 'true', user is an admin
 * @param {String} companyId Selected Company ID
 *
 *
 * @return {Array} Navigation items
 */
export const getNavigation = (
  intl: IntlShape,
  usecase: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
  companyId: string,
): Common.NavigationItem[] => {
  if ((!usecase && (companyId || isAdmin))) {
    return [{
      href: generatePath(DASHBOARD_PATH),
      title: intl.formatMessage({ id: 'common.layout.navigation.dashboard' }),
      SideMenuIconComponent: DashboardIcon,
      onlyForAdmin: false,
    }, {
      href: generatePath(USERS_PATH),
      title: intl.formatMessage({ id: 'common.layout.navigation.users' }),
      SideMenuIconComponent: UsersIcon,
      onlyForAdmin: false,
    }, {
      href: generatePath(CONNECTORS_PATH),
      title: intl.formatMessage({ id: 'common.layout.navigation.connectors' }),
      SideMenuIconComponent: IntegrationsIcon,
      onlyForAdmin: true,
    }, {
      href: generatePath(COMPANIES_PATH),
      title: intl.formatMessage({ id: 'common.layout.navigation.company' }),
      SideMenuIconComponent: CompaniesIcon,
      onlyForAdmin: true,
    }, {
      href: generatePath(PIPELINES_PATH),
      title: intl.formatMessage({ id: 'common.layout.navigation.pipelines' }),
      SideMenuIconComponent: CalendarIcon,
      onlyForAdmin: true,
    }]
  }

  if (!usecase && !companyId && !isAdmin) {
    return []
  }

  return getUseCaseNavigation(intl, usecase, useCaseDetails, isAdmin)
}

export const getUseCaseNavigation = (
  intl: IntlShape,
  usecase: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
): Common.NavigationItem[] => {
  const useCaseNavigationItems: Common.NavigationItem[] = [
    {
      href: generatePath(TRAINING_DATA_OVERVIEW_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.training_data' }),
      SideMenuIconComponent: DatabaseIcon,
      onlyForAdmin: true,
    },
    {
      href: generatePath(FILE_MANAGER_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.fileManager' }),
      SideMenuIconComponent: ParetosSftpIcon,
      onlyForAdmin: true,
    }, {
      href: generatePath(USE_CASE_PIPELINES_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.pipelines' }),
      SideMenuIconComponent: CalendarIcon,
      onlyForAdmin: true,
    }, {
      href: generatePath(SNAPSHOTS_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.snapshots' }),
      SideMenuIconComponent: SnapshotIcon,
      onlyForAdmin: true,
    },
  ]

  switch (useCaseDetails.family) {
    case USE_CASE_FAMILY_TYPES.SALES_PLANNING:
    case USE_CASE_FAMILY_TYPES.WORKFORCE_PLANNING:
    case USE_CASE_FAMILY_TYPES.ARTICLE_ALLOCATION:
      useCaseNavigationItems.unshift(...getArticleAllocationUseCaseNavigation(intl, usecase, useCaseDetails, isAdmin))
      break
    case USE_CASE_FAMILY_TYPES.REPLENISHMENT:
      useCaseNavigationItems.unshift(...getReplenishmentUseCaseNavigation(intl, usecase, useCaseDetails, isAdmin))
      break
    case USE_CASE_FAMILY_TYPES.CUSTOM:
    case USE_CASE_FAMILY_TYPES.DEMAND:
    default:
      useCaseNavigationItems.unshift(...getDemandUseCaseNavigation(intl, usecase, useCaseDetails, isAdmin))
      break
  }

  return useCaseNavigationItems
}

export const getReplenishmentUseCaseNavigation = (
  intl: IntlShape,
  usecase: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
): Common.NavigationItem[] => {
  return [
    {
      href: generatePath(REPLENISHMENT_RESULTS_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.rp_results' }),
      SideMenuIconComponent: WidgetIcon,
      disabled: isUseCaseMenuItemDisabled(REPLENISHMENT_RESULTS_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(REPLENISHMENT_DISCOVER_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.rp_discover' }),
      SideMenuIconComponent: DiscoverIcon,
      disabled: isUseCaseMenuItemDisabled(REPLENISHMENT_DISCOVER_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(REPLENISHMENT_ANALYZE_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.rp_analyze' }),
      SideMenuIconComponent: InsightsIcon,
      disabled: isUseCaseMenuItemDisabled(REPLENISHMENT_ANALYZE_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(REPLENISHMENT_EXPORT_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.rp_export' }),
      SideMenuIconComponent: OutboundEmailIcon,
      disabled: isUseCaseMenuItemDisabled(REPLENISHMENT_EXPORT_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(REPLENISHMENT_SETUP_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.rp_setup' }),
      SideMenuIconComponent: ConnectIcon,
      disabled: isUseCaseMenuItemDisabled(REPLENISHMENT_SETUP_PATH, useCaseDetails, isAdmin),
    },
  ]
}

export const getArticleAllocationUseCaseNavigation = (
  intl: IntlShape,
  usecase: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
): Common.NavigationItem[] => {
  return [{
    href: generatePath(ARTICLE_ALLOCATION_RESULTS_PATH, { usecase }),
    title: intl.formatMessage({ id: 'common.layout.navigation.results' }),
    SideMenuIconComponent: WidgetIcon,
    disabled: isUseCaseMenuItemDisabled(ARTICLE_ALLOCATION_RESULTS_PATH, useCaseDetails, isAdmin),
  }, {
    href: generatePath(ARTICLE_ALLOCATION_ANALYZE_PATH, { usecase }),
    title: intl.formatMessage({ id: 'common.layout.navigation.analyze' }),
    SideMenuIconComponent: InsightsIcon,
    disabled: isUseCaseMenuItemDisabled(ARTICLE_ALLOCATION_ANALYZE_PATH, useCaseDetails, isAdmin),
  }, {
    href: generatePath(ARTICLE_ALLOCATION_EXPORT_PATH, { usecase }),
    title: intl.formatMessage({ id: 'common.layout.navigation.export' }),
    SideMenuIconComponent: OutboundEmailIcon,
    disabled: isUseCaseMenuItemDisabled(ARTICLE_ALLOCATION_EXPORT_PATH, useCaseDetails, isAdmin),
  }, {
    href: generatePath(ARTICLE_ALLOCATION_SETUP_PATH, { usecase }),
    title: intl.formatMessage({ id: 'common.layout.navigation.setup' }),
    SideMenuIconComponent: ConnectIcon,
    disabled: isUseCaseMenuItemDisabled(ARTICLE_ALLOCATION_SETUP_PATH, useCaseDetails, isAdmin),
  }]
}

export const getDemandUseCaseNavigation = (
  intl: IntlShape,
  usecase: string,
  useCaseDetails: UseCase.DetailsExtended,
  isAdmin: boolean,
): Common.NavigationItem[] => {
  const optimizeEnabled = isOptimizeEnabled(usecase)

  return [
    {
      href: generatePath(ANALYZE_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.insights' }),
      SideMenuIconComponent: InsightsChartIcon,
      disabled: isUseCaseMenuItemDisabled(ANALYZE_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(MONITOR_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.track' }),
      SideMenuIconComponent: InsightsIcon,
      disabled: isUseCaseMenuItemDisabled(MONITOR_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(EXPORT_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.export' }),
      SideMenuIconComponent: OutboundEmailIcon,
      disabled: isUseCaseMenuItemDisabled(EXPORT_PATH, useCaseDetails, isAdmin),
    },
    {
      href: generatePath(TRADE_OFFS_PATH, { usecase }),
      title: optimizeEnabled ? intl.formatMessage({ id: 'common.layout.navigation.optimize' }) : intl.formatMessage({ id: 'common.layout.navigation.trade_offs' }),
      SideMenuIconComponent: TradeOffsIcon,
      disabled: isUseCaseMenuItemDisabled(TRADE_OFFS_PATH, useCaseDetails, isAdmin),
      hidden: optimizeEnabled && !document.location.pathname.includes('/trade-offs'),
    },
    {
      href: generatePath(OPTIMIZE_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.optimize' }),
      SideMenuIconComponent: TradeOffsIcon,
      disabled: false,
      hidden: !optimizeEnabled || document.location.pathname.includes('/trade-offs'),
    },
    {
      href: generatePath(CONNECT_PATH, { usecase }),
      title: intl.formatMessage({ id: 'common.layout.navigation.connect' }),
      SideMenuIconComponent: ConnectIcon,
      disabled: isUseCaseMenuItemDisabled(CONNECT_PATH, useCaseDetails, isAdmin),
    },
  ]
}

/**
 * @function getHashByModalName Returns hash for a modal by name
 *
 * @param {String} modalName Modal name
 *
 * @return {String} Modal hash
 */
export const getHashByModalName = (modalName: Common.ModalPageName): Common.ModalPageNameContent => {
  // @ts-ignore
  const modalHash = (modalName && typeof modalName === 'string') ? MODAL_NAMES_TO_HASH_MAP[modalName] : null

  if (!modalHash) {
    return null
  }

  return modalHash
}

/**
 * @function getModalNameByHash Returns name for a modal by hash
 *
 * @param {String} modalHash Modal hash
 *
 * @return {String} Modal name
 */
export const getModalNameByHash = (modalHash: string): Common.ModalPageNameContent => {
  const modalName = modalHash ? MODAL_HASH_TO_NAME_MAP[modalHash] : null

  if (!modalName) {
    return null
  }

  return modalName
}
