import { State } from '@redux/modules/types'

import { REQUEST_SNAPSHOTS, DELETE_SNAPSHOT, REQUEST_SNAPSHOT } from './snapshots.action-types'

export const getSnapshotsList = (state: State) => {
  return state.snapshots.snapshotsList
}

export const getSnapshotItem = (state: State) => {
  return state.snapshots.snapshotsItem
}

export const isFetchingSnapshots = (state: State) => {
  return state.snapshots.fetchingKeys.includes(REQUEST_SNAPSHOTS)
}

export const isFetchingSnapshot = (state: State) => {
  return state.snapshots.fetchingKeys.includes(REQUEST_SNAPSHOT)
}

export const isDeletingSnapshot = (state: State) => {
  return state.snapshots.fetchingKeys.includes(DELETE_SNAPSHOT)
}
