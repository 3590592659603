import React from 'react'

const DatabaseIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DatabaseIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9.00003 7.07145C13.6155 7.07145 17.3572 5.63237 17.3572 3.85717C17.3572 2.08197 13.6155 0.642883 9.00003 0.642883C4.3845 0.642883 0.642883 2.08197 0.642883 3.85717C0.642883 5.63237 4.3845 7.07145 9.00003 7.07145Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.642883 3.85718V14.1429C0.642883 15.9172 4.38431 17.3572 9.00003 17.3572C13.6157 17.3572 17.3572 15.9172 17.3572 14.1429V3.85718" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.3572 9C17.3572 10.7743 13.6157 12.2143 9.00003 12.2143C4.38431 12.2143 0.642883 10.7743 0.642883 9" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default DatabaseIcon
