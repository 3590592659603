import React from 'react'

import { useLocation } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { createId } from '@utils/common.utils'
import { TRACKING_ACTIONS, TRACKING_MODULES, trackEvent } from '@utils/tracking.utils'

import {
  DEFAULT_BUTTON_HEIGHT,
  DEFAULT_PADDING,
  SIDEBAR_EXPANDED_WIDTH,
  SIDEBAR_WIDTH,
} from '@constants/ui.constants'

import LArrowIcon from '@icons/lArrow.icon'
import RArrowIcon from '@icons/rArrow.icon'
import NavigationButtonComponent from '@base/sidebar/NavigationButton'
import SideBarDividerComponent from '@base/sidebar/SideBarDivider'

export interface SideBarLayoutComponentProps {
  /**
   * if true, sidebar is expanded
   */
  expanded: boolean
  /**
   * if true, user is admin
   */
  isAdmin: boolean
  /**
   * Navigation items for admin
   */
  adminItems: Common.NavigationItem[]
  /**
   * Navigation items for non-admin
   */
  nonAdminItems: Common.NavigationItem[]
  /**
   * Function to change sidebar state
   * @param newState new sidebar state
   * @returns void
   */
  changeSidebarState: (newState: boolean) => void
}

const SideBarLayoutComponent: React.FC<SideBarLayoutComponentProps> = ({
  changeSidebarState,
  expanded,
  isAdmin,
  adminItems,
  nonAdminItems,
}) => {
  const intl = useIntl()
  const location = useLocation()
  const theme = useTheme()

  const onSidebarStateIconClick = () => {
    const newSidebarState = !expanded

    changeSidebarState(newSidebarState)

    trackEvent({
      actionName: TRACKING_ACTIONS.CLICK,
      moduleName: TRACKING_MODULES.SIDE_BAR,
      componentName: 'sidebarStateButton',
    }, {
      newSidebarState,
    })
  }

  const onNavItemClick = (href: string, title: string) => {
    trackEvent({
      actionName: TRACKING_ACTIONS.NAVIGATE,
      moduleName: TRACKING_MODULES.SIDE_BAR,
      componentName: 'navItemButton',
    }, {
      href,
      title,
      sidebarState: expanded,
    })
  }

  const renderNavigationItem = (item: Common.NavigationItem, index: number) => {
    const isActive = location.pathname.includes(item.href || '')

    return (
      <NavigationButtonComponent
        key={createId(index, item.href)}
        hidden={item.hidden}
        disabled={item.disabled}
        href={item.href}
        label={item.title!}
        IconComponent={item.SideMenuIconComponent}
        expanded={expanded}
        active={isActive}
        onClick={() => onNavItemClick(item.href!, item.title!)}
      />
    )
  }

  const width = expanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_WIDTH
  const navContainerStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    gap: '5px',
    pl: DEFAULT_PADDING.MEDIUM,
  }

  return (
    <Box
      data-testid={SideBarLayoutComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '100%',
        minWidth: width,
        width,
        backgroundColor: theme.palette.new.white,
        borderRight: `1px solid ${theme.palette.new.grey_a}`,
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
        transition: theme.transitions.create(['min-width', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.shortest,
        }),
      }}
    >
      <Box>
        <Box
          sx={navContainerStyles}
        >
          {
            nonAdminItems.map(renderNavigationItem)
          }
        </Box>

        {
          (isAdmin && adminItems.length > 0 && nonAdminItems.length > 0) ? (
            <SideBarDividerComponent
              width={`${width}px`}
            />
          ) : (
            null
          )
        }

        <Box
          sx={navContainerStyles}
        >
          {
            isAdmin && adminItems.map(renderNavigationItem)
          }
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: DEFAULT_BUTTON_HEIGHT,
          width: '100%',
          pl: DEFAULT_PADDING.MEDIUM,
        }}
      >
        <NavigationButtonComponent
          label={intl.formatMessage({ id: expanded ? 'common.layout.navigation.sidebar' : 'common.layout.navigation.sidebar_expand' })}
          IconComponent={expanded ? LArrowIcon : RArrowIcon}
          expanded={expanded}
          onClick={onSidebarStateIconClick}
        />
      </Box>
    </Box>
  )
}

export default SideBarLayoutComponent
