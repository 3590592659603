import React from 'react'
import { ControlButton, FitView } from '@xyflow/react'
import { Box, useTheme } from '@mui/material'

import {
  trackEvent,
  TRACKING_ACTIONS,
} from '@utils/tracking.utils'

import ZoomInIcon from '@icons/flow/zoomIn.icon'
import ZoomOutIcon from '@icons/flow/zoomOut.icon'
import FitInIcon from '@icons/flow/fitIn.icon'

export interface ReactFlowControlsComponentProps {
  /**
   * Zoom in handler
   */
  zoomIn?: (options?: { duration: number; }) => void
  /**
   * Zoom out handler
   */
  zoomOut?: (options?: { duration: number; }) => void
  /**
   * Fit view handler
   */
  fitView?: FitView
}

const ReactFlowControlsComponent: React.FC<ReactFlowControlsComponentProps> = ({
  zoomIn, zoomOut, fitView,
}) => {
  const theme = useTheme()
  const styles = {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.new.grey_a}`,
    backgroundColor: theme.palette.new.white,
    borderRadius: theme.spacing(0.5),
    position: 'absolute',
    zIndex: 5,
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(0.6, 0.8),
    '& .react-flow__controls-button': {
      border: 'none',
      outline: 'none',
      color: theme.palette.new.transparent,
      padding: theme.spacing(0),
      height: '16px',
      width: '16px',
      marginRight: theme.spacing(1.8),
      opacity: 0.6,
      transition: theme.transitions.create(['opacity', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.complex,
      }),
      '&:last-child': {
        marginRight: theme.spacing(0),
      },
      '&:hover': {
        color: theme.palette.new.transparent,
        backgroundColor: 'transparent',
        opacity: 0.4,
      },
      '&:focus-visible': {
        color: theme.palette.new.transparent,
        backgroundColor: 'transparent',
        opacity: 0.4,
      },
      '&:active': {
        color: theme.palette.new.transparent,
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
  }

  const doTrackEvent = (name: string) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CLICK,
    })
  }

  const handleZoomIn = () => {
    doTrackEvent('reactFlowZoomIn')

    if (zoomIn) {
      zoomIn()
    }
  }

  const handleZoomOut = () => {
    doTrackEvent('reactFlowZoomOut')

    if (zoomOut) {
      zoomOut()
    }
  }

  const handleFitView = () => {
    doTrackEvent('reactFlowFitView')

    if (fitView) {
      fitView()
    }
  }

  return (
    <Box sx={styles} data-testid={ReactFlowControlsComponent.name}>
      <ControlButton onClick={handleZoomOut}>
        <ZoomOutIcon />
      </ControlButton>
      <ControlButton onClick={handleZoomIn}>
        <ZoomInIcon />
      </ControlButton>
      <ControlButton onClick={handleFitView}>
        <FitInIcon />
      </ControlButton>
    </Box>
  )
}

export default ReactFlowControlsComponent
