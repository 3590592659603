import React from 'react'

const SnapshotIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={SnapshotIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>
      <path d="M16.0715 0.642883H1.9286C1.21852 0.642883 0.642883 1.21852 0.642883 1.9286V16.0715C0.642883 16.7815 1.21852 17.3572 1.9286 17.3572H16.0715C16.7815 17.3572 17.3572 16.7815 17.3572 16.0715V1.9286C17.3572 1.21852 16.7815 0.642883 16.0715 0.642883Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 7.71431C13.5 7.37332 13.3646 7.04629 13.1234 6.80517C12.8823 6.56406 12.5553 6.4286 12.2143 6.4286H11.5715L10.2857 4.50003H7.71431L6.4286 6.4286H5.78574C5.44475 6.4286 5.11772 6.56406 4.8766 6.80517C4.63548 7.04629 4.50003 7.37332 4.50003 7.71431V10.9286C4.50003 11.2696 4.63548 11.5966 4.8766 11.8377C5.11772 12.0789 5.44475 12.2143 5.78574 12.2143H12.2143C12.5553 12.2143 12.8823 12.0789 13.1234 11.8377C13.3646 11.5966 13.5 11.2696 13.5 10.9286V7.71431Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00003 9.64288C9.35507 9.64288 9.64288 9.35507 9.64288 9.00003C9.64288 8.64499 9.35507 8.35717 9.00003 8.35717C8.64499 8.35717 8.35717 8.64499 8.35717 9.00003C8.35717 9.35507 8.64499 9.64288 9.00003 9.64288Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default SnapshotIcon
