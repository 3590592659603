import React from 'react'
import { Box, FormControlLabel, useTheme } from '@mui/material'
import { DEFAULT_BUTTON_FONT_SIZE, DEFAULT_BUTTON_TRANSITION, DEFAULT_PADDING } from '@constants/ui.constants'

import SwitchBaseComponent, { SwitchBaseComponentProps } from '@base/forms/SwitchBase/SwitchBase.component'

export const createTestId = (name: string) => {
  return `${name || 'switch'}_field`
}

export interface ToggleButtonComponentProps extends SwitchBaseComponentProps {
  /**
   * Label of the switch
   */
  label: string | React.ReactNode,
  /**
   * If true, the switch will have a transparent background
   */
  transparent?: boolean,
}

const ToggleButtonComponent: React.FC<ToggleButtonComponentProps> = ({
  name,
  label,
  transparent,
  ...props
}) => {
  const theme = useTheme()
  const testId = createTestId(name)
  const styles = {
    gap: !label ? 0 : DEFAULT_PADDING.SMALL,
    margin: 0,
    whiteSpace: 'nowrap',

    '& .MuiFormControlLabel-label': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
    },
  }

  return (
    <Box
      data-testid={ToggleButtonComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '36px',
        backgroundColor: transparent ? theme.palette.new.transparent : theme.palette.new.business_black_5,
        borderRadius: '36px',
        px: DEFAULT_PADDING.MEDIUM,
        width: 'fit-content',
        transition: DEFAULT_BUTTON_TRANSITION,

        '&:hover': {
          backgroundColor: theme.palette.new.business_black_10,
        },
        '&:active': {
          backgroundColor: theme.palette.new.business_black_20,
        },
      }}
    >
      <FormControlLabel
        data-testid={testId}
        labelPlacement='start'
        label={label}
        sx={styles}
        control={(
          <SwitchBaseComponent
            name={name}
            {...props}
          />
        )}
      />
    </Box>
  )
}

export default ToggleButtonComponent
