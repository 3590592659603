import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, match as Match } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { injectIntl, IntlShape } from 'react-intl'
import TopBarAutocompleteComponent from '@base/topbar/TopBarAutocomplete'

import { State } from '@redux/modules/types'
import { fetchAllUseCasesAction, redirectToUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { FetchAllUseCasesActionActionPayload, RedirectToUseCaseActionPayload } from '@redux/modules/use-case/use-case.types'
import { getSortedUseCasesList, isFetchingUseCases } from '@redux/modules/use-case/use-case.selectors'
import { requestCompanyItemAction } from '@redux/modules/customer/customer.actions'
import { RequestCompanyItemPayload } from '@redux/modules/customer/customer.types'
import { DASHBOARD_PATH } from '@constants/routes.constants'
import { TRACKING_ACTIONS, TRACKING_MODULES, trackEvent } from '@utils/tracking.utils'

import {
  getSelectedCustomerId,
  getSelectedCompanyId,
  getIsAdmin,
  isFetchingCompany,
} from '@redux/modules/customer/customer.selectors'

export interface UseCaseSelectorContainerProps {
  isAdmin: boolean
  selectedCompanyId: string
  match: Match<Common.RouterMatch>
  useCases: UseCase.DetailsExtended[]
  intl: IntlShape
  isFetching: boolean

  requestCompanyItem(payload: RequestCompanyItemPayload): any
  fetchAllUseCases(payload: FetchAllUseCasesActionActionPayload): any
  redirectToUseCase(payload: RedirectToUseCaseActionPayload): any
}

export interface UseCaseSelectorContainerState {
  value: null | UseCase.DetailsExtended,
  anchorEl: null | Element,
}

class UseCaseSelectorContainer extends React.Component<UseCaseSelectorContainerProps, UseCaseSelectorContainerState> {
  static defaultProps: any

  static propTypes: any

  constructor(props: UseCaseSelectorContainerProps) {
    super(props)

    const selectedUseCase = props.useCases.find((item) => String(item.useCaseId) === String(props.match.params.usecase))

    this.state = selectedUseCase ? {
      value: selectedUseCase,
      anchorEl: null,
    } : {
      value: null,
      anchorEl: null,
    }
  }

  componentDidMount() {
    const {
      fetchAllUseCases,
      selectedCompanyId,
      requestCompanyItem,
      isAdmin,
      match: { path },
    } = this.props

    /*
    * Fetch all use cases if the user is not an admin
    * For the admin user, the use cases are fetched in the CompanySelector
    *
    * Skip fetching use cases if the user is on the dashboard page, because the use cases are already fetched in the DashboardContainer
    * */
    if (!isAdmin) {
      if (path !== DASHBOARD_PATH) {
        fetchAllUseCases({ companyId: selectedCompanyId })
      }

      requestCompanyItem({ companyId: selectedCompanyId })
    }
  }

  componentDidUpdate(prevProps: UseCaseSelectorContainerProps) {
    const {
      useCases,
      match: { params: { usecase } },
    } = this.props

    const {
      useCases: prevUseCases,
      match: { params: { usecase: prevUsecase } },
    } = prevProps

    if (
      useCases.length &&
      ((useCases.length !== prevUseCases.length) ||
      ((usecase !== prevUsecase)))
    ) {
      const selectedUseCase = useCases.find((item) => String(item.useCaseId) === String(usecase))

      if (selectedUseCase) {
        this.setState({
          value: selectedUseCase,
        })
      } else {
        this.setState({
          value: null,
        })
      }
    }
  }

  getSelectedUseCaseName = () => {
    const { intl, isFetching } = this.props
    const { value } = this.state

    if (value && value.name) {
      return value.name
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'common.layout.header.loading' })
    }

    return intl.formatMessage({ id: 'common.layout.header.list.title' })
  }

  handleChange = (e: React.SyntheticEvent, value: UseCase.DetailsExtended) => {
    const { redirectToUseCase } = this.props

    if (value) {
      trackEvent({
        moduleName: TRACKING_MODULES.TOP_BAR,
        componentName: 'useCaseSelectorListItem',
        actionName: TRACKING_ACTIONS.CLICK,
      }, {
        useCaseId: value.useCaseId,
      })

      redirectToUseCase({
        useCaseId: value.useCaseId,
      })

      this.setState({
        value,
      })
    }
  }

  render() {
    const {
      useCases,
      intl,
      match: { params: { usecase } },
    } = this.props

    const { value } = this.state

    const selectedUseCaseName = this.getSelectedUseCaseName()

    if (!usecase) {
      return null
    }

    return (
      <TopBarAutocompleteComponent
        name='useCaseSelectorButton'
        dataTestId={UseCaseSelectorContainer.name}
        buttonAriaLabel={intl.formatMessage({ id: 'common.layout.header.useCaseSelectorAriaLabel' }, { name: selectedUseCaseName })}
        buttonLabel={selectedUseCaseName}
        inputPlaceholder={intl.formatMessage({ id: 'common.layout.header.search.placeholder_use_cases' })}
        noOptionsText={intl.formatMessage({ id: 'common.layout.header.search.not_found_use_cases' })}
        isOptionEqualToValue={(fOption, fValue) => {
          return Boolean((fOption && fValue && (fOption.useCaseId === fValue.useCaseId)))
        }}
        getOptionLabel={(option) => {
          return option?.name || ''
        }}
        onChange={this.handleChange}
        options={useCases}
        value={value}
      />
    )
  }
}

UseCaseSelectorContainer.propTypes = {
  useCases: PropTypes.array,
  fetchAllUseCases: PropTypes.func,
  intl: PropTypes.object,
  isFetching: PropTypes.bool,
  match: PropTypes.object,
  isAdmin: PropTypes.bool,
  selectedCompanyId: PropTypes.string,
  requestCompanyItem: PropTypes.func,
}

UseCaseSelectorContainer.defaultProps = {
  useCases: [],
}

export const mapStateToProps = (state: State) => {
  return {
    useCases: getSortedUseCasesList(state),
    customerId: getSelectedCustomerId(state),
    isFetching: isFetchingUseCases(state) || isFetchingCompany(state),
    isAdmin: getIsAdmin(state),
    selectedCompanyId: getSelectedCompanyId(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const fetchAllUseCases = bindActionCreators(fetchAllUseCasesAction, dispatch)
  const requestCompanyItem = bindActionCreators(requestCompanyItemAction, dispatch)
  const redirectToUseCase = bindActionCreators(redirectToUseCaseAction, dispatch)

  return {
    redirectToUseCase,
    fetchAllUseCases,
    requestCompanyItem,
  }
}

// @ts-ignore-next-line
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(UseCaseSelectorContainer)))
