import { handleActions } from 'redux-actions'
import { PipelinesState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_PIPELINES_LIST,
  RECEIVE_PIPELINE_OVERVIEW,
  RECEIVE_COMPOSITE_PIPELINE_STATUS,
  START_FETCHING_PIPELINES,
  STOP_FETCHING_PIPELINES,
} from './pipelines.action-types'

import {
  receivePipelinesList,
  receivePipelineOverview,
  receiveCompositePipelineStatus,
  initialState,
} from './pipelines.reducers'

export default handleActions<PipelinesState, any>({
  [RECEIVE_PIPELINES_LIST]: receivePipelinesList,
  [RECEIVE_PIPELINE_OVERVIEW]: receivePipelineOverview,
  [RECEIVE_COMPOSITE_PIPELINE_STATUS]: receiveCompositePipelineStatus,
  [START_FETCHING_PIPELINES]: startFetchingAction<PipelinesState>,
  [STOP_FETCHING_PIPELINES]: stopFetchingAction<PipelinesState>,
}, initialState)
