import { DEFAULT_BOX_SHADOW } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

/**
 * @function getChartTooltipStyles Returns chart tooltips styles
 *
 * @param {Object} theme MUI theme
 *
 * @return {Object} Theme config
 */
export const getChartTooltipStyles = (theme: Theme) => {
  return {
    customTooltipDataSectionItem: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      '&:first-of-type': {
        marginTop: theme.spacing(0),
      },
    },
    customTooltipDataSectionItemKey: {
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.6)',
      paddingRight: theme.spacing(4),
    },
    customTooltipDataSectionItemKeyKpi: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      fontWeight: 500,
      color: theme.palette.new.black,
      paddingRight: theme.spacing(4),
    },
    customTooltipDataSectionItemValue: {
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    customTooltipDataSectionItemValueKpi: {
      fontSize: '16px',
      fontWeight: 500,
      color: theme.palette.new.black,
    },
    customTooltipDataSectionItemValueNull: {
      color: theme.palette.new.rebellious_red,
    },
  }
}

/**
 * @function getBaseDialogStyles Returns base for dialog styles
 *
 * @param {Object} theme MUI theme
 *
 * @return {Object} Theme config
 */
export const getBaseDialogStyles = (theme: Theme, minWidth = 70) => {
  return {
    root: {
      minWidth: theme.spacing(minWidth),
      borderRadius: theme.spacing(1),
      boxShadow: DEFAULT_BOX_SHADOW,
      zIndex: theme.zIndex.drawer + 101,
    },
    dialogContent: {
      borderTop: `1px solid ${theme.palette.new.grey_a}`,
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      padding: `${theme.spacing(3)} !important`,
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
      height: theme.spacing(6),
    },
    dialogTitleText: {
      ...getTextBackgroundFillStyles('linear-gradient(219.13deg, #8F6BDE 0.5%, #F06DAC 97.79%)'),
      fontSize: '22px',
      fontWeight: 500,
    },
    dialogContentText: {
      fontSize: '18px',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 3),
      gap: theme.spacing(2),
      '& > div': {
        marginLeft: '0px !important',
      },
    },
    backdrop: {
      opacity: '0.03 !important',
      backgroundColor: theme.palette.new.black,
    },
  }
}
