import React from 'react'

const SaveIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={SaveIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M17.3572 16.0714C17.3572 16.4124 17.2217 16.7394 16.9806 16.9806C16.7395 17.2217 16.4124 17.3571 16.0715 17.3571H1.9286C1.5876 17.3571 1.26058 17.2217 1.01946 16.9806C0.778342 16.7394 0.642883 16.4124 0.642883 16.0714V4.5L4.50003 0.642853H16.0715C16.4124 0.642853 16.7395 0.778312 16.9806 1.01943C17.2217 1.26055 17.3572 1.58757 17.3572 1.92857V16.0714Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5 10.9286H4.5V17.3571H13.5V10.9286Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5001 0.642853H5.78577V5.78571H13.5001V0.642853Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default SaveIcon
