import React from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography, useTheme } from '@mui/material'
import { PROCESSING_STATES, VALIDATION_ERRORS } from '@constants/files.constants'

import DefaultColumnsErrorComponent from './DefaultColumnsError'
import CustomErrorComponent from './CustomError'
import PlainTextErrorComponent from './PlainTextError'

export interface TrainingDataErrorRowComponentProps {
  /**
   * Training file record
   */
  record?: TrainingFiles.TrainingFileItem,
}

const TrainingDataErrorRowComponent: React.FC<TrainingDataErrorRowComponentProps> = ({
  record,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  if (!record || (record.processingStatus !== PROCESSING_STATES.FAILED)) {
    return null
  }

  const errorsList = (record.csvValidationResult || []) as TrainingFiles.CsvValidationResultItem[]
  const totalCount = errorsList.length

  if (totalCount === 0) {
    return null
  }

  return (
    <Box
      key={record.trainingDataFileId}
      data-testid={`${TrainingDataErrorRowComponent.name}-${record.trainingDataFileId}`}
      sx={{
        backgroundColor: '#FB6C4A0D',
        padding: theme.spacing(2),
      }}
    >
      <Typography
        variant='subtitle1'
        mb={2}
      >
        {intl.formatMessage({ id: 'connect.data_input.modal.dropzone.status.error.headline' }, { count: totalCount })}
      </Typography>

      <Box component='ul'>
        {
          errorsList.map((error) => {
            const type = error.validationType

            switch (type) {
              case VALIDATION_ERRORS.NOT_NULL:
              case VALIDATION_ERRORS.CATEGORIES:
              case VALIDATION_ERRORS.UNIQUE:
              case VALIDATION_ERRORS.VALUE_RANGE:
              case VALIDATION_ERRORS.NUMBER_FORMAT:
              case VALIDATION_ERRORS.NUMBER:
              case VALIDATION_ERRORS.INTEGER:
              case VALIDATION_ERRORS.PLAIN_TEXT:
              case VALIDATION_ERRORS.DATE_FORMAT:
              case VALIDATION_ERRORS.ADDITIONAL_COLUMNS:
              case VALIDATION_ERRORS.DUPLICATE_COLUMNS:
              case VALIDATION_ERRORS.MISSING_COLUMNS: {
                return (
                  <DefaultColumnsErrorComponent
                    key={type}
                    type={type}
                    error={error}
                  />
                )
              }

              case VALIDATION_ERRORS.INVALID_COLUMNS:
              case VALIDATION_ERRORS.INVALID_ROWS: {
                return (
                  <CustomErrorComponent
                    key={type}
                    type={type}
                    error={error}
                  />
                )
              }

              case VALIDATION_ERRORS.EMPTY_COLUMN_NAMES:
              case VALIDATION_ERRORS.PARSABLE: {
                return (
                  <PlainTextErrorComponent
                    key={type}
                    type={type}
                    error={error}
                  />
                )
              }

              default: {
                return null
              }
            }
          })
        }
      </Box>
    </Box>
  )
}

export default TrainingDataErrorRowComponent
