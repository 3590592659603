import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, useTheme, Typography } from '@mui/material'

import {
  SOURCE_TYPES, INPUT_TYPES,
  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES_TO_WHITE_ICONS_MAP,
  NO_UNIT_LABEL_PLACEHOLDER,
  INPUT_PARAMETER_BLOCK_HEIGHT,
  INPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_HEIGHT,
  FLOW_ELEMENTS_TRANSITIONS,
  FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
} from '@constants/flow.constants'

import { PARETOS_GRADIENT } from '@constants/ui.constants'

import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'

export enum PARAMETER_BLOCK_TYPES {
  ACTIVE_INPUT = 'ACTIVE_INPUT',
  PASSIVE_INPUT = 'PASSIVE_INPUT',
  GENERIC_INPUT = 'GENERIC_INPUT',
  TARGET = 'TARGET',
  GROUPING_ATTRIBUTE = 'GROUPING_ATTRIBUTE',
}

export const getParameterBlockByInputType = (inputType: INPUT_TYPES): PARAMETER_BLOCK_TYPES => {
  if (inputType === INPUT_TYPES.PASSIVE) {
    return PARAMETER_BLOCK_TYPES.PASSIVE_INPUT
  }

  if (inputType === INPUT_TYPES.ACTIVE) {
    return PARAMETER_BLOCK_TYPES.ACTIVE_INPUT
  }

  if (inputType === INPUT_TYPES.GENERIC) {
    return PARAMETER_BLOCK_TYPES.GENERIC_INPUT
  }

  if (inputType === INPUT_TYPES.GROUPING) {
    return PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE
  }

  return PARAMETER_BLOCK_TYPES.TARGET
}

export interface ReactFlowParameterBlockComponentProps {
  /**
   * Element's type
   */
  type?: PARAMETER_BLOCK_TYPES,
  /**
   * Element's onClick handler
   */
  onClick?: {
    (e?: React.SyntheticEvent): any,
  },
  /**
   * Title of the element
   */
  name?: string,
  /**
   * Unit label of the element
   */
  unitLabel?: string,
  /**
   * If true, allows to use the element outside of the react-flow
   */
  nonFlowUsage?: boolean,
  /**
   * Element's source type
   */
  sourceType?: SOURCE_TYPES,
  /**
   * List of filter values. Used for grouping attributes
   */
  filterValues?: any[],
  /**
   * Data Test ID for e2e tests
   */
  dataTestId?: string,
}

const ReactFlowParameterBlockComponent: React.FC<ReactFlowParameterBlockComponentProps> = ({
  type = '' as PARAMETER_BLOCK_TYPES,
  onClick,
  name,
  unitLabel = '',
  nonFlowUsage = false,
  sourceType = SOURCE_TYPES.CUSTOM,
  filterValues = [],
  dataTestId = '',
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const isTarget = type === PARAMETER_BLOCK_TYPES.TARGET
  const isActiveInput = type === PARAMETER_BLOCK_TYPES.ACTIVE_INPUT
  const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[sourceType]
  const WhiteIconComponent = SOURCE_TYPES_TO_WHITE_ICONS_MAP[sourceType]
  const isInactive = sourceType === SOURCE_TYPES.NO_CONNECTION
  const isGroupingAttribute = type === PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE
  const filterValuesCount = ((filterValues || []).length)
  const shouldDisplayItemsCount = isGroupingAttribute && !isInactive

  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'parameterBlock',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      name,
      type,
      sourceType,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const rootStyles = useMemo(() => {
    const root = {}

    if ([PARAMETER_BLOCK_TYPES.PASSIVE_INPUT, PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE, PARAMETER_BLOCK_TYPES.GENERIC_INPUT].includes(type)) {
      Object.assign(root, {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: INPUT_PARAMETER_BLOCK_WIDTHS,
        height: INPUT_PARAMETER_BLOCK_HEIGHT,
        backgroundColor: theme.palette.new.white,
        border: `1px solid ${theme.palette.new.grey_a}`,
        padding: theme.spacing(1.5, 2, 1.5, 1.5),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        outline: 'none',
        ...FLOW_ELEMENTS_TRANSITIONS,

        '&:hover': {
          border: `1px solid ${theme.palette.new.grey_c}`,
          backgroundColor: theme.palette.new.grey,
        },
        '&:focus-visible': {
          border: `1px solid ${theme.palette.new.grey_c}`,
          backgroundColor: theme.palette.new.grey,
        },
      })
    }

    if (isActiveInput) {
      Object.assign(root, {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: INPUT_PARAMETER_BLOCK_WIDTHS,
        height: INPUT_PARAMETER_BLOCK_HEIGHT,
        background: theme.palette.new.white,
        border: `1px solid ${theme.palette.new.versatile_violet}`,
        padding: theme.spacing(1.5, 2, 1.5, 1.5),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        ...FLOW_ELEMENTS_TRANSITIONS,
        outline: 'none',

        '&:hover': {
          border: `1px solid ${theme.palette.new.versatile_violet}`,
          background: 'rgba(95, 38, 224, 0.2)',
        },
        '&:focus-visible': {
          border: `1px solid ${theme.palette.new.versatile_violet}`,
          background: 'rgba(95, 38, 224, 0.2)',
        },
      })
    }

    if (isTarget) {
      Object.assign(root, {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: OUTPUT_PARAMETER_BLOCK_WIDTHS,
        height: OUTPUT_PARAMETER_BLOCK_HEIGHT,
        padding: theme.spacing(1.5, 2, 1.5, 1.5),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        background: 'rgba(255, 255, 255, 0.4)',
        outline: 'none',

        ...FLOW_ELEMENTS_TRANSITIONS,

        '&:hover': {
          background: 'rgba(255, 255, 255, 0.2)',
        },
        '&:focus-visible': {
          background: 'rgba(255, 255, 255, 0.2)',
        },
      })
    }

    if (nonFlowUsage && isTarget) {
      Object.assign(root, {
        '& .outputParameterItem': {
          background: PARETOS_GRADIENT,
        },
      })
    }

    if (onClick) {
      Object.assign(root, {
        cursor: 'pointer',
      })
    }

    if (isInactive && !isTarget) {
      Object.assign(root, {
        background: 'rgba(0, 0, 0, 0.03)',
        border: '1px solid rgba(0, 0, 0, 0.2)',

        '& .name': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          opacity: 0.4,
        },
        '& .footerIcon': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          opacity: 0.4,
        },
        '& .footerUnitsInactive': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          color: 'rgba(0, 0, 0, 0.4) !important',
        },
        '&:hover': {
          '& .name': {
            opacity: 1,
          },
          '& .footerIcon': {
            opacity: 1,
          },
          '& .footerUnitsInactive': {
            color: theme.palette.new.grey_c,
          },
        },
      })
    }

    if (isInactive && isTarget) {
      Object.assign(root, {
        background: 'rgba(255, 255, 255, 0.2)',

        '& .name': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          opacity: 0.4,
        },
        '& .footerIcon': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          opacity: 0.4,
        },
        '& .footerUnitsInactive': {
          ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
          color: 'rgba(255, 255, 255, 0.4) !important',
        },
        '&:hover': {
          '& .name': {
            opacity: 1,
          },
          '& .footerIcon': {
            opacity: 1,
          },
          '& .footerUnitsInactive': {
            color: `${theme.palette.new.white} !important`,
          },
        },
      })
    }

    if (isInactive && isActiveInput) {
      Object.assign(root, {
        '&:hover': {
          '& .footerUnitsInactive': {
            color: `${theme.palette.new.violet_a} !important`,
          },
        },
      })
    }

    return root
  }, [
    theme,
    type,
    isActiveInput,
    isTarget,
    nonFlowUsage,
    onClick,
    isInactive,
  ])

  const nameStyles = useMemo(() => {
    const root = {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
    }

    if ([
      PARAMETER_BLOCK_TYPES.ACTIVE_INPUT,
      PARAMETER_BLOCK_TYPES.PASSIVE_INPUT,
      PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE,
      PARAMETER_BLOCK_TYPES.GENERIC_INPUT,
    ].includes(type)) {
      Object.assign(root, {
        color: theme.palette.new.black,
        width: INPUT_PARAMETER_BLOCK_WIDTHS - 40,
      })
    }

    if (isTarget) {
      Object.assign(root, {
        color: theme.palette.new.white,
        width: OUTPUT_PARAMETER_BLOCK_WIDTHS - 40,
      })
    }

    return root
  }, [
    theme,
    type,
    isTarget,
  ])

  return (
    <Box
      data-testid={dataTestId || ReactFlowParameterBlockComponent.name}
      sx={rootStyles}
      onClick={handleOnClick}
      role='button'
      tabIndex={0}
    >
      <Typography
        variant='body1'
        className='name'
        sx={nameStyles}
        title={name}
      >
        {name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: theme.spacing(2),

          ...((isActiveInput || isTarget) ? {
            '& .footerUnits': {
              color: isTarget ? theme.palette.new.white : theme.palette.new.violet_a,
            },
          } : {}),
        }}
      >
        <Typography
          variant='overline'
          className={`footerUnits ${isInactive ? 'footerUnitsInactive' : ''}`}
          noWrap={true}
          sx={{
            display: 'block',
            alignItems: 'center',
            letterSpacing: '-0.2px',
            color: isInactive ? 'rgba(0, 0, 0, 0.4)' : theme.palette.new.grey_c,
            width: '130px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {isInactive ? intl.formatMessage({ id: 'connect.block.input.inactive' }) : (unitLabel || NO_UNIT_LABEL_PLACEHOLDER)}
        </Typography>
        <Box
          className='footerIcon'
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {
            shouldDisplayItemsCount ? (
              <Typography
                variant='overline'
                sx={{
                  display: 'block',
                  alignItems: 'flex-end',
                  fontWeight: 500,
                  letterSpacing: '-0.2px',
                  color: filterValuesCount === 0 ? theme.palette.new.grey_c : theme.palette.new.versatile_violet,
                  maxWidth: theme.spacing(6),
                  whiteSpace: 'nowrap',
                  marginRight: theme.spacing(1),
                }}
                noWrap={true}
              >
                {
                  filterValuesCount ?
                    intl.formatMessage({ id: 'connect.block.grouping.items' }, { filterValuesCount }) :
                    intl.formatMessage({ id: 'connect.block.grouping.all_items' })
                }
              </Typography>
            ) : (
              null
            )
          }
          {isTarget ? <WhiteIconComponent /> : <IconComponent /> }
        </Box>
      </Box>
    </Box>
  )
}

export default ReactFlowParameterBlockComponent
