import React from 'react'

const CompaniesIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={CompaniesIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillOpacity="0" d="M16.1923 4.5H1.80769C1.08547 4.5 0.5 5.08203 0.5 5.8V16.2C0.5 16.918 1.08547 17.5 1.80769 17.5H16.1923C16.9145 17.5 17.5 16.918 17.5 16.2V5.8C17.5 5.08203 16.9145 4.5 16.1923 4.5Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path fillOpacity="0" d="M6.66667 0.5H11.3333C11.6428 0.5 11.9395 0.640476 12.1583 0.890524C12.3771 1.14057 12.5 1.47971 12.5 1.83333V4.5H5.5V1.83333C5.5 1.47971 5.62292 1.14057 5.84171 0.890524C6.0605 0.640476 6.35725 0.5 6.66667 0.5Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default CompaniesIcon
