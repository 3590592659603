import React from 'react'

const RArrowIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={RArrowIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillOpacity='0' d="M6 2L12.6231 8.62308C12.6746 8.67142 12.7156 8.72981 12.7437 8.79464C12.7718 8.85947 12.7863 8.92936 12.7863 9C12.7863 9.07064 12.7718 9.14054 12.7437 9.20536C12.7156 9.27019 12.6746 9.32858 12.6231 9.37692L6 16" stroke={detailsFill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default RArrowIcon
