import { CUSTOMER } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/api.utils'
import apiProvider from '@redux/api-provider'

import {
  CreateCompanyActionPayload,
  DeleteCompanyActionPayload,
  UpdateCompanyActionPayload,
  AddCompanyUsersPayload,
  EditCompanyUsersPayload,
  DeleteCompanyUsersPayload,
  FetchCompanyUsersPayload,
  CloneCompanyActionPayload,
  ReAssignCompanyUsersPayload,
  ChangeUserLanguagePayload,
} from './customer.types'

export const fetchCompanies = () => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/list').then(({ data }) => {
    checkApiForError(data)

    return data.data.companies as Customer.CompanyItem[]
  })
}

export interface GetCompanyPayload {
  companyId: string,
}

export const getCompany = ({ companyId } : GetCompanyPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/get', { companyId }).then(({ data }) => {
    checkApiForError(data)

    return data.data.company as Customer.CompanyItem
  })
}

export const addCompany = (payload: CreateCompanyActionPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/register', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const editCompany = (payload: UpdateCompanyActionPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/edit', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const cloneCompany = (payload: CloneCompanyActionPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/clone', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const deleteCompany = (payload: DeleteCompanyActionPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v2/company/delete', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const fetchCompanyUsers = (payload: FetchCompanyUsersPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/listCustomers', payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.customers as Customer.UserItem[]
  })
}

export const getCompanyUser = (payload : { customerId: string }) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/getCustomer', payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.customer as Customer.UserItem
  })
}

export const addCompanyUser = (payload: AddCompanyUsersPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/addCustomer', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const editCompanyUser = (payload: EditCompanyUsersPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/editCustomer', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const deleteCompanyUser = (payload: DeleteCompanyUsersPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/removeCustomer', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const reassignCompanyUser = (payload: ReAssignCompanyUsersPayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/company/reassignCustomer', payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const changeUserLanguage = (payload: ChangeUserLanguagePayload) => {
  return apiProvider.getApi(CUSTOMER).post('v1/customer/language/switch', payload).then(({ data }) => {
    checkApiForError(data)
  })
}
