import { handleActions } from 'redux-actions'
import { ParametersTemplatesState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_PARAMETERS_TEMPLATES,
  RECEIVE_PARAMETER_TEMPLATE,
  START_FETCHING_PARAMETERS_TEMPLATES,
  STOP_FETCHING_PARAMETERS_TEMPLATES,
} from './parameters-templates.action-types'

import {
  receiveList,
  receiveOne,
  initialState,
} from './parameters-templates.reducers'

export default handleActions<ParametersTemplatesState, any>({
  [RECEIVE_PARAMETERS_TEMPLATES]: receiveList,
  [RECEIVE_PARAMETER_TEMPLATE]: receiveOne,
  [START_FETCHING_PARAMETERS_TEMPLATES]: startFetchingAction<ParametersTemplatesState>,
  [STOP_FETCHING_PARAMETERS_TEMPLATES]: stopFetchingAction<ParametersTemplatesState>,
}, initialState)
