import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

interface ValidationScheme {
  name: string,
  dataColumnName: string,
  sourceType: string,
  unitLabel?: string,
}

const managePredefinedParametersModalValidations = (intl: IntlShape) => {
  const scheme: Yup.SchemaOf<ValidationScheme> = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'use_cases.form.parameter.name.required' })),
    dataColumnName: Yup.string().required(intl.formatMessage({ id: 'use_cases.form.parameter.dataColumnName.required' })),
    sourceType: Yup.string().required(intl.formatMessage({ id: 'use_cases.form.parameter.sourceType.required' })),
    unitLabel: Yup.string(),
    possiblePresetTypes: Yup.array().of(Yup.string().required()).min(1).required(),
  })

  return scheme
}

export default managePredefinedParametersModalValidations
