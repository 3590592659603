import React from 'react'
import { useIntl } from 'react-intl'

import {
  styled, InputBase,
  InputBaseProps,
  FormControl,
  InputLabel,
  useTheme,
  Box,
} from '@mui/material'

import classNames from 'classnames'
import SearchIcon from '@icons/search.icon'

const SearchFormControl = styled(FormControl)(({ theme }) => ({
  '&:not(.hasClearIcon)': {
    '& .MuiInputBase-input': {
      '&::-webkit-search-cancel-button': {
        display: 'none',
      },
    },
  },
  '&:hover:not(.disabled):not(.hasValue)': {
    '& .MuiInputLabel-root': {
      color: theme.palette.new.business_black,
    },
    '& .MuiInputBase-input': {
      borderColor: theme.palette.new.business_black,
    },
  },
  '&.disabled': {
    '& .MuiInputLabel-root': {
      color: theme.palette.new.business_black_20,
    },
  },
  '&.hasValue': {
    '& .MuiInputLabel-root': {
      color: theme.palette.new.versatile_violet,
    },
    '& .MuiInputBase-input': {
      borderColor: theme.palette.new.business_black_20,
      backgroundColor: theme.palette.new.versatile_violet_10,

      '&:hover': {
        borderColor: theme.palette.new.versatile_violet,
      },
      '&:focus': {
        borderColor: theme.palette.new.business_black_20,
        backgroundColor: theme.palette.new.versatile_violet_20,
      },
      '&:hover:focus': {
        borderColor: theme.palette.new.versatile_violet,
      },
    },
  },
}))

const SearchInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.new.business_black_60,
  fontSize: '0.75rem',
  lineHeight: '125%',
  letterSpacing: '0.24px',
  fontWeight: 400,
  transform: 'none',
  marginLeft: '10px',
}))

const SearchInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '19px',
  },
  '& .MuiInputBase-input': {
    height: '18px',
    borderRadius: '3px',
    position: 'relative',
    backgroundColor: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.business_black_20,
    color: theme.palette.new.versatile_violet,
    fontSize: '16px',
    padding: '8px 40px 8px 40px',
    caretColor: theme.palette.new.carret,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      borderColor: theme.palette.new.business_black,
    },
    '&:focus': {
      borderColor: theme.palette.new.business_black_20,
      backgroundColor: theme.palette.new.smokey_silver,
      '&::placeholder': {
        opacity: 0,
      },
    },
    '&:hover:focus': {
      borderColor: theme.palette.new.business_black,
    },
    '&::placeholder': {
      opacity: 0.2,
      color: theme.palette.new.business_black,
    },
    '&::-webkit-search-cancel-button': {
      height: '18px',
      width: '18px',
      borderRadius: 0,
      background: 'url("/close_active.svg") no-repeat 50% 50%',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      opacity: 1,
      display: 'block',
      position: 'absolute',
      right: '12px',
      mr: '0px',
    },
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.new.smokey_silver,
    color: theme.palette.new.business_black_20,
    borderColor: theme.palette.new.smokey_silver,

    '&:hover': {
      borderColor: theme.palette.new.smokey_silver,
    },
  },
}))

export interface SearchFieldComponentProps extends InputBaseProps {
  /**
   * The input id. Same as name.
   */
  id?: string,
  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name?: string,
  /**
   * The label content.
   */
  label?: string | null
  /**
   * The input value.
   */
  value?: string
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * If provided, the input will be full width.
   * @default true
   */
  fullWidth?: boolean
  /**
   * If true, the clear icon will be shown.
   * @default true
   */
  showClearIcon?: boolean
  /**
   * If provided, the input will be disabled.
   */
  disabled?: boolean
}

const SearchFieldComponent: React.FC<SearchFieldComponentProps> = ({
  touched,
  disabled,
  hidden,
  type,
  placeholder,
  name,
  id,
  value,
  label,
  showClearIcon = true,
  fullWidth = true,
  ...rest
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const identifier = id || name || 'searchField'
  const finalLabel = label === null ? null : (label || intl.formatMessage({ id: 'common.search' }))
  const hasSearchValue = Boolean(value)
  const startAdornment = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '12px',
        zIndex: 1,
      }}
    >
      <SearchIcon
        detailsFill={hasSearchValue ? theme.palette.new.versatile_violet : theme.palette.new.black}
        opacity={disabled ? '0.2' : '1'}
      />
    </Box>
  )

  return (
    <SearchFormControl
      variant='standard'
      fullWidth={fullWidth}
      className={classNames({
        hidden,
        disabled,
        hasValue: Boolean(value),
        hasClearIcon: showClearIcon,
      })}
    >
      {
        finalLabel ? (
          <SearchInputLabel shrink={true} htmlFor={identifier}>
            {finalLabel}
          </SearchInputLabel>
        ) : (
          null
        )
      }

      <SearchInput
        data-testid={SearchFieldComponent.name}
        type='search'
        size='small'
        name={name}
        id={identifier}
        fullWidth={fullWidth}
        disabled={disabled}
        autoComplete='new-password'
        placeholder={placeholder || intl.formatMessage({ id: 'common.search.placeholder' })}
        value={value}
        startAdornment={startAdornment}
        inputProps={{
          ...rest.inputProps,
          'aria-label': finalLabel ? intl.formatMessage({ id: 'common.search' }) : undefined,
        }}
        {...rest}
      />
    </SearchFormControl>
  )
}

export default SearchFieldComponent
