import React from 'react'
import { SwitchProps, Switch, styled } from '@mui/material'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { useRouteMatch } from 'react-router-dom'

const StyledSwitch = styled(Switch)(({ theme }) => {
  return {
    width: theme.spacing(3),
    padding: '7px 0px',

    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-thumb': {
      width: '18px',
      height: '18px',
      boxShadow: 'none',
      border: '1px solid #CCCCCC',
      transform: 'translate(0px, 3px)',
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translate(12px, 0px)',
    },
    '&.MuiSwitch-sizeSmall .Mui-checked .MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.new.violet}`,
    },
    '&.MuiSwitch-sizeSmall .Mui-checked.Mui-disabled .MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.new.business_black_40}`,
      background: theme.palette.new.business_black_40,
    },
    '&.MuiSwitch-sizeSmall :not(.Mui-checked).Mui-disabled .MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.new.business_black_40}`,
      background: theme.palette.new.smokey_silver,
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-switchBase': {
      padding: 0,
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      opacity: '1 !important',
    },
    '&.MuiSwitch-sizeSmall .Mui-disabled + .MuiSwitch-track': {
      border: `1px solid ${theme.palette.new.business_black_40}`,
    },
    '& .MuiSwitch-switchBase': {
      color: theme.palette.new.white,

      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        color: theme.palette.new.business_black_20,
        '&.Mui-checked + .MuiSwitch-track': {
          opacity: '1 !important',
        },
        '&.Mui-checked': {
          color: theme.palette.new.business_black_20,
        },
      },
      '&.Mui-checked': {
        color: theme.palette.new.violet,

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#F7F7F7',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        opacity: 1,
      },
    },
    '& .MuiSwitch-switchPrimary': {
      '&.MuiSwitch-thumb': {
        border: `1px solid ${theme.palette.new.violet}`,
      },
      '&.Mui-checked': {
        color: theme.palette.new.violet,
      },
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.new.smokey_silver,
        border: `1px solid ${theme.palette.new.business_black_20}`,
      },
      '&:.Mui-checked.Mui-disabled + .MuiSwitch-track': {
        border: `1px solid ${theme.palette.new.business_black_40}`,
      },
      '&:not(.Mui-checked).Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        border: `1px solid ${theme.palette.new.business_black_40}`,
        background: theme.palette.new.smokey_silver,
      },
    },
  }
})

export interface SwitchBaseComponentProps extends SwitchProps {
  /**
   * Name of the radio
   */
  name: string
  /**
   * if true, the radio will be checked
   */
  checked?: boolean
  /**
   * if true, the radio will be disabled
   */
  disabled?: boolean
  /**
   * Ref to be forwarded to the Checkbox component
   */
  forwardedRef?: any
  /**
   * Callback fired when the state is changed
   */
  onChange?: (event: React.ChangeEvent<any>, checked: boolean) => void
}

export const SwitchBaseComponent: React.FC<SwitchBaseComponentProps> = ({
  checked,
  forwardedRef,
  name,
  disabled,
  onChange,
  ...props
}) => {
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onChangeHandler = (event: React.ChangeEvent<any>, checkedValue: boolean) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      checked,
      router: params,
    })

    if (onChange) {
      onChange(event, checkedValue)
    }
  }

  return (
    <StyledSwitch
      data-testid={name || SwitchBaseComponent.name}
      ref={forwardedRef}
      checked={checked}
      disableRipple={true}
      name={name}
      disabled={disabled}
      onChange={onChangeHandler}
      size='small'
      {...props}
    />
  )
}

export default React.forwardRef((props: SwitchBaseComponentProps, ref) => <SwitchBaseComponent {...props} forwardedRef={ref} />)
