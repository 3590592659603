import React from 'react'
import {
  Box, SxProps,
  Theme, TooltipProps,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import InfoButtonIcon from '@icons/infoButton.icon'

export interface InfoButtonComponentProps {
  /**
   * Button name, used as a id for aria-labels and tests
   */
  name: string,
  /**
   * Text to be displayed in the tooltip
   */
  tooltip?: string | React.ReactNode,
  /**
   * Tooltip placement
   */
  placement?: TooltipProps['placement'],
  /**
   * Custom styles for the button
   */
  sx?: SxProps<Theme>,
  /**
   * Custom styles for the tooltip content
   */
  contentSx?: SxProps<Theme>,
  /**
   * Icon component to be displayed
   */
  IconComponent?: React.FC<Common.IconProps>,
  /**
   * Icon component props
   */
  IconComponentProps?: Common.IconProps,
}

const InfoButtonComponent: React.FC<InfoButtonComponentProps> = ({
  name,
  tooltip,
  placement = 'top',
  IconComponent = InfoButtonIcon,
  IconComponentProps = {},
  sx,
  contentSx,
}) => {
  return (
    <TooltipComponent
      title={tooltip}
      describeChild={true}
      placement={placement}
      contentSx={contentSx}
    >
      <Box
        tabIndex={0}
        data-testid={name}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: 'fit-content',
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
          '&:focus-visible': {
            opacity: 1,
            outline: 'none',
          },
          '&:active': {
            opacity: 0.6,
          },
          ...sx,
        }}
      >
        <IconComponent
          {...IconComponentProps}
        />
      </Box>
    </TooltipComponent>
  )
}

export default InfoButtonComponent
