export const projectDetailsMapper = (projectDetails: Socrates.ProjectItem): Socrates.ProjectMap => {
  const {
    targetDefinition = {} as Socrates.TargetDefinition,
    designDefinition = {} as Socrates.DesignDefinition,
    updatedAt,
    finishedAt,
    createdAt,
  } = projectDetails || {}

  const metaGoals = {}
  const metaKpis = Object.keys(targetDefinition)
  const metaDesign = Object.keys(designDefinition)

  const kpis = metaKpis.map((kpiItem) => {
    Object.assign(metaGoals, {
      [targetDefinition[kpiItem].name]: targetDefinition[kpiItem].goal,
    })

    return targetDefinition[kpiItem].name
  })

  const design = metaDesign.map((designItemName) => {
    const designItem = designDefinition[designItemName] || {}
    return {
      constraints: [{
        type: 'range',
        continuity: designItem.continuity,
        value: {
          from: designItem.minimum,
          to: designItem.maximum,
        },
      }],
      id: designItem.name,
    }
  })

  return {
    kpis,
    design,
    goals: metaGoals,
    timestamps: {
      updatedAt,
      finishedAt,
      createdAt,
    },
  }
}

export const evaluationsMapper = (
  evaluationsDetails: Socrates.EvaluationsDetails[],
  projectEvaluations: Socrates.ProjectEvaluations[] = [],
): Socrates.EvaluationsMapper[] => {
  const mappedParetoSolutions: Socrates.EvaluationsMapper[] = []

  evaluationsDetails.forEach((evaluationItem) => {
    const designKeys = Object.keys(evaluationItem.design)
    const targetKeys = Object.keys(evaluationItem.target)
    const evaluationDetails = (projectEvaluations.find((item) => item.evaluationId === evaluationItem.id) || {}) as Socrates.ProjectEvaluations
    const reslutObject = {}

    designKeys.forEach((designKey) => {
      const item = evaluationItem.design[designKey]
      Object.assign(reslutObject, {
        [item.name]: item.value,
      })
    })

    targetKeys.forEach((targetKey) => {
      const item = evaluationItem.target[targetKey]
      Object.assign(reslutObject, {
        [item.name]: item.value,
      })
    })

    mappedParetoSolutions.push({
      ...reslutObject,
      name: evaluationItem.id,
      isParetoOptimal: evaluationDetails.isParetoOptimal,
    })
  })

  return mappedParetoSolutions
}
