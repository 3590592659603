import React from 'react'

const WarningIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none',
  className = '', detailsFill = '#FF6161',
}) => {
  return (
    <svg data-testid={WarningIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9 6.37952V10.3044" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.0018 14.8834C9.36308 14.8834 9.65595 14.5905 9.65595 14.2292C9.65595 13.8679 9.36308 13.5751 9.0018 13.5751C8.64053 13.5751 8.34766 13.8679 8.34766 14.2292C8.34766 14.5905 8.64053 14.8834 9.0018 14.8834Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.1643 1.21176C10.0545 0.997462 9.88773 0.817618 9.68229 0.692034C9.47684 0.566451 9.24073 0.5 8.99994 0.5C8.75915 0.5 8.52304 0.566451 8.31759 0.692034C8.11215 0.817618 7.94535 0.997462 7.83556 1.21176L0.639953 15.603C0.539594 15.8021 0.491813 16.0236 0.501146 16.2465C0.51048 16.4693 0.57662 16.686 0.693283 16.8761C0.809947 17.0662 0.973261 17.2233 1.16772 17.3325C1.36217 17.4417 1.58132 17.4993 1.80433 17.5H16.1955C16.4186 17.4993 16.6377 17.4417 16.8322 17.3325C17.0266 17.2233 17.1899 17.0662 17.3066 16.8761C17.4233 16.686 17.4894 16.4693 17.4987 16.2465C17.5081 16.0236 17.4603 15.8021 17.3599 15.603L10.1643 1.21176Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default WarningIcon
