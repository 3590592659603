import {
  Theme, createTheme,
  Palette, ThemeOptions,
} from '@mui/material/styles'

import {
  DEFAULT_BUTTON_FONT_SIZE,
  DEFAULT_SELECT_BOX_SHADOW,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BOX_SHADOW,
} from '@constants/ui.constants'

import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import typography from './theme/theme.typography'
import palette, { NewPalette, PaletteOptionsExtended } from './theme/theme.palette'
import defaultDataGridConfig from './theme/data-grid.config'
import defaultDatePickersConfigs from './theme/date-pickers.config'

export interface ThemeOptionsExtended extends ThemeOptions {
  palette: PaletteOptionsExtended
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    new: NewPalette,
    colorWay: {
      [key: string]: string,
    },
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    new: NewPalette,
  }

  interface PaletteOptions {
    new: NewPalette,
  }
}

declare module '@mui/material/styles' {
  export interface Theme {
    palette: Palette
  }
}

const themeOptions: ThemeOptionsExtended = {
  spacing: 10,
  typography,
  palette,
  components: {
    ...defaultDatePickersConfigs,
    MuiDataGrid: defaultDataGridConfig,
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '16px',
          lineHeight: '125%',
          letterSpacing: '0.24px',
          color: theme.palette.new.business_black,
          padding: '8px 12px',
          gap: '10px',

          '&&': {
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
            '&:focus-visible': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
            '&:active': {
              backgroundColor: theme.palette.new.business_black_10,
            },
            '&.Mui-selected': {
              fontWeight: 500,
              backgroundColor: theme.palette.new.white,
              color: theme.palette.new.versatile_violet,

              '& .MuiTypography-root': {
                fontWeight: 500,
                color: theme.palette.new.versatile_violet,
              },
              '&:hover': {
                backgroundColor: theme.palette.new.versatile_violet_5,
              },
              '&.Mui-focused': {
                backgroundColor: theme.palette.new.versatile_violet_5,
              },
              '&:focus-visible': {
                backgroundColor: theme.palette.new.versatile_violet_5,
              },
              '&:active': {
                backgroundColor: theme.palette.new.versatile_violet_10,
              },
            },
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiMenuItem-root': {
            borderTop: `1px solid ${theme.palette.new.business_black_20}`,

            '&:first-of-type': {
              borderTop: 'none',
            },
            '&:last-of-type': {
              borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
              borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
            },

            '&.Mui-disabled': {
              opacity: 1,
              backgroundColor: theme.palette.new.smokey_silver,
              '& .MuiTypography-root': {
                opacity: 0.4,
              },
            },
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiPaper-root': {
            outline: `1px solid ${theme.palette.new.business_black_20}`,
            boxShadow: DEFAULT_SELECT_BOX_SHADOW,
            borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
          },
          '& .MuiMenuItem-root': {
            fontSize: DEFAULT_BUTTON_FONT_SIZE,
            '& .MuiTypography-root': {
              fontSize: DEFAULT_BUTTON_FONT_SIZE,
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
            '&:focus-visible': {
              backgroundColor: theme.palette.new.smokey_silver,
            },
            '&:active': {
              backgroundColor: theme.palette.new.business_black_10,
            },
            '&.Mui-selected': {
              fontWeight: 500,
              backgroundColor: theme.palette.new.white,
              color: theme.palette.new.versatile_violet,

              '& .MuiTypography-root': {
                fontWeight: 500,
                color: theme.palette.new.versatile_violet,
              },
              '&:hover': {
                backgroundColor: theme.palette.new.versatile_violet_5,
              },
              '&:focus-visible': {
                backgroundColor: theme.palette.new.versatile_violet_5,
              },
              '&:active': {
                backgroundColor: theme.palette.new.versatile_violet_10,
              },
            },
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.new.white,
          color: theme.palette.new.black_b,
          boxShadow: DEFAULT_BOX_SHADOW,
          opacity: 1,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          padding: theme.spacing(1.2, 2),
          fontSize: '16px',
          fontWeight: 400,
          letterSpacing: '0.015em',
          outline: `1px solid ${theme.palette.new.business_black_20}`,
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.new.white,

          '&::before': {
            outline: `1px solid ${theme.palette.new.business_black_20}`,
            backgroundColor: theme.palette.new.white,
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiAccordion-root': {
            '&:before': {
              display: 'none',
            },
          },
          '&.MuiAccordion-root.Mui-expanded': {
            margin: 0,
            '& .MuiAccordionSummary': {
              borderBottom: `1px solid ${theme.palette.new.grey_a} !important`,
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            },
            '&:hover': {
              '& .MuiAccordionSummary': {
                borderBottom: `1px solid ${theme.palette.new.grey_c} !important`,
              },
            },
            '&:before': {
              opacity: 1,
            },
          },
          '&.MuiAccordion-rounded': {
            borderTop: `1px solid ${theme.palette.new.grey_a}`,
            borderLeft: `1px solid ${theme.palette.new.grey_a}`,
            borderRight: `1px solid ${theme.palette.new.grey_a}`,
            '&:first-of-type': {
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            },
            '&:last-child': {
              borderBottom: `1px solid ${theme.palette.new.grey_a}`,
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            },
            '&:hover': {
              backgroundColor: palette.new.grey,
              borderTop: `1px solid ${theme.palette.new.grey_c}`,
              borderLeft: `1px solid ${theme.palette.new.grey_c}`,
              borderRight: `1px solid ${theme.palette.new.grey_c}`,
              '&:last-child': {
                borderBottom: `1px solid ${theme.palette.new.grey_c}`,
              },
              '& + .MuiAccordion-rounded': {
                borderTop: `1px solid ${theme.palette.new.grey_c}`,
              },
            },
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: '60px',
          minHeight: '60px',
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}

const theme: Theme = createTheme(themeOptions)

export default theme
