/**
 * @function splitRangeIntoIntervals Split min-max range into intervals
 *
 * @param {Number} min Range Min
 * @param {Number} max Range Max
 * @param {Number} intervals Num of intervals
 *
 * @return {Array} Intervals between min & max
 */
export const splitRangeIntoIntervals = (
  min: number = 0,
  max: number = 0,
  intervals: number = 0,
): number[] => {
  const results: number[] = []

  if ((intervals === 0) || (min === max)) {
    return results
  }

  const delta = (max - min) / (intervals - 1)
  let minValue = min

  while (minValue < max) {
    results.push(minValue)
    minValue += delta
  }

  results.push(max)

  return results
}

/**
 * @function checkIfValueInRange Checks whether value in range
 *
 * @param {Number} min Range Min
 * @param {Number} max Range Max
 * @param {Number} value Value to check
 *
 * @return {Boolean} true, if in range
 */
export const checkIfValueInRange = (
  min: number = 0,
  max: number = 0,
  value: number = 0,
) => {
  return (value > min) && (value < max)
}
