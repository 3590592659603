import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'

import ButtonComponent from '@base/buttons/Button'
import LoadingCloudComponent from '@base/loading/LoadingCloud'

export interface InitialSetupComponentProps {
  onCreateButtonClick: () => void
}

const InitialSetupComponent: React.FC<InitialSetupComponentProps> = ({ onCreateButtonClick }) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      data-testid={InitialSetupComponent.name}
      sx={{
        display: 'flex',
        minHeight: '50vh',
        height: '100%',
        width: '100%',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingCloudComponent
        size='large'
        position='absolute'
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 4,
          gap: theme.spacing(2),
        }}
      >
        <Typography
          variant='h1'
        >
          {intl.formatMessage({ id: 'use_cases.initial.title' })}
        </Typography>

        <ButtonComponent
          name='setupUseCase'
          color='primary'
          onClick={onCreateButtonClick}
          label={intl.formatMessage({ id: 'use_cases.initial.button' })}
        />
      </Box>
    </Box>
  )
}

export default InitialSetupComponent
