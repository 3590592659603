import React from 'react'

const FileCodeIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={FileCodeIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M3.21431 17.3572L0.642883 14.7857L3.21431 12.2143M7.07145 17.3572L9.64288 14.7857L7.07145 12.2143M3.21431 9.64288V1.9286C3.21431 1.5876 3.34977 1.26058 3.59089 1.01946C3.83201 0.778342 4.15903 0.642883 4.50003 0.642883H10.9286M10.9286 0.642883L17.3572 7.07145M10.9286 0.642883V7.07145H17.3572M17.3572 7.07145V16.0715C17.3572 16.4124 17.2217 16.7395 16.9806 16.9806C16.7395 17.2217 16.4124 17.3572 16.0715 17.3572H12.2143" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FileCodeIcon
