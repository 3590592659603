import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

interface ValidationScheme {
  name: string,
  unitLabel?: string,
}

const connectParameterValidations = (intl: IntlShape) => {
  const scheme: Yup.SchemaOf<ValidationScheme> = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'use_cases.form.parameter.name.required' })),
    unitLabel: Yup.string(),
  })

  return scheme
}

export default connectParameterValidations
