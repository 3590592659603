import React, { forwardRef, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Box, Container, ContainerProps } from '@mui/material'
import { PAGE_GUTTERS } from '@constants/ui.constants'

import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import LoadingOverlayComponent from '@base/loading/LoadingOverlay'
import PageBarContainer from '@containers/application/PageBar'

export interface PageProps {
  /**
   * Children to be rendered
   */
  children: React.ReactNode | React.ReactChild | React.ReactElement,
  /**
   * Browser tab title
   */
  title?: string,
  /**
   * If true, the overlay fetching will be shown
   */
  overlayFetching?: boolean,
  /**
   * If true, the fetching spinner will be shown
   */
  isFetching?: boolean,
  /**
   * Right side blocks for the page bar
   */
  pageBarRightSideBlocks?: React.ReactNode,
  /**
   * Custom title for the page bar.
   * If provided, the title from navigation will be ignored
   */
  customTitle?: React.ReactNode,
  /**
   * Path to go back, if provided, a back button will be shown
   */
  backToPath?: string,
  /**
   * Tabs to be displayed
   */
  tabs?: Common.TabOption[]
  /**
   * Callback to be called when tab is changed
   * @param tab - Selected tab option
   */
  onTabChange?: (tab: Common.TabOption) => void,
  /**
   * Container props
   */
  containerProps?: ContainerProps,
}

const PageLayoutContainer = forwardRef(({
  children,
  tabs,
  title = '',
  isFetching = false,
  pageBarRightSideBlocks,
  customTitle,
  backToPath,
  overlayFetching,
  containerProps,
  onTabChange,
  ...rest
} : PageProps, ref: any) => {
  const intl = useIntl()

  const padding = `${(isFetching && !overlayFetching) ? 0 : PAGE_GUTTERS}px`
  const content = useMemo(() => {
    if (isFetching && !overlayFetching) {
      return <LoadingFallbackComponent size='large' showLongRequestNote={true} />
    }

    return (
      <>
        <PageBarContainer
          rightSideBlocks={pageBarRightSideBlocks}
          customTitle={customTitle}
          backToPath={backToPath}
          onTabChange={onTabChange}
          tabs={tabs}
        />

        {children}
      </>
    )
  }, [
    isFetching, children, overlayFetching, tabs,
    pageBarRightSideBlocks, customTitle, backToPath,
    onTabChange,
  ])

  const rootStyles = {
    minHeight: '100%',
    paddingBottom: padding,
    paddingTop: padding,
    position: 'relative',
  }

  const containerStyles = {
    paddingLeft: padding,
    paddingRight: padding,
  }

  return (
    <Box
      data-testid='PageLayoutContainer'
      ref={ref}
      sx={rootStyles}
      {...rest}
    >
      <Helmet>
        <title>{isFetching ? intl.formatMessage({ id: 'common.layout.header.loading' }) : title}</title>
      </Helmet>
      <Container
        data-testid='PageLayoutContainer-wrapper'
        maxWidth={false}
        disableGutters={true}
        sx={containerStyles}
        {...containerProps}
      >
        {content}

        <LoadingOverlayComponent show={Boolean(overlayFetching && isFetching)} showLongRequestNote={true} />
      </Container>
    </Box>
  )
})

export default PageLayoutContainer
