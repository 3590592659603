import { handleActions } from 'redux-actions'
import { HermesState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_CONNECTIONS,
  RECEIVE_TRANSFORMATIONS,
  RECEIVE_DETAILED_CONNECTIONS,
  RECEIVE_REMOVED_CONNECTIONS,
  RECEIVE_SOURCES,
  RECEIVE_PUBLISHER,
  RECEIVE_PUBLISHERS_LIST,
  RECEIVE_DESTINATIONS,
  RECEIVE_ARTIFACTS_EXPORT_DETAILS,
  REQUEST_ADD_CONNECTION,
  REQUEST_UPDATE_CONNECTION,
  START_FETCHING_HERMES,
  STOP_FETCHING_HERMES,
} from './hermes.action-types'

import {
  initialState,
  receiveConnectionsByCompanyId,
  receiveRemovedConnections,
  receiveTransformations,
  receiveConnectionsWithDetails,
  receiveSources,
  receiveDestinations,
  receivePublisher,
  receivePublishersList,
  receiveArtifactsExportDetails,
  requestAddConnection,
  requestUpdateConnection,
} from './hermes.reducers'

export default handleActions<HermesState, any>({
  [RECEIVE_SOURCES]: receiveSources,
  [RECEIVE_PUBLISHER]: receivePublisher,
  [RECEIVE_PUBLISHERS_LIST]: receivePublishersList,
  [RECEIVE_DESTINATIONS]: receiveDestinations,
  [RECEIVE_DETAILED_CONNECTIONS]: receiveConnectionsWithDetails,
  [RECEIVE_ARTIFACTS_EXPORT_DETAILS]: receiveArtifactsExportDetails,
  [RECEIVE_TRANSFORMATIONS]: receiveTransformations,
  [RECEIVE_CONNECTIONS]: receiveConnectionsByCompanyId,
  [RECEIVE_REMOVED_CONNECTIONS]: receiveRemovedConnections,
  [REQUEST_ADD_CONNECTION]: requestAddConnection,
  [REQUEST_UPDATE_CONNECTION]: requestUpdateConnection,
  [START_FETCHING_HERMES]: startFetchingAction<HermesState>,
  [STOP_FETCHING_HERMES]: stopFetchingAction<HermesState>,
}, initialState)
