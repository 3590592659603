import React from 'react'
import { Handle, HandleProps, Position } from '@xyflow/react'
import { Box, useTheme } from '@mui/material'

export interface ReactFlowHandleComponentProps extends HandleProps {
  /**
   * Position of the handle
   */
  position: Position,
  /**
   * Whether the dots should be invisible
   */
  invisibleDots?: boolean,
  /**
   * Additional styles
   */
  style?: any,
}

const ReactFlowHandleComponent: React.FC<ReactFlowHandleComponentProps> = ({
  position,
  invisibleDots = false,
  ...rest
}) => {
  const theme = useTheme()

  if (!position) {
    return null
  }

  const styles = {
    '& .react-flow__handle': {
      ...(position !== Position.Left ? {
        border: 'none',
        right: theme.spacing(0),
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: theme.palette.new.grey_c,
      } : {
        border: 'none',
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: 'transparent',
      }),
      ...(invisibleDots ? {
        backgroundColor: 'transparent',
      } : {}),
    },
  }

  return (
    <Box
      sx={styles}
    >
      <Handle
        data-testid={ReactFlowHandleComponent.name}
        position={position}
        isConnectable={false}
        {...rest}
      />
    </Box>
  )
}

export default ReactFlowHandleComponent
