import { Box } from '@mui/material'
import React from 'react'

const ChevronLeftIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <Box
      data-testid={ChevronLeftIcon.name}
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        '& svg': {
          transform: 'rotate(90deg)',
        },
      }}
    >
      <svg data-testid={ChevronLeftIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
        <path d="M16 6L9.37692 12.6231C9.32858 12.6746 9.27019 12.7156 9.20536 12.7437C9.14053 12.7718 9.07064 12.7863 9 12.7863C8.92936 12.7863 8.85946 12.7718 8.79464 12.7437C8.72981 12.7156 8.67142 12.6746 8.62308 12.6231L2 6" strokeWidth="1.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Box>
  )
}

export default ChevronLeftIcon
