import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'

import { REQUEST_CANCELED_BY_USER } from '@utils/request-cancelation.utils'
import { EMITTER_TYPES } from '@constants/emitter.constants'
import eventEmitter, { axiosCancelTokenController } from '@utils/emitter.utils'

import ButtonComponent from '@base/buttons/Button'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

export interface DropzoneProgressComponentProps {
  /**
   * Flag to indicate if the file is being uploaded
   */
  isUploading?: boolean,
  /**
   * Height of the component
   * @default '100px'
   */
  height?: string,
}

const DropzoneProgressComponent: React.FC<DropzoneProgressComponentProps> = ({
  isUploading,
  height = '100px',
}) => {
  const intl = useIntl()
  const [progress, setProgress] = useState(0)
  const canCancel = axiosCancelTokenController && axiosCancelTokenController.controller && isUploading

  const getEvent = () => {
    if (isUploading) {
      return EMITTER_TYPES.FILE_UPLOAD_PROGRESS
    }

    return ''
  }

  const emitterEvent = getEvent()

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (canCancel) {
      axiosCancelTokenController.controller.abort(REQUEST_CANCELED_BY_USER)
      axiosCancelTokenController.controller = new AbortController()
    }
  }

  useEffect(() => {
    eventEmitter.on(
      emitterEvent,
      (progressEvent) => {
        setProgress(Math.floor(100 * (progressEvent.loaded / progressEvent.total)))
      },
    )

    return function cleanup() {
      eventEmitter.off(emitterEvent)
    }
  }, [
    emitterEvent,
  ])

  return (
    <Box
      data-testid={DropzoneProgressComponent.name}
      width='100%'
      height={height}
      overflow='hidden'
      position='relative'
    >
      <LoadingFallbackComponent
        fluid={true}
        size='medium'
      />

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          px: '32px',
          zIndex: 10,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          gap='5px'
        >
          <Typography
            variant='caption'
            noWrap={true}
            color='white'
          >
            {intl.formatMessage({ id: 'use_cases.files.progress.upload' })}
          </Typography>

          <Typography
            variant='h4'
            noWrap={true}
            color='white'
          >
            {`${progress}%`}
          </Typography>
        </Box>

        {
          canCancel ? (
            <ButtonComponent
              name='cancelUpload'
              color='secondary'
              onClick={handleCancel}
              label={intl.formatMessage({ id: 'use_cases.files.progress.cancel' })}
              sx={{
                position: 'absolute',
                right: '32px',
              }}
            />
          ) : (
            <Box />
          )
        }
      </Box>
    </Box>
  )
}

export default DropzoneProgressComponent
