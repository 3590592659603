import React from 'react'

import {
  Typography,
  Box,
  useTheme,
} from '@mui/material'

import { DEFAULT_PADDING, SIDE_PANEL_HEADER_HEIGTH } from '@constants/ui.constants'

export interface SidePanelHeaderComponentProps {
  /**
   * Title of the side panel
   */
  title?: string | React.ReactNode
  /**
   * Right side blocks
   */
  rightSideBlocks?: React.ReactNode
  /**
   * Icon of the side panel
   */
  SidePanelIcon?: React.ElementType,
}

export const SidePanelHeaderComponent: React.FC<SidePanelHeaderComponentProps> = ({
  title,
  rightSideBlocks,
  SidePanelIcon,
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={SidePanelHeaderComponent.name}
      display='flex'
      flexDirection='row'
      gap={DEFAULT_PADDING.SMALL}
      justifyContent='space-between'
      alignItems='center'
      sx={{
        minHeight: SIDE_PANEL_HEADER_HEIGTH,
        height: SIDE_PANEL_HEADER_HEIGTH,
        padding: theme.spacing(0, 4),
        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap={DEFAULT_PADDING.SMALL}
      >
        { SidePanelIcon ? <SidePanelIcon width={24} height={24} /> : null }

        <Typography variant='h5'>
          {title}
        </Typography>
      </Box>

      {
        rightSideBlocks ? (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap={DEFAULT_PADDING.SMALL}
          >
            {rightSideBlocks}
          </Box>
        ) : (
          <Box />
        )
      }
    </Box>
  )
}

export default SidePanelHeaderComponent
