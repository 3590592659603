import React from 'react'
import ButtonComponent, { ButtonComponentProps } from '@base/buttons/Button/Button.component'
import ChevronRightIcon from '@icons/chevronRight.icon'

export interface CallToActionButtonComponentProps extends Pick<ButtonComponentProps,
  'name' |
  'loading' |
  'disabled' |
  'forwardedRef' |
  'onClick' |
  'label' |
  'StartIconComponent'
> {}

export const CallToActionButtonComponent: React.FC<CallToActionButtonComponentProps> = ({
  name,
  loading,
  disabled,
  label,
  onClick,
  StartIconComponent = ChevronRightIcon,
  ...props
}) => {
  return (
    <ButtonComponent
      name={name}
      loading={loading}
      disabled={disabled}
      color='primary'
      label={label}
      onClick={onClick}
      StartIconComponent={StartIconComponent}
      {...props}
    />
  )
}

export default React.forwardRef<React.FC, CallToActionButtonComponentProps>((props, ref) => <CallToActionButtonComponent {...props} forwardedRef={ref} />)
