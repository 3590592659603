const SYNC_STATUS = {
  pending: 'pending',
  running: 'running',
  cancelled: 'cancelled',
  succeeded: 'succeeded',
  failed: 'failed',
  resetting: 'resetting',
  nosync: 'nosync',
}

export default SYNC_STATUS
