import { handleActions } from 'redux-actions'
import { OptimizeState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_OPTIMIZE_PAGE_DATA,
  RECEIVE_OPTIMIZE_DRILL_DOWN,
  SET_TARGET_REVENUEDONE,
  SET_TARGET_REPLENISHMENT_COST_DONE,
  SET_STRATEGY_ACTION_DONE,
  START_FETCHING_OPTIMIZE,
  STOP_FETCHING_OPTIMIZE,
  SET_TARGET_SELECTOR_TYPE_DONE,
} from './optimize.action-types'

import {
  receiveOptimizePageData,
  receiveOptimizeDrillDownData,
  setTargetReplenishmentCostData,
  setTargetRevenueData,
  setTargetSelectorType,
  setStrategyData,
  initialState,
} from './optimize.reducers'

export default handleActions<OptimizeState, any>({
  [RECEIVE_OPTIMIZE_PAGE_DATA]: receiveOptimizePageData,
  [RECEIVE_OPTIMIZE_DRILL_DOWN]: receiveOptimizeDrillDownData,
  [SET_TARGET_REVENUEDONE]: setTargetRevenueData,
  [SET_TARGET_REPLENISHMENT_COST_DONE]: setTargetReplenishmentCostData,
  [SET_TARGET_SELECTOR_TYPE_DONE]: setTargetSelectorType,
  [SET_STRATEGY_ACTION_DONE]: setStrategyData,
  [START_FETCHING_OPTIMIZE]: startFetchingAction<OptimizeState>,
  [STOP_FETCHING_OPTIMIZE]: stopFetchingAction<OptimizeState>,
}, initialState)
