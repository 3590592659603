import { getModalNameByHash } from '@utils/navigation.utils'
import { State } from '@redux/modules/types'

export const getOpenedModal = (state: State) => {
  const hashedModalName = getModalNameByHash(window.location.hash)

  return hashedModalName || state.modalManager.primaryModalPage
}

export const getModalDetails = <T extends Common.ModalDetails>(state: State) => state.modalManager.modalDetails as T
