import React from 'react'

const FilterIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={FilterIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M17.357 0.978027H0.6427L7.07127 9.97803V17.6923L10.9284 15.1209V9.97803L17.357 0.978027Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default FilterIcon
