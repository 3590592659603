import { createAction } from 'redux-actions'

import { PROMOTION_DAYS } from '@constants/promotions.constants'

import {
  REQUEST_INSIGHTS_ABC_TOTALS,
  RECEIVE_INSIGHTS_ABC_TOTALS,

  START_FETCHING_INSIGHTS,
  STOP_FETCHING_INSIGHTS,

  REQUEST_INSIGHTS_PIPELINE_RUN_INFO,
  RECEIVE_INSIGHTS_PIPELINE_RUN_INFO,

  REQUEST_PROMOTIONS_VISIBILITY_CHANGE,
  RECEIVE_PROMOTIONS_VISIBILITY_CHANGE,

  REQUEST_PROMOTIONS_SETTINGS_CHANGE,
  RECEIVE_PROMOTIONS_SETTINGS_CHANGE,
} from './insights.action-types'

export const startFetchingInsightsAction = createAction<string>(START_FETCHING_INSIGHTS)
export const stopFetchingInsightsAction = createAction<string>(STOP_FETCHING_INSIGHTS)

export const requestInsightsAbcTotalsAction = createAction<Hera.AbcTotalsApiRequest>(REQUEST_INSIGHTS_ABC_TOTALS)
export const receiveInsightsAbcTotalsAction = createAction(RECEIVE_INSIGHTS_ABC_TOTALS)

export const requestInsightsPipelineRunInfoAction = createAction<Hera.InsightsLatestPipelineRunInfoAPIRequest>(REQUEST_INSIGHTS_PIPELINE_RUN_INFO)
export const receiveInsightsPipelineRunInfoAction = createAction(RECEIVE_INSIGHTS_PIPELINE_RUN_INFO)

export const requestPromotionsVisibilityChangeAction = createAction<boolean>(REQUEST_PROMOTIONS_VISIBILITY_CHANGE)
export const receivePromotionsVisibilityChangeAction = createAction(RECEIVE_PROMOTIONS_VISIBILITY_CHANGE)

export const requestPromotionsSettingsChangeAction = createAction<PROMOTION_DAYS[]>(REQUEST_PROMOTIONS_SETTINGS_CHANGE)
export const receivePromotionsSettingsChangeAction = createAction(RECEIVE_PROMOTIONS_SETTINGS_CHANGE)
