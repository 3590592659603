import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Typography, useTheme } from '@mui/material'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export interface ChartTooltipValueItemComponentProps {
  /**
   * The value of the data point
   */
  value?: string | number | null,
  /**
   * The color of the value
   */
  color?: string
}

export const ChartTooltipValueItemComponent: React.FC<ChartTooltipValueItemComponentProps> = ({
  value,
  color,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const gradientColor = color?.includes('linear-gradient')

  const valueToRender = useMemo(() => {
    if (value === null) {
      return intl.formatMessage({ id: 'common.na' })
    }

    return value
  }, [
    value,
    intl,
  ])

  const typographyStyles = color ? {
    ...(gradientColor ? {
      ...getTextBackgroundFillStyles(color),
      backgroundImage: color,
    } : {
      color,
    }),
  } : {
    color: theme.palette.new.business_black_60,
  }

  return (
    <Typography
      display='flex'
      alignItems='center'
      variant='body1'
      sx={typographyStyles}
      data-testid={ChartTooltipValueItemComponent.name}
    >
      {valueToRender}
    </Typography>
  )
}

export default ChartTooltipValueItemComponent
