import React, { useLayoutEffect } from 'react'

import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import { useDispatch } from '@redux/hooks'
import { requestBacktestingAction, resetBacktestingAction } from '@redux/modules/monitor/monitor.actions'

import BacktestingTableContainer from './backtesting-table/BacktestingTable.container'
import BacktestingFiltersContainer from './backtesting-filters/BacktestingFilters.container'
import BacktestingChartContainer from './backtesting-chart/BacktestingChart.container'
import BacktestingKpisContainer from './backtesting-kpis/BacktestingKpis.container'

export interface BacktestingContainerProps {
  exportId?: string,
}

const BacktestingContainer: React.FC<BacktestingContainerProps> = ({ exportId }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      requestBacktestingAction({
        useCaseId,
      }),
    )

    return () => {
      dispatch(
        resetBacktestingAction(),
      )
    }
  }, [dispatch, useCaseId])

  return (
    <Box
      id={exportId}
      data-testid={BacktestingContainer.name}
      sx={{ backgroundColor: 'white' }}
    >
      <BacktestingKpisContainer />

      <BacktestingChartContainer />

      <BacktestingFiltersContainer />

      <BacktestingTableContainer />
    </Box>
  )
}

export default BacktestingContainer
