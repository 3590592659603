import React from 'react'

const BrokenLinkWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'white',
}) => {
  return (
    <svg data-testid={BrokenLinkWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M3.95444 9.1626L1.50971 11.6056C0.863063 12.2545 0.5 13.1329 0.5 14.0486C0.5 14.9644 0.863063 15.8428 1.50971 16.4917V16.4917C2.15901 17.1379 3.03806 17.5007 3.95444 17.5007C4.87082 17.5007 5.74987 17.1379 6.39917 16.4917L7.85031 15.0415" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6174 12.2593H14.049C14.9644 12.2593 15.8423 11.896 16.4895 11.2491C17.1368 10.6023 17.5004 9.72508 17.5004 8.81036V8.81036C17.5004 7.89563 17.1368 7.01838 16.4895 6.37157C15.8423 5.72476 14.9644 5.36139 14.049 5.36139H10.5977" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00087 0.5L8.34766 3.11285" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.503906 4.42065L3.11859 5.72708" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.77344 0.5L5.07986 3.11285" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default BrokenLinkWhiteIcon
