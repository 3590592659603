import produce from 'immer'
import orderBy from 'lodash.orderby'
import { Edge, Node } from '@xyflow/react'

import {
  NODE_TYPES,
  DEFAULT_ARROW_CONFIG,
  MAX_SOURCES_TO_SHOW,
  INPUT_TYPES,
  SOURCE_TYPES,
  SOURCE_TYPES_OPTIONS,
  SourceTypeOption,
  SOURCE_TYPE_FALLBACK_OPTION,
} from '@constants/flow.constants'

import { ARTIFACTS_FORMAT_DEFAULT } from '@constants/locales.constants'
import { UseCaseState, ReducerPayload } from '@redux/modules/types'
import { getFileIdentifiersCount } from '@utils/use-cases.utils'

import {
  DEFAULT_USE_CASE_FAMILY_TYPE,
} from '@constants/use-cases.constants'

import {
  CREATED_AT_SORTING_KEY,
  DEFAULT_SORTER,
  DEFAULT_SORTING_ORDER,
  DESC_SORTING_ORDER,
} from '@constants/filters.constants'

import { PrepareConnectOverviewDataReducerPayload } from './use-case.types'
import { defaultDemandProblemDefinition } from './use-case.api'

export const initialState: UseCaseState = {
  fetchingKeys: [],
  list: [],
  item: {
    companyId: '',
    createdAt: '',
    artifactLanguage: ARTIFACTS_FORMAT_DEFAULT,
    name: '',
    inputParameters: [],
    outputParameters: [],
    updatedAt: '',
    demandUseCaseId: '',
    useCaseId: '',
    artifactAvailable: false,
    analysisPlotAvailable: false,
    executePlotAvailable: false,
    groupingAttributes: [],
    contacts: [],
    frozen: 'False',
    fileStorageBucket: '',
    family: DEFAULT_USE_CASE_FAMILY_TYPE,
    artifacts: {},
    forecastValue: null,
    forecastParameters: null,
    lastSuccessfulPipelineRun: null,
    compositePipelineStatus: null,
  },
  demandProblemDefintion: defaultDemandProblemDefinition as UseCase.DemandProblemDefinition,
  availableMetaDataColumns: [],
  specifiedMetaDataColumns: [],
  connectOverviewNodes: [],
  connectOverviewEdges: [],
  artifactsMappings: [],
}

export const receiveUseCaseAvailableMetaDataColumns = (state: UseCaseState, action: ReducerPayload<string[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.availableMetaDataColumns = action.payload
  })

  return nextState
}

export const receiveUseCaseSpecifiedMetaDataColumns = (state: UseCaseState, action: ReducerPayload<string[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.specifiedMetaDataColumns = action.payload
  })

  return nextState
}

export const receiveDemandProblemDefintion = (state: UseCaseState, action: ReducerPayload<UseCase.DemandProblemDefinition>) => {
  const nextState = produce(state, (draftState) => {
    draftState.demandProblemDefintion = action.payload
  })

  return nextState
}

export const resetConnectOverviewData = (state: UseCaseState) => {
  const nextState = produce(state, (draftState) => {
    draftState.connectOverviewNodes = []
    draftState.connectOverviewEdges = []
  })

  return nextState
}

export const receiveArtifactsMappings = (state: UseCaseState, action: ReducerPayload<UseCase.ArtifactMappingItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.artifactsMappings = orderBy(action.payload, (data) => DEFAULT_SORTER(data, CREATED_AT_SORTING_KEY), DESC_SORTING_ORDER) as UseCase.ArtifactMappingItem[]
  })

  return nextState
}

export const receive = (state: UseCaseState, action: ReducerPayload<UseCase.DetailsExtended[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.list = orderBy(action.payload, DEFAULT_SORTER, DEFAULT_SORTING_ORDER) as UseCase.DetailsExtended[]
  })

  return nextState
}

export const receiveOne = (state: UseCaseState, action: ReducerPayload<UseCase.DetailsExtended>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
  })

  return nextState
}

export const prepareConnectOverviewData = (state: UseCaseState, action: ReducerPayload<PrepareConnectOverviewDataReducerPayload>) => {
  const nextState = produce(state, (draftState) => {
    const {
      useCase: {
        inputParameters,
        outputParameters,
        groupingAttributes = [],
      },
      filesIdentifiers = [],
      connectionsList = [],
    } = action.payload

    const fileVersionsCount = getFileIdentifiersCount(filesIdentifiers)
    const allParameters: UseCase.ParameterItem[] = inputParameters.concat(outputParameters as any, groupingAttributes as any) as UseCase.ParameterItem[]

    const activeInputs = inputParameters.filter((parameter) => {
      return parameter.inputType === INPUT_TYPES.ACTIVE
    })

    const genericInputs = inputParameters.filter((parameter) => {
      return parameter.inputType === INPUT_TYPES.GENERIC
    })

    const passiveInputs = inputParameters.filter((parameter) => {
      return parameter.inputType === INPUT_TYPES.PASSIVE
    })

    /** Sources connected to Hermes */
    const activeSourcesIds = [...new Set((connectionsList.filter((connection) => connection.isConnectedToUseCase).map((connection) => connection.sourceId) || []))]
    const uniqueApiSources: string[] = activeSourcesIds.filter((sourceId) => {
      const sourceDetails = SOURCE_TYPES_OPTIONS.find((source) => source.sourceId === sourceId) || SOURCE_TYPE_FALLBACK_OPTION

      return !sourceDetails.hidden
    }).map((sourceId) => {
      const sourceDetails = SOURCE_TYPES_OPTIONS.find((source) => source.sourceId === sourceId) || SOURCE_TYPE_FALLBACK_OPTION

      return sourceDetails?.value
    })

    /** Static sources */
    const uniqueNonApiSources: string[] = [...new Set(allParameters.map((item) => item.sourceType))].filter((sourceType) => {
      const sourceDetails = (SOURCE_TYPES_OPTIONS.find((source) => source.value === sourceType) || SOURCE_TYPE_FALLBACK_OPTION) as SourceTypeOption

      return !sourceDetails.sourceId && !sourceDetails.hidden
    })

    if (fileVersionsCount && !uniqueNonApiSources.includes(SOURCE_TYPES.CUSTOM)) {
      uniqueNonApiSources.unshift(SOURCE_TYPES.CUSTOM)
    }

    /** Sort sources to match the order */
    const uniqueSources: string[] = [...uniqueApiSources, ...uniqueNonApiSources].sort((a, b) => {
      const indexOfA = SOURCE_TYPES_OPTIONS.findIndex((source) => source.value === a)
      const indexOfB = SOURCE_TYPES_OPTIONS.findIndex((source) => source.value === b)

      return indexOfA - indexOfB
    })

    const uniqueSourcesLength = uniqueSources.length
    const uniqueSourcesSplitted = (uniqueSourcesLength - MAX_SOURCES_TO_SHOW > 0) ? uniqueSources.splice(uniqueSourcesLength - MAX_SOURCES_TO_SHOW) : uniqueSources

    const initialIndex = 1
    const groupingIndex = initialIndex + 1
    const groupingHandlesAmount = 3

    let inputGroupIndex = (groupingIndex + 1)
    let handleIndex = 1

    const numOfInputTypes = 3
    const targetsIndex = inputGroupIndex + numOfInputTypes
    const isGroupingAttributesPresent = Boolean(groupingAttributes.length)
    const isActiveInputsActive = Boolean(activeInputs.filter((input) => input.sourceType !== SOURCE_TYPES.NO_CONNECTION).length)
    const isPassiveInputsActive = Boolean(passiveInputs.filter((input) => input.sourceType !== SOURCE_TYPES.NO_CONNECTION).length)
    const isGenericInputsActive = Boolean(genericInputs.filter((input) => input.sourceType !== SOURCE_TYPES.NO_CONNECTION).length)
    const isActiveByIndex = [isActiveInputsActive, isPassiveInputsActive, isGenericInputsActive]

    /** DATA_SOURCES */
    const edgesList: Edge[] = []
    const nodesList: Node<UseCase.ConnectOverviewElementData>[] = [{
      id: String(initialIndex),
      type: NODE_TYPES.INPUT,
      position: { x: 0, y: 0 },
      data: {
        fileVersionsCount,
        numOfSources: uniqueSourcesLength,
        uniqueSources: uniqueSourcesSplitted,
        numOfInputTypes,
      },
    }]

    if (!isGroupingAttributesPresent) {
      /** EMPTY_GROUPING */
      nodesList.push({
        id: String(groupingIndex),
        type: NODE_TYPES.EMPTY_GROUPING,
        position: { x: 0, y: 0 },
        data: {
          numOfInputTypes,
        },
      })
    } else {
      /** GROUPING_ATTRIBUTES */
      nodesList.push({
        id: String(groupingIndex),
        type: NODE_TYPES.GROUPING_ATTRIBUTES,
        position: { x: 0, y: 0 },
        data: {
          numOfInputTypes,
          groupingAttributes,
          numOfNodes: groupingAttributes.length,
        },
      })
    }

    /** DATA_SOURCES to GROUPING_ATTRIBUTES connectors */
    for (let i = 0; i < groupingHandlesAmount; i += 1) {
      edgesList.push({
        id: `e${initialIndex}-${groupingIndex}-${i + 1}`,
        source: `${initialIndex}`,
        sourceHandle: `${i + 1}`,
        target: `${groupingIndex}`,
        targetHandle: `target_${i + 1}`,
        ...DEFAULT_ARROW_CONFIG,
        markerEnd: isGroupingAttributesPresent ? 'arrow' : undefined,
        animated: isActiveByIndex[i],
      })
    }

    /** ACTIVE_INPUTS */
    nodesList.push({
      id: String(inputGroupIndex),
      type: NODE_TYPES.ACTIVE_INPUTS_GROUP,
      position: { x: 0, y: 0 },
      data: {
        activeInputs,
        numOfNodes: activeInputs.length,
      },
    })

    /** GROUPING_ATTRIBUTES to ACTIVE_INPUTS connectors */
    edgesList.push({
      id: `e${groupingIndex}-${inputGroupIndex}`,
      source: `${groupingIndex}`,
      sourceHandle: `source_${handleIndex}`,
      target: `${inputGroupIndex}`,
      ...DEFAULT_ARROW_CONFIG,
      animated: isActiveInputsActive,
    })

    /** ACTIVE_INPUTS to TARGETS_GROUP connectors */
    edgesList.push({
      id: `e${inputGroupIndex}-${targetsIndex}`,
      source: `${inputGroupIndex}`,
      target: `${targetsIndex}`,
      targetHandle: String(handleIndex),
      ...DEFAULT_ARROW_CONFIG,
      animated: isActiveInputsActive,
    })

    inputGroupIndex += 1
    handleIndex += 1

    /** PASSIVE_INPUTS */
    nodesList.push({
      id: String(inputGroupIndex),
      type: NODE_TYPES.INPUTS_GROUP,
      position: { x: 0, y: 0 },
      data: {
        passiveInputs,
        numOfNodes: passiveInputs.length,
      },
    })

    /** GROUPING_ATTRIBUTES to PASSIVE_INPUTS connectors */
    edgesList.push({
      id: `e${groupingIndex}-${inputGroupIndex}`,
      source: `${groupingIndex}`,
      sourceHandle: `source_${handleIndex}`,
      target: `${inputGroupIndex}`,
      ...DEFAULT_ARROW_CONFIG,
      animated: isPassiveInputsActive,
    })

    /** PASSIVE_INPUTS to TARGETS_GROUP connectors */
    edgesList.push({
      id: `e${inputGroupIndex}-${targetsIndex}`,
      source: `${inputGroupIndex}`,
      target: `${targetsIndex}`,
      targetHandle: String(handleIndex),
      ...DEFAULT_ARROW_CONFIG,
      animated: isPassiveInputsActive,
    })

    inputGroupIndex += 1
    handleIndex += 1

    /** EXTERNAL_INPUTS */
    nodesList.push({
      id: String(inputGroupIndex),
      type: NODE_TYPES.GENERIC_INPUTS_GROUP,
      position: { x: 0, y: 0 },
      data: {
        genericInputs,
        numOfNodes: genericInputs.length,
      },
    })

    /** GROUPING_ATTRIBUTES to EXTERNAL_INPUTS connectors */
    edgesList.push({
      id: `e${groupingIndex}-${inputGroupIndex}`,
      source: `${groupingIndex}`,
      sourceHandle: `source_${handleIndex}`,
      target: `${inputGroupIndex}`,
      ...DEFAULT_ARROW_CONFIG,
      animated: isGenericInputsActive,
    })

    /** EXTERNAL_INPUTS to TARGETS_GROUP connectors */
    edgesList.push({
      id: `e${inputGroupIndex}-${targetsIndex}`,
      source: `${inputGroupIndex}`,
      target: `${targetsIndex}`,
      targetHandle: String(handleIndex),
      ...DEFAULT_ARROW_CONFIG,
      animated: isGenericInputsActive,
    })

    inputGroupIndex += 1
    handleIndex += 1

    /** TARGETS_GROUP */
    nodesList.push({
      id: String(targetsIndex),
      type: NODE_TYPES.TARGETS_GROUP,
      position: { x: 0, y: 0 },
      data: {
        numOfNodes: outputParameters.length,
        numOfInputTypes,
      },
    })

    draftState.connectOverviewNodes = [
      ...nodesList,
    ]
    draftState.connectOverviewEdges = [
      ...edgesList,
    ]
  })

  return nextState
}
