import React from 'react'
import { DialogContent } from '@mui/material'
import { DEFAULT_PADDING } from '@constants/ui.constants'

export interface DialogContentComponentProps {
  /**
   * Children to be rendered
   */
  children?: React.ReactNode
}

export const DialogContentComponent: React.FC<DialogContentComponentProps> = ({
  children,
  ...props
}) => {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    px: DEFAULT_PADDING.LARGE,
    pt: 0,
    pb: DEFAULT_PADDING.LARGE,
  }

  return (
    <DialogContent
      {...props}
      sx={styles}
      data-testid={DialogContentComponent.name}
    >
      {children}
    </DialogContent>
  )
}

export default DialogContentComponent
