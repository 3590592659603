import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import DeleteIcon from '@icons/delete.icon'
import ButtonComponent from '@base/buttons/Button'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'

export interface TrainingModalRemovalBlockComponentProps {
  /**
   * if true - user is admin
   */
  isAdmin: boolean,
  /**
   * if true - form is submitting
   */
  isSubmitting: boolean,
  /**
   * if true - file is uploading
   */
  isUploading: boolean,
  /**
   * Remove version handler
   */
  handleRemoveVersion: () => void,
}

const TrainingModalRemovalBlockComponent: React.FC<TrainingModalRemovalBlockComponentProps> = ({
  isAdmin,
  isSubmitting,
  isUploading,
  handleRemoveVersion,
}) => {
  const intl = useIntl()
  const [removeVersionDialogState, setRemoveVersionDialogState] = useState(false)

  const {
    params: {
      version,
    },
  } = useRouteMatch<Common.RouterMatch>()

  if (!isAdmin) {
    return null
  }

  return (
    <Box
      data-testid={TrainingModalRemovalBlockComponent.name}
      display='flex'
      flexDirection='row'
      justifyContent='flex-start'
    >
      <ButtonComponent
        color='tertiary'
        name='removeVersionButton'
        StartIconComponent={DeleteIcon}
        label={intl.formatMessage({ id: 'connect.data_input.modal.versions.remove' })}
        onClick={() => setRemoveVersionDialogState(true)}
        disabled={isSubmitting || isUploading}
      />

      <TextConfirmationDialogComponent
        open={removeVersionDialogState}
        onClose={() => setRemoveVersionDialogState(false)}
        onSubmit={() => handleRemoveVersion()}
        confirmationText={version}
        confirmationInputLabel={intl.formatMessage({ id: 'connect.block.data_input.table.dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'connect.block.data_input.table.dialog.content' }, {
            version: <Box component='strong'>{version}</Box>,
          })
        }
      />
    </Box>
  )
}

export default TrainingModalRemovalBlockComponent
