import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import LeafIcon from '@icons/leaf.icon'

import InfoButtonComponent from '@base/buttons/InfoButton'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DEFAULT_PADDING } from '@constants/ui.constants'
import UseCaseListItemBlockComponent from '@components/dashboard/UseCaseListItemBlock'

export interface PotentialCoSavingsComponentProps {
  /**
   * Use case details
   */
  useCase: UseCase.DetailsExtended,
}

const PotentialCoSavingsComponent: React.FC<PotentialCoSavingsComponentProps> = ({ useCase }) => {
  const intl = useIntl()

  if (!useCase.forecastValue || !useCase.forecastParameters || !useCase.forecastValue.co2SavingsYearly || !useCase.forecastParameters.displayForecastValue) {
    return null
  }

  return (
    <UseCaseListItemBlockComponent
      title={intl.formatMessage({ id: 'use_cases.forecast.coSavings' })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: DEFAULT_PADDING.SMALL,
        }}
      >
        <LeafIcon />

        <Typography
          variant='body1'
          noWrap={true}
        >
          {intl.formatMessage({ id: 'use_cases.forecast.coSavingsValue' }, {
            num: defaultNumberFormatter(useCase.forecastValue?.co2SavingsYearly || 1000),
          })}
        </Typography>

        <InfoButtonComponent
          name='coSavingsHelp'
          tooltip={intl.formatMessage({ id: 'use_cases.forecast.coSavingsHelp' })}
        />
      </Box>
    </UseCaseListItemBlockComponent>
  )
}

export default PotentialCoSavingsComponent
