import React, { useEffect } from 'react'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

const OAuthContainer = () => {
  useEffect(() => {
    const params = window.location.search
    if (window.opener) {
      window.opener.postMessage(params, '*')
    }

    window.close()
  })

  return (
    <LoadingFallbackComponent />
  )
}

export default OAuthContainer
