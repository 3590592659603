import React, {
  ChangeEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { ARTIFACT_TYPES } from '@constants/use-cases.constants'

import PageLayoutContainer from '@containers/application/PageLayout'
import ExportOverviewComponent from '@containers/pages/(mixed-usecase)/export/export-overview'
import FileBrowserItemPreviewDialog from '@components/file-browser/FileBrowserItemPreviewDialog'
import UploadSmallIcon from '@icons/uploadSmall.icon'
import ActionButtonComponent from '@base/pagebar/ActionButton'

import {
  getUseCaseItem,
  isFetchingUseCase,
} from '@redux/modules/use-case/use-case.selectors'

import {
  getUseCaseArtifacts,
  isUploadingArtifacts,
  isFetchingArtifacts,
} from '@redux/modules/artifacts/artifacts.selectors'

import {
  requestArtifactListAction,
  uploadArtifactAction,
  removeArtifactAction,
  downloadArtifactAction,
} from '@redux/modules/artifacts/artifacts.actions'

import { requestFilePreviewAction } from '@redux/modules/file-service/file-service.actions'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { FILE_PREVIEW_TYPE } from '@constants/files.constants'

const ExportContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()

  const filesList = useSelector((state) => getUseCaseArtifacts(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isUploading = useSelector((state) => isUploadingArtifacts(state))
  const selectedCompanyId = useSelector((state) => getSelectedCompanyId(state))
  const useCase = useSelector((state) => getUseCaseItem(state))
  const isFetchingArtifactsFlag = useSelector((state) => isFetchingArtifacts(state))
  const isFetchingUseCaseFlag = useSelector((state) => isFetchingUseCase(state))

  const isFetching = isFetchingArtifactsFlag || isFetchingUseCaseFlag
  const inputFileRef: React.MutableRefObject<any> = useRef(null)
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId,
      }),
    )

    dispatch(
      requestArtifactListAction({
        useCaseId,
        artifactType: ARTIFACT_TYPES.PREDICTION,
      }),
    )
  }, [dispatch, useCaseId, selectedCompanyId])

  const handleUpload = useCallback(() => {
    inputFileRef.current.click()
  }, [])

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const file: File | null = files ? files[0] : null

    if (!file) {
      return
    }

    const data = new FormData()

    data.append('artifactFile', file)
    data.append('useCaseId', useCaseId)
    data.append('artifactType', ARTIFACT_TYPES.PREDICTION)

    dispatch(uploadArtifactAction({
      data,
      meta: {
        useCaseId,
        fileName: file?.name,
        fetchList: true,
        showToast: true,
      },
    }))

    e.target.value = ''
  }

  const handleFileDownload = useCallback((record: Artifacts.ArtifactItem) => {
    const {
      fileName,
      artifactId,
    } = record

    dispatch(downloadArtifactAction({
      useCaseId,
      fileName,
      artifactId,
    }))
  }, [dispatch, useCaseId])

  const handleFileDelete = useCallback((record: Artifacts.ArtifactItem) => {
    const { artifactId } = record

    dispatch(removeArtifactAction({
      useCaseId,
      artifactId,
      fetchList: true,
      showToast: true,
    }))
  }, [dispatch, useCaseId])

  const handleFilePreview = useCallback((record: Artifacts.ArtifactItem) => {
    dispatch(
      requestFilePreviewAction({
        fileName: record.fileName,
        filePath: record.artifactId,
        fileType: FILE_PREVIEW_TYPE.OUTPUT_FILES,
        isEditable: false,
      }),
    )
  }, [dispatch])

  const pageBarRightSideBlocks = useMemo(() => {
    if (!isAdmin) {
      return null
    }

    return (
      <ActionButtonComponent
        name='uploadArtifact'
        onClick={handleUpload}
        IconComponent={UploadSmallIcon}
        disabled={isUploading}
        label={intl.formatMessage({ id: 'common.button.upload_artifact' })}
      />
    )
  }, [isAdmin, intl, isUploading, handleUpload])

  return (
    <PageLayoutContainer
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      title={intl.formatMessage({ id: 'export.browser_tab.title' }, { name: useCase.name })}
    >
      <ExportOverviewComponent
        list={filesList}
        isFetching={isFetching}
        handleFileDownload={handleFileDownload}
        handleFileDelete={handleFileDelete}
        handleFilePreview={handleFilePreview}
      />

      <FileBrowserItemPreviewDialog />

      <input ref={inputFileRef} onChange={onFileChange} type='file' id='uploadInput' style={{ display: 'none' }} />
    </PageLayoutContainer>
  )
}

export default ExportContainer
