import React from 'react'

const WidgetIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={WidgetIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.4285 11.8792C16.1386 11.8792 16.7142 12.4548 16.7142 13.1649V15.0934C16.7142 15.8035 16.1386 16.3791 15.4285 16.3791H2.57136C1.86127 16.3791 1.28564 15.8035 1.28564 15.0934V13.1649C1.28564 12.4548 1.86128 11.8792 2.57136 11.8792H15.4285Z"  stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.99997 0.950684C9.71004 0.950684 10.2857 1.52632 10.2857 2.2364V8.02211C10.2857 8.73219 9.71004 9.30783 8.99997 9.30783H2.48347C1.77339 9.30783 1.19776 8.73219 1.19776 8.02211L1.19775 2.2364C1.19775 1.52631 1.77339 0.950684 2.48347 0.950684H8.99997Z"  stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.0311 0.950684C16.3862 0.950684 16.674 1.2385 16.674 1.59354V3.20068C16.674 3.55572 16.3862 3.84354 16.0311 3.84354H13.4999C13.1449 3.84354 12.8571 3.55572 12.8571 3.20068V1.59354C12.8571 1.2385 13.1449 0.950684 13.4999 0.950684H16.0311Z"  stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.0311 6.41504C16.3862 6.41504 16.674 6.70286 16.674 7.0579V8.66504C16.674 9.02008 16.3862 9.3079 16.0311 9.3079H13.4999C13.1449 9.3079 12.8571 9.02008 12.8571 8.66504V7.0579C12.8571 6.70286 13.1449 6.41504 13.4999 6.41504H16.0311Z"  stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default WidgetIcon
