import React from 'react'

const CloneIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={CloneIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g clipPath="url(#clip0_8189_2613)">
        <path d="M17.4286 16.2143C17.4286 16.5553 17.2931 16.8823 17.052 17.1234C16.8109 17.3645 16.4838 17.5 16.1429 17.5H7.78571C7.44472 17.5 7.1177 17.3645 6.87658 17.1234C6.63546 16.8823 6.5 16.5553 6.5 16.2143V5.28571C6.5 4.94472 6.63546 4.6177 6.87658 4.37658C7.1177 4.13546 7.44472 4 7.78571 4H13.5714L17.4286 7.85714V16.2143Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.26023 14H1.78571C1.44472 14 1.1177 13.8645 0.876577 13.6234C0.635459 13.3823 0.5 13.0553 0.5 12.7143V1.78571C0.5 1.44472 0.635459 1.1177 0.876577 0.876577C1.1177 0.635459 1.44472 0.5 1.78571 0.5H7.57143L10.9784 3.90698" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.9648 8.5V13" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.71484 10.75H14.2148" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_8189_2613">
          <rect width={width} height={height} fill="white"/>
        </clipPath>
      </defs>

    </svg>
  )
}

export default CloneIcon
