import React from 'react'

const GAWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={GAWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M17.2679 15.7483C17.2746 16.9842 16.2778 17.9906 15.0427 17.9972C14.9508 17.998 14.8583 17.9928 14.7672 17.9817C13.6209 17.8121 12.7811 16.8146 12.8093 15.6565V2.3406C12.7819 1.18095 13.6238 0.183485 14.7709 0.015389C15.9979 -0.12827 17.1094 0.749977 17.2531 1.97701C17.2635 2.06735 17.2686 2.15769 17.2679 2.24877V15.7483Z" fill="white" fillOpacity="0.4"/>
      <path d="M3.22968 13.5406C4.46115 13.5406 5.45936 14.5388 5.45936 15.7703C5.45936 17.0018 4.46115 18 3.22968 18C1.99821 18 1 17.0018 1 15.7703C1 14.5388 1.99821 13.5406 3.22968 13.5406ZM9.09602 6.78346C7.85862 6.85158 6.89892 7.88904 6.9278 9.12792V15.1164C6.9278 16.7419 7.64313 17.7282 8.69096 17.9385C9.90022 18.1836 11.0784 17.4024 11.3235 16.1931C11.3538 16.045 11.3687 15.8947 11.3679 15.7437V9.02425C11.3701 7.78833 10.3704 6.78494 9.13453 6.78271C9.12194 6.78271 9.10861 6.78272 9.09602 6.78346Z" fill="white"/>
    </svg>
  )
}

export default GAWhiteIcon
