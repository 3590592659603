export const AVATAR_WIDTH = 44
export const AVATAR_HEIGHT = 44
export const AVATAR_MARGIN = 20
export const MESSAGE_PADDING = 20
export const MESSAGE_MARGIN_SIDE = 40
export const MESSAGE_MARGIN_BOT = 20
export const CHAT_WIDTH = 850
export const MIN_CHAT_WIDTH = 350
export const MAX_CHAT_WIDTH = 850
export const CHAT_SCROLL_BOTTOM_OFFSET = 500
export const CHAT_HEADER_HEIGHT = 40
export const CHAT_HEADER_MARGIN = 20
export const CHAT_HEADER_FULL_HEIGHT = CHAT_HEADER_HEIGHT + CHAT_HEADER_MARGIN

export enum CHAT_ROLES {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export enum CHAT_ROUTES_TYPES {
  DEFAULT = 'DEFAULT',
  RECOMMENDATION = 'RECOMMENDATION',
  DATA = 'DATA',
  INSIGHTS = 'INSIGHTS',
}
