import React, { useMemo } from 'react'

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from '@mui/x-data-grid-premium'

import {
  DEFAULT_ACTIONS_COL_WIDTH, DEFAULT_BOOLEAN_COL_MIN_WIDTH,
  DEFAULT_COL_MIN_WIDTH, DEFAULT_COL_WIDTH,
} from '@constants/data-grid.constants'

export interface useDataGridTransformedColumnsProps {
  columns?: Record<string, string | Function>
}

export const useDataGridTransformedColumns = ({
  columns,
  useCheckboxSelection = false,
  rowSelectionMode = 'client',
  disableMultipleRowSelection = false,
  DataGridHeaderCheckboxComponent = undefined,
}: {
  columns: readonly GridColDef<any>[],
  useCheckboxSelection?: boolean,
  rowSelectionMode?: 'server' | 'client',
  disableMultipleRowSelection?: boolean,
  DataGridHeaderCheckboxComponent?: React.FC<any>,
}) => {
  return useMemo(() => {
    const finalColumns = []

    columns.forEach((column) => {
      const colCopy = { ...column }

      if (colCopy.type === 'boolean') {
        colCopy.availableAggregationFunctions = ['booleanAggregation']
        colCopy.minWidth = colCopy.minWidth || DEFAULT_BOOLEAN_COL_MIN_WIDTH
      }

      if (colCopy.type === 'actions') {
        colCopy.aggregable = false
        colCopy.sortable = false
        colCopy.filterable = false
        colCopy.resizable = false
        colCopy.width = colCopy.width || DEFAULT_ACTIONS_COL_WIDTH
        colCopy.minWidth = colCopy.width
      }

      finalColumns.push({
        ...colCopy,
        width: colCopy.width || DEFAULT_COL_WIDTH,
        minWidth: colCopy.minWidth || DEFAULT_COL_MIN_WIDTH,
        aggregable: colCopy.aggregable !== false,
        sortable: colCopy.sortable !== false,
        filterable: colCopy.filterable !== false,
        resizable: colCopy.resizable !== false,
        pinnable: colCopy.pinnable !== false,
      } as GridColDef)
    })

    if (useCheckboxSelection) {
      const checkboxCol = {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        hideable: false,
      }

      if (disableMultipleRowSelection) {
        checkboxCol.renderHeader = () => null
      } else if (rowSelectionMode === 'server' && DataGridHeaderCheckboxComponent) {
        checkboxCol.renderHeader = (params) => (
          <DataGridHeaderCheckboxComponent
            {...params}
          />
        )
      } else if (rowSelectionMode === 'server') {
        checkboxCol.renderHeader = () => null
      }

      finalColumns.unshift(checkboxCol)
    }

    return finalColumns
  }, [
    columns,
    useCheckboxSelection,
    rowSelectionMode,
    disableMultipleRowSelection,
    DataGridHeaderCheckboxComponent,
  ])
}
