import React from 'react'

const PlayIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={PlayIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>
      {/* eslint-disable */}
      <path d="M1.92859 15.8786C1.93321 16.1465 2.01115 16.4079 2.15396 16.6346C2.29676 16.8613 2.49896 17.0445 2.73859 17.1643C2.97787 17.2995 3.24804 17.3706 3.52287 17.3706C3.79771 17.3706 4.06788 17.2995 4.30716 17.1643L15.4286 10.2857C15.6706 10.1691 15.8747 9.98665 16.0176 9.75921C16.1605 9.53176 16.2363 9.26862 16.2363 9.00002C16.2363 8.73141 16.1605 8.46827 16.0176 8.24082C15.8747 8.01338 15.6706 7.83088 15.4286 7.7143L4.30716 0.887158C4.06788 0.751957 3.79771 0.680908 3.52287 0.680908C3.24804 0.680908 2.97787 0.751957 2.73859 0.887158C2.49896 1.00697 2.29676 1.19017 2.15396 1.41685C2.01115 1.64352 1.93321 1.905 1.92859 2.17287V15.8786Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      {/* eslint-enable */}
    </svg>
  )
}

export default PlayIcon
