import React, {
  useMemo,
} from 'react'

import { Box } from '@mui/material'

export interface FileBrowserPreviewImageComponentProps {
  /**
   * File preview data
   */
  filePreview: FileService.FilePreview
}

const FileBrowserPreviewImageComponent: React.FC<FileBrowserPreviewImageComponentProps> = ({
  filePreview,
}) => {
  const imageSrc = useMemo(() => {
    if (!filePreview) {
      return null
    }

    const imageBlob = new Blob([filePreview.file], { type: filePreview.contentType })

    return URL.createObjectURL(imageBlob)
  }, [filePreview])

  if (!filePreview || !imageSrc) {
    return null
  }

  return (
    <Box
      data-testid={FileBrowserPreviewImageComponent.name}
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      maxHeight='100%'
      maxWidth='100%'
      p={2}
    >
      <img
        src={imageSrc}
        alt={filePreview.fileName}
        width='auto'
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          border: '4px solid #ccc',
          borderRadius: '4px',
        }}
      />
    </Box>
  )
}

export default FileBrowserPreviewImageComponent
