import { AddCompanyUsersPayload } from '@redux/modules/customer/customer.types'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

interface AddCompanyUsersPayloadValidation extends Omit<AddCompanyUsersPayload, 'companyId' | 'language'> {}

const companyUsersValidations = (intl: IntlShape) => {
  const scheme: Yup.SchemaOf<AddCompanyUsersPayloadValidation> = Yup.object().shape({
    firstName: Yup.string().min(1).max(255).required(intl.formatMessage({ id: 'customers.users.form.firstName.required' })),
    lastName: Yup.string().min(1).max(255).required(intl.formatMessage({ id: 'customers.users.form.lastName.required' })),
    email: Yup.string().email().required(intl.formatMessage({ id: 'customers.users.form.email.required' })),
  })

  return scheme
}

export default companyUsersValidations
