import React from 'react'

const DownloadIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DownloadIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M0.644531 13.5V14.7857C0.644531 15.4677 0.915449 16.1218 1.39769 16.604C1.87992 17.0862 2.53397 17.3571 3.21596 17.3571H14.7874C15.4694 17.3571 16.1234 17.0862 16.6057 16.604C17.0879 16.1218 17.3588 15.4677 17.3588 14.7857V13.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.14453 7.71436L9.00167 12.2144L12.8588 7.71436" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 12.2145V0.643066" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default DownloadIcon
