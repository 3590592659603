import React from 'react'

const LogOutIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={LogOutIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M8.35626 8.99989H17.3563M17.3563 8.99989L14.7848 6.42846M17.3563 8.99989L14.7848 11.5713M15.042 14.7727C13.8875 15.9761 12.3993 16.8066 10.769 17.1573C9.13865 17.508 7.44066 17.3629 5.89344 16.7407C4.34622 16.1186 3.02052 15.0477 2.08687 13.6659C1.15321 12.2842 0.654297 10.6547 0.654297 8.98703C0.654297 7.31939 1.15321 5.68989 2.08687 4.30812C3.02052 2.92635 4.34622 1.85551 5.89344 1.23332C7.44066 0.61114 9.13865 0.466075 10.769 0.816786C12.3993 1.1675 13.8875 1.99795 15.042 3.20131" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LogOutIcon
