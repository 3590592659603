import React from 'react'

const AlertErrorIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#000000',
}) => {
  return (
    <svg data-testid={AlertErrorIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M9 4.5V8.35714M17.3571 9C17.3571 13.6155 13.6155 17.3571 9 17.3571C4.38447 17.3571 0.642853 13.6155 0.642853 9C0.642853 4.38448 4.38447 0.642858 9 0.642858C13.6155 0.642858 17.3571 4.38448 17.3571 9ZM9.64285 12.2143C9.64285 12.5693 9.35504 12.8571 9 12.8571C8.64496 12.8571 8.35714 12.5693 8.35714 12.2143C8.35714 11.8592 8.64496 11.5714 9 11.5714C9.35504 11.5714 9.64285 11.8592 9.64285 12.2143Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AlertErrorIcon
