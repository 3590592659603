import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'
import type Keycloak from 'keycloak-js'
import { PostHog } from 'posthog-js/react'
import posthog, { PostHogConfig } from 'posthog-js'
import { matchPath } from 'react-router-dom'

import * as ROUTES from '@constants/routes.constants'

import {
  ENV_TYPE, IS_DEV_ENV,
  IS_LOCAL_ENV, SENTRY_DNS,
} from '@constants/common.constants'

import {
  KEYCLOAK_ADMIN_ROLE,
  COCKPIT_GA_ID,
  COCKPIT_GTM_ID,
  COCKPIT_POSTHOG_KEY,
  COCKPIT_POSTHOG_HOST,
} from '@constants/env-replacements.constants'

export const TRACKING_APP_NAME = 'ui'
export const TRACKING_ROUTES = ROUTES.ROUTES
export const TRACKING_ROUTES_KEYS = Object.keys(TRACKING_ROUTES)
export const TRACKING_MODULES = {
  [ROUTES.ROOT_PAGE_NAME]: 'root',
  [ROUTES.USERS_PAGE_NAME]: 'users',
  [ROUTES.CONNECTORS_PAGE_NAME]: 'connectors',
  [ROUTES.PIPELINES_PAGE_NAME]: 'pipelines',
  [ROUTES.DASHBOARD_PAGE_NAME]: 'dashboard',
  [ROUTES.COMPANIES_PAGE_NAME]: 'companies',
  [ROUTES.OAUTH_CALLBACK_PAGE_NAME]: 'oauth',
  [ROUTES.NOT_FOUND_PAGE_NAME]: 'notFound',

  [ROUTES.OPTIMIZE_PAGE_NAME]: 'optimize',
  [ROUTES.OPTIMIZE_DRILL_DOWN_PAGE_NAME]: 'optimizeDrillDown',
  [ROUTES.TRADE_OFFS_PAGE_NAME]: 'tradeOffs',
  [ROUTES.FILE_MANAGER_PAGE_NAME]: 'fileManager',
  [ROUTES.FILE_MANAGER_FOLDER_PAGE_NAME]: 'fileManagerFolder',
  [ROUTES.SNAPSHOTS_PAGE_NAME]: 'snapshots',
  [ROUTES.SNAPSHOT_DETAILS_PAGE_NAME]: 'snapshotDetails',
  [ROUTES.TRAINING_DATA_OVERVIEW_PAGE_NAME]: 'trainingDataOverview',
  [ROUTES.ANALYZE_PAGE_NAME]: 'analyze',
  [ROUTES.MONITOR_PAGE_NAME]: 'monitor',
  [ROUTES.MONITOR_BACKTESTING_PAGE_NAME]: 'monitorBacktesting',
  [ROUTES.MONITOR_LIVE_MONITORING_PAGE_NAME]: 'monitorLiveMonitoring',
  [ROUTES.EXPORT_PAGE_NAME]: 'export',
  [ROUTES.CONNECT_PAGE_NAME]: 'connect',
  [ROUTES.DATA_UPLOAD_PAGE_NAME]: 'dataUpload',
  [ROUTES.DATA_FILE_LINK_PAGE_NAME]: 'dataFileLink',
  [ROUTES.USE_CASE_PIPELINES_PAGE_NAME]: 'useCasePipelines',
  [ROUTES.PIPELINE_OVERVIEW_PAGE_NAME]: 'pipelineOverview',

  [ROUTES.ARTICLE_ALLOCATION_SETUP_PAGE_NAME]: 'aaSetup',
  [ROUTES.ARTICLE_ALLOCATION_RESULTS_PAGE_NAME]: 'aaResults',
  [ROUTES.ARTICLE_ALLOCATION_EXPORT_PAGE_NAME]: 'aaExport',
  [ROUTES.ARTICLE_ALLOCATION_ANALYZE_PAGE_NAME]: 'aaAnalyze',

  [ROUTES.REPLENISHMENT_SETUP_PAGE_NAME]: 'rpConnect',
  [ROUTES.REPLENISHMENT_RESULTS_PAGE_NAME]: 'rpResults',
  [ROUTES.REPLENISHMENT_EXPORT_PAGE_NAME]: 'rpExport',
  [ROUTES.REPLENISHMENT_ANALYZE_PAGE_NAME]: 'rpAnalyze',
  [ROUTES.REPLENISHMENT_DISCOVER_PAGE_NAME]: 'rpDiscover',

  SIDE_BAR: 'sideBar',
  TOP_BAR: 'topBar',
  UNKNOWN: 'unknown',
} as const

export enum TRACKING_ACTIONS {
  CLICK = 'click',
  CHANGE = 'change',
  SELECT = 'select',
  SEARCH = 'search',
  NAVIGATE = 'navigate',
  DOWNLOAD = 'download',
  UPLOAD = 'upload',
  DELETE = 'delete',
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
  CANCEL = 'cancel',
  SUBMIT = 'submit',
  UNKNOWN = 'unknown',

  /** Data Grid */
  PAGINATION = 'pagination',
  SORTING = 'sorting',
  FILTERING = 'filtering',
  GROUPING = 'grouping',
  AGGREGATION = 'aggregation',
  ROW_SELECTION = 'rowSelection',
  COL_VISIBILITY = 'columnVisibility',
  COL_PINNING = 'columnPinning',
  DETAILS_TOGGLE = 'detailsToggle',
  COPY = 'copy',
}

export type TRACKING_MODULES_KEYS = keyof typeof TRACKING_MODULES
export type TRACKING_MODULES_VALUES = typeof TRACKING_MODULES[TRACKING_MODULES_KEYS]

export interface TrackingEventNameProps {
  moduleName?: TRACKING_MODULES_VALUES
  componentName: string,
  actionName: TRACKING_ACTIONS
}

export interface TrackingEventDetails {
  [key: string]: any
}

export interface TrackingDetailsProps {
  naming: Partial<TrackingEventNameProps>
  context?: TrackingEventDetails
}

export const emailRegex = /(\S+)@(\S+\.\S+)/g

/**
 * Process sentry event before sending
 *
 * @param event Sentry event
 * @param hint Sentry event hint
 * @returns Sentry event or null
 */
export const sentryBeforeSendProcessor = (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
  const error = hint?.originalException as any
  const message = error?.message || ''

  if (message.includes('ChunkLoadError') || message.includes('Loading chunk')) {
    return null
  }

  return event
}

/**
 * Process posthog text and replace email with masked text
 *
 * @param text Text to process
 * @returns Processed text
 */
export const postHogMaskTextProcessor = (text: string) => {
  return text.replace(emailRegex, (match, g1, g2) => {
    return `${'*'.repeat(g1.length)}@${'*'.repeat(g2.length)}`
  })
}

export const SENTRY_INIT_CONFIG = {
  dsn: SENTRY_DNS,
  environment: ENV_TYPE,
  normalizeDepth: 3,
  integrations: [SentryBrowser.browserTracingIntegration(), SentryBrowser.httpClientIntegration()],
  tracesSampleRate: 0.1,
  beforeSend: sentryBeforeSendProcessor,
}

export const POSTHOG_INIT_CONFIG = {
  api_host: COCKPIT_POSTHOG_HOST,
  disable_session_recording: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
      email: true,
    },
    maskTextSelector: '*',
    maskTextFn: postHogMaskTextProcessor,
  },
} as Partial<PostHogConfig>

/**
 * Initialize posthog user tracking
 *
 * @param posthogInstance Posthog instance
 * @param userDetails user details
 * @returns void
 */
export const initPosthogUserTracking = (posthogInstance: PostHog, {
  userId,
  email,
  language,
  companyId,
  isAdmin,
} : {
  userId: string,
  email: string,
  language: string
  companyId: string
  isAdmin: boolean
}) => {
  if (!posthogInstance) {
    return
  }

  posthogInstance.identify(userId, {
    language,
    isAdmin,
    companyId,
  }, {
    email,
    env: ENV_TYPE,
  })

  if (isAdmin) {
    posthogInstance.opt_out_capturing()
  } else {
    posthogInstance.opt_in_capturing()

    if (!IS_DEV_ENV) {
      posthogInstance.startSessionRecording()
    }
  }
}

/**
 * Initialize user tracking
 * @param keycloakInstance Keycloak instance
 * @param posthogInstance Posthog instance
 */
export const initUserTracking = (keycloakInstance: Keycloak, posthogInstance: PostHog) => {
  const keycloakUserId = keycloakInstance?.tokenParsed?.sub || ''
  const keycloakUserEmail = keycloakInstance?.tokenParsed?.email || ''
  const companyId = keycloakInstance?.tokenParsed?.companyId || ''
  const language = keycloakInstance?.tokenParsed?.locale
  const roles = keycloakInstance?.realmAccess?.roles || []
  const isAdmin = roles.includes(KEYCLOAK_ADMIN_ROLE)

  const trackingPayload = {
    userId: keycloakUserId,
    userCompany: companyId,
  }

  TagManager.initialize({
    gtmId: COCKPIT_GTM_ID,
    dataLayer: trackingPayload,
  })

  ReactGA.initialize(COCKPIT_GA_ID, {
    gaOptions: trackingPayload,
  })

  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: document?.title,
  })

  Sentry.setUser({
    email: keycloakUserEmail,
    userId: keycloakUserId,
    companyId,
    language,
    isAdmin,
  })

  initPosthogUserTracking(posthogInstance, {
    userId: keycloakUserId,
    email: keycloakUserEmail,
    language,
    companyId,
    isAdmin,
  })
}

/**
 * Initialize tracking
 */
export const initTracking = () => {
  if (!IS_LOCAL_ENV) {
    /* Init Sentry */
    Sentry.init(SENTRY_INIT_CONFIG)

    /** Init posthog */
    posthog.init(
      COCKPIT_POSTHOG_KEY,
      POSTHOG_INIT_CONFIG,
    )
  }
}

/**
 * Get tracking module name
 * @returns tracking module name
 */
export const getTrackingModuleName = (moduleName?: string) => {
  if (moduleName) {
    return moduleName
  }

  const routerMatch = TRACKING_ROUTES_KEYS.find((key: string) => {
    if (!TRACKING_ROUTES[key]) {
      return false
    }

    return matchPath<Common.RouterMatch>(document.location.pathname, {
      path: TRACKING_ROUTES[key],
      exact: true,
      strict: true,
    })
  }) as keyof typeof TRACKING_MODULES

  if (routerMatch) {
    return TRACKING_MODULES[routerMatch]
  }

  return TRACKING_MODULES.UNKNOWN
}

/**
 * Generates tracking event name
 *
 * @param applicationName Application
 * @param moduleName Module
 * @param componentName Component
 *
 * @returns event name
 */
export const generateTrackingEventName = ({
  moduleName,
  componentName,
  actionName,
} : TrackingEventNameProps) => {
  return `${TRACKING_APP_NAME}:${getTrackingModuleName(moduleName)}:${componentName}:${actionName}`
}

/**
 * Track event
 *
 * @param eventNameProps Event name properties
 * @param eventDetails Event details
 */
export const trackEvent = (eventNameProps: TrackingEventNameProps, eventDetails?: TrackingEventDetails) => {
  const eventName = generateTrackingEventName(eventNameProps)

  if (IS_LOCAL_ENV || IS_DEV_ENV) {
    // eslint-disable-next-line
    console.log('Tracking log: %s', eventName, eventDetails)
  }

  if (posthog) {
    posthog.capture(
      eventName,
      eventDetails,
    )
  }
}
