import React from 'react'

import {
  useTheme,
  CardContent,
} from '@mui/material'

import { SIDE_PANEL_FOOTER_HEIGTH, SIDE_PANEL_HEADER_HEIGTH } from '@constants/ui.constants'

export interface SidePanelCardProps {
  /**
   * content of the card
   */
  children: React.ReactNode,
  /**
   * if true, horizontal padding is disabled
   */
  disableHorizontalSpacing?: boolean,
}

export const SidePanelCardComponent: React.FC<SidePanelCardProps> = ({
  children,
  disableHorizontalSpacing,
}) => {
  const theme = useTheme()

  const styles = {
    padding: disableHorizontalSpacing ? 0 : theme.spacing(4),
    height: `calc(100vh - ${SIDE_PANEL_FOOTER_HEIGTH + SIDE_PANEL_HEADER_HEIGTH}px)`,
    overflow: 'auto',
  }

  return (
    <CardContent
      data-testid={SidePanelCardComponent.name}
      sx={styles}
    >
      {children}
    </CardContent>
  )
}

export default SidePanelCardComponent
