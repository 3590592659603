import React, { useEffect, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import dagre from 'dagre'

import {
  ReactFlow, Node, Edge,
  NodeTypes, FitViewOptions,
  ReactFlowInstance,
} from '@xyflow/react'

import ReactFlowControlsComponent from '@components/connect-view/flow/ReactFlowControls'
import ReactFlowCustomEdgeComponent from '@components/connect-view/flow/ReactFlowCustomEdge'

import { CONNECT_PAGE_DEFAULT_HEIGHT } from '@constants/ui.constants'
import { getLayoutedElements } from '@utils/flow.utils'

import '@xyflow/react/dist/style.css'
import '@xyflow/react/dist/base.css'

const edgeTypes = {
  custom: ReactFlowCustomEdgeComponent,
}

const dagreGraph = new dagre.graphlib.Graph()

dagreGraph.setDefaultEdgeLabel(() => ({}))

function getLayoutedNodesAndEdges<T extends Common.ReactFlowElementData>(nodes: Node<T>[], edges: Edge[]) {
  return getLayoutedElements<T>(
    dagre,
    dagreGraph,
    nodes,
    edges,
  )
}

export interface ReactFlowComponentProps<T extends Common.ReactFlowElementData> {
  /**
   * Nodes to be displayed
   */
  nodes: Node<T, any>[]
  /**
   * Edges to be displayed
   */
  edges: Edge[]
  /**
   * Node to type mapping
   */
  nodeTypes?: NodeTypes
  /**
   * Flag to indicate if data is being fetched
   */
  isFetching?: boolean
  /**
   * Options to fit view
   */
  fitViewOptions?: FitViewOptions
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>
}

const ReactFlowComponent = <T extends Common.ReactFlowElementData>({
  sx,
  nodes = [],
  edges = [],
  nodeTypes = {},
  isFetching,
  fitViewOptions,
}: ReactFlowComponentProps<T>) => {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedNodesAndEdges(
    nodes,
    edges,
  )

  const [instance, setInstance] = useState<null | ReactFlowInstance>(null)

  useEffect(() => {
    if (!isFetching && instance) {
      setTimeout(() => instance?.fitView(fitViewOptions), 100)
    }
  }, [nodes, edges, instance, fitViewOptions, isFetching])

  return (
    <Box
      sx={{
        height: CONNECT_PAGE_DEFAULT_HEIGHT,
        ...sx,
      }}
      data-testid={ReactFlowComponent.name}
    >
      <ReactFlow
        nodes={layoutedNodes}
        edges={layoutedEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes as any}
        proOptions={{ hideAttribution: true }}
        onInit={(reactFlowInstance: ReactFlowInstance<any>) => {
          setInstance(reactFlowInstance)
        }}
      >
        <ReactFlowControlsComponent
          zoomIn={instance?.zoomIn}
          zoomOut={instance?.zoomOut}
          fitView={instance?.fitView}
        />
      </ReactFlow>
    </Box>
  )
}

export default ReactFlowComponent
