import { CreateCompanyActionPayload } from '@redux/modules/customer/customer.types'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const companyValidations = (intl: IntlShape) => {
  const scheme: Yup.SchemaOf<CreateCompanyActionPayload> = Yup.object().shape({
    name: Yup.string().min(1).max(255).required(intl.formatMessage({ id: 'companies.form.name.required' })),
  })

  return scheme
}

export default companyValidations
