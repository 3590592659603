import produce from 'immer'
import { ReducerPayload, SnapshotsState } from '@redux/modules/types'

export const initialState: SnapshotsState = {
  snapshotsItem: null,
  snapshotsList: [],
  fetchingKeys: [],
}

export const receiveSnapshotsReducer = (state: SnapshotsState, action: ReducerPayload<Snapshots.SnapshotItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.snapshotsList = action.payload
  })

  return nextState
}

export const receiveSnapshotReducer = (state: SnapshotsState, action: ReducerPayload<Snapshots.SnapshotItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.snapshotsItem = action.payload
  })

  return nextState
}
