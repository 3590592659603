import React from 'react'
import { Box, Typography } from '@mui/material'
import InfoButtonComponent from '@base/buttons/InfoButton'

export interface DataGridTooltipCellComponentProps {
  tooltip?: string,
  label?: string,
}

export const DataGridTooltipCellComponent: React.FC<DataGridTooltipCellComponentProps> = ({
  label,
  tooltip,
}) => {
  if (!label && !tooltip) {
    return null
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      gap={1}
      data-testid={DataGridTooltipCellComponent.name}
    >
      {
        tooltip ? (
          <InfoButtonComponent
            name='rowTooltip'
            tooltip={tooltip}
            placement='top'
          />
        ) : (
          null
        )
      }
      {
        label ? (
          <Typography
            fontSize='14px'
          >
            {label}
          </Typography>
        ) : (
          null
        )
      }
    </Box>
  )
}

export default DataGridTooltipCellComponent
