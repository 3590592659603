import React from 'react'
import { Box, Typography } from '@mui/material'

export interface ViewTitleComponentProps {
  /**
   * Title to be displayed
   */
  title?: React.ReactNode
}

export const ViewTitleComponent: React.FC<ViewTitleComponentProps> = ({
  title,
}) => {
  return (
    <Box
      data-testid={ViewTitleComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 15px',
        border: '1px solid black',
        height: '36px',
        width: 'fit-content',
        borderRadius: '60px',
        flex: '1 1 100%',
        minWidth: 0,
      }}
    >
      <Typography
        align='left'
        variant='h5'
        noWrap={true}
        title={String(title)}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default ViewTitleComponent
