import React from 'react'

const ResetIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ResetIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M0.644531 8.99973C0.644531 10.6526 1.13467 12.2684 2.05296 13.6427C2.97126 15.017 4.27647 16.0882 5.80353 16.7207C7.3306 17.3533 9.01095 17.5188 10.6321 17.1963C12.2532 16.8738 13.7423 16.0779 14.9111 14.9091C16.0798 13.7404 16.8758 12.2513 17.1982 10.6301C17.5207 9.009 17.3552 7.32865 16.7227 5.80159C16.0901 4.27452 15.019 2.96931 13.6447 2.05102C12.2703 1.13272 10.6546 0.642584 9.00167 0.642584C6.60764 0.639934 4.30489 1.56104 2.5731 3.21401" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.21708 0.642578L2.57422 3.21401L5.14565 3.85686" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.00301 4.5V9.64286L5.66016 11.3143" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ResetIcon
