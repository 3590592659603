import React, {
  ChangeEvent, useMemo,
  useRef, useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import sortBy from 'lodash.sortby'

import {
  SOURCE_TYPES_OPTIONS,
  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES,
  SOURCE_TYPES_CATEGORIES,
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES_TO_DISABLED_ICONS_MAP,
  POSSIBLE_TEMPLATE_TYPES,
} from '@constants/flow.constants'

import {
  Popper, InputBase,
  Typography, Box,
  useTheme,
} from '@mui/material'

import Autocomplete from '@mui/material/Autocomplete'
import DropdownButtonComponent from '@base/dropdowns/DropdownButton'
import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'
import ParameterSelectorListBoxComponent from '@components/connect-view/parameters/ParameterSelectorListBox'

import { enrichSourceTypeOptions, SourceTypeOptionExtended } from '@utils/flow.utils'
import { DEFAULT_AGGREGATION_TYPE } from '@constants/date.constants'
import { getParametersTemplatesListWithConstraints } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getCompanyConnectionsBySource } from '@redux/modules/hermes/hermes.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'

export const SOURCE_TYPE_SELECTOR_ID = 'source-type-selector'
export const PREDEFINED_INPUT_SELECTOR_ID = 'predefined-input-selector'

export interface AutocompleteParameterTemplateSelectorFieldComponentProps {
  /**
   * Flag to indicate if the component is fetching data
   */
  isFetching?: boolean,
  /**
   * Flag to indicate if the component is disabled
   */
  disabled?: boolean,
  /**
   * Selected source type
   */
  selectedSourceType: SOURCE_TYPES,
  /**
   * Selected input template
   */
  selectedInputTemplate?: string,
  /**
   * Parameter template type
   */
  parameterTemplateType: POSSIBLE_TEMPLATE_TYPES,
  /**
   * Callback to handle source type change
   */
  sourceTypeChangeCb: {
    (e: ChangeEvent, selectedValue: any): any,
  }
  /**
   * Callback to handle predefined input change
   */
  predefinedInputChangeCb: {
    (e: ChangeEvent, selectedValue: any): any,
  }
}

const ParameterTemplateSelectorComponent: React.FC<AutocompleteParameterTemplateSelectorFieldComponentProps> = ({
  isFetching,
  sourceTypeChangeCb,
  predefinedInputChangeCb,
  disabled,
  parameterTemplateType,
  selectedSourceType,
  selectedInputTemplate,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<HTMLElement>(null)
  const inputWidth = `${containerWidth}px`

  const [anchorSourceTypeEl, setAnchorSourceTypeEl] = useState(null)
  const [anchorPredefinedInputEl, setAnchorPredefinedInputEl] = useState(null)
  const parametersTemplatesList = useSelector((state) => getParametersTemplatesListWithConstraints(state, selectedSourceType, parameterTemplateType))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state))
  const filesIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const fileVersionsCount = filesIdentifiers.length || 0
  const parametersTemplatesListOptions: UseCase.ParameterTemplateItem[] = [{
    name: intl.formatMessage({ id: 'connect.modal.parameter.predefined.custom' }),
    sourceType: '',
    unitLabel: '',
    description: '',
    dataColumnName: '',
    aggregationFunction: DEFAULT_AGGREGATION_TYPE,
    parameterPresetId: '',
    possiblePresetTypes: [
      POSSIBLE_TEMPLATE_TYPES.ACTIVE,
      POSSIBLE_TEMPLATE_TYPES.EXTERNAL,
      POSSIBLE_TEMPLATE_TYPES.PASSIVE,
      POSSIBLE_TEMPLATE_TYPES.TARGET,
    ],
    // @ts-ignore
  }].concat(parametersTemplatesList)

  const allowedOptions: SourceTypeOptionExtended[] = sortBy(SOURCE_TYPES_OPTIONS.filter((item) => {
    if (!item.forbiddenParameterTypes) {
      return true
    }

    return !item.forbiddenParameterTypes.includes(parameterTemplateType)
  }).map((item) => enrichSourceTypeOptions(item, connectionsList, fileVersionsCount, isAdmin)), (option) => {
    const category: SOURCE_TYPES_CATEGORIES = option.category
    return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
  })

  const sourceTypeValue = (SOURCE_TYPES_OPTIONS.find((item) => {
    return item.value === selectedSourceType
  }) || null) as SourceTypeOptionExtended

  const inputTemplateValue = parametersTemplatesList.find((item) => {
    return item.parameterPresetId === selectedInputTemplate
  }) || null

  const getSourceTypeOptionLabel = (option: SourceTypeOptionExtended) => {
    return option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
  }

  const getPredefinedInputOptionLabel = (option: UseCase.ParameterTemplateItem) => {
    return option.name || ''
  }

  const handleSourceTypeOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorSourceTypeEl(event.currentTarget)
  }

  const handlePredefinedInputOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorPredefinedInputEl(event.currentTarget)
  }

  const handleSourceTypeClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorSourceTypeEl(null)
    }
  }

  const handlePredefinedInputClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorPredefinedInputEl(null)
    }
  }

  const handleSourceTypeChange = (e: React.ChangeEvent<any>, newValue?: any, reason?: any) => {
    const selectedValue = newValue[newValue.length - 1]

    if (selectedValue) {
      sourceTypeChangeCb && sourceTypeChangeCb(e, selectedValue)
    }

    handleSourceTypeClose()
  }

  const handlePredefinedInputChange = (e: React.ChangeEvent<any>, newValue?: any, reason?: any) => {
    const selectedValue = newValue[newValue.length - 1]

    if (selectedValue) {
      predefinedInputChangeCb && predefinedInputChangeCb(e, selectedValue)
    }

    handlePredefinedInputClose()
  }

  const getSourceTypeButtonTitle = () => {
    if (sourceTypeValue) {
      return getSourceTypeOptionLabel(sourceTypeValue)
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
  }

  const getPredefinedInputButtonTitle = () => {
    if (inputTemplateValue) {
      return getPredefinedInputOptionLabel(inputTemplateValue)
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'connect.modal.parameter.predefined.empty_selection' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.predefined.empty_selection' })
  }

  const sourceTypeButtonTitle = getSourceTypeButtonTitle()
  const predefinedInputButtonTitle = getPredefinedInputButtonTitle()
  const sourceTypeOpen = Boolean(anchorSourceTypeEl)
  const predefinedInputOpen = Boolean(anchorPredefinedInputEl)

  const inputBaseStyles = useMemo(() => {
    return {
      fontSize: '18px',
      color: theme.palette.new.black,
      height: theme.spacing(6),
      padding: theme.spacing(0, 3),
      width: '100%',
      '& ::-webkit-search-cancel-button': {
        height: '18px',
        width: '18px',
        borderRadius: 0,
        background: 'url("/close.svg") no-repeat 50% 50%',
        WebkitAppearance: 'none',
        cursor: 'pointer',
        opacity: 1,
      },
    }
  }, [
    theme,
  ])

  const autocompleteStyles = useMemo(() => {
    return {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      height: theme.spacing(6),
    }
  }, [
    theme,
  ])

  const autocompleteSlotProps = useMemo(() => {
    return {
      paper: {
        sx: {
          minWidth: `${(inputWidth)}px`,
          backgroundColor: theme.palette.new.white,
          boxShadow: 'none',
          margin: theme.spacing(0),
          borderRadius: theme.spacing(0),
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          '& .MuiAutocomplete-listbox': {
            maxHeight: '50vh',
          },

          '& .MuiAutocomplete-noOptions': {
            minHeight: theme.spacing(15),
            padding: theme.spacing(0, 3),
            '&:hover': {
              backgroundColor: 'initial',
            },
          },

          '& .MuiAutocomplete-option': {
            height: theme.spacing(6),
            padding: `${theme.spacing(0, 3)} !important`,
            borderTop: `1px solid ${theme.palette.new.grey_a}`,
            '&:first-of-type': {
              borderTop: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.new.grey_d,
            },
            '&[aria-selected="true"]': {
              backgroundColor: theme.palette.new.white,
              '& .optionLabel': {
                color: theme.palette.new.violet,
              },
              '&:hover': {
                backgroundColor: theme.palette.new.grey_d,
              },
            },
            '&[data-focus="true"]': {
              backgroundColor: theme.palette.new.grey_d,
            },
            '&[aria-disabled="true"]': {
              opacity: 1,

              '& .optionLabel': {
                color: 'rgba(0, 0, 0, 0.4)',
              },

              '& .optionIcon': {
                opacity: 0.4,
              },
            },
          },
        },
      },
    }
  }, [
    theme,
    inputWidth,
  ])

  return (
    <Box
      data-testid={ParameterTemplateSelectorComponent.name}
      ref={containerRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1, 2),
        height: theme.spacing(6),
        border: `1px solid ${theme.palette.new.grey_a}`,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...(disabled ? {
          cursor: 'not-allowed',
          background: theme.palette.new.grey,
        } : {}),
      }}
    >
      <DropdownButtonComponent
        name='sourceTypeAutocomplete'
        label={sourceTypeButtonTitle}
        onClick={handleSourceTypeOpen}
        open={sourceTypeOpen}
        disabled={disabled}
      />

      {
        (sourceTypeValue && parametersTemplatesList && parametersTemplatesList.length > 0) ? (
          <Box ml={1}>
            <DropdownButtonComponent
              name='predefinedInputAutocomplete'
              label={predefinedInputButtonTitle}
              onClick={handlePredefinedInputOpen}
              open={predefinedInputOpen}
              disabled={disabled}
            />
          </Box>
        ) : (
          null
        )
      }

      <Popper
        open={sourceTypeOpen}
        anchorEl={anchorSourceTypeEl}
        placement='bottom-start'
        sx={{
          minWidth: inputWidth,
          backgroundColor: theme.palette.new.white,
          border: `1px solid ${theme.palette.new.grey_a}`,
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
          filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
          zIndex: 100000,
          marginTop: '-50px !important',
          marginLeft: '-21px !important',

          '& .MuiAutocomplete-popperDisablePortal': {
            minWidth: `${(inputWidth)}px`,
            position: 'relative !important' as any,
            transform: 'none !important',
          },
        }}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box
          data-testid={`${ParameterTemplateSelectorComponent.name}-${SOURCE_TYPE_SELECTOR_ID}`}
          sx={{
            minWidth: inputWidth,
          }}
        >
          <Autocomplete
            id={SOURCE_TYPE_SELECTOR_ID}
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={sourceTypeValue ? [sourceTypeValue] : []}
            renderTags={() => null}
            onClose={handleSourceTypeClose}
            getOptionDisabled={(option) => {
              return option.isDisabled
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value
            }}
            onChange={handleSourceTypeChange}
            options={allowedOptions}
            getOptionLabel={getSourceTypeOptionLabel}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                sx={inputBaseStyles}
                placeholder={intl.formatMessage({ id: 'connect.modal.parameter.source.placeholder' })}
              />
            )}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            groupBy={(option) => {
              const category: SOURCE_TYPES_CATEGORIES = option.category

              return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
            }}
            renderGroup={(option) => {
              return (
                <Box component='li' key={option.key}>
                  <Box
                    sx={{
                      height: theme.spacing(6),
                      padding: theme.spacing(0, 7),
                      display: !option.group ? 'none' : 'flex',
                      alignItems: 'flex-end',
                      color: theme.palette.new.black,
                      fontWeight: '500',
                      fontSize: '11px',
                      lineHeight: '40px',
                      letterSpacing: '0.06em',
                      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                      textTransform: 'uppercase',
                      position: 'sticky',
                      top: '0px',
                      width: '100%',
                      zIndex: 10,
                      backgroundColor: theme.palette.new.white,
                    }}
                  >
                    {option.group}
                  </Box>
                  <Box
                    component='ul'
                    sx={{
                      '&:last-child': {
                        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                      },
                    }}
                  >
                    {option.children}
                  </Box>
                </Box>
              )
            }}
            renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: SourceTypeOptionExtended, { selected } : { selected: boolean }) => {
              const label = option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
              const IconComponent = option.isDisabled ? SOURCE_TYPES_TO_DISABLED_ICONS_MAP[option.value] : SOURCE_TYPES_TO_ICONS_MAP[option.value]

              return (
                <Box component='li' {...props}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className='optionIcon optionIconDataSources'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: theme.spacing(2),
                      }}
                    >
                      <IconComponent />
                    </Box>
                    <Typography
                      title={label}
                      className='optionLabel'
                      sx={{
                        fontSize: '18px',
                        color: selected ? theme.palette.new.violet : theme.palette.new.black,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textTransform: 'none',
                        maxWidth: inputWidth,
                        '& span > span': {
                          color: theme.palette.new.grey_c,
                        },
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                </Box>
              )
            }) as any}
            sx={autocompleteStyles}
            slotProps={autocompleteSlotProps}
          />
        </Box>
      </Popper>

      <Popper
        open={predefinedInputOpen}
        anchorEl={anchorPredefinedInputEl}
        placement='bottom-start'
        sx={{
          minWidth: inputWidth,
          backgroundColor: theme.palette.new.white,
          border: `1px solid ${theme.palette.new.grey_a}`,
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
          filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
          zIndex: 100000,
          marginTop: '-50px !important',
          marginLeft: '-21px !important',
          inset: '0px 0px auto auto !important',
          transform: 'translate3d(-40px, 304px, 10px) !important',

          '& .MuiAutocomplete-popperDisablePortal': {
            minWidth: `${(inputWidth)}px`,
            position: 'relative !important' as any,
            transform: 'none !important',
          },
        }}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box
          sx={{
            minWidth: inputWidth,
          }}
          data-testid={`${ParameterTemplateSelectorComponent.name}-${PREDEFINED_INPUT_SELECTOR_ID}`}
        >
          <Autocomplete
            id={PREDEFINED_INPUT_SELECTOR_ID}
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={inputTemplateValue ? [inputTemplateValue] : []}
            renderTags={() => null}
            onClose={handlePredefinedInputClose}
            onChange={handlePredefinedInputChange}
            options={parametersTemplatesListOptions}
            getOptionLabel={getPredefinedInputOptionLabel}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                sx={inputBaseStyles}
                placeholder={sourceTypeValue ? (
                  intl.formatMessage({ id: 'connect.modal.parameter.predefined.placeholder' }, { name: intl.formatMessage({ id: sourceTypeValue.labelKey }) })
                ) : (
                  ''
                )}
              />
            )}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: UseCase.ParameterTemplateItem, { selected } : { selected: boolean }) => {
              const label = option.name
              const unit = option.unitLabel || ''
              const sourceType = option.sourceType as SOURCE_TYPES
              const IconComponent = sourceType ? SOURCE_TYPES_TO_ICONS_MAP[sourceType] : null

              return (
                <Box component='li' {...props}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className='optionLabel'
                      title={label}
                      sx={{
                        fontSize: '18px',
                        color: selected ? theme.palette.new.violet : theme.palette.new.black,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textTransform: 'none',
                        maxWidth: inputWidth,
                        '& span > span': {
                          color: theme.palette.new.grey_c,
                        },
                      }}
                    >
                      <Box
                        component='span'
                        dangerouslySetInnerHTML={{
                          __html: label,
                        }}
                      />
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {
                        (sourceType && IconComponent) ? (
                          <>
                            <Box
                              sx={{
                                marginRight: theme.spacing(3),
                                fontWeight: '400',
                                fontSize: '13px',
                                color: theme.palette.new.grey_c,
                                textTransform: 'uppercase',
                              }}
                            >
                              {unit}
                            </Box>
                            <Box
                              className='optionIcon'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <IconComponent />
                            </Box>
                          </>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              )
            }) as any}
            sx={autocompleteStyles}
            slotProps={autocompleteSlotProps}
          />
        </Box>
      </Popper>
    </Box>
  )
}

export default ParameterTemplateSelectorComponent
