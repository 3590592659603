import React, { useMemo } from 'react'

import ButtonComponent, { ButtonComponentProps } from '@base/buttons/Button/Button.component'
import { useIntl } from 'react-intl'

export type PropsFromButton = Pick<ButtonComponentProps, 'forwardedRef' | 'name' | 'loading' | 'disabled' | 'onClick'>

export interface ModalButtonComponentProps extends PropsFromButton {
  /**
   * The name of the button
   */
  name: string,
  /**
   * The label of the button
   */
  label?: string,
  /**
   * If true, the loading spinner will be shown
   */
  loading?: boolean,
  /**
   * If true, the button will be disabled
   */
  disabled?: boolean,
  /**
   * The onClick event handler
   */
  onClick?: React.MouseEventHandler,
  /**
   * The type of the button
   * @default 'submit'
   */
  type?: 'submit' | 'cancel' | 'back',
  /**
   * Custom min width
   * @default '200px'
   */
  minWidth?: string,
  /**
   * Custom width
   */
  width?: string,
}

export const ModalButtonComponent: React.FC<ModalButtonComponentProps> = ({
  name,
  label,
  type = 'submit',
  loading,
  disabled,
  minWidth = '200px',
  width,
  onClick,
  ...props
}) => {
  const intl = useIntl()

  const buttonColor = useMemo(() => {
    switch (type) {
      case 'submit':
        return 'highlighted'
      case 'back':
      case 'cancel':
        return 'highlighted-secondary'
      default:
        return 'highlighted'
    }
  }, [type])

  const styles = {
    minWidth,
    width,
  }

  const finalLabel = useMemo(() => {
    if (label) {
      return label
    }

    switch (type) {
      case 'submit':
        return intl.formatMessage({ id: 'common.modal.button.submit' })
      case 'cancel':
        return intl.formatMessage({ id: 'common.modal.button.cancel' })
      case 'back':
        return intl.formatMessage({ id: 'common.modal.button.back' })
      default:
        return intl.formatMessage({ id: 'common.modal.button.submit' })
    }
  }, [label, type, intl])

  return (
    <ButtonComponent
      name={name}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      color={buttonColor}
      label={finalLabel}
      rounded={true}
      sx={styles}
      data-type={type}
      {...props}
    />
  )
}

export default React.forwardRef<React.FC, ModalButtonComponentProps>((props, ref) => <ModalButtonComponent {...props} forwardedRef={ref} />)
