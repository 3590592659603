import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from '@redux/hooks'
import { getAnalyzeChart, getAnalyzeTable, isFetchingAnalyzeChart } from '@redux/modules/analyze/analyze.selectors'
import { DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX } from '@constants/insights.constants'
import { getPromotionsToExclude, getPromotionsVisibility } from '@redux/modules/insights/insights.selectors'

import InsightsChartComponent from '@components/insights/InsightsChart'

const AnalyzeChartContainer = () => {
  const analyzeTable = useSelector((state) => getAnalyzeTable(state))
  const analyzeChart = useSelector((state) => getAnalyzeChart(state))
  const isFetching = useSelector((state) => isFetchingAnalyzeChart(state))
  const promotionsVisibility = useSelector((state) => getPromotionsVisibility(state))
  const promotionsToExclude = useSelector((state) => getPromotionsToExclude(state))

  const {
    gridState,
  } = analyzeTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
    promotionDays,
  } = analyzeChart

  return (
    <Box
      data-testid={AnalyzeChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        promotionDays={promotionDays}
        isFetching={isFetching}
        predictionKeyPrefix={DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX}
        promotionsVisibility={promotionsVisibility}
        promotionsToExclude={promotionsToExclude}
      />
    </Box>
  )
}

export default AnalyzeChartContainer
