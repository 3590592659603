import { useEffect } from 'react'

import { DataGridComponentProps } from '@base/datagrid/data-grid/DataGrid.component'
import { useIntl } from 'react-intl'
import { DEFAULT_DATA_GIRD_AUTOSIZE_OPTIONS } from '@constants/data-grid.constants'

export interface useDataGridAutosizeProps {
  apiRef: DataGridComponentProps['apiRef']
  autosizeOnMount: DataGridComponentProps['autosizeOnMount']
  loading: DataGridComponentProps['loading']
  disableVirtualization: DataGridComponentProps['disableVirtualization']
  disableAutosize: DataGridComponentProps['disableAutosize']
  uploading: DataGridComponentProps['uploading']
}

export const useDataGridAutosize = ({
  apiRef,
  autosizeOnMount,
  loading,
  disableVirtualization,
  disableAutosize,
  uploading,
}: useDataGridAutosizeProps) => {
  const intl = useIntl()

  useEffect(() => {
    if (apiRef && apiRef.current && autosizeOnMount && !loading && !uploading && disableVirtualization && !disableAutosize) {
      setTimeout(() => {
        apiRef.current?.autosizeColumns(DEFAULT_DATA_GIRD_AUTOSIZE_OPTIONS)
      }, 100)
    }
  }, [
    apiRef, disableVirtualization, disableAutosize,
    loading, autosizeOnMount, intl, uploading,
  ])
}
