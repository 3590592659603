import { State } from '@redux/modules/types'

import {
  REQUEST_PARAMETER_OVERVIEW,
  REQUEST_PARAMETER,
  CREATE_PARAMETER,
  UPDATE_PARAMETER,
  DELETE_PARAMETER,
  REQUEST_GROUPING_OVERVIEW,
  REQUEST_GROUPING,
  CREATE_GROUPING,
  UPDATE_GROUPING,
  DELETE_GROUPING,
} from './parameters.action-types'

export const getParameterItem = (state: State) => {
  return state.parameters.item
}

export const getGroupingItem = (state: State) => {
  return state.parameters.groupingItem
}

export const isFetchingGrouping = (state: State) => {
  return state.parameters.fetchingKeys.includes(REQUEST_GROUPING_OVERVIEW) || state.parameters.fetchingKeys.includes(REQUEST_GROUPING)
}

export const isSubmittingGrouping = (state: State) => {
  return state.parameters.fetchingKeys.includes(CREATE_GROUPING) ||
         state.parameters.fetchingKeys.includes(UPDATE_GROUPING) ||
         state.parameters.fetchingKeys.includes(DELETE_GROUPING)
}

export const isFetchingParameter = (state: State) => {
  return state.parameters.fetchingKeys.includes(REQUEST_PARAMETER_OVERVIEW) || state.parameters.fetchingKeys.includes(REQUEST_PARAMETER)
}

export const isSubmittingParameter = (state: State) => {
  return state.parameters.fetchingKeys.includes(CREATE_PARAMETER) ||
          state.parameters.fetchingKeys.includes(UPDATE_PARAMETER) ||
          state.parameters.fetchingKeys.includes(DELETE_PARAMETER)
}
