import React, {
  useEffect,
} from 'react'

import { Grid, GridProps } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { requestKPIItemsArtifactAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'
import { getComparisonModeState, getKPIItemsArtifact, isFetchingKPIItemsArtifact } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import KPIItemComponent from '@components/recommendation-artifacts/KPIItem'

export interface KpiItemsArtifactContainerProps {
  /**
   * Props for the container Grid component
   */
  containerProps?: GridProps
  /**
   * Props for the item Grid component
   */
  itemsProps?: GridProps
}

const KPIItemsArtifactContainer: React.FC<KpiItemsArtifactContainerProps> = ({
  containerProps,
  itemsProps,
}) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const KPIItemsArtifact = useSelector((state) => getKPIItemsArtifact(state))
  const isFetching = useSelector((state) => isFetchingKPIItemsArtifact(state))
  const comparisonMode = useSelector((state) => getComparisonModeState(state))

  useEffect(() => {
    dispatch(
      requestKPIItemsArtifactAction({
        useCaseId,
      }),
    )
  }, [useCaseId, dispatch])

  const {
    items = [],
  } = KPIItemsArtifact

  if (items.length === 0 || isFetching) {
    return null
  }

  return (
    <Grid
      container={true}
      spacing={2}
      data-testid={KPIItemsArtifactContainer.name}
      {...containerProps}
    >
      {
        items.map((kpiItem, index) => {
          return (
            <Grid
              item={true}
              xs={6}
              sm={6}
              md={6}
              lg={3}
              key={index}
              {...itemsProps}
            >
              <KPIItemComponent
                key={index}
                item={kpiItem}
                comparisonMode={comparisonMode}
              />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default KPIItemsArtifactContainer
