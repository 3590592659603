import React from 'react'
import { Theme, useTheme } from '@mui/material'

import ChipComponent from '@base/chips/Chip'
import CheckMarkIcon from '@icons/flow/checkMark.icon'
import CloseIcon from '@icons/close.icon'

const getLabelColorByState = (theme: Theme, active?: boolean, outlined?: boolean) => {
  if (!outlined) {
    return active ? theme.palette.new.generous_green : theme.palette.new.rebellious_red
  }

  if (active) {
    return theme.palette.new.generous_green
  }

  return theme.palette.new.rebellious_red
}

export interface DataGridBooleanChipComponentProps {
  label?: string | React.ReactNode,
  active?: boolean,
  outlined?: boolean,
}

const DataGridBooleanChipComponent: React.FC<DataGridBooleanChipComponentProps> = ({
  active,
  label,
  outlined = false,
}) => {
  const theme = useTheme()
  const IconComponent = active ? CheckMarkIcon : CloseIcon
  const color = getLabelColorByState(theme, active, outlined)

  return (
    <ChipComponent
      StartIconComponent={IconComponent}
      color={color}
      label={String(label)}
      variant={outlined ? 'outlined' : 'filled'}
      textTransform='none'
    />
  )
}

export default DataGridBooleanChipComponent
