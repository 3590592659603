import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'

import IconButtonComponent from '@base/buttons/IconButton'
import CloseIcon from '@icons/close.icon'
import TooltipComponent, { TooltipComponentProps } from '@base/tooltips/Tooltip/Tooltip.component'

import { setMemoryTooltipState, getMemoryTooltipState } from '@utils/local-storage.utils'

export interface MemoryTooltipComponentProps extends Omit<TooltipComponentProps, 'title' | 'children'> {
  /**
   * Unique identifier for the tooltip
   */
  id: string,
  /**
   * Tooltip children
   */
  children?: React.ReactNode,
  /**
   * Tooltip open state, if provided, tooltip will be controlled and will not close automatically
   */
  open?: boolean,
  /**
   * Tooltip timeout
   */
  timeout?: number,
  /**
   * If true, tooltip will have an arrow
   */
  arrow?: boolean,
  /**
   * Tooltip content
   */
  title?: string | React.ReactNode,
}

const MemoryTooltipComponent: React.FC<MemoryTooltipComponentProps> = ({
  id,
  arrow = true,
  timeout = 5000,
  open,
  title,
  placement = 'top',
  children,
  ...rest
}) => {
  const lsTooltipsState = getMemoryTooltipState(id)
  const [tooltipStatus, setTooltipStatus] = useState(!lsTooltipsState)

  const transitionProps = {
    timeout: {
      appear: 3000,
      enter: 3000,
    },
  }

  const handleClick = () => {
    if (!lsTooltipsState) {
      setMemoryTooltipState(id)
    }

    setTooltipStatus(false)
  }

  useEffect(() => {
    if (open === false) {
      setTooltipStatus(false)
    }
  }, [open])

  useEffect(
    () => {
      let timer: any = null

      if (timeout) {
        timer = setTimeout(() => {
          if (!lsTooltipsState) {
            setTooltipStatus(false)
          }
        }, timeout)
      }

      return () => {
        clearTimeout(timer)
      }
    }, [
      timeout,
      lsTooltipsState,
    ],
  )

  return (
    <TooltipComponent
      {...rest}
      arrow={arrow}
      open={open !== undefined ? open : tooltipStatus}
      placement={placement}
      TransitionProps={transitionProps}
      title={(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {title}

          <IconButtonComponent
            name='memoryTooltipClose'
            label='close'
            onClick={handleClick}
            sx={{
              position: 'absolute',
              left: '-16px',
              top: '-16px',
              transform: 'scale(0.5)',
            }}
            IconComponent={CloseIcon}
          />
        </Box>
      )}
    >
      {children as any}
    </TooltipComponent>
  )
}

export default MemoryTooltipComponent
