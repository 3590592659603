import React, { useMemo } from 'react'
import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'

import { useRouteMatch } from 'react-router-dom'

import {
  trackEvent,
  TRACKING_ACTIONS,
  TrackingEventNameProps,
} from '@utils/tracking.utils'

import {
  DEFAULT_BUTTON_FONT_SIZE,
  INLINE_BUTTON_HEIGHT,
  DEFAULT_BUTTON_TRANSITION,
  DEFAULT_PADDING,
} from '@constants/ui.constants'

export interface InlineButtonComponentProps extends Pick<ButtonProps, 'onClick' | 'disabled' | 'sx'> {
  /**
   * Button name, used for tracking and testing
   */
  name: string
  /**
   * Button label
   */
  label: string
  /**
   * Icon component to be displayed
   */
  StartIconComponent?: React.FC<Common.IconProps>
  /**
   * Props to be passed to the Icon component
   */
  startIconComponentProps?: Common.IconProps
  /**
   * Icon component to be displayed at the end of the button
   */
  EndIconComponent?: React.FC<Common.IconProps>
  /**
   * Props to be passed to the End Icon component
   */
  endIconComponentProps?: Common.IconProps
  /**
   * If 'true', the button will display only the icon
   */
  iconOnly?: boolean
  /**
   * Color of the button text
   */
  color?: string
  /**
   * Ref to be forwarded to the button
   */
  forwardedRef?: React.ForwardedRef<any>
  /**
   * Tracking props to be passed to the tracking event
   */
  trackingProps?: TrackingEventNameProps
  /**
   * If provided, the button label will be wrapped in a Typography component with these props
   */
  typographyProps?: TypographyProps
}

export const InlineButtonComponent: React.FC<InlineButtonComponentProps> = ({
  name,
  color,
  forwardedRef,
  StartIconComponent,
  EndIconComponent,
  startIconComponentProps,
  endIconComponentProps,
  label,
  trackingProps,
  onClick,
  disabled,
  iconOnly,
  sx,
  typographyProps,
  ...props
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CLICK,
      ...trackingProps,
    }, {
      router: params,
    })

    if (onClick) {
      onClick(e)
    }
  }

  const startIconWrapped = useMemo(() => {
    return StartIconComponent ? (
      <StartIconComponent
        {...startIconComponentProps}
        {...(disabled ? {
          detailsFill: 'rgba(0, 0, 0, 0.20)',
        } : {})}
      />
    ) : undefined
  }, [StartIconComponent, startIconComponentProps, disabled])

  const endIconWrapped = useMemo(() => {
    return EndIconComponent ? (
      <EndIconComponent
        {...endIconComponentProps}
        {...(disabled ? {
          detailsFill: 'rgba(0, 0, 0, 0.20)',
        } : {})}
      />
    ) : undefined
  }, [EndIconComponent, endIconComponentProps, disabled])

  const startIcon = !iconOnly ? startIconWrapped : undefined
  const endIcon = !iconOnly ? endIconWrapped : undefined

  const content = useMemo(() => {
    if (iconOnly) {
      return startIconWrapped || endIconWrapped
    }

    if (typographyProps) {
      return (
        <Typography
          variant='button'
          noWrap={true}
          lineHeight='18px'
          {...typographyProps}
        >
          {label}
        </Typography>
      )
    }

    return label
  }, [iconOnly, startIconWrapped, endIconWrapped, typographyProps, label])

  return (
    <Button
      {...props}
      data-testid={name}
      ref={forwardedRef}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={true}
      disableRipple={true}
      onClick={onClickHandler}
      variant='contained'
      disabled={disabled}
      aria-label={iconOnly ? label : undefined}
      sx={{
        fontWeight: 400,
        transition: DEFAULT_BUTTON_TRANSITION,

        textTransform: 'initial',
        padding: '0px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        justifyContent: 'flex-start',

        height: INLINE_BUTTON_HEIGHT,
        minWidth: INLINE_BUTTON_HEIGHT,
        minHeight: INLINE_BUTTON_HEIGHT,
        fontSize: DEFAULT_BUTTON_FONT_SIZE,

        backgroundColor: theme.palette.new.transparent,
        color: color || theme.palette.new.business_black,

        '&:hover': {
          opacity: 0.6,
          backgroundColor: theme.palette.new.transparent,
        },
        '&:focus-visible': {
          opacity: 0.6,
          backgroundColor: theme.palette.new.transparent,
        },
        '&:active': {
          opacity: 1,
          backgroundColor: theme.palette.new.transparent,
        },

        '&.Mui-disabled': {
          cursor: 'not-allowed',
          backgroundColor: theme.palette.new.business_black_5,
          color: 'rgba(0, 0, 0, 0.20)',
        },

        '& .MuiButton-startIcon': {
          marginLeft: 0,
          marginRight: DEFAULT_PADDING.SMALL,
        },

        ...sx,
      }}
    >
      {content}
    </Button>
  )
}

export default React.forwardRef<React.FC, InlineButtonComponentProps>((props, ref) => <InlineButtonComponent {...props} forwardedRef={ref} />)
