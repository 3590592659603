import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useDispatch, useSelector } from '@redux/hooks'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import { requestFileDeleteAction } from '@redux/modules/file-service/file-service.actions'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME } from '@constants/modals.constants'
import { getOpenedModal, getModalDetails } from '@redux/modules/modal-manager/modal-manager.selectors'
import { Box } from '@mui/material'

export interface FileBrowserItemRemovalDialogModalDetails extends Common.ModalDetails {
  filePath: string
  fileName: string
}

export interface FileBrowserItemRemovalDialogProps {
}

const FileBrowserItemRemovalDialog: React.FC<FileBrowserItemRemovalDialogProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const modalPayload = useSelector((state) => getModalDetails<FileBrowserItemRemovalDialogModalDetails>(state))

  const openModalName = useSelector((state) => getOpenedModal(state))
  const isOpen = openModalName === FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME

  const handleClose = useCallback(() => {
    dispatch(
      setPrimaryModalPageName(''),
    )
  }, [dispatch])

  const handleConfirm = useCallback(() => {
    dispatch(
      requestFileDeleteAction({
        filePath: modalPayload.filePath,
      }),
    )
  }, [dispatch, modalPayload])

  return (
    <TextConfirmationDialogComponent
      open={isOpen}
      onClose={handleClose}
      onSubmit={handleConfirm}
      confirmationText={modalPayload.fileName}
      confirmationInputLabel={intl.formatMessage({ id: 'fileManager.delete.dialog.confirmation' })}
      description={
        intl.formatMessage({ id: 'fileManager.delete.dialog.content' }, {
          name: <Box component='strong'>{modalPayload.fileName}</Box>,
        })
      }
    />

  )
}

export default FileBrowserItemRemovalDialog
