/* istanbul ignore file */
import moment from 'moment'

import {
  STOCK_STATUS_OPTIONS, REVENUE_MEDIUM,
  REPLENISHMENT_COST_MEDIUM,
  REVENUE_MIN_MAX, REPLENISHMENT_COST_MIN_MAX,
  OPTIMIZE_RESTOCK_SELECTOR_TYPES,
} from '@constants/optimize.constants'

import { normalizeValue } from '@utils/analysis.utils'

const getDatesRange = () => {
  const pastAmount = 15
  const futureAmount = 4
  const startOfTheWeek = moment().startOf('week')
  const startDate = startOfTheWeek.subtract(pastAmount, 'week')
  const today = moment().startOf('week').format('YYYY-MM-DD')

  const dates = []
  for (let i = 0; i < pastAmount; i += 1) {
    const date = moment(startDate).add(i, 'week').format('YYYY-MM-DD')
    dates.push(date)
  }

  dates.push(today)

  for (let i = 1; i <= futureAmount; i += 1) {
    const date = moment().startOf('week').add(i, 'week').format('YYYY-MM-DD')
    dates.push(date)
  }

  return dates
}

const getGradientPercentage = (dataToCheck: Optimize.OptimizeDrillDownItemDataItem[]) => {
  const pastPointsAmount = dataToCheck.filter((item) => item.stockNetHistoric !== null).length
  const predictedPointsAmount = dataToCheck.filter((item) => item.stockNetPredict !== null).length

  return normalizeValue(
    pastPointsAmount,
    0,
    (predictedPointsAmount + pastPointsAmount),
  )
}

const DATES = getDatesRange()
const BASE_DRILL_DOWN_DATA = [
  {
    date: '2022-12-05',
    stockNetHistoric: 720,
    stockNetPredict: null,
    salesHistoric: 273,
    salesPredict: null,
    reorder: 860,
  }, {
    date: '2022-12-12',
    stockNetHistoric: 947,
    stockNetPredict: null,
    salesHistoric: 656,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2022-12-19',
    stockNetHistoric: 291,
    stockNetPredict: null,
    salesHistoric: 1248,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2022-12-26',
    stockNetHistoric: 1243,
    stockNetPredict: null,
    salesHistoric: 149,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-01-02',
    stockNetHistoric: 1095,
    stockNetPredict: null,
    salesHistoric: 360,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-01-09',
    stockNetHistoric: 735,
    stockNetPredict: null,
    salesHistoric: 257,
    salesPredict: null,
    reorder: 555,
  }, {
    date: '2023-01-16',
    stockNetHistoric: 968,
    stockNetPredict: null,
    salesHistoric: 316,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-01-23',
    stockNetHistoric: 652,
    stockNetPredict: null,
    salesHistoric: 412,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-01-30',
    stockNetHistoric: 1140,
    stockNetPredict: null,
    salesHistoric: 336,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-02-06',
    stockNetHistoric: 804,
    stockNetPredict: null,
    salesHistoric: 257,
    salesPredict: null,
    reorder: 421,
  }, {
    date: '2023-02-13',
    stockNetHistoric: 548,
    stockNetPredict: null,
    salesHistoric: 255,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-02-20',
    stockNetHistoric: 1493,
    stockNetPredict: null,
    salesHistoric: 452,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-02-27',
    stockNetHistoric: 1041,
    stockNetPredict: null,
    salesHistoric: 332,
    salesPredict: null,
    reorder: 321,
  }, {
    date: '2023-03-06',
    stockNetHistoric: 709,
    stockNetPredict: null,
    salesHistoric: 258,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-03-13',
    stockNetHistoric: 451,
    stockNetPredict: null,
    salesHistoric: 238,
    salesPredict: null,
    reorder: null,
  }, {
    date: '2023-03-20',
    stockNetHistoric: 305,
    stockNetPredict: null,
    salesHistoric: 146,
    salesPredict: null,
    reorder: 892,
  }, {
    date: '2023-03-27',
    stockNetHistoric: null,
    stockNetPredict: -55,
    salesHistoric: null,
    salesPredict: 361,
    reorder: null,
  }, {
    date: '2023-04-03',
    stockNetHistoric: null,
    stockNetPredict: -416,
    salesHistoric: null,
    salesPredict: 299,
    reorder: null,
  }, {
    date: '2023-04-10',
    stockNetHistoric: null,
    stockNetPredict: -715,
    salesHistoric: null,
    salesPredict: 228,
    reorder: null,
  }, {
    date: '2023-04-17',
    stockNetHistoric: null,
    stockNetPredict: -943,
    salesHistoric: null,
    salesPredict: 310,
    reorder: null,
  },
]

const DRIL_DONW_DATA_MAP = {
  cd8506c9: BASE_DRILL_DOWN_DATA,
}

export const OPTIMIZE_DATA: Optimize.OptimizeItem[] = [
  {
    sku: 'cd8506c9',
    productName: 'Timeless watch',
    category: 'Watches',
    region: 'West-1',
    stockNet: 797,
    stockNetInEur: 239194,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 2320,
  },
  {
    sku: '730ddbc1',
    productName: 'Fountain pen',
    category: 'Accessoires',
    region: 'West-1',
    stockNet: 305,
    stockNetInEur: 19851,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 892,
  },
  {
    sku: '6dfffac6',
    productName: 'Travelbag',
    category: 'Luggage',
    region: 'West-1',
    stockNet: 37,
    stockNetInEur: 7379,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: '832fe7a8',
    productName: 'Classic sunglasses',
    category: 'Glasses',
    region: 'West-1',
    stockNet: 174,
    stockNetInEur: 10249,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: 'db2cafd2',
    productName: 'Carbon bike',
    category: 'Sports equipment',
    region: 'West-1',
    stockNet: 45,
    stockNetInEur: 145385,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: 'cd8506c9',
    productName: 'Timeless watch',
    category: 'Watches',
    region: 'West-2',
    stockNet: 1395,
    stockNetInEur: 239194,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 3223,
  },
  {
    sku: '730ddbc1',
    productName: 'Fountain pen',
    category: 'Accessoires',
    region: 'West-2',
    stockNet: 770,
    stockNetInEur: 19851,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 1108,
  },
  {
    sku: '6dfffac6',
    productName: 'Travelbag',
    category: 'Luggage',
    region: 'West-2',
    stockNet: 49,
    stockNetInEur: 7379,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: '832fe7a8',
    productName: 'Classic sunglasses',
    category: 'Glasses',
    region: 'West-2',
    stockNet: 108,
    stockNetInEur: 10249,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 193,
  },
  {
    sku: 'db2cafd2',
    productName: 'Carbon bike',
    category: 'Sports equipment',
    region: 'West-2',
    stockNet: 58,
    stockNetInEur: 145385,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: 'cd8506c9',
    productName: 'Timeless watch',
    category: 'Watches',
    region: 'North-East-1',
    stockNet: 2089,
    stockNetInEur: 239194,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: '730ddbc1',
    productName: 'Fountain pen',
    category: 'Accessoires',
    region: 'North-East-1',
    stockNet: 180,
    stockNetInEur: 19851,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 238,
  },
  {
    sku: '6dfffac6',
    productName: 'Travelbag',
    category: 'Luggage',
    region: 'North-East-1',
    stockNet: 99,
    stockNetInEur: 7379,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: '832fe7a8',
    productName: 'Classic sunglasses',
    category: 'Glasses',
    region: 'North-East-1',
    stockNet: 405,
    stockNetInEur: 10249,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 429,
  },
  {
    sku: 'db2cafd2',
    productName: 'Carbon bike',
    category: 'Sports equipment',
    region: 'North-East-1',
    stockNet: 70,
    stockNetInEur: 145385,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: 'cd8506c9',
    productName: 'Timeless watch',
    category: 'Watches',
    region: 'South-West-2',
    stockNet: 702,
    stockNetInEur: 239194,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: '730ddbc1',
    productName: 'Fountain pen',
    category: 'Accessoires',
    region: 'South-West-2',
    stockNet: 434,
    stockNetInEur: 19851,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 1093,
  },
  {
    sku: '6dfffac6',
    productName: 'Travelbag',
    category: 'Luggage',
    region: 'South-West-2',
    stockNet: 62,
    stockNetInEur: 7379,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: '832fe7a8',
    productName: 'Classic sunglasses',
    category: 'Glasses',
    region: 'South-West-2',
    stockNet: 193,
    stockNetInEur: 10249,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: 'db2cafd2',
    productName: 'Carbon bike',
    category: 'Sports equipment',
    region: 'South-West-2',
    stockNet: 108,
    stockNetInEur: 145385,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: 'cd8506c9',
    productName: 'Timeless watch',
    category: 'Watches',
    region: 'North-2',
    stockNet: 2344,
    stockNetInEur: 239194,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 6399,
  },
  {
    sku: '730ddbc1',
    productName: 'Fountain pen',
    category: 'Accessoires',
    region: 'North-2',
    stockNet: 339,
    stockNetInEur: 19851,
    stockStatus: STOCK_STATUS_OPTIONS.OVERSTOCKED,
    restockQuantity: 0,
  },
  {
    sku: '6dfffac6',
    productName: 'Travelbag',
    category: 'Luggage',
    region: 'North-2',
    stockNet: 99,
    stockNetInEur: 7379,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: '832fe7a8',
    productName: 'Classic sunglasses',
    category: 'Glasses',
    region: 'North-2',
    stockNet: 403,
    stockNetInEur: 10249,
    stockStatus: STOCK_STATUS_OPTIONS.GOOD,
    restockQuantity: 0,
  },
  {
    sku: 'db2cafd2',
    productName: 'Carbon bike',
    category: 'Sports equipment',
    region: 'North-2',
    stockNet: 79,
    stockNetInEur: 145385,
    stockStatus: STOCK_STATUS_OPTIONS.UNDERSTOCKED,
    restockQuantity: 195,
  },
]

export const generateDrillDownData = (optimizeData: Optimize.OptimizeItem[]) => {
  return optimizeData.map((optimizeDataItem) => {
    // @ts-ignore
    const dataToUse = DRIL_DONW_DATA_MAP[optimizeDataItem.sku] || BASE_DRILL_DOWN_DATA
    const drillDownData = dataToUse.map((item: any, index: number) => {
      return {
        ...item,
        date: DATES[index],
        stockNetPredictedFlag: item.stockNetPredict !== null,
        salesPredictedFlag: item.salesPredict !== null,
        stockNetComputed: item.stockNetHistoric || item.stockNetPredict,
        salesComputed: item.salesHistoric || item.salesPredict,
      }
    })

    return {
      meta: {
        sku: optimizeDataItem.sku,
        region: optimizeDataItem.region,
        productName: optimizeDataItem.productName,
        currentStock: optimizeDataItem.stockNet,
        stockStatus: optimizeDataItem.stockStatus,
        currentStockInEur: optimizeDataItem.stockNetInEur,
        currentReachInDays: 7,
        restockRecommendation: 892,
        leadTimeInDays: 7,
        gradientPercentage: getGradientPercentage(drillDownData),
      } as Optimize.OptimizeDrillDownItemMeta,
      data: drillDownData,
    } as Optimize.OptimizeDrillDownItem
  })
}

export const getStockStatus = (quantity: number, stockStatus: STOCK_STATUS_OPTIONS) => {
  if (quantity === 0) {
    return STOCK_STATUS_OPTIONS.GOOD
  }

  if (quantity > 0) {
    return STOCK_STATUS_OPTIONS.UNDERSTOCKED
  }

  return STOCK_STATUS_OPTIONS.OVERSTOCKED
}

export const randomizeNumber = (num: number, factor: number, growing: boolean) => {
  const min = num - (num * factor)
  const max = num + (num * factor)

  return Math.floor(growing ? max : min)
}

export const generateOptimizeData = (rangeSelectorData: Optimize.OptimizeRangeSelectorData) => {
  return OPTIMIZE_DATA.map((item) => {
    const type = rangeSelectorData.targetSelectorType
    const targetRevenueGrowing = rangeSelectorData.targetRevenueSelected > REVENUE_MEDIUM
    const targetReplenishmentCostGrowing = rangeSelectorData.targeReplenishmentCostSelected > REPLENISHMENT_COST_MEDIUM
    const targetRevenueMax = REVENUE_MIN_MAX[1]
    const targetRevenueMin = REVENUE_MIN_MAX[0]
    const targetReplenishmentCostMax = REPLENISHMENT_COST_MIN_MAX[1]
    const targetReplenishmentCostMin = REPLENISHMENT_COST_MIN_MAX[0]

    const factorRevenueCalculation = (targetRevenueGrowing ? (
      (rangeSelectorData.targetRevenueSelected - targetRevenueMin) / (targetRevenueMax - targetRevenueMin)
    ) : (
      (targetRevenueMax - rangeSelectorData.targetRevenueSelected) / (targetRevenueMax - targetRevenueMin)
    )) - 0.5

    const factorReplenishmentCostCalculation = (targetReplenishmentCostGrowing ? (
      ((rangeSelectorData.targeReplenishmentCostSelected - targetReplenishmentCostMin) / (targetReplenishmentCostMax - targetReplenishmentCostMin))
    ) : (
      ((targetReplenishmentCostMax - rangeSelectorData.targeReplenishmentCostSelected) / (targetReplenishmentCostMax - targetReplenishmentCostMin))
    )) - 0.5

    const getFactor = () => {
      if (type === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE) {
        return factorRevenueCalculation
      }

      if (type === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST) {
        return factorReplenishmentCostCalculation
      }

      return factorRevenueCalculation
    }

    const factor = getFactor()

    const quantity = item.restockQuantity === 0 ? 0 : randomizeNumber(item.restockQuantity, factor, targetRevenueGrowing || targetReplenishmentCostGrowing)

    return {
      ...item,
      stockStatus: getStockStatus(quantity, item.stockStatus),
      restockQuantity: quantity,
    }
  })
}
