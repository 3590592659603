import React from 'react'

const EditFlowIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={EditFlowIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.43025 15.737L0.644531 17.357L2.26453 11.5713L12.8588 1.02844C12.9786 0.905982 13.1216 0.808681 13.2794 0.742251C13.4373 0.675821 13.6068 0.641602 13.7781 0.641602C13.9494 0.641602 14.1189 0.675821 14.2768 0.742251C14.4346 0.808681 14.5776 0.905982 14.6974 1.02844L16.9731 3.31701C17.0936 3.43654 17.1893 3.57874 17.2545 3.73541C17.3198 3.89209 17.3534 4.06014 17.3534 4.22987C17.3534 4.3996 17.3198 4.56765 17.2545 4.72433C17.1893 4.881 17.0936 5.0232 16.9731 5.14273L6.43025 15.737Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default EditFlowIcon
