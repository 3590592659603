import { ASC_SORTING_ORDER } from '@constants/filters.constants'
import { IntlShape } from 'react-intl'

/**
 * Perform search in list of objects
 *
 * @param {Array} list List of object
 * @param {Array} keys List of keys to check
 * @param {String} searchValue Search string
 *
 * @returns {Array} Filtered array
 */
export const searchByKeys = (
  list: ListUtils.List,
  keys: string[],
  searchValue: string,
) => {
  if (!list || list.length === 0) {
    return []
  }

  return list.filter((item) => {
    let searchResult = false
    const searchTerm = (searchValue || '').toLowerCase()
    const valuesToSearch = keys.map((key) => {
      const keyValue = item[key] || ''

      return keyValue.toLowerCase()
    })

    if (!searchValue) {
      return true
    }

    valuesToSearch.forEach((itemValue) => {
      if (itemValue.includes(searchTerm)) {
        searchResult = true
      }
    })

    return searchResult
  })
}

/**
 * Sorts list by key
 *
 * @param {Array} list Original List
 * @param {String} key Key to sort by
 * @param {String} direction Sorting direction
 * @param {Object} options Options (numberic, date)
 *
 * @returns {Array} Sorted list
 */
export const sortByKey = <T extends ListUtils.List>(
  list: T,
  key: string,
  direction?: ListUtils.Direction,
  options: {
    numeric: boolean,
    date: boolean,
  } = {
    numeric: false,
    date: false,
  },
) => {
  const {
    numeric = false,
    date = false,
  } = options

  const isAsc = direction === ASC_SORTING_ORDER
  const shouldReverse = (numeric || date)

  return list.sort((a, b) => {
    const xValue = date ? new Date(a[key]) : a[key]
    const yValue = date ? new Date(b[key]) : b[key]
    const x = typeof xValue === 'string' ? xValue.toLowerCase() : xValue
    const y = typeof yValue === 'string' ? yValue.toLowerCase() : yValue

    let result = 0

    if (x < y) {
      result = -1
    } else if (x > y) {
      result = 1
    }

    if (isAsc) {
      return shouldReverse ? result : -result
    }

    return shouldReverse ? -result : result
  })
}

/**
 * Sorts list by key
 *
 * @param {Array} list Original List
 * @param {String} labelKey Key to sort by
 * @param {Boolean} direction Direction
 * @param {Object} intl React Intl
 *
 * @returns {Array} Sorted list
 */
export const sortByLabelKey = (
  list: ListUtils.List,
  labelKey: string,
  direction: ListUtils.Direction,
  intl: IntlShape,
) => {
  return list.sort((a, b) => {
    const aValue = intl.formatMessage({ id: a[labelKey] }).toLowerCase()
    const bValue = intl.formatMessage({ id: b[labelKey] }).toLowerCase()
    const directionUp = ((aValue < bValue) ? ((aValue > bValue) ? 1 : 0) : -1)
    const directionDown = ((aValue < bValue) ? -1 : ((aValue > bValue) ? 1 : 0))

    return direction === ASC_SORTING_ORDER ? directionUp : directionDown
  })
}
