import React from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

import {
  Box,
  Table, TableBody, TableCell,
  TableHead, TableRow,
  useTheme,
} from '@mui/material'

import SwitchFieldComponent from '@base/forms/SwitchField'
import SelectFieldComponent from '@base/forms/SelectField'

export interface StreamConfigFieldWidgetProps {
  /**
   * Streams to be rendered
   */
  streams: Hermes.FormStreams,
  /**
   * Handle change event
   */
  handleChange: any,
}

const StreamConfigFieldWidget: React.FC<StreamConfigFieldWidgetProps> = ({ streams, handleChange }) => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Box
      data-testid={StreamConfigFieldWidget.name}
      sx={{
        borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.new.grey_a}`,
      }}
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({ id: 'connectors.modal.stream' })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'connectors.modal.sync_mode' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(streams).map((key: string) => {
            const supportedSyncModes = streams[key].supportedSyncModes.map((mode) => {
              return { label: mode, value: mode }
            })

            return (
              <TableRow key={key}>
                <TableCell>
                  <SwitchFieldComponent
                    key={key}
                    name={`streams.${key}.isSelected`}
                    label={streams[key].name}
                    value={streams[key].isSelected}
                    checked={streams[key].isSelected}
                    onChange={handleChange}
                  />
                </TableCell>
                <TableCell>
                  <SelectFieldComponent
                    name={`streams.${key}.syncMode`}
                    label='syncMode'
                    hideLabel={true}
                    disabled={!streams[key].isSelected}
                    options={supportedSyncModes}
                    value={streams[key].syncMode}
                    onChange={handleChange}
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export default StreamConfigFieldWidget
