import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-premium'

import {
  SxProps,
  Theme,
} from '@mui/material'

import DataGridTableEmptyOverlayComponent from '@base/datagrid/data-grid-table-empty-overlay'

export interface DataGridEmptyOverlayComponentProps {
  labels?: string[]
  sx?: SxProps<Theme>
}

export const DataGridEmptyOverlayComponent: React.FC<DataGridEmptyOverlayComponentProps> = ({
  labels = [],
  sx,
}) => {
  const intl = useIntl()
  const apiRef = useGridApiContext()
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const quickFilterValues = useMemo(() => {
    return (filterModel.quickFilterValues || []).filter((value) => value !== '')
  }, [filterModel])

  const filterValues = useMemo(() => {
    if (!filterModel || !filterModel.items.length) {
      return []
    }

    return filterModel.items.filter((item) => (item.value !== '' && item.value !== undefined)).map((item) => item.field)
  }, [filterModel])

  const labelsToUse = useMemo(() => {
    const hasFilters = (filterValues.length > 0) || (quickFilterValues.length > 0)

    if (hasFilters && apiRef.current && apiRef.current.getLocaleText) {
      return [apiRef.current.getLocaleText('noResultsOverlayLabel')]
    }

    if (labels.length > 0) {
      return labels
    }
    return [intl.formatMessage({ id: 'common.no_data' })]
  }, [filterValues, quickFilterValues, apiRef, labels, intl])

  return (
    <DataGridTableEmptyOverlayComponent
      labels={labelsToUse}
      sx={sx}
    />
  )
}

export default DataGridEmptyOverlayComponent
