import React from 'react'
import GoogleButton from 'react-google-button'
import { useIntl } from 'react-intl'

import {
  Box, SxProps, Theme,
  Typography, useTheme,
} from '@mui/material'

export interface GoogleAuthButtonComponentProps {
  /**
   * Function to be called when the button is clicked
   */
  onClick: React.MouseEventHandler<HTMLDivElement>,
  /**
   * If 'true', the button will be disabled
   */
  disabled?: boolean,
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles
   */
  sx?: SxProps<Theme>,
}

/**
 * Google Auth Button. Validated by the Google review team.
 * Please, do not change the button's color or text.
 */
export const GoogleAuthButtonComponent: React.FC<GoogleAuthButtonComponentProps> = ({
  onClick, disabled, sx,
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      data-testid={GoogleAuthButtonComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <GoogleButton
        type='dark'
        disabled={disabled}
        onClick={onClick}
      />

      <Typography
        sx={{
          maxWidth: '600px',
          lineHeight: '18px',
          marginTop: theme.spacing(2),
          color: theme.palette.new.grey_c,
          '& a': {
            cursor: 'pointer',
            color: theme.palette.new.black,
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        }}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'export.button.export_google_disclosure' }),
        }}
      />
    </Box>
  )
}

export default GoogleAuthButtonComponent
