import React from 'react'
import { Box } from '@mui/material'

import {
  BarChart, Bar,
  Cell, ResponsiveContainer,
} from 'recharts'

import palette from '@configuration/theme/theme.palette'

export interface SliderBarCount {
  num: number,
  firstStep: number,
  secStep: number,
}

export type SliderBarValue = [number, number]

export interface SliderBarChartComponentProps {
  counts?: SliderBarCount[],
  value?: SliderBarValue,
}

const SliderBarChartComponent: React.FC<SliderBarChartComponentProps> = ({ counts, value }) => {
  if (!counts || !value) {
    return null
  }

  return (
    <Box
      data-testid={SliderBarChartComponent.name}
      sx={{
        position: 'absolute',
        height: '60px',
        width: '100%',
        top: '-25px',
        zIndex: 1,
      }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          data={counts}
          barCategoryGap={1}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Bar dataKey='num' minPointSize={1}>
            {
              counts.map((entry, index) => {
                const leftPickerValue = value[0]
                const rightPickerValue = value[1]
                const isSelected = (entry.firstStep >= leftPickerValue && entry.secStep <= rightPickerValue)

                return (
                  <Cell
                    key={`fill-${index + 1}-${entry.num}`}
                    fill={isSelected ? palette.new.black : palette.new.grey_a}
                    strokeWidth={0}
                  />
                )
              })
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default SliderBarChartComponent
