import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

import DateRangeSliderComponent from '@base/dates/DateRangeSlider'
import DateRangePickerComponent from '@base/dates/DateRangePicker'
import AbcClassificationSelectorComponent from '@components/insights/AbcClassificationSelector'
import InsightsFiltersLayoutComponent from '@components/insights/InsightsFiltersLayout'

import { foldsToOptions } from '@utils/monitor.utils'
import { requestBacktestingGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { getInsightsAbcTotals } from '@redux/modules/insights/insights.selectors'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'

import {
  getBacktestingChart, getBacktestingTable,
  isFetchingBacktestingChart,
  getBacktestingAvailableFolds,
  isFetchingBacktesting,
} from '@redux/modules/monitor/monitor.selectors'

const BacktestingFiltersContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const backtestingTable = useSelector((state) => getBacktestingTable(state))
  const backtestingChart = useSelector((state) => getBacktestingChart(state))
  const isFetchingBacktestingChartFlag = useSelector((state) => isFetchingBacktestingChart(state))
  const isFetchingBacktestingFlag = useSelector((state) => isFetchingBacktesting(state))
  const isFetching = isFetchingBacktestingChartFlag || isFetchingBacktestingFlag
  const abcTotals = useSelector((state) => getInsightsAbcTotals(state))
  const backtestingAvailableFolds = useSelector((state) => getBacktestingAvailableFolds(state))
  const userLanguage = useSelector((state) => getSelectedLanguage(state))

  const {
    gridState,
    targetName,
  } = backtestingTable

  const {
    abcClassification,
  } = backtestingChart

  const timeWindow = useMemo(() => (gridState && gridState.timeWindow), [gridState])
  const backtestingFold = useMemo(() => (gridState && gridState.backtestingFold), [gridState])
  const backtestingFoldsOptions = useMemo(() => foldsToOptions(intl, backtestingAvailableFolds), [intl, backtestingAvailableFolds])

  const handleDatesChange = (value: Common.DatePickerValue) => {
    dispatch(
      requestBacktestingGridStateChangeAction({
        timeWindow: value,
      }),
    )
  }

  const handleViewResultAsOfChange = (value: number | string) => {
    dispatch(
      requestBacktestingGridStateChangeAction({
        backtestingFold: Number(value),
      }),
    )
  }

  const handleAbcClassificationChange = (value: Hera.APIABCFilter) => {
    dispatch(
      requestBacktestingGridStateChangeAction({
        abcFilter: value,
      }),
    )
  }

  const leftSideSection = (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      gap={2}
    >
      <DateRangePickerComponent
        overline={intl.formatMessage({ id: 'insights.timeFrame' })}
        onChange={handleDatesChange}
        value={timeWindow}
        name='timeWindow'
        disabled={!gridState}
        userLanguage={userLanguage}
        hiddenShortcuts={[
          DATE_PICKER_SHORTCUT.FROM_TODAY,
          DATE_PICKER_SHORTCUT.FROM_LAST_WEEK,
          DATE_PICKER_SHORTCUT.FROM_THIS_MONTH,
          DATE_PICKER_SHORTCUT.FROM_THIS_YEAR,
        ]}
      />

      <DateRangeSliderComponent
        overline={intl.formatMessage({ id: 'insights.viewResultAsOf' })}
        onChange={handleViewResultAsOfChange}
        value={backtestingFold!}
        name='viewResultAsOf'
        loading={isFetchingBacktestingFlag}
        disabled={!gridState}
        options={backtestingFoldsOptions}
        reversed={true}
      />
    </Box>
  )

  const abcClassificationSection = (
    <AbcClassificationSelectorComponent
      abcClassification={abcClassification}
      abcTotals={abcTotals}
      targetName={targetName}
      gridState={gridState!}
      selectedValue={gridState?.abcFilter}
      loading={isFetching}
      onClick={handleAbcClassificationChange}
    />
  )

  return (
    <InsightsFiltersLayoutComponent
      dataTestId={BacktestingFiltersContainer.name}
      leftSideSection={leftSideSection}
      abcSection={abcClassificationSection}
    />
  )
}

export default BacktestingFiltersContainer
