import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const maxFilterValueLength = 60

interface ValidationScheme {
  name: string,
  unitLabel?: string,
}

const connectGroupingValidations = (intl: IntlShape) => {
  const scheme: Yup.SchemaOf<ValidationScheme> = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'use_cases.form.parameter.name.required' })),
    unitLabel: Yup.string(),
    filterValues: Yup.array().of(
      Yup.string().max(maxFilterValueLength, intl.formatMessage({ id: 'use_cases.form.filter_value.max' }, { maxFilterValueLength })),
    ),
  })

  return scheme
}

export default connectGroupingValidations
