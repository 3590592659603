import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory, generatePath } from 'react-router-dom'
import { Box } from '@mui/material'

import palette from '@configuration/theme/theme.palette'

import { DATA_FILE_LINK_PATH, DATA_UPLOAD_PATH } from '@constants/routes.constants'
import { PROCESSING_STATES } from '@constants/files.constants'

import ExpandLessIcon from '@icons/expandLess.icon'
import ExpandMoreIcon from '@icons/expandMore.icon'
import WarningIcon from '@icons/flow/warning.icon'
import CheckMarkIcon from '@icons/flow/checkMark.icon'
import RefreshIcon from '@icons/flow/refresh.icon'
import ChipComponent from '@base/chips/Chip'

import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium'
import { getFileProcessingLabelKey } from '@utils/training-data.utils'

const getChipIcon = (status: PROCESSING_STATES) => {
  switch (status) {
    case PROCESSING_STATES.ERROR:
    case PROCESSING_STATES.FAILED:
      return WarningIcon
    case PROCESSING_STATES.NO_PROCESSING:
    case PROCESSING_STATES.SUCCEEDED:
      return CheckMarkIcon
    case PROCESSING_STATES.QUEUED:
    case PROCESSING_STATES.RUNNING:
    default:
      return RefreshIcon
  }
}

const getChipColor = (status: PROCESSING_STATES) => {
  switch (status) {
    case PROCESSING_STATES.ERROR:
    case PROCESSING_STATES.FAILED:
      return palette.new.rebellious_red
    case PROCESSING_STATES.NO_PROCESSING:
    case PROCESSING_STATES.SUCCEEDED:
      return palette.new.generous_green
    case PROCESSING_STATES.QUEUED:
    case PROCESSING_STATES.RUNNING:
    default:
      return 'rgba(0, 0, 0, 0.6)'
  }
}

const TrainingStatusChipComponent: React.FC<{
  useCaseId: string,
  reflectRouteChange: boolean
  params: GridRenderCellParams,
}> = ({
  useCaseId,
  reflectRouteChange,
  params,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const apiRef = useGridApiContext()
  const status = params.value
  const trainingDataFileId = params.row.trainingDataFileId
  const expandedDetailPanels = apiRef.current?.getExpandedDetailPanels?.() || []
  const isExpanded = (expandedDetailPanels.includes(trainingDataFileId) && status === PROCESSING_STATES.FAILED)

  const handleExpandClick = (status === PROCESSING_STATES.FAILED && params.row.processingStatus) ? () => {
    if (reflectRouteChange) {
      if (isExpanded) {
        history.push(generatePath(DATA_UPLOAD_PATH, {
          usecase: useCaseId,
          identifier: params.row.fileIdentifier!,
          version: params.row.version,
        }))
      } else {
        history.push(generatePath(DATA_FILE_LINK_PATH, {
          usecase: useCaseId,
          identifier: params.row.fileIdentifier!,
          version: params.row.version,
          file: trainingDataFileId,
        }))
      }
    }

    if (apiRef.current) {
      apiRef.current.toggleDetailPanel(trainingDataFileId)
    }
  } : undefined

  const EndIconComponent = isExpanded ? ExpandLessIcon : ExpandMoreIcon

  return (
    <Box
      data-testid={TrainingStatusChipComponent.name}
    >
      <ChipComponent
        StartIconComponent={getChipIcon(status)}
        color={getChipColor(status)}
        label={intl.formatMessage({ id: getFileProcessingLabelKey(status) })}
        EndIconComponent={status === PROCESSING_STATES.FAILED ? EndIconComponent : undefined}
        onEndIconClick={handleExpandClick}
        onClick={handleExpandClick}
        variant={isExpanded ? 'filled' : 'outlined'}
        textTransform='lowercase'
      />
    </Box>
  )
}

export default TrainingStatusChipComponent
