import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import InfoButtonComponent from '@base/buttons/InfoButton'
import AddNodeButtonComponent from '@components/connect-view/view/AddNodeButton'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { useSelector } from '@redux/hooks'

export interface ReactFlowBlockHeaderComponentProps {
  /**
   * Title of the block
   */
  name?: string,
  /**
   * Help text for the block
   */
  help?: string,
  /**
   * if true, text and elements will be white
   */
  white?: boolean,
  /**
   * if true, ignore frozen status and allow creation
   * Ussually used for regular input blocks
   *
   * @default false
   */
  ignoreFrozen?: boolean,
  /**
   * Parent component name for testing
   */
  parentComponentName?: string,
  /**
   * If true, add button will be available
   */
  isAddButtonAvailable?: boolean,
  /**
   * On add button click handler
   */
  onAddButtonClick?: {
    (e?: React.SyntheticEvent): any,
  },
}

const ReactFlowBlockHeaderComponent: React.FC<ReactFlowBlockHeaderComponentProps> = ({
  isAddButtonAvailable,
  onAddButtonClick,
  name,
  help,
  white,
  parentComponentName,
  ignoreFrozen = false,
}) => {
  const theme = useTheme()
  const frozen = useSelector((state) => getUseCaseFreezeStatus(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const creationDisabled = !isAdmin && (frozen && !ignoreFrozen)

  const infoTooltip = help ? (
    <InfoButtonComponent
      name='parameterBlockHelp'
      tooltip={help}
      placement='top'
      IconComponentProps={{
        detailsFill: white ? theme.palette.new.white : theme.palette.new.black,
      }}
    />
  ) : null

  return (
    <Box
      data-testid={ReactFlowBlockHeaderComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: theme.spacing(2.8),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <Typography
          variant='subtitle1'
          color={white ? theme.palette.new.white : theme.palette.new.business_black}
        >
          {name}
        </Typography>

        {infoTooltip}
      </Box>

      {
        isAddButtonAvailable && !creationDisabled ? (
          <AddNodeButtonComponent
            onClick={onAddButtonClick}
            dataTestId={`${ReactFlowBlockHeaderComponent.name}-${parentComponentName}`}
          />
        ) : (
          null
        )
      }
    </Box>
  )
}

export default ReactFlowBlockHeaderComponent
