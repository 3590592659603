import { createAction } from 'redux-actions'

import {
  RECEIVE_CONNECTIONS,
  REQUEST_DETAILED_CONNECTIONS,
  RECEIVE_DETAILED_CONNECTIONS,
  REQUEST_DELETE_CONNECTION,
  REQUEST_TRANSFORMATIONS,
  RECEIVE_TRANSFORMATIONS,
  RECEIVE_REMOVED_CONNECTIONS,
  RECEIVE_SOURCES,
  REQUEST_ADD_CONNECTION,
  REQUEST_CONNECTIONS,
  REQUEST_REMOVE_CONNECTION,
  REQUEST_SOURCES,
  REQUEST_UPDATE_CONNECTION,
  START_FETCHING_HERMES,
  STOP_FETCHING_HERMES,
  MODIFY_TRANSFORMATION,
  CREATE_TRANSFORMATION,
  UPDATE_TRANSFORMATION,
  REMOVE_TRANSFORMATION,
  REQUEST_DESTINATIONS,
  RECEIVE_DESTINATIONS,
  REQUEST_PUBLISHER,
  REQUEST_PUBLISHERS_LIST,
  CREATE_PUBLISHER,
  UPDATE_PUBLISHER,
  REMOVE_PUBLISHER,
  RECEIVE_PUBLISHERS_LIST,
  RECEIVE_PUBLISHER,
  RECEIVE_ARTIFACTS_EXPORT_DETAILS,
  REQUEST_ARTIFACTS_EXPORT_DETAILS,
} from './hermes.action-types'

import {
  RequestRemoveConnectionActionPayload,
  ModifyTransformationActionPayload,
  FetchTransformationsByUseCaseIdActionPayload,
  FetchConnectionsWithDetailsActionPayload,
  RequestDeleteConnectionActionPayload,
  RequestAddConnectionActionPayload,
  RequestUpdateConnectionActionPayload,
  AddTransformationPayload,
  UpdateTransformationPayload,
  GetPublisherPayload,
  AddPublisherActionPayload,
  UpdatePublisherActionPayload,
  RemovePublisherActionPayload,
  GetPublishersListPayload,
  RequestArtifactExportDetailsActionPayload,
} from './hermes.types'

export const fetchAllSourcesAction = createAction<void>(REQUEST_SOURCES)
export const receiveSourcesActionDone = createAction(RECEIVE_SOURCES)

export const fetchAllDestinationsAction = createAction<void>(REQUEST_DESTINATIONS)
export const receiveDestinationsActionDone = createAction(RECEIVE_DESTINATIONS)

export const fetchConnectionsWithDetailsAction = createAction<FetchConnectionsWithDetailsActionPayload>(REQUEST_DETAILED_CONNECTIONS)
export const receiveConnectionsWithDetailsActionDone = createAction(RECEIVE_DETAILED_CONNECTIONS)

export const fetchConnectionsByCompanyIdAction = createAction<string>(REQUEST_CONNECTIONS)
export const receiveConnectionsByCompanyIdActionDone = createAction(RECEIVE_CONNECTIONS)

export const fetchTransformationsByUseCaseIdAction = createAction<FetchTransformationsByUseCaseIdActionPayload>(REQUEST_TRANSFORMATIONS)
export const receiveTransformationsByUseCaseIdActionDone = createAction(RECEIVE_TRANSFORMATIONS)

export const requestAddConnectionAction = createAction<RequestAddConnectionActionPayload>(REQUEST_ADD_CONNECTION)
export const requestUpdateConnectionAction = createAction<RequestUpdateConnectionActionPayload>(REQUEST_UPDATE_CONNECTION)
export const requestRemoveConnectionAction = createAction<RequestRemoveConnectionActionPayload>(REQUEST_REMOVE_CONNECTION)
export const requestDeleteConnectionAction = createAction<RequestDeleteConnectionActionPayload>(REQUEST_DELETE_CONNECTION)
export const receiveRemovedConnectionsAction = createAction(RECEIVE_REMOVED_CONNECTIONS)

export const modifyTransformationAction = createAction<ModifyTransformationActionPayload>(MODIFY_TRANSFORMATION)
export const createTransformationAction = createAction<AddTransformationPayload>(CREATE_TRANSFORMATION)
export const updateTransformationAction = createAction<UpdateTransformationPayload>(UPDATE_TRANSFORMATION)
export const removeTransformationAction = createAction<string>(REMOVE_TRANSFORMATION)

export const fetchPublishersListAction = createAction<GetPublishersListPayload>(REQUEST_PUBLISHERS_LIST)
export const receivePublishersListActionDone = createAction(RECEIVE_PUBLISHERS_LIST)
export const fetchPublisherAction = createAction<GetPublisherPayload>(REQUEST_PUBLISHER)
export const receivePublisherActionDone = createAction(RECEIVE_PUBLISHER)
export const createPublisherAction = createAction<AddPublisherActionPayload>(CREATE_PUBLISHER)
export const updatePublisherAction = createAction<UpdatePublisherActionPayload>(UPDATE_PUBLISHER)
export const removePublisherAction = createAction<RemovePublisherActionPayload>(REMOVE_PUBLISHER)

export const requestArtifactExportDetails = createAction<RequestArtifactExportDetailsActionPayload>(REQUEST_ARTIFACTS_EXPORT_DETAILS)
export const receiveArtifactExportDetails = createAction(RECEIVE_ARTIFACTS_EXPORT_DETAILS)

export const startFetchingHermesAction = createAction<string>(START_FETCHING_HERMES)
export const stopFetchingHermesAction = createAction<string>(STOP_FETCHING_HERMES)
