import React from 'react'
import { useIntl } from 'react-intl'
import { Box, useTheme } from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'

import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Text,
  CartesianGrid,
  Tooltip,
  Bar,
  Cell,
  ReferenceLine,
} from 'recharts'

import {
  getArrowHead,
  getXLabelProps,
  getXAxisProps,
  getYAxisProps,
  getYTextProps,
  getLineProps,
  getCartesianGridProps,
  getTooltipProps,
  getReferenceLabelProps,
} from '@utils/svg.utils'

import palette from '@configuration/theme/theme.palette'
import ChartTooltipComponent from '@components/charts/ChartTooltip'
import ChartLegendComponent from '@components/charts/ChartLegend'
import { getStartOfTheWeek } from '@utils/moment.utils'

export interface OptimizeDrillDownChartComponentProps {
  dataset?: Optimize.OptimizeDrillDownItemDataItem[],
  gradientPercentage?: number,
}

const OptimizeDrillDownChartComponent: React.FC<OptimizeDrillDownChartComponentProps> = (props) => {
  const {
    dataset,
    gradientPercentage,
  } = props

  const intl = useIntl()
  const theme = useTheme()

  const itemsLength = dataset?.length || 0

  const stockNetComputedKey = 'stockNetComputed'
  const salesComputedKey = 'salesComputed'
  const reorderKey = 'reorder'
  const dateKey = 'date'
  const todayDate = getStartOfTheWeek()

  return (
    <Box
      data-testid={OptimizeDrillDownChartComponent.name}
      sx={{
        width: '100%',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.new.business_black_20}`,
        height: '480px',
        minWidth: '500px',
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          marginRight: theme.spacing(0),
          marginBottom: theme.spacing(3),
        },
      }}
    >
      <Box
        sx={{
          height: '390px',
          width: '99%',
        }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <ComposedChart
            data={dataset}
            margin={{
              top: 40,
              right: 40,
              left: 40,
              bottom: 20,
            }}
          >
            <CartesianGrid
              {...getCartesianGridProps(true, false)}
            />

            <Tooltip
              {...getTooltipProps(false, { x: false, y: false })}
              content={(
                <ChartTooltipComponent<Optimize.OptimizeDrillDownItemDataItem>
                  rows={[{
                    key: dateKey,
                    numeric: false,
                    label: intl.formatMessage({ id: 'optimize.chart.date.tooltip' }),
                  }, {
                    key: stockNetComputedKey,
                    label: intl.formatMessage({ id: 'optimize.chart.netStock.tooltip' }),
                    numeric: true,
                    legendColor: palette.new.gainful_grey,
                  }, {
                    key: salesComputedKey,
                    label: intl.formatMessage({ id: 'optimize.chart.sales' }),
                    numeric: true,
                    legendColor: palette.new.versatile_violet,
                  }, {
                    key: reorderKey,
                    label: intl.formatMessage({ id: 'optimize.chart.reorder' }),
                    numeric: true,
                    hideIfNull: true,
                    legendColor: (data) => {
                      if (data[dateKey] === todayDate) {
                        return 'linear-gradient(45deg, #F500E1 0%, #F500E1 0.01%, #FBB03B 100%)'
                      }

                      return palette.new.generous_green
                    },
                  }]}
                />
              )}
            />

            <ReferenceLine
              x={todayDate}
              stroke={palette.new.black}
              strokeWidth={1}
            >
              <Label
                value={intl.formatMessage({ id: 'optimize.chart.today' })}
                {...getReferenceLabelProps()}
              />
            </ReferenceLine>

            <ReferenceLine y={0} stroke={palette.new.business_black_20} className='optimize-zero-x' />

            <defs>
              <marker
                id='optimizeZeroX'
                markerUnits='strokeWidth'
                markerWidth='5'
                markerHeight='8'
                viewBox='0 0 5 8'
                refX='4'
                refY='4'
                orient='0deg'
                stroke={palette.new.business_black_20}
                // eslint-disable-next-line
                fill='none'
              >
                <path d='M1 1L4 4L1 7' />
              </marker>
              <linearGradient id={`gradient_${itemsLength}`} x1='0' y1='0' x2='1' y2='0'>
                <stop offset='0%' stopColor={palette.new.versatile_violet} />
                <stop offset={`${gradientPercentage}%`} stopColor={palette.new.versatile_violet} />
                <stop offset={`${gradientPercentage}%`} stopColor={palette.new.pink} />
                <stop offset='100%' stopColor={palette.new.pink} />
              </linearGradient>
              <linearGradient id={`gradient_cell_${itemsLength}`} x1='-0.03%' y1='99.97%' x2='100.03%' y2='.03%'>
                <stop stopColor='#F500E1' />
                <stop offset='.001' stopColor='#F500E1' />
                <stop offset='1' stopColor='#FBB03B' />
              </linearGradient>
            </defs>

            <Bar
              dataKey={stockNetComputedKey}
              barSize={10}
              stackId='a'
            >
              {
                dataset?.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.stockNetPredictedFlag ? palette.new.pink : palette.new.gainful_grey}
                      radius={[10, 10, 0, 0] as any}
                    />
                  )
                })
              }
            </Bar>

            <Bar
              dataKey={reorderKey}
              barSize={10}
              stackId='a'
            >
              {
                dataset?.map((entry, index) => {
                  const isTodayCell = entry[dateKey] === todayDate

                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={isTodayCell ? `url(#gradient_cell_${itemsLength})` : palette.new.generous_green}
                      radius={10}
                      opacity={isTodayCell ? 1 : 0.4}
                    />
                  )
                })
              }
            </Bar>

            <Line
              {...getLineProps(salesComputedKey, palette.new.versatile_violet, false)}
              stroke={`url(#gradient_${itemsLength})`}
            />

            {getArrowHead(false, true)}

            <XAxis
              {...getXAxisProps()}
              dataKey={dateKey}
            >
              <Label
                {...getXLabelProps()}
                value={intl.formatMessage({ id: 'optimize.chart.date' })}
              />
            </XAxis>

            <YAxis
              {...getYAxisProps()}
              dataKey={stockNetComputedKey}
              domain={['auto', 'auto']}
              tickFormatter={(value) => String(defaultNumberFormatter(value))}
            >
              <Label
                content={(
                  <Text
                    {...getYTextProps()}
                  >
                    {intl.formatMessage({ id: 'optimize.chart.netStock' })}
                  </Text>
                )}
              />
            </YAxis>

          </ComposedChart>
        </ResponsiveContainer>
      </Box>

      <ChartLegendComponent
        items={[{
          label: intl.formatMessage({ id: 'optimize.chart.netStock' }),
          color: palette.new.gainful_grey,
        }, {
          label: intl.formatMessage({ id: 'optimize.chart.reorder' }),
          color: `${palette.new.generous_green}66`,
        }, {
          label: intl.formatMessage({ id: 'optimize.chart.sales' }),
          color: palette.new.versatile_violet,
        }, {
          label: intl.formatMessage({ id: 'optimize.chart.salesPrediction' }),
          color: palette.new.pink,
        }]}
      />
    </Box>
  )
}

export default OptimizeDrillDownChartComponent
