import { COCKPIT_BUILD_ENV } from '@constants/env-replacements.constants'

/**
 * Error codes below are used to ignore errors in sentry.
 *  CANCELED_REQUEST_ERROR - error when request is canceled, when user changes route very quickly
 *  CANCELED_ERROR_CODE - error code for canceled request, when file is canceled by user
 *  NETWORK_ERROR_CODE - error code for network error when request is failed due to network issue on client side
 */
export const CANCELED_REQUEST_ERROR = 'Canceled request via changing route'
export const CANCELED_ERROR_CODE = 'ERR_CANCELED'
export const NETWORK_ERROR_CODE = 'ERR_NETWORK'
export const ERROR_CODES_TO_IGNORE_IN_SENTRY = [CANCELED_ERROR_CODE, NETWORK_ERROR_CODE]

/**
 * Those are the reasons codes which are returned by the backend when:
 *  NO_INSIGHTS_AVAILABLE - insights are not available
 *  PIPELINE_RUN_NOT_FOUND - pipeline run not found
 *  COMPOSITE_PIPELINE_RUN_NOT_FOUND - composite pipeline run not found
 *  RECOMMENDATION_ARTIFACTS_NOT_FOUND- recommendation artifacts not found
 * We don't want to report these errors to sentry as they are expected when use-case is not yet ready.
 */
export const NO_INSIGHTS_AVAILABLE = '87f20432-32a2-4ec7-8193-e40d5607f1af'
export const PIPELINE_RUN_NOT_FOUND = '92f0a913-cebb-4ff4-bd9e-72f6cfd7e94b'
export const RECOMMENDATION_ARTIFACTS_NOT_FOUND = '535e84ee-e33b-4c6b-8b5d-2680e9754fdc'
export const COMPOSITE_PIPELINE_RUN_NOT_FOUND = '8b95e761-06e1-4987-800c-c70090c02a6e'
export const BACK_END_REASONS_TO_IGNORE_IN_SENTRY = [NO_INSIGHTS_AVAILABLE, PIPELINE_RUN_NOT_FOUND, RECOMMENDATION_ARTIFACTS_NOT_FOUND, COMPOSITE_PIPELINE_RUN_NOT_FOUND]

export const TOAST_TYPE_INFO = 'info'
export const TOAST_TYPE_WARNING = 'warning'
export const TOAST_TYPE_ERROR = 'error'
export const TOAST_TYPE_SUCCESS = 'success'
export const TOAST_TYPE_NONE = TOAST_TYPE_INFO

export const SENTRY_DNS = 'https://3158cef7ca834e8ead66c6eb28871220@o1034873.ingest.sentry.io/6020805'

export const IS_LOCAL_ENV = process.env.NODE_ENV === 'development'
export const IS_DEV_ENV = COCKPIT_BUILD_ENV === 'dev'
export const ENV_TYPE = IS_DEV_ENV ? 'development' : 'production'
export const UPLOAD_DOCUMENTATION_URL = IS_DEV_ENV ? 'https://docs.dev.paretos.io/training_data_upload/' : 'https://docs.paretos.io/training_data_upload/'
