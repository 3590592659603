import React from 'react'

const LArrowIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={LArrowIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillOpacity='0' d="M12 16L5.37692 9.37692C5.32541 9.32858 5.28436 9.27019 5.25629 9.20536C5.22823 9.14053 5.21375 9.07064 5.21375 9C5.21375 8.92936 5.22823 8.85946 5.25629 8.79464C5.28436 8.72981 5.32541 8.67142 5.37692 8.62308L12 2" stroke={detailsFill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default LArrowIcon
