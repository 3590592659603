import React from 'react'

const SortDescIcon: React.FC<Common.IconProps> = ({
  width = 24, height = 11,
  viewBox = '0.83 25.56 21.94 9.86', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={SortDescIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.462 35.424V28.098L9.828 31.464L10.764 30.528L5.796 25.56L0.828 30.528L1.764 31.464L5.13 28.098V35.424H6.462Z" fill={detailsFill}/>
      <path opacity="0.4" d="M17.13 32.886L13.764 29.52L12.828 30.456L17.796 35.424L22.764 30.456L21.828 29.52L18.462 32.886V25.56H17.13V32.886Z" fill={detailsFill}/>

    </svg>
  )
}

export default SortDescIcon
