import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, useTheme } from '@mui/material'

import {
  Bar,
  ComposedChart,
  BarChart,
  LineChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Text,
  Tooltip, XAxis, YAxis,
  Line,
} from 'recharts'

import ChartTooltipComponent, { TooltipRow } from '@components/charts/ChartTooltip/ChartTooltip.component'
import ChartLegendComponent, { ChartLegendItem } from '@components/charts/ChartLegend/ChartLegend.component'

import {
  ChartConfig, getContainerProps, getTooltipFormattedAxis,
  getArrowHead, getCartesianGridProps, getChartWrapperProps,
  getTooltipProps, getXAxisProps, getXLabelProps, getYAxisProps,
  getYTextProps, getChartItemColor, getGradientsSet,
  getLineProps,
} from '@utils/svg.utils'

export interface VisualisationCombinedChartComponentProps {
  /**
   * Unique identifier of the x-axis key
   */
  xKey: string
  /**
   * Height of the chart
   *
   * @default '360px'
   */
  height?: string
  /**
   * Label of the x-axis
   */
  xLabel?: string
  /**
   * Label of the y-axis
   */
  yLabel?: string
  /**
   * Label of the unit
   */
  unitLabel?: string
  /**
   * Chart configuration
   */
  chartConfig?: ChartConfig
  /**
   * Data to be displayed
   */
  data?: RecommendationArtifacts.VisualisationDataItem[]
  /**
   * Items (lines/bars) to be displayed
   */
  items?: RecommendationArtifacts.VisualisationItem[]
  /**
   * Tooltip rows to be displayed
   */
  tooltipRows?: TooltipRow<RecommendationArtifacts.VisualisationDataItem>[]
  /**
   * Legend rows to be displayed
   */
  legendRows?: ChartLegendItem[]
  /**
   * X-axis tick formatter
   */
  xTickFormatter?: (value: string) => string
  /**
   * Y-axis tick formatter
   */
  yTickFormatter?: (value: string) => string
}

const VisualizationComposedChartComponent: React.FC<VisualisationCombinedChartComponentProps> = ({
  xKey,
  xLabel,
  yLabel,
  unitLabel,
  data = [],
  items = [],
  tooltipRows = [],
  legendRows = [],
  xTickFormatter,
  yTickFormatter,
  chartConfig,
  height = '360px',
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const gradients = getGradientsSet()

  const lines = items.filter((item) => item.type === 'line')
  const bars = items.filter((item) => item.type === 'bar')
  const ChartBaseComponent = useMemo(() => {
    if (lines.length > 0 && bars.length > 0) {
      return ComposedChart
    }

    if (lines.length > 0) {
      return LineChart
    }

    if (bars.length > 0) {
      return BarChart
    }

    return ComposedChart
  }, [
    lines,
    bars,
  ])

  const chartWrapperProps = getChartWrapperProps(chartConfig)
  const containerProps = getContainerProps(chartConfig)
  const tooltipProps = getTooltipProps(false, { x: false, y: false })
  const cartesianGridProps = getCartesianGridProps(true, false)
  const maxBarSize = useMemo(() => {
    if (!chartConfig || !chartConfig.barChartBarSizes) {
      return undefined
    }

    if (chartConfig.barChartBarSizes === 'small') {
      return 10
    }

    if (chartConfig.barChartBarSizes === 'medium') {
      return 40
    }

    return undefined
  }, [chartConfig])

  const getBarRadius = useCallback((index: number, stackId?: string) => {
    const barsWithStack = bars.filter((item) => item.barStackId)
    const lastStackIndex = barsWithStack.length - 1

    if (chartWrapperProps.stackOffset === 'sign' || barsWithStack.length === 0 || !stackId) {
      return [3, 3, 0, 0] as [number, number, number, number]
    }

    if (barsWithStack.length > 0 && index === lastStackIndex) {
      return [3, 3, 0, 0] as [number, number, number, number]
    }

    // Issue with radius on top of stacked bars in recharts
    // https://github.com/recharts/recharts/issues/3887
    return undefined
  }, [chartWrapperProps, bars])

  return (
    <Box
      data-testid={VisualizationComposedChartComponent.name}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height,
        }}
      >
        <ResponsiveContainer
          {...containerProps}
        >
          <ChartBaseComponent
            data={data}
            {...chartWrapperProps}
          >

            {gradients}

            <CartesianGrid
              {...cartesianGridProps}
            />

            <Tooltip
              {...tooltipProps}
              cursor={{
                stroke: '#CCCCCC',
                fill: '#0000000D',
                cursor: 'pointer',
              }}
              content={(
                <ChartTooltipComponent
                  rows={tooltipRows}
                />
              )}
            />

            {
              bars.map((item, index) => {
                return (
                  <Bar
                    key={item.dataKey}
                    dataKey={item.dataKey}
                    stackId={item.barStackId}
                    radius={getBarRadius(index, item.barStackId)}
                    maxBarSize={maxBarSize}
                    fill={getChartItemColor(item.color, 'bar', false)}
                  />
                )
              })
            }

            {
              lines.map((item, index) => {
                return (
                  <Line
                    key={item.dataKey}
                    dataKey={item.dataKey}
                    {...getLineProps(item.dataKey, getChartItemColor(item.color, 'line'))}
                  />
                )
              })
            }

            <XAxis
              {...getXAxisProps(chartConfig)}
              dataKey={xKey}
              minTickGap={100}
              domain={['auto', 'auto']}
              tickFormatter={xTickFormatter}
              padding={{
                left: 0,
                right: xLabel ? 100 : 20,
              }}
            >
              <Label
                {...getXLabelProps()}
                value={xLabel}
                width={90}
                style={{ transform: 'translate(-15px, 0px)' }}
              />
            </XAxis>

            <YAxis
              {...getYAxisProps()}
              tickFormatter={yTickFormatter}
            >
              <Label
                content={(
                  <Text
                    {...getYTextProps()}
                    style={{ transform: 'translate(90px, 2px)' }}
                  >
                    {
                      getTooltipFormattedAxis(intl, yLabel, 'recommendation.results.chart.yAxis', unitLabel)
                    }
                  </Text>
                )}
              />
            </YAxis>

            {getArrowHead()}
          </ChartBaseComponent>
        </ResponsiveContainer>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        gap={1}
      >
        <ChartLegendComponent
          items={legendRows}
          sx={{
            padding: 0,
            paddingLeft: theme.spacing(10),
          }}
        />
      </Box>
    </Box>
  )
}

export default VisualizationComposedChartComponent
