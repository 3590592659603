import produce from 'immer'
import { ReducerPayload, CustomerState } from '@redux/modules/types'
import { getUserMessages } from '@decorators/ReactIntl.decorator'
import EN_LOCALE from '@locales/en-US'

export const initialState: CustomerState = {
  companyId: '',
  companyItem: {} as Customer.CompanyItem,
  companiesList: [],
  companyUsersList: [],
  userInfo: {
    sub: '',
    isAdmin: false,
  } as Common.UserInfo,
  intlMessages: {
    ...EN_LOCALE,
  },
  fetchingKeys: [],
}

export const receiveCompanyItemReducer = (state: CustomerState, action: ReducerPayload<Customer.CompanyItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.companyItem = action.payload
  })

  return nextState
}

export const changeUserInfoReducer = (state: CustomerState, action: ReducerPayload<Common.UserInfo>) => {
  const nextState = produce(state, (draftState) => {
    const selectedMessages = getUserMessages(action.payload.language)

    draftState.userInfo = action.payload

    draftState.intlMessages = {
      ...EN_LOCALE,
      ...selectedMessages,
    }
  })

  return nextState
}

export const changeCompanyIdReducer = (state: CustomerState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.companyId = action.payload
  })

  return nextState
}

export const receiveCompaniesListReducer = (state: CustomerState, action: ReducerPayload<Customer.CompanyItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.companiesList = action.payload
  })

  return nextState
}

export const receiveCompanyUsersListReducer = (state: CustomerState, action: ReducerPayload<Customer.UserItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.companyUsersList = action.payload
  })

  return nextState
}
