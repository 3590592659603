import { curveBumpX } from 'd3-shape'

import palette from '@configuration/theme/theme.palette'

export const X_DEFAULT_INDEX = 0
export const Y_DEFAULT_INDEX = 1

export const DEFAULT_AXIS_FONT_COLOR = 'rgba(0, 0, 0, 0.8)'
export const DEFAULT_AXIS_FONT_SIZE = '14px'
export const DEFAULT_AXIS_FONT_WEIGHT = 400

export const CHARTS_COLORWAY = Object.values(palette.colorWay)

export const DEFAULT_RECHARTS_CURVE = curveBumpX
export const DEFAULT_RECHARTS_LINE_WIDTH = 3
export const DEFAULT_RECHARTS_DOT = {
  fill: 'white',
  stroke: 'black',
  strokeWidth: 2,
  r: 2.5,
}
export const DEFAULT_RECHARTS_ACTIVE_DOT = {
  fill: 'white',
  stroke: 'black',
  strokeWidth: 1.5,
  r: 2.5,
}

export const DEFAULT_RECHARTS_CURSOR = {
  stroke: palette.new.business_black_20,
  fill: 'transparent',
  cursor: 'pointer',
}

export const CHARTS_FONT_SIZE = '16px'
export const DEFAULT_CHARTS_FONT_COLOR = 'black'
export const DEFAULT_CHARTS_FONT_SIZE = '11px'
export const DEFAULT_CHARTS_FONT_WEIGHT = 300
export const DEFAULT_CHARTS_FONT_FAMILY = 'AeonikPro, sans-serif'
export const DEFAULT_AXIS_UNIT = '#A6A6A6'
export const DEFAULT_CARTESIAN_GRID_STROKE = palette.new.grey_k
export const DEFAULT_AXIS_STROKE = palette.new.grey_j
export const DEFAULT_AXIS_TICKS_STROKE = palette.new.black_d
export const DEFAULT_FILL_COLOR = palette.new.versatile_violet
export const DEFAULT_STROKE_COLOR = palette.new.white
export const DEFAULT_BG_COLOR = palette.new.white
export const DEFAULT_SELECTED_COLOR = palette.new.versatile_violet
export const SUB_OPTIMAL_FILL_COLOR = palette.new.business_black_20
export const DEFAULT_RADIUS = 7
export const DEFAULT_SUB_OPTIMAL_RADIUS = 4
export const DEFAULT_SELECTED_RADIUS = 10
export const SUB_OPTIMAL_SELECTED_RADIUS = 10
export const DEFAULT_BUBBLE_SIZE = '0px'

export const MAX_SELECTED_POINTS = 3
export const SHOW_ALL_OPTION = 'SHOW_ALL_OPTION'
