import React, {
  useEffect,
  useMemo, useRef, useState,
} from 'react'

import { Box } from '@mui/material'
import { Moment } from 'moment'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { DEFAULT_DATE_PICKER_FORMAT } from '@utils/moment.utils'
import { SHORT_LOCALES } from '@constants/locales.constants'

import ChevronLeftIcon from '@icons/chevronLeft.icon'
import ChevronRightIcon from '@icons/chevronRight.icon'

import {
  DateRange, DateRangePicker,
  DateRangePickerProps,
  DateRangeValidationError, PickerChangeHandlerContext,
} from '@mui/x-date-pickers-pro'

import {
  SHORTCUT_OPTIONS,
  dateRangeToShortcutOption,
  getDatePickerLocales, getShortcutsItems,
} from './DateRangePicker.utils'

import DateRangePickerButtonComponent from './DateRangePickerButton.component'
import DateRangePickerRangeShortcutsComponent, { DateRangePickerRangeShortcutsPayload } from './DateRangePickerRangeShortcuts.component'

export const DEFAULT_VALUE: DateRange<Moment> = [null, null]

export interface DateRangePickerComponentProps extends DateRangePickerProps<Moment> {
  /**
   * Name of the component for tracking purposes
   */
  name: string,
  /**
   * Overline text
   */
  overline?: string,
  /**
   * On change callback
   * @param value Date range value
   */
  onChange?: (value: DateRange<Moment>) => void,
  /**
   * Shortcuts to hide
   */
  hiddenShortcuts?: DATE_PICKER_SHORTCUT[]
  /**
   * Date format
   * @default 'MMM D, YYYY'
   */
  format?: string,
  /**
   * User language
   */
  userLanguage?: SHORT_LOCALES,
}

export const DateRangePickerComponent: React.FC<DateRangePickerComponentProps> = ({
  onChange,
  overline = '',
  hiddenShortcuts = [],
  format = DEFAULT_DATE_PICKER_FORMAT,
  value = DEFAULT_VALUE,
  userLanguage = SHORT_LOCALES.en,
  name,
  ...rest
}) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const { params } = useRouteMatch<Common.RouterMatch>()

  const [open, setOpen] = useState(false)
  const [internalValue, setInternalValue] = useState<DateRange<Moment>>(value)

  const shortcutsItems = useMemo(() => getShortcutsItems(intl, hiddenShortcuts), [hiddenShortcuts, intl])
  const localeText = useMemo(() => getDatePickerLocales(userLanguage), [userLanguage])
  const selectedShortcutOption = useMemo(() => dateRangeToShortcutOption(internalValue), [internalValue])

  useEffect(() => {
    /**
     * Update internal value when value prop changes
     * The initial value might start as null, so we need to update the internal value when the value prop is set
     */
    setInternalValue(value)
  }, [value])

  const label = useMemo(() => {
    if (selectedShortcutOption === DATE_PICKER_SHORTCUT.CUSTOM) {
      return internalValue.map((date) => {
        return date ? date.locale(userLanguage).format(format) : ''
      }).filter((item) => item).join(' - ')
    }

    const labelKey = SHORTCUT_OPTIONS.find((item) => item.value === selectedShortcutOption)?.labelKey

    return intl.formatMessage({ id: labelKey })
  }, [intl, internalValue, format, selectedShortcutOption, userLanguage])

  const handleChange = (newValue: DateRange<Moment>, context: PickerChangeHandlerContext<DateRangeValidationError>) => {
    setInternalValue(newValue)
  }

  const handleAccept = (newValue: DateRange<Moment>) => {
    if (onChange) {
      onChange(newValue)

      trackEvent({
        componentName: name,
        actionName: TRACKING_ACTIONS.CHANGE,
      }, {
        dateRange: value,
        router: params,
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const dayOfWeekFormatter = (weekday: Moment) => {
    return `${weekday.format('dd').toUpperCase()}`
  }

  return (
    <Box
      data-testid={DateRangePickerComponent.name}
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <DateRangePicker
        open={open}
        onOpen={handleOpen}
        label={label}
        value={internalValue}
        localeText={localeText}
        onChange={handleChange}
        onClose={handleClose}
        calendars={1}
        showDaysOutsideCurrentMonth={true}
        dayOfWeekFormatter={dayOfWeekFormatter}
        onAccept={handleAccept}
        slots={{
          field: DateRangePickerButtonComponent,
          shortcuts: DateRangePickerRangeShortcutsComponent as any,
          leftArrowIcon: ChevronLeftIcon,
          rightArrowIcon: ChevronRightIcon,
          ...rest.slots,
        }}
        slotProps={{
          field: {
            setOpen,
            open,
            label,
            overline,
            ariaLabel: overline,
          } as any,
          popper: {
            anchorEl: ref.current,
          } as any,
          shortcuts: {
            name,
            routerParams: params,
            items: shortcutsItems,
            changeImportance: 'accept',
            selectedShortcutOption,
            minDate: rest.minDate,
            maxDate: rest.maxDate,
          } as DateRangePickerRangeShortcutsPayload,
          toolbar: {
            hidden: true,
          },
          ...rest.slotProps,
        }}
        {...rest}
      />
    </Box>
  )
}

export default DateRangePickerComponent
