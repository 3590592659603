import React from 'react'
import ButtonComponent, { ButtonComponentProps } from '@base/buttons/Button/Button.component'

export interface SidePanelActionButtonComponentProps extends Pick<ButtonComponentProps,
  'name' |
  'loading' |
  'onClick' |
  'label' |
  'disabled' |
  'forwardedRef' |
  'StartIconComponent'
> {
  /**
   * if true, the button will be hidden
   */
  hidden?: boolean
}

export const SidePanelActionButtonComponent: React.FC<SidePanelActionButtonComponentProps> = ({
  name,
  label,
  loading,
  disabled,
  onClick,
  StartIconComponent,
  hidden,
  ...props
}) => {
  if (hidden) {
    return null
  }

  return (
    <ButtonComponent
      color='tertiary'
      name={name}
      loading={loading}
      onClick={onClick}
      StartIconComponent={StartIconComponent}
      label={label}
      disabled={disabled}
      {...props}
    />
  )
}

export default React.forwardRef<React.FC, SidePanelActionButtonComponentProps>((props, ref) => <SidePanelActionButtonComponent {...props} forwardedRef={ref} />)
