import React from 'react'
import { Box } from '@mui/material'

import ChevronIcon from '@icons/flow/chevron.icon'
import SortIcon from '@icons/sort.icon'
import CloseIcon from '@icons/close.icon'
import MenuIcon from '@icons/menu.icon'
import FilterIcon from '@icons/filter.icon'
import SmallFilterIcon from '@icons/smallFilter.icon'
import ColumnsIcon from '@icons/columns.icon'
import HideIcon from '@icons/hide.icon'
import PinLeftIcon from '@icons/pinLeft.icon'
import PinRightIcon from '@icons/pinRight.icon'
import SumIcon from '@icons/flow/sum.icon'
import GroupIcon from '@icons/group.icon'
import UngroupIcon from '@icons/ungroup.icon'
import ChevronDownIcon from '@icons/chevronDown.icon'
import ChevronUpIcon from '@icons/chevronUp.icon'
import DeleteIcon from '@icons/delete.icon'
import PlusIcon from '@icons/plus.icon'
import TableHandleIcon from '@icons/tableHandle.icon'
import SearchIcon from '@icons/search.icon'

export const FilterMenuAddIcon = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <PlusIcon />
    </Box>
  )
}

export const GroupingCriteriaCollapseIcon = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <ChevronIcon />
    </Box>
  )
}

export const GroupingCriteriaExpandIcon = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        '& svg': {
          transform: 'rotate(-90deg)',
        },
      }}
    >
      <ChevronIcon />
    </Box>
  )
}

export const ColumnSortedAscendingIcon = (props: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <SortIcon
        detailsFill='#000000'
        opacity='1'
      />
    </Box>
  )
}

export const ColumnSortedDescendingIcon = (props: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        ...props?.sx,
        '& svg': {
          transform: 'rotate(-180deg)',
        },
      }}
      {...props}
    >
      <SortIcon
        detailsFill='#000000'
        opacity='1'
      />
    </Box>
  )
}

export const SmallFilterMenuIcon = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        '& svg': {
          minWidth: '14px',
        },
      }}
    >
      <SmallFilterIcon />
    </Box>
  )
}

export const QuickFilterIcon = SearchIcon

export const QuickFilterClearIcon = CloseIcon

export const ColumnMenuIcon = MenuIcon

export const FilterMenuIcon = FilterIcon

export const ColumnsMenuIcon = ColumnsIcon

export const HideMenuIcon = HideIcon

export const PinLeftMenuIcon = PinLeftIcon

export const PinRightMenuIcon = PinRightIcon

export const AggregationMenuIcon = SumIcon

export const GroupMenuIcon = GroupIcon

export const UngroupMenuIcon = UngroupIcon

export const ColumnMenuSortAscendingIcon = ChevronUpIcon

export const ColumnMenuSortDescendingIcon = ChevronDownIcon

export const FilterMenuDeleteIcon = CloseIcon

export const FilterMenuRemoveAllIcon = DeleteIcon

export const TableColumnSeparatorIcon = TableHandleIcon

export const DetailPanelExpandIcon = GroupingCriteriaExpandIcon

export const DetailPanelCollapseIcon = GroupingCriteriaCollapseIcon
