import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'

export interface FileBrowserPreviewHtmlComponentProps {
  /**
   * File preview data
   */
  filePreview: FileService.FilePreview
}

const FileBrowserPreviewHtmlComponent: React.FC<FileBrowserPreviewHtmlComponentProps> = ({
  filePreview,
}) => {
  const [html, setHtml] = useState<string | ArrayBuffer | null>(null)

  useEffect(() => {
    if (!filePreview) {
      return
    }

    const jsonBlob = new Blob([filePreview.file], { type: filePreview.contentType })
    const reader = new FileReader()

    reader.onload = () => {
      setHtml(reader.result)
    }

    reader.readAsText(jsonBlob)
  }, [filePreview])

  useEffect(() => {
    const handleLinkClick = (event: any) => {
      const targetHash = event?.target?.getAttribute('href')

      if (targetHash && targetHash.startsWith('#')) {
        event?.preventDefault()

        const iframe = document.getElementById('dynamicIframe') as HTMLIFrameElement
        const targetElement = iframe.contentWindow?.document?.querySelector(targetHash)

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    const iframe = document.getElementById('dynamicIframe') as HTMLIFrameElement
    if (iframe) {
      iframe.onload = () => {
        const iframeDocument = iframe.contentWindow?.document || iframe.contentDocument
        const links = iframeDocument?.querySelectorAll('a') || []

        links.forEach((link) => {
          link.addEventListener('click', handleLinkClick)
        })
      }
    }

    return () => {
      if (iframe) {
        const iframeDocument = iframe.contentWindow?.document || iframe.contentDocument
        const links = iframeDocument?.querySelectorAll('a') || []

        links.forEach((link) => {
          link.removeEventListener('click', handleLinkClick)
        })
      }
    }
  }, [html])

  if (!filePreview || !html) {
    return null
  }

  return (
    <Box
      data-testid={FileBrowserPreviewHtmlComponent.name}
      display='flex'
      alignItems='flex-start'
      justifyContent='flex-start'
      width='100%'
      height='100%'
      px={0}
    >
      <iframe
        id='dynamicIframe'
        title='preview'
        srcDoc={html as string}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    </Box>
  )
}

export default FileBrowserPreviewHtmlComponent
