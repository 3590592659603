import { IntlShape } from 'react-intl'
import { getSourceFieldSchemas, checkifOAuthRequired, isOAuthDone } from '@utils/connectors.utils'

const validateEditConnectorModal = (values: Hermes.ConnectionFormItem, props: any, intl: IntlShape, sources: Hermes.SourceDetails[]) => {
  // Todo: Actually validate these fields
  // eslint-disable-next-line
  const fieldSchemas = getSourceFieldSchemas(values.sourceId, sources)
  const selectedSource = sources.find((e) => e.sourceId === values.sourceId) || {} as Hermes.SourceDetails
  const isOAuthRequired = checkifOAuthRequired(selectedSource)
  const isAuthDone = isOAuthDone(values)
  const errors = {} as any

  if (!values.name) {
    errors.name = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  if (!isAuthDone && isOAuthRequired) {
    errors['configuration.credentials.refreshToken'] = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  return errors
}

export default validateEditConnectorModal
