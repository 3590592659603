import React from 'react'

import KpiTileComponent from '@base/tiles/KpiTile'

export interface KPIItemComponentProps {
  item: RecommendationArtifacts.KPIItem
  size?: 'small' | 'medium'
  comparisonMode?: boolean
  loading?: boolean
}

const KPIItemComponent: React.FC<KPIItemComponentProps> = ({
  item,
  size = 'medium',
  comparisonMode = false,
  loading = false,
}) => {
  const {
    title,
    highlight,
    unit,
    description,
    value,
    differenceValue,
    type,
    formattingOptions,
  } = item

  const showDiff = (comparisonMode && differenceValue !== undefined && differenceValue !== null)
  const isSmall = size === 'small'

  return (
    <KpiTileComponent
      title={title}
      tooltip={description}
      value={value}
      valueType={type}
      showDeltaArrows={true}
      valueFormattingOptions={formattingOptions}
      secondaryValue={showDiff ? differenceValue : undefined}
      secondaryValueType={type}
      secondaryValueFormattingOptions={formattingOptions}
      unit={unit}
      size={isSmall ? 'compact' : 'default'}
      variant={highlight ? 'highlighted' : 'default'}
      loading={loading}
    />
  )
}

export default KPIItemComponent
