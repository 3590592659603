import copy from 'copy-to-clipboard'
import { SyntheticEvent } from 'react'
import { IntlShape } from 'react-intl'

import { TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { CHANGE_TOAST_REQUEST, storeDispatchProxy } from '@configuration/proxy.config'

import {
  COCKPIT_BUILD_ENV, KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM, KEYCLOAK_URI,
} from '@constants/env-replacements.constants'

/**
 * @function createId Creates unique ID/Key for the react elemts
 *
 * @param {String} base - Base Key
 * @param {Object} args - As many args as you can provide
 *
 * @return {String} Generated ID
 */
export const createId = (base: any, ...args: any[]) => {
  const result = [base].concat(args || []).map((item) => String(item))

  return result.join('-')
}

/**
 * @function copyToClipboard Checks whether value is in the provided range
 *
 * @param {Object} e Browser Event
 * @param {String} text Text value
 * @param {Function} changeToast Change toast action
 * @param {Object} intl Intl object
 *
 * @return {Number} Index of element in dataset
 */
export const copyToClipboard = ({
  e,
  text,
  intl,
  showToast = true,
  customMessageId,
} : {
  e: Event | SyntheticEvent | null,
  text: string,
  intl: IntlShape,
  showToast?: boolean,
  customMessageId?: string,
}): void => {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }

  copy(text)

  if (showToast) {
    dispatchGlobalToast(intl, customMessageId || 'common.copyToClipboard.successful', TOAST_TYPE_SUCCESS)
  }
}

/**
 * @function dispatchGlobalToast Dispatches global toast
 * CAUTION: This function uses storeDispatchProxy, which is a proxy for store.dispatch
 *
 * @param intl React Intl
 * @param customMessageId Custom message ID
 * @param severity Severity
 */
export const dispatchGlobalToast = (intl: IntlShape, customMessageId: string, severity: string) => {
  storeDispatchProxy[CHANGE_TOAST_REQUEST]({
    severity,
    message: intl.formatMessage({ id: customMessageId || 'common.copyToClipboard.successful' }),
  })
}

/**
 * @function saveAsFile Generates UUID
 *
 * @param {String} fileName File Name
 * @param {String} type File Type
 * @param {Blob} data File Data
 *
 */
export const saveAsFile = (fileName: string, type: string, data: string | ArrayBuffer | Blob) => {
  const link = document.createElement('a')

  link.target = '_blank'
  link.download = fileName
  link.href = URL.createObjectURL(new Blob([data], { type }))
  link.click()
}

/**
 * @function humanFileSize returns human readable file size
 *
 * @param {Number} size File Size
 *
 * @return {String} Human readable File Size
 */
export const humanFileSize = (size: number | string, options?: Intl.NumberFormatOptions): string => {
  const protectedSize = Number(size)
  const i = protectedSize === 0 ? 0 : Math.floor(Math.log(protectedSize) / Math.log(1024))
  const value = (protectedSize / Math.pow(1024, i)) * 1
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i]

  return `${defaultNumberFormatter(value, { numberFormatOptions: options })} ${unit}`
}

/**
 * @function readFileAsText reads file as text
 *
 * @param {String} url File URL
 *
 * @return {String} Image
 */
export const readFileAsText = async (url: string): Promise<string | ArrayBuffer | Blob | null> => {
  const imageSource: string | ArrayBuffer | null = await fetch(url).then((response) => response.blob()).then((blob) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsText(blob)
  }))

  return imageSource
}

/**
 * @function parseIntegerValueFromStr Parses integer value from string
 *
 * @param {String} value Value to parse
 *
 * @returns {Number} Parsed value
 */
export const parseIntegerValueFromStr = (value: string | null): number => {
  const parsedValue = value ? (Number.parseInt(value || '0', 10)) : 0

  return isNaN(parsedValue) ? 0 : parsedValue
}

/**
 * @returns {String} Keycloak account management url
 */
export const getKeycloakAccountManagementUrl = () => {
  const isDev = COCKPIT_BUILD_ENV === 'dev' || COCKPIT_BUILD_ENV === 'local'

  return `${KEYCLOAK_URI}realms/${KEYCLOAK_REALM}/account/password?referrer=${KEYCLOAK_CLIENT_ID}&referrer_uri=https%3A%2F%2Fapp.${isDev ? 'dev.' : ''}paretos.com%2Fdashboard`
}

/**
 * @function getGridSize Returns grid size
 *
 * @param itemsPerRow Items per row
 * @param expanded Expanded
 * @param defaultItemsPerRow Default items per row
 * @returns Grid size
 */
export const getGridSize = (itemsPerRow: number, expanded: boolean, defaultItemsPerRow = 4) => {
  const gridSize = itemsPerRow > 0 ? (12 / itemsPerRow) : defaultItemsPerRow

  return expanded ? 12 : gridSize
}
