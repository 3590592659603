import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Box, Grid,
  Collapse, Typography,
} from '@mui/material'

import LoadingFallbackComponent from '@base/loading/LoadingFallback'

import type { SectionTileComponentProps } from './SectionTile.component'

export interface SectionTileContentComponentProps extends Pick<SectionTileComponentProps,
  'contentPadding' | 'contentMinHeight' | 'container' | 'children' |
  'noDataMessage' | 'noData' | 'loading' | 'spacing' | 'contentType'
> {
  /**
   * If 'true' the content section is collapsed
   */
  isCollapsed: boolean
}

const SectionTileContentComponent: React.FC<SectionTileContentComponentProps> = ({
  loading,
  spacing,
  noData,
  noDataMessage,
  children,
  container,
  contentMinHeight,
  contentPadding,
  isCollapsed,
  contentType,
}) => {
  const intl = useIntl()

  const isContainerGrid = container && !loading && !noData
  const containerStyles = useMemo(() => {
    const baseStyles = {
      height: '100%',
      minHeight: contentMinHeight,
      padding: contentPadding,
    }

    if (contentType === 'data-grid' || contentType === 'data-grid-headless' || loading) {
      Object.assign(baseStyles, {
        padding: 0,
        '& .MuiDataGrid-toolbarContainer': {
          borderTop: 'none !important',
        },
      })
    }

    return baseStyles
  }, [
    loading, contentMinHeight,
    contentType, contentPadding,
  ])

  const content = useMemo(() => {
    if (loading) {
      return (
        <Box
          data-testid='loading'
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            minHeight: contentMinHeight,
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
        >
          <LoadingFallbackComponent
            fluid={true}
            size='medium'
          />
        </Box>
      )
    }

    if (noData) {
      return (
        <Box
          data-testid='no-data'
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>
            {noDataMessage || intl.formatMessage({ id: 'common.no_data_available' })}
          </Typography>
        </Box>
      )
    }

    return children
  }, [intl, children, loading, noData, noDataMessage, contentMinHeight])

  return (
    <Collapse in={!isCollapsed} style={{ width: '100%' }}>
      <Grid
        data-testid={SectionTileContentComponent.name}
        item={true}
        container={isContainerGrid}
        spacing={isContainerGrid ? spacing : undefined}
        xs={12}
        sx={containerStyles}
      >
        {content}
      </Grid>
    </Collapse>
  )
}

export default SectionTileContentComponent
