import React from 'react'
import { Button, ButtonProps, useTheme } from '@mui/material'
import { getButtonsStyles } from '@utils/ui.utils'

export interface DataGridBaseButtonComponentProps extends ButtonProps {
  forwardedRef?: any
}

export const DataGridBaseButtonComponent: React.FC<DataGridBaseButtonComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const buttonStyles = getButtonsStyles(theme, 'tertiary', 'regular', false)

  return (
    <Button
      data-testid={DataGridBaseButtonComponent.name}
      ref={forwardedRef}
      disableRipple={true}
      sx={{
        ...sx,
        ...buttonStyles,
        borderRadius: '5px',
        padding: '5px 10px',
        textTransform: 'none',
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridBaseButtonComponent {...props} forwardedRef={ref} />)
