import { useMemo } from 'react'

import {
  DataGridPremiumProps,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium'

import {
  booleanAggregation,
} from '@utils/data-grid.utils'

export interface useDataGridAggregationFunctionsProps {
  aggregationFunctions: DataGridPremiumProps['aggregationFunctions']
}

export const useDataGridAggregationFunctions = ({
  aggregationFunctions,
}: useDataGridAggregationFunctionsProps) => {
  const finalAggregationFunctions = useMemo(() => {
    if (!aggregationFunctions) {
      return {
        ...GRID_AGGREGATION_FUNCTIONS,
        booleanAggregation,
      }
    }

    return {
      booleanAggregation,
      ...aggregationFunctions,
    }
  }, [aggregationFunctions])

  return finalAggregationFunctions
}
