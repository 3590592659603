import React from 'react'
import IconButtonComponent, { IconButtonComponentProps } from '@base/buttons/IconButton/IconButton.component'

export interface ActionButtonComponentProps extends Pick<IconButtonComponentProps,
  'name' |
  'loading' |
  'tooltip' |
  'onClick' |
  'label' |
  'disabled' |
  'forwardedRef' |
  'IconComponent'
> {}

export const ActionButtonComponent: React.FC<ActionButtonComponentProps> = ({
  tooltip = true,
  name,
  label,
  loading,
  disabled,
  onClick,
  IconComponent,
  ...props
}) => {
  return (
    <IconButtonComponent
      color='secondary'
      name={name}
      loading={loading}
      tooltip={tooltip}
      onClick={onClick}
      IconComponent={IconComponent}
      label={label}
      disabled={disabled}
      {...props}
    />
  )
}

export default React.forwardRef<React.FC, ActionButtonComponentProps>((props, ref) => <ActionButtonComponent {...props} forwardedRef={ref} />)
