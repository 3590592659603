import React from 'react'

const RedshiftIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={RedshiftIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M4.80469 14.7972V3.16653L2.30469 2.57129V15.3924L4.80469 14.7972Z" fill="white" fillOpacity="0.6"/>
      <path d="M17.2985 3.22319L15.9947 2.57129V15.3924L17.2985 14.7405V3.22319Z" fill="white" fillOpacity="0.6"/>
      <path d="M2.30381 2.57129L1 3.22319V14.7405L2.30381 15.3924V2.57129Z" fill="white" fillOpacity="0.4"/>
      <path d="M10.891 18L13.4986 16.6962V1.30381L10.891 0L10.0938 8.5474L10.891 18Z" fill="white" fillOpacity="0.6"/>
      <path d="M7.4123 18L4.80469 16.6962V1.30381L7.4123 0L8.20952 8.5474L7.4123 18Z" fill="white" fillOpacity="0.4"/>
      <path d="M7.41016 0H10.923V17.9637H7.41016V0Z" fill="white"/>
      <path d="M13.5 14.7984L15.9948 15.3924V2.57129L13.5 3.1653V14.7984Z" fill="white" fillOpacity="0.4"/>

    </svg>
  )
}

export default RedshiftIcon
