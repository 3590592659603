import React from 'react'

const ArrowLastIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ArrowLastIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m3.25 2 6.62308 6.62308c.05151.04834.09256.10673.12063.17156.02809.06482.04259.13472.04259.20536 0 0-.0145.14054-.04259.20536-.02807.06483-.06912.12322-.12063.17156l-6.62308 6.62308"/>
        <path d="m13.5 2v14"/>
      </g>

    </svg>
  )
}

export default ArrowLastIcon
