import React from 'react'

const GTMIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={GTMIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.15312 2.84907C6.32592 2.39547 6.56352 1.97787 6.91632 1.63947C8.32752 0.264265 10.6603 0.609865 11.6179 2.33787C12.3379 3.64827 13.1011 4.92987 13.8427 6.22587C15.0811 8.37866 16.3339 10.5315 17.5579 12.6915C18.5875 14.4987 17.4715 16.7811 15.4267 17.0907C14.1739 17.2779 13.0003 16.7019 12.3523 15.5787C11.2651 13.6851 10.1707 11.7915 9.08352 9.90506C9.06192 9.86187 9.03312 9.82586 9.00432 9.78987C8.88912 9.69627 8.83872 9.55947 8.76672 9.43707C8.28432 8.58746 7.78752 7.74507 7.30512 6.90267C6.99552 6.35547 6.67152 5.81547 6.36192 5.26827C6.08112 4.77867 5.95152 4.24587 5.96592 3.68427C5.98752 3.39627 6.02352 3.10827 6.15312 2.84907Z" fill={detailsFill || "#3C8BD9"}/>
      <path d="M6.15306 2.849C6.08826 3.1082 6.03066 3.3674 6.01626 3.641C5.99466 4.2458 6.14586 4.8074 6.44826 5.333C7.24026 6.6938 8.03226 8.0618 8.81706 9.4298C8.88906 9.5522 8.94666 9.6746 9.01866 9.7898C8.58666 10.5386 8.15466 11.2802 7.71546 12.029C7.11066 13.073 6.50586 14.1242 5.89386 15.1682C5.86506 15.1682 5.85786 15.1538 5.85066 15.1322C5.84346 15.0746 5.86506 15.0242 5.87946 14.9666C6.17466 13.8866 5.92986 12.929 5.18826 12.1082C4.73466 11.6114 4.15866 11.3306 3.49626 11.237C2.63226 11.1146 1.86906 11.3378 1.18506 11.8778C1.06266 11.9714 0.983463 12.1082 0.839463 12.1802C0.810663 12.1802 0.796263 12.1658 0.789062 12.1442C1.13466 11.5466 1.47306 10.949 1.81866 10.3514C3.24426 7.8746 4.66986 5.3978 6.10266 2.9282C6.11706 2.8994 6.13866 2.8778 6.15306 2.849Z" fill={detailsFill || "#FABC04"}/>
      <path d="M0.818953 12.1659C0.955753 12.0435 1.08535 11.9139 1.22935 11.7987C2.97895 10.4163 5.60695 11.4171 5.98855 13.6059C6.08215 14.1315 6.03175 14.6355 5.87335 15.1395C5.86615 15.1827 5.85895 15.2187 5.84455 15.2619C5.77975 15.3771 5.72215 15.4995 5.65015 15.6147C5.00935 16.6731 4.06615 17.1987 2.82775 17.1195C1.40935 17.0187 0.293353 15.9531 0.0989531 14.5419C0.0053531 13.8579 0.142153 13.2171 0.494953 12.6267C0.566953 12.4971 0.653353 12.3819 0.732553 12.2523C0.768553 12.2235 0.754153 12.1659 0.818953 12.1659Z" fill={detailsFill || "#34A852"}/>

    </svg>
  )
}

export default GTMIcon
