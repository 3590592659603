import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from '@redux/hooks'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import { bindActionCreators } from 'redux'
import { Box, Typography } from '@mui/material'

import {
  SidePanelCardActionsComponent,
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import {
  mapSyncStatusToTranslation, checkifOAuthRequired,
  isOAuthDone, encodeStreamName,
} from '@utils/connectors.utils'

import TextFieldComponent from '@base/forms/TextField'
import ButtonComponent from '@base/buttons/Button'
import SYNC_STATUS from '@containers/modals/connector-edit-modal/EditConnectorModal.status'
import ConnectorConfigurationContainer from '@components/connectors/ConnectorConfiguration'
import validateEditConnectorModal from '@containers/modals/connector-edit-modal/EditConnectorModal.validations'
import StreamsConfigurationComponent from '@components/connectors/StreamsConfiguration'
import ConfirmationDialogComponent from '@base/dialogs/ConfirmationDialog'

import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getAvailableSources } from '@redux/modules/hermes/hermes.selectors'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { UpdateConnectionPayload } from '@redux/modules/hermes/hermes.types'
import { requestUpdateConnectionAction } from '@redux/modules/hermes/hermes.actions'
import { formatDateTime } from '@utils/moment.utils'

import * as API from '@redux/modules/hermes/hermes.api'

const initialFormValues = {
  name: '',
  configuration: {},
  streams: {},
} as Hermes.ConnectionFormItem

export interface EditConnectorModalContainerProps {
  isOpen?: boolean,
  selectedConnectionId: string,
  handleClose?: {
    (): any,
  },
}

export interface SyncStatusState {
  status: string,
  attempt: number,
  lastSyncAt?: string,
}

const EditConnectorModalContainer: React.FC<EditConnectorModalContainerProps> = ({
  isOpen,
  handleClose,
  selectedConnectionId,
}) => {
  const dispatch = useDispatch()
  const updateConnection = bindActionCreators(requestUpdateConnectionAction, dispatch)

  const showToast = useMemo(() => {
    return bindActionCreators(changeToastAction, dispatch)
  }, [dispatch])

  const [isLoading, setIsLoading] = useState(false)
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false)
  const [recentlySubmitted, setRecentlySubmitted] = useState(false)
  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const sources = useSelector((state) => getAvailableSources(state))
  const [source, setSource] = useState<Hermes.SourceDetails | null>(null)
  const [connection, setConnection] = useState<Hermes.ConnectionItem | null>(null)
  const [formValues, setFormValues] = useState<Hermes.ConnectionFormItem>(initialFormValues)
  const [syncStatus, setSyncStatus] = useState<SyncStatusState>({
    status: 'nosync',
    attempt: 0,
  })

  const intl = useIntl()

  useEffect(() => {
    let isMounted = true
    const cancel = () => { isMounted = false }
    if (!isOpen || !selectedConnectionId) {
      return cancel
    }

    const fetchSyncStatus = async () => {
      try {
        const status = await API.getSyncStatus(companyId, selectedConnectionId)
        if (isMounted) {
          setSyncStatus(status)
        }
      } catch (e: any) {
        showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
      }
    }

    fetchSyncStatus()
    const id = setInterval(fetchSyncStatus, 4000)
    return () => {
      clearInterval(id)
      cancel()
    }
  }, [selectedConnectionId, isOpen, companyId, showToast])

  useEffect(() => {
    let isMounted = true
    const cancel = () => { isMounted = false }
    if ((!isOpen || !selectedConnectionId) && !recentlySubmitted) {
      return cancel
    }

    const fetchConnection = async () => {
      setIsLoading(true)
      try {
        const apiConnection = await API.getConnection(companyId, selectedConnectionId)
        const apiSource = sources.find((e) => e.sourceId === apiConnection.sourceId) || {} as Hermes.SourceDetails

        if (!isMounted) {
          return
        }

        setSource(apiSource)
        setConnection(apiConnection)

        const updatedFormValues = {
          name: apiConnection.name,
          configuration: apiConnection.configuration,
          streams: Object.assign({}, ...apiConnection.streams.map((s) => ({ [encodeStreamName(s.name)]: s }))),
        } as Hermes.ConnectionFormItem

        if (isMounted) {
          setRecentlySubmitted(false)
          setFormValues(updatedFormValues)
        }
      } catch (e: any) {
        showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchConnection()

    return cancel
  }, [selectedConnectionId, recentlySubmitted, isOpen, companyId, sources, showToast])

  const handleStartSync = async () => {
    try {
      const status = await API.startSync(companyId, selectedConnectionId)
      setSyncStatus(status)
    } catch (e: any) {
      showToast({
        message: e.message,
        severity: TOAST_TYPE_ERROR,
      })
    }
  }

  const handleResetData = async () => {
    try {
      const status = await API.resetSyncData(companyId, selectedConnectionId)
      setSyncStatus(status)
    } catch (e: any) {
      showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
    }
  }

  const handleCancelSync = async () => {
    try {
      const status = await API.cancelSync(companyId, selectedConnectionId)
      setSyncStatus(status)
    } catch (e: any) {
      showToast({
        message: e.message,
        severity: TOAST_TYPE_ERROR,
      })
    }
  }

  const handleSubmitSource = async (
    values: Hermes.ConnectionFormItem,
    { setSubmitting }: { setSubmitting(state: boolean): void },
  ) => {
    const streamConfig = Object.entries(values.streams).map(([_, data]) => {
      return data
    })

    const payload = {
      connectionId: selectedConnectionId,
      companyId,
      name: values.name,
      configuration: values.configuration,
      streams: streamConfig,
    } as UpdateConnectionPayload

    try {
      const connectionId = await API.updateConnection(payload)
      updateConnection({
        connectionId,
        name: values.name,
      })
      setRecentlySubmitted(true)
      setSubmitting(false)

      if (handleClose) {
        handleClose()
      }
    } catch (e: any) {
      showToast({ message: e.message, severity: TOAST_TYPE_ERROR })
    }
  }

  const handleCancel = () => {
    if (handleClose) {
      handleClose()
    }
  }

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    values,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: formValues,
    onSubmit: handleSubmitSource,
    enableReinitialize: true,
    validate: (valuesToValidate: Hermes.ConnectionFormItem) => validateEditConnectorModal(valuesToValidate, {}, intl, sources),
  })

  const isOAuthRequired = checkifOAuthRequired(source!)
  const isAuthDone = isOAuthDone(values)

  return (
    <SidePanelComponent
      open={isOpen}
      loading={isLoading}
      title={intl.formatMessage({ id: 'connectors.modal.title_edit' }, { name: formValues.name })}
      handleClose={handleClose}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isLoading}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'connectors.modal.general' })}
              >
                <FormLayoutItem xs={12}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant='body2' component='h3'>
                        {
                          intl.formatMessage(
                            { id: 'connectors.modal.status' },
                            { status: mapSyncStatusToTranslation(intl, syncStatus.status) },
                          )
                        }
                      </Typography>
                      <Typography variant='body2' component='h3'>
                        {intl.formatMessage({ id: 'connectors.modal.attempt' }, { count: syncStatus.attempt })}
                      </Typography>
                      <Typography variant='body2' component='h3'>
                        {intl.formatMessage(
                          { id: 'connectors.modal.last_sync_at' },
                          { lastSyncAt: syncStatus.lastSyncAt ? formatDateTime(intl, syncStatus.lastSyncAt) : '-' },
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <ButtonComponent
                        name='resetData'
                        sx={{
                          marginRight: '5px',
                        }}
                        onClick={() => setIsResetDialogOpen(true)}
                        disabled={syncStatus.status === SYNC_STATUS.pending ||
                            syncStatus.status === SYNC_STATUS.running ||
                            syncStatus.status === SYNC_STATUS.nosync ||
                            syncStatus.status === SYNC_STATUS.resetting}
                        color='secondary'
                        label={intl.formatMessage({ id: 'connectors.modal.sync.reset_data' })}
                      />
                      <ButtonComponent
                        name='sync'
                        onClick={syncStatus.status === SYNC_STATUS.running ? handleCancelSync : handleStartSync}
                        disabled={syncStatus.status === SYNC_STATUS.pending || syncStatus.status === SYNC_STATUS.resetting}
                        color='primary'
                        label={syncStatus.status === SYNC_STATUS.running ?
                          intl.formatMessage({ id: 'connectors.modal.sync.cancel' }) :
                          intl.formatMessage({ id: 'connectors.modal.sync.start' })}
                      />
                    </Box>
                  </Box>
                </FormLayoutItem>
                <FormLayoutItem
                  xs={12}
                  hidden={!connection?.name}
                >
                  <TextFieldComponent
                    name='name'
                    label={intl.formatMessage({ id: 'connectors.modal.name' })}
                    floatingHelp={intl.formatMessage({ id: 'connectors.modal.name.help' })}
                    touched={touched.name}
                    errors={errors.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <ConnectorConfigurationContainer
                values={values}
                touched={touched}
                errors={errors}
                onChange={handleChange}
                onBlur={handleBlur}
                source={source!}
                setFormValues={setFormValues}
                isOpen={isOpen}
              />

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'connectors.modal.synchronized_streams' })}
                hidden={!(Object.keys(values.streams).length > 0)}
                divider={false}
              >
                <FormLayoutItem xs={12}>
                  <StreamsConfigurationComponent
                    streams={values.streams}
                    handleChange={handleChange}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>
          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='editConnectorModalCancelButton'
              onClick={handleCancel}
              type='cancel'
              disabled={isSubmitting}
            />
            <ModalButtonComponent
              name='editConnectorModalSubmitButton'
              onClick={handleSubmit as any}
              loading={isSubmitting}
              disabled={isSubmitting || !dirty || (!dirty && (!isAuthDone && isOAuthRequired)) || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>

      <ConfirmationDialogComponent
        open={isResetDialogOpen}
        onClose={() => setIsResetDialogOpen(false)}
        onSubmit={handleResetData}
        title={intl.formatMessage({ id: 'connectors.modal.reset_data.title' })}
        description={intl.formatMessage({ id: 'connectors.modal.reset_data.description' })}
        closeButtonLabel={intl.formatMessage({ id: 'connectors.modal.reset_data.cancel' })}
        submitButtonLabel={intl.formatMessage({ id: 'connectors.modal.reset_data.confirm' })}
      />
    </SidePanelComponent>
  )
}

export default EditConnectorModalContainer
