import { createAction } from 'redux-actions'

import {
  CHANGE_COMPANY_ID,
  CHANGE_COMPANY_ID_DONE,

  CHANGE_USER_INFO,
  CHANGE_USER_INFO_DONE,

  REQUEST_COMPANIES_LIST,
  REQUEST_COMPANIES_LIST_DONE,

  REQUEST_COMPANY_ITEM,
  REQUEST_COMPANY_ITEM_DONE,

  REQUEST_COMPANY_USERS_LIST,
  REQUEST_COMPANY_USERS_LIST_DONE,

  START_FETCHING_CUSTOMER,
  STOP_FETCHING_CUSTOMER,

  CLONE_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,

  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  REASSIGN_USER,

  CHANGE_USER_LANGUAGE,
} from './customer.action-types'

import {
  CreateCompanyActionPayload,
  DeleteCompanyActionPayload,
  RequestCompanyItemPayload,
  UpdateCompanyActionPayload,
  ChangeCompanyIdActionPayload,
  AddCompanyUsersPayload,
  EditCompanyUsersPayload,
  DeleteCompanyUsersPayload,
  FetchCompanyUsersPayload,
  CloneCompanyActionPayload,
  ReAssignCompanyUsersPayload,
  ChangeUserLanguageActionPayload,
} from './customer.types'

export const cloneCompanyAction = createAction<CloneCompanyActionPayload>(CLONE_COMPANY)
export const createCompanyAction = createAction<CreateCompanyActionPayload>(CREATE_COMPANY)
export const updateCompanyAction = createAction<UpdateCompanyActionPayload>(UPDATE_COMPANY)
export const deleteCompanyAction = createAction<DeleteCompanyActionPayload>(DELETE_COMPANY)

export const requestCompanyItemAction = createAction<RequestCompanyItemPayload>(REQUEST_COMPANY_ITEM)
export const requestCompanyItemActionDone = createAction(REQUEST_COMPANY_ITEM_DONE)

export const requestCompaniesListAction = createAction<void>(REQUEST_COMPANIES_LIST)
export const requestCompaniesListActionDone = createAction(REQUEST_COMPANIES_LIST_DONE)

export const createUserAction = createAction<AddCompanyUsersPayload>(CREATE_USER)
export const updateUserAction = createAction<EditCompanyUsersPayload>(UPDATE_USER)
export const deleteUserAction = createAction<DeleteCompanyUsersPayload>(DELETE_USER)
export const reassignUserAction = createAction<ReAssignCompanyUsersPayload>(REASSIGN_USER)

export const changeUserLanguageAction = createAction<ChangeUserLanguageActionPayload>(CHANGE_USER_LANGUAGE)

export const requestCompanyUsersListAction = createAction<FetchCompanyUsersPayload>(REQUEST_COMPANY_USERS_LIST)
export const requestCompanyUsersListActionDone = createAction(REQUEST_COMPANY_USERS_LIST_DONE)

export const changeCompanyIdAction = createAction<ChangeCompanyIdActionPayload>(CHANGE_COMPANY_ID)
export const changeCompanyIdActionDone = createAction(CHANGE_COMPANY_ID_DONE)

export const changeUserInfoAction = createAction<Common.UserInfo>(CHANGE_USER_INFO)
export const changeUserInfoActionDone = createAction(CHANGE_USER_INFO_DONE)

export const startFetchingCustomerAction = createAction<string>(START_FETCHING_CUSTOMER)
export const stopFetchingCustomerAction = createAction<string>(STOP_FETCHING_CUSTOMER)
