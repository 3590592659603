import React from 'react'

const ZoomOutIcon: React.FC<Common.IconProps> = ({
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ZoomOutIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.25 13.25L10.75 10.75" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.5 6.5H9.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ZoomOutIcon
