import React from 'react'

import {
  Box, Fade, SxProps, Theme,
  Tooltip, TooltipProps,
} from '@mui/material'

export interface TooltipComponentProps extends TooltipProps {
  /**
   * If true, tooltip will have no max width
   */
  noMaxWidth?: boolean,
  /**
   * Custom content styles
   */
  contentSx?: SxProps<Theme>,
  /**
   * Custom tooltip styles
   */
  tooltipSx?: SxProps<Theme>,
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({
  arrow = true,
  disableInteractive = true,
  children,
  title,
  noMaxWidth,
  PopperProps,
  contentSx,
  tooltipSx,
  ...props
}) => {
  const transitionProps = {
    timeout: {
      appear: 300,
      enter: 300,
    },
  }

  const tooltipSxProps = {
    ...tooltipSx,
    ...(noMaxWidth ? {
      maxWidth: 'none',
    } : {}),
  }

  const titleToRender = (
    <Box
      display='flex'
      flexDirection='column'
      sx={contentSx}
    >
      {title}
    </Box>
  )

  if (!title) {
    return children
  }

  return (
    <Tooltip
      arrow={arrow}
      TransitionComponent={Fade}
      title={titleToRender}
      TransitionProps={transitionProps}
      data-testid={TooltipComponent.name}
      disableInteractive={disableInteractive}
      componentsProps={{
        tooltip: {
          sx: tooltipSxProps as any,
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default TooltipComponent
