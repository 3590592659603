import React from 'react'

const DotsIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DotsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9.00167 17.3571C13.6172 17.3571 17.3588 13.6155 17.3588 8.99996C17.3588 4.38444 13.6172 0.642822 9.00167 0.642822C4.38615 0.642822 0.644531 4.38444 0.644531 8.99996C0.644531 13.6155 4.38615 17.3571 9.00167 17.3571Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00223 9.64277C9.35727 9.64277 9.64509 9.35495 9.64509 8.99991C9.64509 8.64487 9.35727 8.35706 9.00223 8.35706C8.64719 8.35706 8.35938 8.64487 8.35938 8.99991C8.35938 9.35495 8.64719 9.64277 9.00223 9.64277Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.14286 9.64277C5.4979 9.64277 5.78571 9.35495 5.78571 8.99991C5.78571 8.64487 5.4979 8.35706 5.14286 8.35706C4.78782 8.35706 4.5 8.64487 4.5 8.99991C4.5 9.35495 4.78782 9.64277 5.14286 9.64277Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8577 9.64277C13.2127 9.64277 13.5006 9.35495 13.5006 8.99991C13.5006 8.64487 13.2127 8.35706 12.8577 8.35706C12.5027 8.35706 12.2148 8.64487 12.2148 8.99991C12.2148 9.35495 12.5027 9.64277 12.8577 9.64277Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default DotsIcon
