import produce from 'immer'

import { CommonState, ReducerPayload } from '@redux/modules/types'
import { getSidebarState } from '@utils/local-storage.utils'
import { DEFAULT_SORTING_ORDER, DEFAULT_TABLE_LIMIT } from '@constants/filters.constants'

import { SetPaginationConfigActionPayload } from './common.types'

const defaultPaginationConfig = {
  page: 1,
  limit: DEFAULT_TABLE_LIMIT,
  search: '',
  sortingKey: '',
  sortingDirection: DEFAULT_SORTING_ORDER,
}

export const initialState: CommonState = {
  toast: {
    open: false,
    message: '',
    type: 'none',
  },
  paginationConfig: defaultPaginationConfig,
  sidebar: getSidebarState(),
  chatbot: false,
  unresolvedRequests: 0,
  fetchingKeys: [],
}

export const resetPaginationConfigReducer = (state: CommonState) => {
  const nextState = produce(state, (draftState) => {
    draftState.paginationConfig = { ...defaultPaginationConfig }
  })

  return nextState
}

export const changePaginationConfigReducer = (state: CommonState, action: ReducerPayload<SetPaginationConfigActionPayload>) => {
  const nextState = produce(state, (draftState) => {
    const { initial, ...rest } = action.payload

    if (draftState.paginationConfig.sortingKey && initial) {
      /** initial setup should not occur when sorting key is already provided */
    } else {
      draftState.paginationConfig = {
        ...draftState.paginationConfig,
        ...rest,
      }
    }
  })

  return nextState
}

export const incrementUnresolvedRequestsReducer = (state: CommonState) => {
  const newState = { ...state }
  const unresolvedRequests = newState.unresolvedRequests

  newState.unresolvedRequests = unresolvedRequests + 1

  return newState
}

export const decrementUnresolvedRequestsReducer = (state: CommonState) => {
  const newState = { ...state }
  const unresolvedRequests = newState.unresolvedRequests

  newState.unresolvedRequests = unresolvedRequests - 1

  return newState
}

export const changeToastReducer = (state: CommonState, action: ReducerPayload<Common.ToastPayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.toast = {
      ...draftState.toast,
      ...action.payload,
    }
  })

  return nextState
}

export const changeSidebarStateReducer = (state: CommonState, action: ReducerPayload<boolean>) => {
  const newState = { ...state }

  newState.sidebar = action.payload

  return newState
}

export const changeChatbotStateReducer = (state: CommonState, action: ReducerPayload<boolean>) => {
  const newState = { ...state }

  newState.chatbot = action.payload

  return newState
}
