import React from 'react'
import get from 'lodash.get'
import { TooltipProps } from 'recharts'
import { createId } from '@utils/common.utils'
import { Box } from '@mui/material'

import ChartTooltipLegendItemComponent from '@components/charts/ChartTooltipLegendItem'
import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'
import ChartTooltipValueItemComponent from '@components/charts/ChartTooltipValueItem'

export interface TooltipRow<T> {
  /**
   * The key of the data point to be displayed
   */
  key: string,
  /**
   * The label of the row
   */
  label?: string,
  /**
   * If 'true' the data point is numeric.
   * This will have an impact on the formatting and sorting of the value
   */
  numeric: boolean,
  /**
   * The unit of the value
   */
  unit?: string,
  /**
   * Type of legend.
   */
  legendType?: 'line' | 'square',
  /**
   * Color of the legend.
   */
  legendColor?: string | {
    (payload: T): string
  },
  /**
   * If 'true' the row will be hidden if the value is null
   */
  hideIfNull?: boolean,
  /**
   * The custom formatting function for the value
   */
  valueFormatter?: {
    (value: any, payload: T): string | number
  }
}

export interface CustomTooltipProps<T> extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * If 'true' the tooltip is active
   */
  active?: boolean,
  /**
   * If 'true' the tooltip rows will be sorted
   */
  sort?: boolean,
  /**
   * If 'true' the tooltip is disabled, and will not be shown
   */
  disabled?: boolean,
  /**
   * The payload of the chart data point
   */
  payload?: T[],
  /**
   * The rows to be displayed in the tooltip
   */
  rows: TooltipRow<T>[]
}

const ChartTooltipComponent = <T extends {}> ({
  active, payload, rows, sort, disabled,
} : CustomTooltipProps<T>) => {
  if (active && !disabled && payload && rows && rows.length && payload.length) {
    const data: T = get(payload[0], 'payload', {} as T) as T

    const sortedRows = sort ? rows.sort((a, b) => {
      if (!a.numeric) {
        return 0
      }

      const aValue: number = get(data, a.key, 0) as number
      const bValue: number = get(data, b.key, 0) as number

      return bValue - aValue
    }) : rows

    return (
      <ChartTooltipContainerComponent
        data-testid={ChartTooltipComponent.name}
      >
        {
          sortedRows.map((row, index) => {
            const value: number | string = get(data, row.key, row.numeric ? 0 : '') as number | string
            const formattedValue = row.valueFormatter ? row.valueFormatter(value, data) : value
            const legendColor = row.legendColor ? (typeof row.legendColor === 'function' ? row.legendColor(data) : row.legendColor) : undefined

            if (row.hideIfNull && value === null) {
              return null
            }

            return (
              <Box
                key={createId(row.label, index)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                  '&:first-of-type': {
                    marginTop: '0px',
                  },
                }}
              >
                <ChartTooltipLegendItemComponent
                  color={legendColor}
                  type={row.legendType}
                  label={row.label}
                />

                <ChartTooltipValueItemComponent
                  color={legendColor}
                  value={formattedValue}
                />
              </Box>
            )
          })
        }
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default ChartTooltipComponent
