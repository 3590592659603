import { useCallback, useLayoutEffect, useMemo } from 'react'
import { storeTableStateInStorage } from '@utils/data-grid.utils'

import { DataGridComponentProps } from '@base/datagrid/data-grid/DataGrid.component'

export interface useDataGridPersistanceProps {
  id: DataGridComponentProps['id']
  apiRef: DataGridComponentProps['apiRef']
  rowSelectionMode: DataGridComponentProps['rowSelectionMode']
  groupingMode: DataGridComponentProps['groupingMode']
  aggregationMode: DataGridComponentProps['aggregationMode']
  enablePersistence: DataGridComponentProps['enablePersistence']
}

export const useDataGridPersistance = ({
  id,
  apiRef,
  groupingMode,
  rowSelectionMode,
  aggregationMode,
  enablePersistence,
}: useDataGridPersistanceProps) => {
  const isServerSideMode = useMemo(() => {
    return (
      rowSelectionMode === 'server' ||
      groupingMode === 'server' ||
      aggregationMode === 'server'
    )
  }, [groupingMode, rowSelectionMode, aggregationMode])

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && enablePersistence && !isServerSideMode) {
      const currentState = apiRef.current.exportState()

      storeTableStateInStorage(id, currentState)
    }
  }, [id, enablePersistence, isServerSideMode, apiRef])

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', saveSnapshot)

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot)

      saveSnapshot()
    }
  }, [saveSnapshot])

  return {
    saveSnapshot,
  }
}
