import React from 'react'

const MenuIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 10,
  height = 3,
  viewBox = '0 0 10 3',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={MenuIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <circle cx="5.11108" cy="1.08456" r="0.5" stroke={detailsFill} />
      <circle cx="1.22217" cy="1.08456" r="0.5" stroke={detailsFill} />
      <circle cx="9" cy="1.08456" r="0.5" stroke={detailsFill} />

    </svg>
  )
}

export default MenuIcon
