import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import useIsRequestTakingLongTime from '@hooks/useIsRequestTakingLongTime.hook'

const FadeInAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  position: absolute;
  text-align: center;
  color: white;
  z-index: 999;
  animation: 3s infinite ease-in-out alternate ${FadeInAnimation};
`

export interface LoadingTooLongTextComponentProps {
  /**
   * If true, the component will be enabled
   */
  enabled?: boolean
}

const LoadingTooLongTextComponent: React.FC<LoadingTooLongTextComponentProps> = ({ enabled = false }) => {
  const intl = useIntl()
  const isTakingLong = useIsRequestTakingLongTime(3000)

  if (!isTakingLong || !enabled) {
    return null
  }

  return (
    <Container
      data-testid={LoadingTooLongTextComponent.name}
    >
      <Typography
        variant='h6Alt'
      >
        {intl.formatMessage({ id: 'common.tooLong.header' })}
      </Typography>
      <Typography
        variant='h1'
        mt='-5px'
      >
        {intl.formatMessage({ id: 'common.tooLong.body' })}
      </Typography>
    </Container>
  )
}

export default LoadingTooLongTextComponent
