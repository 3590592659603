import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import TooltipComponent from '@base/tooltips/Tooltip'
import {
  Box, Button, useTheme,
} from '@mui/material'

import { DEFAULT_BUTTON_HEIGHT } from '@constants/ui.constants'

export interface NavigationButtonComponentProps {
  /**
   * Href to navigate to
   */
  href?: string,
  /**
   * Button label
   */
  label: string
  /**
   * If the button is disabled
   * @default false
   */
  disabled?: boolean
  /**
   * If the button is active
   * @default false
   */
  active?: boolean,
  /**
   * If the button is hidden
   * @default false
   */
  hidden?: boolean,
  /**
   * If the button is expanded
   * @default false
   */
  expanded?: boolean,
  /**
   * Icon component to be displayed
   */
  IconComponent: React.FC<Common.IconProps>
  /**
   * Ref to be forwarded to the button
   */
  forwardedRef?: React.ForwardedRef<any>
  /**
   * Click handler
   */
  onClick?: (e: React.MouseEvent<any>) => void
}

export const NavigationButtonComponent: React.FC<NavigationButtonComponentProps> = ({
  href,
  active,
  hidden,
  label,
  disabled,
  onClick,
  IconComponent,
  forwardedRef,
  expanded,
}) => {
  const theme = useTheme()

  const onClickHandler = (e: React.MouseEvent<any>) => {
    if (onClick) {
      onClick(e)
    }
  }

  const iconComponentProps = useMemo(() => {
    if (disabled) {
      return {
        detailsFill: theme.palette.new.business_black_20,
      }
    }

    if (active) {
      return {
        detailsFill: theme.palette.new.versatile_violet,
      }
    }

    return {
      detailsFill: theme.palette.new.black,
    }
  }, [active, disabled, theme])

  const tooltipSx = useMemo(() => {
    return {
      '&&': {
        display: expanded ? 'none' : 'block',
        color: (active && !disabled) ? theme.palette.new.versatile_violet : theme.palette.new.black,
        '& > div': {
          fontWeight: 500,
          opacity: disabled ? 0.4 : 1,
        },
      },
    }
  }, [active, disabled, expanded, theme])

  const buttonSx = useMemo(() => ({
    fontWeight: 400,
    height: DEFAULT_BUTTON_HEIGHT,
    minWidth: 'unset',
    borderRadius: '7px',
    padding: '0px',

    background: theme.palette.new.transparent,
    outline: 'none',
    color: active ? theme.palette.new.versatile_violet : theme.palette.new.business_black,
    transition: 'background-color 0.3s, color 0.3s, opacity 0.3s, outline 0.3s, width 0.3s, min-width 0.3s, padding 0.3s ease-out',

    '&:hover': {
      backgroundColor: theme.palette.new.business_black_5,
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.new.business_black_5,
    },
    '&:active': {
      backgroundColor: theme.palette.new.business_black_10,
    },

    '&.Mui-disabled': {
      cursor: 'not-allowed',
      color: theme.palette.new.business_black_20,
      backgroundColor: theme.palette.new.transparent,
    },

    '& .MuiButton-startIcon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: DEFAULT_BUTTON_HEIGHT,
      margin: '0px',
    },
  }), [active, theme])

  const navTitleStyles = useMemo(() => {
    return {
      display: 'inline-block',
      textAlign: 'left',
      opacity: expanded ? 1 : 0,
      width: expanded ? '110px' : '0px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pr: expanded ? '9px' : '0px',
      transition: theme.transitions.create(['width', 'opacity', 'padding-right'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
      }),
    }
  }, [theme, expanded])

  const containerSx = useMemo(() => {
    return {
      position: 'relative',
      '&:before': {
        content: '""',
        width: '2px',
        height: '14px',
        backgroundColor: active ? theme.palette.new.versatile_violet : theme.palette.new.transparent,
        borderRadius: '0px 2px 2px 0px',
        position: 'absolute',
        top: '11px',
        left: '-12px',
        pointerEvents: 'none',
      },
    }
  }, [
    active,
    theme,
  ])

  if (hidden) {
    return null
  }

  return (
    <TooltipComponent
      open={expanded ? false : undefined}
      key={expanded ? 'expanded' : 'collapsed'}
      describeChild={false}
      title={label}
      placement='right'
      tooltipSx={tooltipSx}
    >
      <Box
        component='span'
        display='inline-block'
        sx={containerSx}
        data-testid={NavigationButtonComponent.name}
      >
        {/* Ignore error, MUI issue - https://github.com/mui/material-ui/issues/16846 */}
        {/* @ts-ignore */}
        <Button
          LinkComponent={href ? NavLink : undefined}
          to={href}
          ref={forwardedRef}
          disableRipple={true}
          onClick={onClickHandler}
          disabled={disabled}
          aria-label={label}
          startIcon={<IconComponent {...iconComponentProps} />}
          sx={buttonSx}
        >
          <Box
            component='span'
            sx={navTitleStyles}
          >
            {label}
          </Box>
        </Button>
      </Box>
    </TooltipComponent>
  )
}

export default React.forwardRef<React.FC, NavigationButtonComponentProps>((props, ref) => <NavigationButtonComponent {...props} forwardedRef={ref} />)
