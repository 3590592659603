import React from 'react'

const DeliveryIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={DeliveryIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>
      {/* eslint-disable */}
      <path d="M14.1429 3.85718H8.35714C7.64706 3.85718 7.07143 4.43281 7.07143 5.14289V10.9286C7.07143 11.6387 7.64706 12.2143 8.35714 12.2143H14.1429C14.8529 12.2143 15.4286 11.6387 15.4286 10.9286V5.14289C15.4286 4.43281 14.8529 3.85718 14.1429 3.85718Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.14286 15.4286H14.9271C15.2644 15.43 15.5877 15.5639 15.8271 15.8014L17.3571 17.3572" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.642857 0.642883H1.92857C2.26956 0.642883 2.59659 0.778342 2.83771 1.01946C3.07883 1.26058 3.21429 1.5876 3.21429 1.9286V12.8572" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.14286 15.1072C5.14921 15.2142 5.14921 15.3216 5.14286 15.4286C5.10123 16.0065 4.83702 16.5457 4.40583 16.9328C3.97464 17.3198 3.41012 17.5244 2.83108 17.5036C2.25205 17.4828 1.70369 17.2381 1.30142 16.8211C0.899146 16.4041 0.674351 15.8473 0.674351 15.2679C0.674351 14.6885 0.899146 14.1317 1.30142 13.7147C1.70369 13.2977 2.25205 13.053 2.83108 13.0322C3.41012 13.0114 3.97464 13.216 4.40583 13.603C4.83702 13.9901 5.10123 14.5293 5.14286 15.1072Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9286 9H12.2143" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      {/* eslint-enable */}
    </svg>
  )
}

export default DeliveryIcon
