import React, {
  useEffect,
  useLayoutEffect,
} from 'react'

import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import { getUseCaseItem, isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { toggleComparisonModeAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'

import PageLayoutContainer from '@containers/application/PageLayout'

import {
  getComparisonModeState,
  isFetchingKPIItemsArtifact,
  isFetchingVisualisationsArtifact,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import KPIItemsArtifactContainer from '@containers/shared/artifacts/KPIItemsArtifact'
import VisualisationsArtifactContainer from '@containers/shared/artifacts/VisualisationsArtifact'
import { Box } from '@mui/material'

const RpResultsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const isFetchingUseCaseFlag = useSelector((state) => isFetchingUseCase(state))
  const isFetchingKPIItemsArtifactFlag = useSelector((state) => isFetchingKPIItemsArtifact(state))
  const isFetchingVisualisationsArtifactFlag = useSelector((state) => isFetchingVisualisationsArtifact(state))
  const comparisonMode = useSelector((state) => getComparisonModeState(state))
  const isFetching = isFetchingUseCaseFlag || isFetchingKPIItemsArtifactFlag || isFetchingVisualisationsArtifactFlag
  const useCaseId = match.params.usecase

  useEffect(() => {
    if (!comparisonMode) {
      dispatch(
        toggleComparisonModeAction(),
      )
    }
  }, [dispatch, comparisonMode])

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'recommendation.results.browser_tab.title' }, { name: useCase.name })}
      isFetching={isFetching}
      overlayFetching={true}
    >
      <KPIItemsArtifactContainer />

      <Box mt={4} />

      <VisualisationsArtifactContainer />
    </PageLayoutContainer>
  )
}

export default RpResultsContainer
