import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils/moment.utils'

export interface TrainingModalHeaderBlockComponentProps {
  /**
   * if true - it is legacy upload folder
   */
  isLegacyUpload: boolean,
  /**
   * Date when the file identifier was created
   */
  createdAt: string,
}

const TrainingModalHeaderBlockComponent: React.FC<TrainingModalHeaderBlockComponentProps> = ({
  isLegacyUpload,
  createdAt,
}) => {
  const intl = useIntl()

  const {
    params: {
      version,
      identifier: fileIdentifier,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const title = isLegacyUpload ? (
    intl.formatMessage({ id: 'connect.data_input.modal.data_types.legacy_files' })
  ) : (
    intl.formatMessage({
      id: 'connect.data_input.modal.file_upload_header',
    }, {
      name: fileIdentifier,
      version,
      date: formatDate(intl, createdAt),
    })
  )

  return (
    <Box
      data-testid={TrainingModalHeaderBlockComponent.name}
    >
      <Typography
        variant='h5'
        mb={3}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default TrainingModalHeaderBlockComponent
