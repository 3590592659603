import React from 'react'

const PinterestIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={PinterestIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M18 9.00002C18 13.9705 13.9705 18 8.99998 18C4.02945 18 0 13.9705 0 9.00002C0 4.02941 4.02945 3.24249e-05 8.99998 3.24249e-05C13.9705 3.24249e-05 18 4.02941 18 9.00002Z" fill="white"/>
      <path d="M8.99998 -8.96454e-05C4.02945 -8.96454e-05 0 4.02936 0 8.99989C0 12.8127 2.37224 16.0722 5.72016 17.3836C5.64152 16.6715 5.5704 15.5764 5.75137 14.799C5.91493 14.0966 6.80672 10.3254 6.80672 10.3254C6.80672 10.3254 6.53752 9.78626 6.53752 8.9892C6.53752 7.73775 7.26287 6.80346 8.16603 6.80346C8.93388 6.80346 9.30475 7.37994 9.30475 8.07121C9.30475 8.84342 8.81311 9.99792 8.5594 11.0678C8.34737 11.9636 9.00862 12.6941 9.89215 12.6941C11.4917 12.6941 12.7214 11.0075 12.7214 8.57281C12.7214 6.41791 11.173 4.91127 8.96199 4.91127C6.40118 4.91127 4.89808 6.83201 4.89808 8.81701C4.89808 9.59054 5.19606 10.42 5.56789 10.8709C5.64144 10.96 5.65221 11.0381 5.6303 11.129C5.56199 11.4133 5.41016 12.0246 5.38035 12.1497C5.3411 12.3145 5.24992 12.3494 5.07935 12.2701C3.95531 11.7468 3.25253 10.1034 3.25253 8.78344C3.25253 5.94442 5.31528 3.33713 9.19918 3.33713C12.3213 3.33713 14.7475 5.5619 14.7475 8.53511C14.7475 11.6368 12.7918 14.1331 10.0773 14.1331C9.16531 14.1331 8.30797 13.6593 8.01449 13.0997C8.01449 13.0997 7.56321 14.818 7.4538 15.2391C7.25063 16.0209 6.70211 17.0007 6.33522 17.5985C7.17729 17.8593 8.07219 17.9999 8.99998 17.9999C13.9706 17.9999 18 13.9704 18 8.99989C18 4.02936 13.9706 -8.96454e-05 8.99998 -8.96454e-05Z" fill="#E60023"/>
    </svg>
  )
}

export default PinterestIcon
