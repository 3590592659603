import React from 'react'

import { DEFAULT_PADDING, TOPBAR_HEIGHT } from '@constants/ui.constants'
import { Divider, useTheme } from '@mui/material'

export interface TopBarDividerComponentProps {
  /**
   * Enables right margin
   *
   * @default true
   */
  mr?: boolean,
  /**
   * Enables left margin
   *
   * @default true
   */
  ml?: boolean,
}

const TopBarDividerComponent: React.FC<TopBarDividerComponentProps> = ({
  mr = true,
  ml = true,
}) => {
  const theme = useTheme()

  const styles = {
    borderColor: theme.palette.new.grey_a,
    backgroundColor: theme.palette.new.grey_a,
    height: TOPBAR_HEIGHT,
    marginLeft: ml ? DEFAULT_PADDING.MEDIUM : 0,
    marginRight: mr ? DEFAULT_PADDING.MEDIUM : 0,
    width: '1px',
  }

  return (
    <Divider
      data-testid={TopBarDividerComponent.name}
      orientation='vertical'
      sx={styles}
    />
  )
}

export default TopBarDividerComponent
