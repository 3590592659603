import { handleActions } from 'redux-actions'
import { ModalManagerState } from '@redux/modules/types'

import {
  SET_MODAL_DETAILS,
  SET_PRIMARY_MODAL_PAGE_NAME_DONE,
} from './modal-manager.action-types'

import {
  handleSetModalDetails,
  handleSetPrimaryModalPageName,
} from './modal-manager.reducers'

const initialState: ModalManagerState = {
  primaryModalPage: null,
  modalDetails: {
    returnTo: '',
  },
}

export default handleActions<ModalManagerState, any>({
  [SET_MODAL_DETAILS]: handleSetModalDetails,
  [SET_PRIMARY_MODAL_PAGE_NAME_DONE]: handleSetPrimaryModalPageName,
}, initialState)
