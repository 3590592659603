import React from 'react'

const OutboundEmailIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={OutboundEmailIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M17.3572 11.0942H12.8572C12.5162 11.0942 12.1892 11.2297 11.948 11.4708C11.7069 11.7119 11.5715 12.039 11.5715 12.38C11.5715 13.0619 11.3005 13.7159 10.8183 14.1982C10.3361 14.6805 9.68201 14.9514 9.00003 14.9514C8.31804 14.9514 7.66399 14.6805 7.18176 14.1982C6.69951 13.7159 6.4286 13.0619 6.4286 12.38C6.4286 12.039 6.29313 11.7119 6.05202 11.4708C5.8109 11.2297 5.48388 11.0942 5.14288 11.0942H0.642883V16.88C0.642883 17.2209 0.778342 17.548 1.01946 17.7891C1.26058 18.0302 1.5876 18.1657 1.9286 18.1657H16.0715C16.4124 18.1657 16.7395 18.0302 16.9806 17.7891C17.2217 17.548 17.3572 17.2209 17.3572 16.88V11.0942Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.78577 4.6657L9.00005 1.45142L12.2143 4.6657" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 9.1657V1.45142" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default OutboundEmailIcon
