import React from 'react'

export interface CloseIconProps extends Common.IconProps {
  opacity?: string,
}

const CloseIcon: React.FC<CloseIconProps> = ({
  opacity = '1',
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={CloseIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M1.5 1.5L16.5 16.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 16.5L16.5 1.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default CloseIcon
