import * as Yup from 'yup'

import {
  PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS, PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON,
  PIPELINE_DEMAND_CONFIG_MIN_STRIDE, PIPELINE_MIN_RAM_MB, PIPELINE_TYPES,
  PIPELINES_WITH_RESOURCE_MANAGEMENT,
} from '@constants/pipelines.constants'

import { isValidCron } from '@utils/pipelines.utils'
import { IntlShape } from 'react-intl'

const validation = (intl: IntlShape) => {
  const shape = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-z0-9_]+$/, intl.formatMessage({ id: 'pipelines.config.dialog.name.validation' }))
      .required(intl.formatMessage({ id: 'pipelines.config.dialog.name.required' })),
    company: Yup.object().required(intl.formatMessage({ id: 'pipelines.config.dialog.company.required' })),
    useCase: Yup.object().required(intl.formatMessage({ id: 'pipelines.config.dialog.useCase.required' })),
    pipelineType: Yup.string().required(intl.formatMessage({ id: 'pipelines.config.dialog.type.required' })),
    executionSchedule: Yup.string()
      .test('executionSchedule', intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.schedule.invalid' }), (value) => {
        return isValidCron(value!)
      }).required(intl.formatMessage({ id: 'pipelines.config.dialog.executionSchedule.required' })),

    memoryMb: Yup.number().when('pipelineType', {
      is: (value: PIPELINE_TYPES) => {
        return PIPELINES_WITH_RESOURCE_MANAGEMENT.includes(value)
      },
      then: Yup.number().min(PIPELINE_MIN_RAM_MB).required(),
    }),
    demandConfig: Yup.object().when('pipelineType', {
      is: (value: PIPELINE_TYPES) => {
        return value === PIPELINE_TYPES.DEMAND_PIPELINE
      },
      then: Yup.object().shape({
        predictionHorizon: Yup.number().min(PIPELINE_DEMAND_CONFIG_MIN_PREDICTION_HORIZON).required(),
        nFolds: Yup.number().min(PIPELINE_DEMAND_CONFIG_MIN_N_FOLDS).required(),
        stride: Yup.number().min(PIPELINE_DEMAND_CONFIG_MIN_STRIDE).required(),
      }),
    }),
    timeResolution: Yup.object().when('pipelineType', {
      is: (value: PIPELINE_TYPES) => {
        return value === PIPELINE_TYPES.DEMAND_PIPELINE
      },
      then: Yup.object().shape({
        stride: Yup.number().min(PIPELINE_DEMAND_CONFIG_MIN_STRIDE).required(),
        unit: Yup.string().required(),
      }),
    }),
    executionOffset: Yup.number().when('pipelineType', {
      is: (value: PIPELINE_TYPES) => {
        return value === PIPELINE_TYPES.DEMAND_PIPELINE
      },
      then: Yup.number().required(),
    }),
  })

  return shape
}

export default validation
