import React, {
  useEffect,
} from 'react'

import {
  useRouteMatch,
} from 'react-router-dom'

import { Grid, GridProps } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { requestVisualisationsArtifactAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'

import {
  getComparisonModeState,
  getVisualisationsArtifact,
  isFetchingVisualisationsArtifact,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import VisualizationItemComponent from '@components/recommendation-artifacts/VisualizationItem'

export interface VisualisationsArtifactContainerProps {
  /**
   * Props for the container Grid component
   */
  containerProps?: GridProps
  /**
   * Props for the item Grid component
   */
  itemsProps?: GridProps
}

const VisualisationsArtifactContainer: React.FC<VisualisationsArtifactContainerProps> = ({ containerProps, itemsProps }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const visualizationsArtifact = useSelector((state) => getVisualisationsArtifact(state))
  const isFetching = useSelector((state) => isFetchingVisualisationsArtifact(state))
  const comparisonMode = useSelector((state) => getComparisonModeState(state))

  useEffect(() => {
    dispatch(
      requestVisualisationsArtifactAction({
        useCaseId,
      }),
    )
  }, [useCaseId, dispatch])

  const {
    visualisations = [],
  } = visualizationsArtifact

  if (visualisations.length === 0 || isFetching) {
    return null
  }

  return (
    <Grid
      container={true}
      spacing={2}
      data-testid={VisualisationsArtifactContainer.name}
      {...containerProps}
    >
      {
        visualisations.map((visualisation, index) => {
          return (
            <Grid
              item={true}
              xs={12}
              sm={6}
              key={index}
              {...itemsProps}
            >
              <VisualizationItemComponent
                key={index}
                id={`results-visualization-${index}`}
                visualization={visualisation}
                comparisonMode={comparisonMode}
              />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default VisualisationsArtifactContainer
