import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  Box, Grid,
  Typography, useTheme,
} from '@mui/material'

import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'

import IconButtonComponent from '@base/buttons/IconButton/IconButton.component'
import ExpandMoreIcon from '@icons/expandMore.icon'
import ExportIcon from '@icons/export.icon'
import ExpandLessIcon from '@icons/expandLess.icon'
import type { SectionTileComponentProps } from './SectionTile.component'

export interface SectionTileHeaderComponentProps extends Pick<SectionTileComponentProps,
  'name' | 'title' | 'collapsable' | 'exportable' | 'rightSideActions' |
  'loading' | 'leftSideActionsBefore' | 'leftSideActionsAfter' | 'contentType' | 'noData'
> {
  /**
   * Function to handle export action
   */
  handleExport: () => void
  /**
   * Function to handle collapse action
   */
  handleCollapse: () => void
  /**
   * If 'true' the content section is collapsed
   */
  isCollapsed: boolean
  /**
   * If 'true' the header is hidden
   */
  hidden?: boolean
}

const SectionTileHeaderComponent: React.FC<SectionTileHeaderComponentProps> = ({
  name,
  title,
  hidden,
  loading,
  collapsable,
  exportable,
  rightSideActions,
  leftSideActionsBefore,
  leftSideActionsAfter,
  isCollapsed,
  contentType,
  noData,
  handleCollapse,
  handleExport,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const containerStyles = useMemo(() => {
    const baseStyles = {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      height: '60px',
      py: DEFAULT_PADDING.MEDIUM,
      px: DEFAULT_PADDING.SMALL,
      borderTopLeftRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
      borderTopRightRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
      gap: '10px',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: isCollapsed ? theme.palette.new.transparent : theme.palette.new.business_black_20,
      transition: theme.transitions.create('border-color'),
    }

    if (contentType === 'data-grid' && !loading && !noData) {
      Object.assign(baseStyles, {
        border: '1px solid',
        borderColor: theme.palette.new.business_black_20,
        borderRadius: isCollapsed ? DEFAULT_BORDER_RADIUS.DEFAULT : undefined,
      })
    }

    if (contentType === 'data-grid-headless' && !loading && !noData) {
      Object.assign(baseStyles, {
        border: '1px solid',
        borderColor: theme.palette.new.business_black_20,
        borderRadius: isCollapsed ? DEFAULT_BORDER_RADIUS.DEFAULT : undefined,
      })
    }

    return baseStyles
  }, [
    contentType, theme,
    isCollapsed,
    loading, noData,
  ])

  const rightSideActionsBlock = useMemo(() => {
    const collapseButton = collapsable ? (
      <IconButtonComponent
        name={`collapse-${name}`}
        IconComponent={isCollapsed ? ExpandMoreIcon : ExpandLessIcon}
        onClick={handleCollapse}
        label={intl.formatMessage({ id: 'common.button.collapse' })}
        tooltip={false}
        disabled={loading}
        color='tertiary'
      />
    ) : (
      null
    )

    const exportButton = exportable ? (
      <IconButtonComponent
        name={`export-${name}`}
        IconComponent={ExportIcon}
        label={intl.formatMessage({ id: 'common.button.export' })}
        tooltip={false}
        disabled={loading}
        onClick={handleExport}
        color='tertiary'
      />
    ) : (
      null
    )

    return (
      <>
        {rightSideActions}

        {exportButton}

        {collapseButton}
      </>
    )
  }, [
    intl, name, isCollapsed,
    handleCollapse, handleExport,
    rightSideActions, loading,
    collapsable,
    exportable,
  ])

  const leftSideActionsBlock = useMemo(() => {
    const titleToUse = loading ? intl.formatMessage({ id: 'common.loading' }) : title

    const titleContent = titleToUse ? (
      <Typography
        variant='h5'
        pl={leftSideActionsBefore && !loading ? 0 : DEFAULT_PADDING.SMALL}
        noWrap={true}
        title={titleToUse}
      >
        {titleToUse}
      </Typography>
    ) : (
      null
    )

    if (loading) {
      return titleContent
    }

    return (
      <>
        {leftSideActionsBefore}

        {titleContent}

        {leftSideActionsAfter}
      </>
    )
  }, [
    loading, title, intl,
    leftSideActionsBefore,
    leftSideActionsAfter,
  ])

  if (hidden) {
    return null
  }

  return (
    <Grid
      xs={12}
      item={true}
      sx={containerStyles}
      data-testid={SectionTileHeaderComponent.name}
    >
      <Box
        display='flex'
        alignItems='center'
        gap='5px'
        minWidth={0}
      >
        {leftSideActionsBlock}
      </Box>

      <Box
        display='flex'
        alignItems='center'
        gap='10px'
      >
        {rightSideActionsBlock}
      </Box>
    </Grid>
  )
}

export default SectionTileHeaderComponent
