import produce from 'immer'
import { ParametersState, ReducerPayload } from '@redux/modules/types'

export const initialState: ParametersState = {
  fetchingKeys: [],
  item: {} as UseCase.ParameterItem,
  groupingItem: {} as UseCase.GroupingParameter,
}

export const receive = (state: ParametersState, action: ReducerPayload<UseCase.ParameterItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
  })

  return nextState
}

export const receiveGrouping = (state: ParametersState, action: ReducerPayload<UseCase.GroupingParameter>) => {
  const nextState = produce(state, (draftState) => {
    draftState.groupingItem = action.payload
  })

  return nextState
}
