import { createAction } from 'redux-actions'

import {
  REDIRECTED_TO_USE_CASE,
  REDIRECT_TO_USE_CASE,
  SET_USE_CASE_CONTACTS,
  REQUEST_USE_CASES,
  RECEIVE_USE_CASES,
  REQUEST_USE_CASE,
  RECEIVE_USE_CASE,
  CREATE_USE_CASE,
  UPDATE_USE_CASE,
  DELETE_USE_CASE,
  MANAGE_USE_CASE,
  RESET_CONNECT_OVERVIEW,
  FREEZE_USE_CASE,
  START_FETCHING_USE_CASES,
  STOP_FETCHING_USE_CASES,
  PREPARE_CONNECT_OVERVIEW,
  PREPARE_CONNECT_OVERVIEW_DONE,
  REQUEST_ARTIFACT_MAPPINGS,
  RECEIVE_ARTIFACT_MAPPINGS,
  CREATE_ARTIFACT_MAPPINGS,
  DELETE_ARTIFACT_MAPPINGS,
  UPDATE_DEMAND_PROBLEM_DEFINITION,
  REQUEST_DEMAND_PROBLEM_DEFINITION,
  RECEIVE_DEMAND_PROBLEM_DEFINITION,
  CREATE_FORECAST_PARAMETERS,
  REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS,
  REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
  RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS,
  SET_USE_CASE_META_DATA_COLUMNS,
} from './use-case.action-types'

import {
  CreatePayload,
  ConnectOverviewUseCaseGeneratorPayload,
  CreateArtifactsMappingsPayload,
  DeleteArtifactsMappingsPayload,
  FetchAllUseCasesActionActionPayload,
  FetchUseCaseActionPayload,
  GetArtifactsMappingsPayload,
  RemovePayload,
  UpdateUseCaseActionPayload,
  EditDemandProblemDefinitionPayload,
  ManageUseCaseActionPayload,
  GetDemandProblemDefinitionPayload,
  SetUseCaseContactsPayload,
  CreateForecastParametersPayload,
  GetAvailableMetaDataColumnsActionPayload,
  GetSpecifiedMetaDataColumnsActionPayload,
  SetSpecifiedMetaDataColumnsActionPayload,
  FreezeUseCaseActionPayload,
  RedirectToUseCaseActionPayload,
} from './use-case.types'

export const fetchAllUseCasesAction = createAction<FetchAllUseCasesActionActionPayload>(REQUEST_USE_CASES)
export const receiveUseCasesActionDone = createAction(RECEIVE_USE_CASES)
export const fetchUseCaseAction = createAction<FetchUseCaseActionPayload>(REQUEST_USE_CASE)
export const receiveUseCaseActionDone = createAction(RECEIVE_USE_CASE)
export const createUseCaseAction = createAction<CreatePayload>(CREATE_USE_CASE)
export const updateUseCaseAction = createAction<UpdateUseCaseActionPayload>(UPDATE_USE_CASE)

export const redirectToUseCaseAction = createAction<RedirectToUseCaseActionPayload>(REDIRECT_TO_USE_CASE)
export const redirectToUseCaseActionDone = createAction(REDIRECTED_TO_USE_CASE)

export const requestDemandProblemDefinitionAction = createAction<GetDemandProblemDefinitionPayload>(REQUEST_DEMAND_PROBLEM_DEFINITION)
export const receiveDemandProblemDefinitionActionDone = createAction(RECEIVE_DEMAND_PROBLEM_DEFINITION)
export const updateDemandProblemDefinitionAction = createAction<EditDemandProblemDefinitionPayload>(UPDATE_DEMAND_PROBLEM_DEFINITION)

export const setUseCaseContactsAction = createAction<SetUseCaseContactsPayload>(SET_USE_CASE_CONTACTS)
export const removeUseCaseAction = createAction<RemovePayload>(DELETE_USE_CASE)
export const freezeUseCaseAction = createAction<FreezeUseCaseActionPayload>(FREEZE_USE_CASE)
export const manageUseCaseAction = createAction<ManageUseCaseActionPayload>(MANAGE_USE_CASE)

export const requestArtifactsMappingsAction = createAction<GetArtifactsMappingsPayload>(REQUEST_ARTIFACT_MAPPINGS)
export const receiveArtifactsMappingsActionDone = createAction(RECEIVE_ARTIFACT_MAPPINGS)
export const createArtifactsMappingsAction = createAction<CreateArtifactsMappingsPayload>(CREATE_ARTIFACT_MAPPINGS)
export const deleteArtifactsMappingsAction = createAction<DeleteArtifactsMappingsPayload>(DELETE_ARTIFACT_MAPPINGS)

export const createForecastParametersAction = createAction<CreateForecastParametersPayload>(CREATE_FORECAST_PARAMETERS)

export const requestUseCaseAvailableMetaDataColumnsAction = createAction<GetAvailableMetaDataColumnsActionPayload>(REQUEST_USE_CASE_AVAILABLE_META_DATA_COLUMNS)
export const receiveUseCaseAvailableMetaDataColumnsActionDone = createAction(RECEIVE_USE_CASE_AVAILABLE_META_DATA_COLUMNS)
export const requestUseCaseSpecifiedMetaDataColumnsAction = createAction<GetSpecifiedMetaDataColumnsActionPayload>(REQUEST_USE_CASE_SPECIFIED_META_DATA_COLUMNS)
export const receiveUseCaseSpecifiedMetaDataColumnsActionDone = createAction(RECEIVE_USE_CASE_SPECIFIED_META_DATA_COLUMNS)
export const setUseCaseMetaDataColumnsAction = createAction<SetSpecifiedMetaDataColumnsActionPayload>(SET_USE_CASE_META_DATA_COLUMNS)

export const startFetchingUseCasesAction = createAction<string>(START_FETCHING_USE_CASES)
export const stopFetchingUseCasesAction = createAction<string>(STOP_FETCHING_USE_CASES)

export const resetConnectOverviewAction = createAction<void>(RESET_CONNECT_OVERVIEW)
export const prepareConnectOverviewAction = createAction<ConnectOverviewUseCaseGeneratorPayload>(PREPARE_CONNECT_OVERVIEW)
export const prepareConnectOverviewActionDone = createAction(PREPARE_CONNECT_OVERVIEW_DONE)
