import React, { useLayoutEffect } from 'react'
import { Box } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'

import PageLayoutContainer from '@containers/application/PageLayout'
import ComparisonToggleContainer from '@containers/shared/artifacts/ComparisonToggle'
import ConstraintsAnalyzeArtifactContainer from '@containers/shared/artifacts/ConstraintsAnalyzeArtifact'
import TopArticlesArtifactContainer from '@containers/shared/artifacts/TopArticlesArtifact'

const AaAnalyzeContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId,
      }),
    )
  }, [dispatch, useCaseId])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'recommendation.analyze.browser_tab.title' }, { name: useCase.name })}
      pageBarRightSideBlocks={(
        <ComparisonToggleContainer />
      )}
    >
      <ConstraintsAnalyzeArtifactContainer />

      <Box mt={4} />

      <TopArticlesArtifactContainer />
    </PageLayoutContainer>
  )
}

export default AaAnalyzeContainer
