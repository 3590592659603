import { Location } from 'history'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Prompt, matchPath } from 'react-router-dom'

interface SidePanelRouterPromptComponentProps {
  /**
   * Flag to determine if prompt is active
   */
  isPromptActive: boolean,
  /**
   * Custom prompt message to be displayed when closing the side panel
   */
  customPromptMessage?: string,
  /**
   * Array of allowed redirects when the prompt is active
   */
  allowedRedirects?: string[],
  /**
   * If true, the side panel is considered global.
   * Thant means that it is available on all pages, therefore prompt has to be handled differently.
   * Side needs to be closed with resetGlobalSidePanel.
   */
  isGlobalSidePanel?: boolean,
  /**
   * Function to reset the global side panel.
   */
  resetGlobalSidePanel?: () => void,
}

const SidePanelRouterPromptComponent: React.FC<SidePanelRouterPromptComponentProps> = ({
  isPromptActive, customPromptMessage,
  allowedRedirects = [], isGlobalSidePanel,
  resetGlobalSidePanel,
}) => {
  const intl = useIntl()

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isPromptActive) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [isPromptActive])

  const onLocationChange = (newLocation: Location) => {
    if (allowedRedirects.includes(newLocation.pathname)) {
      return true
    }

    if (allowedRedirects.some((allowedRedirect) => matchPath(newLocation.pathname, { path: allowedRedirect }))) {
      return true
    }

    if (isGlobalSidePanel) {
      // eslint-disable-next-line
      const answer = confirm(customPromptMessage || intl.formatMessage({ id: 'common.form.leave' }))

      if (answer && resetGlobalSidePanel) {
        resetGlobalSidePanel()
      }

      return answer
    }

    return customPromptMessage || intl.formatMessage({ id: 'common.form.leave' })
  }

  return (
    <Prompt
      when={isPromptActive}
      message={onLocationChange}
      data-testid={SidePanelRouterPromptComponent.name}
    />
  )
}

export default SidePanelRouterPromptComponent
