import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import FormGroupComponent from '@base/forms/FormGroup'
import CheckboxFieldComponent from '@base/forms/CheckboxField'

import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { MANAGE_PROMOTIONS_MODAL_NAME } from '@constants/modals.constants'
import { getPromotionsToExclude, isSubmittingPromotionSettings } from '@redux/modules/insights/insights.selectors'
import { requestPromotionsSettingsChangeAction } from '@redux/modules/insights/insights.actions'
import { PROMOTION_DATES_TO_LABELS_MAP, PROMOTION_DAYS, PROMOTION_DAYS_LIST } from '@constants/promotions.constants'
import { PROMOTION_DAYS_LIST_SORTED } from '@utils/promotions.utils'

const ManagePromotionsModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isSubmitting = useSelector((state) => isSubmittingPromotionSettings(state))
  const promotionsToExclude = useSelector((state) => getPromotionsToExclude(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === MANAGE_PROMOTIONS_MODAL_NAME

  const initialValues = {
    promotionsToExclude,
  }

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: {
    promotionsToExclude: PROMOTION_DAYS[]
  }) => {
    dispatch(
      requestPromotionsSettingsChangeAction(values.promotionsToExclude),
    )
  }

  const {
    setFieldValue,
    handleSubmit,
    resetForm,
    isValid,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const hasAllSelected = values.promotionsToExclude.length === 0

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'common.promotions.manage' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={false}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer divider={false} spacing={2}>
                <FormLayoutItem xs={12}>
                  <CheckboxFieldComponent
                    key='all'
                    label={intl.formatMessage({ id: 'common.all' })}
                    name='all'
                    checked={hasAllSelected}
                    indeterminate={values.promotionsToExclude.length > 0 && values.promotionsToExclude.length !== PROMOTION_DAYS_LIST.length}
                    onChange={() => {
                      const newExcludeList = []

                      if (hasAllSelected) {
                        newExcludeList.push(...PROMOTION_DAYS_LIST)
                      }

                      setFieldValue('promotionsToExclude', newExcludeList)
                    }}
                  />
                </FormLayoutItem>

                <FormLayoutItem xs={12}>
                  <FormGroupComponent>
                    {
                      PROMOTION_DAYS_LIST_SORTED.map((promotion) => (
                        <CheckboxFieldComponent
                          key={promotion}
                          label={intl.formatMessage({ id: PROMOTION_DATES_TO_LABELS_MAP[promotion as PROMOTION_DAYS] })}
                          name={promotion}
                          checked={!values.promotionsToExclude.includes(promotion as PROMOTION_DAYS)}
                          onChange={() => {
                            const newExcludeList = []

                            if (values.promotionsToExclude.includes(promotion as PROMOTION_DAYS)) {
                              newExcludeList.push(...values.promotionsToExclude.filter((item) => item !== promotion))
                            } else {
                              newExcludeList.push(...values.promotionsToExclude, promotion)
                            }

                            setFieldValue('promotionsToExclude', newExcludeList)
                          }}
                        />
                      ))
                    }
                  </FormGroupComponent>
                </FormLayoutItem>
              </FormLayoutItemsContainer>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='managePromotionsModalBackButton'
              onClick={() => handleCloseAction()}
              type='cancel'
            />

            <ModalButtonComponent
              name='managePromotionsModalSubmitButton'
              onClick={(e) => handleSubmitAction(values)}
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              type='submit'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ManagePromotionsModalContainer
