import React from 'react'
import {
  Dialog, DialogProps,
  Slide, useTheme,
} from '@mui/material'

import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BOX_SHADOW,
} from '@constants/ui.constants'

const Transition: any = React.forwardRef((props: any, ref: any) => {
  return <Slide direction='up' ref={ref} {...props} />
})

export interface DialogComponentProps extends DialogProps {
  /**
   * Open state of the dialog
   */
  open: boolean
  /**
   * Function to be called when the dialog is closed
   */
  onClose?: DialogProps['onClose']
  /**
   * Min width of the dialog
   * @default '500px'
   */
  width?: string
  /**
   * Children to be rendered
   */
  children?: React.ReactNode
}

export const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  children,
  width = '500px',
  onClose,
  ...props
}) => {
  const theme = useTheme()

  const paperProps = {
    elevation: 0,
    sx: {
      minWidth: width,
      maxWidth: width,
      borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
      boxShadow: DEFAULT_BOX_SHADOW,
      zIndex: theme.zIndex.drawer + 101,
    },
  }

  const slotProps: DialogProps['slotProps'] = {
    backdrop: {
      sx: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
  }

  return (
    <Dialog
      data-testid={DialogComponent.name}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      slotProps={slotProps}
      PaperProps={paperProps}
      {...props}
    >
      {children}
    </Dialog>
  )
}

export default DialogComponent
