import React from 'react'

const FileImgIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={FileImgIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M13.5 11.5715V16.0715C13.5 16.4124 13.3645 16.7395 13.1234 16.9806C12.8823 17.2217 12.5553 17.3572 12.2143 17.3572H1.92857C1.58757 17.3572 1.26055 17.2217 1.01943 16.9806C0.778312 16.7395 0.642853 16.4124 0.642853 16.0715V1.9286C0.642853 1.5876 0.778312 1.26058 1.01943 1.01946C1.26055 0.778342 1.58757 0.642883 1.92857 0.642883H6.42857M6.10714 14.1429H8.03571M17.3571 3.85717C17.3571 3.51618 17.2217 3.18915 16.9806 2.94803C16.7394 2.70691 16.4124 2.57145 16.0714 2.57145H15.4286L14.1429 0.642883H11.5714L10.2857 2.57145H9.64285C9.30186 2.57145 8.97483 2.70691 8.73372 2.94803C8.4926 3.18915 8.35714 3.51618 8.35714 3.85717V7.07145C8.35714 7.41245 8.4926 7.73947 8.73372 7.98059C8.97483 8.22171 9.30186 8.35717 9.64285 8.35717H16.0714C16.4124 8.35717 16.7394 8.22171 16.9806 7.98059C17.2217 7.73947 17.3571 7.41245 17.3571 7.07145V3.85717ZM13.5 5.14288C13.5 5.49792 13.2122 5.78574 12.8571 5.78574C12.5021 5.78574 12.2143 5.49792 12.2143 5.14288C12.2143 4.78784 12.5021 4.50003 12.8571 4.50003C13.2122 4.50003 13.5 4.78784 13.5 5.14288Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FileImgIcon
