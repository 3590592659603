import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import {
  Box, Grid,
  Typography, useTheme,
} from '@mui/material'

import { formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import { TRADE_OFFS_LABEL_REPLENISHMENT_COST, TRADE_OFFS_LABEL_REVENUE } from '@constants/optimize.constants'
import { useDispatch } from '@redux/hooks'
import { setStrategyAction } from '@redux/modules/optimize/optimize.actions'
import ModalButtonComponent from '@base/buttons/ModalButton'

export type TradeOffsDatasetSolution = (Socrates.TradeOffsDatasetKpi & Socrates.TradeOffsDatasetDesign)

export interface TradeOffsOptimizePanelComponentProps {
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  solutionToExecute: number | null,
}

const TradeOffsOptimizePanelComponent: React.FC<TradeOffsOptimizePanelComponentProps> = ({
  selectedPoints,
  solutionToExecute,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const dispatch = useDispatch()
  const {
    params: {
      usecase,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const point = ((solutionToExecute !== null ? selectedPoints[solutionToExecute] : null) || {}) as Socrates.TradeOffsDatasetItem
  const datasetPayload = (point?.datasetPayload || {}) as Socrates.TradeOffsDatasetPayload
  const targets = (datasetPayload.datasetKpis || []) as Socrates.TradeOffsDatasetKpi[]
  const hasNoTargets = targets.length === 0
  const handleSetStrategy = () => {
    dispatch(
      setStrategyAction({
        targetRevenue: (targets.find((target: TradeOffsDatasetSolution) => target.label === TRADE_OFFS_LABEL_REVENUE)?.value || 0),
        targetReplenishmentCost: (targets.find((target: TradeOffsDatasetSolution) => target.label === TRADE_OFFS_LABEL_REPLENISHMENT_COST)?.value || 0),
        useCaseId: usecase,
      }),
    )
  }

  return (
    <Grid
      item={true}
      xs={4}
      data-testid={TradeOffsOptimizePanelComponent.name}
      sx={{
        backgroundColor: theme.palette.new.grey_d,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          height: theme.spacing(8),
          borderBottom: `1px solid ${theme.palette.new.grey_a}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            padding: theme.spacing(0, 4),
            [theme.breakpoints.down('xl')]: {
              padding: theme.spacing(0, 2),
            },
          }}
        >
          <Box
            sx={{
              width: theme.spacing(2),
              height: theme.spacing(2),
              borderRadius: '100%',
              backgroundColor: theme.palette.new.pink,
              marginRight: theme.spacing(1),
            }}
          />
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              color: theme.palette.new.black,
              [theme.breakpoints.down('xl')]: {
                fontSize: '12px',
              },
            }}
          >
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
          </Typography>
        </Box>
        <Box />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: hasNoTargets ? 'none' : 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              height: theme.spacing(6),
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '35px',
              alignItems: 'flex-end',
              textTransform: 'uppercase',
              borderBottom: `1px solid ${theme.palette.new.grey_a}`,
              color: theme.palette.new.grey_c,
              padding: theme.spacing(0, 4),
              [theme.breakpoints.down('xl')]: {
                padding: theme.spacing(0, 2),
              },
            }}
          >
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.targets' })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              marginBottom: theme.spacing(3),
            }}
          >
            {
              targets.map((target: TradeOffsDatasetSolution, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      padding: theme.spacing(1.5, 4),
                      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                      [theme.breakpoints.down('xl')]: {
                        padding: theme.spacing(1.5, 2),
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: theme.palette.new.black,
                        [theme.breakpoints.down('xl')]: {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {target.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: theme.palette.new.black,
                        [theme.breakpoints.down('xl')]: {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {formatNumberWithSuffixUnit(target.value)}
                    </Typography>
                  </Box>
                )
              })
            }
          </Box>
        </Box>

        {
          hasNoTargets ? (
            <Typography
              sx={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: theme.spacing(2),
                fontSize: '16px',
                color: theme.palette.new.pink,
                minHeight: '78px',
                padding: theme.spacing(4),
                [theme.breakpoints.down('xl')]: {
                  padding: theme.spacing(2),
                  fontSize: '14px',
                },
              }}
            >
              {intl.formatMessage({ id: 'trade_offs.details.empty_optimize' })}
            </Typography>
          ) : (
            null
          )
        }

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing(0, 4),
            marginBottom: theme.spacing(4),
            background: 'transparent',
            [theme.breakpoints.down('xl')]: {
              padding: theme.spacing(0, 2),
              marginBottom: theme.spacing(2),
            },
          }}
        >
          <ModalButtonComponent
            name='setStrategyButton'
            onClick={handleSetStrategy}
            type='submit'
            loading={false}
            disabled={solutionToExecute === null}
            label={intl.formatMessage({ id: 'trade_offs.details.set_strategy' })}
          />
        </Box>
      </Box>
    </Grid>
  )
}

export default TradeOffsOptimizePanelComponent
