import React from 'react'

const ChatIcon: React.FC<Common.IconProps> = ({
  width = 19,
  height = 19,
  viewBox = '0 0 19 19',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={ChatIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g clipPath="url(#clip0_8_2337)">
        <path d="M8.19483 0.960022C6.80162 1.15818 5.48102 1.70494 4.35547 2.54959C3.22993 3.39424 2.3359 4.50944 1.75629 5.79176C1.17668 7.07407 0.930266 8.48199 1.03989 9.88494C1.14952 11.2879 1.61164 12.6404 2.3834 13.8172L0.981972 17.5972L5.67483 16.7486C6.88154 17.3396 8.21313 17.6304 9.55636 17.5964C10.8996 17.5623 12.2147 17.2044 13.39 16.553C14.5652 15.9017 15.5657 14.9762 16.3065 13.8551C17.0473 12.7341 17.5064 11.4508 17.6448 10.1143" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5718 0.740051C16.5718 1.43818 16.0059 2.00412 15.3077 2.00412C16.0059 2.00412 16.5718 2.57006 16.5718 3.26819C16.5718 2.57006 17.1377 2.00412 17.8359 2.00412C17.1377 2.00412 16.5718 1.43818 16.5718 0.740051Z" stroke="url(#paint0_linear_8_2337)" strokeLinejoin="round"/>
        <path d="M11.8432 1.88873C11.8432 4.56403 9.67441 6.73277 6.99911 6.73277C9.67441 6.73277 11.8432 8.90151 11.8432 11.5768C11.8432 8.90151 14.0119 6.73277 16.6872 6.73277C14.0119 6.73277 11.8432 4.56403 11.8432 1.88873Z" stroke="url(#paint1_linear_8_2337)" strokeLinejoin="round"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_8_2337" x1="15.3077" y1="3.26594" x2="17.8359" y2="0.740051" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F500E1"/>
          <stop offset="0.0001" stopColor="#F500E1"/>
          <stop offset="1" stopColor="#FBB03B"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8_2337" x1="6.99911" y1="11.5682" x2="16.6872" y2="1.88873" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F500E1"/>
          <stop offset="0.0001" stopColor="#F500E1"/>
          <stop offset="1" stopColor="#FBB03B"/>
        </linearGradient>
        <clipPath id="clip0_8_2337">
          <rect width="18" height="18" fill="white" transform="translate(0.339111 0.240051)"/>
        </clipPath>
      </defs>

    </svg>
  )
}

export default ChatIcon
