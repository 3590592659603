import React from 'react'
import { Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import KpiTileComponent from '@base/tiles/KpiTile/KpiTile.component'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold/IntlFormatBold.component'

export interface InsightsKpisComponentProps {
  /**
   * Whether the component is loading
   */
  loading?: boolean
  /**
   * Whether the values are loading
   */
  loadingValues?: boolean
  /**
   * The name of the target
   */
  targetName: string
  /**
   * The absolute deviation
   */
  absDeviation: number | null
  /**
   * The relative deviation (as float)
   */
  relDeviation: number | null
  /**
   * The target value
   */
  target: number | null
  /**
   * The prediction value
   */
  prediction: number | null
}

const InsightsKpisComponent: React.FC<InsightsKpisComponentProps> = ({
  loading,
  loadingValues,
  targetName,
  absDeviation,
  relDeviation,
  target,
  prediction,
}) => {
  const intl = useIntl()

  const relativeDeviationValue = relDeviation === null ? (
    intl.formatMessage({ id: 'common.na' })
  ) : (
    `${defaultNumberFormatter(relDeviation * 100, { intl })}%`
  )

  const absDeviationValue = absDeviation === null ? intl.formatMessage({ id: 'common.na' }) : absDeviation
  const targetValue = target === null ? intl.formatMessage({ id: 'common.na' }) : target
  const predictionValue = prediction === null ? intl.formatMessage({ id: 'common.na' }) : prediction

  return (
    <Grid
      data-testid={InsightsKpisComponent.name}
      container={true}
      mb={2}
      spacing={2}
    >
      <Grid
        item={true}
        xs={3}
      >
        <KpiTileComponent
          title={intl.formatMessage({ id: 'insights.kpis.absDeviation' })}
          unit={intl.formatMessage({ id: 'insights.table.mae' })}
          tooltip={intl.formatMessage({ id: 'insights.table.mae.help' }, {
            name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
          })}
          value={absDeviationValue}
          loading={loading}
          loadingValues={loadingValues}
        />
      </Grid>
      <Grid
        item={true}
        xs={3}
      >
        <KpiTileComponent
          title={intl.formatMessage({ id: 'insights.kpis.relDeviation' })}
          unit={intl.formatMessage({ id: 'insights.table.wape' })}
          tooltip={intl.formatMessage({ id: 'insights.table.wape.help' }, {
            name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
          })}
          value={relativeDeviationValue}
          valueType='string'
          loading={loading}
          loadingValues={loadingValues}
        />
      </Grid>
      <Grid
        item={true}
        xs={3}
      >
        <KpiTileComponent
          title={
            intl.formatMessage({
              id: 'insights.table.targetColumn',
            }, {
              name: targetName,
            })
          }
          unit={intl.formatMessage({ id: 'insights.table.sum' })}
          value={targetValue}
          loading={loading}
          loadingValues={loadingValues}
        />
      </Grid>
      <Grid
        item={true}
        xs={3}
      >
        <KpiTileComponent
          title={
            intl.formatMessage({
              id: 'insights.table.predictionColumn',
            }, {
              name: targetName,
            })
          }
          unit={intl.formatMessage({ id: 'insights.table.sum' })}
          value={predictionValue}
          variant='highlighted'
          loading={loading}
          loadingValues={loadingValues}
        />
      </Grid>
    </Grid>
  )
}

export default InsightsKpisComponent
