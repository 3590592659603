import React from 'react'

const SnowflakeblackIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={SnowflakeblackIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.1114 13.4555L11.5231 11.3825C11.019 11.0923 10.3743 11.2649 10.0826 11.769C9.96953 11.9676 9.92523 12.1861 9.94662 12.3984V16.4496C9.94662 17.0286 10.4171 17.4991 10.9976 17.4991C11.5766 17.4991 12.0471 17.0286 12.0471 16.4496V14.12L14.0574 15.281C14.5615 15.5728 15.2077 15.3986 15.4979 14.8945C15.7897 14.3904 15.6171 13.7457 15.1114 13.4555Z" fill="black"/>
      <path d="M6.1874 9.01328C6.19351 8.63902 5.99645 8.29073 5.6726 8.10283L2.08426 6.03141C1.92845 5.94128 1.74972 5.89392 1.57099 5.89392C1.20283 5.89392 0.860652 6.09098 0.677339 6.40872C0.393206 6.90061 0.56277 7.53151 1.05466 7.81564L3.12303 9.0087L1.05466 10.2033C0.816351 10.3408 0.64526 10.5623 0.57499 10.8281C0.503193 11.0939 0.539855 11.3719 0.677339 11.6102C0.860652 11.9279 1.20283 12.125 1.56946 12.125C1.74972 12.125 1.92845 12.0777 2.08426 11.9875L5.6726 9.9161C5.99339 9.72973 6.19045 9.38449 6.1874 9.01328Z" fill="black"/>
      <path d="M2.8991 4.56342L6.48744 6.63485C6.906 6.87774 7.42233 6.7983 7.75229 6.47598C7.96157 6.2835 8.09142 6.00853 8.09142 5.70301V1.55099C8.09142 0.970501 7.62092 0.5 7.04196 0.5C6.46147 0.5 5.99097 0.970501 5.99097 1.55099V3.91419L3.95315 2.73794C3.44904 2.44616 2.80439 2.61878 2.51262 3.12289C2.22085 3.627 2.39499 4.27165 2.8991 4.56342Z" fill="black"/>
      <path d="M10.9701 9.18149C10.9701 9.26092 10.9243 9.37091 10.8678 9.42896L9.44556 10.8512C9.38904 10.9077 9.27752 10.9535 9.19809 10.9535H8.83605C8.75661 10.9535 8.6451 10.9077 8.58857 10.8512L7.16485 9.42896C7.10833 9.37091 7.0625 9.26092 7.0625 9.18149V8.81944C7.0625 8.73848 7.10833 8.62849 7.16485 8.57197L8.58857 7.14825C8.6451 7.09173 8.75661 7.0459 8.83605 7.0459H9.19809C9.27752 7.0459 9.38904 7.09173 9.44556 7.14825L10.8678 8.57197C10.9243 8.62849 10.9701 8.73848 10.9701 8.81944V9.18149ZM9.70067 9.00734V8.99206C9.70067 8.93401 9.66706 8.85305 9.62582 8.81028L9.20572 8.39172C9.16448 8.34894 9.08352 8.31534 9.02394 8.31534H9.00866C8.95062 8.31534 8.86965 8.34894 8.82688 8.39172L8.40832 8.81028C8.36707 8.85152 8.33346 8.93249 8.33346 8.99206V9.00734C8.33346 9.06692 8.36707 9.14788 8.40832 9.18912L8.82688 9.60921C8.86965 9.65046 8.95062 9.68407 9.00866 9.68407H9.02394C9.08352 9.68407 9.16448 9.65046 9.20572 9.60921L9.62582 9.18912C9.66706 9.14788 9.70067 9.06692 9.70067 9.00734Z" fill="black"/>
      <path d="M11.5227 6.63485L15.111 4.56342C15.6151 4.27318 15.7893 3.627 15.4975 3.12289C15.2057 2.61878 14.5611 2.44616 14.057 2.73794L12.0466 3.89891V1.55099C12.0466 0.970501 11.5761 0.5 10.9972 0.5C10.4167 0.5 9.9462 0.970501 9.9462 1.55099V5.62052C9.92634 5.83133 9.96758 6.0513 10.0822 6.24989C10.3739 6.754 11.0186 6.92662 11.5227 6.63485Z" fill="black"/>
      <path d="M7.20694 11.2605C6.96863 11.2147 6.71352 11.2529 6.48744 11.3827L2.8991 13.4557C2.39499 13.7459 2.22085 14.3906 2.51262 14.8947C2.80439 15.4003 3.44904 15.573 3.95315 15.2812L5.99097 14.1049V16.4498C5.99097 17.0288 6.46147 17.4993 7.04196 17.4993C7.62092 17.4993 8.09142 17.0288 8.09142 16.4498V12.2978C8.09142 11.7738 7.70799 11.34 7.20694 11.2605Z" fill="black"/>
      <path d="M17.3529 6.39651C17.0627 5.89087 16.4165 5.71825 15.9124 6.01002L12.324 8.08145C11.9803 8.28004 11.7909 8.64361 11.797 9.01329C11.794 9.38144 11.9834 9.74043 12.324 9.93596L15.9124 12.0089C16.4165 12.2992 17.0611 12.1265 17.3529 11.6224C17.6447 11.1183 17.4705 10.4737 16.9664 10.1819L14.9347 9.00871L16.9664 7.83551C17.4721 7.54526 17.6447 6.90062 17.3529 6.39651Z" fill="black"/>

    </svg>
  )
}

export default SnowflakeblackIcon
