import { createSelector } from 'reselect'

import { State } from '@redux/modules/types'

import {
  CREATE_PIPELINE,
  REQUEST_PIPELINES_LIST,
  REQUEST_PIPELINE_OVERVIEW,
  UPDATE_PIPELINE_DELIVERY_SCHEDULE,
  DELETE_PIPELINE_DELIVERY_SCHEDULE,
  SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG,
  REQUEST_COMPOSITE_PIPELINE_STATUS,
  START_COMPOSITE_PIPELINE,
  STOP_COMPOSITE_PIPELINE,
  UPDATE_PIPELINE_EXECUTION_SCHEDULE,
  UPDATE_PIPELINE_CONFIGURATION,
  REPORT_PIPELINE_DELIVERY,
} from './pipelines.action-types'

export const getCompositePipelineStatus = (state: State) => {
  return state.pipelines.compositePipelineStatus
}

export const isCompositePipelineRunning = (state: State) => {
  return state.pipelines.compositePipelineStatus.status === 'RUNNING'
}

export const isFetchingCompositePipelineStatus = (state: State) => {
  return state.pipelines.fetchingKeys.includes(REQUEST_COMPOSITE_PIPELINE_STATUS)
}

export const getPipelinesList = (state: State) => {
  return state.pipelines.pipelinesList
}

export const getPipelineOverview = (state: State) => {
  return state.pipelines.pipelineOverview
}

export const getPipelinesByUseCaseId = createSelector(
  getPipelinesList,
  (state: State, useCaseId: string) => useCaseId,
  (pipelinesList, useCaseId) => {
    if (!pipelinesList) {
      return []
    }

    if (!useCaseId) {
      return pipelinesList
    }

    return pipelinesList.filter((pipeline) => pipeline.useCaseId === useCaseId)
  },
)

export const getPipelineById = createSelector(
  getPipelinesList,
  (state: State, pipelineId: string) => pipelineId,
  (pipelinesList, pipelineId) => {
    if (!pipelinesList) {
      return null
    }

    if (!pipelineId) {
      return null
    }

    return pipelinesList.find((pipeline) => pipeline.pipelineId === pipelineId)
  },
)

export const isFetchingPipelines = (state: State) => {
  return state.pipelines.fetchingKeys.includes(REQUEST_PIPELINE_OVERVIEW) ||
         state.pipelines.fetchingKeys.includes(REQUEST_PIPELINES_LIST)
}

export const isSubmittingPipeline = (state: State) => {
  return state.pipelines.fetchingKeys.includes(UPDATE_PIPELINE_DELIVERY_SCHEDULE) ||
         state.pipelines.fetchingKeys.includes(DELETE_PIPELINE_DELIVERY_SCHEDULE) ||
         state.pipelines.fetchingKeys.includes(SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG) ||
         state.pipelines.fetchingKeys.includes(START_COMPOSITE_PIPELINE) ||
         state.pipelines.fetchingKeys.includes(STOP_COMPOSITE_PIPELINE) ||
         state.pipelines.fetchingKeys.includes(UPDATE_PIPELINE_EXECUTION_SCHEDULE) ||
         state.pipelines.fetchingKeys.includes(UPDATE_PIPELINE_CONFIGURATION) ||
         state.pipelines.fetchingKeys.includes(REPORT_PIPELINE_DELIVERY) ||
         state.pipelines.fetchingKeys.includes(CREATE_PIPELINE)
}
