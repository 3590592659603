import get from 'lodash.get'
import assignIn from 'lodash.assignin'
import find from 'lodash.find'
import map from 'lodash.map'

import {
  takeEvery, call, take,
  put, select, all,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { projectDetailsMapper, evaluationsMapper } from '@utils/new-api.utils'

import { getArtifactId, isOptimizeEnabled } from '@utils/use-cases.utils'
import { USE_CASE_ARTIFACTS_TYPES } from '@constants/use-cases.constants'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import { ActionPayload, State } from '@redux/modules/types'

import {
  CalculateTradesOffsDatasetActionPayload,
  FetchProjectActionPayload,
  FetchTradeOffsDataActionPayload,
  RequestTradeOffsByUseCaseActionPayload,
} from './socrates.types'

import {
  fetchAll as fetchAllProjects,
  fetch as fetchProject, track as trackProject,
  fetchProjectEvaluationsList, fetchEvaluationsDetails,
} from './socrates.api'

import {
  REQUEST_TRADE_OFFS_BY_USE_CASE,
  REQUEST_TRADE_OFFS_DATA,
  CHANGE_TRADE_OFFS_CONFIGURATION,
  CALCULATE_TRADE_OFFS_DATASET,
  SELECT_TRADE_OFFS_POINT,
  RESET_TRADE_OFFS_CONFIGURATION,
  REQUEST_SOCRATES_PROJECTS, REQUEST_SOCRATES_PROJECT,
} from './socrates.action-types'

import {
  calculateTradesOffsDatasetAction,
  receiveTradeOffsDataActionDone,
  fetchProjectAction,
  fetchTradeOffsDataAction,
  startFetchingAnalysisAction,
  stopFetchingAnalysisAction,
  changeTradeOffsConfigurationActionDone,
  calculateTradesOffsDatasetActionDone,
  resetTradeOffsConfigurationActionDone,
  selectTradeOffsPointActionDone,
  receiveProjectsActionDone,
  receiveProjectActionDone,
} from './socrates.actions'

import {
  getProjectParetoSolutions,
  getTradeOffsData,
  getProjectMeta,
  showSuboptimalSolutions,
} from './socrates.selectors'

function* fetchAllProjectsGenerator() {
  try {
    yield put(startFetchingAnalysisAction(REQUEST_SOCRATES_PROJECTS))

    const projects: string[] = yield call(fetchAllProjects)

    const detailedProjects: Socrates.ProjectItem[] = yield all(projects.map((projectId) => (
      call(fetchProject, { id: projectId })
    )))
    const projectsStatus: Socrates.ProjectProgress[] = yield all(projects.map((projectId) => (
      call(trackProject, { id: projectId })
    )))

    const mergedList = map(detailedProjects, (item) => {
      return assignIn(item, find(projectsStatus, { id: item.id }))
    })

    yield put(receiveProjectsActionDone(mergedList))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, {})
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(REQUEST_SOCRATES_PROJECTS))
  }
}

function* fetchProjectGenerator({ payload } : ActionPayload<FetchProjectActionPayload>) {
  try {
    yield put(startFetchingAnalysisAction(REQUEST_SOCRATES_PROJECT))

    const project: Socrates.ProjectItem = yield call(fetchProject, payload)
    const projectProgress: Socrates.ProjectProgress = yield call(trackProject, payload)

    yield put(receiveProjectActionDone({ ...projectProgress, ...project }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(REQUEST_SOCRATES_PROJECT))
  }
}

function* requestTradeOffsByUseCaseGenerator({ payload } : ActionPayload<RequestTradeOffsByUseCaseActionPayload>) {
  try {
    yield put(startFetchingAnalysisAction(REQUEST_TRADE_OFFS_BY_USE_CASE))

    yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId }))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const useCaseDetails: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const socratesId = getArtifactId(useCaseDetails, USE_CASE_ARTIFACTS_TYPES.TRADEOFFS_V1)

    yield put(fetchProjectAction({ id: socratesId }))
    yield put(fetchTradeOffsDataAction({ id: socratesId }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(REQUEST_TRADE_OFFS_BY_USE_CASE))
  }
}

function* fetchTradeOffsDataGenerator({ payload } : ActionPayload<FetchTradeOffsDataActionPayload>) {
  try {
    yield put(startFetchingAnalysisAction(REQUEST_TRADE_OFFS_DATA))

    const state: State = yield select()
    const useCaseDetails: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const shouldShowSuboptimalSolutions: boolean = yield call(showSuboptimalSolutions, state)
    const projectDetails: Socrates.ProjectItem = yield call(fetchProject, payload)
    const projectEvaluations: Socrates.EvaluationItem[] = yield call(fetchProjectEvaluationsList, { ...payload, onlyOptimal: !shouldShowSuboptimalSolutions }) || {}
    const evaluationsDetails: Socrates.EvaluationsDetails[] = yield call(fetchEvaluationsDetails, { evaluationsList: projectEvaluations.map((item) => item.evaluationId) }) || {}
    const optimizeEnabled = isOptimizeEnabled(useCaseDetails.useCaseId)

    yield put(receiveTradeOffsDataActionDone({
      paretoSolutions: evaluationsMapper(evaluationsDetails, projectEvaluations),
      projectMeta: projectDetailsMapper(projectDetails),
      optimizeEnabled,
    }))

    yield put(calculateTradesOffsDatasetAction({ initial: true }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(REQUEST_TRADE_OFFS_DATA))
  }
}

function* changeTradeOffsConfigurationGenerator({ payload } : ActionPayload<Socrates.TradeOffsConfiguration>) {
  try {
    yield put(startFetchingAnalysisAction(CHANGE_TRADE_OFFS_CONFIGURATION))

    yield put(changeTradeOffsConfigurationActionDone(payload))

    yield put(calculateTradesOffsDatasetAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(CHANGE_TRADE_OFFS_CONFIGURATION))
  }
}

function* calculateTradeOffsDatasetGenerator({ payload } : ActionPayload<CalculateTradesOffsDatasetActionPayload>) {
  try {
    yield put(startFetchingAnalysisAction(CALCULATE_TRADE_OFFS_DATASET))

    const state: State = yield select()
    const isInitial: boolean = get(payload, 'initial', false)
    const paretoSolutionFromState: Socrates.EvaluationsMapper[] = yield call(getProjectParetoSolutions, state)
    const projectMetaFromState: Socrates.ProjectMap = yield call(getProjectMeta, state)
    const tradeOffsDataFromState: Socrates.TradeOffsData = yield call(getTradeOffsData, state)

    yield put(calculateTradesOffsDatasetActionDone({
      projectMeta: projectMetaFromState,
      paretoSolutions: paretoSolutionFromState,
      tradeOffsData: tradeOffsDataFromState,
      initial: isInitial,
    }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(CALCULATE_TRADE_OFFS_DATASET))
  }
}

function* selectTradeOffsPointGenerator({ payload } : ActionPayload<Socrates.SelectedTradeOffsPoint>) {
  try {
    yield put(startFetchingAnalysisAction(SELECT_TRADE_OFFS_POINT))

    yield put(selectTradeOffsPointActionDone(payload))

    yield put(calculateTradesOffsDatasetAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(SELECT_TRADE_OFFS_POINT))
  }
}

function* resetTradeOffsConfigurationGenerator() {
  try {
    yield put(startFetchingAnalysisAction(RESET_TRADE_OFFS_CONFIGURATION))

    yield put(resetTradeOffsConfigurationActionDone())

    yield put(calculateTradesOffsDatasetAction({}))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, {})
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalysisAction(RESET_TRADE_OFFS_CONFIGURATION))
  }
}

export function* watchFetchAllProjects() {
  yield takeEvery(REQUEST_SOCRATES_PROJECTS, fetchAllProjectsGenerator)
}

export function* watchFetchProject() {
  yield takeEvery(REQUEST_SOCRATES_PROJECT, fetchProjectGenerator)
}

export function* watchRequestTradeOffsByUseCase() {
  yield takeEvery(REQUEST_TRADE_OFFS_BY_USE_CASE, requestTradeOffsByUseCaseGenerator)
}

export function* watchTradeOffsData() {
  yield takeEvery(REQUEST_TRADE_OFFS_DATA, fetchTradeOffsDataGenerator)
}

export function* watchChangeTradeOffsConfiguration() {
  yield takeEvery(CHANGE_TRADE_OFFS_CONFIGURATION, changeTradeOffsConfigurationGenerator)
}

export function* watchCalculateTradeOffsDataset() {
  yield takeEvery(CALCULATE_TRADE_OFFS_DATASET, calculateTradeOffsDatasetGenerator)
}

export function* watchSelectTradeOffsPoint() {
  yield takeEvery(SELECT_TRADE_OFFS_POINT, selectTradeOffsPointGenerator)
}

export function* watchResetTradeOffsConfiguration() {
  yield takeEvery(RESET_TRADE_OFFS_CONFIGURATION, resetTradeOffsConfigurationGenerator)
}
