import React from 'react'

export interface ArrowDownIconProps extends Common.IconProps {
  opacity?: string,
}

const ArrowDownIcon: React.FC<ArrowDownIconProps> = ({
  width = 11,
  height = 11,
  viewBox = '0 0 11 11',
  fill = 'none',
  opacity = '0.2',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ArrowDownIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path opacity={opacity} d="M5.13 7.886L1.764 4.52L0.828 5.456L5.796 10.424L10.764 5.456L9.828 4.52L6.462 7.886V0.559999H5.13V7.886Z" fill={detailsFill} />

    </svg>
  )
}

export default ArrowDownIcon
