import { createAction } from 'redux-actions'

import {
  REQUEST_ARTIFACTS_LIST,
  RECEIVE_ARTIFACTS_LIST,
  UPLOAD_ARTIFACT,
  REMOVE_ARTIFACT,
  DOWNLOAD_ARTIFACT,
  START_FETCHING_ARTIFACTS,
  STOP_FETCHING_ARTIFACTS,
} from './artifacts.action-types'

import {
  DownloadArtifactActionPayload,
  RemoveArtifactActionPayload,
  RequestArtifactListActionPayload,
  UploadArtifactActionPayload,
} from './artifacts.types'

export const requestArtifactListAction = createAction<RequestArtifactListActionPayload>(REQUEST_ARTIFACTS_LIST)
export const receiveArtifactList = createAction(RECEIVE_ARTIFACTS_LIST)
export const downloadArtifactAction = createAction<DownloadArtifactActionPayload>(DOWNLOAD_ARTIFACT)
export const uploadArtifactAction = createAction<UploadArtifactActionPayload>(UPLOAD_ARTIFACT)
export const removeArtifactAction = createAction<RemoveArtifactActionPayload>(REMOVE_ARTIFACT)

export const startFetchingArtifactsAction = createAction<string>(START_FETCHING_ARTIFACTS)
export const stopFetchingArtifactsAction = createAction<string>(STOP_FETCHING_ARTIFACTS)
