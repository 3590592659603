import React from 'react'
import { IntlShape } from 'react-intl'

import {
  CartesianGridProps,
  LabelProps, LineProps, TextProps,
  TooltipProps, AreaProps,
  XAxisProps, YAxisProps,
} from 'recharts'

import palette from '@configuration/theme/theme.palette'

import {
  DEFAULT_CHARTS_FONT_FAMILY,
  DEFAULT_CHARTS_FONT_WEIGHT,
  DEFAULT_AXIS_STROKE,
  DEFAULT_AXIS_TICKS_STROKE,
  CHARTS_FONT_SIZE,
  DEFAULT_CHARTS_FONT_COLOR,
  DEFAULT_RECHARTS_ACTIVE_DOT,
  DEFAULT_RECHARTS_DOT,
  DEFAULT_RECHARTS_LINE_WIDTH,
  DEFAULT_RECHARTS_CURVE,
  DEFAULT_BG_COLOR,
  DEFAULT_CARTESIAN_GRID_STROKE,
  DEFAULT_RECHARTS_CURSOR,
} from '@constants/analysis.constants'

export const UNITS_TO_HIDE = ['num', 'number', ' ']

export enum SVG_GRADIENT_COLORWAY {
  GRADIENT_CELL_PARETOS = 'gradient_cell_paretos',
  GRADIENT_CELL_IST = 'gradient_cell_ist',
  GRADIENT_CELL_BLACK = 'gradient_cell_black',
  GRADIENT_CELL_DARK_GREY = 'gradient_cell_dark_grey',
  GRADIENT_CELL_LIGHT_GREY = 'gradient_cell_light_grey',
  GRADIENT_CELL_PREDICT = 'gradient_cell_predict',
  GRADIENT_CELL_GREEN = 'gradient_cell_green',
  GRADIENT_CELL_YELLOW = 'gradient_cell_yellow',
  GRADIENT_CELL_RED = 'gradient_cell_red',
  GRADIENT_CELL_LIGHT_YELLOW = 'gradient_cell_light_yellow',
}

export const SVG_GRADIENT_TO_CSS_COLORWAY_MAP = {
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PARETOS]: 'linear-gradient(359.1deg, #F500E1 0%, #FBB03B 100%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_IST]: 'linear-gradient(179.95deg, #DDDAEB 0%, #AAA3CC 100%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PREDICT]: 'linear-gradient(359.99deg, #5F26E0 0.01%, #9F7DED 100%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_GREEN]: 'linear-gradient(259.87deg, #99E6B8 0.94%, #00C04D 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_YELLOW]: 'linear-gradient(259.87deg, #FDA792 0.94%, #FB6C4A 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_RED]: 'linear-gradient(259.87deg, #FDA792 0.94%, #FB6C4A 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_YELLOW]: 'linear-gradient(259.87deg, #FDD089 0.94%, #FBB03B 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_BLACK]: 'linear-gradient(260deg, #666 0.94%, #000 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_DARK_GREY]: 'linear-gradient(259.87deg, #AAA3CC 0.94%, #666666 97.62%)',
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_GREY]: 'linear-gradient(259.87deg, rgba(221, 218, 235, 0.5) 0.94%, rgba(170, 163, 204, 0.5) 97.62%)',
}

export const SVG_GRADIENT_TO_HEX_MAP = {
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PARETOS]: ['#F500E1', '#FBB03B'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_IST]: ['#DDDAEB', '#AAA3CC'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PREDICT]: ['#5F26E0', '#9F7DED'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_GREEN]: ['#99E6B8', '#00C04D'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_YELLOW]: ['#FDA792', '#FB6C4A'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_RED]: ['#FDA792', '#FB6C4A'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_YELLOW]: ['#FDD089', '#FBB03B'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_BLACK]: ['#666666', '#666666'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_DARK_GREY]: ['#AAA3CC', '#666666'],
  [SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_GREY]: ['rgba(221, 218, 235, 0.5)', 'rgba(170, 163, 204, 0.5)'],
}

export interface ChartConfig {
  showAllXTicks?: boolean
  rotateXLabels?: boolean
  maxXLabelsHeight?: number
  barChartStackOffset?: RecommendationArtifacts.VisualisationBarChartStackOffset
  barChartBarSizes?: RecommendationArtifacts.VisualisationBarChartBarSizes
}

/**
 * @function getLineProps  Returns default pros for rechart's Line.
 *                         Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getLineProps = (key: string, color: string, dot = false) => {
  return {
    type: DEFAULT_RECHARTS_CURVE,
    dataKey: key,
    stroke: color,
    strokeWidth: DEFAULT_RECHARTS_LINE_WIDTH,
    dot: dot ? DEFAULT_RECHARTS_DOT : dot,
    activeDot: DEFAULT_RECHARTS_ACTIVE_DOT,
  } as Omit<LineProps, 'points' | 'pathRef' | 'ref'>
}

/**
 * @function getAreaProps  Returns default pros for rechart's Area.
 *                         Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getAreaProps = (key: string, color: string, dot = false) => {
  return {
    type: DEFAULT_RECHARTS_CURVE,
    dataKey: key,
    stroke: color,
    strokeWidth: DEFAULT_RECHARTS_LINE_WIDTH,
    dot: dot ? DEFAULT_RECHARTS_DOT : dot,
    activeDot: DEFAULT_RECHARTS_ACTIVE_DOT,
  } as Omit<AreaProps, 'points' | 'pathRef' | 'ref'>
}

/**
 * @function getCartesianGridProps  Returns default pros for rechart's CartesianGrid.
 *                                  Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getCartesianGridProps = (horizontal = true, vertical = false) => {
  return {
    horizontal,
    vertical,
    fill: DEFAULT_BG_COLOR,
    stroke: DEFAULT_CARTESIAN_GRID_STROKE,
  } as Omit<CartesianGridProps, 'ref'>
}

/**
 * @function getTooltipProps  Returns default pros for rechart's CartesianGrid.
 *                            Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getTooltipProps = (
  filterNull = true,
  allowEscapeViewBox: {
    x?: boolean | undefined;
    y?: boolean | undefined;
  } | undefined = { x: true, y: true },
) => {
  return {
    wrapperStyle: { outline: 'none' },
    allowEscapeViewBox,
    cursor: DEFAULT_RECHARTS_CURSOR,
    filterNull,
  } as Omit<TooltipProps<any, any>, 'ref'>
}

/**
 * Returns default pros for rechart's container
 *
 * @param params
 *
 * @returns {Object} props
 */
export const getContainerProps = ({
  rotateXLabels = false,
}: ChartConfig = {}) => {
  return {
    width: '100%',
    height: '100%',
    className: rotateXLabels ? 'rotated' : '',
  }
}

/**
 * Returns default pros for rechart's container
 *
 * @param params
 *
 * @returns {Object} props
 */
export const getChartWrapperProps = ({
  rotateXLabels = false,
  maxXLabelsHeight = 30,
  barChartStackOffset,
}: ChartConfig = {}) => {
  return {
    stackOffset: barChartStackOffset,
    margin: {
      top: 0,
      right: 5,
      left: 40,
      bottom: rotateXLabels ? maxXLabelsHeight : 20,
    },
  }
}

/**
 * @function getXAxisProps Returns default pros for rechart's XAxis.
 *                         Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getXAxisProps = ({
  showAllXTicks = false,
  rotateXLabels = false,
}: ChartConfig = {}) => {
  return {
    minTickGap: 40,
    tickMargin: 10,
    fontWeight: DEFAULT_CHARTS_FONT_WEIGHT,
    fontSize: CHARTS_FONT_SIZE,
    fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
    stroke: DEFAULT_AXIS_STROKE,
    tick: {
      fill: DEFAULT_AXIS_TICKS_STROKE,
      ...(rotateXLabels ? {
      } : {}),
    },
    tickLine: true,
    padding: { left: 0, right: 100 },
    interval: showAllXTicks ? 0 : 'preserveEnd',
    angle: rotateXLabels ? -90 : 0,
  } as XAxisProps
}

/**
 * @function getYAxisProps Returns default pros for rechart's YAxis.
 *                         Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getYAxisProps = () => {
  return {
    tickMargin: 5,
    interval: 0,
    minTickGap: 0,
    tickLine: false,
    fontWeight: DEFAULT_CHARTS_FONT_WEIGHT,
    fontSize: CHARTS_FONT_SIZE,
    fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
    stroke: DEFAULT_AXIS_STROKE,
    tick: { fill: DEFAULT_AXIS_TICKS_STROKE },
    padding: { top: 80 },
    textAnchor: 'end',
    allowDecimals: false,
  } as YAxisProps
}

/**
 * @function getReferenceLabelProps Returns default pros for rechart's Label for reference label.
 *                                  Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getReferenceLabelProps = (props?: {
  position?: string,
  offset?: number,
}) => {
  const {
    position = 'insideTopRight',
    offset = 2,
  } = props || {}

  return {
    offset,
    position,
    fontWeight: DEFAULT_CHARTS_FONT_WEIGHT,
    fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
    fontSize: CHARTS_FONT_SIZE,
    fill: DEFAULT_CHARTS_FONT_COLOR,
  } as LabelProps
}

/**
 * @function getXLabelProps Returns default pros for rechart's Label for X axis.
 *                          Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getXLabelProps = () => {
  return {
    offset: 15,
    position: 'insideTopRight',
    fontWeight: DEFAULT_CHARTS_FONT_WEIGHT,
    fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
    fontSize: CHARTS_FONT_SIZE,
    fill: DEFAULT_CHARTS_FONT_COLOR,
    style: { transform: 'translate(0px, 0px)' },
  } as LabelProps
}

/**
 * @function getYTextProps Returns default pros for rechart's Text for Y axis.
 *                         Please note, recharts do not support components wrapping!
 *
 * @return {Object} props
*/
export const getYTextProps = (width = 80) => {
  return {
    width,
    fontWeight: DEFAULT_CHARTS_FONT_WEIGHT,
    fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
    fontSize: CHARTS_FONT_SIZE,
    fill: DEFAULT_CHARTS_FONT_COLOR,
    style: { transform: 'translate(90px, 40px)' },
    textAnchor: 'end',
    verticalAnchor: 'start',
  } as Omit<TextProps, 'ref'>
}

/**
 * @function getArrowHead Returns defenition for chart arrow
 *
 * @param {boolean} x - if true, arrow will be shown on X axis
 * @param {boolean} y - if true, arrow will be shown on Y axis
 *
 * @return {React.ReactNode} defenition for chart arrow
*/
export const getArrowHead = (x = true, y = true) => {
  return (
    <defs>
      {
        x ? (
          <marker
            id='arrowhead'
            markerUnits='strokeWidth'
            markerWidth='5'
            markerHeight='8'
            viewBox='0 0 5 8'
            refX='4'
            refY='4'
            orient='auto'
            stroke={palette.new.business_black_20}
            // eslint-disable-next-line
            fill='none'
          >
            <path d='M1 1L4 4L1 7' />
          </marker>
        ) : (
          null
        )
      }
      {
        y ? (
          <marker
            id='arrowheadtop'
            markerUnits='strokeWidth'
            markerWidth='5'
            markerHeight='8'
            viewBox='0 0 5 8'
            refX='4'
            refY='4'
            orient='-90deg'
            stroke={palette.new.business_black_20}
            // eslint-disable-next-line
            fill='none'
          >
            <path d='M1 1L4 4L1 7' />
          </marker>
        ) : (
          null
        )
      }
    </defs>
  )
}

/**
 * @function getTooltipFormattedAxis Formats axis
 *
 * @param {String} name axis name
 * @param {String} unit unit
 *
 * @return {String} formatted axis
*/
export const getTooltipFormattedAxis = (intl: IntlShape, name?: string, messageId?: string, unit?: string) => {
  const shouldShowUnit = unit && !UNITS_TO_HIDE.includes(unit.toLowerCase())
  const messageToRender = messageId ? intl.formatMessage({ id: messageId }, {
    name,
    unit,
  }) : `${name} ${unit}`

  return shouldShowUnit ? messageToRender : name
}

export const getGradientsSet = () => {
  return (
    <defs>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PARETOS} x1='50%' y1='100%' x2='50%' y2='0%'>
        <stop stopColor='#F500E1' />
        <stop offset='.001' stopColor='#F500E1' />
        <stop offset='1' stopColor='#FBB03B' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_IST} x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='.009' stopColor='#DDDAEB' />
        <stop offset='.976' stopColor='#AAA3CC' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_BLACK} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop offset='.009' stopColor='#666666' />
        <stop offset='.976' stopColor='#000000' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_DARK_GREY} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop stopColor='#AAA3CC' />
        <stop offset='1' stopColor='#666666' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_GREY} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop stopColor='rgba(221, 218, 235, 0.5)' />
        <stop offset='1' stopColor='rgba(170, 163, 204, 0.5)' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PREDICT} x1='50%' y1='100%' x2='50%' y2='0%'>
        <stop stopColor='#5F26E0' />
        <stop offset='1' stopColor='#9F7DED' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_GREEN} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop offset='.009' stopColor='#99E6B8' />
        <stop offset='.976' stopColor='#00C04D' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_YELLOW} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop offset='.009' stopColor='#FDA792' />
        <stop offset='.976' stopColor='#FB6C4A' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_RED} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop offset='.009' stopColor='#FDA792' />
        <stop offset='.976' stopColor='#FB6C4A' />
      </linearGradient>
      <linearGradient id={SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_YELLOW} x1='107.11%' y1='39.8%' x2='-7.11%' y2='60.2%'>
        <stop offset='.009' stopColor='#FDD089' />
        <stop offset='.976' stopColor='#FBB03B' />
      </linearGradient>
    </defs>
  )
}

/**
 * Get chart item color
 *
 * @param {Number} index item index
 * @param {String} color color
 * @param {Boolean} cssGradient should return css gradient
 *
 * @returns {String} processed color
 */
export const getChartItemColor = (color: string, type: RecommendationArtifacts.VisualisationItemTypes, cssGradient = false) => {
  if (color.includes('gradient')) {
    switch (color) {
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PARETOS:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_IST:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_PREDICT:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_GREEN:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_YELLOW:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_BLACK:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_DARK_GREY:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_GREY:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_RED:
      case SVG_GRADIENT_COLORWAY.GRADIENT_CELL_LIGHT_YELLOW:
        if (type === 'line') {
          return SVG_GRADIENT_TO_HEX_MAP[color][0]
        }

        if (cssGradient) {
          return SVG_GRADIENT_TO_CSS_COLORWAY_MAP[color]
        }

        return `url(#${color})`
      default:
        break
    }
  }

  return color
}
