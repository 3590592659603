import React, { useMemo } from 'react'
import { CheckboxProps, Checkbox } from '@mui/material'

import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { useRouteMatch } from 'react-router-dom'
import { CHECKBOX_BASE_ICONS_STYLES, CHECKBOX_BASE_ICONS_HOVER_STYLES } from '@constants/ui.constants'

import IndeterminateSquareIcon from '@icons/checkboxes/indeterminateSquare.icon'
import CheckSquareIcon from '@icons/checkboxes/checkSquare.icon'
import CheckedSquareIcon from '@icons/checkboxes/checkedSquare.icon'

export interface CheckboxBaseComponentProps extends CheckboxProps {
  /**
   * Name of the radio
   */
  name: string
  /**
   * if true, the radio will be checked
   */
  checked?: boolean
  /**
   * if true, the radio will be disabled
   */
  disabled?: boolean
  /**
   * If true, the radio will be indeterminate
   */
  indeterminate?: boolean
  /**
   * Ref to be forwarded to the Checkbox component
   */
  forwardedRef?: any
  /**
   * Icon props to be passed to the icons
   */
  iconProps?: Common.IconProps
}

export const CheckboxBaseComponent: React.FC<CheckboxBaseComponentProps> = ({
  forwardedRef,
  sx,
  name,
  disabled,
  checked,
  indeterminate,
  iconProps,
  onChange,
  ...props
}) => {
  const finalIconProps = useMemo(() => {
    return disabled ? {
      width: '18px',
      height: '18px',
      ...iconProps,
      detailsFill: '#CCC',
    } : {
      width: '18px',
      height: '18px',
      ...iconProps,
    }
  }, [disabled, iconProps])

  const { params } = useRouteMatch<Common.RouterMatch>()

  const onChangeHandler = (event: React.ChangeEvent<any>, checkedValue: boolean) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      checked: checkedValue,
      router: params,
    })

    if (onChange) {
      onChange(event, checkedValue)
    }
  }

  const styles = useMemo(() => {
    return {
      borderRadius: '8px',
      padding: '0px',
      pointerEvents: disabled ? 'auto !important' : 'auto',

      ...CHECKBOX_BASE_ICONS_STYLES,
      '&:hover:not(.Mui-disabled)': {
        ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
      },
      '&:focus-within': {
        ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
      },
    }
  }, [
    disabled,
  ])

  return (
    <Checkbox
      data-testid={name || CheckboxBaseComponent.name}
      ref={forwardedRef}
      checked={checked}
      indeterminate={indeterminate}
      icon={<CheckSquareIcon {...finalIconProps} />}
      checkedIcon={<CheckedSquareIcon {...finalIconProps} />}
      indeterminateIcon={<IndeterminateSquareIcon {...finalIconProps} />}
      disableRipple={true}
      name={name}
      sx={styles}
      onChange={onChangeHandler}
      disabled={disabled}
      {...props}
    />
  )
}

export default React.forwardRef((props: CheckboxBaseComponentProps, ref) => <CheckboxBaseComponent {...props} forwardedRef={ref} />)
