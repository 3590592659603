import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'
import { DATA_UPLOAD_PATH } from '@constants/routes.constants'
import { Box, Typography, useTheme } from '@mui/material'

import WarningIcon from '@icons/flow/warning.icon'
import { formatDate } from '@utils/moment.utils'

export interface TrainingModalWarningBlockComponentProps {
  /**
   * if true - the data is being fetched
   */
  isFetching?: boolean
  /**
   * if true - it is legacy upload folder
   */
  isLegacyUpload?: boolean
  /**
   * Latest version details
   */
  latestVersionDetails: TrainingFiles.FileIdentifierVersionItem
}

const TrainingModalWarningBlockComponent: React.FC<TrainingModalWarningBlockComponentProps> = ({
  isFetching,
  isLegacyUpload,
  latestVersionDetails,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const theme = useTheme()

  const {
    params: {
      usecase,
      version,
      identifier,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const latestVersion = String(latestVersionDetails.versionNumber)

  const handleLinkClick = () => {
    history.push(generatePath(DATA_UPLOAD_PATH, { usecase, identifier: identifier!, version: latestVersion }))
  }

  const isLatestVersionSelected = (latestVersion === version)

  if (isLegacyUpload || isLatestVersionSelected || !version || isFetching) {
    return null
  }

  return (
    <Box
      data-testid={TrainingModalWarningBlockComponent.name}
      onClick={handleLinkClick}
      sx={{
        p: 1.5,
        mt: -2,
        pt: 3.5,
        border: '1px solid',
        borderColor: theme.palette.new.youthful_yellow,
        backgroundColor: '#FBB03B1A',
        borderRadius: '10px',
        position: 'relative',
        height: '64px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: theme.spacing(1),
        }}
      >
        <WarningIcon
          detailsFill={theme.palette.new.youthful_yellow}
        />

        <Typography variant='h6Alt' color={theme.palette.new.youthful_yellow}>
          {
            intl.formatMessage({ id: 'connect.data_input.modal.dropzone.warning' }, {
              details: (
                <Box component='strong'>
                  {
                    intl.formatMessage({ id: 'connect.data_input.modal.dropzone.details' }, {
                      version: latestVersion,
                      date: formatDate(intl, latestVersionDetails.createdAt),
                    })
                  }
                </Box>
              ),
            })
          }
        </Typography>
      </Box>
    </Box>
  )
}

export default TrainingModalWarningBlockComponent
