import get from 'lodash.get'
import set from 'lodash.set'
import { IntlShape } from 'react-intl'

import {
  getSourceFieldSchemas,
  isOAuthDone,
  checkifOAuthRequired,
} from '@utils/connectors.utils'

const validateNewConnectorModal = (values: Hermes.ConnectionFormItem, props: any, intl: IntlShape, sources: Hermes.SourceDetails[]) => {
  const fieldSchemas = getSourceFieldSchemas(values.sourceId, sources) as Hermes.ConfigurationFormFields
  const selectedSource = sources.find((e) => e.sourceId === values.sourceId) || {} as Hermes.SourceDetails
  const isOAuthRequired = checkifOAuthRequired(selectedSource)
  const isAuthDone = isOAuthDone(values)
  const errors = {} as any

  if (!values.name) {
    errors.name = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  if (!isAuthDone && isOAuthRequired) {
    errors['configuration.credentials.refreshToken'] = intl.formatMessage({ id: 'connectors.modal.form.required' })
  }

  Object.entries(fieldSchemas).forEach(([path, data]) => {
    // TODO: dynamic validations provided by backend
    const value = get(values, `configuration.${path}`)
    if (!value && (data.required ?? true)) {
      const required = intl.formatMessage({ id: 'connectors.modal.form.required' })
      set(errors, `configuration.${path}`, required)
    }
  })

  return errors
}

export default validateNewConnectorModal
