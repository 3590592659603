import React from 'react'

import palette from '@configuration/theme/theme.palette'

const ConnectIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = palette.new.black,
}) => {
  return (
    <svg data-testid={ConnectIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path fillOpacity="0" d="M14.2148 16.5L16.4648 13.9286L14.2148 11.3572" stroke={detailsFill} />
      <path strokeOpacity="0" d="M16.1437 14.4285L16.6437 14.4285L16.6437 13.4285L16.1437 13.4285L16.1437 14.4285ZM4.57227 3.78564L4.07227 3.78564L4.07227 4.78564L4.57227 4.78564L4.57227 3.78564ZM9.07227 6.28564L8.57227 6.28564L9.07227 6.28564ZM9.07227 11.9285L8.57227 11.9285L9.07227 11.9285ZM11.0723 14.4285L16.1437 14.4285L16.1437 13.4285L11.0723 13.4285L11.0723 14.4285ZM7.07227 3.78564L4.57227 3.78564L4.57227 4.78564L7.07227 4.78564L7.07227 3.78564ZM8.57227 6.28564L8.57227 11.9285L9.57227 11.9285L9.57227 6.28564L8.57227 6.28564ZM7.07227 4.78564C7.90069 4.78564 8.57227 5.45722 8.57227 6.28564L9.57227 6.28564C9.57227 4.90493 8.45298 3.78564 7.07227 3.78564L7.07227 4.78564ZM11.0723 13.4285C10.2438 13.4285 9.57227 12.7569 9.57227 11.9285L8.57227 11.9285C8.57227 13.3092 9.69155 14.4285 11.0723 14.4285L11.0723 13.4285Z" fill={detailsFill} />
      <circle fillOpacity="0" cx="3.28571" cy="4.28571" r="1.28571" fill="white" stroke={detailsFill} />
    </svg>
  )
}

export default ConnectIcon
