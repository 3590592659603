import Papa, { ParseResult } from 'papaparse'
import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { v4 } from 'uuid'
import { GridColDef } from '@mui/x-data-grid-premium'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import FileBrowserPreviewTableComponent from '@components/file-browser/FileBrowserPreviewTable'
import FileBrowserEditablePreviewTableComponent from '@components/file-browser/FileBrowserEditablePreviewTable'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

import { generateColumnsDefinitionsFromRow } from '@utils/files.utils'
import { EMPTY_CSV_VALUE, FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX } from '@constants/files.constants'

export interface FileBrowserPreviewCsvComponentProps {
  /**
   * File preview data
   */
  filePreview: FileService.FilePreview
  /**
   * If true, the preview is editable
   */
  isEditable?: boolean
}

const FileBrowserPreviewCsvComponent: React.FC<FileBrowserPreviewCsvComponentProps> = ({
  filePreview, isEditable,
}) => {
  const intl = useIntl()
  const [data, setData] = useState<any[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [meta, setMeta] = useState<Papa.ParseMeta | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (data.length && columns.length) {
      setLoading(false)
    }
  }, [data, columns])

  const handleDataChange = useCallback((results: ParseResult<any>) => {
    const rows = results.data.map((row, index) => {
      const rowWithreplacedNulls = Object.entries(row).reduce((acc, [key, value]) => {
        if (value === null || value === undefined) {
          acc[key] = EMPTY_CSV_VALUE
        } else {
          acc[key] = value
        }

        return acc
      }, {} as any)

      return {
        id: v4(),
        ...rowWithreplacedNulls,
      }
    })

    setData(rows)

    setMeta(results.meta)

    setColumns(generateColumnsDefinitionsFromRow(intl, results.meta.fields, isEditable))
  }, [intl, isEditable])

  const handleTransformHeader = useCallback((header: string, index: number) => {
    /* Replace badly endcoded symbols '\uFFFD' to avoid datagrid crash */
    if (header && header.includes('\uFFFD')) {
      return encodeURI(header)
    }

    return header || `${FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX}${index + 1}`
  }, [])

  useEffect(() => {
    if (!filePreview) {
      return
    }

    const jsonBlob = new Blob([filePreview.file], { type: filePreview.contentType })

    const reader = new FileReader()

    reader.onload = () => {
      Papa.parse(reader.result as string, {
        header: true,
        worker: false,
        skipEmptyLines: true,
        dynamicTyping: false,
        complete: handleDataChange,
        transformHeader: handleTransformHeader,
      })
    }

    reader.readAsText(jsonBlob)
  }, [filePreview, handleDataChange, handleTransformHeader])

  const content = useMemo(() => {
    if (!filePreview) {
      return null
    }

    if (loading) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          height='100%'
          position='relative'
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            width='100%'
            height='100%'
            position='absolute'
            zIndex={10}
          >
            <Typography
              variant='h4'
              mt={0}
              color='white'
            >
              {
                intl.formatMessage({
                  id: 'fileManager.preview.dialog.loading.header',
                })
              }
            </Typography>
            <Typography
              variant='h5'
              mt={2}
              color='white'
            >
              {
                intl.formatMessage({
                  id: 'fileManager.preview.dialog.loading.text',
                })
              }
            </Typography>
          </Box>

          <LoadingFallbackComponent
            fluid={true}
            showLongRequestNote={false}
            size='large'
          />
        </Box>
      )
    }

    if (isEditable) {
      return (
        <FileBrowserEditablePreviewTableComponent
          filePreview={filePreview}
          columns={columns}
          data={data}
          meta={meta}
        />
      )
    }

    return (
      <FileBrowserPreviewTableComponent
        columns={columns}
        data={data}
      />
    )
  }, [
    filePreview,
    loading,
    intl,
    isEditable,
    columns,
    data,
    meta,
  ])

  if (!filePreview) {
    return null
  }

  return (
    <Box
      data-testid={FileBrowserPreviewCsvComponent.name}
      display='flex'
      alignItems='flex-start'
      justifyContent='flex-start'
      width='100%'
      height='100%'
    >
      {content}
    </Box>
  )
}

export default FileBrowserPreviewCsvComponent
