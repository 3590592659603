import React from 'react'
import { IntlShape, useIntl } from 'react-intl'

import {
  Box,
  Typography,
} from '@mui/material'

import { defaultNumberFormatter } from '@utils/analysis.utils'

import type { KpiTileComponentProps } from './KpiTile.component'

export const valueFormatter = ({
  intl,
  type,
  value,
  formattingOptions,
  showArrows = false,
} : {
  intl: IntlShape,
  type?: 'number' | 'float' | 'string',
  value?: string | number,
  formattingOptions?: Intl.NumberFormatOptions,
  showArrows?: boolean,
}) => {
  if (((type === 'number') || (type === 'float')) && typeof value === 'number') {
    const formattedValue = defaultNumberFormatter((showArrows ? Math.abs(value) : value), {
      float: type === 'float',
      numberFormatOptions: formattingOptions,
      intl,
    })

    if (!showArrows || value === 0) {
      return formattedValue
    }

    return value > 0 ? `↑${formattedValue}` : `↓${formattedValue}`
  }

  return value
}

export interface KpiTileValuesBlockProps extends Pick<KpiTileComponentProps,
  'value' | 'valueType' | 'secondaryValue' | 'secondaryValueType' | 'showDeltaArrows' |
  'valueFormattingOptions' | 'secondaryValueFormattingOptions' | 'unit' | 'loading'
> {
  color?: string
  variant?: 'h5' | 'body2'
}

export const KpiTileValuesComponent: React.FC<KpiTileValuesBlockProps> = ({
  value,
  valueType,
  valueFormattingOptions,
  secondaryValue,
  secondaryValueType,
  secondaryValueFormattingOptions,
  showDeltaArrows,
  color,
  unit,
  loading,
  variant = 'h5',
}) => {
  const intl = useIntl()
  const loadingStyles = loading ? {
    filter: 'blur(5px)',
  } : {}

  const formattedValue = valueFormatter({
    intl,
    value,
    type: valueType,
    formattingOptions: valueFormattingOptions,
  })

  const formattedSecondaryValue = valueFormatter({
    intl,
    value: secondaryValue,
    type: secondaryValueType,
    showArrows: showDeltaArrows,
    formattingOptions: secondaryValueFormattingOptions,
  })

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='flex-end'
      gap='5px'
      sx={loadingStyles}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap='10px'
        width='100%'
      >
        <Typography
          variant={variant}
          color={color}
          noWrap={true}
          title={String(formattedValue)}
        >
          {formattedValue}
        </Typography>

        <Typography
          variant={variant}
          sx={{
            transition: 'opacity 0.3s',
            opacity: secondaryValue !== undefined ? 0.4 : 0,
          }}
          color={color}
          title={String(formattedSecondaryValue)}
        >
          {formattedSecondaryValue}
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
      >
        {
          unit ? (
            <Typography
              variant='overline'
              color={color}
              sx={{ opacity: 0.4 }}
            >
              {unit}
            </Typography>
          ) : (
            null
          )
        }
      </Box>
    </Box>
  )
}

export default KpiTileValuesComponent
