import throttle from 'lodash.throttle'
import { checkApiForError } from '@utils/api.utils'
import { USE_CASE } from '@constants/api-provider.constants'
import { EMITTER_TYPES, DEFAULT_THROTTLE_TIMEOUT } from '@constants/emitter.constants'
import eventEmitter from '@utils/emitter.utils'
import apiProvider from '@redux/api-provider'

import {
  UploadArtifactPayload,
  DownloadArtifactsPayload,
  ListArtifactsPayload,
  ListArtifactsWithIdPayload,
  RemoveArtifactsPayload,
} from './artifacts.types'

export const getArtifacts = (payload = {}) => {
  const URL = 'v6/artifact/get'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.artifacts
  })
}

export const listArtifacts = (payload: ListArtifactsPayload = {} as ListArtifactsPayload) => {
  const URL = 'v6/artifact/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.artifactIds as string[]
  })
}

export const listArtifactsWithId = (payload: ListArtifactsWithIdPayload = {} as ListArtifactsWithIdPayload) => {
  const URL = 'v6/artifact/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return {
      artifactIds: data.data.artifactIds,
      useCaseId: payload.useCaseId,
    }
  })
}

export const getArtifactDownloadToken = ({
  useCaseId,
  artifactId,
} : DownloadArtifactsPayload) => {
  const URL = 'v6/artifact/download-token/get'
  const payload = {
    artifactId,
    useCaseId,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.downloadToken
  })
}

export const uploadArtifact = (payload: UploadArtifactPayload = {} as UploadArtifactPayload) => {
  const URL = 'v6/artifact/upload'

  return apiProvider.getApi(USE_CASE).post(URL, payload.data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
    onUploadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
        {
          name: payload.meta.fileName,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then(({ data }) => {
    checkApiForError(data)

    const filesDetais = data.data || {}

    return filesDetais
  })
}

export const removeArtifact = ({
  useCaseId,
  artifactId,
} : RemoveArtifactsPayload) => {
  const URL = 'v6/artifact/delete'
  const payload = {
    artifactId,
    useCaseId,
  }

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}
