import React from 'react'

export interface ArrowUpIconProps extends Common.IconProps {
  opacity?: string,
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({
  width = 11,
  height = 11,
  viewBox = '0 0 11 11',
  fill = 'none',
  opacity = '0.2',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ArrowUpIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path opacity={opacity} d="M6.462 10.424V3.098L9.828 6.464L10.764 5.528L5.796 0.559999L0.828 5.528L1.764 6.464L5.13 3.098V10.424H6.462Z" fill={detailsFill} />

    </svg>
  )
}

export default ArrowUpIcon
