import { DEFAULT_BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { dateRangePickerDayClasses } from '@mui/x-date-pickers-pro'
import { PickersProComponents } from '@mui/x-date-pickers-pro/themeAugmentation'
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'

const borderColor = '#CCCCCC'
const borderRadius = '5px'
const weekContainerMargin = '5px'

export const defaultDatePickersConfigs: PickersProComponents<Theme> & PickerComponents<Theme> = {
  MuiPickersDay: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-selected': {
          backgroundColor: theme.palette.new.versatile_violet,

          '&:hover': {
            backgroundColor: theme.palette.new.versatile_violet,
          },

          '&:focus': {
            backgroundColor: 'rgb(204 204 204 / 20%)',
            color: theme.palette.new.black,
            borderColor,
          },
        },
      }),
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        marginTop: '0px',
        paddingLeft: '30px',
        paddingRight: '20px',
      },
      labelContainer: {
        cursor: 'default',
      },
    },
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      button: ({ theme }) => ({
        borderRadius: '5px',

        '&.Mui-disabled': {
          '& svg > path': {
            stroke: theme.palette.new.business_black_20,
          },
        },
      }),
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      root: {
        marginTop: '2px !important',
      },
      paper: ({ theme }) => ({
        borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
        outline: `1px solid ${theme.palette.new.business_black_20}`,
        boxShadow: DEFAULT_BOX_SHADOW,
      }),
    },
  },
  MuiPickersLayout: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: '20px',
        paddingBottom: '15px',

        '& .MuiPickersLayout-actionBar': {
          borderTop: `1px solid ${borderColor}`,
          justifyContent: 'space-between',

          '& .MuiButton-root': {
            borderRadius: '5px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 400,
          },
        },

        '& .MuiPickersLayout-shortcuts': {
          paddingTop: '40px',

          '& .MuiListItem-root': {
            paddingLeft: '20px',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingRight: '20px',
          },

          '& .MuiChip-root': {
            backgroundColor: '#0000001A',
            height: '22px',
            borderRadius: '50px',
            padding: '3px 8px 3px 8px',

            '&:hover': {
              backgroundColor: '#5F26E033',
              color: theme.palette.new.versatile_violet,
            },

            '&.selected': {
              backgroundColor: theme.palette.new.versatile_violet,
              color: theme.palette.new.white,
            },

            '& .MuiChip-label': {
              padding: '0px',
              fontSize: '16px',
              lineHeight: '16px',
            },
          },
        },
      }),
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      root: {
        paddingRight: '0px',
      },
      weekDayLabel: ({ theme }) => ({
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '12px',
        color: theme.palette.new.black_d,
        width: '34px',
        height: '36px',
      }),
    },
  },
  MuiDateRangeCalendar: {
    styleOverrides: {
      root: {
        '& .MuiDayCalendar-weekContainer': {
          margin: `${weekContainerMargin} 0px`,
        },
      },
    },
    defaultProps: {},
  },
  MuiDateRangePickerDay: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiPickersDay-today': {
          color: theme.palette.new.versatile_violet,
          borderColor: `${theme.palette.new.versatile_violet} !important`,
        },
        '&:last-of-type': {
          borderTopRightRadius: borderRadius,
          borderBottomRightRadius: borderRadius,

          [`& .${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
          },
        },
        '&:first-of-type': {
          borderTopLeftRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,

          [`& .${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
          },
        },
        [`& .${dateRangePickerDayClasses.rangeIntervalPreview}`]: {
          borderWidth: '1px',

          [`&.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart}`]: {
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
          },
          [`&.${dateRangePickerDayClasses.rangeIntervalDayPreviewEnd}`]: {
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
          },
        },
      }),
      outsideCurrentMonth: {
        '& .MuiPickersDay-dayOutsideMonth': {
          color: '#CCCCCC',
        },
      },
      rangeIntervalDayPreview: {
      },
      dayOutsideRangeInterval: {
        '&:hover': {
          borderColor: '#CCCCCC !important',
        },
      },
      rangeIntervalDayPreviewStart: {
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
      },
      rangeIntervalDayHighlightEnd: {
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      rangeIntervalDayHighlight: {
        backgroundColor: 'rgb(95 38 224 / 10%)',
      },
      day: ({ theme }) => ({
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        borderRadius,
        transform: 'scale(1)',

        '&.Mui-selected': {
          backgroundColor: theme.palette.new.versatile_violet,

          '&:hover': {
            backgroundColor: theme.palette.new.versatile_violet,
          },

          '&:focus': {
            backgroundColor: 'rgb(204 204 204 / 20%)',
            color: theme.palette.new.black,
            borderColor,
          },
        },
      }),
    },
    defaultProps: {},
  },
}

export default defaultDatePickersConfigs
