import React, { useMemo } from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { createId } from '@utils/common.utils'
import { Box, Typography } from '@mui/material'
import { DEFAULT_TOOLTIP_DATE_FORMAT, formatTimestamp, ISO_CALENDAR_WEEK_FORMAT } from '@utils/moment.utils'
import { PROMOTION_DAYS } from '@constants/promotions.constants'
import { formatPromotionEvents } from '@utils/promotions.utils'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

import {
  defaultInsightsTooltipValueFormatter,
  generateAbsDeviationPayloadKey,
  generatePredictionPayloadKey,
  getColorByIndex, getLineLabel,
} from '@utils/insights.utils'

import ChartTooltipComponent, { TooltipRow } from '@components/charts/ChartTooltip/ChartTooltip.component'
import ChartTooltipLegendItemComponent from '@components/charts/ChartTooltipLegendItem'
import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'

export interface TooltipRowsItem {
  /**
   * Label to be displayed
   */
  label: string,
  /**
   * Key to be used to get the value from the data
   */
  valueKey: string,
  /**
   * Key to be used to get the prediction value from the data
   */
  predictionKey: string,
  /**
   * Key to be used to get the absolute deviation value from the data
   */
  absDeviationKey: string,
  /**
   * Color to be used for the row actual value
   */
  secondaryColor: string,
  /**
   * Color to be used for the row predicted value
   */
  primaryColor: string,
  /**
   * Default color to be used for the row absolute deviation value
   */
  defaultColor: string,
}

export interface InsightsChartRowComponentProps {
  /**
   * Label to be displayed
   */
  label: string
  /**
   * Key to be used to get the color from the data
   */
  colorKey: 'primaryColor' | 'secondaryColor' | 'defaultColor'
  /**
   * Key to be used to get the value from the data
   */
  valueKey: keyof TooltipRowsItem
  /**
   * Rows to be displayed
   */
  rows: TooltipRowsItem[]
  /**
   * Data to be displayed
   */
  data: Hera.BaseChartDatasetItem
  /**
   * Flag to indicate if the intervals should be shown
   */
  showIntervals?: boolean
}

export const InsightsChartRowComponent: React.FC<InsightsChartRowComponentProps> = ({
  label,
  valueKey,
  colorKey,
  rows,
  data,
  showIntervals = true,
}) => {
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      data-testid={InsightsChartRowComponent.name}
    >
      <Typography
        fontWeight={500}
        textAlign='right'
        variant='body1'
      >
        {label}
      </Typography>

      {
        rows.map((row, index) => {
          const value: number | null = get(data, row[valueKey], null)
          const valueToRender = defaultInsightsTooltipValueFormatter(intl, value, { showIntervals })

          return (
            <Typography
              key={createId(index, valueKey)}
              variant='body1'
              textAlign='right'
              color={row[colorKey]}
            >
              {valueToRender}
            </Typography>
          )
        })
      }
    </Box>
  )
}

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  /**
   * Flag to indicate if the tooltip is active
   */
  active?: boolean
  /**
   * Flag to indicate if the chart is fetching data
   */
  isFetching?: boolean
  /**
   * Flag to indicate if the promotion days should be shown
   */
  promotionsVisibility?: boolean
  /**
   * Name of the target
   */
  targetName?: string
  /**
   * Prefix for the prediction key
   */
  predictionKeyPrefix?: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationKeyPrefix?: string
  /**
   * Flag to indicate if the grid has grouping enabled
   */
  hasGroupingEnabled?: boolean
  /**
   * Selected rows in the grid
   */
  selectedRows?: GridRowSelectionModel
  /**
   * Data to be displayed in the tooltip
   */
  payload?: Hera.BaseChartDatasetItem[]
  /**
   * Lines to be displayed in the chart
   */
  lines: Hera.BaseChartLineItem[]
  /**
   * Flag to show promotion days
   */
  promotionsToExclude?: PROMOTION_DAYS[]
}

const InsightsChartTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload, lines, isFetching,
  selectedRows = [],
  promotionsToExclude = [],
  targetName,
  hasGroupingEnabled = true,
  promotionsVisibility = true,
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
}) => {
  const intl = useIntl()
  const data: Hera.BaseChartDatasetItem = get(payload, '[0].payload', {})
  const promotionEvents = (promotionsVisibility ? (data.eventKeys || []) : []) as PROMOTION_DAYS[]
  const filteredPromotionEvents = promotionEvents.filter((event) => !promotionsToExclude.includes(event))
  const hasEvents = filteredPromotionEvents && filteredPromotionEvents.length > 0
  const promotionLabel = intl.formatMessage({ id: filteredPromotionEvents && filteredPromotionEvents.length > 1 ? 'common.promotions' : 'common.promotion' })

  const sortedRows = useMemo(() => {
    const rows: TooltipRowsItem[] = []

    if (!active || !payload || !payload.length || !lines || !lines.length) {
      return rows
    }

    lines.forEach((line, index) => {
      rows.push({
        label: line.label,
        valueKey: line.id,
        predictionKey: generatePredictionPayloadKey(line.id, predictionKeyPrefix),
        absDeviationKey: generateAbsDeviationPayloadKey(line.id, absDeviationKeyPrefix),
        defaultColor: 'black',
        secondaryColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: true,
          selectedRows,
        }),
        primaryColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: false,
          selectedRows,
        }),
      })
    })

    return rows.sort((a, b) => {
      const aPredictionValue: number = get(data, a.predictionKey, 0) as number
      const bPredictionValue: number = get(data, b.predictionKey, 0) as number
      const aTruthValue: number = get(data, a.valueKey, 0) as number
      const bTruthValue: number = get(data, b.valueKey, 0) as number
      const aName = a.label
      const bName = b.label

      if (bPredictionValue !== aPredictionValue) {
        return bPredictionValue - aPredictionValue
      }

      if (bTruthValue !== aTruthValue) {
        return bTruthValue - aTruthValue
      }

      return aName.localeCompare(bName)
    })
  }, [
    active, payload, lines, isFetching, selectedRows, data,
    predictionKeyPrefix, absDeviationKeyPrefix, hasGroupingEnabled,
  ])

  /** Use regular tooltip when we do not group. */
  if (!hasGroupingEnabled) {
    const rows = [{
      key: 'date',
      numeric: false,
      label: intl.formatMessage({ id: 'insights.chart.x.title' }),
      valueFormatter: (value: number) => formatTimestamp(value, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT),
    }, {
      key: 'date',
      numeric: false,
      label: intl.formatMessage({ id: 'insights.chart.x.calendarWeek' }),
      valueFormatter: (value: number) => formatTimestamp(value, intl.locale, ISO_CALENDAR_WEEK_FORMAT),
    }] as TooltipRow<Hera.BaseChartDatasetItem>[]

    if (hasEvents) {
      rows.push({
        key: 'eventKeys',
        numeric: false,
        label: promotionLabel,
        valueFormatter: (value: PROMOTION_DAYS[]) => {
          return formatPromotionEvents(intl, value)
        },
      })
    }

    lines.forEach((line, index) => {
      rows.push({
        key: line.id,
        label: getLineLabel(intl, line, targetName),
        numeric: true,
        legendColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: false,
          useOpacity: false,
          selectedRows,
        }),
        valueFormatter: (value: number | null) => {
          return defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true })
        },
      })
    })

    if (absDeviationKeyPrefix) {
      rows.push({
        key: absDeviationKeyPrefix,
        numeric: false,
        label: intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' }),
        valueFormatter: (value: number | null) => {
          return defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false })
        },
      })
    }

    return (
      <ChartTooltipComponent<Hera.BaseChartDatasetItem>
        rows={rows}
        sort={true}
        disabled={isFetching}
        active={active}
        payload={payload}
      />
    )
  }

  if (active && !isFetching && payload && sortedRows && sortedRows.length && payload.length) {
    return (
      <ChartTooltipContainerComponent
        data-testid={InsightsChartTooltipComponent.name}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'insights.chart.x.title' })}
          </Typography>
          <Typography>
            {formatTimestamp(data.date, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT)}
          </Typography>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          mt={0.5}
          mb={hasEvents ? 0 : 2}
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'insights.chart.x.calendarWeek' })}
          </Typography>
          <Typography>
            {formatTimestamp(data.date, intl.locale, ISO_CALENDAR_WEEK_FORMAT)}
          </Typography>
        </Box>

        {
          hasEvents ? (
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              mt={0.5}
              mb={2}
            >
              <Typography
                fontWeight={500}
              >
                {promotionLabel}
              </Typography>
              <Typography>
                {formatPromotionEvents(intl, filteredPromotionEvents)}
              </Typography>
            </Box>
          ) : (
            null
          )
        }

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='flex-start'
          gap={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Typography>
              &nbsp;
            </Typography>

            {
              sortedRows.map((row, index) => {
                return (
                  <ChartTooltipLegendItemComponent
                    type='line'
                    color={row.primaryColor}
                    label={row.label}
                    key={createId(index, row.label)}
                  />
                )
              })
            }
          </Box>

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.actual' })}
            colorKey='secondaryColor'
            valueKey='valueKey'
            rows={sortedRows}
            data={data}
          />

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.predicted' })}
            colorKey='primaryColor'
            valueKey='predictionKey'
            rows={sortedRows}
            data={data}
          />

          {
            absDeviationKeyPrefix ? (
              <InsightsChartRowComponent
                label={intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' })}
                colorKey='defaultColor'
                valueKey='absDeviationKey'
                rows={sortedRows}
                data={data}
                showIntervals={false}
              />
            ) : (
              null
            )
          }
        </Box>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default InsightsChartTooltipComponent
