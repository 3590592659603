import React from 'react'

import {
  Box, Typography, useTheme,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import CloseIcon from '@icons/close.icon'

export interface DataGridToolbarSelectionBoxComponentProps {
  label: string,
  values?: string[],
  onClick?: () => void,
  onClear?: () => void,
  disabled?: boolean,
}

export const DataGridToolbarSelectionBoxComponent: React.FC<DataGridToolbarSelectionBoxComponentProps> = ({
  label, values, onClick, disabled, onClear,
}) => {
  const theme = useTheme()

  if (!values || values.length === 0) {
    return null
  }

  const handleClear = (e: React.SyntheticEvent) => {
    e.stopPropagation()

    if (onClear) {
      onClear()
    }
  }

  return (
    <TooltipComponent
      title={values.join(', ')}
      placement='bottom'
    >
      <Box
        data-testid={DataGridToolbarSelectionBoxComponent.name}
        onClick={disabled ? undefined : onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: disabled ? 'not-allowed' : 'pointer',
          backgroundColor: theme.palette.new.versatile_violet_10,
          color: theme.palette.new.versatile_violet,
          padding: theme.spacing(1),
          height: '36px',
          borderRadius: '4px',
          gap: 0.5,
          maxWidth: '290px',
        }}
        role='button'
        tabIndex={0}
      >
        <Typography
          noWrap={true}
          color={theme.palette.new.versatile_violet}
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            minWidth: 'fit-content',
          }}
        >
          {label}
        </Typography>
        <Typography
          noWrap={true}
          color={theme.palette.new.versatile_violet}
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {values.join(', ')}
        </Typography>

        <Box
          onClick={disabled ? undefined : handleClear}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: disabled ? 'not-allowed' : 'pointer',
            '&:hover': {
              opacity: 0.6,
            },
          }}
        >
          <CloseIcon detailsFill={theme.palette.new.versatile_violet} />
        </Box>
      </Box>
    </TooltipComponent>
  )
}

export default DataGridToolbarSelectionBoxComponent
