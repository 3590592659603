import React, { useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { FilterMenuIcon } from '@base/datagrid/data-grid/DataGrid.utils'

import {
  GridToolbarFilterButton,
  useGridApiContext,
  gridFilterModelSelector,
  useGridSelector,
  gridColumnDefinitionsSelector,
} from '@mui/x-data-grid-premium'

import DataGridToolbarSelectionBoxComponent from '@base/datagrid/data-grid-toolbar-selection-box'

export interface DataGridCustomFiltersToolbarComponentProps {
  disabled?: boolean,
}

export const DataGridCustomFiltersToolbarComponent: React.FC<DataGridCustomFiltersToolbarComponentProps> = ({
  disabled = false,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const apiRef = useGridApiContext()
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector)
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const filterValues = useMemo(() => {
    if (!filterModel || !filterModel.items.length) {
      return []
    }

    return filterModel.items.filter((item) => {
      return (item.value !== '' && item.value !== undefined) || item.operator === 'isEmpty' || item.operator === 'isNotEmpty'
    }).map((item) => item.field)
  }, [
    filterModel,
  ])

  const readableValues = useMemo(() => {
    return columns.filter((item) => {
      return filterValues.includes(item.field)
    }).map((item) => item.headerName || item.field)
  }, [
    filterValues,
    columns,
  ])

  const onBoxClick = () => {
    if (apiRef.current) {
      apiRef.current.showFilterPanel()
    }
  }

  const handleClear = () => {
    if (apiRef.current) {
      apiRef.current.setFilterModel({
        ...filterModel,
        items: [],
      })
    }
  }

  return (
    <Box
      data-testid={DataGridCustomFiltersToolbarComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <GridToolbarFilterButton
        slotProps={{
          button: {
            disabled,
            startIcon: (
              <FilterMenuIcon
                detailsFill={disabled ? theme.palette.new.black_d : theme.palette.new.black}
              />
            ),
          },
        }}
      />

      <DataGridToolbarSelectionBoxComponent
        label={intl.formatMessage({ id: 'common.filteredBy' })}
        values={readableValues}
        onClick={onBoxClick}
        disabled={disabled}
        onClear={handleClear}
      />
    </Box>
  )
}

export default DataGridCustomFiltersToolbarComponent
