import React from 'react'
import { useIntl } from 'react-intl'
import { formatDate, getCurrentDate } from '@utils/moment.utils'
import { DATE_FORMATS } from '@constants/date-formats.constants'
import ViewInfoComponent from '@base/pagebar/ViewInfo'

export interface ViewInfoDateComponentProps {
  /**
   * Label to be displayed
   */
  label: string
  /**
   * Date to be displayed
   */
  date?: string
  /**
   * If true, loading state will be displayed
   */
  loading?: boolean
  /**
   * Tooltip to be displayed
   */
  tooltip?: React.ReactNode
  /**
   * Date format, one of supported DATE_FORMATS
   *
   * @default DATE_FORMATS.FULL_DATE_MEDIUM
   */
  format?: typeof DATE_FORMATS,
}

const ViewInfoDateComponent: React.FC<ViewInfoDateComponentProps> = ({
  label,
  tooltip,
  loading,
  date,
  format = DATE_FORMATS.FULL_DATE_MEDIUM,
}) => {
  const intl = useIntl()
  const dateToUse = loading ? getCurrentDate() : date

  if (!date && !loading) {
    return null
  }

  return (
    <ViewInfoComponent
      label={label}
      loading={loading}
      tooltip={tooltip}
      value={formatDate(intl, dateToUse, format as string)}
    />
  )
}

export default ViewInfoDateComponent
