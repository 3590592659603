import React from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@mui/material'
import { VALIDATION_ERRORS } from '@constants/files.constants'
import ErrorListItem from '@components/training-data/TrainingDataErrorRow/ErrorListItem'

export interface PlainTextErrorComponentProps {
  error: TrainingFiles.CsvValidationResultItem
  type: VALIDATION_ERRORS.PARSABLE | VALIDATION_ERRORS.EMPTY_COLUMN_NAMES
}

const PlainTextErrorComponent: React.FC<PlainTextErrorComponentProps> = ({
  error,
  type,
}) => {
  const intl = useIntl()

  let messageKey = null
  let messageParams = {}

  switch (type) {
    case VALIDATION_ERRORS.PARSABLE: {
      messageKey = 'connect.data_input.modal.dropzone.status.error.parsing'
      messageParams = {}
      break
    }

    case VALIDATION_ERRORS.EMPTY_COLUMN_NAMES: {
      messageKey = 'connect.data_input.modal.dropzone.status.error.invalid_columns_empty'
      messageParams = {}
      break
    }

    default: {
      return null
    }
  }

  return (
    <ErrorListItem
      key={type}
      data-testid={`${PlainTextErrorComponent.name}-${type}`}
    >
      <Typography
        variant='body1'
      >
        {intl.formatMessage({ id: messageKey }, messageParams)}
      </Typography>
    </ErrorListItem>
  )
}

export default PlainTextErrorComponent
