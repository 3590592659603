import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Typography,
  SxProps, Theme,
} from '@mui/material'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import ChevronIcon from '@icons/flow/chevron.icon'

export interface PipelineConfigComponentProps {
  pipelineConfig: Pipelines.DemandConfig | null
  isFetching?: boolean
  sx?: SxProps<Theme>
}

const PipelineConfigComponent: React.FC<PipelineConfigComponentProps> = ({ pipelineConfig, isFetching, sx }) => {
  const intl = useIntl()
  const configuration = useMemo(() => JSON.stringify(pipelineConfig, null, 2), [pipelineConfig])

  if (!pipelineConfig || isFetching) {
    return null
  }

  return (
    <Box sx={sx} data-testid={PipelineConfigComponent.name}>
      <Accordion
        disableGutters={true}
        sx={{
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          id='pipeline-configuration'
          expandIcon={<ChevronIcon />}
        >
          <Typography
            variant='h5'
          >
            {intl.formatMessage({ id: 'pipeline_overview.pipelineConfiguration' })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SyntaxHighlighter
            style={atomDark as any}
            PreTag='div'
            language='json'
            wrapLongLines={true}
          >
            {configuration}
          </SyntaxHighlighter>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default PipelineConfigComponent
