import React from 'react'

import { Card, CardContent, useTheme } from '@mui/material'
import { Node, Edge, NodeTypes } from '@xyflow/react'

import { NODE_TYPES } from '@constants/flow.constants'

import ReactFlowComponent from '@components/connect-view/flow/ReactFlow'
import ReactFlowInputNodeComponent from '@components/connect-view/flow/ReactFlowInputNode'
import ReactFlowEmptyGroupingNodeComponent from '@components/connect-view/flow/ReactFlowEmptyGroupingNode'
import ReactFlowGroupingAttributesNodeComponent from '@components/connect-view/flow/ReactFlowGroupingAttributesNode'
import ReactFlowActiveInputGroupNodeComponent from '@components/connect-view/flow/ReactFlowActiveInputGroupNode'
import ReactFlowGenericInputGroupNodeComponent from '@components/connect-view/flow/ReactFlowGenericInputGroupNode'
import ReactFlowInputGroupNodeComponent from '@components/connect-view/flow/ReactFlowInputGroupNode'
import ReactFlowTargetsGroupNodeComponent from '@components/connect-view/flow/ReactFlowTargetsGroupNode'

const nodeTypes = {
  [NODE_TYPES.INPUT]: ReactFlowInputNodeComponent,
  [NODE_TYPES.EMPTY_GROUPING]: ReactFlowEmptyGroupingNodeComponent,
  [NODE_TYPES.GROUPING_ATTRIBUTES]: ReactFlowGroupingAttributesNodeComponent,
  [NODE_TYPES.INPUTS_GROUP]: ReactFlowInputGroupNodeComponent,
  [NODE_TYPES.ACTIVE_INPUTS_GROUP]: ReactFlowActiveInputGroupNodeComponent,
  [NODE_TYPES.GENERIC_INPUTS_GROUP]: ReactFlowGenericInputGroupNodeComponent,
  [NODE_TYPES.TARGETS_GROUP]: ReactFlowTargetsGroupNodeComponent,
} as NodeTypes

export interface ConnectionsOverviewComponentProps {
  /**
   * Nodes to be displayed
   */
  nodes: Node<UseCase.ConnectOverviewElementData>[]
  /**
   * Edges to be displayed
   */
  edges: Edge[]
  /**
   * Flag to indicate if data is being fetched
   */
  isFetching?: boolean
  /**
   * Export ID to allow export to PNG
   */
  exportId?: string
}

const ConnectViewComponent: React.FC<ConnectionsOverviewComponentProps> = ({
  nodes = [],
  edges = [],
  isFetching,
  exportId,
}) => {
  const theme = useTheme()

  return (
    <Card
      id={exportId}
      elevation={0}
      data-testid={ConnectViewComponent.name}
      sx={{
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.new.grey_a}`,
      }}
    >
      <CardContent
        sx={{
          padding: '0 !important',
          position: 'relative',
        }}
      >
        <ReactFlowComponent<UseCase.ConnectOverviewElementData>
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          isFetching={isFetching}
          sx={{ maxHeight: '100%' }}
        />
      </CardContent>
    </Card>
  )
}

export default ConnectViewComponent
