import {
  takeEvery,
  call,
  put,
} from 'redux-saga/effects'

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { ARTIFACT_TYPES } from '@constants/use-cases.constants'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { generateUploadToken, downloadByToken } from '@redux/modules/file-service/file-service.api'
import { ActionPayload } from '@redux/modules/types'

import * as API from './artifacts.api'

import {
  REQUEST_ARTIFACTS_LIST,
  UPLOAD_ARTIFACT,
  REMOVE_ARTIFACT,
  DOWNLOAD_ARTIFACT,
} from './artifacts.action-types'

import {
  startFetchingArtifactsAction,
  stopFetchingArtifactsAction,
  receiveArtifactList,
  requestArtifactListAction,
} from './artifacts.actions'

import {
  RequestArtifactListActionPayload,
  RemoveArtifactActionPayload,
  UploadArtifactActionPayload,
  DownloadArtifactActionPayload,
} from './artifacts.types'

export const CREATE_FILE_SUCCESS = 'use_cases.files.confirmation.create'
export const REMOVE_FILE_SUCCESS = 'use_cases.files.confirmation.delete'
export const DOWNLOAD_FILE_SUCCESS = 'use_cases.files.confirmation.download'

export function* storeArtifactGenerator({ payload } : ActionPayload<UploadArtifactActionPayload>) {
  try {
    yield put(startFetchingArtifactsAction(UPLOAD_ARTIFACT))

    const uploadToken: string = yield call(generateUploadToken)
    const { data } = payload

    data.append('uploadToken', uploadToken)

    yield call(API.uploadArtifact, { data: payload.data, meta: payload.meta })

    const {
      useCaseId,
      showToast = true,
      fetchList = true,
    } = payload.meta

    if (fetchList) {
      yield put(requestArtifactListAction({ useCaseId, artifactType: ARTIFACT_TYPES.PREDICTION }))
    }

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: CREATE_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingArtifactsAction(UPLOAD_ARTIFACT))
  }
}

export function* removeArtifactGenerator({ payload } : ActionPayload<RemoveArtifactActionPayload>) {
  try {
    yield put(startFetchingArtifactsAction(REMOVE_ARTIFACT))

    const {
      useCaseId,
      artifactId,
      showToast = true,
      fetchList = true,
    } = payload

    yield call(API.removeArtifact, {
      useCaseId,
      artifactId,
    })

    if (fetchList) {
      yield put(requestArtifactListAction({ useCaseId, artifactType: ARTIFACT_TYPES.PREDICTION }))
    }

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: REMOVE_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingArtifactsAction(REMOVE_ARTIFACT))
  }
}

export function* downloadArtifactGenerator({ payload } : ActionPayload<DownloadArtifactActionPayload>) {
  try {
    yield put(startFetchingArtifactsAction(DOWNLOAD_ARTIFACT))

    const downloadToken: string = yield call(API.getArtifactDownloadToken, payload)

    yield call(downloadByToken, {
      downloadToken,
      fileName: payload.fileName,
    })

    yield put(changeToastAction({ useIntl: true, message: DOWNLOAD_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingArtifactsAction(DOWNLOAD_ARTIFACT))
  }
}

export function* fetchArtifactsGenerator({ payload } : ActionPayload<RequestArtifactListActionPayload>) {
  try {
    yield put(startFetchingArtifactsAction(REQUEST_ARTIFACTS_LIST))

    const artifactIds: string[] = yield call(API.listArtifacts, payload)
    const filesList: Artifacts.ArtifactItem[] = (artifactIds && artifactIds.length) ? yield call(API.getArtifacts, { artifactId: artifactIds }) : []

    yield put(receiveArtifactList(filesList))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingArtifactsAction(REQUEST_ARTIFACTS_LIST))
  }
}

export function* watchFetchArtifacts() {
  yield takeEvery(REQUEST_ARTIFACTS_LIST, fetchArtifactsGenerator)
}

export function* watchStoreArtifact() {
  yield takeEvery(UPLOAD_ARTIFACT, storeArtifactGenerator)
}

export function* watchRemoveArtifact() {
  yield takeEvery(REMOVE_ARTIFACT, removeArtifactGenerator)
}

export function* watchDownloadArtifact() {
  yield takeEvery(DOWNLOAD_ARTIFACT, downloadArtifactGenerator)
}

export default {
  watchFetchArtifacts,
  watchStoreArtifact,
  watchRemoveArtifact,
  watchDownloadArtifact,
}
