export enum SHORT_LOCALES {
  en = 'en',
  de = 'de',
}

export enum FULL_LOCALES {
  en = 'en-US',
  de = 'de-DE',
}

export const DEFAULT_LOCALE = FULL_LOCALES.en
export const DEFAULT_LOCALE_SHORT = SHORT_LOCALES.en

export const SUPPORTED_LOCALES_KEYS = {
  'en-US': true,
  'de-DE': true,
}

export const SUPPORTED_LOCALES = [{
  id: 'de-DE',
  value: SHORT_LOCALES.de,
  label: 'common.layout.header.lang.de.label',
  description: 'common.layout.header.lang.de.desc',
}, {
  id: 'en-US',
  value: SHORT_LOCALES.en,
  label: 'common.layout.header.lang.en.label',
  description: 'common.layout.header.lang.en.desc',
}]

export enum ARTIFACTS_FORMAT {
  EN = 'EN',
  DE = 'DE',
  BOTH = 'EN_DE',
}

export const ARTIFACTS_FORMAT_DEFAULT = ARTIFACTS_FORMAT.EN

export const ARTIFACTS_FORMAT_OPTIONS = [{
  value: ARTIFACTS_FORMAT.EN,
  labelKey: 'export.lang.en',
}, {
  value: ARTIFACTS_FORMAT.DE,
  labelKey: 'export.lang.de',
}, {
  value: ARTIFACTS_FORMAT.BOTH,
  labelKey: 'export.lang.both',
}]
