import React from 'react'

const CheckMarkIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18, viewBox = '0 0 18 18',
  fill = 'none', className, detailsFill = '#00C04D',
  opacity = '1',
}) => {
  return (
    <svg data-testid={CheckMarkIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g opacity={opacity}>
        <path d="M1.60596 11.3077L4.84596 15.4734C4.95529 15.6155 5.09537 15.7309 5.2557 15.8111C5.41603 15.8912 5.59243 15.934 5.77167 15.9363C5.94802 15.9383 6.12261 15.901 6.28275 15.8271C6.44288 15.7532 6.58452 15.6445 6.69739 15.509L17.0345 3" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      </g>

    </svg>
  )
}

export default CheckMarkIcon
