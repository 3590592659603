import { useMemo } from 'react'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { getTableStateFromStorage, createDataGridState } from '@utils/data-grid.utils'

const useGridInitialState = (tableId: string, initialState?: GridInitialStatePremium) => {
  const finalState = useMemo(() => {
    const tableStateFromStorage = getTableStateFromStorage<GridInitialStatePremium>(tableId)

    return createDataGridState(initialState, tableStateFromStorage?.localStorageState, tableStateFromStorage?.localStorageState)
  }, [initialState, tableId])

  if (!initialState) {
    return undefined
  }

  return finalState
}

export default useGridInitialState
