import React from 'react'

import { DEFAULT_PADDING } from '@constants/ui.constants'
import { Divider, useTheme } from '@mui/material'

export interface SideBarDividerComponentProps {
  /**
   * Enables top margin
   *
   * @default true
   */
  mt?: boolean,
  /**
   * Enables bottom margin
   *
   * @default true
   */
  mb?: boolean,
  /**
   * Width of the divider
   * @default '100%'
   */
  width?: string,
}

const SideBarDividerComponent: React.FC<SideBarDividerComponentProps> = ({
  mt = true,
  mb = true,
  width = '100%',
}) => {
  const theme = useTheme()

  const styles = {
    borderColor: theme.palette.new.grey_a,
    backgroundColor: theme.palette.new.grey_a,
    mt: mt ? DEFAULT_PADDING.MEDIUM : 0,
    mb: mb ? DEFAULT_PADDING.MEDIUM : 0,
    height: '1px',
    width,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }

  return (
    <Divider
      data-testid={SideBarDividerComponent.name}
      orientation='horizontal'
      sx={styles}
    />
  )
}

export default SideBarDividerComponent
