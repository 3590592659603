import React, { useMemo } from 'react'
import { Box, Divider } from '@mui/material'
import { useIntl } from 'react-intl'

import SearchIcon from '@icons/search.icon'
import CloseIcon from '@icons/close.icon'

import DropdownMenuComponent from '@base/dropdowns/DropdownMenu'
import IconButtonComponent from '@base/buttons/IconButton'
import SearchFieldComponent from '@base/forms/SearchField'
import FileBrowserBreadcrumbsComponent from '@components/file-browser/FileBrowserBreadcrumbs'

import {
  FILE_BROWSER_SORTING_OPTIONS_LIST,
  FILE_BROWSER_SORTING_OPTIONS,
  FILE_BROWSER_SORTING_ORDER_OPTIONS,
} from '@constants/files.constants'

import SortAscIcon from '@icons/sortAsc.icon'
import SortDescIcon from '@icons/sortDesc.icon'

export interface FileBrowserHeaderComponentProps {
  /**
   * Handles search bar visibility
   * @param state new state of search bar
   */
  handleShowSearchBar: (state: boolean) => void
  /**
   * Handles search action
   * @param searchPattern new search pattern
   */
  handleSearch: (searchPattern: string) => void
  /**
   * Handles sorting change
   * @param key sorting key
   * @param order sorting order
   */
  handleSortingChange: (key: FILE_BROWSER_SORTING_OPTIONS, order: FILE_BROWSER_SORTING_ORDER_OPTIONS) => void
  /**
   * if true, search bar will be shown
   */
  showSearchBar?: boolean
  /**
   * if true, uploading is in progress
   */
  isUploading?: boolean
  /**
   * if true, refreshing is in progress
   */
  isRefreshing?: boolean
  /**
   * File storage bucket
   */
  fileStorageBucket: string
  /**
   * Search field value
   */
  searchValue: string
  /**
   * Sorting key
   */
  sortingKey: FILE_BROWSER_SORTING_OPTIONS
  /**
   * Sorting order
   */
  sortingOrder: FILE_BROWSER_SORTING_ORDER_OPTIONS
}

const FileBrowserHeaderComponent: React.FC<FileBrowserHeaderComponentProps> = ({
  handleSearch, searchValue, isUploading,
  showSearchBar, handleShowSearchBar,
  fileStorageBucket, isRefreshing,
  handleSortingChange, sortingKey,
  sortingOrder,
}) => {
  const intl = useIntl()

  const IconComponent = sortingOrder === FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC ? SortAscIcon : SortDescIcon

  const options = useMemo(() => {
    return FILE_BROWSER_SORTING_OPTIONS_LIST.map((item) => {
      return {
        value: item.value,
        label: intl.formatMessage({ id: item.labelKey }),
      }
    })
  }, [intl])

  const handleSortingChangeClick = () => {
    const newOrder = sortingOrder === FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC ?
      FILE_BROWSER_SORTING_ORDER_OPTIONS.DESC :
      FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC

    handleSortingChange(sortingKey, newOrder)
  }

  const handleCloseSearchBarClick = () => {
    handleShowSearchBar(false)
  }

  const handleShowSearchBarClick = () => {
    handleShowSearchBar(true)
  }

  if (showSearchBar) {
    return (
      <Box
        data-testid={FileBrowserHeaderComponent.name}
        mt={0}
        mb={2}
        display='flex'
        alignItems='center'
        width='100%'
        height={36}
        gap={1}
      >
        <SearchFieldComponent
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
          label={null}
          value={searchValue}
          showClearIcon={false}
          autoFocus={true}
        />

        <IconButtonComponent
          name='closeSearch'
          color='secondary'
          rounded={true}
          label={intl.formatMessage({ id: 'fileManager.header.button.cancel' })}
          IconComponent={CloseIcon}
          onClick={handleCloseSearchBarClick}
        />
      </Box>
    )
  }

  return (
    <Box
      data-testid={FileBrowserHeaderComponent.name}
      mt={1}
      mb={2}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
    >
      <FileBrowserBreadcrumbsComponent fileStorageBucket={fileStorageBucket} />

      <Box
        display='flex'
        alignItems='center'
        sx={{
          cursor: 'pointer',
          gap: 1,
        }}
      >
        <IconButtonComponent
          name='sortOrder'
          color='secondary'
          rounded={true}
          label={intl.formatMessage({ id: 'fileManager.header.sorting.title' })}
          IconComponent={IconComponent}
          onClick={handleSortingChangeClick}
          disabled={isUploading || isRefreshing}
        />

        <DropdownMenuComponent
          name='sortBy'
          options={options}
          value={sortingKey}
          onChange={(value) => handleSortingChange(value as FILE_BROWSER_SORTING_OPTIONS, sortingOrder)}
          disabled={isUploading || isRefreshing}
          buttonProps={{
            color: 'secondary',
            ariaLabel: 'Sort by',
          }}
        />

        <Divider orientation='vertical' flexItem={true} />

        <IconButtonComponent
          name='search'
          color='secondary'
          rounded={true}
          label={intl.formatMessage({ id: 'fileManager.header.button.search' })}
          onClick={handleShowSearchBarClick}
          disabled={isUploading}
          IconComponent={SearchIcon}
          iconComponentProps={{
            width: '18px',
            height: '18px',
          }}
        />
      </Box>
    </Box>
  )
}

export default FileBrowserHeaderComponent
