export const REQUEST_FILES_LIST = 'REQUEST_FILES_LIST'
export const REQUEST_FILES_LIST_REFRESH = 'REQUEST_FILES_LIST_REFRESH'
export const RECEIVE_FILES_LIST = 'RECEIVE_FILES_LIST'

export const REQUEST_FILE_PREVIEW = 'REQUEST_FILE_PREVIEW'
export const RECEIVE_FILE_PREVIEW = 'RECEIVE_FILE_PREVIEW'
export const CLOSE_FILE_PREVIEW = 'CLOSE_FILE_PREVIEW'

export const REQUEST_SORTING_CHANGE = 'REQUEST_SORTING_CHANGE'
export const RECEIVE_SORTING_CHANGE = 'RECEIVE_SORTING_CHANGE'

export const REQUEST_FILE_DOWNLOAD = 'REQUEST_FILE_DOWNLOAD'
export const REQUEST_FILE_DELETE = 'REQUEST_FILE_DELETE'
export const REQUEST_FILE_UPLOAD = 'REQUEST_FILE_UPLOAD'

export const START_FETCHING_FILES = 'START_FETCHING_FILES'
export const STOP_FETCHING_FILES = 'STOP_FETCHING_FILES'
