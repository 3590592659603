import React from 'react'
import { useIntl } from 'react-intl'
import { createId } from '@utils/common.utils'
import { Box, Typography, useTheme } from '@mui/material'

import {
  NO_UNIT_LABEL_PLACEHOLDER,
  SourceTypeOption,
  SOURCE_TYPES_OPTIONS,
  SOURCE_TYPES_TO_ICONS_MAP,
} from '@constants/flow.constants'

export interface ParameterTemplatesTableComponentProps {
  /**
   * Title of the table
   */
  title?: string,
  /**
   * List of parameters
   */
  list: UseCase.ParameterTemplateItem[],
}

const ParameterTemplatesTableComponent: React.FC<ParameterTemplatesTableComponentProps> = ({
  title,
  list = [],
}) => {
  const theme = useTheme()
  const intl = useIntl()

  if (!list || list.length === 0) {
    return null
  }

  return (
    <Box
      data-testid={ParameterTemplatesTableComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {
        title ? (
          <Typography
            variant='h4'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              maxWidth: '450px',
              padding: 3,
            }}
          >
            {title}
          </Typography>
        ) : (
          null
        )
      }

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: theme.spacing(0),
          width: '100%',
          '& .listItem:last-child': {
            borderBottom: `1px solid ${theme.palette.new.grey_a}`,
          },
        }}
      >
        {
          list.map((item, index) => {
            const {
              name,
              sourceType,
              unitLabel,
            } = item

            const dataSourceOption = (SOURCE_TYPES_OPTIONS.find((sourceTypeItem) => sourceTypeItem.value === sourceType) || {}) as SourceTypeOption
            const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[dataSourceOption.value]

            return (
              <Box
                className='listItem'
                key={createId(name, index)}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  height: theme.spacing(6),
                  padding: theme.spacing(0, 3),
                  borderTop: `1px solid ${theme.palette.new.grey_a}`,
                  '&:hover': {
                    backgroundColor: theme.palette.new.grey_d,
                  },
                  '&:last-child': {
                    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                  },
                }}
              >
                <Typography
                  pr={1}
                  variant='body1'
                  noWrap={true}
                  title={name}
                  color={theme.palette.new.grey_c}
                >
                  {name}
                </Typography>
                <Typography
                  variant='body2'
                  noWrap={true}
                  component='div'
                  color={theme.palette.new.grey_c}
                  sx={{
                    width: 'auto',
                    minWidth: 'fit-content',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {unitLabel || NO_UNIT_LABEL_PLACEHOLDER}

                  <Box
                    sx={{
                      minWidth: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconComponent />
                  </Box>
                </Typography>
              </Box>
            )
          })
        }
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(6),
        }}
      >
        <Typography variant='h4Alt'>
          {intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_missing_header' })}
        </Typography>
        <Typography
          variant='body1'
          color={theme.palette.new.grey_h}
          sx={{
            maxWidth: '500px',
            textAlign: 'center',
            marginTop: theme.spacing(2),
          }}
        >
          {intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_missing_text' })}
        </Typography>
      </Box>
    </Box>
  )
}

export default ParameterTemplatesTableComponent
