import React, {
  useState, useMemo,
  useEffect, SyntheticEvent,
} from 'react'

import groupBy from 'lodash.groupby'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import {
  Accordion, AccordionSummary,
  AccordionDetails, Typography,
  Box, useTheme,
} from '@mui/material'

import { sortByKey } from '@utils/list.utils'
import { copyToClipboard } from '@utils/common.utils'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { getCompanyConnectionsBySource } from '@redux/modules/hermes/hermes.selectors'
import { getModalDetails } from '@redux/modules/modal-manager/modal-manager.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { DATA_SOURCES_MODAL_NAME } from '@constants/modals.constants'
import { DESC_SORTING_ORDER } from '@constants/filters.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { matchSourcesToApi, MappedSources } from '@utils/flow.utils'
import { SOURCE_TYPES_CATEGORIES, CATEGORIES_TO_LABEL_MAP } from '@constants/flow.constants'
import { getFileIdentifiersCount } from '@utils/use-cases.utils'
import { AuthDataSourceModalDetails } from '@containers/modals/auth-data-source-modal/AuthDataSourceModal.container'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ChevronIcon from '@icons/flow/chevron.icon'
import CopyIcon from '@icons/flow/copy.icon'
import ButtonComponent from '@base/buttons/Button'
import VideoBlockComponent from '@components/connectors/VideoBlock'
import InlineButtonComponent from '@base/buttons/InlineButton'

export interface DataSourcesWidgetModalDetails extends Common.ModalDetails {
  panelToOpen: string
}

export interface DataSourcesWidgetComponentProps {
  searchValue?: string,
  setSearchValue?: {
    (value: string): any,
  }
}

const DataSourcesWidgetComponent: React.FC<DataSourcesWidgetComponentProps> = ({ searchValue = '', setSearchValue }) => {
  const theme = useTheme()
  const intl = useIntl()
  const dispatch = useDispatch()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const { useCaseId } = useCase || ''
  const filesIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const useCaseFileIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state))
  const { panelToOpen } = useSelector((state) => getModalDetails<DataSourcesWidgetModalDetails>(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const fileVersionsCount = getFileIdentifiersCount(filesIdentifiers)
  const useCaseFileIdentifiersCount = useCaseFileIdentifiers.length || 0
  const categories = Object.values(SOURCE_TYPES_CATEGORIES)

  const [expanded, setExpanded] = useState<null | boolean | string>(null)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    trackEvent({
      componentName: 'mockDataSourcesListItem',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId,
      dataSourceType: panel,
    })

    setExpanded(newExpanded ? panel : false)
  }

  const handleCustom = (modalName: string, newModalDetails: AuthDataSourceModalDetails) => {
    if (setSearchValue) {
      setSearchValue('')
    }

    trackEvent({
      componentName: 'dataSourcesListItem',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId,
      dataSourceType: newModalDetails.key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: modalName,
        modalDetails: {
          ...newModalDetails,
          returnTo: DATA_SOURCES_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const filterBySearch = (item: MappedSources) => {
    const name = item.title.toLowerCase()
    const searchTerm = searchValue?.toLowerCase()

    if (!searchValue) {
      return true
    }

    return name.includes(searchTerm)
  }

  const sourcesListByCategory = useMemo(() => {
    const sourcesList = matchSourcesToApi(
      intl,
      isAdmin,
      {
        connectionsList,
        fileVersionsCount,
        useCaseFileIdentifiersCount,
        handleCustomSourceClick: handleCustom,
        handleStaticSourceClick: handleChange,
      },
    )

    return groupBy(sourcesList, (option) => option.category)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionsList, fileVersionsCount, useCaseFileIdentifiersCount])

  useEffect(() => {
    if (panelToOpen) {
      handleChange(panelToOpen)({} as React.SyntheticEvent, true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelToOpen])

  const onCopyButtonClick = (e: SyntheticEvent) => copyToClipboard({
    e, text: useCaseId, intl,
  })

  const handleRedirectClick = (dataSourceType: string, link?: string) => {
    trackEvent({
      componentName: 'dataSourceDocumentationLink',
      actionName: TRACKING_ACTIONS.NAVIGATE,
    }, {
      useCaseId,
      dataSourceType,
    })

    if (link) {
      window.open(link, '_blank')
    }
  }

  const listToRender = categories.map((category, categoryIndex) => {
    const list = sourcesListByCategory[category] as MappedSources[]
    const items = list ? (
      sortByKey<MappedSources[]>(list, 'title', DESC_SORTING_ORDER).filter(filterBySearch)
    ) : []

    if (!items || items.length === 0) {
      return null
    }

    return (
      <Box key={category}>
        <Box>
          <Typography
            variant='overline'
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingBottom: theme.spacing(1.5),
              marginLeft: theme.spacing(6),
              fontWeight: 500,
              textTransform: 'uppercase',
              letterSpacing: '0.06em',
              color: theme.palette.new.black,
              height: categoryIndex === 0 ? 'auto' : theme.spacing(6),
            }}
          >
            {intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] })}
          </Typography>
        </Box>
        <Box>
          {
            items.map((item, index) => {
              const {
                key,
                title,
                description,
                documentationLink,
                videoLink,
                onChange,
                videoHeight,
                statusText,
                disabled,
                icon: IconComponent,
              } = item
              const isExpanded = (expanded === key)

              return (
                <Accordion
                  key={key}
                  elevation={0}
                  expanded={isExpanded}
                  onChange={onChange}
                  disabled={disabled}
                  sx={{
                    '&.MuiAccordion-root': {
                      '&:before': {
                        display: 'none',
                      },
                    },
                    '&.MuiAccordion-root.Mui-expanded': {
                      margin: 0,
                      '& .summary': {
                        borderBottom: `1px solid ${theme.palette.new.grey_a} !important`,
                        borderBottomLeftRadius: `${theme.spacing(0)} !important`,
                        borderBottomRightRadius: `${theme.spacing(0)} !important`,
                      },
                      '&:hover': {
                        '& .summary': {
                          borderBottom: `1px solid ${theme.palette.new.grey_c} !important`,
                        },
                      },
                      '&:before': {
                        opacity: 1,
                      },
                    },
                    '&.MuiAccordion-rounded': {
                      borderTop: `1px solid ${theme.palette.new.grey_a}`,
                      borderLeft: `1px solid ${theme.palette.new.grey_a}`,
                      borderRight: `1px solid ${theme.palette.new.grey_a}`,
                      '&:first-of-type': {
                        borderTopLeftRadius: theme.spacing(0.5),
                        borderTopRightRadius: theme.spacing(0.5),
                      },
                      '&:last-child': {
                        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                        borderBottomLeftRadius: theme.spacing(0.5),
                        borderBottomRightRadius: theme.spacing(0.5),
                      },
                      '&:hover': {
                        backgroundColor: theme.palette.new.grey,
                        borderTop: `1px solid ${theme.palette.new.grey_c}`,
                        borderLeft: `1px solid ${theme.palette.new.grey_c}`,
                        borderRight: `1px solid ${theme.palette.new.grey_c}`,
                        '&:last-child': {
                          borderBottom: `1px solid ${theme.palette.new.grey_c}`,
                        },
                        '& + .MuiAccordion-rounded': {
                          borderTop: `1px solid ${theme.palette.new.grey_c}`,
                        },
                      },
                    },
                    '&.Mui-disabled': {
                      pointerEvents: 'none',
                    },
                  }}
                >
                  <AccordionSummary
                    aria-controls={key}
                    id={key}
                    className='summary'
                    expandIcon={<ChevronIcon />}
                    sx={{
                      height: '60px',
                      minHeight: '60px',
                      '& .MuiAccordionSummary-content': {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'rotate(-90deg)',
                      },
                      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(0deg)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <IconComponent />
                      <Typography
                        variant='body1'
                        sx={{
                          marginLeft: theme.spacing(2),
                        }}
                      >
                        {title}
                      </Typography>
                    </Box>
                    {
                      statusText ? (
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            color: theme.palette.new.grey_c,
                            marginRight: theme.spacing(2),
                          }}
                        >
                          {statusText}
                        </Typography>
                      ) : (
                        null
                      )
                    }
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: 'flex',
                      borderBottomLeftRadius: theme.spacing(0.5),
                      borderBottomRightRadius: theme.spacing(0.5),
                      backgroundColor: theme.palette.new.grey,
                      padding: theme.spacing(3, 2),
                    }}
                  >
                    <VideoBlockComponent
                      documentationLink={documentationLink}
                      videoLink={videoLink}
                      title={title}
                      videoHeight={videoHeight}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          position: 'relative',
                          width: '100%',
                          height: theme.spacing(4),
                          padding: theme.spacing(1, 1, 1, 2),
                          border: `1px solid ${theme.palette.new.grey_a}`,
                          borderRadius: theme.spacing(1),
                        }}
                      >
                        <Typography variant='body2'>
                          {useCaseId}
                        </Typography>
                        <InlineButtonComponent
                          name='copyButton'
                          iconOnly={true}
                          label={intl.formatMessage({ id: 'common.button.copy' })}
                          onClick={onCopyButtonClick}
                          StartIconComponent={CopyIcon}
                        />
                        <Typography
                          variant='body2'
                          sx={{
                            color: theme.palette.new.grey_c,
                            fontSize: '12px',
                            position: 'absolute',
                            top: theme.spacing(-0.8),
                            padding: theme.spacing(0, 1),
                            left: theme.spacing(1),
                            backgroundColor: theme.palette.new.grey,
                          }}
                        >
                          {intl.formatMessage({ id: 'connect.modal.use_case_id' })}
                        </Typography>
                      </Box>

                      <Typography
                        variant='body2'
                        sx={{
                          margin: theme.spacing(2, 0),
                        }}
                      >
                        {description}
                      </Typography>
                      <ButtonComponent
                        name='documentationButton'
                        color='primary'
                        onClick={() => handleRedirectClick(key, documentationLink)}
                        label={intl.formatMessage({ id: 'connect.modal.data_sources.documentation' })}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </Box>
      </Box>
    )
  })

  const isEmptyList = listToRender.filter((item) => item !== null).length === 0

  return (
    <Box
      data-testid={DataSourcesWidgetComponent.name}
      sx={{
        minHeight: '100%',
        borderRadius: theme.spacing(0.5),
        ...(isEmptyList ? {
          height: '100%',
        } : {}),
      }}
    >
      {
        isEmptyList ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: theme.spacing(3),
              height: '100%',
            }}
          >
            <Typography
              variant='h4'
              sx={{
                color: theme.palette.new.black,
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textAlign: 'center',
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.data_sources.search_empty_title' })}
            </Typography>
            <Typography
              variant='subtitle1'
              sx={{
                color: theme.palette.new.grey_h,
                maxWidth: '400px',
                textAlign: 'center',
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.data_sources.search_empty_body' })}
            </Typography>
          </Box>
        ) : (
          listToRender
        )
      }
    </Box>
  )
}

export default DataSourcesWidgetComponent
