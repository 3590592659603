export const REQUEST_SNAPSHOTS = 'REQUEST_SNAPSHOTS'
export const RECEIVE_SNAPSHOTS = 'RECEIVE_SNAPSHOTS'

export const REQUEST_SNAPSHOT = 'REQUEST_SNAPSHOT'
export const RECEIVE_SNAPSHOT = 'RECEIVE_SNAPSHOT'

export const DELETE_SNAPSHOT = 'DELETE_SNAPSHOT'

export const START_FETCHING_SNAPSHOTS = 'START_FETCHING_SNAPSHOTS'
export const STOP_FETCHING_SNAPSHOTS = 'STOP_FETCHING_SNAPSHOTS'
