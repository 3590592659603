import { keyframes } from '@emotion/react'

export const TOPBAR_HEIGHT = 60
export const SIDEBAR_WIDTH = 60
export const SIDEBAR_EXPANDED_WIDTH = 180
export const SIDE_PANEL_FOOTER_HEIGTH = 76
export const SIDE_PANEL_HEADER_HEIGTH = 60
export const PAGE_GUTTERS = 40

export const DEFAULT_ANIMATION_TIMEOUT = 500
export const CONTENT_LAYOUT_ID = 'content'

export const CONNECT_PAGE_DEFAULT_HEIGHT = `calc(100vh - ${TOPBAR_HEIGHT}px - 140px)`

export const LAYOUT_ELEMENTS_TRANSITIONS = {
  transition: 'padding 0.2s, border 0.3s, background 0.3s, stroke 0.3s',
}

export const REVERSED_Y_AXIS_CLASS_NAME = 'reversed-y-axis'

export const PARETOS_GRADIENT = 'linear-gradient(45.03deg, #F500E1 0.04%, #F500E1 0.05%, #FBB03B 100%)'
export const PARETOS_GRADIENT_20 = 'linear-gradient(45.03deg, rgba(245, 0, 225, 0.2) 0.04%, rgba(245, 0, 225, 0.2) 0.05%, rgba(251, 176, 59, 0.2) 100%)'
export const PARETOS_GRADIENT_10 = 'linear-gradient(45deg, rgba(245, 0, 225, 0.10) 0.04%, rgba(251, 176, 59, 0.10) 100%)'
export const SUSTAINABILITY_GRADIENT = 'linear-gradient(45deg, rgba(0, 192, 77, 0.10) 0.04%, rgba(102, 217, 148, 0.10) 100%)'

export const DEFAULT_BUTTON_FONT_SIZE = '16px'
export const DEFAULT_BUTTON_HEIGHT = '36px'
export const INLINE_BUTTON_HEIGHT = '18px'
export const DEFAULT_BUTTON_TRANSITION = 'background-color 0.3s, color 0.3s, opacity 0.3s, outline 0.3s, border-color 0.3s, border-width 0.3s, border-radius 0.3s ease-out'

export const DEFAULT_LAYOUT_FONT_SIZE = '16px'
export const DEFAULT_BOX_SHADOW = '0px 0px 10px rgba(0, 0, 0, 0.15)'
export const DEFAULT_SELECT_BOX_SHADOW = '0px 0px 10px 0px rgba(0, 0, 0, 0.15)'

export const CHECKBOX_BASE_ICONS_HOVER_STYLES = {
  '& .checkSquareIconCheckMark': {
    opacity: 0.2,
  },
  '& .checkedSquareIconCheckMark': {
    opacity: 0.6,
  },
  '& .indeterminateSquareIconDash': {
    opacity: 0,
  },
  '& .indeterminateSquareIconCheckMark': {
    opacity: 0.6,
  },
}

export const CHECKBOX_BASE_ICONS_STYLES = {
  '&.Mui-disabled .checkSquareIconBody': {
    fill: '#F2F2F2',
  },
  '& .checkSquareIconCheckMark': {
    transition: DEFAULT_BUTTON_TRANSITION,
    opacity: 0,
  },
  '& .checkedSquareIconCheckMark': {
    transition: DEFAULT_BUTTON_TRANSITION,
    opacity: 1,
  },
  '& .indeterminateSquareIconDash': {
    transition: DEFAULT_BUTTON_TRANSITION,
    opacity: 1,
  },
  '& .indeterminateSquareIconCheckMark': {
    transition: DEFAULT_BUTTON_TRANSITION,
    opacity: 0,
  },
}

export enum DEFAULT_PADDING {
  SMALL = '10px',
  MEDIUM = '12px',
  LARGE = '20px',
}

export enum DEFAULT_BORDER_RADIUS {
  SMALL = '3px',
  DEFAULT = '7px',
  MEDIUM = '10px',
}

export const spinAnimation = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`
