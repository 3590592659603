import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { EMITTER_TYPES } from '@constants/emitter.constants'
import { REQUEST_CANCELED_BY_USER } from '@utils/request-cancelation.utils'
import eventEmitter, { axiosCancelTokenController } from '@utils/emitter.utils'

import UploadSmallIcon from '@icons/uploadSmall.icon'
import TooltipComponent from '@base/tooltips/Tooltip'
import theme from '@configuration/theme.config'
import ActionButtonComponent from '@base/pagebar/ActionButton'

export interface FileBrowserUploadButtonComponentProps {
  /**
   * Handles upload action
   */
  handleUpload: () => void
  /**
   * if true, uploading is in progress
   */
  isUploading?: boolean
  /**
   * if true, file will be overwritten
   */
  overwrite?: boolean
}

const FileBrowserUploadButtonComponent: React.FC<FileBrowserUploadButtonComponentProps> = ({
  handleUpload, overwrite, isUploading,
}) => {
  const intl = useIntl()
  const [progress, setProgress] = useState(0)
  const [uploadFileName, setUploadFileName] = useState('')
  const [uploadPath, setUploadPath] = useState<string>('')

  const canCancel = useMemo(() => {
    return axiosCancelTokenController && axiosCancelTokenController.controller && isUploading
  }, [isUploading])

  const handleCancel = useCallback(() => {
    if (canCancel) {
      axiosCancelTokenController.controller.abort(REQUEST_CANCELED_BY_USER)

      // eslint-disable-next-line
      axiosCancelTokenController.controller = new AbortController()
    }
  }, [canCancel])

  const uploadProgress = useMemo(() => {
    if (!uploadFileName || !isUploading) {
      return ''
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
      >
        <Typography
          variant='h6'
          mb={1}
          width='100%'
          textAlign='center'
        >
          {`${progress}%`}
        </Typography>

        {
          progress === 100 ? (
            <Typography
              variant='body2'
              textAlign='center'
              color={theme.palette.new.violet_a}
              mb={1}
            >
              {
                intl.formatMessage({
                  id: 'fileManager.upload.dialog.fun',
                })
              }
            </Typography>
          ) : (
            null
          )
        }

        <Typography
          variant='body2'
          textAlign='center'
          sx={{
            wordBreak: 'break-word',
            display: 'inline-block',
          }}
        >
          {
            intl.formatMessage({
              id: 'fileManager.upload.dialog.title',
            }, {
              path: <Box component='strong'>{uploadPath.replace(`/${uploadFileName}`, '')}</Box>,
              name: <Box component='strong'>{uploadFileName}</Box>,
              overwrite: overwrite ? (
                <Box component='strong'>
                  {intl.formatMessage({
                    id: 'common.enabled',
                  })}
                </Box>
              ) : (
                <Box component='strong'>
                  {intl.formatMessage({
                    id: 'common.disabled',
                  })}
                </Box>
              ),
            })
          }
        </Typography>

        {
          canCancel ? (
            <Typography
              onClick={handleCancel}
              mt={1}
              sx={{
                fontSize: '12px',
                cursor: 'pointer',
                color: theme.palette.new.black,
                marginLeft: theme.spacing(1),
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              {intl.formatMessage({ id: 'use_cases.files.progress.cancel' })}
            </Typography>
          ) : (
            null
          )
        }
      </Box>
    )
  }, [
    intl, progress, uploadFileName,
    uploadPath, isUploading, overwrite,
    handleCancel, canCancel,
  ])

  useEffect(() => {
    eventEmitter.on(
      EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
      (progressEvent) => {
        if (progressEvent.name) {
          setProgress(Math.floor(100 * (progressEvent.loaded / progressEvent.total)))
          setUploadFileName(progressEvent.name)
          setUploadPath(progressEvent.path)
        }
      },
    )

    return function cleanup() {
      eventEmitter.off(EMITTER_TYPES.FILE_UPLOAD_PROGRESS)

      setProgress(0)
      setUploadFileName('')
      setUploadPath('')
    }
  }, [])

  return (
    <TooltipComponent
      open={isUploading}
      title={uploadProgress}
      describeChild={true}
      placement='left'
    >
      <ActionButtonComponent
        name='fileUpload'
        tooltip={false}
        label={intl.formatMessage({ id: 'fileManager.header.button.upload' })}
        IconComponent={UploadSmallIcon}
        onClick={handleUpload}
        loading={isUploading}
        disabled={isUploading}
      />
    </TooltipComponent>
  )
}

export default FileBrowserUploadButtonComponent
