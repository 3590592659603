import React from 'react'

const StorageIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={StorageIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M5.78572 13.5L8.03572 9.96431M5.25857 9.00003C4.96666 8.24441 4.92608 7.41481 5.14286 6.63431C5.31944 6.05082 5.63181 5.51749 6.05435 5.07806C6.4769 4.63863 6.99759 4.30562 7.57372 4.10632C8.14985 3.90703 8.76501 3.84714 9.36876 3.93155C9.97252 4.01597 10.5477 4.24229 11.047 4.59196C11.5464 4.94163 11.9558 5.40469 12.2416 5.94316C12.5274 6.48163 12.6815 7.08018 12.6913 7.68973C12.7011 8.29928 12.5662 8.90246 12.2978 9.44981C12.0294 9.99717 11.6351 10.4731 11.1471 10.8386M9.64286 14.1429H12.8571M3.21429 0.642883H14.7857C15.4958 0.642883 16.0714 1.21852 16.0714 1.9286V16.0715C16.0714 16.7815 15.4958 17.3572 14.7857 17.3572H3.21429C2.50421 17.3572 1.92857 16.7815 1.92857 16.0715V1.9286C1.92857 1.21852 2.50421 0.642883 3.21429 0.642883Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default StorageIcon
