import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { getNavigation } from '@utils/navigation.utils'
import { getIsAdmin, getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getUseCaseItem, isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'

import PageBarComponent from '@base/pagebar/PageBar'

export interface PageBarContainerProps {
  /**
   * Blocks to be displayed on the right side of the page bar
   */
  rightSideBlocks?: React.ReactNode,
  /**
   * Custom title to be displayed, if not provided, the title will be retrived from the navigation
   */
  customTitle?: React.ReactNode,
  /**
   * Path for the back button
   */
  backToPath?: string,
  /**
   * Tabs to be displayed
   */
  tabs?: Common.TabOption[]
  /**
   * Callback to be called when tab is changed
   * @param tab - Selected tab option
   */
  onTabChange?: (tab: Common.TabOption) => void,
}

const PageBarContainer: React.FC<PageBarContainerProps> = ({
  rightSideBlocks,
  onTabChange,
  customTitle,
  backToPath,
  tabs,
}) => {
  const intl = useIntl()
  const location = useLocation()
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isFetching = useSelector((state) => isFetchingUseCase(state))
  const useCaseDetails = useSelector((state) => getUseCaseItem(state))
  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const title = useMemo(() => {
    const items = getNavigation(intl, usecase, useCaseDetails, isAdmin, companyId)
    const currentItemIndex = items.findIndex((item) => {
      return location.pathname.includes(item.href || '')
    })

    const currentItem = items[currentItemIndex] || {}

    if ((isFetching || !useCaseDetails.companyId) && !(customTitle || currentItem.title)) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    return customTitle || currentItem.title
  }, [
    intl,
    isFetching,
    location,
    useCaseDetails,
    usecase,
    isAdmin,
    companyId,
    customTitle,
  ])

  return (
    <PageBarComponent
      tabs={tabs}
      title={title}
      backToPath={backToPath}
      onTabChange={onTabChange}
      rightSideBlocks={rightSideBlocks}
    />
  )
}

export default PageBarContainer
