import React from 'react'

const ShopifyblackIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={ShopifyblackIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M14.833 3.42983C14.8479 3.45028 14.8586 3.47496 14.8626 3.50371C14.8748 3.59418 16.8275 16.7957 16.8275 16.7957L11.2656 17.9989L12.0418 2.12277C11.9486 2.10845 11.8493 2.12652 11.7993 2.14124C11.7875 2.14471 11.5893 2.20587 11.2616 2.3073C10.9406 1.38369 10.3741 0.534923 9.37759 0.534923C9.35007 0.534923 9.32176 0.536038 9.29344 0.537649C9.01002 0.162838 8.65894 0 8.3557 0C6.03427 0 4.9252 2.90202 4.57747 4.37673C3.67542 4.65624 3.0346 4.85495 2.95275 4.88067C2.44924 5.03861 2.43332 5.05447 2.3672 5.52892C2.31745 5.88812 1 16.0766 1 16.0766L11.2658 18L16.8282 16.7967C16.8282 16.7967 14.8756 3.59513 14.8633 3.50466H14.8633C14.8592 3.47546 14.8483 3.45047 14.833 3.42983ZM10.6942 2.48278L9.82551 2.75163L9.82553 2.74846C9.82583 2.68833 9.82613 2.62905 9.82613 2.56432C9.82613 1.9903 9.74645 1.52812 9.61862 1.16174C10.1321 1.22618 10.4741 1.81042 10.6942 2.48278ZM8.98165 1.27556C9.12441 1.63327 9.21723 2.14663 9.21723 2.83937C9.21723 2.87434 9.21692 2.90635 9.21662 2.93868V2.93872L9.21661 2.94L7.4226 3.49556C7.76805 2.16237 8.41556 1.51845 8.98165 1.27556ZM8.29194 0.622662C8.39213 0.622662 8.49307 0.656679 8.58967 0.723165C7.84568 1.07325 7.04823 1.95498 6.71146 3.71577L5.29339 4.15496C5.68784 2.81192 6.62446 0.622662 8.29188 0.622662H8.29194ZM9.37549 6.43139L8.68962 8.47157C8.68962 8.47157 8.08871 8.15085 7.3521 8.15085C6.27222 8.15085 6.21788 8.82854 6.21788 8.99931C6.21788 9.36754 6.5972 9.646 7.05605 9.98285C7.7583 10.4984 8.64681 11.1506 8.64681 12.4707C8.64681 14.1879 7.55769 15.2936 6.08918 15.2936C4.32696 15.2936 3.42578 14.1968 3.42578 14.1968L3.89763 12.6379C3.89763 12.6379 4.82397 13.4332 5.60562 13.4332C6.11638 13.4332 6.32414 13.031 6.32414 12.7372C6.32414 12.2045 5.94144 11.8949 5.51146 11.547C4.96028 11.1011 4.33143 10.5923 4.33143 9.47028C4.33143 7.7893 5.53796 6.1626 7.97346 6.1626C8.91188 6.1626 9.37549 6.43139 9.37549 6.43139Z" fill="black"/>
      <path d="M14.7054 3.35767C14.64 3.35221 13.2565 3.24966 13.2565 3.24966C13.2565 3.24966 12.2956 2.29575 12.1902 2.19017C12.1507 2.15088 12.0975 2.13075 12.0418 2.12207L11.2656 17.9989L16.8275 16.7957C16.8275 16.7957 14.8748 3.59418 14.8626 3.50371C14.8501 3.41325 14.7708 3.36318 14.7054 3.35767Z" fill="black" fillOpacity="0.4"/>

    </svg>
  )
}

export default ShopifyblackIcon
