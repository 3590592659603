import React from 'react'

const TableHandleIcon: React.FC<Common.IconProps> = ({
  width = 3, height = 36,
  viewBox = '0 0 3 36', fill = 'none', opacity = '1',
  className = '', detailsFill = '#CCCCCC',
  ...rest
}) => {
  return (
    <svg data-testid={TableHandleIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <rect x="0.121826" y="0.000183105" width="2" height="36" rx="1" fill={detailsFill} />

    </svg>
  )
}

export default TableHandleIcon
