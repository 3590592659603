import React from 'react'

const SmallFilterIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 14,
  height = 15,
  viewBox = '0 0 14 15',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg
      data-testid={SmallFilterIcon.name}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      {...rest}
      xmlns='http://www.w3.org/2000/svg'
    >

      <path d="M10.925 3.54845H3.07495L6.09418 7.77537V11.3984L7.90572 10.1908V7.77537L10.925 3.54845Z" fill={detailsFill} />

    </svg>
  )
}

export default SmallFilterIcon
