import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getGridSize } from '@utils/common.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'

import ButtonComponent from '@base/buttons/Button'
import CodeIcon from '@icons/code.icon'
import ShrinkIcon from '@icons/shrink.icon'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu'
import SectionTileComponent from '@base/tiles/SectionTile'
import TopArticlesItemComponent from '@components/recommendation-artifacts/TopArticlesItem'

export interface TopArticlesComponentProps {
  /**
   * Flag to indicate if the component is loading
   */
  loading?: boolean
  /**
   * List of top articles tables
   */
  tables?: RecommendationArtifacts.TopArticlesTable[]
  /**
   * Total count of articles
   */
  totalCount?: number
  /**
   * List of top N options
   */
  topOptions?: number[]
  /**
   * Default top N option
   */
  defaultTopOption?: number
  /**
   * Number of tables per row
   */
  tablesPerRow?: number
  /**
   * Flag to indicate if the component is in comparison mode
   */
  comparisonMode?: boolean
}

const TopArticlesComponent: React.FC<TopArticlesComponentProps> = ({
  loading,
  tables = [],
  topOptions = [],
  totalCount = 0,
  tablesPerRow = 2,
  defaultTopOption = 5,
  comparisonMode,
}) => {
  const intl = useIntl()

  const [expanded, setExpanded] = useState(false)
  const [limit, setLimit] = useState(defaultTopOption)
  const gridSize = getGridSize(tablesPerRow, expanded, 4)

  const limitOptions = useMemo(() => {
    return topOptions.map((option) => {
      return {
        label: intl.formatMessage({ id: 'recommendation.analyze.top.option' }, { num: option }),
        value: option,
      }
    })
  }, [
    topOptions,
    intl,
  ])

  useEffect(() => {
    setLimit(defaultTopOption)
  }, [defaultTopOption])

  const title = totalCount ? intl.formatMessage({ id: 'recommendation.analyze.top' }, { count: defaultNumberFormatter(totalCount) }) : undefined

  const rightSideActions = (
    <ButtonComponent
      color='tertiary'
      name='expandTableButton'
      StartIconComponent={expanded ? ShrinkIcon : CodeIcon}
      label={expanded ? intl.formatMessage({ id: 'common.button.collapse_table' }) : intl.formatMessage({ id: 'common.button.expand_table' })}
      onClick={() => setExpanded(!expanded)}
    />
  )

  const leftSideActionsBefore = (
    <DropdownMenuComponent
      name='topDropdown'
      options={limitOptions}
      value={limit}
      onChange={(value) => setLimit(Number(value))}
      disabled={loading}
      buttonProps={{
        bold: true,
        ariaLabel: intl.formatMessage({ id: 'recommendation.analyze.top.dropdown' }),
      }}
    />
  )

  const containerProps = {
    item: true,
    xs: 12,
    sm: gridSize,
    md: gridSize,
    lg: gridSize,
  }

  return (
    <SectionTileComponent
      name='topArticles'
      noData={tables.length === 0}
      loading={loading}
      rightSideActions={rightSideActions}
      leftSideActionsBefore={leftSideActionsBefore}
      title={title}
      container={true}
      contentMinHeight='280px'
    >
      {
        tables.map((table, index) => {
          return (
            <TopArticlesItemComponent
              key={index}
              id={`top-articles-${index}`}
              title={table.title}
              comparisonMode={comparisonMode}
              actualValueColumnNames={table.actualValueColumnNames}
              columns={table.columns}
              rows={table.rows}
              limit={limit}
              expanded={expanded}
              containerProps={containerProps}
            />
          )
        })
      }
    </SectionTileComponent>
  )
}

export default TopArticlesComponent
