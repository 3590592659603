import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'

const ParameterSelectorNoOptionSectionComponent = () => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Box
      data-testid={ParameterSelectorNoOptionSectionComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(6),
        }}
      >
        <Typography
          variant='h4Alt'
        >
          {intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_missing_header' })}
        </Typography>
        <Typography
          color={theme.palette.new.grey_h}
          maxWidth='500px'
          textAlign='center'
          mt={2}
        >
          {intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_missing_text' })}
        </Typography>
      </Box>
    </Box>
  )
}

export default ParameterSelectorNoOptionSectionComponent
