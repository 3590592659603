import React from 'react'

const PlusIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 19,
  height = 19,
  viewBox = '0 0 19 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={PlusIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M8.5 1.29352V16.2935" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 8.79352L16 8.7934" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default PlusIcon
