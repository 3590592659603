import React from 'react'

import {
  Box,
  styled,
  useTheme,
} from '@mui/material'

import {
  PARETOS_GRADIENT, PARETOS_GRADIENT_10, SUSTAINABILITY_GRADIENT,
  DEFAULT_BORDER_RADIUS, DEFAULT_PADDING,
} from '@constants/ui.constants'

import KpiTileValuesComponent from './KpiTileValuesBlock.component'
import KpiTileHeaderBlockComponent from './KpiTileHeaderBlock.component'

export const TileInnerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'tileVariant' && prop !== 'tileSize',
})<{
  tileVariant?: KpiTileComponentProps['variant']
  tileSize?: KpiTileComponentProps['size']
}>(({
  theme, tileVariant, tileSize,
}) => {
  const gap = tileSize === 'compact' ? '5px' : '20px'
  const padding = tileSize === 'compact' ? '12px 20px' : DEFAULT_PADDING.LARGE
  const baseStyles = {
    display: 'flex',
    flexDirection: 'column' as any,
    gap,
    padding,
    borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
  }

  let additionalStyles = {}
  switch (tileVariant) {
    case 'sustainability': {
      additionalStyles = {
        border: '1px solid',
        borderColor: theme.palette.new.generous_green,
        background: SUSTAINABILITY_GRADIENT,
      }
      break
    }
    case 'paretos-bold': {
      additionalStyles = {
        background: `${PARETOS_GRADIENT}, #FFF`,
      }
      break
    }
    case 'paretos': {
      additionalStyles = {
        background: `${PARETOS_GRADIENT_10}, #FFF`,
      }
      break
    }

    case 'highlighted': {
      additionalStyles = {
        background: theme.palette.new.white,
      }
      break
    }
    case 'default':
    default: {
      additionalStyles = {
        border: '1px solid',
        borderColor: theme.palette.new.business_black_20,
        background: theme.palette.new.white,
      }

      break
    }
  }

  return {
    ...baseStyles,
    ...additionalStyles,
  }
})

export const TileOuterContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'tileVariant' && prop !== 'tileSize',
})<{
  tileVariant?: KpiTileComponentProps['variant']
  tileSize?: KpiTileComponentProps['size']
}>(({
  theme, tileVariant, tileSize,
}) => {
  const baseStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  }

  let additionalStyles = {}

  switch (tileVariant) {
    case 'paretos-bold': {
      additionalStyles = {
        padding: '1px',
        background: PARETOS_GRADIENT,
        border: 'none',
      }
      break
    }
    case 'paretos': {
      additionalStyles = {
        padding: '1px',
        background: PARETOS_GRADIENT,
        border: 'none',
      }
      break
    }

    case 'highlighted': {
      additionalStyles = {
        padding: '1px',
        background: PARETOS_GRADIENT,
        border: 'none',
      }
      break
    }
    case 'sustainability':
    case 'default':
    default: {
      additionalStyles = {}
      break
    }
  }

  return {
    ...baseStyles,
    ...additionalStyles,
  }
})

export type KpiTileValueTypes = 'number' | 'float' | 'string'
export type KpiTileSizes = 'compact' | 'default'
export type KpiTileVariants = 'default' | 'highlighted' | 'paretos' | 'paretos-bold' | 'sustainability'

export interface KpiTileComponentProps {
  /**
   * The title of the KPI tile
   */
  title: string
  /**
   * The value of the KPI tile
   */
  value: string | number
  /**
   * The unit of the value
   */
  unit?: string
  /**
   * The type of the value
   * @default 'number'
   */
  valueType?: KpiTileValueTypes
  /**
   * The formatting options of the value
   */
  valueFormattingOptions?: Intl.NumberFormatOptions
  /**
   * The secondary value of the KPI tile
   */
  secondaryValue?: string | number
  /**
   * The type of the secondary value
   * @default 'number'
   */
  secondaryValueType?: KpiTileValueTypes
  /**
   * The formatting options of the secondary value
   */
  secondaryValueFormattingOptions?: Intl.NumberFormatOptions
  /**
   * Overline text of the KPI tile
   */
  overline?: string
  /**
   * The tooltip of the KPI tile
   */
  tooltip?: string | React.ReactNode
  /**
   * The size of the KPI tile
   * @default 'default'
   */
  size?: KpiTileSizes
  /**
   * The variant of the KPI tile
   * @default 'default'
   */
  variant?: KpiTileVariants
  /**
   * Icon component to be displayed
   */
  IconComponent?: React.FC<Common.IconProps>
  /**
   * Props to be passed to the Icon component
   */
  iconComponentProps?: Common.IconProps
  /**
   * If 'true', the arrows will be shown instead of the positive/negative signs
   * @default false
   */
  showDeltaArrows?: boolean
  /**
   * If 'true', the loading state will be shown for the whole tile
   */
  loading?: boolean
  /**
   * If 'true', the loading state will be shown only for the values
   */
  loadingValues?: boolean
}

export const KpiTileComponent: React.FC<KpiTileComponentProps> = ({
  title,
  value,
  unit,
  valueType = 'number',
  valueFormattingOptions,
  secondaryValue,
  secondaryValueType = 'number',
  secondaryValueFormattingOptions,
  showDeltaArrows = false,
  tooltip,
  loading,
  loadingValues,
  overline,
  size = 'default',
  variant = 'default',
  IconComponent,
  iconComponentProps,
}) => {
  const theme = useTheme()

  return (
    <TileOuterContainer
      data-testid={KpiTileComponent.name}
      tileVariant={variant}
      tileSize={size}
    >
      <TileInnerContainer
        tileVariant={variant}
        tileSize={size}
      >
        <KpiTileHeaderBlockComponent
          title={title}
          overline={overline}
          tooltip={tooltip}
          size={size}
          variant={variant}
          IconComponent={IconComponent}
          iconComponentProps={iconComponentProps}
          loading={loading}
        />

        <KpiTileValuesComponent
          color={variant === 'paretos-bold' ? theme.palette.new.white : theme.palette.new.business_black}
          variant={size === 'compact' ? 'body2' : 'h5'}
          value={value}
          unit={unit}
          valueType={valueType}
          secondaryValue={secondaryValue}
          secondaryValueType={secondaryValueType}
          showDeltaArrows={showDeltaArrows}
          valueFormattingOptions={valueFormattingOptions}
          secondaryValueFormattingOptions={secondaryValueFormattingOptions}
          loading={loadingValues || loading}
        />
      </TileInnerContainer>
    </TileOuterContainer>
  )
}

export default KpiTileComponent
