import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, TooltipProps } from '@mui/material'

import HelpIcon from '@icons/help.icon'
import TooltipComponent from '@base/tooltips/Tooltip'
import ButtonComponent from '@base/buttons/Button'
import InlineButtonComponent from '@base/buttons/InlineButton'

export interface HelpButtonComponentProps {
  /**
   * Button name, used as a id for aria-labels and tests
   */
  name: string,
  /**
   * Help text to be displayed in the tooltip
   */
  tooltip: NonNullable<React.ReactNode>,
  /**
   * Button variant
   * @default 'contained'
   */
  variant?: 'contained' | 'inline'
  /**
   * Tooltip placement
   * @default 'left'
   */
  placement?: TooltipProps['placement'],
  /**
   * Custom button color
   */
  color?: string
  /**
   * Label
   *
   * @default 'Help'
   */
  label?: string
}

const HelpButtonComponent: React.FC<HelpButtonComponentProps> = ({
  name,
  tooltip,
  variant = 'contained',
  placement = 'left',
  label,
  color,
}) => {
  const intl = useIntl()
  const buttonLabel = label || intl.formatMessage({ id: 'common.button.help' })

  const content = useMemo(() => {
    if (variant === 'contained') {
      return (
        <ButtonComponent
          name={name}
          label={buttonLabel}
          StartIconComponent={HelpIcon}
          color='tertiary'
          rounded={true}
        />
      )
    }

    return (
      <InlineButtonComponent
        name={name}
        label={buttonLabel}
        color={color}
        StartIconComponent={HelpIcon}
        startIconComponentProps={{
          detailsFill: color,
        }}
      />
    )
  }, [
    name,
    variant,
    buttonLabel,
    color,
  ])

  return (
    <Box data-testid={HelpButtonComponent.name}>
      <TooltipComponent
        title={tooltip}
        placement={placement}
        describeChild={true}
      >
        {content}
      </TooltipComponent>
    </Box>
  )
}

export default HelpButtonComponent
