import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'
import { PARETOS_GRADIENT_20 } from '@constants/ui.constants'

import WarningIcon from '@icons/flow/warning.icon'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'

export interface AuthLoadingProps {
  /**
   * If true, an error message will be displayed
   */
  error?: boolean,
}

const AuthLoadingComponent: React.FC<AuthLoadingProps> = ({ error }) => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Box
      data-testid={AuthLoadingComponent.name}
      sx={{
        minHeight: '100vh',
        width: '100%',
        minWidth: '100%',
      }}
    >
      {
        error ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: theme.spacing(2),
              height: '100vh',
              background: PARETOS_GRADIENT_20,
            }}
          >
            <WarningIcon
              detailsFill={theme.palette.new.rebellious_red}
              height={64}
              width={64}
            />

            <Typography
              variant='h4'
              mt={4}
            >
              {intl.formatMessage({ id: 'common.keycloak.error.head' })}
            </Typography>

            <Typography
              variant='h5'
              mt={2}
            >
              {intl.formatMessage({ id: 'common.keycloak.error.body' })}
            </Typography>
          </Box>
        ) : (
          <LoadingFallbackComponent
            showLongRequestNote={true}
            minHeight='100vh'
          />
        )
      }
    </Box>
  )
}

export default AuthLoadingComponent
