import React, { useLayoutEffect } from 'react'

import { useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import { useDispatch } from '@redux/hooks'
import { requestLiveMonitoringAction, resetLiveMonitoringAction } from '@redux/modules/monitor/monitor.actions'

import LiveMonitoringTableContainer from './live-monitoring-table/LiveMonitoringTable.container'
import LiveMonitoringFiltersContainer from './live-monitoring-filters/LiveMonitoringFilters.container'
import LiveMonitoringChartContainer from './live-monitoring-chart/LiveMonitoringChart.container'
import LiveMonitoringKpisContainer from './live-monitoring-kpis/LiveMonitoringKpis.container'

export interface LiveMonitoringContainerProps {
  exportId?: string,
}

const LiveMonitoringContainer: React.FC<LiveMonitoringContainerProps> = ({ exportId }) => {
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      requestLiveMonitoringAction({
        useCaseId,
      }),
    )

    return () => {
      dispatch(
        resetLiveMonitoringAction(),
      )
    }
  }, [dispatch, useCaseId])

  return (
    <Box
      id={exportId}
      data-testid={LiveMonitoringContainer.name}
      sx={{ backgroundColor: 'white' }}
    >
      <LiveMonitoringKpisContainer />

      <LiveMonitoringChartContainer />

      <LiveMonitoringFiltersContainer />

      <LiveMonitoringTableContainer />
    </Box>
  )
}

export default LiveMonitoringContainer
