import { handleActions } from 'redux-actions'
import { TrainingFilesState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_TRAINING_DATA_FLAT_LIST,
  RECEIVE_TRAINING_DATA_LIST,
  RECEIVE_FILE_IDENTIFIERS_LIST,
  START_FETCHING_TRAINING_FILES,
  STOP_FETCHING_TRAINING_FILES,
} from './training-files.action-types'

import {
  initialState,
  receiveFileIdentifiersList,
  receiveTrainingDataList,
  receiveTrainingDataFlatList,
} from './training-files.reducers'

export default handleActions<TrainingFilesState, any>({
  [RECEIVE_TRAINING_DATA_LIST]: receiveTrainingDataList,
  [RECEIVE_FILE_IDENTIFIERS_LIST]: receiveFileIdentifiersList,
  [RECEIVE_TRAINING_DATA_FLAT_LIST]: receiveTrainingDataFlatList,
  [START_FETCHING_TRAINING_FILES]: startFetchingAction<TrainingFilesState>,
  [STOP_FETCHING_TRAINING_FILES]: stopFetchingAction<TrainingFilesState>,
}, initialState)
