import { State } from '@redux/modules/types'

import {
  REQUEST_TRAINING_DATA_FLAT_LIST,
  REQUEST_TRAINING_DATA_LIST,
  REQUEST_FILE_IDENTIFIERS_LIST,
  UPLOAD_TRAINING_DATA,
  REMOVE_TRAINING_DATA,
  DOWNLOAD_TRAINING_DATA,
  REMOVE_FILE_IDENTIFIER,
  CREATE_FILE_IDENTIFIER,
  CREATE_FILE_IDENTIFIER_VERSION,
  REMOVE_FILE_IDENTIFIER_VERSION,
} from './training-files.action-types'

export const getUseCaseTrainingData = (state: State) => {
  return state.trainingFiles.trainingDataList
}

export const getUseCaseTrainingDataFlatList = (state: State) => {
  return state.trainingFiles.trainingDataFlatList
}

export const getUseCaseFileIdentifiers = (state: State) => {
  return state.trainingFiles.fileIdentifiers
}

export const isFetchingFileIdentifiers = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(REQUEST_FILE_IDENTIFIERS_LIST)
}

export const isFetchingTrainingDataFlatList = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(REQUEST_TRAINING_DATA_FLAT_LIST)
}

export const isFetchingTrainingData = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(REQUEST_TRAINING_DATA_LIST)
}

export const isSubmittingTrainingData = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(UPLOAD_TRAINING_DATA) ||
         state.trainingFiles.fetchingKeys.includes(DOWNLOAD_TRAINING_DATA) ||
         state.trainingFiles.fetchingKeys.includes(REMOVE_TRAINING_DATA)
}

export const isSubmittingFileIdentifier = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(CREATE_FILE_IDENTIFIER) || state.trainingFiles.fetchingKeys.includes(REMOVE_FILE_IDENTIFIER)
}

export const isSubmittingFileIdentifierVersion = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(CREATE_FILE_IDENTIFIER_VERSION) || state.trainingFiles.fetchingKeys.includes(REMOVE_FILE_IDENTIFIER_VERSION)
}

export const isUploadingTrainingData = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(UPLOAD_TRAINING_DATA)
}

export const isDownloadingTrainingData = (state: State) => {
  return state.trainingFiles.fetchingKeys.includes(DOWNLOAD_TRAINING_DATA)
}
