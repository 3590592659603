export const REQUEST_OPTIMIZE_PAGE_DATA = 'REQUEST_OPTIMIZE_PAGE_DATA'
export const RECEIVE_OPTIMIZE_PAGE_DATA = 'RECEIVE_OPTIMIZE_PAGE_DATA'

export const REQUEST_OPTIMIZE_DRILL_DOWN = 'REQUEST_OPTIMIZE_DRILL_DOWN'
export const RECEIVE_OPTIMIZE_DRILL_DOWN = 'RECEIVE_OPTIMIZE_DRILL_DOWN'

export const SET_TARGET_REVENUE = 'SET_TARGET_REVENUE'
export const SET_TARGET_REVENUEDONE = 'SET_TARGET_REVENUEDONE'

export const SET_TARGET_REPLENISHMENT_COST = 'SET_TARGET_REPLENISHMENT_COST'
export const SET_TARGET_REPLENISHMENT_COST_DONE = 'SET_TARGET_REPLENISHMENT_COST_DONE'

export const SET_STRATEGY_ACTION = 'SET_STRATEGY_ACTION'
export const SET_STRATEGY_ACTION_DONE = 'SET_STRATEGY_ACTION_DONE'

export const SET_TARGET_SELECTOR_TYPE = 'SET_TARGET_SELECTOR_TYPE'
export const SET_TARGET_SELECTOR_TYPE_DONE = 'SET_TARGET_SELECTOR_TYPE_DONE'

export const START_FETCHING_OPTIMIZE = 'START_FETCHING_OPTIMIZE'
export const STOP_FETCHING_OPTIMIZE = 'STOP_FETCHING_OPTIMIZE'
