import React from 'react'
import { Typography, Box, useTheme } from '@mui/material'
import OptimizeValueComponent from '@components/(features)/optimize/optimize-value'

export interface OptimizeRestockDetailsItemComponentProps {
  header: string,
  subHeader?: string,
  description?: React.ReactNode | React.ReactNode[] | null,
  value?: number | string | React.ReactNode | React.ReactNode[] | null,
  subValue?: React.ReactNode | React.ReactNode[] | null,
  borderRight?: boolean,
  colorless?: boolean,
}

const OptimizeRestockDetailsItemComponent: React.FC<OptimizeRestockDetailsItemComponentProps> = ({
  header,
  subHeader,
  value,
  subValue,
  borderRight,
  colorless,
  description,
}) => {
  const theme = useTheme()

  return (
    <Box
      data-testid={OptimizeRestockDetailsItemComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '280px',
        padding: theme.spacing(3, 3),
        width: '100%',
        ...(borderRight ? {
          borderRight: '1px solid rgba(255, 255, 255, 0.4)',
        } : {}),
        ...(colorless ? {
          '& .subHeader': {
            color: theme.palette.new.black_d,
          },
        } : {}),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {
          subHeader ? (
            <Typography
              className='subHeader'
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: 'rgba(255, 255, 255, 0.8)',
              }}
            >
              {subHeader}
            </Typography>
          ) : (
            null
          )
        }
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '36px',
            whiteSpace: 'nowrap',
            lineHeight: '40px',
            color: theme.palette.new.white,
            margin: theme.spacing(1.5, 0),
            [theme.breakpoints.down('xl')]: {
              fontSize: '28px',
              lineHeight: '30px',
            },
          }}
        >
          {header}
        </Typography>
        {
          description && typeof description === 'string' ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                width: '100%',
                lineHeight: '20px',
                color: theme.palette.new.white,
                opacity: 0.8,
                [theme.breakpoints.down('xl')]: {
                  fontSize: '14px',
                  lineHeight: '18px',
                },
              }}
            >
              {description}
            </Typography>
          ) : (
            null
          )
        }
        {
          description && typeof description !== 'string' ? description : null
        }
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        {
          (typeof value === 'number' || typeof value === 'string') ? (
            <>
              <OptimizeValueComponent value={value} />

              {
                (typeof subValue === 'number' || typeof subValue === 'string') ? (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '28px',
                      color: theme.palette.new.white,
                      lineHeight: 1,
                      opacity: 0.6,
                      [theme.breakpoints.down('xl')]: {
                        fontSize: '24px',
                      },
                    }}
                  >
                    {subValue}
                  </Typography>
                ) : (
                  subValue
                )
              }
            </>
          ) : (
            value
          )
        }
      </Box>
    </Box>
  )
}

export default OptimizeRestockDetailsItemComponent
