import React from 'react'

const SortIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={SortIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.31393 5.607L3.93393 8.029L2.98193 7.091L6.99993 3.073L11.0179 7.091L10.0799 8.029L7.69993 5.607V10.927H6.31393V5.607Z" fill={detailsFill} />

    </svg>
  )
}

export default SortIcon
