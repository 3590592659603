import produce from 'immer'

import { AnalyzeState, ReducerPayload } from '@redux/modules/types'
import { getDataGridColumnsFromHeraColumns, getTargetNameFromColumnDefinitions, processChartData } from '@utils/insights.utils'

import {
  DEFAULT_ANALYZE_AGGREGATED_TARGET_FIELD,
  DEFAULT_ANALYZE_AGGREGATED_PREDICTION_FIELD,
  DEFAULT_ANALYZE_PREDICTION_COLUMN,
} from '@constants/insights.constants'

export const defaultTableState = {
  tableId: '',
  useCaseId: '',
  targetName: '',
  columns: [],
  rows: [],
  rowCount: 0,
  gridState: undefined,
} as Analyze.AnalyzeTable

export const defaultCbcClassification = {
  a: 0,
  b: 0,
  c: 0,
  total: 0,
}

export const defaultChartState = {
  targetName: '',
  targetUnit: '',
  dataset: [],
  lines: [],
  promotionDays: [],
  abcClassification: defaultCbcClassification,
} as Analyze.AnalyzeChart

const defaultTimeWindowDetails = {
  dateFrom: null,
  dateTo: null,
} as Analyze.AnalyzeTimeWindowDetailsAPIResponse

export const initialState: AnalyzeState = {
  fetchingKeys: [],
  analyzeTable: defaultTableState,
  analyzeChart: defaultChartState,
  analyzeTimeWindowDetails: defaultTimeWindowDetails,
}

export const receiveAnalyzeTable = (state: AnalyzeState, action: ReducerPayload<{
  useCaseId: string
  tableId?: string
  initialization?: boolean
  response?: Analyze.AnalyzeTablePaginatedAPIResponse
  gridInitialState?: Analyze.AnalyzeGridState
}>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.response || !action.payload.tableId) {
      draftState.analyzeTable = defaultTableState

      return
    }

    const {
      tableId,
      gridInitialState,
      initialization,
      response,
      useCaseId,
    } = action.payload

    const {
      data,
      metaData,
    } = response

    const {
      columns,
      totalCount,
    } = metaData

    if (initialization) {
      const targetName = getTargetNameFromColumnDefinitions(columns, DEFAULT_ANALYZE_PREDICTION_COLUMN)
      const tableColumns = getDataGridColumnsFromHeraColumns({
        columns,
        specialColumnNames: [DEFAULT_ANALYZE_PREDICTION_COLUMN],
        disableAggregation: false,
      })

      draftState.analyzeTable = {
        tableId,
        useCaseId,
        targetName,
        columns: tableColumns,
        rows: data,
        rowCount: totalCount,
        gridState: gridInitialState,
      }
    } else {
      draftState.analyzeTable = {
        ...draftState.analyzeTable,
        rows: data,
        rowCount: totalCount,
      }
    }
  })

  return nextState
}

export const receiveAnalyzeChart = (state: AnalyzeState, action: ReducerPayload<Analyze.AnalyzeChartAPIResponse>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload.data || !action.payload.metaData || !draftState.analyzeTable.gridState) {
      draftState.analyzeChart = defaultChartState

      return
    }

    const {
      data,
      metaData: {
        targetName,
        targetUnit,
        legend,
        abcClassification,
      },
    } = action.payload

    const { lines, dataset, promotionDays } = processChartData<Monitor.BacktestingGridState>({
      gridState: draftState.analyzeTable.gridState,
      dataPoints: data,
      baseLegend: [DEFAULT_ANALYZE_AGGREGATED_TARGET_FIELD, DEFAULT_ANALYZE_AGGREGATED_PREDICTION_FIELD],
      groupingLegend: legend,
    })

    draftState.analyzeChart = {
      targetName,
      targetUnit,
      lines,
      dataset,
      promotionDays,
      abcClassification,
    }
  })

  return nextState
}

export const receiveGridStateChange = (state: AnalyzeState, action: ReducerPayload<Analyze.AnalyzeGridState>) => {
  const nextState = produce(state, (draftState) => {
    draftState.analyzeTable.gridState = {
      ...draftState.analyzeTable.gridState,
      ...action.payload,
    }
  })

  return nextState
}

export const receiveAnalyzeTimeWindowDetails = (state: AnalyzeState, action: ReducerPayload<Analyze.AnalyzeTimeWindowDetailsAPIResponse>) => {
  const nextState = produce(state, (draftState) => {
    draftState.analyzeTimeWindowDetails = action.payload
  })

  return nextState
}
