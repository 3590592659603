import React from 'react'

const SettingsIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={SettingsIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M14.1428 10.6072H3.85711C2.08191 10.6072 0.642822 12.0462 0.642822 13.8214C0.642822 15.5966 2.08191 17.0357 3.85711 17.0357H14.1428C15.918 17.0357 17.3571 15.5966 17.3571 13.8214C17.3571 12.0462 15.918 10.6072 14.1428 10.6072Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.99997 14.3214C5.27611 14.3214 5.49997 14.0976 5.49997 13.8214C5.49997 13.5453 5.27611 13.3214 4.99997 13.3214C4.72383 13.3214 4.49997 13.5453 4.49997 13.8214C4.49997 14.0976 4.72383 14.3214 4.99997 14.3214Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.85711 0.964294H14.1428C15.918 0.964294 17.3571 2.40338 17.3571 4.17858C17.3571 5.95378 15.918 7.39287 14.1428 7.39287H3.85711C2.08191 7.39287 0.642822 5.95378 0.642822 4.17858C0.642822 2.40338 2.08191 0.964294 3.85711 0.964294Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 4.67858C12.7238 4.67858 12.5 4.45472 12.5 4.17858C12.5 3.90244 12.7238 3.67858 13 3.67858C13.2761 3.67858 13.5 3.90244 13.5 4.17858C13.5 4.45472 13.2761 4.67858 13 4.67858Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SettingsIcon
