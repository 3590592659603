import React from 'react'

const GoogleSheetsWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
  opacity = '1',
}) => {
  return (
    <svg
      data-testid={GoogleSheetsWhiteIcon.name}
      opacity={opacity}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >

      <path d="M10.875 0.666748L15 4.79175L12.9375 5.16675L10.875 4.79175L10.5 2.72925L10.875 0.666748Z" fill="black" fillOpacity="0.4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.875 0.666626V4.79163H15V16.0416C15 16.6632 14.4966 17.1666 13.875 17.1666H4.125C3.50344 17.1666 3 16.6632 3 16.0416V1.79163C3 1.17006 3.50344 0.666626 4.125 0.666626H10.875ZM5.25 12.4792V7.04175H12.75V12.4792H5.25ZM6.1875 11.5417H8.53125V10.2292H6.1875V11.5417ZM6.1875 9.29175H8.53125V7.97925H6.1875V9.29175ZM9.46875 11.5417H11.8125V10.2292H9.46875V11.5417ZM9.46875 9.29175H11.8125V7.97925H9.46875V9.29175Z" fill="black"/>

    </svg>
  )
}

export default GoogleSheetsWhiteIcon
