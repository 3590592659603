import {
  takeEvery,
  call,
  put,
  select,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { CHAT_ROLES } from '@constants/chat.constnats'
import { getCurrentUserInfo } from '@redux/modules/customer/customer.selectors'

import * as API from './chat.api'

import {
  REQUEST_CHAT_MESSAGES,
  SEND_CHAT_MESSAGE,
} from './chat.action-types'

import {
  receiveChatMessagesAction,
  sendChatMessageDoneAction,
  startFetchingChatAction,
  stopFetchingChatAction,
} from './chat.actions'

import {
  RequestChatMessagesPayload,
  SendChatMessagePayload,
} from './chat.types'

export function* fetchChatMessagesGenerator({ payload } : ActionPayload<RequestChatMessagesPayload>) {
  try {
    yield put(startFetchingChatAction(REQUEST_CHAT_MESSAGES))

    const state: State = yield select()
    const userInfo: Common.UserInfo = yield call(getCurrentUserInfo, state)

    const messages: Chat.ChatMessages[] = yield call(API.listChatMessages, {
      useCaseId: payload.useCaseId,
      companyId: payload.companyId,
      name: userInfo.given_name,
    })

    yield put(receiveChatMessagesAction(messages))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingChatAction(REQUEST_CHAT_MESSAGES))
  }
}

export function* sendChatMessagesGenerator({ payload } : ActionPayload<SendChatMessagePayload>) {
  try {
    yield put(sendChatMessageDoneAction(payload.message))

    yield put(startFetchingChatAction(SEND_CHAT_MESSAGE))

    const answer: Chat.ChatMessage = yield call(API.sendChatMessageAccenture, {
      message: payload.message,
      nextRoute: payload.nextRoute,
      useCaseId: payload.useCaseId,
      companyId: payload.companyId,
      sessionId: payload.sessionId,
    })

    yield put(sendChatMessageDoneAction({
      ...answer,
      actions: (answer.actions || []).map((action) => {
        return {
          ...action,
          // Somitems back-end sends boolean as string...
          // @ts-ignore
          recommended: action.recommended === 'true' || action.recommended === true,
        }
      }),
      role: CHAT_ROLES.ASSISTANT,
    } as Chat.ChatMessage))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingChatAction(SEND_CHAT_MESSAGE))
  }
}

export function* watchRequestChatMessages() {
  yield takeEvery(REQUEST_CHAT_MESSAGES, fetchChatMessagesGenerator)
}

export function* watchSendChatMessages() {
  yield takeEvery(SEND_CHAT_MESSAGE, sendChatMessagesGenerator)
}

export default {
  watchRequestChatMessages,
  watchSendChatMessages,
}
