import { SOURCE_TYPES, POSSIBLE_TEMPLATE_TYPES } from '@constants/flow.constants'
import { State } from '@redux/modules/types'

import {
  REQUEST_PARAMETERS_TEMPLATES,
  REQUEST_PARAMETER_TEMPLATE,

  CREATE_PARAMETERS_TEMPLATES,
  UPDATE_PARAMETERS_TEMPLATES,
  DELETE_PARAMETERS_TEMPLATES,
} from './parameters-templates.action-types'

export const getParameterTemplateItem = (state: State) => {
  return state.parametersTemplates.item
}

export const getParametersTemplatesList = (state: State) => {
  return state.parametersTemplates.list
}

export const getParametersTemplatesListBySourceType = (state: State, sourceType: SOURCE_TYPES) => {
  return state.parametersTemplates.list.filter((item) => item.sourceType === sourceType)
}

export const getParametersTemplatesListWithConstraints = (state: State, sourceType: SOURCE_TYPES, parameterType: POSSIBLE_TEMPLATE_TYPES) => {
  return state.parametersTemplates.list.filter((item) => ((item.sourceType === sourceType) && (item.possiblePresetTypes.includes(parameterType))))
}

export const isFetching = (state: State) => {
  return state.parametersTemplates.fetchingKeys.includes(REQUEST_PARAMETERS_TEMPLATES) || state.parametersTemplates.fetchingKeys.includes(REQUEST_PARAMETER_TEMPLATE)
}

export const isSubmittingParameterTemplate = (state: State) => {
  return state.parametersTemplates.fetchingKeys.includes(CREATE_PARAMETERS_TEMPLATES) ||
         state.parametersTemplates.fetchingKeys.includes(UPDATE_PARAMETERS_TEMPLATES) ||
         state.parametersTemplates.fetchingKeys.includes(DELETE_PARAMETERS_TEMPLATES)
}
