import React from 'react'

const ParetosWhiteIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ParetosWhiteIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.1545 5.24023C15.0919 7.67404 13.0806 9.90882 10.4815 9.87091C7.88246 9.90882 5.8673 7.67215 5.80853 5.24023H0V12.7596H2.98768V5.88091C3.31374 9.73444 6.54218 12.7596 10.4796 12.7596C14.6332 12.7596 18 9.39325 18 5.24023H15.1526H15.1545Z" fill={detailsFill}/>

    </svg>
  )
}

export default ParetosWhiteIcon
