import React from 'react'
import {
  DialogActions,
  DialogActionsProps,
  useTheme,
} from '@mui/material'

import { DEFAULT_PADDING } from '@constants/ui.constants'

export interface DialogActionsComponentProps extends DialogActionsProps {
  /**
   * Children to be rendered
   */
  children?: React.ReactNode
}

export const DialogActionsComponent: React.FC<DialogActionsComponentProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme()
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    px: DEFAULT_PADDING.LARGE,
    py: DEFAULT_PADDING.LARGE,
    gap: DEFAULT_PADDING.LARGE,
    borderTop: '1px solid',
    borderColor: theme.palette.new.business_black_20,
    ...props.sx,
  }

  return (
    <DialogActions
      {...props}
      sx={styles}
      data-testid={DialogActionsComponent.name}
    >
      {children}
    </DialogActions>
  )
}

export default DialogActionsComponent
