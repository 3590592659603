import React from 'react'

const ArrowFirstIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ArrowFirstIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m14.75 2-6.62308 6.62308c-.05151.04834-.09256.10673-.12063.17156-.02806.06482-.04254.13472-.04254.20536 0 0 .01448.14054.04254.20536.02807.06483.06912.12322.12063.17156l6.62308 6.62308"/>
        <path d="m.75-.75h14" transform="matrix(.00000004 1 1 -.00000004 5.25 1.25)"/>
      </g>

    </svg>
  )
}

export default ArrowFirstIcon
