import produce from 'immer'

import { RecommendationArtifactsState, ReducerPayload } from '@redux/modules/types'
import { getInitialConfigFromSchema, getTransformedRows } from '@utils/recommendation-artifacts.utils'

export const initialState: RecommendationArtifactsState = {
  comparisonMode: true,
  fetchingKeys: [],
  articlesOverviewTableArtifact: {
    table: {
      columns: [],
      rows: [],
      gridInitialState: undefined,
      metaData: {
        paretosValueColumnNames: [],
        actualValueColumnNames: [],
      },
    },
    metaData: {
      title: '',
      rowIdentifierKey: '',
      defaultSelection: null,
      tableInstructions: '',
    },
  },
  articlesOverviewDetailsArtifact: {
    itemId: '',
    kpiItems: [],
    visualisations: [],
    breakdown: {
      title: '',
      options: [],
      kpiItems: {},
      visualisations: {},
    },
  },
  constraintsAnalyzeArtifact: {
    metaData: {
      paretosValueColumnNames: [],
      actualValueColumnNames: [],
    },
    rows: [],
    columns: [],
    gridInitialState: undefined,
  },
  topArticlesArtifact: {
    tables: [],
    metaData: {
      totalCount: 0,
      topOptions: [0],
      defaultTopOption: 0,
      tablesPerRow: 3,
    },
  },
  visualisationsArtifact: {
    visualisations: [],
  },
  KPIItemsArtifact: {
    items: [],
  },
  KPIsPerItemArtifact: {
    title: '',
    items: [],
    kpis: {},
  },
}

export const receiveArticlesOverviewTableArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.ArticlesOverviewTableArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.articlesOverviewTableArtifact = {
      table: {
        columns: action.payload.table.columns,
        rows: getTransformedRows(action.payload.table.columns, action.payload.table.rows),
        gridInitialState: getInitialConfigFromSchema(action.payload.table.gridConfiguration),
        metaData: action.payload.table.metaData,
      },
      metaData: action.payload.metaData,
    }
  })

  return nextState
}

export const selectArticlesOverviewDetailsItem = (state: RecommendationArtifactsState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.articlesOverviewDetailsArtifact.itemId = action.payload
  })

  return nextState
}

export const receiveArticlesOverviewDetailsArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.ArticlesOverviewDetailsArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.articlesOverviewDetailsArtifact = {
      itemId: action.payload.itemId,
      kpiItems: action.payload.kpiItems,
      visualisations: action.payload.visualisations,
      breakdown: {
        title: action.payload.breakdown.title,
        options: action.payload.breakdown.options,
        kpiItems: action.payload.breakdown.kpiItems,
        visualisations: action.payload.breakdown.visualisations,
      },
    }
  })

  return nextState
}

export const receiveKPIItemsArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.KPIItemsArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.KPIItemsArtifact = {
      items: action.payload.items,
    }
  })

  return nextState
}

export const receiveKPIsPerItemArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.KPIsPerItemArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.KPIsPerItemArtifact = {
      title: action.payload.title,
      items: action.payload.items,
      kpis: action.payload.kpis,
    }
  })

  return nextState
}

export const receiveVisualisationsArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.VisualisationsArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.visualisationsArtifact = {
      visualisations: action.payload.visualisations,
    }
  })

  return nextState
}

export const receiveConstraintsAnalyzeArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.ConstraintsAnalyzeArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.constraintsAnalyzeArtifact = {
      metaData: action.payload.metaData,
      gridInitialState: getInitialConfigFromSchema(action.payload.gridConfiguration),
      columns: action.payload.columns,
      rows: getTransformedRows(action.payload.columns, action.payload.rows),
    }
  })

  return nextState
}

export const receiveTopArticlesArtifact = (state: RecommendationArtifactsState, action: ReducerPayload<RecommendationArtifacts.TopArticlesArtifact>) => {
  const nextState = produce(state, (draftState) => {
    draftState.topArticlesArtifact = {
      metaData: action.payload.metaData,
      tables: action.payload.tables.map((table) => {
        return {
          ...table,
          rows: getTransformedRows(table.columns, table.rows),
        }
      }),
    }
  })

  return nextState
}

export const toggleComparisonMode = (state: RecommendationArtifactsState) => {
  const nextState = produce(state, (draftState) => {
    draftState.comparisonMode = !draftState.comparisonMode
  })

  return nextState
}
