import React from 'react'

const FileZipIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={FileZipIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M5.14284 4.5H8.99999M5.14284 8.35714H8.99999M5.14284 12.2143H8.99999M7.07141 0.642853V13.5M16.0714 16.0714C16.0714 16.4124 15.936 16.7394 15.6948 16.9806C15.4537 17.2217 15.1267 17.3571 14.7857 17.3571H3.21427C2.87328 17.3571 2.54625 17.2217 2.30514 16.9806C2.06402 16.7394 1.92856 16.4124 1.92856 16.0714V1.92857C1.92856 1.58757 2.06402 1.26055 2.30514 1.01943C2.54625 0.778312 2.87328 0.642853 3.21427 0.642853H10.9286L16.0714 5.78571V16.0714Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default FileZipIcon
