import { createAction } from 'redux-actions'
import {
  SET_MODAL_DETAILS,
  SET_PRIMARY_MODAL_PAGE_NAME_DONE,
  SET_PRIMARY_MODAL_PAGE_NAME,
} from './modal-manager.action-types'

export const setModalDetails = createAction<Common.ModalDetails>(SET_MODAL_DETAILS)
export const setPrimaryModalPageName = createAction<Common.ModalPageName>(SET_PRIMARY_MODAL_PAGE_NAME)
export const setPrimaryModalPageNameDone = createAction(SET_PRIMARY_MODAL_PAGE_NAME_DONE)
