import React from 'react'

import { Box } from '@mui/material'

export const MAX_ITEMS_TO_SHOW = 3

export const detailsFormatter = (rowsOrCols: any[], skipEmpty = true) => {
  const cols = skipEmpty ? rowsOrCols.filter((val) => val !== '') : rowsOrCols

  return (<Box component='strong'>{cols.splice(0, MAX_ITEMS_TO_SHOW).join(', ')}</Box>)
}

export const getCountLeft = (itemCount: number) => {
  return (<Box component='strong'>{itemCount - MAX_ITEMS_TO_SHOW}</Box>)
}
