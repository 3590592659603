import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'
import {
  Accordion, AccordionSummary,
  Box, Typography,
} from '@mui/material'

import palette from '@configuration/theme/theme.palette'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { DATA_UPLOAD_PATH } from '@constants/routes.constants'
import { USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME } from '@constants/modals.constants'
import { formatDate } from '@utils/moment.utils'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import {
  createFileIdentifierVersionAction, removeFileIdentifierAction,
} from '@redux/modules/training-files/training-files.actions'

import {
  getUseCaseFileIdentifiers, isSubmittingFileIdentifierVersion,
} from '@redux/modules/training-files/training-files.selectors'

import DeleteIcon from '@icons/delete.icon'
import LayoutIcon from '@icons/flow/layout.icon'
import ChevronIcon from '@icons/flow/chevron.icon'
import ButtonComponent from '@base/buttons/Button'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'

export interface UseCaseDataTypeVersionsModalDetails extends Common.ModalDetails {
}

const UseCaseDataTypeVersionsModalContainer: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  const [removeFileIdentifierDialogState, setRemoveFileIdentifierDialogState] = useState(false)

  const { params: { usecase, identifier } } = useRouteMatch<Common.RouterMatch>()

  const modalDetails = useSelector((state) => getModalDetails<UseCaseDataTypeVersionsModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const fileIdentifiersList = useSelector((state) => getUseCaseFileIdentifiers(state))
  const isSubmittingFileIdentifierFlag = useSelector((state) => isSubmittingFileIdentifierVersion(state))

  const fileIdentifierVersions = useMemo(() => {
    const fileIdentifierItem = fileIdentifiersList.find((item) => item.value === identifier)

    return fileIdentifierItem ? fileIdentifierItem.versions : []
  }, [identifier, fileIdentifiersList])

  const open = modalPageName === USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(modalDetails.returnTo || ''),
    )
  }

  const handleChange = (newVersion: number) => (event: React.ChangeEvent<{}>) => {
    trackEvent({
      componentName: 'manageVersionListItem',
      actionName: TRACKING_ACTIONS.NAVIGATE,
    }, {
      useCaseId: usecase,
      fileIdentifier: identifier,
      newVersion,
    })

    history.push(generatePath(DATA_UPLOAD_PATH, { usecase, identifier, version: newVersion }))

    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const handleNewVersionClick = useCallback(() => {
    dispatch(
      createFileIdentifierVersionAction({
        useCaseId: usecase,
        fileIdentifier: identifier,
      }),
    )
  }, [dispatch, usecase, identifier])

  const handleRemoveFileIdentifier = useCallback(() => {
    dispatch(
      removeFileIdentifierAction({
        useCaseId: usecase,
        fileIdentifier: identifier,
      }),
    )
  }, [dispatch, usecase, identifier])

  return (
    <>
      <SidePanelComponent
        open={open}
        hasUnsavedChanges={false}
        title={intl.formatMessage({ id: 'connect.data_input.modal.title' })}
        handleClose={handleModalClose}
        SidePanelIcon={LayoutIcon}
      >
        <SidePanelLoadingComponent loading={false}>
          <SidePanelCardComponent>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              mt={1}
              mb={4}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  color: palette.new.black,
                }}
              >
                {
                  intl.formatMessage({ id: 'connect.data_input.modal.versions.list.title' }, {
                    identifier,
                  })
                }
              </Typography>

              {
                isAdmin ? (
                  <ButtonComponent
                    name='createNewVersionButton'
                    color='primary'
                    onClick={handleNewVersionClick}
                    loading={isSubmittingFileIdentifierFlag}
                    label={intl.formatMessage({ id: 'connect.data_input.modal.versions.new' })}
                  />
                ) : (
                  <Box />
                )
              }
            </Box>
            <Box
              data-testid={UseCaseDataTypeVersionsModalContainer.name}
              mb={5}
            >
              {
                fileIdentifierVersions.map((item, index) => {
                  const {
                    versionNumber,
                    createdAt,
                  } = item

                  return (
                    <Accordion
                      key={versionNumber}
                      elevation={0}
                      expanded={false}
                      onChange={handleChange(versionNumber)}
                      data-testid={`${UseCaseDataTypeVersionsModalContainer.name}-listItem-${index}`}
                    >
                      <AccordionSummary
                        aria-controls={String(versionNumber)}
                        id={String(versionNumber)}
                        expandIcon={<ChevronIcon />}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: index === 0 ? 500 : 400,
                              fontSize: '18px',
                              color: palette.new.black,
                              marginLeft: 1,
                            }}
                          >
                            {intl.formatMessage({ id: 'connect.data_input.modal.versions.list.label' }, { version: versionNumber, date: formatDate(intl, createdAt) })}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  )
                })
              }
            </Box>

            {
              isAdmin ? (
                <ButtonComponent
                  color='tertiary'
                  name='removeFileIdentifierButton'
                  StartIconComponent={DeleteIcon}
                  label={intl.formatMessage({ id: 'connect.block.data_input.table.removeDataTypeDialog.button' })}
                  onClick={() => setRemoveFileIdentifierDialogState(true)}
                  disabled={isSubmittingFileIdentifierFlag}
                />
              ) : (
                null
              )
            }
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='useCaseDataTypeVersionsModalCloseButton'
              onClick={handleModalClose}
              type='back'
              loading={false}
              disabled={false}
            />
          </SidePanelCardActionsComponent>

        </SidePanelLoadingComponent>
      </SidePanelComponent>

      <TextConfirmationDialogComponent
        open={removeFileIdentifierDialogState && isAdmin}
        onClose={() => setRemoveFileIdentifierDialogState(false)}
        onSubmit={() => handleRemoveFileIdentifier()}
        confirmationText={identifier}
        confirmationInputLabel={intl.formatMessage({ id: 'connect.block.data_input.table.removeDataTypeDialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'connect.block.data_input.table.removeDataTypeDialog.content' }, {
            name: <Box component='strong'>{identifier}</Box>,
          })
        }
      />
    </>
  )
}

export default UseCaseDataTypeVersionsModalContainer
