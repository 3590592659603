import React from 'react'

import {
  GridColumnMenu,
  GridColumnMenuColumnsItem,
  GridColumnMenuGroupingItem,
  GridColumnMenuProps,
} from '@mui/x-data-grid-premium'

export interface DataGridCustomColumnMenuComponentProps extends GridColumnMenuProps {
  disableColumnVisibility?: boolean,
  disableGrouping?: boolean,
}

export const DataGridCustomColumnMenuComponent: React.FC<DataGridCustomColumnMenuComponentProps> = ({
  disableColumnVisibility = true,
  disableGrouping = false,
  ...props
}) => {
  return (
    <GridColumnMenu
      data-testid={DataGridCustomColumnMenuComponent.name}
      {...props}
      slots={{
        ...props?.slots,
        columnMenuColumnsItem: disableColumnVisibility ? null : GridColumnMenuColumnsItem,
        columnMenuGroupingItem: disableGrouping ? null : GridColumnMenuGroupingItem,
      }}
    />
  )
}

export default DataGridCustomColumnMenuComponent
