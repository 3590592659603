import React, { useMemo, useRef, useState } from 'react'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { useIntl } from 'react-intl'

import DropdownButtonComponent from '@base/dropdowns/DropdownButton'
import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'
import ParameterSelectorListBoxComponent from '@components/connect-view/parameters/ParameterSelectorListBox'
import { SourceTypeOptionExtended } from '@utils/flow.utils'

import {
  CATEGORIES_TO_LABEL_MAP,
  SourceTypeOption,
  SOURCE_TYPES_CATEGORIES,
  SOURCE_TYPES_TO_DISABLED_ICONS_MAP,
  SOURCE_TYPES_TO_ICONS_MAP,
} from '@constants/flow.constants'

import {
  Popper, InputBase,
  Typography, Box,
  useTheme,
} from '@mui/material'

export interface ParameterSourceSelectorComponentProps extends Omit<AutocompleteProps<any, true, false, false>, 'renderInput' | 'freeSolo'> {
  disabled?: boolean,
  isFetching?: boolean,
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: any, newValue?: any, reason?: any): any,
  value: any,
}

const ParameterSourceSelectorComponent: React.FC<ParameterSourceSelectorComponentProps> = ({
  value,
  isFetching,
  handleChangeCallback,
  options,
  disabled,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const containerRef = useRef<HTMLElement>(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [width, setWidth] = useState(0)
  const inputWidth = `${width}px`

  const handleOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorEl(null)
    }
  }

  const handleChange = (e: React.ChangeEvent<any>, selectedValue: any, newValue?: any, reason?: any) => {
    if (selectedValue) {
      handleChangeCallback && handleChangeCallback(e, selectedValue)
    }

    handleClose()
  }

  const getOptionLabel = (option: SourceTypeOption) => {
    return option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
  }

  const getButtonTitle = () => {
    if (getOptionLabel && value) {
      return getOptionLabel(value)
    } else if (value) {
      return value.name || value.label
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
  }

  const buttonTitle = getButtonTitle()
  const open = Boolean(anchorEl)

  const renderOptionDefault = (props: React.HTMLAttributes<HTMLLIElement>, option: SourceTypeOptionExtended, { selected } : { selected: boolean }) => {
    const label = option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
    const IconComponent = option.isDisabled ? SOURCE_TYPES_TO_DISABLED_ICONS_MAP[option.value] : SOURCE_TYPES_TO_ICONS_MAP[option.value]

    return (
      <Box component='li' {...props}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box
            className='optionIcon'
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: theme.spacing(2),
            }}
          >
            <IconComponent />
          </Box>
          <Typography
            title={label}
            className='optionLabel'
            sx={{
              fontSize: '18px',
              color: selected ? theme.palette.new.violet : theme.palette.new.black,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textTransform: 'none',
              maxWidth: inputWidth,
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    )
  }

  const autocompleteStyles = useMemo(() => {
    return {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      height: theme.spacing(6),
    }
  }, [
    theme,
  ])

  const autocompleteSlotProps = useMemo(() => {
    return {
      paper: {
        sx: {
          minWidth: inputWidth,
          backgroundColor: theme.palette.new.white,
          boxShadow: 'none',
          margin: theme.spacing(0),
          borderRadius: theme.spacing(0),
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          '& .MuiAutocomplete-listbox': {
            maxHeight: '50vh',
          },

          '& .MuiAutocomplete-noOptions': {
            minHeight: theme.spacing(15),
            padding: theme.spacing(0, 3),
            '&:hover': {
              backgroundColor: 'initial',
            },
          },

          '& .MuiAutocomplete-option': {
            height: theme.spacing(6),
            padding: `${theme.spacing(0, 3)} !important`,
            borderTop: `1px solid ${theme.palette.new.grey_a}`,
            '&:first-of-type': {
              borderTop: 'none',
            },
            '&:hover': {
              backgroundColor: theme.palette.new.grey_d,
            },
            '&[aria-selected="true"]': {
              backgroundColor: theme.palette.new.white,
              '& .optionLabel': {
                color: theme.palette.new.violet,
              },
              '&:hover': {
                backgroundColor: theme.palette.new.grey_d,
              },
            },
            '&[data-focus="true"]': {
              backgroundColor: theme.palette.new.grey_d,
            },
            '&[aria-disabled="true"]': {
              opacity: 1,
              '& .optionLabel': {
                color: 'rgba(0, 0, 0, 0.4)',
              },
              '& .optionIcon': {
                opacity: 0.4,
              },
            },
          },
        },
      },
    }
  }, [
    theme,
    inputWidth,
  ])

  return (
    <Box
      ref={containerRef}
      data-testid={ParameterSourceSelectorComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1, 2),
        height: theme.spacing(6),
        border: `1px solid ${theme.palette.new.grey_a}`,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...(disabled ? {
          cursor: 'not-allowed',
          background: theme.palette.new.grey,
        } : {}),
      }}
    >
      <DropdownButtonComponent
        name='autocompleteButton'
        label={buttonTitle}
        onClick={handleOpen}
        open={open}
        disabled={disabled}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        sx={{
          minWidth: inputWidth,
          backgroundColor: theme.palette.new.white,
          border: `1px solid ${theme.palette.new.grey_a}`,
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
          zIndex: 100000,
          marginTop: '-50px !important',
          marginLeft: '-21px !important',

          '& .MuiAutocomplete-popperDisablePortal': {
            minWidth: inputWidth,
            position: 'relative !important' as any,
            transform: 'none !important',
          },
        }}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box
          data-testid={`${ParameterSourceSelectorComponent.name}-autocomplete`}
          sx={{
            minWidth: inputWidth,
          }}
        >
          <Autocomplete
            id='parameterSourceSelector'
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            freeSolo={false}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={value ? [value] : []}
            getOptionLabel={getOptionLabel}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            groupBy={(option) => {
              const category: SOURCE_TYPES_CATEGORIES = option.category

              return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
            }}
            getOptionDisabled={(option) => {
              return option.isDisabled
            }}
            isOptionEqualToValue={(option, secValue) => {
              return option.value === secValue.value
            }}
            renderTags={() => null}
            onClose={handleClose}
            onChange={(event, newValue, reason) => {
              const selectedValue = newValue[newValue.length - 1]

              handleChange(event, selectedValue, newValue, reason)
            }}
            renderGroup={(option) => {
              return (
                <Box component='li' key={option.key}>
                  <Box
                    sx={{
                      display: !option.group ? 'none' : 'flex',
                      height: theme.spacing(6),
                      padding: theme.spacing(0, 7),
                      alignItems: 'flex-end',
                      color: theme.palette.new.black,
                      fontWeight: '500',
                      fontSize: '11px',
                      lineHeight: '40px',
                      letterSpacing: '0.06em',
                      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                      textTransform: 'uppercase',
                      position: 'sticky',
                      top: '0px',
                      width: '100%',
                      zIndex: 10,
                      backgroundColor: theme.palette.new.white,
                    }}
                  >
                    {option.group}
                  </Box>
                  <Box
                    component='ul'
                    sx={{
                      '&:last-child': {
                        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                      },
                    }}
                  >
                    {option.children}
                  </Box>
                </Box>
              )
            }}
            renderOption={renderOptionDefault}
            options={options}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                placeholder={intl.formatMessage({ id: 'connect.modal.parameter.source.placeholder' })}
                sx={{
                  fontSize: '18px',
                  color: theme.palette.new.black,
                  height: theme.spacing(6),
                  padding: theme.spacing(0, 3),
                  width: '100%',
                  '& ::-webkit-search-cancel-button': {
                    height: '18px',
                    width: '18px',
                    borderRadius: 0,
                    background: 'url("/close.svg") no-repeat 50% 50%',
                    WebkitAppearance: 'none',
                    cursor: 'pointer',
                    opacity: 1,
                  },
                }}
              />
            )}
            slotProps={autocompleteSlotProps}
            sx={autocompleteStyles}
          />
        </Box>
      </Popper>
    </Box>
  )
}

export default ParameterSourceSelectorComponent
