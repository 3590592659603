import React from 'react'

export interface InactiveRoundIconIconProps extends Common.IconProps {
}

const InactiveRoundIcon: React.FC<InactiveRoundIconIconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={InactiveRoundIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <circle cx="9" cy="9" r="9" transform="matrix(0 -1 -1 0 18 18)" fill={detailsFill} fillOpacity="0.05"/>
      <circle cx="9" cy="9" r="8.5" transform="matrix(0 -1 -1 0 18 18)" stroke={detailsFill} strokeOpacity="0.2"/>

    </svg>
  )
}

export default InactiveRoundIcon
