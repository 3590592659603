import React, { useMemo } from 'react'
import TooltipComponent from '@base/tooltips/Tooltip'

import {
  Box, Chip, SxProps,
  Theme, useTheme,
} from '@mui/material'

export interface ChipComponentProps {
  /**
   * The label to display in the chip.
   */
  label?: string,
  /**
   * The component to use as the start icon.
   */
  StartIconComponent?: React.ElementType | string,
  /**
   * The component to use as the end icon.
   */
  EndIconComponent?: React.ElementType | string,
  /**
   * The color of the chip.
   * @default business_black_60
   */
  color?: string,
  /**
   * The variant to use.
   */
  variant?: 'outlined' | 'filled',
  /**
   * The tooltip to display when hovering over the chip.
   */
  tooltip?: React.ReactNode,
  /**
   * The placement of the tooltip.
   * @default top
   */
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right',
  /**
   * Text transform to apply to the label.
   */
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none',
  /**
   * Whether the chip is disabled.
   */
  disabled?: boolean,
  /**
   * The function to call when the chip is clicked.
   */
  onClick?: {
    (event: any): void,
  },
  /**
   * The function to call when the end icon is clicked.
   */
  onEndIconClick?: {
    (event: any): void,
  },
  /**
   * Custom styles
   */
  sx?: SxProps<Theme>,
}

const ChipComponent: React.FC<ChipComponentProps> = ({
  label,
  color,
  onClick,
  onEndIconClick,
  StartIconComponent,
  EndIconComponent,
  variant = 'outlined',
  tooltip,
  textTransform,
  tooltipPlacement = 'top',
  disabled,
  sx,
}) => {
  const theme = useTheme()
  const colorToUse = color || theme.palette.new.business_black_60
  const isOutlined = variant === 'outlined'
  const clickable = Boolean(onClick) || Boolean(onEndIconClick)

  const styles = useMemo(() => {
    const finalBgColor = disabled ? theme.palette.new.transparent : colorToUse

    return {
      cursor: (tooltip || Boolean(onClick)) ? 'pointer' : 'default',
      borderRadius: '50px',
      px: '8px',
      py: '2px',
      height: '22px',
      gap: '5px',

      backgroundColor: isOutlined ? theme.palette.new.transparent : finalBgColor,
      border: `0.5px solid ${disabled ? theme.palette.new.business_black_40 : colorToUse}`,
      ...sx,

      '& .MuiChip-label': {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.25,
        textTransform,
        color: isOutlined ? colorToUse : theme.palette.new.white,
        p: 0,
      },
      '& .MuiChip-icon': {
        margin: 0,
      },
      '& .MuiChip-deleteIcon': {
        margin: 0,
        width: '14px',
        height: '14px',
      },
      '&.MuiChip-clickable': {
        '&:hover': {
          backgroundColor: isOutlined ? theme.palette.new.business_black_5 : `${colorToUse}CC`,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: isOutlined ? theme.palette.new.transparent : colorToUse,
          boxShadow: 'none',
        },
        '&:focus-visible': {
          backgroundColor: isOutlined ? theme.palette.new.business_black_5 : `${colorToUse}CC`,
          boxShadow: 'none',
        },
      },
    }
  }, [
    sx,
    disabled,
    onClick,
    isOutlined,
    theme,
    colorToUse,
    tooltip,
    textTransform,
  ])

  const iconDetailsFillColor = useMemo(() => {
    if (disabled) {
      return theme.palette.new.business_black_40
    }

    if (isOutlined) {
      return colorToUse
    }

    return theme.palette.new.white
  }, [theme, colorToUse, isOutlined, disabled])

  const StartIconComponentToUse = useMemo(() => {
    if (StartIconComponent) {
      return (
        <StartIconComponent
          width='14px'
          height='14px'
          detailsFill={iconDetailsFillColor}
        />
      )
    }

    return undefined
  }, [iconDetailsFillColor, StartIconComponent])

  const EndIconComponentToUse = useMemo(() => {
    if (EndIconComponent) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <EndIconComponent
            width='14px'
            height='14px'
            detailsFill={iconDetailsFillColor}
          />
        </Box>
      )
    }

    return undefined
  }, [EndIconComponent, iconDetailsFillColor])

  const content = (
    <Chip
      data-testid={ChipComponent.name}
      size='small'
      label={label}
      onDelete={onEndIconClick}
      clickable={clickable}
      onClick={onClick}
      variant={variant}
      icon={StartIconComponentToUse}
      deleteIcon={EndIconComponentToUse}
      disabled={disabled}
      sx={styles}
    />
  )

  if (tooltip) {
    return (
      <TooltipComponent
        title={tooltip}
        placement={tooltipPlacement}
        describeChild={true}
      >
        {content}
      </TooltipComponent>
    )
  }

  return content
}

export default ChipComponent
