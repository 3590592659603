import React from 'react'
import { Box } from '@mui/material'
import { PARETOS_GRADIENT_20, TOPBAR_HEIGHT } from '@constants/ui.constants'

import LoadingTooLongTextComponent from '@base/loading/LoadingTooLongText'
import LoadingCloudComponent from '@base/loading/LoadingCloud'

export interface LoadingOverlayComponentProps {
  /**
   * Custom min height
   *
   * @default `calc(100vh - ${TOPBAR_HEIGHT}px)`
   */
  minHeight?: string
  /**
   * If true, the loading overlay will be shown
   *
   * @default false
   */
  show?: boolean
  /**
   * If true, the long request note will be shown
   *
   * @default false
   */
  showLongRequestNote?: boolean
}

/**
 * Loading overlay component, displays a loading cloud.
 * Different from the loading fallback component, this component is used to display a loading overlay on top of the content.
 */
const LoadingOverlayComponent: React.FC<LoadingOverlayComponentProps> = ({
  show,
  minHeight = `calc(100vh - ${TOPBAR_HEIGHT}px)`,
  showLongRequestNote,
}) => {
  if (!show) {
    return null
  }

  return (
    <Box
      data-testid={LoadingOverlayComponent.name}
      sx={{
        display: 'flex',
        minHeight,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        left: '0px',
        zIndex: 998,
        width: '100%',
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          minHeight,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          left: '0px',
          zIndex: 999,
          width: '100%',
          background: PARETOS_GRADIENT_20,
        }}
      >
        <LoadingCloudComponent size='large' />

        <LoadingTooLongTextComponent enabled={showLongRequestNote} />
      </Box>
    </Box>
  )
}

export default LoadingOverlayComponent
