import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

const validation = (intl: IntlShape) => {
  return Yup.object().shape({
    artifactId: Yup.string().required(intl.formatMessage({ id: 'use_cases.artifacts.artifactId.required' })),
  })
}

export default validation
