import { takeEvery, put } from 'redux-saga/effects'
import { getHashByModalName } from '@utils/navigation.utils'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/api.utils'
import { ActionPayload } from '@redux/modules/types'
import { changeToastAction } from '@redux/modules/common/common.actions'

import { SET_PRIMARY_MODAL_PAGE_NAME } from './modal-manager.action-types'
import { setPrimaryModalPageNameDone } from './modal-manager.actions'

function* setPrimaryModalPageNameGenerator({ payload } : ActionPayload<Common.ModalPageName>) {
  try {
    const modalHashArg = getHashByModalName(payload)

    if (modalHashArg) {
      window.history.replaceState(null, null!, modalHashArg)
    } else if ((!payload || !modalHashArg) && window.location.hash) {
      window.history.replaceState(null, null!, window.location.origin + window.location.pathname)
    }

    yield put(setPrimaryModalPageNameDone(payload))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

export function* watchDeleteGrouping() {
  yield takeEvery(SET_PRIMARY_MODAL_PAGE_NAME, setPrimaryModalPageNameGenerator)
}
