import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'

import InsightsLineChartComponent from '@components/insights/InsightsLineChart'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { PROMOTION_DAYS } from '@constants/promotions.constants'

export interface InsightsChartComponentProps {
  /**
   * Data grid state. Contains information about the grid filters and rows selection.
   */
  gridState?: Hera.HeraBaseGridState
  /**
   * If 'true', the component is in loading state
   */
  isFetching?: boolean
  /**
   * Data set to be displayed
   */
  dataset: Hera.BaseChartDatasetItem[]
  /**
   * Lines to be displayed
   */
  lines: Hera.BaseChartLineItem[]
  /**
   * Promotion days to be displayed in the chart
   */
  promotionDays?: Hera.BaseChartPromotionItem[]
  /**
   * Annotations to be displayed
   */
  annotations?: Hera.BaseChartAnnotationItem[]
  /**
   * Prefix for the prediction key
   */
  predictionKeyPrefix: string
  /**
   * Prefix for the absolute deviation key
   */
  absDeviationKeyPrefix?: string
  /**
   * Target name to be used in the tooltip
   */
  targetName: string
  /**
   * Target unit to be used in the tooltip
   */
  targetUnit: string
  /**
   * Promotions visibility
   */
  promotionsVisibility?: boolean
  /**
   * Promotion days to exclude
   */
  promotionsToExclude?: PROMOTION_DAYS[]
}

export const INSIGHT_CHART_HEIGHT = '460px'

const InsightsChartComponent: React.FC<InsightsChartComponentProps> = ({
  gridState,
  isFetching,
  dataset = [],
  lines = [],
  annotations = [],
  promotionDays = [],
  targetName,
  targetUnit,
  predictionKeyPrefix,
  absDeviationKeyPrefix,
  promotionsVisibility,
  promotionsToExclude,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const hasGroupingEnabled = useMemo(() => {
    return gridState && gridState.groupingModel && gridState.groupingModel.length > 0
  }, [gridState])

  const selectedRows = useMemo(() => {
    return hasGroupingEnabled && gridState ? (gridState.rowSelectionModel || []) : []
  }, [gridState, hasGroupingEnabled])

  const hasNoSelection = useMemo(() => {
    return ((gridState?.rowSelectionModel || []).length === 0) && (gridState?.rowSelectionModelMode === 'include')
  }, [gridState])

  const disableTodayLine = useMemo(() => {
    if (annotations && annotations.length > 0) {
      return true
    }

    return gridState && gridState.timeWindow && gridState.timeWindow[0] !== null && gridState.timeWindow[1] === null
  }, [gridState, annotations])

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    width: '100%',
    minHeight: INSIGHT_CHART_HEIGHT,
    borderRadius: '7px',
    border: `1px solid ${theme.palette.new.business_black_20}`,
    marginBottom: theme.spacing(2),
  }

  const warpperStypes = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  }

  if (hasNoSelection && !isFetching) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-no-selection`}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noSelection.title' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'insights.chart.noSelection.text' })}
        </Typography>
      </Box>
    )
  }

  if ((!dataset || dataset.length === 0) && !isFetching && gridState) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-no-data`}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noData' })}
        </Typography>
      </Box>
    )
  }

  if ((isFetching || !gridState) && ((lines.length === 0) || (!dataset || dataset.length === 0))) {
    return (
      <Box
        data-testid={`${InsightsChartComponent.name}-loading`}
        sx={{
          width: '100%',
          height: INSIGHT_CHART_HEIGHT,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          border: `1px solid ${theme.palette.new.business_black_20}`,
          marginBottom: theme.spacing(2),
          overflow: 'hidden',
        }}
      >
        <LoadingFallbackComponent fluid={true} size='medium' />
      </Box>
    )
  }

  return (
    <Box
      data-testid={InsightsChartComponent.name}
      sx={containerStyles}
    >
      <InsightsLineChartComponent
        dataset={dataset}
        lines={lines}
        targetName={targetName}
        targetUnit={targetUnit}
        hasGroupingEnabled={hasGroupingEnabled}
        isFetching={isFetching}
        enableTodayLine={!disableTodayLine}
        selectedRows={selectedRows}
        annotations={annotations}
        promotionDays={promotionDays}
        predictionKeyPrefix={predictionKeyPrefix}
        absDeviationKeyPrefix={absDeviationKeyPrefix}
        promotionsVisibility={promotionsVisibility}
        promotionsToExclude={promotionsToExclude}
      />
    </Box>
  )
}

export default InsightsChartComponent
