import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'

import DialogTitleComponent from '@base/dialogs/DialogTitle'
import DialogActionsComponent from '@base/dialogs/DialogActions'
import DialogContentComponent from '@base/dialogs/DialogContent'
import DialogComponent from '@base/dialogs/Dialog'

import ModalButtonComponent from '@base/buttons/ModalButton'
import TextFieldComponent from '@base/forms/TextField'

export interface TextConfirmationDialogProps {
  /**
   * Dialog open state
   */
  open?: boolean,
  /**
   * Text to be confirmed
   */
  confirmationText?: string,
  /**
   * Label for the confirmation input
   */
  confirmationInputLabel?: string,
  /**
   * Dialog title
   */
  title?: string,
  /**
   * Close button label
   */
  closeButtonLabel?: string,
  /**
   * Submit button label
   */
  submitButtonLabel?: string,
  /**
   * Dialog description
   */
  description?: string | React.ReactNode,
  /**
   * Callback to be executed on close
   */
  onClose?: {
    (): void
  },
  /**
   * Callback to be executed on submit
   */
  onSubmit?: {
    (confirmationInputText?: string): void
  },
}

/**
 * This dialog is used to display a confirmation dialog with confirmation input.
 * It is used to confirm a specific action by the user.
 */
const TextConfirmationDialogComponent: React.FC<TextConfirmationDialogProps> = ({
  onSubmit,
  onClose,
  open = false,
  title,
  description,
  submitButtonLabel,
  closeButtonLabel,
  confirmationText = '',
  confirmationInputLabel = '',
}) => {
  const intl = useIntl()
  const [confirmationInputText, setConfirmationInputText] = useState('')
  const isEqual = confirmationText.toLowerCase() === confirmationInputText.toLowerCase()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    setConfirmationInputText('')
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(confirmationInputText)
    }
    handleClose()
  }

  return (
    <DialogComponent
      open={open}
      onClose={handleClose}
      data-testid={TextConfirmationDialogComponent.name}
    >
      <DialogTitleComponent
        title={title || intl.formatMessage({ id: 'common.modal.confirmation.title' })}
      />

      <DialogContentComponent>
        <Typography
          component='div'
          variant='body1'
        >
          {description}
        </Typography>

        <Box my={1}>
          <TextFieldComponent
            name='confirmationInputText'
            value={confirmationInputText}
            label={confirmationInputLabel}
            onBlur={(e) => {
              setConfirmationInputText(e.target.value)
            }}
            onChange={(e) => {
              setConfirmationInputText(e.target.value)
            }}
          />
        </Box>
      </DialogContentComponent>

      <DialogActionsComponent>
        <ModalButtonComponent
          name='textConfirmationDialogCloseButton'
          type='cancel'
          width='100%'
          onClick={handleClose}
          label={closeButtonLabel || intl.formatMessage({ id: 'common.modal.button.cancel' })}
        />
        <ModalButtonComponent
          name='textConfirmationDialogSubmitButton'
          onClick={handleSubmit}
          type='submit'
          width='100%'
          disabled={!isEqual}
          label={submitButtonLabel || intl.formatMessage({ id: 'common.modal.button.delete' })}
        />
      </DialogActionsComponent>
    </DialogComponent>
  )
}

export default TextConfirmationDialogComponent
