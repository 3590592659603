import {
  REQUEST_DETAILED_CONNECTIONS,
  REQUEST_TRANSFORMATIONS,
  REQUEST_CONNECTIONS,
  REQUEST_SOURCES,
  REQUEST_PUBLISHER,
  REQUEST_PUBLISHERS_LIST,
  CREATE_TRANSFORMATION,
  UPDATE_TRANSFORMATION,
  REMOVE_TRANSFORMATION,
  MODIFY_TRANSFORMATION,
  CREATE_PUBLISHER,
  UPDATE_PUBLISHER,
  REMOVE_PUBLISHER,
  REQUEST_ARTIFACTS_EXPORT_DETAILS,
  REQUEST_REMOVE_CONNECTION,
} from '@redux/modules/hermes/hermes.action-types'

import { State } from '@redux/modules/types'

export const getUseCaseTransformations = (state: State) => {
  return state.hermes.transformations
}

export const getCompanyConnections = (state: State) => {
  return state.hermes.connections
}

export const getPublisher = (state: State) => {
  return state.hermes.publisher
}

export const getPublishers = (state: State) => {
  return state.hermes.publishers
}

export const getCompanyDetailedConnections = (state: State) => {
  return state.hermes.detailedConnectionsPerUseCase
}

export const getCompanyConnectionsBySource = (state: State, sourceId?: string) => {
  if (!sourceId) {
    return state.hermes.detailedConnectionsPerUseCase
  }

  return state.hermes.detailedConnectionsPerUseCase.filter((connection) => connection.sourceId === sourceId)
}

export const getAvailableSources = (state: State) => {
  return state.hermes.sources
}

export const getAvailableDestinations = (state: State) => {
  return state.hermes.destinations
}

export const isFetching = (state: State) => {
  const keys = [
    REQUEST_PUBLISHER,
    REQUEST_PUBLISHERS_LIST,
    REQUEST_SOURCES,
    REQUEST_CONNECTIONS,
    REQUEST_TRANSFORMATIONS,
    REQUEST_DETAILED_CONNECTIONS,
    REQUEST_ARTIFACTS_EXPORT_DETAILS,
  ]

  return state.hermes.fetchingKeys.some((key) => keys.includes(key))
}

export const isSubmittingHermes = (state: State) => {
  const keys = [
    CREATE_TRANSFORMATION,
    UPDATE_TRANSFORMATION,
    REMOVE_TRANSFORMATION,
    MODIFY_TRANSFORMATION,
    CREATE_PUBLISHER,
    UPDATE_PUBLISHER,
    REMOVE_PUBLISHER,
    REQUEST_REMOVE_CONNECTION,
  ]

  return state.hermes.fetchingKeys.some((key) => keys.includes(key))
}

export const getArtifactsExportDetails = (state: State) => {
  return state.hermes.exportDetails || {}
}

export const getArtifactsExportUrl = (state: State) => {
  return state.hermes.exportDetails?.spreadsheetId || ''
}
