import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import posthog from 'posthog-js'

import { PostHogProvider } from 'posthog-js/react'
import { LicenseInfo } from '@mui/x-license'
import { initTracking } from '@utils/tracking.utils'
import { initReduxAndRouter } from '@utils/redux.utils'
import { MUI_X_LICENSE_KEY } from '@constants/env-replacements.constants'

import '@styles/index.css'

import AppContainer from '@containers/application/App'

/** Init tracking */
initTracking()

/** Init MUI X License */
LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY)

/** Init Redux & Router */
const { store, reduxHistory } = initReduxAndRouter()

/* Prepare Application */
const runApp = (RootApp: any) => {
  const root = ReactDOMClient.createRoot(document.getElementById('root')!)

  return root.render(
    <PostHogProvider client={posthog}>
      <RootApp
        store={store}
        reduxHistory={reduxHistory}
      />
    </PostHogProvider>,
  )
}

/* Run Application */
runApp(AppContainer)
