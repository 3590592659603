import { useIntl } from 'react-intl'
import { useDispatch } from '@redux/hooks'
import { changeToastAction } from '@redux/modules/common/common.actions'

export const useDataGridOnClipboardCopy = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const onClipboardCopy = () => {
    dispatch(
      changeToastAction({
        message: intl.formatMessage({ id: 'common.copyToClipboard.successful' }),
        severity: 'success',
      }),
    )
  }

  return onClipboardCopy
}
