import { FILE_BROWSER_SORTING_OPTIONS, FILE_BROWSER_SORTING_ORDER_OPTIONS } from '@constants/files.constants'
import { getFileExtension } from '@utils/files.utils'

export const addItemToFileTree = (
  tree: FileService.BrowserTreeNode,
  path: string,
  fileMeta: FileService.ReduxFileItem,
  sortingKey = FILE_BROWSER_SORTING_OPTIONS.NAME,
  sortingOrder = FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC,
) => {
  const parts = path.split('/')

  let current = tree

  for (const part of parts) {
    if (!current.children) {
      current.children = []
    }

    const existingNode = current.children.find((node) => node.name === part)

    if (existingNode) {
      current = existingNode
    } else {
      const isDirectory = !part.includes('.')
      const newNode: FileService.BrowserTreeNode = {
        name: part,
        url: path,
        isDirectory,
        children: [],
        filesCount: 0,
        directoriesCount: 0,
        fileExtension: getFileExtension(part, isDirectory),
        sizeInBytes: isDirectory ? undefined : fileMeta.sizeInBytes,
        createdAt: isDirectory ? undefined : fileMeta.createdAt,
      }

      const index = current.children.findIndex((child) => naturalSort(child, newNode, sortingKey, sortingOrder) > 0)

      if (index === -1) {
        current.children.push(newNode)
      } else {
        current.children.splice(index, 0, newNode)
      }

      current = newNode
    }
  }
}

export const countItemsInTree = (node: FileService.BrowserTreeNode) => {
  if (node.children) {
    /* eslint-disable no-param-reassign */
    node.filesCount = node.children.filter((child) => !child.isDirectory).length
    node.directoriesCount = node.children.filter((child) => child.isDirectory).length

    for (const child of node.children) {
      countItemsInTree(child)

      node.filesCount += child.filesCount
      node.directoriesCount += child.directoriesCount
    }
    /* eslint-enable no-param-reassign */
  }

  return node
}

export const fileListToTree = (
  fileList: FileService.ReduxFileItem[],
  sortingKey = FILE_BROWSER_SORTING_OPTIONS.NAME,
  sortingOrder = FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC,
) => {
  const root: FileService.BrowserTreeNode = {
    name: 'root',
    isDirectory: true,
    url: '',
    children: [],
    filesCount: fileList.length,
    directoriesCount: 0,
    fileExtension: undefined,
    sizeInBytes: undefined,
    createdAt: undefined,
  }

  for (const file of fileList) {
    addItemToFileTree(root, file.path, file, sortingKey, sortingOrder)
  }

  return countItemsInTree(root)
}

export const selectNodeByRoute = (tree: FileService.BrowserTreeNode, route: string) => {
  const routeParts = route ? route.split('/').filter((part) => part.trim() !== '') : []

  if (routeParts.length === 0) {
    return tree
  }

  const findMatchingNodes = (nodes: FileService.BrowserTreeNode[], name: string) => {
    return nodes.filter((node) => node.name === name)
  }

  const findMatchingNodeRecursive = (currentNode: FileService.BrowserTreeNode, remainingParts: string[]): FileService.BrowserTreeNode | null => {
    if (remainingParts.length === 0) {
      return currentNode
    }

    const nextPart = remainingParts[0]
    const remainingPartsTail = remainingParts.slice(1)
    const matchingNodes = findMatchingNodes(currentNode.children || [], nextPart)

    for (const matchingNode of matchingNodes) {
      const result = findMatchingNodeRecursive(matchingNode, remainingPartsTail)

      if (result) {
        return result
      }
    }

    return null
  }

  return findMatchingNodeRecursive(tree, routeParts)
}

export const naturalSort = (
  a: FileService.BrowserTreeNode, b: FileService.BrowserTreeNode,
  sortingKey: FILE_BROWSER_SORTING_OPTIONS, sortingOrder: FILE_BROWSER_SORTING_ORDER_OPTIONS,
) => {
  if (sortingKey === FILE_BROWSER_SORTING_OPTIONS.SIZE) {
    const valAInBytes = a[sortingKey] || 0
    const valBInBytes = b[sortingKey] || 0

    return sortingOrder === FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC ? valBInBytes - valAInBytes : valAInBytes - valBInBytes
  }

  const shouldReverse = sortingKey === FILE_BROWSER_SORTING_OPTIONS.CREATED_AT
  const valA = (shouldReverse ? b[sortingKey] : a[sortingKey]) || ''
  const valB = (shouldReverse ? a[sortingKey] : b[sortingKey]) || ''

  return sortingOrder === FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC ? valA.localeCompare(valB, undefined, {
    numeric: true,
    sensitivity: 'base',
  }) : valB.localeCompare(valA, undefined, {
    numeric: true,
    sensitivity: 'base',
  })
}
