import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'

import DialogTitleComponent from '@base/dialogs/DialogTitle'
import DialogActionsComponent from '@base/dialogs/DialogActions'
import DialogContentComponent from '@base/dialogs/DialogContent'
import DialogComponent from '@base/dialogs/Dialog'
import ModalButtonComponent from '@base/buttons/ModalButton'

export interface FormDialogProps {
  /**
   * Dialog title
   */
  title: string,
  /**
   * Dialog open state
   */
  open?: boolean,
  /**
   * Close button label
   */
  closeButtonLabel?: string,
  /**
   * Submit button label
   */
  submitButtonLabel?: string,
  /**
   * If submit button should be disabled
   */
  disableSubmitButton?: boolean,
  /**
   * Dialog description
   */
  description?: string | React.ReactNode,
  /**
   * Children to be rendered
   */
  children?: React.ReactNode,
  /**
   * Callback to be executed on close
   */
  onClose?: {
    (): void
  },
  /**
   * Callback to be executed on submit
   */
  onSubmit?: {
    (): void
  },
}

/**
 * This dialog is used to display a form with a submit and close button.
 * It does not close on ESC and backdrop click.
 */
const FormDialogComponent: React.FC<FormDialogProps> = ({
  onSubmit,
  onClose,
  open = false,
  title,
  children,
  description,
  submitButtonLabel,
  disableSubmitButton,
  closeButtonLabel,
}) => {
  const intl = useIntl()

  const handleClose = (e: React.SyntheticEvent, reason?: any) => {
    if (reason === 'backdropClick') {
      return
    }

    if (onClose) {
      onClose()
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    if (onSubmit) {
      onSubmit()
    }

    handleClose(e)
  }

  return (
    <DialogComponent
      open={open}
      onClose={handleClose}
      data-testid={FormDialogComponent.name}
      disableEnforceFocus={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitleComponent
        title={title}
      />

      <DialogContentComponent>
        {
          description ? (
            <Typography
              component='div'
              variant='body1'
            >
              {description}
            </Typography>
          ) : (
            null
          )
        }

        <Box my={2}>
          {children}
        </Box>
      </DialogContentComponent>

      <DialogActionsComponent>
        <ModalButtonComponent
          name='formDialogCloseButton'
          type='cancel'
          width='100%'
          onClick={handleClose}
          label={closeButtonLabel || intl.formatMessage({ id: 'common.modal.button.cancel' })}
        />
        <ModalButtonComponent
          name='formDialogSubmitButton'
          onClick={handleSubmit}
          type='submit'
          width='100%'
          disabled={disableSubmitButton}
          label={submitButtonLabel || intl.formatMessage({ id: 'common.modal.button.submit' })}
        />
      </DialogActionsComponent>
    </DialogComponent>
  )
}

export default FormDialogComponent
