import React from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Position } from '@xyflow/react'
import { Box, useTheme } from '@mui/material'

import { getGroupingAttributesWidth, getGroupingAttributesHeigth } from '@utils/flow.utils'
import { createId } from '@utils/common.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'
import { requestGroupingOverviewAction } from '@redux/modules/parameters/parameters.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { ConnectParameterCreationModalDetails } from '@containers/modals/connect-parameter-creation-modal/ConnectParameterCreationModal.container'

import {
  SOURCE_TYPES, GROUPING_ATTRIBUTES_PER_ROW,
  INPUT_TYPES, TRIPPLE_CONNECTOR_STYLES,
} from '@constants/flow.constants'

import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import ReactFlowParameterBlockComponent, { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

export interface ReactFlowGroupingAttributesNodeComponentProps {
  data?: {
    numOfInputTypes?: number,
  }
}

const ReactFlowGroupingAttributesNodeComponent: React.FC<ReactFlowGroupingAttributesNodeComponentProps> = ({ data }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const groupingAttributes = (useCase.groupingAttributes || [])
  const { numOfInputTypes = 0 } = data || {}

  const numOfNodes = groupingAttributes.length
  const groupingAttributesChunks = []
  const isAddButtonAvailable = Boolean(numOfNodes)

  for (let i = 0; i < groupingAttributes.length; i += GROUPING_ATTRIBUTES_PER_ROW) {
    const chunk = groupingAttributes.slice(i, i + GROUPING_ATTRIBUTES_PER_ROW)
    groupingAttributesChunks.push(chunk)
  }

  const flowHandlesSource = []
  const flowHandlesTarget = []

  for (let i = 0; i < numOfInputTypes; i += 1) {
    flowHandlesSource.push((
      <ReactFlowHandleComponent
        type='source'
        position={Position.Right}
        id={`source_${i + 1}`}
        key={`source_${i + 1}`}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
      />
    ))

    flowHandlesTarget.push((
      <ReactFlowHandleComponent
        type='target'
        position={Position.Left}
        id={`target_${i + 1}`}
        key={`target_${i + 1}`}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
      />
    ))
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_PARAMETER_CREATION_MODAL_NAME,
        modalDetails: {
          parameterType: INPUT_TYPES.GROUPING,
          returnTo: '',
        } as ConnectParameterCreationModalDetails,
      }),
    )
  }

  const onParameterClick = (parameterItem: UseCase.GroupingParameter) => {
    dispatch(
      requestGroupingOverviewAction(parameterItem),
    )
  }

  return (
    <Box
      data-testid={ReactFlowGroupingAttributesNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: getGroupingAttributesWidth(numOfNodes),
        height: getGroupingAttributesHeigth(numOfNodes),
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: theme.palette.new.grey_a,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
      }}
    >
      {flowHandlesSource}

      <Box
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.grouping' })}
          help={intl.formatMessage({ id: 'connect.block.grouping.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isAddButtonAvailable}
          parentComponentName={ReactFlowGroupingAttributesNodeComponent.name}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            '& .groupingAttributesChunk': {
              marginRight: theme.spacing(2),
            },
            '& :last-child': {
              marginRight: 0,
            },
          }}
        >
          {
            groupingAttributesChunks.map((chunk, index) => {
              return (
                <Box
                  className='groupingAttributesChunk'
                  key={createId(index, 'chunk')}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  {
                    chunk.map((item, chunkIndex) => {
                      const {
                        name,
                        sourceType,
                        filterValues,
                        unitLabel,
                        groupingAttributeId,
                      } = item

                      return (
                        <ReactFlowParameterBlockComponent
                          key={createId(index, name, groupingAttributeId)}
                          name={name}
                          sourceType={sourceType as SOURCE_TYPES}
                          unitLabel={unitLabel}
                          filterValues={filterValues}
                          type={PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE}
                          onClick={() => onParameterClick(item)}
                          dataTestId={`${ReactFlowParameterBlockComponent.name}-${ReactFlowGroupingAttributesNodeComponent.name}-${index}`}
                        />
                      )
                    })
                  }
                </Box>
              )
            })
          }
        </Box>
      </Box>

      {flowHandlesTarget}
    </Box>
  )
}

export default ReactFlowGroupingAttributesNodeComponent
