import React from 'react'

export interface CheckedSquareIconProps extends Common.IconProps {
}

const CheckedSquareIcon: React.FC<CheckedSquareIconProps> = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  fill = 'none',
  className = '',
  detailsFill = '#5F26E0',
}) => {
  return (
    <svg data-testid={CheckedSquareIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M1.47656 4.1731C1.47656 2.51624 2.81971 1.1731 4.47656 1.1731H16.4766C18.1334 1.1731 19.4766 2.51624 19.4766 4.1731V16.1731C19.4766 17.83 18.1334 19.1731 16.4766 19.1731H4.47656C2.81971 19.1731 1.47656 17.83 1.47656 16.1731V4.1731Z" fill={detailsFill} />
      <path d="M1.47656 4.1731C1.47656 2.51624 2.81971 1.1731 4.47656 1.1731H16.4766C18.1334 1.1731 19.4766 2.51624 19.4766 4.1731V16.1731C19.4766 17.83 18.1334 19.1731 16.4766 19.1731H4.47656C2.81971 19.1731 1.47656 17.83 1.47656 16.1731V4.1731Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path className='checkedSquareIconCheckMark' d="M14.5147 7.05774L8.97626 13.9808L6.20703 11.9039" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CheckedSquareIcon
