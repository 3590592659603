import React, {
  useCallback, useMemo,
} from 'react'

import {
  Box, ListItemText,
  Typography, useTheme,
} from '@mui/material'

import CopyButtonComponent from '@base/buttons/CopyButton'
import ChevronRightIcon from '@icons/chevronRight.icon'
import HomeIcon from '@icons/home.icon'

import { FILE_MANAGER_FOLDER_PATH, FILE_MANAGER_PATH } from '@constants/routes.constants'
import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

export interface FileBrowserBreadcrumbsComponentProps {
  /**
   * Use Case file storage bucket
   */
  fileStorageBucket: string,
}

const FileBrowserBreadcrumbsComponent: React.FC<FileBrowserBreadcrumbsComponentProps> = ({
  fileStorageBucket,
}) => {
  const history = useHistory()
  const match = useRouteMatch<Common.FileBrowserRouterMatch>()
  const theme = useTheme()

  const breadcrumbs = useMemo(() => {
    return (match.params[0] ? match.params[0].split('/') : []).filter((breadcrumb) => breadcrumb)
  }, [match])

  const handleItemClick = useCallback((breadcrumbIndex: number) => {
    const newPath = generatePath(FILE_MANAGER_FOLDER_PATH, {
      // @ts-ignore
      0: breadcrumbs.slice(0, breadcrumbIndex + 1).join('/'),
      usecase: match.params.usecase,
    })

    history.push(newPath)

    return null
  }, [history, breadcrumbs, match])

  const handleRootClick = useCallback(() => {
    history.push(generatePath(FILE_MANAGER_PATH, {
      usecase: match.params.usecase,
    }))
  }, [history, match])

  const itemStyles = {
    cursor: 'pointer',
    py: 0,
    px: DEFAULT_PADDING.MEDIUM,
    borderRadius: DEFAULT_BORDER_RADIUS.MEDIUM,
    height: '36px',
    maxHeight: '36px',
    backgroundColor: 'rgba(96, 49, 200, 0.06)',
    '&:hover': {
      backgroundColor: 'rgba(96, 49, 200, 0.08)',
    },
  }

  return (
    <Box
      data-testid={FileBrowserBreadcrumbsComponent.name}
      overflow='hidden'
      display='inline-flex'
      alignItems='center'
      maxWidth='100%'
      mr={2}
    >
      <Box
        display='inline-flex'
        alignItems='center'
        flexWrap='wrap'
        gap={0.5}
      >
        <Box
          display='inline-flex'
          alignItems='center'
          onClick={handleRootClick}
          sx={{
            ...itemStyles,
            minWidth: 'fit-content',
          }}
        >
          <HomeIcon />

          <Typography
            variant='body2'
            component='span'
            fontWeight={500}
            noWrap={true}
            sx={{
              mx: 1,
              pr: 1,
              borderRight: `1px solid ${theme.palette.new.business_black_20}`,
            }}
          >
            {fileStorageBucket}
          </Typography>

          <CopyButtonComponent
            value={fileStorageBucket}
            iconOnly={true}
          />
        </Box>

        {
          breadcrumbs.map((breadcrumb, index) => {
            return (
              <Box
                key={breadcrumb}
                display='inline-flex'
                alignItems='center'
              >
                <Box
                  display='inline-flex'
                  alignItems='center'
                  mx={0}
                  mr='5px'
                  maxHeight='36px'
                >
                  <ChevronRightIcon
                    detailsFill='black'
                    width={12}
                  />
                </Box>

                <Box
                  display='inline-flex'
                  alignItems='center'
                  onClick={() => handleItemClick(index)}
                  sx={{
                    ...itemStyles,
                    maxWidth: '150px',
                  }}
                >
                  <ListItemText
                    title={breadcrumb}
                    primary={breadcrumb}
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: 'body2',
                    }}
                  />
                </Box>
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default FileBrowserBreadcrumbsComponent
