import React, { SyntheticEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import {
  Box, useTheme,
  Typography, styled,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import EditFlowIcon from '@icons/flow/editFlow.icon'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'

import {
  SOURCE_TYPES_OPTIONS,
  SOURCE_TYPES_TO_ICONS_MAP,
  SourceTypeOption,
  NO_UNIT_LABEL_PLACEHOLDER,
} from '@constants/flow.constants'

import { createId } from '@utils/common.utils'
import HelpButtonComponent from '@base/buttons/HelpButton'
import IconButtonComponent from '@base/buttons/IconButton'
import AddFlowIcon from '@icons/flow/addFlow.icon'

import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getParametersTemplatesList, isFetching } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { LIST_PREDIFINED_PARAMETER_MODAL_NAME, MANAGE_PREDIFINED_PARAMETER_MODAL_NAME } from '@constants/modals.constants'
import { ManagePredefinedParametersModalDetails } from '@containers/modals/manage-predefined-parameters-modal/ManagePredefinedParametersModal.container'

const StyledListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  height: theme.spacing(6),
  padding: theme.spacing(0, 4),
  borderTop: `1px solid ${theme.palette.new.grey_a}`,
  '&:hover': {
    backgroundColor: theme.palette.new.grey_d,
  },
  '&:first-of-type': {
    borderTop: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const StyledListItemValue = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.new.black,
  width: '100%',
  paddingRight: theme.spacing(1),
}))

const StyledListHeaderItem = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.new.grey_c,
  width: '100%',
  paddingRight: theme.spacing(1),
}))

const ListPredefinedParametersModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const theme = useTheme()

  const requestParametersTemplates = bindActionCreators(requestParametersTemplatesAction, dispatch)
  const isFetchingList = useSelector((state) => isFetching(state))
  const parametersTemplatesList = useSelector((state) => getParametersTemplatesList(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === LIST_PREDIFINED_PARAMETER_MODAL_NAME
  const isParametersTemplatesListEmpty = (!parametersTemplatesList || parametersTemplatesList.length === 0)

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: MANAGE_PREDIFINED_PARAMETER_MODAL_NAME,
        modalDetails: {
          returnTo: LIST_PREDIFINED_PARAMETER_MODAL_NAME,
        } as ManagePredefinedParametersModalDetails,
      }),
    )
  }

  const onEditButtonClick = (e: SyntheticEvent, parameterPresetId: string) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: MANAGE_PREDIFINED_PARAMETER_MODAL_NAME,
        modalDetails: {
          returnTo: LIST_PREDIFINED_PARAMETER_MODAL_NAME,
          parameterPresetId,
        } as ManagePredefinedParametersModalDetails,
      }),
    )
  }

  useEffect(() => {
    if (open) {
      requestParametersTemplates()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  if (!isAdmin) {
    return null
  }

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'connect.modal.predifined_parameters' })}
      handleClose={handleModalClose}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.predifined_parameters.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetchingList}>
        <SidePanelCardComponent disableHorizontalSpacing={true}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: theme.spacing(4),
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {intl.formatMessage({ id: 'connect.modal.predifined_parameters.header' })}
            </Typography>

            <IconButtonComponent
              name='addPredefinedParametersButton'
              label='Add'
              IconComponent={AddFlowIcon}
              onClick={onAddButtonClick}
              color='primary'
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: theme.spacing(0),
              width: '100%',
              '& .listItem:last-child': {
                borderBottom: `1px solid ${theme.palette.new.grey_a}`,
              },
            }}
          >
            <StyledListItem
              className='listItem'
            >
              <StyledListHeaderItem
                noWrap={true}
                sx={{
                  marginLeft: theme.spacing(4),
                }}
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.data_source' })}
              </StyledListHeaderItem>
              <StyledListHeaderItem
                noWrap={true}
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.data_column_name' })}
              </StyledListHeaderItem>
              <StyledListHeaderItem
                noWrap={true}
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.name' })}
              </StyledListHeaderItem>
              <StyledListHeaderItem
                noWrap={true}
                sx={{
                  marginRight: theme.spacing(0),
                  paddingRight: theme.spacing(0),
                }}
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.unit' })}
              </StyledListHeaderItem>
            </StyledListItem>

            {
              isParametersTemplatesListEmpty ? (
                <Typography
                  noWrap={true}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderTop: `1px solid ${theme.palette.new.grey_a}`,
                    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                    color: theme.palette.new.grey_c,
                    width: '100%',
                    height: theme.spacing(6),
                    padding: theme.spacing(0, 4),
                  }}
                >
                  {intl.formatMessage({ id: 'connect.modal.predifined_parameters.empty' })}
                </Typography>
              ) : (
                null
              )
            }

            {
              parametersTemplatesList.map((item, index) => {
                const {
                  name, dataColumnName,
                  sourceType, unitLabel,
                  parameterPresetId,
                } = item

                const dataSourceOption = (SOURCE_TYPES_OPTIONS.find((sourceTypeItem) => sourceTypeItem.value === sourceType) || {}) as SourceTypeOption
                const dataSourceLabelKey = dataSourceOption.labelKey
                const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[dataSourceOption.value]
                const dataSourceName = dataSourceLabelKey ? intl.formatMessage({ id: dataSourceLabelKey }) : ''

                return (
                  <StyledListItem
                    className='listItem'
                    onClick={(e) => onEditButtonClick(e, parameterPresetId)}
                    key={createId(name, index)}
                  >
                    <Box
                      sx={{
                        minWidth: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <IconComponent />
                    </Box>
                    <StyledListItemValue
                      noWrap={true}
                      title={dataSourceName}
                    >
                      {dataSourceName}
                    </StyledListItemValue>
                    <StyledListItemValue
                      noWrap={true}
                      title={dataColumnName || ''}
                    >
                      {dataColumnName}
                    </StyledListItemValue>
                    <StyledListItemValue
                      noWrap={true}
                      title={name}
                    >
                      {name}
                    </StyledListItemValue>
                    <StyledListItemValue
                      noWrap={true}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: theme.spacing(15),
                        justifyContent: 'space-between',
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        color: theme.palette.new.grey_c,
                        paddingRight: theme.spacing(0),
                      }}
                    >
                      {unitLabel || NO_UNIT_LABEL_PLACEHOLDER}

                      <IconButtonComponent
                        name='listPredefinedParametersEditButton'
                        label='Edit'
                        IconComponent={EditFlowIcon}
                        onClick={(e) => onEditButtonClick(e, parameterPresetId)}
                        color='tertiary'
                        sx={{
                          marginLeft: theme.spacing(3),
                        }}
                      />
                    </StyledListItemValue>
                  </StyledListItem>
                )
              })
            }
          </Box>
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='listPredefinedParametersModalCloseButton'
            onClick={handleModalClose}
            type='back'
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ListPredefinedParametersModalContainer
