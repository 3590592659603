import React from 'react'

const BingIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={BingIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M3.00004 0L6.59567 1.26491V13.9215L11.6603 10.9978L9.1772 9.83279L7.61067 5.93376L15.5906 8.73724V12.8131L6.59768 18L3 15.9988L3.00004 0Z" fill="#008373"/>

    </svg>
  )
}

export default BingIcon
