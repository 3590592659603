import React from 'react'
import { useIntl } from 'react-intl'

import {
  Box, Container,
  Typography, useTheme,
} from '@mui/material'

const NotFoundErrorComponent = () => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'
      data-testid={NotFoundErrorComponent.name}
      sx={{
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
      }}
    >
      <Container maxWidth='md'>
        <Typography
          align='center'
          color='textPrimary'
          variant='h2'
          gutterBottom={true}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {intl.formatMessage({ id: 'common.notFound.title' })}
        </Typography>

        <Box component='br' />

        <Typography
          align='center'
          color='textPrimary'
          variant='subtitle1'
        >
          <Box
            component='span'
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'common.notFound.content' }),
            }}
          />
        </Typography>
      </Container>
    </Box>
  )
}

export default NotFoundErrorComponent
