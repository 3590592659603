import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@mui/material'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-premium'
import { getDataGridId } from '@utils/data-grid.utils'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getColumnsConfigFromSchema } from '@utils/recommendation-artifacts.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import VisualizationItemComponent from '@components/recommendation-artifacts/VisualizationItem'
import ArticleBreakdownComponent from '@components/recommendation-artifacts/ArticleBreakdown'
import KPIItemComponent from '@components/recommendation-artifacts/KPIItem'
import SectionTileComponent from '@base/tiles/SectionTile'
import useGridInitialState from '@hooks/useGridInitialState.hook'

export interface ArticlesOverviewComponentProps {
  /**
   * Flag to indicate if the table is loading
   */
  loadingTable?: boolean,
  /**
   * Flag to indicate if the details are loading
   */
  loadingDetails?: boolean,
  /**
   * Flag to indicate if the comparison mode is enabled
   */
  comparisonMode?: boolean,
  /**
   * Table definition
   */
  table?: RecommendationArtifacts.ArticlesOverviewTableArtifactProcessed['table'],
  /**
   * Meta data for the table
   */
  metaData: RecommendationArtifacts.ArticlesOverviewTableArtifactProcessed['metaData'],
  /**
   * Visualizations to be displayed
   */
  visualizations?: RecommendationArtifacts.ArticlesOverviewDetailsArtifact['visualisations'],
  /**
   * KPI items to be displayed
   */
  kpiItems?: RecommendationArtifacts.ArticlesOverviewDetailsArtifact['kpiItems'],
  /**
   * Breakdown to be displayed
   */
  breakdown?: RecommendationArtifacts.ArticlesOverviewDetailsArtifact['breakdown'],
  /**
   * ID of selected item in the table
   */
  selectedItem: string | number
  /**
   * Function to handle item selection
   *
   * @param itemId selected item
   */
  handleSelect: (itemId: string | number) => void,
}

const ArticlesOverviewComponent: React.FC<ArticlesOverviewComponentProps> = ({
  loadingTable = false,
  loadingDetails = false,
  comparisonMode = false,
  visualizations = [],
  kpiItems = [],
  breakdown = {} as RecommendationArtifacts.ArticlesOverviewDetailsArtifact['breakdown'],
  table = {} as RecommendationArtifacts.ArticlesOverviewTableArtifactProcessed['table'],
  metaData = {} as RecommendationArtifacts.ArticlesOverviewTableArtifactProcessed['metaData'],
  handleSelect,
  selectedItem,
}) => {
  const {
    title,
    rowIdentifierKey,
    tableInstructions,
  } = metaData

  const intl = useIntl()
  const apiRef = useGridApiRef()
  const tableId = getDataGridId(DATA_GRIDS.OPTIMIZATION_CONSTRAINTS_ANALYZE_OVERVIEW, 1)

  const initialState = useGridInitialState(tableId, {
    ...table.gridInitialState,
    pagination: {
      paginationModel: {
        pageSize: 10,
      },
    },
  })
  const columns = getColumnsConfigFromSchema(table.columns)

  useEffect(() => {
    if (apiRef.current && apiRef.current.setColumnVisibility && table.metaData) {
      table.metaData.actualValueColumnNames.forEach((column) => {
        apiRef.current.setColumnVisibility(column, comparisonMode)
      })
    }
  }, [comparisonMode, apiRef, table])

  const isEmpty = ((table.rows.length === 0)) && !loadingTable
  const rowSelectionModel = selectedItem === null ? [] : [selectedItem]

  const onRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    if (newRowSelectionModel.length === 0 && selectedItem !== null) {
      // no-op, disallow unselecting the row
    } else if (newRowSelectionModel.length === 0) {
      // no-op, disallow unselecting the row
    } else {
      handleSelect(String(newRowSelectionModel[newRowSelectionModel.length - 1]))
    }
  }

  if (table.rows.length === 0 && !loadingTable) {
    return null
  }

  return (
    <SectionTileComponent
      name='articlesOverview'
      loading={loadingTable}
      noData={isEmpty}
      contentMinHeight='600px'
      title={title}
      container={true}
    >
      <Grid
        item={true}
        xs={12}
      >
        <SectionTileComponent
          name={DATA_GRIDS.ARTICLES_OVERVIEW_TABLE_ARTIFACT}
          title={tableInstructions}
          contentType='data-grid'
          noData={table.rows.length === 0 && !loadingTable}
          loading={loadingTable || !initialState}
          item={true}
        >
          <DataGridComponent
            id={DATA_GRIDS.ARTICLES_OVERVIEW_TABLE_ARTIFACT}
            name={DATA_GRIDS.ARTICLES_OVERVIEW_TABLE_ARTIFACT}
            columns={columns}
            apiRef={apiRef}
            rows={table.rows}
            getRowId={(row) => row[rowIdentifierKey] || row.id}
            initialState={initialState}
            rounded={false}
            autoHeight={true}
            disableRowGrouping={true}
            checkboxSelection={true}
            disableMultipleRowSelection={true}
            disableRowSelectionOnClick={false}
            hideFooterSelectedRowCount={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            slotProps={{
              toolbar: {
                withExport: false,
              },
              baseCheckbox: {
                disabledHint: intl.formatMessage({ id: 'recommendation.discover.radio.no_data' }),
                hintPlacement: 'bottom',
              },
            }}
          />
        </SectionTileComponent>
      </Grid>

      {
        kpiItems.map((kpiItem, index) => {
          return (
            <Grid
              item={true}
              xs={6}
              sm={6}
              md={6}
              lg={3}
              key={index}
            >
              <KPIItemComponent
                key={index}
                item={kpiItem}
                comparisonMode={comparisonMode}
                loading={loadingDetails}
              />
            </Grid>
          )
        })
      }

      {
        visualizations.map((visualization, index) => {
          return (
            <Grid
              item={true}
              xs={12}
              sm={12}
              key={index}
            >
              <VisualizationItemComponent
                key={index}
                id={`results-visualization-${index}`}
                visualization={visualization}
                comparisonMode={comparisonMode}
                loading={loadingDetails}
              />
            </Grid>
          )
        })
      }

      <ArticleBreakdownComponent
        breakdown={breakdown}
        comparisonMode={comparisonMode}
        loading={loadingDetails}
      />
    </SectionTileComponent>
  )
}

export default ArticlesOverviewComponent
