import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import {
  Grid,
  Slide,
  Typography,
  Box,
  useTheme,
} from '@mui/material'

import { createId } from '@utils/common.utils'

import {
  DEFAULT_SUFFIX, defaultNumberFormatter,
  formatNumberWithSuffixUnit, getNumberSuffix,
} from '@utils/analysis.utils'

import SliderFieldComponent from '@components/form/slider-field'
import IconButtonComponent from '@base/buttons/IconButton'
import ChevronIcon from '@icons/flow/chevron.icon'
import ChevronTopIcon from '@icons/flow/chevronTop.icon'
import FormattedUnitValueComponent from '@components/(features)/trade-offs/formatted-unit-value'

import { SliderBarCount } from '@components/(features)/trade-offs/slider-bar-chart/SliderBarChart.component'

export interface TradeOffsConfigurationComponentProps {
  className?: string,
  kpisOptions?: Socrates.KpiOption[],
  loading?: boolean,
  optimizeEnabled?: boolean,
  configuration: Socrates.TradeOffsConfiguration,
  handleChange: Socrates.HandleConfigurationChange,
  handleReset: () => void,
}

const TradeOffsConfigurationComponent: React.FC<TradeOffsConfigurationComponentProps> = ({
  className,
  kpisOptions = [],
  loading,
  optimizeEnabled,
  handleChange,
  handleReset,
  configuration = {} as Socrates.TradeOffsConfiguration,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const [slidersValue, setSlidersValue] = useState<{ [key: string]: any }>({})
  const [expanded, setExpanded] = useState(true)
  const isEmpty = (!kpisOptions || (kpisOptions.length === 0))

  const configurationToRender = (loading || isEmpty) ? null : (
    <Slide
      in={expanded}
      timeout={{
        exit: 500,
      }}
    >
      <Grid
        container={true}
        data-testid={`${TradeOffsConfigurationComponent.name}-list`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          '& > :last-child': {
            borderBottom: 'none',
          },
        }}
      >
        {
          kpisOptions.map((kpiOption, index: number) => {
            const sliderValue = slidersValue[kpiOption.value]
            const defaultSliderValue = configuration.SLIDER[kpiOption.value]
            const computedValue: [number, number] = [].concat(sliderValue || defaultSliderValue) as any

            const targetSuffix = optimizeEnabled ? getNumberSuffix(kpiOption.max) : '' as any
            const unit = intl.formatMessage({ id: 'optimize.selector.eurUnit' }, { suffix: targetSuffix?.key || DEFAULT_SUFFIX })

            const formattedMaxValue = (optimizeEnabled) ? formatNumberWithSuffixUnit(kpiOption.max, targetSuffix, true) : defaultNumberFormatter(kpiOption.max)
            const formattedMinValue = (optimizeEnabled) ? formatNumberWithSuffixUnit(kpiOption.min, targetSuffix, true) : defaultNumberFormatter(kpiOption.min)
            const tooltipFormatter = (value: number) => formatNumberWithSuffixUnit(
              value,
              targetSuffix,
              false,
              intl.formatMessage({ id: 'optimize.selector.eur' }),
            )

            return (
              <Grid
                item={true}
                xs={12}
                key={createId(index, kpiOption.key)}
                sx={{
                  padding: theme.spacing(4),
                  paddingBottom: theme.spacing(2),
                  borderBottom: `1px solid ${theme.palette.new.grey_a}`,
                  [theme.breakpoints.down('xl')]: {
                    padding: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    height: theme.spacing(2),
                    marginBottom: theme.spacing(3.5),
                    marginLeft: theme.spacing(0),
                    color: theme.palette.new.black,
                    letterSpacing: '0.025em',
                    [theme.breakpoints.down('xl')]: {
                      fontSize: '14px',
                    },
                  }}
                >
                  {kpiOption.label}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >

                  <FormattedUnitValueComponent
                    value={formattedMinValue}
                    unit={optimizeEnabled ? unit : ''}
                    sx={{
                      minWidth: theme.spacing(12),
                      justifyContent: 'flex-start',
                      '& > :first-of-type': {
                        color: theme.palette.new.black,
                      },
                    }}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      height: '64px',
                      alignItems: 'center',
                      width: '100%',
                      margin: theme.spacing(0, 3, 0, 5),
                    }}
                  >
                    <SliderFieldComponent
                      name={kpiOption.value}
                      value={computedValue}
                      defaultValue={[kpiOption.min, kpiOption.max] as any}
                      onChange={((e: Event, value: any) => setSlidersValue({ ...slidersValue, [kpiOption.value]: value })) as any}
                      onChangeCommitted={(e, value) => handleChange('SLIDER', kpiOption.value as any, value)}
                      valueLabelDisplay='auto'
                      step={null}
                      counts={kpiOption.counts as SliderBarCount[]}
                      marks={kpiOption.marks}
                      min={kpiOption.min}
                      max={kpiOption.max}
                      disabled={kpiOption.min === kpiOption.max}
                      valueLabelFormat={optimizeEnabled ? tooltipFormatter : undefined}
                    />
                  </Box>

                  <FormattedUnitValueComponent
                    value={formattedMaxValue}
                    unit={optimizeEnabled ? unit : ''}
                    sx={{
                      minWidth: theme.spacing(12),
                      justifyContent: 'flex-end',
                      '& > :first-of-type': {
                        color: theme.palette.new.black,
                      },
                    }}
                  />
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
    </Slide>
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          height: theme.spacing(6),
          padding: theme.spacing(0, 4),
          borderTop: `1px solid ${theme.palette.new.grey_a}`,
          [theme.breakpoints.down('xl')]: {
            padding: theme.spacing(0, 2),
          },
          ...(isEmpty ? { display: 'none' } : {}),
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              [theme.breakpoints.down('xl')]: {
                fontSize: '14px',
              },
            }}
          >
            {intl.formatMessage({ id: 'trade_offs.details.title' })}
          </Typography>
        </Box>
        {
          optimizeEnabled ? (
            <IconButtonComponent
              name='expand'
              label='Expand'
              tooltip={false}
              color='secondary'
              onClick={() => setExpanded(!expanded)}
              IconComponent={expanded ? ChevronIcon : ChevronTopIcon}
              disabled={false}
            />
          ) : (
            <Typography
              onClick={handleReset}
              sx={{
                fontSize: '18px',
                lineHeight: '60px',
                cursor: 'pointer',
                color: theme.palette.new.violet,
                textDecoration: 'underline',
                [theme.breakpoints.down('xl')]: {
                  fontSize: '12px',
                  lineHeight: '14px',
                },
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              {intl.formatMessage({ id: 'trade_offs.details.reset' })}
            </Typography>
          )
        }
      </Box>
      <Box
        data-testid={TradeOffsConfigurationComponent.name}
        sx={{
          height: '0px',
          overflow: 'hidden',
          ...(expanded ? {
            borderTop: `1px solid ${theme.palette.new.grey_a}`,
            height: '100%',
          } : {}),
        }}
      >
        {configurationToRender}
      </Box>
    </>
  )
}

export default TradeOffsConfigurationComponent
