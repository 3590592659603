import React from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@mui/material'
import { VALIDATION_ERRORS } from '@constants/files.constants'
import { detailsFormatter, getCountLeft, MAX_ITEMS_TO_SHOW } from '@components/training-data/TrainingDataErrorRow/TrainingDataErrorRow.utils'
import ErrorListItem from '@components/training-data/TrainingDataErrorRow/ErrorListItem'

export const VALIDATION_ERRORS_MESSAGES_MAP = {
  [VALIDATION_ERRORS.NOT_NULL]: {
    short: 'connect.data_input.modal.dropzone.status.error.not_null',
    long: 'connect.data_input.modal.dropzone.status.error.not_null_more',
  },
  [VALIDATION_ERRORS.CATEGORIES]: {
    short: 'connect.data_input.modal.dropzone.status.error.categories',
    long: 'connect.data_input.modal.dropzone.status.error.categories_more',
  },
  [VALIDATION_ERRORS.UNIQUE]: {
    short: 'connect.data_input.modal.dropzone.status.error.unique',
    long: 'connect.data_input.modal.dropzone.status.error.unique_more',
  },
  [VALIDATION_ERRORS.VALUE_RANGE]: {
    short: 'connect.data_input.modal.dropzone.status.error.value_range',
    long: 'connect.data_input.modal.dropzone.status.error.value_range_more',
  },
  [VALIDATION_ERRORS.INTEGER]: {
    short: 'connect.data_input.modal.dropzone.status.error.number_format',
    long: 'connect.data_input.modal.dropzone.status.error.number_format_more',
  },
  [VALIDATION_ERRORS.NUMBER]: {
    short: 'connect.data_input.modal.dropzone.status.error.number',
    long: 'connect.data_input.modal.dropzone.status.error.number_more',
  },
  [VALIDATION_ERRORS.NUMBER_FORMAT]: {
    short: 'connect.data_input.modal.dropzone.status.error.number_format',
    long: 'connect.data_input.modal.dropzone.status.error.number_format_more',
  },
  [VALIDATION_ERRORS.DATE_FORMAT]: {
    short: 'connect.data_input.modal.dropzone.status.error.date_format',
    long: 'connect.data_input.modal.dropzone.status.error.date_format_more',
  },
  [VALIDATION_ERRORS.ADDITIONAL_COLUMNS]: {
    short: 'connect.data_input.modal.dropzone.status.error.additional_columns',
    long: 'connect.data_input.modal.dropzone.status.error.additional_columns_more',
  },
  [VALIDATION_ERRORS.DUPLICATE_COLUMNS]: {
    short: 'connect.data_input.modal.dropzone.status.error.duplicate_columns',
    long: 'connect.data_input.modal.dropzone.status.error.duplicate_columns_more',
  },
  [VALIDATION_ERRORS.MISSING_COLUMNS]: {
    short: 'connect.data_input.modal.dropzone.status.error.missing_columns',
    long: 'connect.data_input.modal.dropzone.status.error.missing_columns_more',
  },
  [VALIDATION_ERRORS.PLAIN_TEXT]: {
    short: 'connect.data_input.modal.dropzone.status.error.plain_text',
    long: 'connect.data_input.modal.dropzone.status.error.plain_text_more',
  },
}

export interface DefaultColumnsErrorComponentProps {
  error: TrainingFiles.CsvValidationResultItem
  type: keyof typeof VALIDATION_ERRORS_MESSAGES_MAP
}

const DefaultColumnsErrorComponent: React.FC<DefaultColumnsErrorComponentProps> = ({
  error,
  type,
}) => {
  const intl = useIntl()

  const affectedColumnNames = (error.affectedColumns || []).map((col) => col.columnName)
  const affectedColumnNamesCount = affectedColumnNames.length
  const longMessageKey = VALIDATION_ERRORS_MESSAGES_MAP[type].long
  const shortMessageKey = VALIDATION_ERRORS_MESSAGES_MAP[type].short

  return (
    <ErrorListItem
      data-testid={`${DefaultColumnsErrorComponent.name}-${type}`}
    >
      <Typography variant='body1'>
        {
          intl.formatMessage({
            id: (affectedColumnNamesCount > MAX_ITEMS_TO_SHOW) ? longMessageKey : shortMessageKey,
          }, {
            columns: detailsFormatter(affectedColumnNames),
            originalCount: affectedColumnNamesCount,
            countLeft: getCountLeft(affectedColumnNamesCount),
          })
        }
      </Typography>
    </ErrorListItem>
  )
}

export default DefaultColumnsErrorComponent
