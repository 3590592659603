import React from 'react'

const RedshiftIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={RedshiftIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M9.14844 13.7626L15.9934 15.3924V2.57129L9.14844 4.20105V13.7626Z" fill="#205B97"/>
      <path d="M15.9947 2.57129L17.2985 3.22319V14.7405L15.9947 15.3924V2.57129ZM9.14967 13.7626L2.30469 15.3924V2.57129L9.14967 4.20105V13.7626Z" fill="#5193CE"/>
      <path d="M2.30381 2.57129L1 3.22319V14.7405L2.30381 15.3924V2.57129Z" fill="#205B97"/>
      <path d="M10.891 18L13.4986 16.6962V1.30381L10.891 0L10.0938 8.5474L10.891 18Z" fill="#5193CE"/>
      <path d="M7.4123 18L4.80469 16.6962V1.30381L7.4123 0L8.20952 8.5474L7.4123 18Z" fill="#205B97"/>
      <path d="M7.41016 0H10.923V17.9637H7.41016V0Z" fill="#2E73B7"/>

    </svg>
  )
}

export default RedshiftIcon
