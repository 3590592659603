import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import LayoutIcon from '@icons/flow/layout.icon'
import TextFieldComponent from '@base/forms/TextField'
import ButtonComponent from '@base/buttons/Button'
import DataTypesWidgetComponent from '@containers/modals/use-case-data-types/data-types-widget'
import StartOptimizationButtonComponent from '@containers/modals/use-case-data-types/start-optimization-button'

import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { createFileIdentifierAction, requestFileIdentifiersListAction } from '@redux/modules/training-files/training-files.actions'
import { DATA_UPLOAD_PATH } from '@constants/routes.constants'
import { USE_CASE_DATA_TYPES_MODAL_NAME } from '@constants/modals.constants'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import {
  isFetchingUseCase,
} from '@redux/modules/use-case/use-case.selectors'

import {
  isFetchingTrainingData,
  isSubmittingTrainingData,
  isFetchingFileIdentifiers,
  getUseCaseFileIdentifiers,
  isSubmittingFileIdentifier,
} from '@redux/modules/training-files/training-files.selectors'

import { validateFileIdentifier } from '@utils/use-cases.utils'

export interface UseCaseDataTypesModalDetails extends Common.ModalDetails {
}

const defaultFileIdentifier = {
  value: '',
  isValid: true,
  isUnique: true,
}

const UseCaseDataTypesModalContainer: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const [fileIdentifier, setFileIdentifier] = useState(defaultFileIdentifier)
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const modalDetails = useSelector((state) => getModalDetails<UseCaseDataTypesModalDetails>(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const fileIdentifiersList = useSelector((state) => getUseCaseFileIdentifiers(state))
  const isSubmittingFileIdentifierFlag = useSelector((state) => isSubmittingFileIdentifier(state))
  const isSubmittingTrainingDataFlag = useSelector((state) => isSubmittingTrainingData(state))
  const isSubmitting = isSubmittingFileIdentifierFlag || isSubmittingTrainingDataFlag
  const isFetchingFileIdentifiersFlag = useSelector((state) => isFetchingFileIdentifiers(state))

  const isFetchingUseCaseData = useSelector((state) => isFetchingUseCase(state))
  const isFetchingTrainingDataList = useSelector((state) => isFetchingTrainingData(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === USE_CASE_DATA_TYPES_MODAL_NAME
  const isFetching = isFetchingUseCaseData || isFetchingTrainingDataList || isFetchingFileIdentifiersFlag

  useEffect(() => {
    if (open && usecase) {
      dispatch(
        requestFileIdentifiersListAction({
          useCaseId: usecase,
        }),
      )
    }

    if (!open) {
      setFileIdentifier(defaultFileIdentifier)
    }
  }, [dispatch, open, usecase])

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(modalDetails.returnTo || ''),
    )

    setFileIdentifier(defaultFileIdentifier)
  }

  const handleDataTypeChange = (identifier: string, version: string) => {
    trackEvent({
      componentName: 'dataTypesListItem',
      actionName: TRACKING_ACTIONS.NAVIGATE,
    }, {
      useCaseId: usecase,
      fileIdentifier: identifier,
      version,
    })

    history.push(generatePath(DATA_UPLOAD_PATH, { usecase, identifier, version }))

    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const handleFileIdentifierChange = (e: React.ChangeEvent<any>) => {
    const value = (e.target.value || '').trim()
    const sameIdentifiers = fileIdentifiersList.filter((item) => (item.value.toLowerCase() === value.toLowerCase()))
    const isUnique = sameIdentifiers.length === 0

    setFileIdentifier({
      value,
      isValid: validateFileIdentifier(value) && isUnique,
      isUnique,
    })
  }

  const getValidationError = () => {
    const { isValid, isUnique } = fileIdentifier

    if (isValid) {
      return ''
    }

    if (!isUnique) {
      return intl.formatMessage({ id: 'connect.data_input.modal.steps.file_identifier.exists' })
    }

    return intl.formatMessage({ id: 'connect.data_input.modal.steps.file_identifier.error' })
  }

  const handleDatatypeCreate = () => {
    dispatch(
      createFileIdentifierAction({
        useCaseId: usecase,
        fileIdentifier: fileIdentifier.value,
      }),
    )

    setFileIdentifier(defaultFileIdentifier)
  }

  return (
    <SidePanelComponent
      open={open}
      hasUnsavedChanges={Boolean(fileIdentifier.value) || isSubmitting}
      title={intl.formatMessage({ id: 'connect.data_input.modal.title' })}
      handleClose={handleModalClose}
      SidePanelIcon={LayoutIcon}
    >
      <SidePanelLoadingComponent loading={isFetching}>
        <SidePanelCardComponent>
          {
            isAdmin ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 1,
                  gap: 1,
                }}
              >
                <TextFieldComponent
                  hideLabel={true}
                  name='newFileIdentifier'
                  label={intl.formatMessage({ id: 'connect.data_input.modal.steps.file_identifier.help' })}
                  value={fileIdentifier.value}
                  onChange={handleFileIdentifierChange}
                  placeholder={intl.formatMessage({ id: 'connect.data_input.modal.steps.file_identifier.placeholder' })}
                  floatingHelp={intl.formatMessage({ id: 'connect.data_input.modal.steps.file_identifier.help' })}
                  errors={getValidationError()}
                  touched={true}
                />
                <ButtonComponent
                  name='createDataTypeButton'
                  rounded={true}
                  color='highlighted'
                  label={intl.formatMessage({ id: 'common.button.create' })}
                  onClick={handleDatatypeCreate}
                  disabled={!fileIdentifier.isValid || !fileIdentifier.value || isSubmitting}
                />
              </Box>
            ) : (
              null
            )
          }

          <DataTypesWidgetComponent handleDataTypeChange={handleDataTypeChange} />
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='useCaseDataTypesModalCloseButton'
            onClick={handleModalClose}
            type='back'
            loading={isSubmitting}
            disabled={isSubmitting}
          />
          <StartOptimizationButtonComponent />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default UseCaseDataTypesModalContainer
