import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { getFormattedCurrentDate, getStartOfTheWeek } from '@utils/moment.utils'
import { OPTIMIZE_PATH } from '@constants/routes.constants'

import PageLayoutContainer from '@containers/application/PageLayout'

import {
  requestTradeOffsByUseCaseAction,
  selectTradeOffsPointAction,
  selectTradeOffsPointResetAction,
  resetTradeOffsConfigurationAction,
  changeTradeOffsConfigurationAction,
} from '@redux/modules/socrates/socrates.actions'

import {
  getTradeOffsComparisonMeta,
  getTradeOffsDataSet,
  getTradeOffsDataKpisOptions,
  getTradeOffsConfiguration,
  getTradeOffsSelectedPoints,
  isDefaultTradeOffsConfig,
  isFetchingIntialData,
  isFetchingProjects,
  isFetching as isFetchingSocrates,
  showSuboptimalSolutions as shouldShowSuboptimalSolutions,
} from '@redux/modules/socrates/socrates.selectors'

import {
  EXPORT_SELECTED_AS_CSV,
  EXPORT_AS_CSV,
  EXPORT_AS_PNG,
} from '@constants/export.constants'

import ViewInfoDateComponent from '@base/pagebar/ViewInfoDate'
import ContextMenuComponent from '@base/menus/ContextMenu'
import { USE_CASE_ARTIFACTS_TYPES } from '@constants/use-cases.constants'
import { getArtifactDate, isOptimizeEnabled } from '@utils/use-cases.utils'
import { getUseCaseItem, isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'
import { handleExport } from '@utils/export.utils'

import TradeOffsLayoutComponent from '@components/(features)/trade-offs/trade-offs-layout'

const TradeOffsContainer: React.FC = () => {
  const EXPORT_ID = 'TradeOffsContainer'
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const requestTradeOffsByUseCase = useMemo(() => bindActionCreators(requestTradeOffsByUseCaseAction, dispatch), [dispatch])
  const changeTradeOffsConfiguration = useMemo(() => bindActionCreators(changeTradeOffsConfigurationAction, dispatch), [dispatch])
  const selectTradeOffsPoint = useMemo(() => bindActionCreators(selectTradeOffsPointAction, dispatch), [dispatch])
  const selectTradeOffsPointReset = useMemo(() => bindActionCreators(selectTradeOffsPointResetAction, dispatch), [dispatch])
  const resetTradeOffsConfiguration = useMemo(() => bindActionCreators(resetTradeOffsConfigurationAction, dispatch), [dispatch])

  const useCase = useSelector((state) => getUseCaseItem(state))
  const dataSet = useSelector((state) => getTradeOffsDataSet(state))
  const configuration = useSelector((state) => getTradeOffsConfiguration(state))

  const tradeOffsComparisonMeta = useSelector((state) => getTradeOffsComparisonMeta(state))
  const kpisOptions = useSelector((state) => getTradeOffsDataKpisOptions(state))
  const isDefaultConfig = useSelector((state) => isDefaultTradeOffsConfig(state))
  const selectedPoints = useSelector((state) => getTradeOffsSelectedPoints(state))
  const showSuboptimalSolutions = useSelector((state) => shouldShowSuboptimalSolutions(state))

  const isFetchingProjectsData = useSelector((state) => isFetchingProjects(state))
  const isFetchingProjectsIntialData = useSelector((state) => isFetchingIntialData(state))
  const isFetchingUseCaseData = useSelector((state) => isFetchingUseCase(state))
  const isFetchingSocratesData = useSelector((state) => isFetchingSocrates(state))
  const isFetching = isFetchingProjectsData || isFetchingProjectsIntialData || isFetchingUseCaseData || isFetchingSocratesData
  const optimizeEnabled = isOptimizeEnabled(usecase)

  useLayoutEffect(() => {
    requestTradeOffsByUseCase({ useCaseId: usecase })

    return () => {
      selectTradeOffsPointReset()
      resetTradeOffsConfiguration()
    }
  }, [usecase, requestTradeOffsByUseCase, selectTradeOffsPointReset, resetTradeOffsConfiguration])

  const handleConfigurationChange = (
    type: keyof Socrates.TradeOffsConfiguration,
    field: keyof Socrates.TradeOffsConfigurationAxis | keyof Socrates.TradeOffsConfigurationSubotimal,
    value: number[] | number | string,
  ) => {
    const payload = {
      [type]: {
        [field]: value,
      },
    } as any

    changeTradeOffsConfiguration(payload)
  }

  const handleResetClick = () => {
    selectTradeOffsPointReset()
    resetTradeOffsConfiguration()
  }

  const handleExportClick = useCallback((type: string) => {
    const currentDate = getFormattedCurrentDate()
    let jsData: any = []
    let fileName = ''

    switch (type) {
      case EXPORT_SELECTED_AS_CSV: {
        fileName = intl.formatMessage({ id: 'trade_offs.export.filename.selected' }, { name: useCase.name, date: currentDate })
        jsData = selectedPoints.map((item) => {
          const {
            name, x, y,
            xKey, yKey,
          } = item

          return ({
            name,
            x,
            y,
            xKey,
            yKey,
          })
        })
        break
      }

      case EXPORT_AS_CSV: {
        fileName = intl.formatMessage({ id: 'trade_offs.export.filename.all' }, { name: useCase.name, date: currentDate })
        jsData = dataSet.map((item) => {
          const {
            name, x, y,
            xKey, yKey,
            datasetPayload,
          } = item

          return ({
            name,
            x,
            y,
            xKey,
            yKey,
            isOptimum: datasetPayload.isParetoOptimal,
          })
        })
        break
      }
      case EXPORT_AS_PNG:
      default: {
        fileName = intl.formatMessage({ id: 'trade_offs.export.filename.image' }, { name: useCase.name, date: currentDate })
        jsData = []
        break
      }
    }

    const data = (jsData?.length > 0) ? jsData : [{
      name: '', x: '', y: '', xKey: '', yKey: '',
    }]

    handleExport({
      type,
      data,
      fileName,
      exportId: EXPORT_ID,
    })
  }, [dataSet, intl, selectedPoints, useCase])

  const handleDownloadSelection = (index: number) => {
    const {
      datasetPayload: {
        datasetKpis,
        datasetDesign,
      },
    } = selectedPoints[index]

    const dataset = [...datasetKpis, ...datasetDesign]
    const currentDate = getFormattedCurrentDate()

    handleExport({
      type: EXPORT_SELECTED_AS_CSV,
      data: dataset.map((item) => ({ name: item.label, value: item.value })),
      fileName: intl.formatMessage({ id: 'trade_offs.export.filename.decision' }, { name: useCase.name, date: currentDate }),
      exportId: EXPORT_ID,
    })
  }

  const pageBarRightSideBlocks = useMemo(() => {
    const tradeoffUpdatedAt = optimizeEnabled ? getStartOfTheWeek() : getArtifactDate(useCase, USE_CASE_ARTIFACTS_TYPES.TRADEOFFS_V1)

    return (
      <>
        <ViewInfoDateComponent
          date={tradeoffUpdatedAt}
          label={intl.formatMessage({ id: 'trade_offs.lastModified.title' })}
        />

        <ContextMenuComponent
          name='exportAsContextMenu'
          label={intl.formatMessage({ id: 'common.button.export_as' })}
          actions={[{
            name: 'exportAsPng',
            label: intl.formatMessage({ id: 'common.button.export_png' }),
            disabled: false,
            handler: () => handleExportClick(EXPORT_AS_PNG),
          }, {
            name: 'exportAsCsv',
            label: intl.formatMessage({ id: 'common.button.export_csv' }),
            disabled: false,
            handler: () => handleExportClick(EXPORT_AS_CSV),
          }, {
            name: 'exportSelectedAsCsv',
            label: intl.formatMessage({ id: 'common.button.export_selected_csv' }),
            disabled: !(selectedPoints?.length > 0),
            handler: () => handleExportClick(EXPORT_SELECTED_AS_CSV),
          }]}
        />
      </>
    )
  }, [
    intl, optimizeEnabled, useCase,
    selectedPoints, handleExportClick,
  ])

  return (
    <PageLayoutContainer
      title={intl.formatMessage(
        { id: optimizeEnabled ? 'optimize.browser_tab.title' : 'trade_offs.browser_tab.title' },
        { name: useCase.name },
      )}
      isFetching={isFetching}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      backToPath={optimizeEnabled ? generatePath(OPTIMIZE_PATH, { usecase }) : undefined}
      customTitle={optimizeEnabled ? intl.formatMessage({ id: 'common.layout.navigation.optimize_drill_down' }) : undefined}
    >
      <TradeOffsLayoutComponent
        exportId={EXPORT_ID}
        selectedPoints={selectedPoints}
        selectTradeOffsPoint={selectTradeOffsPoint}
        dataSet={dataSet}
        configuration={configuration}
        isDefaultConfig={isDefaultConfig}
        kpisOptions={kpisOptions}
        showSuboptimalSolutions={showSuboptimalSolutions}
        handleConfigurationChange={handleConfigurationChange}
        handleResetClick={handleResetClick}
        tradeOffsComparisonMeta={tradeOffsComparisonMeta}
        handleDownloadSelection={handleDownloadSelection}
        optimizeEnabled={optimizeEnabled}
      />
    </PageLayoutContainer>
  )
}

export default TradeOffsContainer
