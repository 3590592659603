import React from 'react'

const S3Icon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '',
}) => {
  return (
    <svg data-testid={S3Icon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.1797 14.9623V3.11243L16.4547 3.82489V14.2499L15.1797 14.9623Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.25391 4.11459L2.60156 3.09998V14.9666L6.26562 14.0447V12.7238L8.89531 13.3831L8.89594 13.383L6.28906 12.7293L8.96105 12.2345V11.3L8.96094 11.3V11.2999L6.26562 10.9915V7.06009L8.96094 6.7669V6.76672L8.96105 6.76674V5.80641L6.26562 5.36667V5.35918L6.25391 5.36248V4.11459Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96028 5.80641V6.76674L8.96098 6.76681L8.96181 6.76672V6.7669L11.6571 7.06009V10.9915L8.96181 11.2999V11.3L8.96098 11.3L8.96028 11.3V12.2345L8.9664 12.2334L11.6362 12.729L11.6571 12.7238V14.5594L15.1781 14.9624V3.11243L11.6523 3.51598V5.36248L11.6505 5.36196L11.6588 5.36667L8.96181 5.80666L8.96028 5.80641ZM8.89453 13.3831L8.96136 13.3999L8.96199 13.3997L8.89516 13.383L8.89453 13.3831ZM11.6584 12.735L11.66 12.7334L11.6584 12.7331V12.735Z" fill="black" fillOpacity="0.6"/>
      <path d="M2.60156 3.11792L1.60156 3.56236V14.5123L2.60156 14.9568V3.11792Z" fill="black" fillOpacity="0.4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.9539 0L6.25391 1.425V5.36248L8.9623 4.6L8.9539 0Z" fill="black" fillOpacity="0.6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96258 6.76672L6.26562 7.06009V10.9915L8.96258 11.3V6.76672Z" fill="black" fillOpacity="0.6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96258 13.4L6.26562 12.7238V16.5886L8.96258 18V13.4Z" fill="black" fillOpacity="0.6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6579 12.7238L8.96094 13.4002V18L11.6579 16.5886V12.7238Z" fill="black"/>
      <path d="M6.28906 12.7293L8.96718 12.2334L11.6377 12.7291L8.96339 13.3999L6.28906 12.7293Z" fill="black" fillOpacity="0.4"/>
      <path d="M11.6592 12.735L11.6608 12.7334L11.6592 12.7331V12.735Z" fill="black" fillOpacity="0.4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.96094 6.76672L11.6579 7.06009V10.9915L8.96094 11.3V6.76672Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.95312 0L11.6531 1.425V5.36248L8.95312 4.61248V0Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26562 5.35914V5.36659L8.96258 5.80658L11.6595 5.36659L11.6514 5.36196L8.95312 4.61244V4.6124V4.61236V4.60254L6.26562 5.35914Z" fill="black" fillOpacity="0.4"/>

    </svg>
  )
}

export default S3Icon
