import React from 'react'

const RewindIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={RewindIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M0.64286 9C0.64286 10.6529 1.133 12.2687 2.05129 13.643C2.96959 15.0173 4.27479 16.0885 5.80186 16.721C7.32893 17.3535 9.00927 17.519 10.6304 17.1966C12.2515 16.8741 13.7406 16.0782 14.9094 14.9094C16.0782 13.7406 16.8741 12.2515 17.1966 10.6304C17.519 9.00927 17.3535 7.32893 16.721 5.80186C16.0885 4.27479 15.0173 2.96959 13.643 2.05129C12.2687 1.133 10.6529 0.642858 9 0.642858C6.60597 0.640209 4.30322 1.56131 2.57143 3.21429" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.21429 0.642853L2.57143 3.21428L5.14286 3.85714" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.00001 4.5V9.64286L5.65715 11.3143" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default RewindIcon
