export const CHANGE_COMPANY_ID = 'CHANGE_COMPANY_ID'
export const CHANGE_COMPANY_ID_DONE = 'CHANGE_COMPANY_ID_DONE'

export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'
export const CHANGE_USER_INFO_DONE = 'CHANGE_USER_INFO_DONE'

export const REQUEST_COMPANY_ITEM = 'REQUEST_COMPANY_ITEM'
export const REQUEST_COMPANY_ITEM_DONE = 'REQUEST_COMPANY_ITEM_DONE'

export const REQUEST_COMPANIES_LIST = 'REQUEST_COMPANIES_LIST'
export const REQUEST_COMPANIES_LIST_DONE = 'REQUEST_COMPANIES_LIST_DONE'

export const REQUEST_COMPANY_USERS_LIST = 'REQUEST_COMPANY_USERS_LIST'
export const REQUEST_COMPANY_USERS_LIST_DONE = 'REQUEST_COMPANY_USERS_LIST_DONE'

export const START_FETCHING_CUSTOMER = 'START_FETCHING_CUSTOMER'
export const STOP_FETCHING_CUSTOMER = 'STOP_FETCHING_CUSTOMER'

export const CHANGE_USER_LANGUAGE = 'CHANGE_USER_LANGUAGE'

export const CLONE_COMPANY = 'CLONE_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'

export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const REASSIGN_USER = 'REASSIGN_USER'
