import React from 'react'

export interface CheckedRoundIconProps extends Common.IconProps {
}

const CheckedRoundIcon: React.FC<CheckedRoundIconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = '#5F26E0',
}) => {
  return (
    <svg data-testid={CheckedRoundIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g opacity="0.8">
        <circle cx="9" cy="9" r="9" transform="matrix(0 -1 -1 0 18 18)" fill="white"/>
        <circle cx="9" cy="9" r="8.5" transform="matrix(0 -1 -1 0 18 18)" stroke="black" strokeOpacity="0.2"/>
      </g>
      <circle cx="9" cy="9" r="9" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 18 18)" fill={detailsFill} />

    </svg>
  )
}

export default CheckedRoundIcon
