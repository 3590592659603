import React from 'react'

const ChevronUpIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  opacity = '1',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={ChevronUpIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M16 9.50037L9.37692 2.87729C9.32858 2.82578 9.27019 2.78473 9.20536 2.75666C9.14053 2.7286 9.07064 2.71412 9 2.71412C8.92936 2.71412 8.85946 2.7286 8.79464 2.75666C8.72981 2.78473 8.67142 2.82578 8.62308 2.87729L2 9.50037" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="0.5" y1="-0.5" x2="13.2863" y2="-0.5" transform="matrix(-4.37113e-08 -1 -1 4.37113e-08 8.5 16.5004)" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default ChevronUpIcon
