import React from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@mui/material'
import { VALIDATION_ERRORS } from '@constants/files.constants'

import InfoButtonComponent from '@base/buttons/InfoButton'
import ErrorListItem from '@components/training-data/TrainingDataErrorRow/ErrorListItem'
import { detailsFormatter, getCountLeft, MAX_ITEMS_TO_SHOW } from '@components/training-data/TrainingDataErrorRow/TrainingDataErrorRow.utils'

export interface CustomErrorComponentProps {
  error: TrainingFiles.CsvValidationResultItem
  type: VALIDATION_ERRORS.INVALID_COLUMNS | VALIDATION_ERRORS.INVALID_ROWS
}

const CustomErrorComponent: React.FC<CustomErrorComponentProps> = ({
  error,
  type,
}) => {
  const intl = useIntl()
  const affectedColumnNames = (error.affectedColumns || []).map((col) => col.columnName)
  const affectedColumnNamesCount = affectedColumnNames.length

  let content = null

  switch (type) {
    case VALIDATION_ERRORS.INVALID_COLUMNS: {
      content = (
        <Typography
          component='div'
          variant='body1'
          sx={{
            display: 'inline-block',
            position: 'relative',
          }}
        >
          {
            intl.formatMessage({
              id: (affectedColumnNamesCount > MAX_ITEMS_TO_SHOW) ?
                'connect.data_input.modal.dropzone.status.error.invalid_columns_more' :
                'connect.data_input.modal.dropzone.status.error.invalid_columns',
            }, {
              columns: detailsFormatter(affectedColumnNames),
              originalCount: affectedColumnNamesCount,
              countLeft: getCountLeft(affectedColumnNamesCount),
            })
          }
          <InfoButtonComponent
            name='invalidColumnsTooltip'
            tooltip={intl.formatMessage({ id: 'connect.data_input.modal.dropzone.status.error.invalid_columns_rules' })}
            placement='top'
            sx={{
              display: 'inline-block',
              lineHeight: '16px',
              marginLeft: 1,
              cursor: 'pointer',
              position: 'absolute',
              bottom: '0px',
            }}
          />
        </Typography>
      )

      break
    }

    case VALIDATION_ERRORS.INVALID_ROWS: {
      const invalidRows = (error.affectedRows || [])
      const invalidRowsCount = invalidRows.length
      const invalidRowsNames = invalidRows.map((row) => row.rowNumber)

      content = (
        <Typography variant='body1'>
          {
            intl.formatMessage({
              id: (invalidRowsCount > MAX_ITEMS_TO_SHOW) ?
                'connect.data_input.modal.dropzone.status.error.invalid_rows_more' :
                'connect.data_input.modal.dropzone.status.error.invalid_rows',
            }, {
              rows: detailsFormatter(invalidRowsNames),
              originalCount: invalidRowsCount,
              countLeft: getCountLeft(invalidRowsCount),
            })
          }
        </Typography>
      )

      break
    }

    default: {
      return null
    }
  }

  return (
    <ErrorListItem
      data-testid={`${CustomErrorComponent.name}-${type}`}
    >
      {content}
    </ErrorListItem>
  )
}

export default CustomErrorComponent
