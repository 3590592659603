import React, { useMemo } from 'react'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, SxProps, Theme } from '@mui/material'
import { DEFAULT_BUTTON_HEIGHT } from '@constants/ui.constants'

export interface AnimationStages {
  size: number[],
  color: string[],
  zIndex: number[],
  position: { x: number, y: number }[],
}

const createAnimation = (stages: AnimationStages) => keyframes`
  0% {
    width: ${stages.size[0]}px;
    height: ${stages.size[0]}px;
    background-color: ${stages.color[0]};
    z-index: ${stages.zIndex[0]};
    transform: translateX(${stages.position[0].x}px) translateY(${stages.position[0].y}px);
  }
  50% {
    width: ${stages.size[1]}px;
    height: ${stages.size[1]}px;
    background-color: ${stages.color[1]};
    z-index: ${stages.zIndex[1]};
    transform: translateX(${stages.position[1].x}px) translateY(${stages.position[1].y}px);
  }
  100% {
    width: ${stages.size[2]}px;
    height: ${stages.size[2]}px;
    background-color: ${stages.color[2]};
    z-index: ${stages.zIndex[2]};
    transform: translateX(${stages.position[2].x}px) translateY(${stages.position[2].y}px);
  }
`

const Ellipse = styled.div<{
  stages: AnimationStages;
  blur: number;
  delay: number;
  animation: ReturnType<typeof keyframes>;
}>`
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  animation: ${({ animation, delay }) => css`${delay}s infinite ease-in-out alternate ${animation}`};
  animation-duration: 5s;
  filter: blur(${({ blur }) => blur}px);
`

const getSizeMultiplier = (size: LoadingCloudComponentProps['size']) => {
  switch (size) {
    case 'small':
      return 1
    case 'default':
      return 1.8
    case 'medium':
      return 2.2
    case 'large':
    default:
      return 10
  }
}

const getSize = (index: number, stage: number, size: LoadingCloudComponentProps['size']) => {
  switch (index) {
    case 1:
      switch (stage) {
        case 1:
          return 15
        case 2:
          return 18
        case 3:
        default:
          return 21
      }
    case 2:
      switch (stage) {
        case 1:
          return 10
        case 2:
          return 14
        case 3:
        default:
          return 14
      }
    case 3:
    default:
      switch (stage) {
        case 1:
          return 10
        case 2:
          return 8
        case 3:
        default:
          return 12
      }
  }
}

const getBlur = (size: LoadingCloudComponentProps['size']) => {
  switch (size) {
    case 'small':
      return 3.5
    case 'default':
      return 6.5
    case 'medium':
      return 7.5
    case 'large':
    default:
      return 40
  }
}

export interface LoadingCloudComponentProps {
  size?: 'small' | 'medium' | 'large' | 'default'
  position?: 'absolute' | 'relative'
  sx?: SxProps<Theme>
}

const LoadingCloudComponent: React.FC<LoadingCloudComponentProps> = ({
  size = 'small',
  position = 'relative',
  sx,
}) => {
  const multiplier = getSizeMultiplier(size)

  const ellipses = useMemo(() => {
    const setup = [
      {
        stages: {
          size: [getSize(1, 1, size), getSize(1, 2, size), getSize(1, 3, size)],
          color: ['#F500E1', '#F500E1', '#FBB03B'],
          position: [{ x: 1, y: -1 }, { x: -1, y: 1 }, { x: 0, y: 0 }],
          zIndex: [1, 2, 3],
        },
        delay: 2,
        blur: getBlur(size),
      },
      {
        stages: {
          size: [getSize(2, 1, size), getSize(2, 2, size), getSize(2, 3, size)],
          color: ['#FBB03B', '#FBB03B', '#F500E1'],
          position: [{ x: 7, y: 2 }, { x: -7, y: -5 }, { x: -6, y: 8 }],
          zIndex: [2, 3, 1],
        },
        delay: 1.5,
        blur: getBlur(size),
      },
      {
        stages: {
          size: [getSize(3, 1, size), getSize(3, 2, size), getSize(3, 3, size)],
          color: ['#FBB03B', '#FBB03B', '#F500E1'],
          position: [{ x: -6, y: 8 }, { x: 7, y: 2 }, { x: 5, y: -6 }],
          zIndex: [3, 1, 2],
        },
        delay: 1,
        blur: getBlur(size),
      },
    ]

    return setup.map((ellipse) => ({
      stages: {
        size: ellipse.stages.size.map((s) => s * multiplier),
        color: ellipse.stages.color,
        position: ellipse.stages.position.map((p) => ({ x: p.x * multiplier, y: p.y * multiplier })),
        zIndex: ellipse.stages.zIndex,
      },
      delay: ellipse.delay,
      blur: ellipse.blur,
    }))
  }, [
    size, multiplier,
  ])

  const containerSize = useMemo(() => {
    if (size === 'small') {
      return {
        width: DEFAULT_BUTTON_HEIGHT,
        height: DEFAULT_BUTTON_HEIGHT,
      }
    }

    if (size === 'default') {
      return {
        width: '48px',
        height: '48px',
      }
    }

    if (size === 'medium') {
      return {
        width: '80px',
        height: '80px',
      }
    }

    return {
      width: '400px',
      height: '400px',
    }
  }, [size])

  return (
    <Box
      data-testid={LoadingCloudComponent.name}
      sx={{
        position,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...containerSize,
        ...sx,
      }}
    >
      {ellipses.map((ellipse, index) => (
        <Ellipse
          key={index}
          stages={ellipse.stages}
          blur={ellipse.blur}
          delay={ellipse.delay}
          animation={createAnimation(ellipse.stages)}
        />
      ))}
    </Box>
  )
}

export default LoadingCloudComponent
