import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX, DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX } from '@constants/insights.constants'
import { foldsToChartAnnotations } from '@utils/monitor.utils'
import { getPromotionsToExclude, getPromotionsVisibility } from '@redux/modules/insights/insights.selectors'

import {
  getBacktestingAvailableFolds, getBacktestingChart,
  getBacktestingTable, isFetchingBacktestingChart,
} from '@redux/modules/monitor/monitor.selectors'

import InsightsChartComponent from '@components/insights/InsightsChart'

const BacktestingChartContainer = () => {
  const intl = useIntl()
  const backtestingTable = useSelector((state) => getBacktestingTable(state))
  const backtestingChart = useSelector((state) => getBacktestingChart(state))
  const backtestingAvailableFolds = useSelector((state) => getBacktestingAvailableFolds(state))
  const isFetching = useSelector((state) => isFetchingBacktestingChart(state))
  const promotionsVisibility = useSelector((state) => getPromotionsVisibility(state))
  const promotionsToExclude = useSelector((state) => getPromotionsToExclude(state))

  const {
    gridState,
  } = backtestingTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
    promotionDays,
  } = backtestingChart

  const annotations: Hera.BaseChartAnnotationItem[] = useMemo(() => {
    if (!gridState) {
      return []
    }

    return foldsToChartAnnotations(intl, gridState.backtestingFold, backtestingAvailableFolds)
  }, [intl, gridState, backtestingAvailableFolds])

  return (
    <Box
      data-testid={BacktestingChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        annotations={annotations}
        promotionDays={promotionDays}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        isFetching={isFetching}
        predictionKeyPrefix={DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX}
        absDeviationKeyPrefix={DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX}
        promotionsVisibility={promotionsVisibility}
        promotionsToExclude={promotionsToExclude}
      />
    </Box>
  )
}

export default BacktestingChartContainer
