import React from 'react'

const CalendarIcon: React.FC<Common.IconProps> = ({
  width = 18, height = 18,
  viewBox = '0 0 18 18', fill = 'none', opacity = '1',
  className = '', detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={CalendarIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <g>
        <g>
          <path d="M1.93025 3.21436C1.58925 3.21436 1.26223 3.34981 1.02111 3.59093C0.77999 3.83205 0.644531 4.15908 0.644531 4.50007V16.0715C0.644531 16.4125 0.77999 16.7395 1.02111 16.9806C1.26223 17.2218 1.58925 17.3572 1.93025 17.3572H16.0731C16.4141 17.3572 16.7411 17.2218 16.9822 16.9806C17.2234 16.7395 17.3588 16.4125 17.3588 16.0715V4.50007C17.3588 4.15908 17.2234 3.83205 16.9822 3.59093C16.7411 3.34981 16.4141 3.21436 16.0731 3.21436H13.5017" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.5 0.642822V5.78568" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.5 0.642822V5.78568" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.5 3.21436H10.9286" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.50016 10.6082C5.03311 10.6082 5.46516 10.176 5.46516 9.64286C5.46516 9.1097 5.03311 8.67749 4.50016 8.67749C3.9672 8.67749 3.53516 9.1097 3.53516 9.64286C3.53516 10.176 3.9672 10.6082 4.50016 10.6082Z" fill={detailsFill} />
          <path d="M9.00016 10.6082C9.53311 10.6082 9.96516 10.176 9.96516 9.64286C9.96516 9.1097 9.53311 8.67749 9.00016 8.67749C8.4672 8.67749 8.03516 9.1097 8.03516 9.64286C8.03516 10.176 8.4672 10.6082 9.00016 10.6082Z" fill={detailsFill} />
          <path d="M13.5002 10.6082C14.0331 10.6082 14.4652 10.176 14.4652 9.64286C14.4652 9.1097 14.0331 8.67749 13.5002 8.67749C12.9672 8.67749 12.5352 9.1097 12.5352 9.64286C12.5352 10.176 12.9672 10.6082 13.5002 10.6082Z" fill={detailsFill} />
          <path d="M4.50016 14.4654C5.03311 14.4654 5.46516 14.0332 5.46516 13.5C5.46516 12.9669 5.03311 12.5347 4.50016 12.5347C3.9672 12.5347 3.53516 12.9669 3.53516 13.5C3.53516 14.0332 3.9672 14.4654 4.50016 14.4654Z" fill={detailsFill} />
        </g>
      </g>

    </svg>
  )
}

export default CalendarIcon
