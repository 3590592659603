export const DEFAUL_TOOLTIP_ENTRY_DELAY = 300
export const MEMORY_TOOLTIP_IDS = {
  CHAT_BOT: 'CHAT_BOT',
}

export const SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS = {
  timeout: {
    appear: 500,
    enter: 500,
    exit: 2000,
  },
}
