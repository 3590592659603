import React from 'react'

export interface CheckSquareIconProps extends Common.IconProps {
}

const CheckSquareIcon: React.FC<CheckSquareIconProps> = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  fill = 'none',
  className = '',
  detailsFill = '#5F26E0',
}) => {
  return (
    <svg data-testid={CheckSquareIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path className='checkSquareIconBody' d="M1.35938 4.1731C1.35938 2.51624 2.70252 1.1731 4.35938 1.1731H16.3594C18.0162 1.1731 19.3594 2.51624 19.3594 4.1731V16.1731C19.3594 17.83 18.0162 19.1731 16.3594 19.1731H4.35938C2.70252 19.1731 1.35938 17.83 1.35938 16.1731V4.1731Z" fill="white"/>
      <path d="M4.35938 1.6731H16.3594V0.673096H4.35938V1.6731ZM18.8594 4.1731V16.1731H19.8594V4.1731H18.8594ZM16.3594 18.6731H4.35938V19.6731H16.3594V18.6731ZM1.85938 16.1731V4.1731H0.859375V16.1731H1.85938ZM4.35938 18.6731C2.97866 18.6731 1.85938 17.5538 1.85938 16.1731H0.859375C0.859375 18.1061 2.42638 19.6731 4.35938 19.6731V18.6731ZM18.8594 16.1731C18.8594 17.5538 17.7401 18.6731 16.3594 18.6731V19.6731C18.2924 19.6731 19.8594 18.1061 19.8594 16.1731H18.8594ZM16.3594 1.6731C17.7401 1.6731 18.8594 2.79238 18.8594 4.1731H19.8594C19.8594 2.2401 18.2924 0.673096 16.3594 0.673096V1.6731ZM4.35938 0.673096C2.42638 0.673096 0.859375 2.2401 0.859375 4.1731H1.85938C1.85938 2.79238 2.97866 1.6731 4.35938 1.6731V0.673096Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path className='checkSquareIconCheckMark' d="M14.5147 7.05774L8.97626 13.9808L6.20703 11.9039" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CheckSquareIcon
