import React from 'react'
import * as Sentry from '@sentry/react'

import InternalErrorComponent from '@base/utils/InternalError'

export enum ERROR_BOUNDARY_ERROR_TYPES {
  INNER = 'INNER',
  OUTER = 'OUTER',
  GLOBAL = 'GLOBAL',
}

interface ErrorBoundaryContainerProps {
  /**
   * Type of error boundary
   * @default ERROR_BOUNDARY_ERROR_TYPES.INNER
   */
  type: ERROR_BOUNDARY_ERROR_TYPES,
  /**
   * Children to be rendered
   */
  children: React.ReactNode,
}

interface ErrorBoundaryContainerWrapperProps {
  /**
   * Error object
   */
  error: any,
}

class ErrorBoundaryContainer extends React.Component<ErrorBoundaryContainerProps> {
  public static propTypes: any

  public static defaultProps: any

  renderContent = (wrapperProps: ErrorBoundaryContainerWrapperProps = {} as ErrorBoundaryContainerWrapperProps) => {
    const { type = ERROR_BOUNDARY_ERROR_TYPES.INNER } = this.props
    const { error } = wrapperProps
    const isChunkLoadError = ((error?.name || '') === 'ChunkLoadError')

    switch (type) {
      case ERROR_BOUNDARY_ERROR_TYPES.INNER: {
        return (
          <>
            <InternalErrorComponent isChunkLoadError={isChunkLoadError} />
          </>
        )
      }

      case ERROR_BOUNDARY_ERROR_TYPES.OUTER: {
        return (
          <>
            <InternalErrorComponent isChunkLoadError={isChunkLoadError} sx={{ minHeight: '100vh' }} />
          </>
        )
      }

      default: {
        /* eslint-disable */
        return (
          <div
            style={{
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <h1>OOOPS...</h1>
            <h3>
              
              {
                isChunkLoadError ? (
                  'This application has been updated, please refresh your browser to use the latest version.'
                ) : (
                  'Looks like something is not working. The issue is reported and will be fixed soon. Please contant us if you need any help.'
                )
              }
            </h3>
          </div>
        )
        /* eslint-enable */
      }
    }
  }

  render() {
    const { children } = this.props

    return (
      <Sentry.ErrorBoundary fallback={(props) => this.renderContent(props)} showDialog={false}>
        {children}
      </Sentry.ErrorBoundary>
    )
  }
}

export default ErrorBoundaryContainer
