import React from 'react'

const TradeOffIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'white',
}) => {
  return (
    <svg data-testid={TradeOffIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <g opacity="0.6">
        <line x1="2.01465" y1="15.5" x2="16.0146" y2="15.5" stroke={detailsFill}/>
        <line x1="2.51465" y1="16" x2="2.51465" y2="2" stroke={detailsFill}/>
        <path d="M5.51465 4.5C7.52501 4.5 9.29288 5.06582 10.6883 6.06746M14.0146 13C14.0146 11.0777 13.4973 9.37703 12.5762 8.01179" stroke={detailsFill}/>
        <circle cx="11.7288" cy="6.78571" r="1.28571" stroke={detailsFill}/>
      </g>

    </svg>
  )
}

export default TradeOffIcon
