import Paper from '@mui/material/Paper'
import { GridColDef, gridClasses } from '@mui/x-data-grid-premium'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { useTheme } from '@mui/material'

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import DataGridComponent from '@base/datagrid/data-grid'
import useGridInitialState from '@hooks/useGridInitialState.hook'
import { FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX } from '@constants/files.constants'

export interface FileBrowserPreviewTableComponentProps {
  /**
   * Columns definitions
   */
  columns: GridColDef[]
  /**
   * Data to display
   */
  data: any[]
}

const gridSlotConfig = {
  toolbar: {
    withExport: false,
  },
  pagination: {
    nextIconButtonProps: {
      enableGoToLastPage: true,
    },
    backIconButtonProps: {
      enableGoToFirstPage: true,
    },
  } as any,
}

const FileBrowserPreviewTableComponent: React.FC<FileBrowserPreviewTableComponentProps> = ({
  columns, data,
}) => {
  const theme = useTheme()
  const [containerMaxHeight, setContainerMaxHeight] = useState(0)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const initialState = useGridInitialState(DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE, {
    pagination: {
      paginationModel: {
        pageSize: 50,
      },
    },
  })

  const sx = {
    [`& .${gridClasses.toolbarContainer}`]: {
      borderTop: 'none',
    },
    [`& .${gridClasses.main}`]: {
      borderRadius: '0px',
    },
    [`& .${gridClasses.columnHeader}[data-field^="${FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX}"]`]: {
      backgroundColor: theme.palette.new.rebellious_red_10,
      color: theme.palette.new.rebellious_red,
    },
  }

  const handleResize = useCallback(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    setContainerMaxHeight((tableContainerRef.current.parentElement?.clientHeight || 0))
  }, [tableContainerRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    handleResize()
  }, [handleResize])

  return (
    <Paper
      data-testid={FileBrowserPreviewTableComponent.name}
      sx={{ width: '100%', overflow: 'hidden' }}
      ref={tableContainerRef}
      elevation={0}
    >
      <DataGridComponent
        id={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        name={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        columns={columns}
        rows={data}
        height={`${containerMaxHeight}px`}
        rounded={false}
        enablePersistence={false}
        slotProps={gridSlotConfig}
        initialState={initialState}
        sx={sx}
      />
    </Paper>
  )
}

export default FileBrowserPreviewTableComponent
