import React from 'react'

const DiscoverIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={DiscoverIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M3.85717 17.6656C5.63237 17.6656 7.07145 16.2265 7.07145 14.4513C7.07145 12.6761 5.63237 11.2371 3.85717 11.2371C2.08197 11.2371 0.642883 12.6761 0.642883 14.4513C0.642883 16.2265 2.08197 17.6656 3.85717 17.6656Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.07144 4.16567V2.2371C7.07144 1.89611 6.93598 1.56908 6.69486 1.32796C6.45374 1.08684 6.12672 0.951385 5.78572 0.951385H4.26858C3.96598 0.945881 3.67113 1.04731 3.43595 1.2378C3.20076 1.4283 3.04032 1.69566 2.98287 1.99281L0.707153 13.8085" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.78577 7.37988H12.2143" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.07147 10.5942V14.4514" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1429 17.6656C15.9181 17.6656 17.3572 16.2265 17.3572 14.4513C17.3572 12.6761 15.9181 11.2371 14.1429 11.2371C12.3677 11.2371 10.9286 12.6761 10.9286 14.4513C10.9286 16.2265 12.3677 17.6656 14.1429 17.6656Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9286 4.16567V2.2371C10.9286 1.89611 11.064 1.56908 11.3052 1.32796C11.5463 1.08684 11.8733 0.951385 12.2143 0.951385H13.7314C14.0341 0.945881 14.3289 1.04731 14.5641 1.2378C14.7993 1.4283 14.9597 1.69566 15.0172 1.99281L17.3186 13.8085" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9286 10.5942V14.4514" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default DiscoverIcon
