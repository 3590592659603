import React from 'react'
import { Typography, Box, useTheme } from '@mui/material'
import { PARETOS_GRADIENT } from '@constants/ui.constants'

export interface OptimizeDrillDownGridItemComponentProps {
  header: string,
  subHeader?: string,
  value: string | React.ReactNode | React.ReactNode[],
  colorfull?: boolean,
  borderRight?: boolean,
  borderBottom?: boolean,
}

const OptimizeDrillDownGridItemComponent: React.FC<OptimizeDrillDownGridItemComponentProps> = ({
  header,
  subHeader,
  value,
  colorfull,
  borderRight,
  borderBottom,
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '260px',
        minHeight: '240px',
        padding: theme.spacing(3, 3),
        ...(colorfull ? {
          background: PARETOS_GRADIENT,
          borderBottomRightRadius: theme.spacing(1),
          '& .header': {
            color: theme.palette.new.white,
          },
          '& .subHeader': {
            color: theme.palette.new.white,
          },
          '& .value': {
            color: theme.palette.new.white,
          },
        } : {}),
        ...(borderRight ? {
          borderRight: `1px solid ${theme.palette.new.business_black_20}`,
        } : {}),
        ...(borderBottom ? {
          borderBottom: `1px solid ${theme.palette.new.business_black_20}`,
        } : {}),
      }}
      data-testid={OptimizeDrillDownGridItemComponent.name}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          className='header'
          sx={{
            fontWeight: 500,
            fontSize: '30px',
            lineHeight: '40px',
            color: theme.palette.new.black,
          }}
        >
          {header}
        </Typography>
        {
          subHeader ? (
            <Typography
              className='subHeader'
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: theme.palette.new.black,
              }}
            >
              {subHeader}
            </Typography>
          ) : (
            null
          )
        }
      </Box>
      {
        typeof value === 'string' ? (
          <Typography
            className='value'
            sx={{
              fontWeight: 500,
              fontSize: '48px',
              color: theme.palette.new.black,
              lineHeight: 1,
            }}
          >
            {value}
          </Typography>
        ) : (
          value
        )
      }
    </Box>
  )
}

export default OptimizeDrillDownGridItemComponent
