import React, { memo } from 'react'

const bottomLeftCorner = (x: number, y: number, size: number) => (`L ${x},${y - size}Q ${x},${y} ${x + size},${y}`)
const leftBottomCorner = (x: number, y: number, size: number) => (`L ${x + size},${y}Q ${x},${y} ${x},${y - size}`)
const bottomRightCorner = (x: number, y: number, size: number) => (`L ${x},${y - size}Q ${x},${y} ${x - size},${y}`)
const rightBottomCorner = (x: number, y: number, size: number) => (`L ${x - size},${y}Q ${x},${y} ${x},${y - size}`)
const leftTopCorner = (x: number, y: number, size: number) => (`L ${x + size},${y}Q ${x},${y} ${x},${y + size}`)
const topLeftCorner = (x: number, y: number, size: number) => (`L ${x},${y + size}Q ${x},${y} ${x + size},${y}`)
const topRightCorner = (x: number, y: number, size: number) => (`L ${x},${y + size}Q ${x},${y} ${x - size},${y}`)
const rightTopCorner = (x: number, y: number, size: number) => (`L ${x - size},${y}Q ${x},${y} ${x},${y + size}`)

enum Position {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
}

const LeftOrRight = [Position.Left, Position.Right]

export interface getCenterProps {
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourcePosition: Position,
  targetPosition: Position,
}

const getCenter = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
} : getCenterProps) => {
  const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition)
  const targetIsLeftOrRight = LeftOrRight.includes(targetPosition)

  // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
  // a mixed edge is when one the source is on the left and the target is on the top for example.
  const mixedEdge = (sourceIsLeftOrRight && !targetIsLeftOrRight) || (targetIsLeftOrRight && !sourceIsLeftOrRight)

  if (mixedEdge) {
    const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0
    const centerX = sourceX > targetX ? sourceX - xOffset : sourceX + xOffset

    const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY)
    const centerY = sourceY < targetY ? sourceY + yOffset : sourceY - yOffset

    return [centerX, centerY, xOffset, yOffset]
  }

  const xOffset = Math.abs(targetX - sourceX) / 2
  const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset

  const yOffset = Math.abs(targetY - sourceY) / 2
  const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset

  return [centerX, centerY, xOffset, yOffset]
}

const getMarkerEnd = (markerEnd?: string, markerEndId?: string) => {
  if (typeof markerEndId !== 'undefined' && markerEndId) {
    return `url(#${markerEndId})`
  }

  return typeof markerEnd !== 'undefined' ? 'url(#react-flow__arrow)' : 'none'
}

export interface getSmoothStepPathProps {
  source?: string,
  sourceX: number,
  sourceY: number,
  target?: string,
  targetX: number,
  targetY: number,
  centerX: number,
  centerY: number,
  offsetX: number,
  offsetY: number,
  borderRadius: number,
  sourcePosition: Position,
  targetPosition: Position,
}

const getSmoothStepPath = ({
  source,
  sourceX,
  sourceY,
  sourcePosition = Position.Bottom,
  target,
  targetX,
  targetY,
  targetPosition = Position.Top,
  borderRadius = 5,
  centerX,
  centerY,
  offsetX,
  offsetY,
} : getSmoothStepPathProps) => {
  const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX))
  const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY))
  const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY)
  const leftAndRight = [Position.Left, Position.Right]
  const getCx = () => {
    if (source === '1') {
      return sourceX + 30
    }

    if (target === '6') {
      return targetX - 30
    }

    return centerX
  }

  const cX = getCx()
  const cY = centerY

  let firstCornerPath = null
  let secondCornerPath = null

  if (sourceX <= targetX) {
    firstCornerPath =
      sourceY <= targetY ? bottomLeftCorner(sourceX, cY, cornerSize) : topLeftCorner(sourceX, cY, cornerSize)
    secondCornerPath =
      sourceY <= targetY ? rightTopCorner(targetX, cY, cornerSize) : rightBottomCorner(targetX, cY, cornerSize)
  } else {
    firstCornerPath =
      sourceY < targetY ? bottomRightCorner(sourceX, cY, cornerSize) : topRightCorner(sourceX, cY, cornerSize)
    secondCornerPath =
      sourceY < targetY ? leftTopCorner(targetX, cY, cornerSize) : leftBottomCorner(targetX, cY, cornerSize)
  }

  if (leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY ? rightTopCorner(cX, sourceY, cornerSize) : rightBottomCorner(cX, sourceY, cornerSize)
      secondCornerPath =
        sourceY <= targetY ? bottomLeftCorner(cX, targetY, cornerSize) : topLeftCorner(cX, targetY, cornerSize)
    }
  } else if (leftAndRight.includes(sourcePosition) && !leftAndRight.includes(targetPosition)) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY ? rightTopCorner(targetX, sourceY, cornerSize) : rightBottomCorner(targetX, sourceY, cornerSize)
    } else {
      firstCornerPath =
        sourceY <= targetY ? bottomRightCorner(sourceX, targetY, cornerSize) : topRightCorner(sourceX, targetY, cornerSize)
    }
    secondCornerPath = ''
  } else if (!leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
    if (sourceX <= targetX) {
      firstCornerPath =
        sourceY <= targetY ? bottomLeftCorner(sourceX, targetY, cornerSize) : topLeftCorner(sourceX, targetY, cornerSize)
    } else {
      firstCornerPath =
        sourceY <= targetY ? bottomRightCorner(sourceX, targetY, cornerSize) : topRightCorner(sourceX, targetY, cornerSize)
    }
    secondCornerPath = ''
  }

  return `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`
}

export interface ReactFlowCustomEdgeProps {
  source?: string,
  label?: string,
  sourceX: number,
  sourceY: number,
  target?: string,
  targetX: number,
  targetY: number,
  borderRadius: number,
  sourcePosition: Position,
  targetPosition: Position,
  markerEnd?: string,
  markerEndId?: string,
  style?: any,
}

export default memo(
  (props: ReactFlowCustomEdgeProps) => {
    const {
      source,
      sourceX,
      sourceY,
      target,
      targetX,
      targetY,
      label,
      style,
      sourcePosition = Position.Bottom,
      targetPosition = Position.Top,
      markerEnd,
      markerEndId,
      borderRadius = 25,
    } = props

    const [centerX, centerY, offsetX, offsetY] = getCenter({
      sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition,
    })

    const path = getSmoothStepPath({
      source,
      sourceX,
      sourceY,
      sourcePosition,
      target,
      targetX,
      targetY,
      targetPosition,
      borderRadius,
      centerX,
      centerY,
      offsetX,
      offsetY,
    })

    const finalMarkerEnd = getMarkerEnd(markerEnd, markerEndId)

    const text = label ? (
      null
    ) : (
      null
    )

    return (
      <>
        <marker className='react-flow__arrowhead' id='react-flow__arrow' markerWidth='12.5' markerHeight='12.5' viewBox='-10 -10 20 20' orient='auto' refX='0' refY='0'>
          <polyline stroke='#b1b1b7' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' fill='none' points='-5,-4 0,0 -5,4' />
        </marker>

        <path
          style={style}
          className='react-flow__edge-path'
          d={path}
          markerEnd={finalMarkerEnd}
          data-testid='ReactFlowCustomEdge'
        />
        {text}
      </>
    )
  },
)
