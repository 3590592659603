import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'

import UseCaseListItemBlockComponent from '@components/dashboard/UseCaseListItemBlock'
import { DEFAULT_PADDING } from '@constants/ui.constants'

export interface TargetsBlockComponentProps {
  /**
   * Use case details
   */
  useCase: UseCase.DetailsExtended
}

const TargetsBlockComponent: React.FC<TargetsBlockComponentProps> = ({ useCase }) => {
  const intl = useIntl()

  const targets = useMemo(() => (useCase.outputParameters?.map((parameter) => parameter.name) || []), [useCase])

  const targetText = useMemo(() => {
    if (targets.length === 0) {
      return intl.formatMessage({ id: 'use_cases.targets.to_be_defined' })
    }

    return targets[0]
  }, [intl, targets])

  return (
    <UseCaseListItemBlockComponent
      title={intl.formatMessage({ id: 'use_cases.targets.title' })}
    >
      <Box
        data-testid={TargetsBlockComponent.name}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: DEFAULT_PADDING.SMALL,
        }}
      >
        <Typography
          variant='body1'
          title={targetText}
          noWrap={true}
          maxWidth='165px'
        >
          {targetText}
        </Typography>

        {
          targets.length > 1 ? (
            <Typography
              variant='body1'
              title={targets.slice(1).join(', ')}
            >
              {`+${targets.length - 1}`}
            </Typography>
          ) : (
            null
          )
        }
      </Box>
    </UseCaseListItemBlockComponent>
  )
}

export default TargetsBlockComponent
