import React from 'react'

const LogoIcon: React.FC<Common.IconProps> = ({
  width = 48,
  height = 20,
  viewBox = '0 0 48 20',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={LogoIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillRule="evenodd" clipRule="evenodd" d="M0.375 0H8.28249H8.28258H16.1901C16.1901 6.61157 21.5345 12.011 28.0787 12.011C34.6229 12.011 39.9673 6.61157 39.9673 0H47.8749C47.8749 11.0744 39.0402 20 28.0787 20C17.1911 20 8.31414 11.1061 8.28258 0.0590976V20H0.375V0Z" fill={detailsFill} />

    </svg>
  )
}

export default LogoIcon
