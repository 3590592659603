import React from 'react'
import {
  DialogTitle,
  DialogTitleProps,
  Typography,
} from '@mui/material'

import { DEFAULT_PADDING } from '@constants/ui.constants'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

export interface DialogTitleComponentProps extends DialogTitleProps {
  /**
   * Title of the dialog
   */
  title: string
}

export const DialogTitleComponent: React.FC<DialogTitleComponentProps> = ({
  title,
  ...props
}) => {
  const styles = {
    display: 'flex',
    justifyContent: 'flex-start',
    px: DEFAULT_PADDING.LARGE,
    py: DEFAULT_PADDING.LARGE,
    ...props.sx,
  }

  const titleStyles = {
    ...getTextBackgroundFillStyles(),
    fontWeight: 500,
  }

  return (
    <DialogTitle
      {...props}
      sx={styles}
      data-testid={DialogTitleComponent.name}
    >
      <Typography
        component='div'
        variant='h5'
        sx={titleStyles}
      >
        {title}
      </Typography>
    </DialogTitle>
  )
}

export default DialogTitleComponent
