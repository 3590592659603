import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { POSSIBLE_TEMPLATE_TYPES } from '@constants/flow.constants'

import SwitchFieldComponent from '@base/forms/SwitchField'
import FormGroupComponent from '@base/forms/FormGroup'

export interface TypesSelectorFieldComponentProps {
  /**
   * Label for the field
   */
  label: string,
  /**
   * Value of the field
   */
  value: string[],
  /**
   * If the field is disabled
   */
  disabled?: boolean,
  /**
   * Callback for the field change
   */
  onChange: {
    (value: string[]): any,
  },
}

const ParameterTypesSelectorFieldComponent: React.FC<TypesSelectorFieldComponentProps> = ({
  onChange,
  label,
  disabled,
  value = [],
}) => {
  const intl = useIntl()

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name || ''
    let finalValue = []

    if (value.includes(fieldName)) {
      finalValue = value.filter((item) => item !== fieldName)
    } else {
      finalValue = value.concat(fieldName)
    }

    onChange(finalValue)
  }

  return (
    <Box
      data-testid={ParameterTypesSelectorFieldComponent.name}
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <FormGroupComponent label={label}>
        <SwitchFieldComponent
          label={intl.formatMessage({ id: 'connect.modal.parameter.types.active' })}
          onChange={handleFieldChange}
          name={POSSIBLE_TEMPLATE_TYPES.ACTIVE}
          value={value.includes(POSSIBLE_TEMPLATE_TYPES.ACTIVE)}
          checked={value.includes(POSSIBLE_TEMPLATE_TYPES.ACTIVE)}
          disabled={disabled}
        />
        <SwitchFieldComponent
          label={intl.formatMessage({ id: 'connect.modal.parameter.types.passive' })}
          onChange={handleFieldChange}
          name={POSSIBLE_TEMPLATE_TYPES.PASSIVE}
          value={value.includes(POSSIBLE_TEMPLATE_TYPES.PASSIVE)}
          checked={value.includes(POSSIBLE_TEMPLATE_TYPES.PASSIVE)}
          disabled={disabled}
        />
        <SwitchFieldComponent
          label={intl.formatMessage({ id: 'connect.modal.parameter.types.external' })}
          onChange={handleFieldChange}
          name={POSSIBLE_TEMPLATE_TYPES.EXTERNAL}
          value={value.includes(POSSIBLE_TEMPLATE_TYPES.EXTERNAL)}
          checked={value.includes(POSSIBLE_TEMPLATE_TYPES.EXTERNAL)}
          disabled={disabled}
        />
        <SwitchFieldComponent
          label={intl.formatMessage({ id: 'connect.modal.parameter.types.target' })}
          onChange={handleFieldChange}
          name={POSSIBLE_TEMPLATE_TYPES.TARGET}
          value={value.includes(POSSIBLE_TEMPLATE_TYPES.TARGET)}
          checked={value.includes(POSSIBLE_TEMPLATE_TYPES.TARGET)}
          disabled={disabled}
        />
      </FormGroupComponent>
    </Box>
  )
}

export default ParameterTypesSelectorFieldComponent
