import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useRouteMatch, Prompt } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { selectNodeByRoute } from '@redux/modules/file-service/file-service.utils'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { RequestFileDownloadActionPayload } from '@redux/modules/file-service/file-service.types'
import { FILE_BROWSER_SORTING_OPTIONS, FILE_BROWSER_SORTING_ORDER_OPTIONS } from '@constants/files.constants'
import {
  requestFileDownloadAction, requestFilesListAction,
  requestFileUploadAction, requestSortingChangeAction,
} from '@redux/modules/file-service/file-service.actions'

import PageLayoutContainer from '@containers/application/PageLayout'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'

import {
  getBrowserTree, getFilesList,
  isFetchingFiles, isRefreshingFilesList,
  isUploadInProgress, getSortingKey, getSortingOrder,
} from '@redux/modules/file-service/file-service.selectors'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'
import FileBrowserViewComponent from '@components/file-browser/FileBrowserView'
import FileBrowserHeaderComponent from '@components/file-browser/FileBrowserHeader'
import FileBrowserItemRemovalDialog from '@components/file-browser/FileBrowserItemRemovalDialog'
import FileBrowserItemPreviewDialog from '@components/file-browser/FileBrowserItemPreviewDialog'
import FileBrowserUploadButtonComponent from '@components/file-browser/FileBrowserUploadButton'
import FileBrowserDownloadByUrlButtonComponent from '@components/file-browser/FileBrowserDownloadByUrlButton'

const FileBrowserContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const match = useRouteMatch<Common.FileBrowserRouterMatch>()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const isFetching = useSelector((state) => isFetchingFiles(state))
  const browserTree = useSelector((state) => getBrowserTree(state))
  const filesList = useSelector((state) => getFilesList(state))
  const isRefreshing = useSelector((state) => isRefreshingFilesList(state))
  const isUploading = useSelector((state) => isUploadInProgress(state))
  const sortingKey = useSelector((state) => getSortingKey(state))
  const sortingOrder = useSelector((state) => getSortingOrder(state))

  const [searchValue, setSearchValue] = useState<string>('')
  const [showSearchBar, setShowSearchBar] = React.useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean | undefined>(undefined)
  const [overwrite, setOverwrite] = useState<boolean>(false)

  const [containerMaxHeight, setContainerMaxHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputFileRef: React.MutableRefObject<any> = useRef(null)

  const useCaseId = match.params.usecase
  const selectedBrowserNode = useMemo(() => {
    if (!browserTree || showSearchBar) {
      return null
    }

    return selectNodeByRoute(browserTree, match.params[0])
  }, [browserTree, match, showSearchBar])

  useLayoutEffect(() => {
    dispatch(
      requestFilesListAction({
        useCaseId,
        refresh: refresh !== undefined,
        silent: refresh !== undefined,
      }),
    )
  }, [dispatch, useCaseId, refresh])

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const handleShowSearchBar = (state: boolean) => {
    setShowSearchBar(state)
    setSearchValue('')
  }

  const handleResize = useCallback(() => {
    if (!containerRef || !containerRef.current) {
      return
    }

    setContainerMaxHeight((containerRef.current.parentElement?.clientHeight || 0) - 215)
  }, [containerRef])

  const handleOverwriteChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setOverwrite(e.target.checked)
  }, [])

  const handleUpload = useCallback(() => {
    inputFileRef.current.click()
  }, [])

  const handleDownload = useCallback((payload: RequestFileDownloadActionPayload) => {
    dispatch(
      requestFileDownloadAction(payload),
    )
  }, [dispatch])

  const handleSortingChange = (key: FILE_BROWSER_SORTING_OPTIONS, order: FILE_BROWSER_SORTING_ORDER_OPTIONS) => {
    dispatch(
      requestSortingChangeAction(
        {
          sortingKey: key,
          sortingOrder: order,
        },
      ),
    )
  }

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    const file: File | null = files ? files[0] : null

    if (!file) {
      return
    }

    const folderPath = match.params[0] ? match.params[0] : ''
    const filePath = folderPath ? `${folderPath}/${file.name}` : file.name

    dispatch(
      requestFileUploadAction({
        file,
        overwrite,
        fileKey: filePath,
      }),
    )

    e.target.value = ''
  }, [dispatch, match, overwrite])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return
    }

    handleResize()
  }, [handleResize])

  const pageBarRightSideBlocks = (
    <>
      <ToggleButtonComponent
        label={intl.formatMessage({ id: 'fileManager.header.overrideMode' })}
        onChange={handleOverwriteChange}
        name='overwrite'
        value={overwrite}
        checked={overwrite}
        disabled={isUploading}
      />

      <FileBrowserUploadButtonComponent
        handleUpload={handleUpload}
        overwrite={overwrite}
        isUploading={isUploading}
      />

      <FileBrowserDownloadByUrlButtonComponent
        handleDownload={handleDownload}
      />

      <CallToActionButtonComponent
        name='refreshButton'
        onClick={handleRefresh}
        loading={isRefreshing}
        label={intl.formatMessage({ id: 'common.refresh' })}
        disabled={isUploading}
      />
    </>
  )

  if (!isAdmin && !isFetching) {
    return null
  }

  return (
    <PageLayoutContainer
      isFetching={isFetching}
      title={intl.formatMessage({ id: 'fileManager.browser_tab.title' }, { name: useCase.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      containerProps={{
        ref: containerRef,
      }}
    >
      <FileBrowserHeaderComponent
        searchValue={searchValue}
        showSearchBar={showSearchBar}
        handleSearch={(value) => setSearchValue(value)}
        handleShowSearchBar={handleShowSearchBar}
        handleSortingChange={handleSortingChange}
        isUploading={isUploading}
        isRefreshing={isRefreshing}
        fileStorageBucket={useCase.fileStorageBucket}
        sortingKey={sortingKey}
        sortingOrder={sortingOrder}
      />

      <FileBrowserViewComponent
        filesList={filesList}
        browserTree={selectedBrowserNode}
        searchValue={searchValue}
        showSearchBar={showSearchBar}
        closeSearch={() => handleShowSearchBar(false)}
        containerMaxHeight={containerMaxHeight}
      />

      <FileBrowserItemRemovalDialog />

      <FileBrowserItemPreviewDialog />

      <input ref={inputFileRef} onChange={onFileChange} type='file' id='uploadInput' style={{ display: 'none' }} />

      <Prompt
        when={isUploading}
        message={intl.formatMessage({ id: 'common.dropzone.leave' })}
      />
    </PageLayoutContainer>
  )
}

export default FileBrowserContainer
