import React, { useMemo } from 'react'

import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete'
import DropdownButtonComponent from '@base/dropdowns/DropdownButton'

import {
  Popper, InputBase, Box,
  Typography, MenuItem,
  useTheme, MenuList,
  styled,
} from '@mui/material'

import { TRACKING_ACTIONS, TRACKING_MODULES } from '@utils/tracking.utils'
import { DEFAULT_BORDER_RADIUS, DEFAULT_LAYOUT_FONT_SIZE, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'

export const DEFAULT_WIDTH = '380px'
export const MAX_OPTION_ITEM_WIDTH = '360px'

const TopBarInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: DEFAULT_LAYOUT_FONT_SIZE,
    color: theme.palette.new.black,
    height: theme.spacing(5.8),
    padding: theme.spacing(0, 2.4),
    width: '100%',
    borderRadius: '0px',
    position: 'relative',
    backgroundColor: theme.palette.new.smokey_silver,
    border: '1px solid',
    borderColor: theme.palette.new.business_black,
    caretColor: theme.palette.new.carret,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      borderColor: theme.palette.new.business_black,
    },
    '&:focus': {
      borderColor: theme.palette.new.business_black,
      backgroundColor: theme.palette.new.smokey_silver,
      '&::placeholder': {
        opacity: 0,
      },
    },
    '&:hover:focus': {
      borderColor: theme.palette.new.business_black,
    },
    '&::placeholder': {
      opacity: 0.2,
      color: theme.palette.new.business_black,
    },
    '&::-webkit-search-cancel-button': {
      height: '16px',
      width: '16px',
      borderRadius: 0,
      background: 'url("/close.svg") no-repeat 50% 50%',
      WebkitAppearance: 'none',
      cursor: 'pointer',
      opacity: 1,
    },
    '&[value]:not([value=""])': {
      color: theme.palette.new.versatile_violet,
      borderColor: theme.palette.new.versatile_violet,
      backgroundColor: theme.palette.new.versatile_violet_10,
    },
  },
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
  minWidth: DEFAULT_WIDTH,
  backgroundColor: theme.palette.new.white,
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
  borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
  boxShadow: DEFAULT_SELECT_BOX_SHADOW,
  zIndex: 100000,
  marginTop: '-49px !important',
  marginLeft: '-13px !important',
  '& .MuiAutocomplete-popperDisablePortal': {
    minWidth: DEFAULT_WIDTH,
    position: 'relative !important' as any,
    transform: 'none !important',
  },
}))

export interface TopBarAutocompleteComponentProps<T> {
  /**
   * Name of the component
   */
  name: string
  /**
   * List of options to be displayed in the dropdown
   */
  options: T[]
  /**
   * Value of the selected option
   */
  value: T | null
  /**
   * Custom data-testid attribute
   */
  dataTestId?: string
  /**
   * Callback to be called when the button is clicked to open the dropdown
   */
  onOpen?: () => void
  /**
   * Callback to be called when the dropdown is closed
   */
  onClose?: () => void
  /**
   * Callback to be called when the value changes
   */
  onChange: (event: React.SyntheticEvent, newValue: T) => void
  /**
   * Function to compare if the option is equal to the selected value
   * @param option Option to compare
   * @param selectedValue Selected value
   * @returns True if the option is equal to the selected value
   */
  isOptionEqualToValue: (option: T, selectedValue: T) => boolean
  /**
   * Function to get the label of the option
   * @param option Option to get the label from
   * @returns Label of the option
   */
  getOptionLabel: (option: T) => string
  /**
   * Aria label for the button
   */
  buttonAriaLabel?: string
  /**
   * Label for the button
   */
  buttonLabel?: string
  /**
   * Placeholder for the input
   */
  inputPlaceholder: string
  /**
   * Text to be displayed when there are no options
   */
  noOptionsText: string
}

const TopBarAutocompleteComponent = <T extends { name: string, companyId: string }>({
  name,
  dataTestId,
  buttonLabel,
  buttonAriaLabel,
  onOpen,
  onClose,
  onChange,
  isOptionEqualToValue,
  getOptionLabel,
  value,
  options,
  inputPlaceholder,
  noOptionsText,
}: TopBarAutocompleteComponentProps<T>) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)

    if (onOpen) {
      onOpen()
    }
  }

  const handleClose = (event: React.SyntheticEvent, reason?: AutocompleteCloseReason) => {
    if (reason !== 'toggleInput') {
      setAnchorEl(null)

      if (onClose) {
        onClose()
      }
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValues: T[]) => {
    onChange(event, newValues[newValues.length - 1]!)

    handleClose(event)
  }

  const open = Boolean(anchorEl)
  const testId = dataTestId || TopBarAutocompleteComponent.name
  const trackingProps = {
    moduleName: TRACKING_MODULES.TOP_BAR,
    componentName: name,
    actionName: TRACKING_ACTIONS.CLICK,
  }

  const slotProps = useMemo(() => {
    return {
      paper: {
        sx: {
          minWidth: DEFAULT_WIDTH,
          border: `1px solid ${theme.palette.new.business_black_20} !important`,
          borderTop: 'none',
          boxShadow: 'none',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
          borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
          '& .MuiAutocomplete-listbox': {
            maxHeight: '70vh',
          },
          '& .MuiAutocomplete-noOptions': {
            backgroundColor: theme.palette.new.smokey_silver,
            padding: `${theme.spacing(0)} !important`,
            height: '36px',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    }
  }, [theme])

  const autocompleteStyles = useMemo(() => {
    return {
      '&.MuiAutocomplete-root': {
        borderBottom: 'none',
        marginTop: '1px',
        height: theme.spacing(6),
      },
    }
  }, [theme])

  return (
    <Box
      data-testid={testId}
    >
      <DropdownButtonComponent
        name={name}
        onClick={handleButtonClick}
        open={open}
        ariaLabel={buttonAriaLabel}
        trackingProps={trackingProps}
        label={buttonLabel}
        maxWidth='200px'
      />

      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
      >
        <Box
          data-testid={`${testId}-autocomplete`}
          sx={{
            minWidth: DEFAULT_WIDTH,
          }}
        >
          <Autocomplete
            open={true}
            onClose={handleClose}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={[value!]}
            renderTags={() => null}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleChange}
            noOptionsText={(
              <Typography
                variant='body1'
                px='24px'
                color={theme.palette.new.business_black_40}
              >
                {noOptionsText}
              </Typography>
            )}
            ListboxComponent={MenuList}
            renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: T, { selected } : { selected: boolean }) => {
              return (
                <MenuItem
                  {...props}
                  selected={selected}
                  sx={{ '&&': { px: '24px', py: '8px' } }}
                >
                  <Typography
                    variant='body1'
                    noWrap={true}
                    maxWidth={MAX_OPTION_ITEM_WIDTH}
                  >
                    {option?.name}
                  </Typography>
                </MenuItem>
              )
            })}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={(params: any) => (
              <TopBarInput
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
              />
            )}
            slotProps={slotProps}
            sx={autocompleteStyles}
          />
        </Box>
      </StyledPopper>
    </Box>
  )
}

export default TopBarAutocompleteComponent
