import React from 'react'
import { IntlShape } from 'react-intl'

import {
  TableRow,
  TableCell,
  Typography,
  TableHead,
  Box,
} from '@mui/material'

import { createId } from '@utils/common.utils'
import { ASC_SORTING_ORDER } from '@constants/filters.constants'

import ArrowUpIcon from '@icons/arrowUp.icon'
import ArrowDownIcon from '@icons/arrowDown.icon'
import InfoButtonComponent from '@base/buttons/InfoButton'

import { TableComponentProps } from '@components/_tables/table/Table.component'
import palette from '@configuration/theme/theme.palette'

type ITableBodyPropsFields = 'withTitles' | 'withAligment' | 'fixedWidth' | 'tableColumns' | 'isFetching' | 'overlayFetching'

interface ITableHeaderProps extends Pick<TableComponentProps<IntlShape>, ITableBodyPropsFields> {
  handleSorting: (key: string) => void
  anyColHasHeader?: boolean
  paginationConfig: Common.PaginationConfig
}

export const TableHeaderComponent: React.FC<ITableHeaderProps> = (props) => {
  const {
    withAligment,
    withTitles,
    fixedWidth,
    tableColumns,
    isFetching,
    handleSorting,
    paginationConfig,
  } = props

  const {
    sortingDirection,
    sortingKey,
  } = paginationConfig

  const upSortingOpacity = sortingDirection === ASC_SORTING_ORDER ? '1' : '0.2'
  const downSortingOpacity = sortingDirection === ASC_SORTING_ORDER ? '0.2' : '1'
  const anyColHasHeader = tableColumns?.some((column) => column.header)

  return (
    <TableHead data-testid={TableHeaderComponent.name}>
      <TableRow>
        {
          tableColumns?.map((column, index) => {
            const tableRowWidth = (fixedWidth && column.width) ? { width: column.width } : {}
            const tableRowTitle = (withTitles && column.label) ? { title: column.label } : {}
            const isSortingActive = column.sorting && (column.key === sortingKey)
            const tableCellStyles = {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '220px',
              textTransform: 'uppercase',
              cursor: column.sorting ? 'pointer' : 'default',

              ...(isSortingActive ? {
                fontSize: '18px',
                fontWeight: 500,
                color: palette.new.black,
                '& .columnLabelText': {
                  fontSize: '18px',
                  fontWeight: 500,
                  color: palette.new.black,
                },
                '& .columnHeader': {
                  fontSize: '16px',
                  fontWeight: 400,
                  color: palette.new.black_d,
                },
              } : {}),
            }

            return (
              <TableCell
                key={createId(column.key, index)}
                sx={tableCellStyles}
                align={column.alignment || (withAligment && (index === 0) ? 'left' : 'right')}
                onClick={(column.sorting && !isFetching) ? () => handleSorting(column.key) : undefined}
                {...tableRowTitle}
                {...tableRowWidth}
              >
                <Box
                  sx={{
                    ...(withAligment && column.alignment === 'right' ? {
                      display: 'flex',
                      justifyContent: 'flex-end',
                    } : {}),

                    ...(column.tooltip ? {
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    } : {}),

                    ...(isSortingActive ? {
                      fontSize: '18px',
                      fontWeight: 500,
                      color: palette.new.black,
                      '& .columnLabelText': {
                        fontSize: '18px',
                        fontWeight: 500,
                        color: palette.new.black,
                      },
                      '& .columnHeader': {
                        fontSize: '16px',
                        fontWeight: 400,
                        color: palette.new.black_d,
                      },
                    } : {}),
                  }}
                >
                  {
                    column.tooltip ? (
                      <InfoButtonComponent
                        name='tableHeaderTooltip'
                        tooltip={column.tooltip}
                        placement='top'
                        sx={{ mr: 1 }}
                      />
                    ) : (
                      null
                    )
                  }
                  <Box
                    sx={column.header ? {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    } : {}}
                  >
                    {
                      column.header ? (
                        <Typography
                          className='columnHeader'
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            color: palette.new.black_d,
                            textTransform: 'none',
                          }}
                        >
                          {column.header}
                        </Typography>
                      ) : (
                        anyColHasHeader ? 'ㅤ' : null
                      )
                    }
                    <Box
                      component='span'
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'inherit',
                      }}
                    >
                      <Typography
                        className='columnLabelText'
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: palette.new.black_d,
                          textTransform: 'none',
                        }}
                      >
                        {column.label}
                      </Typography>

                      {
                        column.sorting ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: '10px',
                            }}
                          >
                            <ArrowUpIcon opacity={isSortingActive ? upSortingOpacity : '0.2'} />
                            <ArrowDownIcon opacity={isSortingActive ? downSortingOpacity : '0.2'} />
                          </Box>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            )
          })
        }
      </TableRow>
    </TableHead>
  )
}

export default TableHeaderComponent
