import React from 'react'

import { Grid } from '@mui/material'

import UseCaseListItemComponent, { UseCaseListItemComponentProps } from '@components/dashboard/UseCaseListItem/UseCaseListItem.component'

export interface UseCasesListComponentProps extends Pick<UseCaseListItemComponentProps, 'onAdminButtonClick' | 'onItemClick' | 'isAdmin'> {
  useCases: UseCase.DetailsExtended[]
}

const UseCasesListComponent: React.FC<UseCasesListComponentProps> = ({
  useCases,
  isAdmin,
  onItemClick,
  onAdminButtonClick,
}) => {
  if (!useCases || useCases.length === 0) {
    return null
  }

  return (
    <Grid
      container={true}
      data-testid={UseCasesListComponent.name}
      spacing={2}
    >
      {
        useCases.map((rowItem, index) => {
          return (
            <UseCaseListItemComponent
              useCase={rowItem}
              onItemClick={onItemClick}
              onAdminButtonClick={onAdminButtonClick}
              isAdmin={isAdmin}
              key={index}
            />
          )
        })
      }
    </Grid>
  )
}

export default UseCasesListComponent
