import React from 'react'
import { useIntl } from 'react-intl'

import {
  Box, styled,
  Typography, useTheme,
} from '@mui/material'

import TradeOffIcon from '@icons/tradeOff.icon'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { TRADE_OFFS_PATH } from '@constants/routes.constants'
import { OPTIMIZE_RESTOCK_SELECTOR_TYPES } from '@constants/optimize.constants'

const TypographyItemText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(255, 255, 255, 0.6)',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '20px',
  '& svg': {
    marginRight: theme.spacing(1),
  },
  '@media (max-width: 1680px) and (min-width:1480px)': {
    fontSize: '16px',
    lineHeight: '18px',
  },
  '@media (max-width: 1480px) and (min-width:1310px)': {
    fontSize: '14px',
    lineHeight: '16px',
  },
  '@media (max-width: 1310px) and (min-width:1200px)': {
    fontSize: '14px',
    lineHeight: '16px',
  },
  '@media (max-width: 780px)': {
    fontSize: '12px',
    lineHeight: '16px',
  },
}))

const SelectorItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: theme.spacing(4),
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  '&:hover': {
    '& .selectorItemText': {
      color: 'rgba(255, 255, 255, 0.4)',
    },
  },
}))

export interface OptimizeStrategySelectorComponentProps {
  setSelectedType: (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => void,
  selectedType: OPTIMIZE_RESTOCK_SELECTOR_TYPES,
}

const OptimizeStrategySelectorComponent: React.FC<OptimizeStrategySelectorComponentProps> = (props) => {
  const {
    setSelectedType,
    selectedType,
  } = props

  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()
  const match = useRouteMatch<Common.RouterMatch>()
  const isTradeOffsSelected = selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS

  const onSelectorItemClick = (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => {
    switch (type) {
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST:
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE:
        setSelectedType(type)
        break

      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS:
      default:
        history.push(generatePath(TRADE_OFFS_PATH, match.params))
    }
  }

  const selectorSelectedStyles = {
    backgroundColor: theme.palette.new.white,
    borderRadius: theme.spacing(5),
    '& svg': {
      color: theme.palette.new.black,
    },
    '& .selectorItemText': {
      color: theme.palette.new.black,
    },
    '&:hover': {
      opacity: 0.6,
      '& .selectorItemText': {
        color: theme.palette.new.black,
      },
    },
  }

  return (
    <Box
      data-testid={OptimizeStrategySelectorComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: theme.spacing(4),
        marginTop: theme.spacing(0.5),
        width: '100%',
      }}
    >
      <SelectorItem
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST)}
        sx={selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST ? selectorSelectedStyles : {}}
      >
        <TypographyItemText className='selectorItemText'>
          {intl.formatMessage({ id: 'optimize.selector.replenishmentCost' })}
        </TypographyItemText>
      </SelectorItem>
      <SelectorItem
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE)}
        sx={selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE ? selectorSelectedStyles : {}}
      >
        <TypographyItemText className='selectorItemText'>
          {intl.formatMessage({ id: 'optimize.selector.revenue' })}
        </TypographyItemText>
      </SelectorItem>
      <SelectorItem
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS)}
        sx={selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS ? selectorSelectedStyles : {}}
      >
        <TypographyItemText className='selectorItemText'>
          <TradeOffIcon detailsFill={isTradeOffsSelected ? 'black' : 'white'} />
          {intl.formatMessage({ id: 'optimize.selector.customTradeOff' })}
        </TypographyItemText>
      </SelectorItem>
    </Box>
  )
}

export default OptimizeStrategySelectorComponent
