import React from 'react'
import { useIntl } from 'react-intl'
import get from 'lodash.get'
import {
  Box, Grid,
  styled,
  Typography, useTheme,
} from '@mui/material'

import { MAX_SELECTED_POINTS } from '@constants/analysis.constants'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { createId } from '@utils/common.utils'
import ModalButtonComponent from '@base/buttons/ModalButton'

const ComparisonItemUnits = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: 'rgba(0, 0, 0, 0.4)',
  minWidth: theme.spacing(7),
  textAlign: 'right',
}))

const ComparisonItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0, 2),
  height: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.new.grey_a}`,
}))

const ComparisonItemText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.6)',
  width: '100%',
  minWidth: theme.spacing(20),
  [theme.breakpoints.down('xl')]: {
    minWidth: theme.spacing(15),
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '12px',
    minWidth: theme.spacing(10),
  },
}))

export type TradeOffsDatasetSolution = (Socrates.TradeOffsDatasetKpi & Socrates.TradeOffsDatasetDesign)

export interface TradeOffsPanelComponentProps {
  tradeOffsComparisonMeta?: Socrates.TradeOffsComparisonMetaItem[],
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  solutionToExecute: number | null,
  handleDownloadSelection: {
    (index: number): any,
  },
  selectTradeOffsPoint(payload: Socrates.SelectedTradeOffsPoint): any,
  setSolutionToExecute: {
    (value: number | null): any,
  },
}

const TradeOffsPanelComponent: React.FC<TradeOffsPanelComponentProps> = (props) => {
  const {
    tradeOffsComparisonMeta = [],
    selectedPoints,
    handleDownloadSelection,
    solutionToExecute,
    setSolutionToExecute,
    selectTradeOffsPoint,
  } = props

  const theme = useTheme()
  const intl = useIntl()
  const isPointsSelected = selectedPoints.length > 0
  const numOfPoints = (new Array(MAX_SELECTED_POINTS)).fill('')
  const setSolutionToExecuteHandler = (index: number) => {
    if (isSolutionToExecuteDisabled(index)) {
      return
    }

    if (index === solutionToExecute) {
      setSolutionToExecute(null)
    } else {
      setSolutionToExecute(index)
    }

    selectTradeOffsPoint({
      payload: selectedPoints[index],
      decision: true,
      deselect: (index === solutionToExecute),
    })
  }

  const isSolutionToExecuteActive = (index: number) => {
    return index === solutionToExecute
  }

  const isSolutionToExecuteDisabled = (index: number) => {
    return index + 1 > selectedPoints.length
  }

  const getValueByIndex = (index: number, item: Socrates.TradeOffsComparisonMetaItem) => {
    if (isSolutionToExecuteDisabled(index)) {
      return {
        value: '-',
        original: '-',
      }
    }

    const datasetDesign = get(selectedPoints[index], 'datasetPayload.datasetDesign', [])
    const datasetKpis = get(selectedPoints[index], 'datasetPayload.datasetKpis', [])
    const solutions: TradeOffsDatasetSolution[] = ([] as TradeOffsDatasetSolution[]).concat(datasetDesign).concat(datasetKpis)
    const indexCol = solutions.find((solutionItem) => ((solutionItem.label === item.name) && (solutionItem.kpi === item.isKPI))) || {} as TradeOffsDatasetSolution

    return {
      value: defaultNumberFormatter(indexCol.value),
      original: indexCol.value,
    }
  }

  return (
    <Grid
      item={true}
      xs={4}
      data-testid={TradeOffsPanelComponent.name}
      sx={{
        backgroundColor: theme.palette.new.grey_d,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          height: theme.spacing(8),
          borderBottom: `1px solid ${theme.palette.new.grey_a}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            padding: theme.spacing(0, 2),
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.new.black,
            }}
          >
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
          </Typography>
        </Box>
        <Box />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: '1px',
          }}
        >
          <ComparisonItem>
            <ComparisonItemText className='comparisonItemText' />
            {
              numOfPoints.map((item, index) => {
                return (
                  <Typography
                    key={createId(index, 'item-number')}
                    sx={{
                      width: '100%',
                      minWidth: theme.spacing(7),
                      color: theme.palette.new.black,
                      textAlign: 'right',
                      fontSize: '12px',

                      ...((isSolutionToExecuteActive(index)) ? {
                        fontWeight: 500,
                        color: theme.palette.new.pink,
                      } : {}),
                    }}
                  >
                    {index + 1}
                  </Typography>
                )
              })
            }
            <ComparisonItemUnits />
          </ComparisonItem>

          {
            tradeOffsComparisonMeta?.map((item) => {
              return (
                <ComparisonItem
                  key={item.name}
                  sx={item.isKPI ? {
                    '& .comparisonItemText': {
                      fontWeight: 500,
                      color: theme.palette.new.black,
                    },
                  } : {}}
                >
                  <ComparisonItemText
                    noWrap={true}
                    title={item.name}
                    className='comparisonItemText'
                  >
                    {item.name}
                  </ComparisonItemText>
                  {
                    numOfPoints.map((point, index) => {
                      const values = getValueByIndex(index, item)

                      return (
                        <Typography
                          key={createId(index, 'item-value')}
                          title={String(values.original)}
                          sx={{
                            width: '100%',
                            minWidth: theme.spacing(7),
                            fontSize: '12px',
                            color: 'rgba(0, 0, 0, 0.6)',
                            textAlign: 'right',

                            ...(item.isKPI ? {
                              color: theme.palette.new.black,
                            } : {}),

                            ...((isSolutionToExecuteActive(index) && item.isKPI) ? {
                              fontWeight: 500,
                              color: theme.palette.new.pink,
                            } : {}),

                            ...(isSolutionToExecuteActive(index) && !item.isKPI ? {
                              color: theme.palette.new.black,
                            } : {}),
                          }}
                        >
                          {values.value}
                        </Typography>
                      )
                    })
                  }
                  <ComparisonItemUnits />
                </ComparisonItem>
              )
            })
          }
          <ComparisonItem
            sx={{
              backgroundColor: '#E5E5E5',
              '& .comparisonItemText': {
                fontWeight: 500,
                color: theme.palette.new.black,
              },

              ...((isPointsSelected && solutionToExecute === null) ? {
                background: theme.palette.new.versatile_violet,

                '& .comparisonItemText': {
                  color: theme.palette.new.white,
                },

                '& .decideItemDisabled': {
                  background: '#7F51E6',
                },
              } : {}),

              ...((isPointsSelected && solutionToExecute !== null) ? {
                background: 'transparent',
              } : {}),
            }}
          >
            <ComparisonItemText className='comparisonItemText'>
              {intl.formatMessage({ id: 'trade_offs.details.decide' })}
            </ComparisonItemText>
            {
              numOfPoints.map((point, index) => {
                return (
                  <Box
                    key={createId(index, 'item-decide')}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                      minWidth: theme.spacing(7),
                    }}
                  >
                    <Box
                      onClick={() => setSolutionToExecuteHandler(index)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        border: '1px solid rgba(0, 0, 0, 0.2)',
                        width: '18px',
                        height: '18px',
                        borderRadius: '100%',
                        cursor: 'pointer',
                        position: 'relative',
                        '&:hover': {
                          '& .decideItemHover': {
                            display: 'flex',
                            width: '2px',
                            height: '2px',
                            background: theme.palette.new.pink,
                          },
                        },

                        ...((isSolutionToExecuteDisabled(index)) ? {
                          cursor: 'not-allowed',
                          background: 'rgba(0, 0, 0, 0.05)',
                          '&:hover': {
                            '& .decideItemHover': {
                              display: 'none',
                            },
                          },
                        } : {}),

                        ...((isSolutionToExecuteActive(index)) ? {
                          background: theme.palette.new.pink,
                          border: 'none',
                        } : {}),
                      }}
                    >
                      <Box
                        className='decideItemHover'
                        sx={{
                          display: 'none',
                        }}
                      />
                    </Box>
                  </Box>
                )
              })
            }
            <ComparisonItemUnits />
          </ComparisonItem>
        </Box>

        <Typography
          sx={{
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            maxWidth: theme.spacing(36),
            fontSize: '14px',
            color: theme.palette.new.pink,
            minHeight: '78px',
            padding: theme.spacing(2),
          }}
        >
          {!isPointsSelected ? intl.formatMessage({ id: 'trade_offs.details.empty' }) : null}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing(0, 2),
            marginBottom: theme.spacing(2),
            background: 'transparent',
          }}
        >
          <ModalButtonComponent
            name='downloadDecisionButton'
            onClick={() => handleDownloadSelection(solutionToExecute!)}
            type='submit'
            loading={false}
            disabled={solutionToExecute === null}
            label={intl.formatMessage({ id: 'trade_offs.details.download_decision' })}
          />
        </Box>
      </Box>
    </Grid>
  )
}

export default TradeOffsPanelComponent
