import React, { useId } from 'react'
import {
  Badge, ButtonProps,
  TooltipProps,
  useTheme,
} from '@mui/material'
import { useIntl } from 'react-intl'

import GroupIcon from '@icons/group.icon'

import {
  GridRowGroupingModel,
  useGridRootProps,
} from '@mui/x-data-grid-premium'

export interface DataGridGroupingButtonComponentProps {
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  disabled?: boolean,
  forwardedRef?: React.Ref<any>,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  groupingModel: GridRowGroupingModel
}

export const DataGridGroupingButtonComponent: React.FC<DataGridGroupingButtonComponentProps> = ({
  slotProps = {}, disabled = false, forwardedRef, onClick, groupingModel,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const rootProps = useGridRootProps()
  const buttonProps = slotProps.button || {}
  const tooltipProps = slotProps.tooltip || {}
  const groupingButtonId = useId()

  return (
    <rootProps.slots.baseTooltip
      title={intl.formatMessage({ id: 'common.grouping' })}
      enterDelay={1000}
      {...tooltipProps}
      {...rootProps.slotProps?.baseTooltip}
    >
      <rootProps.slots.baseButton
        ref={forwardedRef}
        id={groupingButtonId}
        size='small'
        disabled={disabled}
        startIcon={(
          <Badge badgeContent={groupingModel.length} color='primary'>
            <GroupIcon detailsFill={disabled ? theme.palette.new.black_d : theme.palette.new.black} />
          </Badge>
        )}
        {...buttonProps}
        onClick={onClick}
        {...rootProps.slotProps?.baseButton}
      >
        {intl.formatMessage({ id: 'common.group' })}
      </rootProps.slots.baseButton>
    </rootProps.slots.baseTooltip>
  )
}

export default React.forwardRef((props: DataGridGroupingButtonComponentProps, ref) => <DataGridGroupingButtonComponent {...props} forwardedRef={ref} />)
