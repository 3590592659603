import React from 'react'
import { IconButton, IconButtonProps, useTheme } from '@mui/material'

export interface DataGridIconButtonComponentProps extends IconButtonProps {
  forwardedRef?: any
}

export const DataGridIconButtonComponent: React.FC<DataGridIconButtonComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  const theme = useTheme()

  return (
    <IconButton
      data-testid={DataGridIconButtonComponent.name}
      ref={forwardedRef}
      disableRipple={true}
      sx={{
        ...sx,
        borderRadius: '5px',
        '&:hover': {
          backgroundColor: theme.palette.new.smokey_silver,
        },
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridIconButtonComponent {...props} forwardedRef={ref} />)
