import React from 'react'

const GroupIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 19,
  height = 19,
  viewBox = '0 0 19 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={GroupIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.83335 11.3807H1.27567C0.914562 11.3807 0.621826 11.6734 0.621826 12.0345V17.5922C0.621826 17.9533 0.914562 18.246 1.27567 18.246H6.83335C7.19446 18.246 7.48719 17.9533 7.48719 17.5922V12.0345C7.48719 11.6734 7.19446 11.3807 6.83335 11.3807Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.9662 11.3807H11.4085C11.0474 11.3807 10.7546 11.6734 10.7546 12.0345V17.5922C10.7546 17.9533 11.0474 18.246 11.4085 18.246H16.9662C17.3273 18.246 17.62 17.9533 17.62 17.5922V12.0345C17.62 11.6734 17.3273 11.3807 16.9662 11.3807Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9115 1.24602H6.3538C5.99269 1.24602 5.69995 1.53875 5.69995 1.89986V7.45754C5.69995 7.81865 5.99269 8.11139 6.3538 8.11139H11.9115C12.2726 8.11139 12.5653 7.81865 12.5653 7.45754V1.89986C12.5653 1.53875 12.2726 1.24602 11.9115 1.24602Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default GroupIcon
