import React from 'react'

const ConnectorIcon: React.FC<Common.IconProps> = ({
  width = 127,
  height = 10,
  viewBox = '0 0 127 10',
  fill = 'none',
  className = '',
  detailsFill = '#707070',
}) => {
  return (
    <svg data-testid={ConnectorIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M0.646447 4.64645C0.451184 4.84171 0.451184 5.15829 0.646447 5.35355L3.82843 8.53553C4.02369 8.7308 4.34027 8.7308 4.53553 8.53553C4.7308 8.34027 4.7308 8.02369 4.53553 7.82843L1.70711 5L4.53553 2.17157C4.7308 1.97631 4.7308 1.65973 4.53553 1.46447C4.34027 1.2692 4.02369 1.2692 3.82843 1.46447L0.646447 4.64645ZM122 4.5L1 4.5L1 5.5L122 5.5V4.5Z" fill="#C4C4C4"/>
      <circle r="5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 122 5)" fill={detailsFill} />

    </svg>
  )
}

export default ConnectorIcon
