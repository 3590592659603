import React, {
  useMemo,
} from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

export interface ArtifactDetailsComponentProps {
  row: Snapshots.AtifactItem,
}

const ArtifactDetailsComponent: React.FC<ArtifactDetailsComponentProps> = ({ row }) => {
  const intl = useIntl()
  const theme = useTheme()

  const columns = useMemo(() => {
    if (!row.metadata || !row.metadata.columns) {
      return []
    }

    if (!row.metadata.columns) {
      return []
    }

    const columnNames = Object.keys(row.metadata.columns)

    if (columnNames.length === 0) {
      return []
    }

    return columnNames.map((columnName) => {
      return {
        columnName,
        columnType: row.metadata!.columns[columnName],
      }
    })
  }, [row])

  if (columns.length === 0) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        data-testid={`${ArtifactDetailsComponent.name}-empty`}
        sx={{
          backgroundColor: theme.palette.new.grey_f,
        }}
      >
        <Typography variant='h6Alt'>
          {intl.formatMessage({ id: 'snapshots.table.metadata.empty' })}
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      data-testid={ArtifactDetailsComponent.name}
      p={2}
      sx={{
        backgroundColor: theme.palette.new.grey_f,
      }}
    >
      <Typography
        variant='h6'
        mb={1}
      >
        {intl.formatMessage({ id: 'snapshots.table.metadata.columns' })}
      </Typography>

      <Box
        display='flex'
        flexDirection='column'
      >
        {
          columns.map((column, index) => (
            <Box
              key={index}
              display='flex'
              flexDirection='row'
              alignItems='center'
              gap={1}
            >
              <Typography
                variant='subtitle1'
                minWidth='200px'
              >
                {column.columnName}
              </Typography>
              <Typography
                variant='body1'
              >
                {column.columnType}
              </Typography>
            </Box>
          ))
        }
      </Box>
    </Box>
  )
}

export default ArtifactDetailsComponent
