import React, { useMemo } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import ButtonComponent from '@base/buttons/Button'
import { FILE_MANAGER_PATH } from '@constants/routes.constants'

export interface FileBrowserEmptyListComponentProps {
  /**
   * Search query
   */
  search?: string,
  /**
   * Close search handler
   */
  closeSearch: () => void,
}

const FileBrowserEmptyListComponent: React.FC<FileBrowserEmptyListComponentProps> = ({
  search, closeSearch,
}) => {
  const match = useRouteMatch<Common.FileBrowserRouterMatch>()
  const intl = useIntl()
  const history = useHistory()

  const content = useMemo(() => {
    if (search) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100%'
        >
          <Typography
            variant='h6'
            mb={2}
          >
            {
              intl.formatMessage({ id: 'fileManager.list.empty.search' }, {
                search,
              })
            }
          </Typography>

          <ButtonComponent
            name='closeSearch'
            color='secondary'
            onClick={closeSearch}
            label={intl.formatMessage({ id: 'fileManager.list.empty.close' })}
          />
        </Box>
      )
    }

    if (match.params[0]) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100%'
        >
          <Typography
            variant='h6'
            mb={2}
          >
            {intl.formatMessage({ id: 'fileManager.list.empty.dir' })}
          </Typography>

          <ButtonComponent
            name='goHome'
            color='primary'
            label={intl.formatMessage({ id: 'fileManager.list.empty.home' })}
            onClick={() => {
              return history.push(generatePath(FILE_MANAGER_PATH, {
                usecase: match.params.usecase,
              }))
            }}
          />
        </Box>
      )
    }

    return (
      <Typography
        variant='h6'
        mb={2}
      >
        {intl.formatMessage({ id: 'fileManager.list.empty.root' })}
      </Typography>
    )
  }, [intl, search, history, closeSearch, match])

  return (
    <Box
      data-testid={FileBrowserEmptyListComponent.name}
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      minHeight='200px'
    >
      {content}
    </Box>
  )
}

export default FileBrowserEmptyListComponent
