import { useCallback, useState } from 'react'
import { DataGridPremiumProps, GridCallbackDetails, GridPinnedColumnFields } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

export interface useDataGridReversPinningOrderProps {
  apiRef: React.MutableRefObject<GridApiPremium>
  onPinnedColumnsChange?: DataGridPremiumProps['onPinnedColumnsChange']
  initialState?: GridPinnedColumnFields
  reversedPinningOrder: boolean
}

export const useDataGridReversPinningOrder = ({
  apiRef,
  initialState,
  onPinnedColumnsChange,
  reversedPinningOrder,
} : useDataGridReversPinningOrderProps) => {
  const [internalPinnedColumns, setInternalPinnedColumns] = useState<GridPinnedColumnFields>({
    ...initialState,
  })

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields, details: GridCallbackDetails<any>) => {
      if (apiRef.current && reversedPinningOrder) {
        const pinnedColumnsState = apiRef.current.getPinnedColumns()

        const newPinnedLeftState = updatedPinnedColumns.left || []
        const newPinnedRightState = updatedPinnedColumns.right || []

        const alreadyPinnedLeft: string[] = []
        const alreadyPinnedRight: string[] = []

        const newPinnedLeft: string[] = []
        const newPinnedRight: string[] = []

        newPinnedLeftState.forEach((col) => {
          if (pinnedColumnsState.left?.includes(col)) {
            alreadyPinnedLeft.push(col)
          } else {
            newPinnedLeft.push(col)
          }
        })

        newPinnedRightState.forEach((col) => {
          if (pinnedColumnsState.right?.includes(col)) {
            alreadyPinnedRight.push(col)
          } else {
            newPinnedRight.push(col)
          }
        })

        const newState = {
          left: [
            ...alreadyPinnedLeft,
            ...newPinnedLeft,
          ],
          right: [
            ...newPinnedRight,
            ...alreadyPinnedRight,
          ],
        }

        if (onPinnedColumnsChange) {
          onPinnedColumnsChange(newState, details)
        }

        setInternalPinnedColumns(newState)
      } else {
        setInternalPinnedColumns(updatedPinnedColumns)

        if (onPinnedColumnsChange) {
          onPinnedColumnsChange(updatedPinnedColumns, details)
        }
      }
    },
    [apiRef, onPinnedColumnsChange, reversedPinningOrder],
  )

  return {
    onPinnedColumnsChange: handlePinnedColumnsChange,
    pinnedColumns: internalPinnedColumns,
  }
}
