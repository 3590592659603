import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

export interface InsightsLatestRunMessageComponentProps {
  pipelineInfo: Hera.InsightsLatestPipelineRunInfoAPIResponse
}

const ListItem: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <Box
    display='flex'
    flexDirection='row'
    whiteSpace='nowrap'
    mb={0.1}
  >
    {children}
  </Box>
)

const ListHeader: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <Typography
    variant='body2'
    fontWeight={500}
    mr={1}
  >
    {children}
  </Typography>
)

const ListValue: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <Typography
    variant='body2'
  >
    {children}
  </Typography>
)

/* eslint-disable @calm/react-intl/missing-formatted-message */
const InsightsLatestRunMessageComponent: React.FC<InsightsLatestRunMessageComponentProps> = ({
  pipelineInfo,
}) => {
  const content = useMemo(() => {
    if (!pipelineInfo || pipelineInfo === null) {
      return null
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        data-testid={InsightsLatestRunMessageComponent.name}
      >
        <ListItem>
          <ListHeader>
            Pipeline run ID:
          </ListHeader>
          <ListValue>
            {pipelineInfo.pipelineRunId}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Dagster run ID:
          </ListHeader>
          <ListValue>
            {pipelineInfo.dagsterRunId}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Neptune run ID:
          </ListHeader>
          <ListValue>
            {pipelineInfo.neptuneRunId}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Read use case ID:
          </ListHeader>
          <ListValue>
            {pipelineInfo.readUseCaseInfo?.useCaseId}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Write use case ID:
          </ListHeader>
          <ListValue>
            {pipelineInfo.writeUseCaseInfo?.useCaseId}
          </ListValue>
        </ListItem>

        <br />

        <ListItem>
          <ListHeader>
            Date of execution:
          </ListHeader>
          <ListValue>
            {pipelineInfo.dateOfExecution}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Date of prediction:
          </ListHeader>
          <ListValue>
            {pipelineInfo.dateOfPrediction}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Mode:
          </ListHeader>
          <ListValue>
            {pipelineInfo.mode}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Platform:
          </ListHeader>
          <ListValue>
            {pipelineInfo.platform}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Model type:
          </ListHeader>
          <ListValue>
            {pipelineInfo.modelType}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Absolute error metric:
          </ListHeader>
          <ListValue>
            {pipelineInfo.absoluteErrorMetric}
          </ListValue>
        </ListItem>

        <ListItem>
          <ListHeader>
            Relative error metric:
          </ListHeader>
          <ListValue>
            {pipelineInfo.relativeErrorMetric}
          </ListValue>
        </ListItem>
      </Box>
    )
  }, [pipelineInfo])

  return content
}

export default InsightsLatestRunMessageComponent
