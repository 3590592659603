import React from 'react'

const PinRightIcon: React.FC<Common.IconProps> = ({
  opacity = '1',
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
  ...rest
}) => {
  return (
    <svg data-testid={PinRightIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} {...rest} xmlns='http://www.w3.org/2000/svg'>

      <path d="M5.49007 10.3361L1.28579 7.62323C1.12203 7.52391 0.982527 7.38926 0.877476 7.22912C0.772425 7.06898 0.704477 6.88739 0.6786 6.69762C0.652723 6.50786 0.669568 6.3147 0.727906 6.13228C0.786244 5.94986 0.8846 5.78277 1.01579 5.64323L5.14293 1.51609C5.28247 1.3849 5.44955 1.28655 5.63198 1.22821C5.8144 1.16987 6.00755 1.15303 6.19732 1.1789C6.38709 1.20478 6.56868 1.27273 6.72882 1.37778C6.88896 1.48283 7.02361 1.62233 7.12293 1.78609L9.90007 5.93895L15.6729 7.22466C15.8049 7.2567 15.9262 7.32302 16.0243 7.41693C16.1225 7.51084 16.1941 7.62901 16.2319 7.75948C16.2698 7.88994 16.2725 8.02809 16.2398 8.15995C16.2071 8.2918 16.1402 8.41269 16.0458 8.51038L8.13864 16.4304C8.03856 16.521 7.91678 16.5842 7.78511 16.6139C7.65343 16.6436 7.51632 16.6389 7.38702 16.6001C7.25772 16.5613 7.14063 16.4898 7.04706 16.3925C6.9535 16.2952 6.88663 16.1754 6.85293 16.0447L5.49007 10.3361Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0986 12.5604L17.3572 17.8575" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  )
}

export default PinRightIcon
