import * as commonActions from '@redux/modules/common/common.actions'
import { Store } from 'redux'

export const INCREMENT_UNRESOLVED_REQUESTS = 'incrementUnresolvedRequestsAction'
export const DECREMENT_UNRESOLVED_REQUESTS = 'decrementUnresolvedRequestsAction'
export const CHANGE_TOAST_REQUEST = 'changeToastAction'

/* Init Proxy Object to expose store.dispatch */
const ACTIONS = {
  [INCREMENT_UNRESOLVED_REQUESTS]: commonActions.incrementUnresolvedRequestsAction,
  [DECREMENT_UNRESOLVED_REQUESTS]: commonActions.decrementUnresolvedRequestsAction,
  [CHANGE_TOAST_REQUEST]: commonActions.changeToastAction,
}

// eslint-disable-next-line
export let storeDispatchProxy: any = () => {}

/**
 * Store Dispatch Proxy is a Proxy object that exposes only the actions that are allowed to be dispatched
 * It is used for dispatching actions from the utils and other places where the store is not directly accessible.
 * Works only with the actions that are defined in the common.actions.ts file
 *
 * Use with caution, as it can be used to dispatch any action from anywhere in the codebase!
 */
export const createStoreDispatchProxy = (store: Store) => ((_store) => {
  storeDispatchProxy = new Proxy(_store, {
    get(target, action, p) {
      switch (action) {
        case CHANGE_TOAST_REQUEST:
        case INCREMENT_UNRESOLVED_REQUESTS:
        case DECREMENT_UNRESOLVED_REQUESTS:
          return (payload: any) => target.dispatch(ACTIONS[action](payload))

        default:
          return undefined
      }
    },
  })

  return storeDispatchProxy
})(store)
