import produce from 'immer'
import { ReducerPayload, ArtifactsState } from '@redux/modules/types'
import { sortByKey } from '@utils/list.utils'
import { DEFAULT_SORTING_ORDER } from '@constants/filters.constants'

export const initialState: ArtifactsState = {
  fetchingKeys: [],
  artifactsList: [],
}

export const receiveArtifactsList = (state: ArtifactsState, action: ReducerPayload<Artifacts.ArtifactItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.artifactsList = sortByKey(action.payload, 'createdAt', DEFAULT_SORTING_ORDER, { numeric: false, date: true })
  })

  return nextState
}
