import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getUserToken } from '@utils/local-storage.utils'
import { interceptRequestForCancellation } from '@utils/request-cancelation.utils'

import {
  HERA_URI,
  SOCRATES_URI,
  CUSTOMER_URI,
  CHAT_URI,
  USE_CASE_URI,
  PIPELINES_URI,
  KEYCLOAK_URI,
  ATHENA_URI,
  FILES_URI,
  HERMES_URI,
  SNAPSHOTS_URI,
} from '@constants/env-replacements.constants'

import {
  BASE_URL, HEADERS, TIMEOUT, CUSTOMER,
  HEADER_KEYS, AUTH_TOKEN, KEYCLOAK,
  SOCRATES, USE_CASE, ATHENA,
  FILES, HERMES, HERA, CHAT,
  AXIOS_TIMEOUT_IN_MS, PIPELINES,
  SNAPSHOTS,
} from '@constants/api-provider.constants'

import {
  storeDispatchProxy,
  INCREMENT_UNRESOLVED_REQUESTS,
  DECREMENT_UNRESOLVED_REQUESTS,
} from '@configuration/proxy.config'

/**
 * Filters list to bypass "count as pending requests" interceptor
 * List item is a function returning Boolean to indicate that request shouldn't be counted
 *
 * All list items are evaluated as 'OR' expression
 * @type {(function(*): (boolean|*))[]}
 */
const uncountableFilterList: any[] = [
  /* req => (req.baseURL === URL) && req.url.includes('send'), */
]

/**
 * @function isCountable Checks whether request is countable
 *
 * @param {Object} req Request object
 *
 * @return {Boolean} true, if request is countable
 */
export const isCountable = (req = {}) => {
  return uncountableFilterList.every((fn) => !fn(req))
}

/**
 * @function increment Increment pending requests
 */
export const increment = () => {
  return storeDispatchProxy[INCREMENT_UNRESOLVED_REQUESTS]()
}

/**
 * @function decrement decrement pending requests
 */
export const decrement = () => {
  return storeDispatchProxy[DECREMENT_UNRESOLVED_REQUESTS]()
}

/**
 * Default headers
 * @type {{Accept: string, 'Content-Type': string}}
 */
const HEADER_VALUE = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

/**
 * Default config to be overridden by API config
 * @type {{}}
 */
const defaultConfig = {
  [HEADERS]: HEADER_KEYS.reduce((acc, key) => ({ ...acc, ...{ [key]: HEADER_VALUE } }), { ...HEADER_VALUE }),
  [TIMEOUT]: AXIOS_TIMEOUT_IN_MS,
}

/**
 * @function setupInstance Create axios instance with config
 *
 * @param {Object} apiConfig
 *
 * @return {Object} Axios Insctance
 */
export const setupInstance = (apiConfig: AxiosRequestConfig) => {
  const axiosWrapper = axios.create({
    ...defaultConfig,
    ...apiConfig,
  })

  interceptRequestForCancellation(axiosWrapper)

  axiosWrapper.interceptors.request.use((req) => {
    req.headers[AUTH_TOKEN] = `Bearer ${getUserToken()}`

    isCountable(req) && increment()

    return req
  })

  axiosWrapper.interceptors.response.use((response) => {
    isCountable(response.request) && decrement()

    return response
  }, (error) => {
    isCountable(error.request) && decrement()

    return Promise.reject(error)
  })

  return axiosWrapper
}

/**
 * API configuration list with overrides to defaults
 *
 * @type {*[]}
 */
const API_CONFIG_LIST = [
  [ATHENA, { [BASE_URL]: ATHENA_URI }],
  [FILES, { [BASE_URL]: FILES_URI }],
  [HERMES, { [BASE_URL]: HERMES_URI }],
  [HERA, { [BASE_URL]: HERA_URI }],
  [SOCRATES, { [BASE_URL]: SOCRATES_URI }],
  [USE_CASE, { [BASE_URL]: USE_CASE_URI }],
  [PIPELINES, { [BASE_URL]: PIPELINES_URI }],
  [KEYCLOAK, { [BASE_URL]: KEYCLOAK_URI }],
  [CUSTOMER, { [BASE_URL]: CUSTOMER_URI }],
  [CHAT, { [BASE_URL]: CHAT_URI }],
  [SNAPSHOTS, { [BASE_URL]: SNAPSHOTS_URI }],
]

/**
 * @function initProvider Initialize apiProvider service to hold all API instances
 *
 * @param apiConfigList {[]} - apiConfigList
 *
 * @return {Map<any, any>} API provider
 */
const initProvider = (apiConfigList: any) => {
  const instanceList = (apiConfigList).map(([apiKey, config] : [any, any]) => [apiKey, setupInstance(config)]) as any

  return new Map(instanceList)
}

const provider = initProvider(API_CONFIG_LIST)

const apiProvider: {
  getApi(key: string): AxiosInstance;
} = {
  getApi(key: string) {
    return provider.get(key) as AxiosInstance
  },
}

export default apiProvider
