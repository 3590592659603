import React from 'react'

const LayoutIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={LayoutIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M15.3462 1.5H2.65385C2.01659 1.5 1.5 2.01659 1.5 2.65385V15.3462C1.5 15.9834 2.01659 16.5 2.65385 16.5H15.3462C15.9834 16.5 16.5 15.9834 16.5 15.3462V2.65385C16.5 2.01659 15.9834 1.5 15.3462 1.5Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"  />
      <path d="M2 5.5H16" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 6V16" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 6V16" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default LayoutIcon
