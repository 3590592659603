import React from 'react'

const UploadSmallIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 19,
  viewBox = '0 0 18 19',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={UploadSmallIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d="M0.642822 13.542V14.8277C0.642822 15.5097 0.91374 16.1637 1.39598 16.646C1.87821 17.1282 2.53227 17.3991 3.21425 17.3991H14.7857C15.4677 17.3991 16.1217 17.1282 16.604 16.646C17.0862 16.1637 17.3571 15.5097 17.3571 14.8277V13.542M5.14282 5.18484L8.99996 0.684845M8.99996 0.684845L12.8571 5.18484M8.99996 0.684845L9.00024 12.2564"  stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UploadSmallIcon
