import produce from 'immer'
import orderBy from 'lodash.orderby'

import { DEFAULT_SORTER, DESC_SORTING_ORDER, CREATED_AT_SORTING_KEY } from '@constants/filters.constants'
import { ReducerPayload, HermesState } from '@redux/modules/types'
import { ReceiveConnectionsWithDetailsPayload } from './hermes.types'

export const initialState: HermesState = {
  sources: [],
  destinations: [],
  publishers: [],
  transformations: {} as Hermes.TransformationItem,
  publisher: {} as Hermes.PublisherItem,
  exportDetails: {} as Hermes.SpreadsheetExportItem,
  connections: [],
  detailedConnectionsPerUseCase: [],
  fetchingKeys: [],
}

export const receiveTransformations = (state: HermesState, action: ReducerPayload<Hermes.TransformationItem>) => {
  return produce(state, (draftState) => {
    draftState.transformations = action.payload
  })
}

export const receiveArtifactsExportDetails = (state: HermesState, action: ReducerPayload<Hermes.SpreadsheetExportItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.exportDetails = action.payload
  })

  return nextState
}

export const receivePublisher = (state: HermesState, action: ReducerPayload<Hermes.PublisherItem>) => {
  return produce(state, (draftState) => {
    draftState.publisher = action.payload
  })
}

export const receivePublishersList = (state: HermesState, action: ReducerPayload<Hermes.PublisherListItem[]>) => {
  return produce(state, (draftState) => {
    draftState.publishers = action.payload
  })
}

export const receiveConnectionsWithDetails = (state: HermesState, action: ReducerPayload<ReceiveConnectionsWithDetailsPayload>) => {
  return produce(state, (draftState) => {
    const list = action.payload.connections.map((connection) => {
      return {
        ...connection,
        createdAt: connection.createdAt,
        isConnectedToUseCase: Boolean(action.payload.connectionsForUseCase.find((transformation) => transformation.connectionId === connection.connectionId)),
      }
    })

    draftState.detailedConnectionsPerUseCase = orderBy(list, (data) => DEFAULT_SORTER(data, CREATED_AT_SORTING_KEY), DESC_SORTING_ORDER)
  })
}

export const receiveSources = (state: HermesState, action: ReducerPayload<Hermes.SourceDetails[]>) => {
  return produce(state, (draftState) => {
    draftState.sources = action.payload
  })
}

export const receiveDestinations = (state: HermesState, action: ReducerPayload<Hermes.DestinationDetails[]>) => {
  return produce(state, (draftState) => {
    draftState.destinations = action.payload
  })
}

export const receiveConnectionsByCompanyId = (state: HermesState, action: ReducerPayload<Hermes.ConnectionListItem[]>) => {
  return produce(state, (draftState) => {
    draftState.connections = action.payload
  })
}

export const receiveRemovedConnections = (state: HermesState, action: ReducerPayload<string[]>) => {
  return produce(state, (draftState) => {
    const removedConnections = action.payload
    draftState.connections = draftState.connections.filter((connection) => {
      const { connectionId } = connection
      return !removedConnections.includes(connectionId)
    })
  })
}

export const requestAddConnection = (state: HermesState, action: ReducerPayload<Hermes.ConnectionListItem>) => {
  return produce(state, (draftState) => {
    draftState.connections = [action.payload, ...draftState.connections]
  })
}

export const requestUpdateConnection = (state: HermesState, action: ReducerPayload<Hermes.ConnectionListItem>) => {
  return produce(state, (draftState) => {
    const { connectionId } = action.payload
    const { connections } = draftState

    const index = connections.findIndex((e) => e.connectionId === connectionId)
    if (index === -1) {
      return
    }

    draftState.connections[index] = { ...state.connections[index], ...action.payload }
  })
}
