import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { Box } from '@mui/material'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import PageLayoutContainer from '@containers/application/PageLayout'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'

import { isFetchingSnapshot, getSnapshotItem } from '@redux/modules/snapshots/snapshots.selectors'
import { requestSnapshotAction } from '@redux/modules/snapshots/snapshots.actions'

import DownloadIcon from '@icons/download.icon'

import {
  GRID_ACTIONS_COLUMN_TYPE,
  DataGridPremiumProps,
  GridColDef,
  GridRowParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium'

import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'
import { SNAPSHOTS_PATH } from '@constants/routes.constants'
import { parsePfpPath } from '@utils/files.utils'
import { requestFileDownloadAction } from '@redux/modules/file-service/file-service.actions'
import { FILE_PREVIEW_TYPE } from '@constants/files.constants'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

import {
  generateActionsColumnDefinition,
  generateDateTimeColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateNumeralColumnDefinition,
  generateRegularColumnDefinition,
} from '@utils/data-grid-cells.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import ArtifactDetailsComponent from './artifact-details/ArtifactDetails.component'

const SnapshotDetailsContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const snapshotItem = useSelector((state) => getSnapshotItem(state))
  const isFetching = useSelector((state) => isFetchingSnapshot(state))
  const { params: { usecase, snapshot } } = useRouteMatch<Common.SnapshotsRouterMatch>()

  const backToPath = generatePath(SNAPSHOTS_PATH, { usecase })
  const customTitle = intl.formatMessage({ id: 'common.layout.drill_down.title' }, {
    name: (
      <Box component='span' fontWeight={300}>
        {
          isFetching ? (
            intl.formatMessage({ id: 'common.loading' })
          ) : snapshot
        }
      </Box>
    ),
  })

  const tableId = getDataGridId(DATA_GRIDS.SNAPSHOTS_TABLE_DETAILS, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field],
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
    },
  })

  const [refresh, setRefresh] = useState<boolean | undefined>(undefined)

  useLayoutEffect(() => {
    dispatch(
      fetchUseCaseAction({
        useCaseId: usecase,
      }),
    )
  }, [dispatch, usecase])

  useLayoutEffect(() => {
    dispatch(
      requestSnapshotAction({
        snapshotId: snapshot,
      }),
    )
  }, [dispatch, snapshot, refresh])

  const artifactsList = useMemo(() => {
    return snapshotItem?.artifacts || [] as Snapshots.AtifactItem[]
  }, [snapshotItem])

  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const handleDownloadArtifact = useCallback((artifactItem: Snapshots.AtifactItem) => {
    const fileDetails = parsePfpPath(artifactItem.pfpPath)

    dispatch(
      requestFileDownloadAction({
        filePath: fileDetails.path,
        fileName: fileDetails.name,
        bucketName: fileDetails.bucket,
        fileType: FILE_PREVIEW_TYPE.FILE_MANAGER,
      }),
    )
  }, [dispatch])

  const pageBarRightSideBlocks = (
    <CallToActionButtonComponent
      name='refreshButton'
      onClick={handleRefresh}
      loading={isFetching}
      label={intl.formatMessage({ id: 'common.refresh' })}
    />
  )

  const getActionItems = useCallback((params: GridRowParams<Snapshots.AtifactItem>) => {
    return [
      <DataGridActionButtonComponent
        name='downloadArtifact'
        icon={<DownloadIcon />}
        onClick={() => handleDownloadArtifact(params.row)}
        label={intl.formatMessage({ id: 'common.tables.actions.download' })}
        id={params.id}
      />,
    ]
  }, [intl, handleDownloadArtifact])

  const columns = useMemo<GridColDef<Snapshots.AtifactItem>[]>(() => {
    return [
      generateRegularColumnDefinition({
        intl,
        field: 'artifactName',
        headerName: intl.formatMessage({ id: 'snapshots.table.artifactName' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
      }),
      generateCopyButtonColumnDefinition({
        field: 'artifactId',
        headerName: intl.formatMessage({ id: 'snapshots.table.artifactId' }),
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'createdAt',
        headerName: intl.formatMessage({ id: 'snapshots.table.createdAt' }),
      }),
      generateNumeralColumnDefinition({
        intl,
        field: 'numRows',
        headerName: intl.formatMessage({ id: 'snapshots.table.metadata.numRows' }),
        naLabel: intl.formatMessage({ id: 'common.na' }),
        gridColDefOverrides: {
          width: 150,
          valueGetter: (value, row: Snapshots.AtifactItem) => {
            return row.metadata?.numRows || null
          },
        },
      }),
      generateCopyButtonColumnDefinition({
        field: 'pfpPath',
        headerName: intl.formatMessage({ id: 'snapshots.table.pfpPath' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 1,
      }),
    ]
  }, [
    intl,
    getActionItems,
  ])

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <ArtifactDetailsComponent row={row} />, [])

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  if (!isAdmin && !isFetching) {
    return null
  }

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'snapshots.browser_tab.details_title' }, { name: snapshot })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      backToPath={backToPath}
      customTitle={customTitle}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.SNAPSHOTS_TABLE_DETAILS}
        columns={columns}
        loading={isFetching}
        rows={artifactsList}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.artifactId}
        initialState={initialState}
        disableRowGrouping={true}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </PageLayoutContainer>
  )
}

export default SnapshotDetailsContainer
