import React from 'react'

const EditIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={EditIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path d="M6.43025 15.7371L0.644531 17.3571L2.26453 11.5714L12.8588 1.02856C12.9786 0.906104 13.1216 0.808803 13.2794 0.742373C13.4373 0.675943 13.6068 0.641724 13.7781 0.641724C13.9494 0.641724 14.1189 0.675943 14.2768 0.742373C14.4346 0.808803 14.5776 0.906104 14.6974 1.02856L16.9731 3.31714C17.0936 3.43666 17.1893 3.57886 17.2545 3.73554C17.3198 3.89221 17.3534 4.06026 17.3534 4.22999C17.3534 4.39972 17.3198 4.56777 17.2545 4.72445C17.1893 4.88112 17.0936 5.02333 16.9731 5.14285L6.43025 15.7371Z" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default EditIcon
