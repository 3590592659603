import React from 'react'

const UsersIcon: React.FC<Common.IconProps> = ({
  width = 18,
  height = 18,
  viewBox = '0 0 18 18',
  fill = 'none',
  className = '',
  detailsFill = 'black',
}) => {
  return (
    <svg data-testid={UsersIcon.name} className={className} width={width} height={height} viewBox={viewBox} fill={fill} xmlns='http://www.w3.org/2000/svg'>

      <path fillOpacity="0" d="M12.5 17.5V14.2305H15.1364C15.498 14.2305 15.8449 14.0927 16.1006 13.8475C16.3563 13.6022 16.5 13.2696 16.5 12.9227V7.69154C16.4998 6.4132 16.1443 5.15803 15.4702 4.05522C14.7961 2.95241 13.8277 2.04182 12.6648 1.41718C11.5018 0.792539 10.1863 0.476422 8.85363 0.50137C7.52097 0.526318 6.21931 0.89143 5.08266 1.55912C3.94601 2.22681 3.01543 3.17295 2.38672 4.30015C1.75801 5.42734 1.45389 6.69487 1.50566 7.97224C1.55743 9.24961 1.96322 10.4907 2.68126 11.5677C3.3993 12.6447 4.31274 13.5187 5.5 14.0997V17.5" stroke={detailsFill} strokeLinecap="round" strokeLinejoin="round" />

    </svg>
  )
}

export default UsersIcon
